<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="lineNotices"
      :items-per-page="-1"
      disable-sort
      hide-default-footer
    >
      <template #[`item.user.name`]="{ item }">
        <a @click="$emit('click:row', item.id)">
          {{ item.user.name }}
        </a>
      </template>
      <template #[`item.notice_type`]="{ item }">
        {{ $$te('line_notice.notice_type', item) }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          color="primary"
          :loading="$wait.is('processing')"
          small
          @click="testNotification(item)"
        >
          {{ $t('notificator.test') }}
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    lineNotices: { type: Array, required: true },
  },
  data: () => ({
    headers: [
      { text: 'ユーザー名', value: 'user.name' },
      { text: '設定', value: 'notice_type' },
      { text: '操作', value: 'actions' },
    ],
  }),
  methods: {
    testNotification(item) {
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.$emit('click:testNotification', item.id)
    },
  },
}
</script>
