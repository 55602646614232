<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn
            v-if="scenarioTimingId !== 'new'"
            color="error"
            :disabled="fsce_active_flg"
            @click="destroy"
            >{{ $t('btn.delete') }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="firstInit" ref="observer">
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.scenario.info') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="3">
              <VfSelect
                v-model="scenarioTiming.scenario_timing_type"
                :label="$$tm('scenario_timing.scenario_timing_type')"
                :vees="vees.scenario_timing_type"
                :selects="opts.scenario_timing_type"
                :disabled="fsce_active_flg"
              />
            </v-col>
            <template v-if="scenarioTiming.scenario_timing_type !== 'start'">
              <v-col md="3">
                <VfTextField
                  v-model="scenarioTiming.sch_day"
                  :label="$$tm('scenario_timing.sch_day')"
                  :vees="vees.sch_day"
                  :disabled="fsce_active_flg"
                  number
                />
              </v-col>
              <v-col md="3">
                <VfSelect
                  v-model="scenarioTiming.sch_hour"
                  :label="$$tm('scenario_timing.sch_hour')"
                  :vees="vees.sch_hour"
                  :selects="opts.sch_hour"
                  :disabled="fsce_active_flg"
                />
              </v-col>
              <v-col md="3">
                <VfSelect
                  v-model="scenarioTiming.sch_minute"
                  :label="$$tm('scenario_timing.sch_minute')"
                  :vees="vees.sch_minute"
                  :selects="opts.sch_minute"
                  :disabled="fsce_active_flg"
                />
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card outlined class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.send_target') }}
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-card-text>
              <PtSearchSelectedList
                v-model="scenarioTiming.target_search_wrap"
                @pt-search-selected-list-save="ptSearchSelectedListSave"
                @pt-search-selected-list-clear="ptSearchSelectedListSave"
              />
            </v-card-text>
          </v-card>
          <div class="mt-4 red--text">
            {{ $t('lbl.broadcast.friend_count', { count: friendCount }) }}
          </div>
          <div>
            {{ $t('lbl.broadcast.warning') }}
          </div>
        </v-card-text>
      </v-card>
      <v-card outlined class="mb-6">
        <v-card-title>
          {{ $t('lbl.scenario_timing.sent_action') }}
        </v-card-title>
        <v-card-text>
          <VfSwitch
            v-model="scenarioTiming.stop_flg"
            :label="$$tm('scenario_timing.stop_flg')"
          />
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.actions') }}
        </v-card-title>
        <v-card-text v-if="firstInit">
          <PtActionGroup
            hide-timing-type-select
            v-model="scenarioTiming.action_arr"
          />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col class="text-right">
          <v-btn
            color="primary"
            :loading="$wait.is('processing')"
            @click="save"
            >{{ $t('btn.create') }}</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtActionGroup from 'components/parts/common/PtActionGroup/PtActionGroup'
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
import {
  validateMultipleDeliText,
  validateMultipleUrlDeliText,
} from 'services/deliText'
export default {
  components: {
    PtActionGroup,
    PtSearchSelectedList,
  },
  data: () => ({
    firstInit: false,
    scenarioId: '',
    scenarioTimingId: '',
    scenarioTiming: {},
    fsce_active_flg: false,
    vees: {},
    opts: {},
    deleteDialog: false,
    savePath: '',
    friendCount: 0,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.firstInit = false
      this.scenarioId = this.$route.query.scenario_id
      this.scenarioTimingId = this.$route.params.id
      const path =
        this.scenarioTimingId === 'new'
          ? '/api/v1/scenario_timings/new'
          : `/api/v1/scenario_timings/${this.scenarioTimingId}/edit`
      const prms = { scenario_id: this.scenarioId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.scenarioTiming = res.data.scenario_timing
          this.fsce_active_flg = res.data.fsce_active_flg
          this.vees = res.data.vees
          this.opts = res.data.opts
          this.ptSearchSelectedListSave()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.firstInit = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        if (
          this.scenarioTiming.action_arr.length > 0 &&
          !validateMultipleUrlDeliText(this.scenarioTiming.action_arr)
        ) {
          return this.$toasted.error(this.$t('validation.length_of_url'))
        } else {
          return this.$toasted.error(this.$t('danger.params'))
        }
      }
      if (this.$wait.is('processing')) {
        return
      }
      if (
        this.scenarioTiming.action_arr.length > 0 &&
        this.scenarioTiming.action_arr[0].type === 'broadcast' &&
        !validateMultipleDeliText(
          this.scenarioTiming.action_arr[0].data.target_delis
        )
      ) {
        return this.$toasted.error(this.$t('danger.deli_text.format_invalid'))
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.scenarioTimingId === 'new'
          ? '/api/v1/scenario_timings'
          : `/api/v1/scenario_timings/${this.scenarioTimingId}`
      const method = this.scenarioTimingId === 'new' ? 'post' : 'put'
      const prms = {
        scenario_timing: this.scenarioTiming,
        scenario_id: this.scenarioId,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({
            name: 'ScenarioForm',
            params: { id: this.scenarioId },
          })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    async ptSearchSelectedListSave() {
      const path = '/api/v1/friends/search_wrap_send_target_count'
      const prms = { search_wrap: this.scenarioTiming.target_search_wrap }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then((res) => {
          this.friendCount = res.data.friend_count
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    destroy() {
      this.savePath = `/api/v1/scenario_timings/${this.scenarioTimingId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({
        name: 'ScenarioForm',
        params: { id: this.scenarioId },
      })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
