<template>
  <div class="pl-2 pb-2 d-flex w-100 flex-column">
    <div v-show="!isEdit" class="pt-1 file-name font-weight-bold">
      {{ fileName }}
    </div>
    <VfTextField
      v-show="isEdit"
      v-model="fileName"
      label=""
      hide-details
      class="mt-0 mb-1 pt-0 pl-1 font-size-x-small"
    />
    <div class="d-flex justify-space-between align-items-center">
      <v-btn v-show="isEdit" class="ma-0" text x-small icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <a
        v-show="!isEdit"
        :href="fileUrl"
        target="_blank"
        class="download-url"
        >{{ $t('btn.download') }}</a
      >
      <v-btn
        v-show="!isEdit"
        small
        class="ma-0 px-1 align-self-end"
        @click="isEdit = !isEdit"
      >
        {{ $t('btn.edit_name') }}
      </v-btn>
      <v-btn v-show="isEdit" small class="ma-0 align-self-end" @click="rename">
        {{ $t('btn.save') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: { type: String, required: true },
    fileId: { type: String, required: true },
    fileUrl: { type: String, required: true },
  },
  data: () => ({
    isEdit: false,
    fileName: '',
  }),
  created() {
    this.fileName = this.name
  },
  methods: {
    close() {
      this.isEdit = !this.isEdit
      this.fileName = this.name
    },
    rename() {
      const path = `api/v1/upload_files/${this.fileId}/update_file_name`
      const prms = { file_name: this.fileName }
      this.axios
        .patch(path, prms)
        .then(() => {
          this.isEdit = !this.isEdit
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {})
    },
  },
}
</script>
<style scoped lang="scss">
.file-name {
  min-height: 28px;
  word-break: break-all;
}
.download-url {
  font-size: small;
}
</style>
