<template>
  <div>
    <video ref="videoPlayer" class="video-js" />
  </div>
</template>
<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
export default {
  props: {
    width: { type: Number, default: null },
    height: { type: Number, default: null },
    src: { type: String, required: true },
    type: { type: String, default: '' },
  },
  data: () => ({
    player: null,
    videoTypes: {
      mp4: 'video/mp4',
      mpeg: 'video/mpeg',
      mpg: 'video/mpeg',
      wmv: 'video/wmv',
    },
    videoOptions: {
      autoplay: false,
      controls: true,
      controlBar: {
        fullscreenToggle: true,
      },
      playbackRates: [0.5, 1, 1.5, 2],
      width: null,
      height: null,
      sources: {
        src: '',
        type: '',
      },
    },
  }),
  mounted() {
    this.videoOptions.width = this.width
    this.videoOptions.height = this.height
    this.videoOptions.sources.src = this.src
    this.videoOptions.sources.type = this.type
      ? this.type
      : (function (val, types) {
          const ext = val.split('.').pop().trim().toLowerCase()
          return types[ext]
        })(this.src, this.videoTypes)
    this.videoOptions.sources.type = this.type
    this.player = videojs(
      this.$refs.videoPlayer,
      this.videoOptions,
      function onPlayerReady() {}
    )
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  },
}
</script>
