<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <VfSelect
                  v-model="internalValue.fixed_phrase_id"
                  :label="$$tm('fixed_phrase.name')"
                  :selects="selects"
                  readonly
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="4" cols="12">
            <PtFolder
              ref="folder"
              folder-type="fixed_phrase"
              select-only
              @select-folder="selectFolder"
            />
          </v-col>
          <v-col md="8" cols="12">
            <v-data-table
              v-model="checkedItems"
              :headers="headers"
              :items="items"
              item-key="id"
              show-select
              mobile-breakpoint="0"
              :single-select="true"
              :disable-sort="true"
              :items-per-page="50"
              :footer-props="{
                itemsPerPageOptions: [50, 100, 150],
              }"
              @click:row="rowClick"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <VSpacer />
        <v-btn @click="cancel">{{ $t('btn.cancel') }}</v-btn>
        <v-btn color="primary" @click="decide">{{ $t('btn.decide') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import PtFolder from 'components/parts/common/PtFolder'
export default {
  components: {
    PtFolder,
  },
  props: {
    value: { type: Object, required: true },
  },
  data: () => ({
    loading: false,
    dialog: false,
    errorMsg: '',
    headers: [],
    items: [],
    selectedId: '',
    checkedItems: [],
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    selects() {
      const ret = {}
      this.checkedItems.forEach((item) => (ret[item.name] = item.id))
      return ret
    },
  },
  watch: {
    checkedItems(arr) {
      if (arr.length === 0) {
        this.internalValue.fixed_phrase_id = ''
        this.$set(this.internalValue, 'fixed_phrase', {})
        return
      }
      this.internalValue.fixed_phrase_id = arr[0].id
      this.$set(this.internalValue, 'fixed_phrase', { name: arr[0].name })
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.initTable(null)
      if (!this.internalValue.fixed_phrase) {
        return
      }
      this.checkedItems = [
        {
          id: this.internalValue.fixed_phrase_id,
          name: this.internalValue.fixed_phrase.name,
        },
      ]
    },
    async initTable(folderId) {
      this.loading = true
      const path = '/api/v1/fixed_phrases/action_group'
      const prms = { folder_id: folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.fixed_phrases
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectFolder(folderId) {
      this.initTable(folderId)
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
    cancel() {
      this.$emit('cancel-item')
    },
    decide() {
      const ret = this.checkedItems.length > 0 ? this.checkedItems[0] : null
      this.$emit('decide-item', ret)
    },
  },
}
</script>
