<template>
  <v-dialog v-model="dialog" :max-width="500">
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" icon outlined v-on="on" @click="$emit('click')">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <validation-observer ref="observer">
      <v-form @submit.prevent="save">
        <v-card>
          <v-card-title> 基本情報変更 </v-card-title>
          <v-divider class="mt-0" />
          <v-card-text>
            <VfTextField
              v-model="internalValue.name"
              :label="$$tm('channel.name')"
              :vees="vees.name"
            />
            <VfTextField
              v-model="internalValue.line_account_id"
              :label="$$tm('channel.line_account_id')"
              :vees="vees.line_account_id"
              :disabled="isUser"
            />
            <VfTextField
              v-model="internalValue.line_channel_id"
              :label="$$tm('channel.line_channel_id')"
              :vees="vees.line_channel_id"
              :disabled="isUser"
            />
            <VfTextField
              v-model="internalValue.line_channel_secret_id"
              :label="$$tm('channel.line_channel_secret_id')"
              :vees="vees.line_channel_secret_id"
              password
            />
            <VfTextField
              v-model="internalValue.line_login_channel_id"
              :label="$$tm('channel.line_login_channel_id')"
              :vees="vees.line_login_channel_id"
              :disabled="isUser"
            />
            <VfTextField
              v-model="internalValue.line_login_channel_secret_id"
              :label="$$tm('channel.line_login_channel_secret_id')"
              :vees="vees.line_login_channel_secret_id"
              password
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="cancel">
              {{ $t('btn.cancel') }}
            </v-btn>
            <v-btn v-if="isUser" type="submit" color="primary">
              {{ $t('btn.update') }}
            </v-btn>
            <v-btn v-else color="primary" @click="openUpdateDialog">
              {{ $t('btn.update') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
    <v-dialog v-model="updateDialog" :max-width="500">
      <v-card>
        <v-card-title>
          {{ $t('confirm_title.send') }}
        </v-card-title>
        <v-card-text>
          <v-col>
            {{ $t('admin.channel_update_confirm') }}
          </v-col>
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="closeUpdateDialog">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="primary" @click="save">{{ $t('btn.action') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    vees: { type: Object, required: true },
    isUser: { type: Boolean, required: true },
  },
  data: () => ({
    dialog: false,
    updateDialog: false,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.dialog = false
      this.$emit('save')
    },
    cancel() {
      this.dialog = false
    },
    openUpdateDialog() {
      this.updateDialog = true
    },
    closeUpdateDialog() {
      this.updateDialog = false
    },
  },
}
</script>
