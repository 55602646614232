<template>
  <validation-provider v-slot="{ errors }" :name="label" :rules="rules">
    <div v-if="!hideLabel" class="mb-0 text-caption theme--light v-label">
      {{ label }}
    </div>
    <v-switch
      v-model="internalValue"
      class="mt-0"
      :error-messages="errors"
      :true-value="trueValue"
      :false-value="falseValue"
      :disabled="disabled"
      :readonly="readonly"
      inset
      @change="onChange"
    />
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: { default: null },
    label: { type: String, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    trueValue: { default: 1 },
    falseValue: { default: 0 },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    hideLabel: { type: Boolean, default: false },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    rules() {
      if (!this.vees) {
        return null
      }
      return (this.vees || {}).rules
    },
  },
  created() {
    if (!this.value) {
      this.internalValue = this.falseValue
    }
  },
  methods: {
    onChange() {
      this.$emit('vf-switch-chenge')
    },
  },
}
</script>
