<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn
            v-if="scenarioId !== 'new'"
            color="error"
            :disabled="fsce_active_flg"
            @click="destroy"
            >{{ $t('btn.delete') }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-form @submit.prevent="save">
        <v-card class="mb-6">
          <v-card-title class="or-card-title mb-6">
            {{ $t('lbl.scenario.info') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="5">
                <VfTextField
                  v-model="scenario.name"
                  :label="$$tm('scenario.name')"
                  :vees="vees.name"
                />
              </v-col>
              <v-col md="5">
                <VfSelect
                  v-model="scenario.scenario_type"
                  :label="$$tm('scenario.scenario_type')"
                  :vees="vees.scenario_type"
                  :selects="opts.scenario_type"
                  :disabled="scenarioId !== 'new'"
                />
              </v-col>
              <v-col md="2">
                <VfSelect
                  v-model="scenario.scenario_status"
                  :label="$$tm('scenario.scenario_status')"
                  :vees="vees.scenario_status"
                  :selects="opts.scenario_status"
                />
              </v-col>
            </v-row>
            <v-expansion-panels v-model="scenarioTypeCaution" accordion>
              <v-expansion-panel>
                <v-expansion-panel-header color="warning white--text">
                  <div color="red">
                    {{ $t('lbl.scenario.cant_change_scenario_type') }}
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="py-6">
                  <div class="or-break">
                    {{ $t('lbl.scenario.scenario_type_time') }}
                  </div>
                  <div class="my-6" />
                  <div class="or-break">
                    {{ $t('lbl.scenario.scenario_type_progress') }}
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <VSpacer />
            <v-btn @click="$router.push({ name: 'ScenarioIndex' })">{{
              $t('btn.back')
            }}</v-btn>
            <v-btn
              type="submit"
              color="primary"
              :loading="$wait.is('processing')"
              @click="update"
              >{{ $t('btn.create') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
    <PtScenarioTimingIndex
      v-if="scenarioId !== 'new' && initDone"
      :scenario-id="scenarioId"
      :fsce-active-flg="fsce_active_flg"
    />
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="deleteConfirm"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
    <VfDialogConfirmWithoutPath
      v-model="updateDialog"
      :max-width="300"
      :detail="$t('lbl.scenario.update_delivery_confirm')"
      target-emit="update-success"
      @update-success="updateSuccess"
    />
  </div>
</template>
<script>
import PtScenarioTimingIndex from 'components/parts/unique/PtScenarioTimingIndex'
export default {
  components: {
    PtScenarioTimingIndex,
  },
  data: () => ({
    initDone: false,
    scenarioId: '',
    scenario: {},
    fsce_active_flg: false,
    vees: {},
    opts: {},
    scenarioTypeCaution: null,
    savePath: '',
    deleteDialog: false,
    deleteConfirm: '',
    updateDialog: false,
    allowSubmit: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.scenarioId = this.$route.params.id
      const path =
        this.scenarioId === 'new'
          ? '/api/v1/scenarios/new'
          : `/api/v1/scenarios/${this.scenarioId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.scenario = res.data.scenario
          this.fsce_active_flg = res.data.fsce_active_flg
          this.vees = res.data.vees
          this.opts = res.data.opts
          this.read_count = res.data.scenario.read_count
          this.read_count === 0
            ? (this.deleteConfirm = this.$t('lbl.scenario.delete_confirm'))
            : (this.deleteConfirm = this.$t('lbl.scenario.use_delete_confirm'))
          if (this.scenarioId === 'new') {
            this.scenarioTypeCaution = 0
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      if (!this.allowSubmit) return
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.scenarioId === 'new'
          ? '/api/v1/scenarios'
          : `/api/v1/scenarios/${this.scenarioId}`
      const method = this.scenarioId === 'new' ? 'post' : 'put'
      const prms = { scenario: this.scenario }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then((res) => {
          this.$toasted.success(this.$t('success.create'))
          this.$router
            .push({
              name: 'ScenarioForm',
              params: { id: res.data.scenario.id },
            })
            .catch((err) => {})
          this.scenarioTypeCaution = null
          this.allowSubmit = false
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    destroy() {
      this.savePath = `/api/v1/scenarios/${this.scenarioId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'ScenarioIndex' })
    },
    updateSuccess() {
      this.allowSubmit = true
      this.updateDialog = false
      this.save()
    },
    update() {
      if (this.scenario.scenario_status === 'stop') {
        this.updateDialog = true
      } else {
        this.allowSubmit = true
      }
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
