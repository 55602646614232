<template>
  <div>
    <v-card outlined class="transparent" style="width: 100%">
      <v-card-title class="pl-0 pa-0 or-card-title">
        <v-list-item class="pa-0">
          <v-list-item-content>
            {{ $t('lbl.new_info') }}
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              small
              class="or-card-title"
              @click="$router.push({ name: 'InfoIndex' })"
            >
              もっと見る
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <VfAlert v-model="errorMsg" type="error" />
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :disable-sort="true"
        hide-default-footer
        @click:row="showDialog"
      >
        <template #[`item.title`]="{ item }">
          <a class="green--text text--darken-1 font-weight-blac">
            {{ item.title }}
          </a>
        </template>
        <template #[`item.subtitle`]="{ item }">
          <a class="grey--text text--lighten-1">
            {{ item.subtitle }}
          </a>
        </template>
        <template #[`item.disp_st_at`]="{ item }">
          {{ $$fat(item.disp_st_at) }}
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="previewDialog" max-width="800">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="4">
              {{ $$fat(selectInfo.disp_st_at) }}
            </v-col>
            <v-col cols="8">
              {{ selectInfo.title }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <v-card-subtitle>
          {{ selectInfo.subtitle }}
        </v-card-subtitle>
        <v-card-text>
          <div class="or-break">{{ selectInfo.desc }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    previewDialog: false,
    errorMsg: '',
    items: [],
    headers: [],
    selectInfo: {},
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.errorMsg = ''
      this.loading = true
      const path = '/api/v1/admin_infos'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          if (res.data.admin_infos.length > 3) {
            for (
              let i = res.data.admin_infos.length - 1;
              i >= res.data.admin_infos.length - 3;
              i--
            ) {
              this.items.push(res.data.admin_infos[i])
            }
          } else {
            this.items = res.data.admin_infos
          }
          this.headers = res.data.thead
          this.headers.pop()
          this.headers.pop()
          this.headers[0].width = '20%'
          this.headers[1].width = '30%'
          this.headers[2].width = '50%'
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
      this.loading = false
    },
    showDialog(info) {
      this.selectInfo = info
      this.previewDialog = true
    },
  },
}
</script>
