<template>
  <div>
    <v-card class="mx-auto px-12 py-6" :max-width="800">
      <validation-observer ref="observer">
        <v-form @submit.prevent="save">
          <v-card-title>
            <v-row justify="center">
              <div>{{ $t(`meta.title.${$route.name}`) }}</div>
            </v-row>
          </v-card-title>
          <v-divider class="mx-4" />
          <v-card-text v-if="user.email">
            <v-row>
              <v-col cols="12" md="6">
                <VfTextField
                  v-model="user.name"
                  :label="$$tm('user.name')"
                  :vees="vees.user.name"
                />
              </v-col>
              <v-col cols="12" md="6">
                <VfTextField
                  v-model="user.phone"
                  :label="$$tm('user.phone')"
                  :vees="vees.user.phone"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <VfTextField
                  v-model="userAddress.zip"
                  :label="$$tm('user_address.zip')"
                  :vees="vees.user_address.zip"
                />
              </v-col>
              <v-col cols="12" md="3">
                <VfSelect
                  v-model="userAddress.pref"
                  :label="$$tm('user_address.pref')"
                  :vees="vees.user_address.pref"
                  :selects="opts.pref"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <VfTextField
                  v-model="userAddress.address1"
                  :label="$$tm('user_address.address1')"
                  :vees="vees.user_address.address1"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <VfTextField
                  v-model="userAddress.address2"
                  :label="$$tm('user_address.address2')"
                  :vees="vees.user_address.address2"
                />
              </v-col>
              <v-col v-if="planDisp" cols="12">
                <VfSelect
                  v-model="user.plan_id"
                  :label="$$tm('plan.name')"
                  :selects="opts.plans"
                  :vees="{ rules: 'required' }"
                />
                <div class="mt-4">
                  プランの詳細については<a
                    href="https://talklabel.com"
                    target="_blank"
                    >こちら</a
                  >をご覧下さい。
                </div>
              </v-col>
              <v-col>
                <pt-payjp-form
                  @payjp:created="payjpTokenCreated"
                  @payjp:failed="payjpTokenFailed"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="ma-4" />
          <v-card-actions>
            <v-spacer />
            <v-btn
              type="submit"
              color="primary"
              :loading="$wait.is('processing')"
              >{{ $t('btn.payment') }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </div>
</template>
<script>
import PtPayjpForm from 'components/parts/unique/PtPayjpForm'
export default {
  components: {
    PtPayjpForm,
  },
  data: () => ({
    user: {},
    userAddress: {},
    vees: {},
    opts: {},
    planDisp: false,
    token: '',
  }),
  watch: {
    'userAddress.zip'(val) {
      this.getAddress(val)
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/account/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.user = res.data.user
          this.userAddress = res.data.user_address
          this.vees = res.data.vees
          this.opts = res.data.opts
          const findPlan = this.opts.plans.find(
            (plan) => plan.value === this.user.plan_id
          )
          if (!findPlan) {
            this.planDisp = true
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    async getAddress(val) {
      if (!val || val.length !== 7) {
        return
      }
      const path = '/api/v1/address/get_address_from_zip'
      const prms = { zipcode: val }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          if (res.data.address) {
            this.userAddress.pref = this.opts.pref[res.data.address.pref]
            this.userAddress.address1 = res.data.address.address
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/account`
      const prms = {
        user: this.user,
        user_address: this.userAddress,
        token: this.token,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.$router.push({ name: 'RegistrationChannel' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    payjpTokenCreated(token) {
      this.token = token
    },
    payjpTokenFailed() {
      this.$toasted.error('failed')
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
