var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"transparent",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"pl-0 pa-0 or-card-title"},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',[_vm._v(" 最新受信メッセージ ")]),_vm._v(" "),_c('v-list-item-action',[_c('v-btn',{staticClass:"or-card-title",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push({ name: 'MessageIndex' })}}},[_vm._v("\n            さらに詳しく\n          ")])],1)],1)],1),_vm._v(" "),_c('VfLoadingOverlayAbsolute',{attrs:{"loading":_vm.loading}}),_vm._v(" "),_c('VfAlert',{attrs:{"type":"error"},model:{value:(_vm.errorMsg),callback:function ($$v) {_vm.errorMsg=$$v},expression:"errorMsg"}}),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.friend.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'FriendShow', params: { id: item.friend.id } }}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"36"}},[_c('v-img',{attrs:{"small":"","src":_vm.$$noimageHelper(item.friend.api_picture_url),"alt":item.friend.name}})],1),_vm._v("\n            "+_vm._s(item.friend.name)+"\n          ")],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.$$fdt(item.created_at))+"\n          "),_c('br'),_vm._v("\n          "+_vm._s(_vm.$$ftm(item.created_at, 'LTS'))+"\n        ")]}},{key:"item.message_status",fn:function(ref){
var item = ref.item;
return [(item.message_type === 'receive')?_c('div',[_c('v-chip',{attrs:{"dark":"","label":"","color":_vm.colors[item.message_status]},on:{"click":function($event){return _vm.openDialogMessageStatus(item)}}},[_vm._v("\n              "+_vm._s(_vm.$$te('friend.message_status', item))+"\n            ")])],1):_vm._e()]}},{key:"item.deli",fn:function(ref){
var item = ref.item;
return [_c('PtLinePreviewRow',{staticClass:"my-2",attrs:{"deli":item.deli,"message-type":item.message_type,"hide-detail":""}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.dialogMessageStatus),callback:function ($$v) {_vm.dialogMessageStatus=$$v},expression:"dialogMessageStatus"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pt-6"},[_c('VfSelect',{attrs:{"label":_vm.$$tm('message.message_status'),"selects":_vm.opts.message_status},model:{value:(_vm.selectedItemClone.message_status),callback:function ($$v) {_vm.$set(_vm.selectedItemClone, "message_status", $$v)},expression:"selectedItemClone.message_status"}})],1),_vm._v(" "),_c('v-card-actions',[_c('VSpacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.closeDialogMessageStatus}},[_vm._v(_vm._s(_vm.$t('btn.cancel')))]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.updateDialogMessageStatus}},[_vm._v(_vm._s(_vm.$t('btn.update')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }