<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-row>
      <v-col md="6" class="pb-0">
        <VfRadioButton
          v-model="internalValue.rich_menu_action_type"
          :label="$$tm('action_rich_menu.rich_menu_action_type')"
          :vees="vees.rich_menu_action_type"
          :selects="opts.rich_menu_action_type"
          label-hidden
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <PtDialogSelect
          v-if="internalValue.rich_menu_action_type !== 'cancel'"
          v-model="internalValue"
          select-type="rich_menu"
          :single-select="true"
          :vees="vees.name"
        >
          <template #[`item.upload_file_id`]="{ item }">
            <VImg width="120" :src="item.upload_file.preview_image.url" />
          </template>
        </PtDialogSelect>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtDialogSelect,
  },
  props: {
    value: { type: Object, required: true },
    vees: { type: Object, default: () => ({}) },
    opts: { type: Object, default: () => ({}) },
  },
  data: () => ({
    loading: false,
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
