<template>
  <validation-provider v-slot="{ errors }" :name="label" :rules="rules">
    <v-checkbox
      v-model="internalValue"
      class="label-mb-0"
      :label="label"
      :name="name"
      :true-value="trueValue"
      :false-value="falseValue"
      :value="checkboxValue"
      :persistent-hint="persistentHint"
      :hide-details="hideDetails"
      :color="color"
      :multiple:="multiple"
      :disabled="disabled"
      :hint="hint"
      @change="$emit('change', $event)"
    />
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: { default: null },
    checkboxValue: { default: null },
    label: { type: String, default: '' },
    name: { type: String, default: '' },
    trueValue: { type: Number, default: 1 },
    falseValue: { type: Number, default: 0 },
    persistentHint: { type: Boolean, default: false },
    color: { type: String, default: '' },
    hideDetails: { type: [Boolean, String], default: false },
    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hint: { type: String, default: '' },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    rules() {
      if (!this.vees) {
        return null
      }
      return (this.vees || {}).rules
    },
  },
}
</script>
<style scoped>
.label-mb-0 ::v-deep label {
  margin-bottom: 0px;
}
</style>
