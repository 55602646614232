<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle />
      </v-col>
    </v-row>
    <v-card class="mx-auto mt-8" max-width="800">
      <v-card-text>
        <v-card class="mt-4">
          <v-card-title>
            {{ $t('cancel_form.name') }}
          </v-card-title>
          <v-row>
            <v-col class="ml-4" md="8">
              {{ cancelForm.name }}
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            {{ $t('cancel_form.email') }}
          </v-card-title>
          <v-row>
            <v-col class="ml-4" md="8">
              {{ cancelForm.email }}
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            {{ $t('cancel_form.phone') }}
          </v-card-title>
          <v-row>
            <v-col class="ml-4" md="8">
              {{ cancelForm.phone }}
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            {{ $t('cancel_form.line_account_id') }}
          </v-card-title>
          <v-row>
            <v-col class="ml-4" md="8">
              {{ cancelForm.line_account_id }}
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            {{ $t('cancel_form.plan_name') }}
          </v-card-title>
          <v-row>
            <v-col class="ml-4" md="8">
              {{ cancelForm.plan_name }}
            </v-col>
          </v-row>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            {{ $t('cancel_form.reason_text') }}
          </v-card-title>
          <v-row>
            <v-col class="ml-4" md="8">
              <div class="or-break">{{ cancelForm.reason_text }}</div>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    cancelForm: {},
    loading: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.cancelFormId = this.$route.params.id
      const path = `/api/v1/cancel_forms/${this.cancelFormId}`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.cancelForm = res.data.cancel_form
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
