<template>
  <div v-if="'id' in defDeli">
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn block class="m-1" @click="templateCopy">{{
          $t('btn.template_copy')
        }}</v-btn>
      </v-col>
    </v-row>
    <v-row v-for="(deli, deliIndex) in internalValue">
      <v-col cols="12">
        <v-card outlined>
          <v-card-title>preview</v-card-title>
          <v-card-text>
            <PtLinePreviewRow :deli="internalValue[deliIndex]" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="selectDialog" :max-width="800">
      <PtDeliSelectFixedPhrase
        ref="ptselector"
        v-model="selectFixedPhrase"
        @decide-item="decideItem"
        @cancel-item="cancelItem"
      />
    </v-dialog>
  </div>
</template>
<script>
import PtDeliSelectFixedPhrase from 'components/parts/common/PtDeli/PtDeliSelectFixedPhrase'
import PtLinePreviewRow from 'components/parts/common/PtLinePreviewRow'
export default {
  components: {
    PtDeliSelectFixedPhrase,
    PtLinePreviewRow,
  },
  props: {
    value: { default: null },
  },
  data: () => ({
    defDeli: {},
    allVees: {},
    opts: {},
    deleteTargetIndex: -1,
    selectDialog: false,
    selectFixedPhrase: {},
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const path = '/api/v1/delis/setup'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.defDeli = res.data.deli
          this.allVees = res.data.all_vees
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    templateCopy() {
      this.selectDialog = true
    },
    async decideItem(item) {
      const path = `/api/v1/fixed_phrases/${item.id}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.internalValue = res.data.delis
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.selectDialog = false
        })
    },
    cancelItem() {
      this.selectDialog = false
    },
  },
}
</script>
