<template>
  <validation-observer ref="observer">
    <v-card class="mx-auto mt-8" max-width="800">
      <v-card-title class="text-h4">
        {{ $t('cancel_form.title') }}
      </v-card-title>
      <v-card-text>
        <v-card class="mt-4">
          <v-card-title>
            {{ $t('cancel_form.name') }}
          </v-card-title>
          <v-col md="8">
            {{ $t('cancel_form.desc_name') }}
            <VfTextField
              v-model="cancelForm.name"
              :label="$$tm('cancel_form.name')"
              :vees="vees.name"
            />
          </v-col>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            {{ $t('cancel_form.email') }}
          </v-card-title>
          <v-col md="8">
            {{ $t('cancel_form.desc_email') }}
            <VfTextField
              v-model="cancelForm.email"
              :label="$$tm('cancel_form.email')"
              :vees="vees.email"
            />
          </v-col>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            {{ $t('cancel_form.phone') }}
          </v-card-title>
          <v-col md="8">
            {{ $t('cancel_form.desc_phone') }}
            <VfTextField
              v-model="cancelForm.phone"
              :label="$$tm('cancel_form.phone')"
              :vees="vees.phone"
            />
          </v-col>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>
            {{ $t('cancel_form.line_account_id') }}
          </v-card-title>
          <v-col md="8">
            {{ $t('cancel_form.desc_line_account_id') }}
            <VfTextField
              v-model="cancelForm.line_account_id"
              :label="$$tm('cancel_form.line_account_id')"
              :vees="vees.line_account_id"
            />
          </v-col>
        </v-card>
        <v-card class="mt-4 mb-4">
          <v-card-title>
            {{ $t('cancel_form.plan_name') }}
          </v-card-title>
          <v-col md="8">
            <VfTextField
              v-model="cancelForm.plan_name"
              :label="$$tm('cancel_form.plan_name')"
              :vees="vees.plan_name"
            />
          </v-col>
        </v-card>
        <VfTextArea
          v-model="cancelForm.reason_text"
          :label="$$tm('cancel_form.reason_text')"
          :vees="vees.reason_text"
        />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="sendForm">
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </validation-observer>
</template>
<script>
export default {
  data: () => ({
    cancelForm: {},
    vees: {},
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      const path = '/api/v1/cancel_forms/new'
      await this.axios
        .get(path, this.$$reqPrms())
        .then((res) => {
          this.cancelForm = res.data.cancel_form
          this.vees = res.data.vees
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    async sendForm() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      const path = '/api/v1/cancel_forms'
      const prms = { cancel_form: this.cancelForm }
      this.axios
        .post(path, this.$$reqPrms(prms))
        .then(() => {
          this.$router.push('sent_form')
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
