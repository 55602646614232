<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col md="6" cols="12">
            <v-btn @click="openDialog">
              {{ $t('btn.select_image') }}
            </v-btn>
            <v-card
              v-if="internalValue.preview_url"
              class="mt-4"
              outlined
              tile
              :max-width="200"
            >
              <VImg :src="internalValue.preview_url" />
            </v-card>
          </v-col>
          <v-col md="6" cols="12">
            <v-item-group mandatory>
              <v-row no-gutters>
                <v-col
                  v-for="layout in linkLayouts"
                  :key="layout.value"
                  cols="6"
                  md="2"
                >
                  <v-item v-slot="{ toggle }">
                    <v-card
                      :color="
                        layout.value == internalValue.link_type ? 'primary' : ''
                      "
                      class="pa-1"
                      @click="
                        toggle()
                        selectLayout(layout.value)
                      "
                    >
                      <VImg :src="layout.image" />
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div>{{ $t('lbl.image_link_desc') }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="area in areas" :key="area" cols="12" md="4">
            <v-card>
              <v-card-subtitle>{{ $t('lbl.area') }}{{ area }}</v-card-subtitle>
              <VDivider class="my-0" />
              <v-card-text>
                <VfSelect
                  v-model="areaData[area].image_link_action"
                  :label="$$tm('deli_image_link.image_link_action')"
                  :vees="veeLinks.image_link_action"
                  :selects="imageLinkActions"
                />
                <VfTextField
                  v-if="areaData[area].image_link_action === 'url_open'"
                  v-model="areaData[area].site_url"
                  :label="$$tm('deli_image_link.site_url')"
                  :vees="veeLinks.site_url"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="areas.length > 0">
          <v-col>
            <VfTextArea
              v-model="internalValue.message_for_pc"
              :label="$$tm('deli_image.message_for_pc')"
              :vees="vees.message_for_pc"
              counter
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <PtDeliDialogForSelectFile
      v-model="dialog"
      upload-file-type="image"
      @select-file-done="selectFileDone"
    />
  </div>
</template>
<script>
import PtDeliDialogForSelectFile from 'components/parts/common/PtDeli/PtDeliDialogForSelectFile'
export default {
  components: {
    PtDeliDialogForSelectFile,
  },
  props: {
    value: { type: Object, required: true },
    vees: { type: Object, default: () => ({}) },
    veeLinks: { type: Object, default: () => ({}) },
    linkTypes: { type: Object, required: true },
    imageLinkActions: { type: Object, required: true },
    linkLayouts: { type: Array, required: true },
  },
  data: () => ({
    dialog: false,
    selectedLayout: {},
    areaData: { A: {}, B: {}, C: {}, D: {}, E: {}, F: {} },
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    areas() {
      const rets = ['A', 'B', 'C', 'D', 'E', 'F']
      if (!this.selectedLayout.area) {
        return []
      }
      return rets.slice(0, this.selectedLayout.area)
    },
  },
  created() {
    this.selectedLayout = this.linkLayouts.find((layout) => {
      return layout.value === this.internalValue.link_type
    })
    Object.keys(this.areaData).forEach((str, index) => {
      this.areaData[str] = { image_link_action: 'none', site_url: '' }
      if (this.internalValue.deli_image_links.length > index) {
        this.areaData[str] = this.internalValue.deli_image_links[index]
      } else {
        this.internalValue.deli_image_links.push(this.areaData[str])
      }
    })
  },
  methods: {
    openDialog() {
      this.dialog = true
    },
    selectFileDone(uploadFile) {
      this.internalValue.upload_file_id = uploadFile.id
      this.internalValue.preview_url = uploadFile.image_url
      this.dialog = false
    },
    selectLayout(val) {
      this.internalValue.link_type = val
      this.selectedLayout = this.linkLayouts.find(
        (layout) => layout.value === val
      )
    },
  },
}
</script>
