<template>
  <v-dialog v-model="dialog" :max-width="500">
    <template #activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" @click="$emit('click')">
        {{ $t('btn.staff_create_code') }}
      </v-btn>
    </template>
    <v-card>
      <validation-observer ref="observer">
        <v-form @submit.prevent="save">
          <v-card-title>{{
            $t('lbl.staff.token_create_confirm')
          }}</v-card-title>
          <v-divider class="mt-2 mb-0" />
          <v-card-text>
            <v-row>
              <v-col>
                <VfSelect
                  v-model="internalValue"
                  :label="$$tm('user_channel.channel_role_id')"
                  :selects="selectChannelRoles"
                  :vees="{ rules: 'required' }"
                />
              </v-col>
            </v-row>
            <div class="or-break">{{ $t('confirm.create_channel_token') }}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="dialog = false">
              {{ $t('btn.close') }}
            </v-btn>
            <v-btn type="submit" color="primary">
              {{ $t('btn.create') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: { type: String, required: true },
    selectChannelRoles: { type: Array, required: true },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.dialog = false
      this.$emit('save')
    },
  },
}
</script>
