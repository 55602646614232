<template>
  <v-row>
    <v-col v-if="!hiddenTitle" sm="4" cols="12" xs="12">
      <span class="text-bold">{{ title }}</span>
      <v-chip
        v-if="required"
        class="ma-2"
        label
        color="red"
        text-color="white"
        small
      >
        {{ $t('lbl.answer_form.require') }}
      </v-chip>
    </v-col>
    <v-col sm="8" cols="12" xs="12">
      <VfDateTimeSelect
        v-model="internalValue"
        :label="$$tm('action_reminder.goal_at')"
        :vees="vees"
        format="HH:mm"
        :only-time="true"
      />
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
export default {
  name: 'PtFormRenderTime',
  props: {
    value: { default: null },
    label: { type: String, default: '' },
    title: { type: String, default: '' },
    hint: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    maxLength: { type: Number, default: null },
    hiddenTitle: { default: 0 },
    required: { default: 0 },
  },
  data: function () {
    return {
      loading: false,
      errorMsg: '',
      data: {},
      localValue: '',
    }
  },
  computed: {
    internalValue: {
      get() {
        const timeDefault = this.value || '12:00'
        const dateNow = this.$$fdt(new Date())
        let dateTime = `${dateNow} ${timeDefault}`
        return dateTime
      },
      set(value) {
        this.$emit('input', moment(value).format('HH:mm'))
      },
    },
    vees: function () {
      let rules = {
        rules: '',
      }

      if (this.maxLength) {
        rules.rules += (rules.rules ? '|' : '') + `max:${this.maxLength}`
        rules.max = this.maxLength
      }

      if (this.required) {
        rules.rules += (rules.rules ? '|' : '') + 'required'
        rules.required = true
      }

      return rules
    },
  },
  created() {},
}
</script>

<style scoped lang="scss">
::v-deep {
  fieldset > legend {
    display: none;
  }

  .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }

  .v-label {
    display: none;
  }
}
</style>
