<template>
  <div>
    <Picker :sheet-size="32" @select="changeEmoji" />
  </div>
</template>
<script>
import { Picker } from 'emoji-mart-vue'
export default {
  components: {
    Picker,
  },
  data() {
    return {
      emoji: '',
    }
  },
  methods: {
    changeEmoji(emoji) {
      this.emoji = emoji
      this.$emit('insertEmoji', this.emoji)
    },
  },
}
</script>
