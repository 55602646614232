<template>
  <div>
    <v-row>
      <v-col>
        <PtTitle :title="friend.name" />
        <v-btn :disabled="friend.hidden_flg">
          <router-link
            class="text-decoration-none black--text"
            :to="{ name: 'UnicastIndex', query: { id: friend.id } }"
          >
            {{ $t('btn.unicast_screen') }}
          </router-link>
        </v-btn>
        <v-row v-if="friend.hidden_flg">
          <v-col class="hidden_caution">
            {{ $t('lbl.friend.hidden_caution') }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="pt-0">
        <PtFriendShowBase v-model="friend" :opts="opts" :vees="vees" />
      </v-col>
      <v-col md="6" class="pt-0">
        <PtFriendShowAction v-model="friend" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtFriendShowBase from 'components/parts/unique/PtFriendShowBase'
import PtFriendShowAction from 'components/parts/unique/PtFriendShowAction'
export default {
  components: {
    PtFriendShowBase,
    PtFriendShowAction,
  },
  data: () => ({
    firstInit: false,
    friendId: '',
    friend: {},
    vees: {},
    opts: {},
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.firstInit = false
      this.friendId = this.$route.params.id
      const path = `/api/v1/friends/${this.friendId}`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.friend = res.data.friend
          if (!this.friend.active_scenario) {
            this.friend.active_scenario = {}
          }
          if (!this.friend.rich_menu) {
            this.friend.rich_menu = {}
          }
          this.vees = res.data.vees
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.firstInit = true
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.hidden_caution {
  font-size: 12px;
  color: #ff0000;
}
</style>
