<template>
  <div>
    <PtTitle />
    <v-row>
      <v-col md="4">
        <VfDatePicker key="from" v-model="stDt" label="from" />
      </v-col>
      <v-col md="4">
        <VfDatePicker key="to" v-model="edDt" label="to" />
      </v-col>
      <v-col md="4">
        <v-btn @click="search">
          <v-icon>mdi-magnify</v-icon>
          表示
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <PtFriendReportChart :items="items" />
    </v-card>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-data-table
        :headers="headers"
        :items="items"
        :disable-sort="true"
        hide-default-footer
        :items-per-page="-1"
      >
        <template #[`item.block_count`]="{ item }">
          <router-link
            :to="{
              name: 'FriendIndex',
              params: { blocked_at: item.report_dt },
            }"
          >
            {{ item.block_count }}
          </router-link>
        </template>
        <template #[`item.report_dt`]="{ item }">
          {{ $$fdt(item.report_dt) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import PtFriendReportChart from 'components/parts/unique/PtFriendReportChart'
export default {
  components: {
    PtFriendReportChart,
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    stDt: '',
    edDt: '',
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      await this.getReport()
    },
    async search() {
      this.loading = true
      await this.getReport(this.stDt, this.edDt)
    },
    async getReport(st, ed) {
      const path = '/api/v1/friend_reports'
      await this.axios
        .get(path, { params: this.$$reqPrms({ st_dt: st, ed_dt: ed }) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.friend_reports
          this.stDt = res.data.st_dt
          this.edDt = res.data.ed_dt
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
