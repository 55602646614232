<template>
  <div>
    <v-row>
      <v-col class="pb-0">
        <VfTextField
          v-model="internalValue.name"
          :label="$$tm('search_name.name')"
          :vees="vees.name"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <VfCheckbox
          v-model="internalValue.api_display_name_flg"
          class="float-left my-0 mr-6"
          :label="$$tm('search_name.api_display_name_flg')"
        />
        <VfCheckbox
          v-model="internalValue.system_disp_name_flg"
          class="float-left my-0 mr-6"
          :label="$$tm('search_name.system_disp_name_flg')"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    opts: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
