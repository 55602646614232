<template>
  <validation-provider v-slot="{ errors }" :name="label" :rules="rules">
    <v-img v-if="preview" :src="previewUrl" :height="height" :width="width" />
    <v-file-input
      :label="label"
      :error-messages="errors"
      :disabled="disabled"
      :readonly="readonly"
      @change="onFileChange"
    />
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: { default: null },
    label: { type: String, required: true },
    vees: { type: Object, default: () => ({}) },
    height: { type: Number, default: null },
    width: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    preview: { type: Boolean, default: true },
  },
  data: () => ({
    previewUrl: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    rules() {
      if (!this.vees) {
        return null
      }
      return (this.vees || {}).rules
    },
  },
  watch: {
    value() {
      this.init()
    },
  },
  created: function () {
    this.init()
  },
  methods: {
    init() {
      this.previewUrl = this.value ? this.value.url : ''
    },
    onFileChange(file) {
      this.createImage(file)
      this.internalValue = file
    },
    createImage(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.previewUrl = e.target.result
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>
