<template>
  <v-card>
    <v-card-title>
      {{ $t('notificator.notify') }}
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent="$emit('save')">
        <v-row>
          <v-col>
            <v-select
              v-model="internalValue.channel_id"
              :label="$t('notificator.channel')"
              :items="channels"
              item-text="name"
              item-value="id"
              :disabled="edit"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="internalValue.notice_type"
              :label="$t('notificator.timing')"
              :items="noticeTypes"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="pb-0">
                <h6>{{ $t('notificator.classification') }}</h6>
              </v-card-title>
              <v-card-text>
                <VfCheckbox
                  v-model="internalValue.normal_flg"
                  :label="$t('notificator.receive')"
                  hide-details
                />
                <VfCheckbox
                  v-model="internalValue.auto_answer_flg"
                  :label="$t('notificator.auto_answer')"
                  hide-details
                />
                <VfCheckbox
                  v-model="internalValue.action_flg"
                  :label="$t('notificator.action')"
                  hide-details
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="right">
            <v-btn color="primary" type="submit">
              {{ $t('btn.create') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    channels: { type: Array, required: true },
    vees: { type: Object, required: true },
    noticeTypes: { type: Array, required: true },
    edit: { type: Boolean, default: false },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
