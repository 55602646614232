<template>
  <div>
    <PtTitle />
    <div class="or-break mb-4">{{ $t('subtitle.line_notice') }}</div>
    <notify-form
      v-model="lineNotice"
      class="mb-4"
      :channels="channels"
      :vees="vees"
      :notice-types="opts.notice_type"
      @save="save"
    />
    <setting-table
      class="mb-4"
      :line-notices="lineNotices"
      @click:row="toEdit"
      @click:testNotification="sendTestNotification"
    />
    <account-table :channels="channelsWithCount" @click:row="toShow" />
  </div>
</template>
<script>
import NotifyForm from './Common/NotifyForm'
import SettingTable from './Index/SettingTable'
import AccountTable from './Index/AccountTable'
export default {
  components: {
    NotifyForm,
    SettingTable,
    AccountTable,
  },
  data: () => ({
    channels: [],
    channelsWithCount: [],
    lineNotices: [],
    lineNotice: {
      channel_id: '',
      notice_type: 'all',
      normal_flg: 1,
      auto_answer_flg: 1,
      action_flg: 1,
    },
    vees: {},
    opts: { notice_type: [] },
  }),
  created() {
    this.init()
    this.getNotificators()
  },
  methods: {
    init() {
      this.lineNotice.channel_id = this.$store.getters.channel?.id
    },
    getNotificators() {
      this.$$loadingOverlay(true)
      const path = '/api/v1/notificator'
      this.axios
        .get(path, { params: {} })
        .then((res) => {
          this.channels = res.data.channels
          this.channelsWithCount = res.data.channels_with_count
          this.lineNotices = res.data.line_notices
          this.vees = res.data.vees
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlay(false)
        })
    },
    save() {
      this.$$loadingOverlay(true)
      const path = '/api/v1/notificator'
      const params = { line_notice: this.lineNotice }
      this.$$sendForm('post', path, params)
        .then((res) => {
          window.location.href = res.data.redirect_url
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlay(false)
        })
    },
    sendTestNotification(item) {
      this.$$loadingOverlay(true)
      const path = '/api/v1/notificator/test_send'
      const params = { id: item.id }
      this.$$sendForm('post', path, params)
        .then(() => {
          this.$toasted.success(this.$t('lbl.send_message_done'))
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing') /* start child component */
          this.$$loadingOverlay(false)
        })
    },
    toEdit(itemId) {
      this.$router.push({ name: 'NotificatorForm', params: { id: itemId } })
    },
    toShow(itemId) {
      this.$router.push({ name: 'NotificatorShow', params: { id: itemId } })
    },
  },
}
</script>
