<template>
  <v-dialog v-model="internalValue" :max-width="350">
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title> プレビュー </v-card-title>
      <v-card-text>
        <PtLinePreview :delis="delis" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import PtLinePreview from 'components/parts/common/PtLinePreview'
export default {
  components: {
    PtLinePreview,
  },
  props: {
    value: { type: Boolean, required: true },
    type: { type: String, required: true },
    modelId: { type: String, required: true },
  },
  data: () => ({
    loading: false,
    delis: [],
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (!this.modelId) {
        return
      }
      this.loading = true
      const path = `/api/v1/delis/${this.modelId}/preview`
      const prms = { type: this.type }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then((res) => {
          this.delis = res.data.delis
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
