var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(!_vm.hiddenTitle)?_c('v-col',{attrs:{"sm":"4","cols":"12","xs":"12"}},[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),(_vm.required)?_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","color":"red","text-color":"white","small":""}},[_vm._v("\n      "+_vm._s(_vm.$t('lbl.answer_form.require'))+"\n    ")]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('v-col',{attrs:{"sm":"8","cols":"12","xs":"12"}},[_c('validation-provider',{attrs:{"name":_vm.label,"rules":_vm.localVees.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0 vf-radio-group",attrs:{"error-messages":errors,"disabled":_vm.disabled,"readonly":_vm.readonly,"label":_vm.title,"selects":_vm.selects,"column":_vm.column,"row":_vm.row,"hint":_vm.hint,"persistent-hint":true,"no-label":"","label-hidden":""},on:{"input":function($event){return _vm.$emit('input', _vm.localData)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-none"})]},proxy:true}],null,true),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},[_vm._v(" "),_vm._l((_vm.inputOptions),function(option){return [_c('VRadio',{staticClass:"vf-checkbox",attrs:{"label":_vm.isDisabled(option)
                ? option.capacity_warning ||
                  _vm.$t('lbl.answer_form.can_not_select') + option.value
                : option.value,"disabled":_vm.isDisabled(option),"value":option.id,"color":_vm.color}}),_vm._v(" "),(
              _vm.localValue === option.id &&
              option.id !== 'no_select' &&
              option.is_other_flg
            )?_c('VfTextField',{staticClass:"vf-hidden-label pt-0",attrs:{"label":_vm.title,"placeholder":'',"hint":''},model:{value:(_vm.customOptions[option.id]),callback:function ($$v) {_vm.$set(_vm.customOptions, option.id, $$v)},expression:"customOptions[option.id]"}}):_vm._e()]}),_vm._v(" "),(!_vm.required)?_c('VRadio',{staticClass:"vf-checkbox",attrs:{"label":_vm.$t('lbl.answer_form.do_not_select'),"value":'no_select'}}):_vm._e()],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }