<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle />
      </v-col>
      <v-col cols="4" align="right" class="pt-8">
        <v-btn v-if="!isNewRecord" color="error" @click="destroy">
          {{ $t('btn.delete') }}
        </v-btn>
      </v-col>
    </v-row>
    <validation-observer ref="observer">
      <v-form @submit.prevent="save">
        <v-card class="pa-4">
          <v-card-text>
            <vf-text-field
              v-model="info.title"
              :label="$$tm('admin_info.title')"
              :vees="vees.title"
            />
            <vf-text-field
              v-model="info.subtitle"
              :label="$$tm('admin_info.subtitle')"
              :vees="vees.subtitle"
              counter
            />
            <VfTextArea
              v-model="info.desc"
              :label="$$tm('admin_info.desc')"
              :vees="vees.desc"
              counter
            />
            <VfDateTimeSelect
              v-model="info.disp_st_at"
              :label="$$tm('admin_info.disp_st_at')"
              :vees="vees.disp_st_at"
              format="YYYY/MM/DD HH:mm"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn color="primary" type="submit">
                {{ $t('btn.save') }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-form>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
export default {
  data: () => ({
    info: {},
    vees: {},
    opts: {},
    deleteDialog: false,
  }),
  computed: {
    infoId() {
      return this.$route.params.id
    },
    isNewRecord() {
      return this.infoId === 'new'
    },
    savePath() {
      return `/api/v1/admin_infos/${this.infoId}`
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      const path = this.isNewRecord
        ? '/api/v1/admin_infos/new'
        : `/api/v1/admin_infos/${this.infoId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.info = res.data.info
          this.vees = res.data.vees
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.$$loadingOverlayContent(true)
      const path = this.isNewRecord
        ? '/api/v1/admin_infos'
        : `/api/v1/admin_infos/${this.infoId}`
      const method = this.isNewRecord ? 'post' : 'put'
      const prms = { admin_info: this.info }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'AdminInfosIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    destroy() {
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'AdminInfosIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
