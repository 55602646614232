<template>
  <v-dialog v-model="dialog" :max-width="500">
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        small
        v-on="on"
        @click="$emit('click')"
      >
        {{ $t('btn.active') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t('confirm_title.plan_active') }}</v-card-title>
      <v-divider class="mt-1 mb-0" />
      <v-card-text class="pt-6">
        <div class="or-break">{{ $t('confirm.plan_active') }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false">
          {{ $t('btn.close') }}
        </v-btn>
        <v-btn color="primary" @click="save">
          {{ $t('btn.active') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
  }),
  methods: {
    save() {
      this.dialog = false
      this.$emit('save')
    },
  },
}
</script>
