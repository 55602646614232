<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle />
      </v-col>
    </v-row>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-col md="8">
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          clearable
          single-line
          hide-details
        />
      </v-col>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :disable-sort="true"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150],
        }"
        @click:row="rowClick"
      >
        <template #[`item.admin_created_flg`]="{ item }">
          {{ $t(`lbl.account.admin_created_flg.${item.admin_created_flg}`) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    search: '',
  }),
  watch: {
    search() {
      this.searchFriends()
    },
  },
  methods: {
    rowClick(item) {
      this.$router.push({ name: 'AdminFriendsShow', params: { id: item.id } })
    },
    searchFriends() {
      this.loading = true
      const path = '/api/v1/admin/friends/'
      const prms = {
        search_word: this.search,
      }
      this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.friends
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
