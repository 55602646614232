<template>
  <v-row>
    <v-col v-if="!hiddenTitle" sm="4" cols="12" xs="12">
      <span class="text-bold">{{ title }}</span>
      <v-chip
        v-if="required"
        class="ma-2"
        label
        color="red"
        text-color="white"
        small
      >
        {{ $t('lbl.answer_form.require') }}
      </v-chip>
    </v-col>
    <v-col sm="8" cols="12" xs="12">
      <validation-provider
        v-slot="{ errors }"
        :name="label"
        :rules="localVees.rules"
      >
        <v-radio-group
          v-model="localValue"
          :error-messages="errors"
          :disabled="disabled"
          :readonly="readonly"
          :label="title"
          :selects="selects"
          :column="column"
          :row="row"
          :hint="hint"
          :persistent-hint="true"
          no-label
          label-hidden
          class="mt-0 vf-radio-group"
          @input="$emit('input', localData)"
        >
          <template #label>
            <div class="d-none" />
          </template>
          <template v-for="option in inputOptions">
            <VRadio
              class="vf-checkbox"
              :label="
                isDisabled(option)
                  ? option.capacity_warning ||
                    $t('lbl.answer_form.can_not_select') + option.value
                  : option.value
              "
              :disabled="isDisabled(option)"
              :value="option.id"
              :color="color"
            />
            <VfTextField
              v-if="
                localValue === option.id &&
                option.id !== 'no_select' &&
                option.is_other_flg
              "
              v-model="customOptions[option.id]"
              class="vf-hidden-label pt-0"
              :label="title"
              :placeholder="''"
              :hint="''"
            />
          </template>
          <VRadio
            v-if="!required"
            class="vf-checkbox"
            :label="$t('lbl.answer_form.do_not_select')"
            :value="'no_select'"
          />
        </v-radio-group>
      </validation-provider>
    </v-col>
  </v-row>
</template>

<script>
import mixin from 'components/vuetifyParts/commonExport'
export default {
  name: 'PtFormRenderRadio',
  mixins: [mixin],
  props: {
    value: { default: null },
    label: { type: String, default: '' },
    title: { type: String, default: '' },
    hint: { type: String, default: '' },
    color: { type: String, default: null },
    placeholder: { type: String, default: '' },
    inputOptions: { type: Array, default: () => [] },
    optionCount: { type: Object, default: () => ({}) },
    friend: { type: Object, default: () => ({}) },
    row: { type: Boolean, default: true },
    column: { type: Boolean, default: false },
    hiddenTitle: { default: 0 },
    required: { default: 0 },
  },
  data: function () {
    return {
      errorMsg: '',
      data: {},
      checkedOptions: [],
      localValue: '',
      customOptions: {},
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    localData: function () {
      if (this.localValue === 'no_select') {
        return [[]]
      } else {
        const input = this.inputOptions.find((option) => {
          return option.id === this.localValue
        })

        if (input && input.is_other_flg) {
          return [[this.localValue, this.customOptions[this.localValue]]]
        } else {
          return [this.localValue]
        }
      }
    },
    selects: function () {
      let list = {}
      this.inputOptions.forEach((option) => {
        list[option.value] = option.id
      })

      return list
    },
    localVees: function () {
      let rules = {
        rules: '',
      }

      if (this.maxLength) {
        rules.rules += (rules.rules ? '|' : '') + `max:${this.maxLength}`
        rules.max = this.maxLength
      }

      if (this.required) {
        rules.rules += (rules.rules ? '|' : '') + 'required'
        rules.required = true
      }

      return rules
    },
  },
  watch: {
    localValue: function () {
      this.$emit('input', this.localData)
    },
    customOptions: {
      handler() {
        this.$emit('input', this.localData)
      },
      deep: true,
    },
  },
  created() {
    this.initLocalValue()
  },
  methods: {
    initLocalValue() {
      if (
        !this.value ||
        (Array.isArray(this.value) && this.value.length === 0)
      ) {
        this.inputOptions.forEach((option) => {
          if (this.localValue) return
          if (option.default_type === 'selected' && !this.isDisabled(option)) {
            this.localValue = option.id
          } else if (
            option.default_type === 'only_with_tag' &&
            !this.isDisabled(option) &&
            this.friend &&
            this.friendHasDefaultTag(this.friend.hashtags, option.default_tag)
          ) {
            this.localValue = option.id
          } else if (
            option.default_type === 'only_without_tag' &&
            !this.isDisabled(option) &&
            this.friend &&
            !this.friendHasDefaultTag(this.friend.hashtags, option.default_tag)
          ) {
            this.localValue = option.id
          }

          this.$set(this.customOptions, option.id, '')
        })
      } else {
        let clone = this.$$clone(this.value)
        this.inputOptions.forEach((option) => {
          //vue Reactivity in Depth
          this.$set(this.customOptions, option.id, '')
        })
        if (typeof this.value[0] === 'object') {
          this.localValue = clone[0][0]
          this.$set(this.customOptions, clone[0][0], clone[0][1])
        } else if (typeof this.value[0] === 'string') {
          this.localValue = clone[0]
        } else {
          this.localValue = ''
        }
      }
    },
    checkCapacity(optionId) {
      if (
        this.optionCount?.[optionId] &&
        this.optionCount[optionId].capacity !== null
      ) {
        return (
          this.optionCount[optionId].select_count >=
          this.optionCount[optionId].capacity
        )
      } else {
        return false
      }
    },
    isDisabled(option) {
      return this.checkCapacity(option.id) && option.limit_type === 'use'
    },
    friendHasDefaultTag(friendTags, defaultTag) {
      if (Array.isArray(friendTags)) {
        return friendTags.some((tag) => {
          return tag.id === defaultTag?.id
        })
      } else {
        return false
      }
    },
  },
}
</script>
<style scoped lang="scss">
.vf-hidden-label ::v-deep {
  .v-input {
    padding-top: 0;
  }
  fieldset > legend {
    display: none;
  }
  .v-label {
    display: none;
  }
}
.vf-radio-group ::v-deep .v-label {
  margin-top: 10px;
}
.vf-checkbox ::v-deep .v-label {
  margin-top: 10px;
}
</style>
