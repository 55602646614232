<template>
  <v-dialog v-model="internalValue" :max-width="500">
    <v-form @submit.prevent="save">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-text-field
                v-model="scenario_name"
                :label="$$tm('scenario.name')"
                required
              />
              <v-alert v-if="error" type="error">
                {{ error }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="close">{{ $t('btn.cancel') }}</v-btn>
          <v-btn
            type="submit"
            color="primary"
            :loading="$wait.is('processing')"
            >{{ $t('btn.create') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    scenarioCopyId: { type: String, required: true },
  },
  data: () => ({
    scenario_name: '',
    error: null,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    internalValue(val) {
      if (!val) {
        this.$emit('clear-copy-id')
        this.scenario_name = ''
        this.error = null
      }
    },
  },
  methods: {
    close() {
      this.internalValue = false
    },
    async save() {
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      await this.$$sendForm('post', `/api/v1/scenarios/copy`, {
        id: this.scenarioCopyId,
        name: this.scenario_name,
      })
        .then((res) => {
          this.$router.push({
            name: 'ScenarioForm',
            params: { id: res.data.scenario.id },
          })
        })
        .catch((error) => {
          this.error = error.response?.data || error
        })
        .finally((res) => {
          this.$wait.end('processing')
        })
    },
  },
}
</script>
