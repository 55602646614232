<template>
  <v-dialog v-model="dialog" :max-width="500">
    <v-card>
      <v-card-title>
        {{ $t('lbl.credit_card.default_card') }}
      </v-card-title>
      <v-divider class="mt-0" />
      <v-card-text>
        <div class="or-break">
          {{ $t('lbl.credit_card.default_card_desc') }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel">
          {{ $t('btn.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="save">
          {{ $t('btn.update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: { type: Boolean, required: true },
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    save() {
      this.dialog = false
      this.$emit('save')
    },
    cancel() {
      this.dialog = false
    },
  },
}
</script>
