<template>
  <v-card>
    <v-card-title>
      {{ $t('notificator.account') }}
    </v-card-title>
    <v-divider class="my-0" />
    <v-data-table
      :headers="headers"
      :items="channels"
      :items-per-page="-1"
      disable-sort
      hide-default-footer
    >
      <template #[`item.name`]="{ item }">
        <a @click="$emit('click:row', item.id)">
          {{ item.name }}
        </a>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    channels: { type: Array, required: true },
  },
  data: () => ({
    headers: [
      { text: 'チャネル名', value: 'name' },
      { text: '通知先数', value: 'public_line_notices_count' },
    ],
  }),
}
</script>
