<template>
  <div>
    <template>
      <div class="mb-4">
        <v-btn @click="openDialog">{{ $t('lbl.action_group.edit') }}</v-btn>
        <v-btn @click="clearConfirm">{{ $t('lbl.action_group.clear') }}</v-btn>
        <v-btn @click="openTemplateDialog">{{
          $t('lbl.action_group.template')
        }}</v-btn>
      </div>
      <v-card v-if="actionMsgs.length">
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-left" width="200">{{ $t('lbl.type') }}</th>
                <th class="text-left">{{ $t('lbl.actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="msg in actionMsgs" :key="msg.no">
                <td>{{ msg.type }}</td>
                <td>{{ msg.operate }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-dialog v-model="dialog">
        <v-card>
          <v-card-title>
            {{ $t('lbl.action_group.setting') }}
          </v-card-title>
          <v-container>
            <validation-observer ref="childObserver">
              <PtActionGroup
                :hide-timing-type-select="hideTimingTypeSelect"
                v-model="clone"
                :msg-hidden-flg="msgHiddenFlg"
              />
            </validation-observer>
          </v-container>
          <VDivider />
          <v-card-actions>
            <VSpacer />
            <v-btn color="error" @click="dialog = false">{{
              $t('btn.cancel')
            }}</v-btn>
            <v-btn color="primary" @click="decide">{{
              $t('btn.decide')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="clearDialog" max-width="300">
        <v-card>
          <v-card-title>{{
            $t('lbl.action_group.clear_confirm_title')
          }}</v-card-title>
          <v-card-text>
            <div class="or-break">
              {{ $t('lbl.action_group.clear_confirm_desc') }}
            </div>
          </v-card-text>
          <v-card-actions>
            <VSpacer />
            <v-btn @click="clearDialog = false">{{ $t('btn.cancel') }}</v-btn>
            <v-btn color="error" @click="clearAction">{{
              $t('lbl.action_group.clear')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="templateDialog" max-width="1200">
        <v-card>
          <VfLoadingOverlayAbsolute :loading="loading" />
          <v-card-title>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <VfSelect
                      v-model="selectedId"
                      :label="$$tm('action_template.name')"
                      :selects="selects"
                      readonly
                      icon
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="4">
                <PtFolder
                  ref="folder"
                  folder-type="action_template"
                  select-only
                  @select-folder="selectFolder"
                />
              </v-col>
              <v-col md="8">
                <v-data-table
                  v-model="activeTemplateClone"
                  :headers="headers"
                  :items="items"
                  item-key="id"
                  show-select
                  :single-select="true"
                  :disable-sort="true"
                  :footer-props="{
                    itemsPerPageOptions: [10, 30, 50, 100],
                  }"
                  @click:row="rowClick"
                >
                  <template #[`item.action_group`]="{ item }">
                    <PtActionLabelMessages
                      v-model="item.action_group.make_action_arr"
                      class="or-list"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <VSpacer />
            <v-btn color="error" @click="templateDialog = false">{{
              $t('btn.cancel')
            }}</v-btn>
            <v-btn color="primary" @click="decideTemplate">{{
              $t('btn.decide')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>
<script>
import PtActionGroup from 'components/parts/common/PtActionGroup/PtActionGroup'
import PtFolder from 'components/parts/common/PtFolder'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
import {
  validateMultipleDeliText,
  validateMultipleUrlDeliText,
} from 'services/deliText'
export default {
  components: {
    PtActionGroup,
    PtFolder,
    PtActionLabelMessages,
  },
  props: {
    hideTimingTypeSelect: { type: Boolean, default: false },
    value: { default: null },
    msgHiddenFlg: { default: false },
  },
  data: () => ({
    dialog: false,
    clearDialog: false,
    templateDialog: false,
    loading: false,
    opts: {},
    clone: [],
    headers: [],
    items: [],
    activeTemplateClone: [],
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    actionMsgs() {
      return this.internalValue.map((action, index) => {
        const yml = `lbl.action_group.action_types_short_msg.${action.type}`
        const ret = {}
        ret.no = index + 1
        switch (action.type) {
          case 'broadcast':
            ret.type = this.$t(`${yml}`)
            ret.id = action.data.broadcast_id
            ret.operate = ''
            action.data.target_delis.forEach((deli) => {
              switch (deli.deli_type) {
                case 'text':
                  ret.operate += `[${this.$$tranc(deli.deli_text.text)}]`
                  break
                default:
                  ret.operate += `[${this.$$te('deli.deli_type', deli)}]`
                  break
              }
            })
            break
          case 'fixed_phrase':
            ret.type = this.$t(`${yml}`)
            ret.operate = `[${action.data.fixed_phrase?.name ?? ''}]`
            break
          case 'hashtag':
            ret.type = this.$t(`${yml}.${action.data.hashtag_action_type}`)
            ret.operate = `${action.data.hashtags
              .map((row) => `[${row['name']}]`)
              .join('')}`
            break
          case 'scenario':
            ret.type = this.$t(`${yml}.${action.data.scenario_action_type}`)
            if (action.data.scenario_action_type == 'start') {
              ret.operate = `[${action.data.scenario.name}]`
            }
            break
          case 'reminder':
            ret.type = this.$t(`${yml}.${action.data.reminder_action_type}`)
            ret.operate = `[${action.data.reminder?.name ?? ''}]`
            break
          case 'label': {
            ret.type = this.$t(`${yml}`)
            const cols = ['change_message_status', 'change_friend_status']
            ret.operate = ''
            cols.forEach((col) => {
              if (action.data[col] != 'none') {
                const target = `action_label.${col}`
                ret.operate += `[${this.$$tm(target)}: ${this.$$te(
                  target,
                  action.data
                )}]`
              }
            })
            break
          }
          case 'notice':
            ret.type = this.$t(`${yml}`)
            ret.operate = `[${this.$$tranc(action.data.text)}]`
            break
          case 'rich_menu':
            ret.type = this.$t(`${yml}.${action.data.rich_menu_action_type}`)
            if (action.data.rich_menu_action_type == 'start') {
              ret.operate = `[${action.data.rich_menu.name}]`
            }
            break
        }
        return ret
      })
    },
    selectedId() {
      if (this.activeTemplateClone.length === 0) {
        return ''
      }
      return this.activeTemplateClone[0].id
    },
    selects() {
      if (this.activeTemplateClone.length === 0) {
        return {}
      }
      return {
        [this.activeTemplateClone[0].name]: this.activeTemplateClone[0].id,
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {},
    openDialog() {
      this.dialog = true
      this.clone = this.$$clone(this.internalValue)
    },
    async decide() {
      const isValid = await this.$refs.childObserver.validate()
      if (!isValid) {
        if (this.clone.length > 0 && !validateMultipleUrlDeliText(this.clone)) {
          return this.$toasted.error(this.$t('validation.length_of_url'))
        } else {
          return this.$toasted.error(this.$t('danger.params'))
        }
      }
      if (
        this.clone.length > 0 &&
        this.clone[0].type === 'broadcast' &&
        !validateMultipleDeliText(this.clone[0].data.target_delis)
      ) {
        return this.$toasted.error(this.$t('danger.deli_text.format_invalid'))
      }

      this.internalValue = this.clone
      this.$emit('pt-action-selected-list-save', this.clone)
      this.dialog = false
    },
    async decideTemplate() {
      this.internalValue =
        this.activeTemplateClone[0].action_group.make_action_arr
      this.$emit('pt-action-selected-list-save', this.internalValue)
      this.templateDialog = false
    },
    clearConfirm() {
      this.clearDialog = true
    },
    clearAction() {
      this.internalValue = []
      this.$emit('pt-action-selected-list-clear', [])
      this.clearDialog = false
    },
    selectFolder(folderId) {
      this.initTable(folderId)
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
    openTemplateDialog() {
      this.templateDialog = true
      this.initTable(null)
    },
    async initTable(folderId) {
      this.loading = true
      const path = '/api/v1/action_templates'
      const prms = { folder_id: folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.action_templates
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
