<template>
  <div>
    <validation-observer ref="observer">
      <v-card class="mx-auto px-12 py-6" max-width="800">
        <v-form @submit.prevent="save">
          <v-card-title>
            <v-row justify="center">
              <div>{{ $t(`meta.title.${$route.name}`) }}</div>
            </v-row>
          </v-card-title>
          <VDivider class="mx-4" />
          <v-card color="grey lighten-4" class="mt-8 mb-4 text-center">
            <v-card-text>
              <div class="or-break">{{ $t('lbl.create_line_account_id') }}</div>
              <!-- <v-btn
                class='my-4'
                color='white'
                @click='$toasted.show("リンク設置予定")'
              >{{$t('lbl.how_channel_setting')}}</v-btn> -->
            </v-card-text>
          </v-card>
          <v-card-text v-if="channel">
            <v-row>
              <v-col md="12">
                <VfTextField
                  v-model="channel.line_channel_id"
                  :label="$$tm('channel.line_channel_id')"
                  :vees="vees.line_channel_id"
                />
              </v-col>
              <v-col md="12">
                <VfTextField
                  v-model="channel.line_channel_secret_id"
                  :label="$$tm('channel.line_channel_secret_id')"
                  :vees="vees.line_channel_secret_id"
                  password
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" color="primary">{{ $t('btn.create') }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </validation-observer>
  </div>
</template>
<script>
export default {
  data: () => ({
    channel: {},
    vees: {},
    opts: {},
  }),
  created: function () {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/channel/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.channel = res.data.channel
          this.vees = res.data.vees
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/channel`
      const prms = { channel: this.channel }
      await this.$$sendForm('put', path, this.$$reqPrms(prms))
        .then(() => {
          this.$router.push({ name: 'RegistrationWebhook' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
