const CORRECT_PATTERN = /\${3}([^${3}]+[\s\S])\${3}/g
const PARTIAL_CORRECT_PATTERN = /\${3}(.*?)\${3}/g
const EXTENSION_CORRECT_PATTERN = /\${3}(name|today[-|+]\d+|today)\${3}/g
const NO_SPECIAL_CHARACTERS = /[^a-zA-Z0-9]/g
const ONLY_CHARACTERS = /[a-zA-Z]/
const DUPLICATE_CHARACTERS = /(.)(?=.*\1)/g

export function validateSingleDeliText(text) {
  if (!text) return false
  if (!text.match(ONLY_CHARACTERS)) return true
  if (!validate(text.match(CORRECT_PATTERN))) return false
  if (!validate(text.replace(/ /g, '').match(CORRECT_PATTERN))) return false
  if (!validate(text.match(PARTIAL_CORRECT_PATTERN))) return false
  return true
}

export function validate(variables) {
  if (variables) {
    const foundInvalid = variables.filter((val) => {
      if (/today/.test(val) || /name/.test(val)) {
        if (val.replace(EXTENSION_CORRECT_PATTERN, '').match(ONLY_CHARACTERS))
          return val
      } else {
        let lowerCaseVal = val.replace(NO_SPECIAL_CHARACTERS, '').toLowerCase()
        if (/today/.test(lowerCaseVal) || /name/.test(lowerCaseVal)) return val
        if (!/today/.test(val) && !/name/.test(val)) {
          let duplicateFormat = val.replace(DUPLICATE_CHARACTERS, '')
          if (/today/.test(duplicateFormat) || /name/.test(duplicateFormat))
            return val
        }
      }
    })
    return foundInvalid.length == 0
  }
  return true
}

export function validateMultipleDeliText(delis) {
  const foundInvalid = delis.filter((deli) => {
    if (deli.deli_type === 'text')
      return !validateSingleDeliText(deli.deli_text.text)
  })
  return foundInvalid.length == 0
}

export function validateUrlDeliText(delis) {
  return !(delis.match(/https?:\/\/[^\s]*/g) || []).find((o) => o.length > 2048)
}

export function validateMultipleUrlDeliText(array) {
  let delis = []
  if (array[0].data != undefined && array[0].data.target_delis != undefined) {
    array.forEach((o) => {
      delis = delis.concat(o.data.target_delis)
    })
  } else {
    delis = array
  }
  const foundInvalid = delis.filter((deli) => {
    if (deli.deli_type === 'text')
      return !validateUrlDeliText(deli.deli_text.text)
  })
  return foundInvalid.length == 0
}
