<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <GmapAutocomplete style="width: 500px" @place_changed="setPlace" />
        <v-row>
          <v-col>
            <GmapMap
              ref="map"
              :center="center"
              :zoom="zoom"
              :options="{
                mapTypeControl: false,
                streetViewControl: false,
              }"
              map-type-id="terrain"
              style="width: 100%; height: 300px"
              @center_changed="onCenterChanged"
            >
              <GmapMarker
                v-show="center"
                :position="location_center"
                :clickable="true"
                :draggable="false"
              />
            </GmapMap>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <VfTextField
              v-model="internalValue.title"
              :label="$$tm('deli_location.title')"
              :vees="vees.title"
              counter
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="getAddress">住所を取得</v-btn>
          </v-col>
          <v-col>
            <p>{{ address }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <VfTextArea
              v-model="internalValue.address"
              :label="$$tm('deli_location.address')"
              :vees="vees.address"
              counter
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    center: {},
    location_center: {},
    zoom: 14,
    geocoder: {},
    address: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    location_center: {
      handler: function (val) {
        this.internalValue.latitude = val.lat
        this.internalValue.longitude = val.lng
      },
      deep: true,
    },
    address: function (val) {
      this.internalValue.address = val
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (this.internalValue.latitude) {
        this.center = {
          lat: Number(this.internalValue.latitude),
          lng: Number(this.internalValue.longitude),
        }
      } else {
        this.center = { lat: 35.6812362, lng: 139.7649361 }
      }
      this.location_center = this.$$clone(this.center)
    },
    setPlace(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.location_center = this.$$clone(this.center)
      this.internalValue.address = place.formatted_address
    },
    onCenterChanged(center) {
      this.location_center = { lat: center.lat(), lng: center.lng() }
    },
    getAddress() {
      let self = this
      $.ajax({
        url: 'https://maps.googleapis.com/maps/api/geocode/json',
        data: {
          latlng: `${this.location_center.lat},${this.location_center.lng}`,
          sensor: false,
          key: 'AIzaSyDjIaM4rYBL1Y2gtKsrdcKw6Jo8IOpF4gg',
        },
      })
        .done(function (results) {
          self.internalValue.address =
            results.results[0].formatted_address.replace(/^日本、/, '')
        })
        .fail(function () {})
    },
  },
}
</script>
