var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('PtTitle')],1),_vm._v(" "),_c('v-col',{staticClass:"pt-8",attrs:{"cols":"4","align":"right"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.newItem}},[_vm._v(_vm._s(_vm.$t('btn.new_item')))])],1)],1),_vm._v(" "),_c('div',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.$t('subtitle.admin_info')))]),_vm._v(" "),_c('v-card',[_c('VfLoadingOverlayAbsolute',{attrs:{"loading":_vm.loading}}),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","disable-sort":true,"items-per-page":50,"footer-props":{
        itemsPerPageOptions: [50, 100, 150],
      }},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("\n          "+_vm._s(item.title)+"\n        ")])]}},{key:"item.disp_st_at",fn:function(ref){
      var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.$$fat(item.disp_st_at))+"\n      ")]}},{key:"item.desc",fn:function(ref){
      var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.$$tranc(item.desc, 40))+"\n      ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"depressed":"","x-small":""},on:{"click":function($event){return _vm.showItem(item)}}},[_vm._v(" プレビュー ")])]}}],null,true)})],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("\n            "+_vm._s(_vm.$$fat(_vm.selectedItem.disp_st_at))+"\n          ")]),_vm._v(" "),_c('v-col',{attrs:{"cols":"8"}},[_vm._v("\n            "+_vm._s(_vm.selectedItem.title)+"\n          ")])],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-subtitle',[_vm._v("\n        "+_vm._s(_vm.selectedItem.subtitle)+"\n      ")]),_vm._v(" "),_c('v-card-text',[_c('div',{staticClass:"or-break"},[_vm._v(_vm._s(_vm.selectedItem.desc))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }