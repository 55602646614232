<template>
  <div>
    <v-row>
      <v-col md="5">
        <VfDateTimeSelect
          v-model="internalValue.range_st_dt"
          :label="$$tm('search_last_response.range_st_dt')"
          :vees="vees.range_st_dt"
          format="YYYY/MM/DD"
        />
      </v-col>
      <v-col md="2">
        <div class="mt-6 text-center">〜</div>
      </v-col>
      <v-col md="5">
        <VfDateTimeSelect
          v-model="internalValue.range_ed_dt"
          :label="$$tm('search_last_response.range_ed_dt')"
          :vees="vees.range_ed_dt"
          format="YYYY/MM/DD"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    opts: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
<style scoped>
.time-pick {
  width: 200px;
}
</style>
