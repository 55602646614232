<template>
  <div>
    <v-row>
      <v-col md="6" class="py-0">
        <PtDialogSelect
          v-model="internalValue"
          select-type="answer_form"
          :single-select="true"
          :vees="vees.answer_form_id"
        />
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <VfSelect
          v-model="internalValue.search_answer_form_type"
          :label="$$tm('search_answer_form.search_answer_form_type')"
          :selects="opts.search_answer_form_type"
          :vees="vees.search_answer_form_type"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtDialogSelect,
  },
  props: {
    value: { type: Object, required: true },
    vees: { type: Object, default: () => ({}) },
    opts: { type: Object, default: () => ({}) },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
