<template>
  <div>
    <v-card outlined class="w-fc transparent">
      <v-card-title class="pa-0 or-card-title">
        <v-list-item class="pl-0">
          <v-list-item-content> 月別メッセージ送信数 </v-list-item-content>
          <v-list-item-action>
            <v-btn
              small
              class="or-card-title"
              @click="$router.push({ name: 'MessageCount' })"
            >
              もっと見る
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <VfAlert v-model="errorMsg" type="error" />
      <v-card-text class="pl-0 pt-0">
        <v-data-table
          :headers="headers"
          :items="items"
          disable-sort
          hide-default-footer
        >
          <template #[`item.month`]="{ item }">
            {{ $$fdt(item.month, 'YYYY/MM') }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    errorMsg: '',
    headers: [],
    items: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.errorMsg = ''
      this.loading = true
      const path = '/api/v1/messages/month_index'
      const prms = { limit: 3 }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.message_counts
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
      this.loading = false
    },
  },
}
</script>
