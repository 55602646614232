var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{attrs:{"name":_vm.label,"rules":_vm.rules,"vid":Math.random().toString()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',_vm._b({attrs:{"error-messages":errors,"items":_vm.items,"placeholder":_vm.placeholder,"chips":"","clearable":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item.value)}}},'v-chip',attrs,false),[_vm._v("\n        "+_vm._s(item.text)+"\n      ")])]}}],null,true),model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},'v-select',_vm.baseProps,false))]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }