<template>
  <validation-provider v-slot="{ errors }" :name="label" :rules="rules">
    <div v-if="!noLabel" class="mb-0 text-caption theme--light v-label">
      {{ label }}
    </div>
    <v-radio-group
      v-model="internalValue"
      :error-messages="errors"
      :disabled="disabled"
      :readonly="readonly"
      row
      class="mt-0 vf-radio-group"
    >
      <VRadio
        v-for="(val, key) in selects"
        :key="key"
        :label="key"
        :value="val"
      />
    </v-radio-group>
  </validation-provider>
</template>
<script>
import mixin from 'components/vuetifyParts/commonExport'
export default {
  mixins: [mixin],
  props: {
    value: { default: null },
    selects: { type: Object, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
<style scoped>
.vf-radio-group ::v-deep .v-label {
  margin-top: 10px;
}
</style>
