<template>
  <v-dialog v-model="internalValue" :max-width="maxWidth">
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title />
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="text-center or-break mt-6">{{ detail }}</div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close">{{
          $t('btn.cancel')
        }}</v-btn>
        <v-btn color="blue darken-1" text @click="save">{{
          $t('btn.yes')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    detail: { type: String, required: true },
    maxWidth: { type: Number, default: 500 },
    targetEmit: { type: String, default: 'form-success' },
    templateType: { type: String, required: true },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    close() {
      this.internalValue = false
    },
    save() {
      this.$emit(this.targetEmit, this.templateType)
      this.close()
    },
  },
}
</script>
