<template>
  <div v-if="hasDisplayTabs">
    <v-card>
      <v-card-title>
        {{ $t('lbl.friend.all_update') }}
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab" grow>
          <v-tab v-for="item in items" :key="item.value">
            {{ item.text }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="hasRole.friend_edit">
            <PtFriendUpdateStatus
              :friends="friends"
              :checked-friends="checkedItems"
              :search-wrap="searchWrap"
              :total-count="totalCount"
              @update="update"
            />
          </v-tab-item>
          <v-tab-item v-if="hasRole.friend_scenario">
            <PtFriendUpdateScenario
              :friends="friends"
              :checked-friends="checkedItems"
              :search-wrap="searchWrap"
              :total-count="totalCount"
              @update="update"
            />
          </v-tab-item>
          <v-tab-item v-if="hasRole.friend_hashtag">
            <PtFriendUpdateHashtag
              :friends="friends"
              :checked-friends="checkedItems"
              :search-wrap="searchWrap"
              :total-count="totalCount"
              @update="update"
            />
          </v-tab-item>
          <v-tab-item v-if="hasRole.friend_rich_menu">
            <PtFriendUpdateRichMenu
              :friends="friends"
              :checked-friends="checkedItems"
              :search-wrap="searchWrap"
              :total-count="totalCount"
              @update="update"
            />
          </v-tab-item>
          <v-tab-item v-if="hasRole.friend_reminder">
            <PtFriendUpdateReminder
              :friends="friends"
              :checked-friends="checkedItems"
              :search-wrap="searchWrap"
              :total-count="totalCount"
              @update="update"
            />
          </v-tab-item>
          <v-tab-item v-if="hasRole.friend_schedule">
            <PtFriendUpdateSchedule
              :friends="friends"
              :checked-friends="checkedItems"
              :search-wrap="searchWrap"
              :total-count="totalCount"
              @update="update"
            />
          </v-tab-item>
          <v-tab-item v-if="hasRole.friend_action">
            <PtFriendUpdateAction
              :friends="friends"
              :checked-friends="checkedItems"
              :search-wrap="searchWrap"
              :total-count="totalCount"
              @update="update"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PtFriendUpdateStatus from 'components/parts/unique/PtFriendUpdate/PtFriendUpdateStatus'
import PtFriendUpdateHashtag from 'components/parts/unique/PtFriendUpdate/PtFriendUpdateHashtag'
import PtFriendUpdateAction from 'components/parts/unique/PtFriendUpdate/PtFriendUpdateAction'
import PtFriendUpdateScenario from 'components/parts/unique/PtFriendUpdate/PtFriendUpdateScenario'
import PtFriendUpdateReminder from 'components/parts/unique/PtFriendUpdate/PtFriendUpdateReminder'
import PtFriendUpdateRichMenu from 'components/parts/unique/PtFriendUpdate/PtFriendUpdateRichMenu'
import PtFriendUpdateSchedule from 'components/parts/unique/PtFriendUpdate/PtFriendUpdateSchedule'
export default {
  components: {
    PtFriendUpdateStatus,
    PtFriendUpdateHashtag,
    PtFriendUpdateAction,
    PtFriendUpdateScenario,
    PtFriendUpdateReminder,
    PtFriendUpdateRichMenu,
    PtFriendUpdateSchedule,
  },
  props: {
    friends: { type: Array, required: true },
    checkedItems: { type: Array, required: true },
    searchWrap: { type: Object, default: () => ({}) },
    totalCount: { type: Number, default: null },
  },
  data: () => ({
    tab: null,
  }),
  computed: {
    hasRole() {
      return {
        friend_edit: this.$store.getters.roles.includes('friend_edit'),
        friend_scenario: this.$store.getters.roles.includes('friend_scenario'),
        friend_hashtag: this.$store.getters.roles.includes('friend_hashtag'),
        friend_rich_menu:
          this.$store.getters.roles.includes('friend_rich_menu'),
        friend_reminder: this.$store.getters.roles.includes('friend_reminder'),
        friend_schedule: this.$store.getters.roles.includes('friend_schedule'),
        friend_action: this.$store.getters.roles.includes('friend_action'),
      }
    },
    items() {
      const tabItems = [
        {
          text: this.$$tm('message.message_status'),
          value: 'status',
          role: 'friend_edit',
        },
        {
          text: this.$$tm('scenario.tname'),
          value: 'scenario',
          role: 'friend_scenario',
        },
        {
          text: this.$$tm('hashtag.tname'),
          value: 'hashtag',
          role: 'friend_hashtag',
        },
        {
          text: this.$$tm('rich_menu.tname'),
          value: 'rich_menu',
          role: 'friend_rich_menu',
        },
        {
          text: this.$$tm('reminder.tname'),
          value: 'reminder',
          role: 'friend_reminder',
        },
        {
          text: this.$$tm('schedule.tname'),
          value: 'schedule',
          role: 'friend_schedule',
        },
        {
          text: this.$t('lbl.action_text'),
          value: 'action',
          role: 'friend_action',
        },
      ]
      return tabItems.filter((item) => this.hasRole[item.role])
    },
    hasDisplayTabs() {
      return this.items.length > 0
    },
  },
  methods: {
    update() {
      this.$emit('friend_update')
    },
  },
}
</script>
