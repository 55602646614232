<template>
  <div>
    <v-row>
      <v-col>
        <VfCheckbox
          v-for="(message_status, message_status_idx) in opts.message_status"
          :key="message_status_idx"
          v-model="internalValue.target_message_statuses"
          class="float-left my-0 mr-6"
          :label="
            $t(
              `enum.search_message_status_choice.message_status.${message_status}`
            )
          "
          :checkbox-value="message_status"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    opts: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
