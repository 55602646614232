<template>
  <div>
    <v-row>
      <v-col md="3">
        <VfCheckbox
          v-model="isUseCustomDesign"
          :label="$t('lbl.answer_form.setting.design.is_use_custom')"
          @change="updateSettings"
        />
      </v-col>
    </v-row>
    <template v-if="isUseCustomDesign">
      <!-- Set form color -->
      <v-row>
        <v-col md="4">
          <div class="d-flex justify-start align-center px-4">
            <span class="font-weight-bold mr-2">{{
              $t('lbl.answer_form.setting.design.button_color')
            }}</span>
            <div class="example-box" :style="{ background: buttonColor }" />
          </div>
          <v-color-picker
            class="ma-2 v-color-picker_custom"
            :value="buttonColor"
            hide-inputs
            hide-sliders
            hide-canvas
            show-swatches
            swatches-max-height="240"
            :swatches="swatches"
            @update:color="changeButtonColor"
          />
        </v-col>
        <v-col md="4">
          <div class="d-flex justify-start align-center px-4">
            <span class="font-weight-bold mr-2">{{
              $t('lbl.answer_form.setting.design.background_color')
            }}</span>
            <div class="example-box" :style="{ background: backgroundColor }" />
          </div>
          <v-color-picker
            class="ma-2 v-color-picker_custom"
            :value="backgroundColor"
            hide-inputs
            hide-sliders
            hide-canvas
            show-swatches
            swatches-max-height="240"
            :swatches="swatches"
            @update:color="changeBackroundColor"
          />
        </v-col>
        <v-col md="4">
          <div class="d-flex justify-start align-center px-4">
            <span class="font-weight-bold mr-2">{{
              $t('lbl.answer_form.setting.design.form_part_color')
            }}</span>
            <div class="example-box" :style="{ background: formPartColor }" />
          </div>
          <v-color-picker
            class="ma-2 v-color-picker_custom"
            :value="formPartColor"
            hide-inputs
            hide-sliders
            hide-canvas
            show-swatches
            swatches-max-height="240"
            :swatches="swatches"
            @update:color="changeFormPartColor"
          />
        </v-col>
      </v-row>
      <!-- Set form image url -->
      <v-row class="mt-10 px-5">
        <v-col md="6">
          <p class="font-weight-bold mb-0">
            {{ $t('lbl.answer_form.setting.design.header_image_url') }}
          </p>
          <!-- FIXME: rules url not exist -->
          <VfTextField
            v-model="headerImageUrl"
            class="vf-hidden-label pt-0"
            :label="$t('lbl.answer_form.setting.design.header_image_url')"
            :vees="{} || rules.header_image_url"
            @input="updateSettings"
          />
        </v-col>
        <v-col md="6">
          <p class="font-weight-bold mb-0">
            {{ $t('lbl.answer_form.setting.design.background_image_url') }}
          </p>
          <!-- FIXME: rules url not exist -->
          <VfTextField
            v-model="backgroundImageUrl"
            class="vf-hidden-label pt-0"
            :label="$t('lbl.answer_form.setting.design.background_image_url')"
            :vees="{} || rules.background_image_url"
            @input="updateSettings"
          />
        </v-col>
      </v-row>
      <!-- Set header icon visible -->
      <v-row class="px-5">
        <v-col md="3">
          <VfCheckbox
            v-model="isHideHeaderIcon"
            :label="$t('lbl.answer_form.setting.design.hide_header_icon')"
            @change="updateSettings"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
const DEFAULT_BUTTON_COLOR = '#2196F3'
const DEFAULT_BACKGROUND_COLOR = '#C5CAE9'
const DEFAULT_FORM_PART_COLOR = '#CFD8DC'
export default {
  props: {
    mode: { type: String, default: 'new' },
    value: { type: Object, default: () => ({}) },
    rules: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      isUseCustomDesign: 0,
      isHideHeaderIcon: 0,
      headerImageUrl: null,
      backgroundImageUrl: null,
      buttonColor: DEFAULT_BUTTON_COLOR,
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
      formPartColor: DEFAULT_FORM_PART_COLOR,
      swatches: [
        ['#B71C1C', '#D32F2F', '#F44336', '#E57373', '#FFCDD2'],
        ['#880E4F', '#C2185B', '#E91E63', '#F06292', '#F8BBD0'],
        ['#4A148C', '#7B1FA2', '#9C27B0', '#BA68C8', '#E1BEE7'],
        ['#311B92', '#512DA8', '#673AB7', '#9575CD', '#D1C4E9'],
        ['#1A237E', '#303F9F', '#3F51B5', '#7986CB', '#C5CAE9'],
        ['#0D47A1', '#1976D2', '#2196F3', '#64B5F6', '#BBDEFB'],
        ['#01579B', '#0288D1', '#03A9F4', '#4FC3F7', '#B3E5FC'],
        ['#006064', '#0097A7', '#00BCD4', '#4DD0E1', '#B2EBF2'],
        ['#004D40', '#00796B', '#009688', '#4DB6AC', '#B2DFDB'],
        ['#1B5E20', '#388E3C', '#4CAF50', '#81C784', '#C8E6C9'],
        ['#33691E', '#689F38', '#8BC34A', '#AED581', '#DCEDC8'],
        ['#827717', '#AFB42B', '#CDDC39', '#DCE775', '#F0F4C3'],
        ['#F57F17', '#FBC02D', '#FFEB3B', '#FFF176', '#FFF9C4'],
        ['#FF6F00', '#FFA000', '#FFC107', '#FFD54F', '#FFECB3'],
        ['#E65100', '#F57C00', '#FF9800', '#FFB74D', '#FFE0B2'],
        ['#BF360C', '#E64A19', '#FF5722', '#FF8A65', '#FFCCBC'],
        ['#3E2723', '#5D4037', '#795548', '#A1887F', '#D7CCC8'],
        ['#263238', '#455A64', '#607D8B', '#90A4AE', '#CFD8DC'],
        ['#000000', '#FFFFFF'],
      ],
    }
  },
  computed: {
    settingsData() {
      // API map with local properties
      return {
        custom_design_flg: this.isUseCustomDesign,
        hide_header_icon_flg: this.isHideHeaderIcon,
        header_image_url: this.headerImageUrl,
        background_image_url: this.backgroundImageUrl,
        button_color: this.isUseCustomDesign ? this.buttonColor : '',
        background_color: this.isUseCustomDesign ? this.backgroundColor : '',
        form_section_color: this.isUseCustomDesign ? this.formPartColor : '',
      }
    },
  },
  watch: {
    isUseCustomDesign(val) {
      if (!val) {
        this.resetDesignSettings()
      } else {
        this.setDefaultDesignSettings()
      }
    },
  },
  mounted() {
    this.isUseCustomDesign = this.value.custom_design_flg
    this.setDefaultDesignSettings()
  },
  methods: {
    /**
     * Change button color
     *
     * @param {Object} color
     */
    changeButtonColor(color) {
      this.buttonColor = color.hex
      this.updateSettings()
    },

    /**
     * Change background color
     *
     * @param {Object} color
     */
    changeBackroundColor(color) {
      this.backgroundColor = color.hex
      this.updateSettings()
    },

    /**
     * Change form part color
     *
     * @param {Object} color
     */
    changeFormPartColor(color) {
      this.formPartColor = color.hex
      this.updateSettings()
    },

    /**
     * Emit design settings to parrent
     */
    updateSettings() {
      this.$emit('change', this.settingsData)
    },

    /**
     * Set design settings default value (edit|new mode)
     */
    setDefaultDesignSettings() {
      if (this.mode !== 'new' && this.value.custom_design_flg) {
        this.isHideHeaderIcon = this.value.hide_header_icon_flg
        this.headerImageUrl = this.value.header_image_url
        this.backgroundImageUrl = this.value.background_image_url
        this.buttonColor = this.value.button_color
        this.backgroundColor = this.value.background_color
        this.formPartColor = this.value.form_section_color
      } else {
        this.resetDesignSettings()
      }
    },

    /**
     * Reset design to default
     */
    resetDesignSettings() {
      this.isHideHeaderIcon = 0
      this.headerImageUrl = null
      this.backgroundImageUrl = null
      this.buttonColor = DEFAULT_BUTTON_COLOR
      this.backgroundColor = DEFAULT_BACKGROUND_COLOR
      this.formPartColor = DEFAULT_FORM_PART_COLOR
      this.updateSettings()
    },
  },
}
</script>
<style lang="scss" scoped>
.example-box {
  width: 42px;
  height: 14px;
}
.v-color-picker_custom ::v-deep {
  .v-color-picker {
    &__swatches {
      div:first-child {
        justify-content: flex-start;
      }
    }
    &__swatch {
      &:last-child {
        .v-color-picker {
          &__color {
            &:last-child {
              border: 1px solid #ddd;
            }
          }
        }
      }
    }
    &__color {
      max-height: unset;
      width: 40px;
      height: 24px;
      i {
        &.mdi-check-circle {
          margin: 0 auto;
        }
      }
    }
    &__controls {
      display: none;
    }
  }
}
.vf-hidden-label ::v-deep {
  .v-text-field {
    margin: 0;
    padding: 0;
  }
  fieldset > legend {
    display: none;
  }
  .v-label {
    display: none;
  }
}
</style>
