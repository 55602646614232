<template>
  <div>
    <PtTitle />
    <div>{{ $t('subtitle.data_migration') }}</div>
    <v-row class="my-5">
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('data_migration.friend_title') }}
          </v-card-title>
          <v-card-text>
            {{ $t('data_migration.friend_body') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <a
              class="text-decoration-none"
              :href="`/api/v1/data_migrations/friends_migration.csv`"
            >
              <v-btn color="primary">
                {{ $t('data_migration.export') }}
              </v-btn>
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('data_migration.tag_title') }}
          </v-card-title>
          <v-card-text>
            {{ $t('data_migration.tag_body') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <a
              class="text-decoration-none"
              :href="`/api/v1/data_migrations/hashtags_migration.csv`"
            >
              <v-btn color="primary">
                {{ $t('data_migration.export') }}
              </v-btn>
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('data_migration.friend_tag_title') }}
          </v-card-title>
          <v-card-text>
            {{ $t('data_migration.friend_tag_body') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <a
              class="text-decoration-none"
              :href="`/api/v1/data_migrations/friend_hashtags_migration.csv`"
            >
              <v-btn color="primary">
                {{ $t('data_migration.export') }}
              </v-btn>
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="my-5">
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('data_migration.scenario_title') }}
          </v-card-title>
          <v-card-text>
            {{ $t('data_migration.scenario_body') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <a
              class="text-decoration-none"
              :href="`/api/v1/data_migrations/scenarios_migration.csv`"
            >
              <v-btn color="primary">
                {{ $t('data_migration.export') }}
              </v-btn>
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('data_migration.scenario_timing_title') }}
          </v-card-title>
          <v-card-text>
            {{ $t('data_migration.scenario_timing_body') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <a
              class="text-decoration-none"
              :href="`/api/v1/data_migrations/scenario_timings_migration.csv`"
            >
              <v-btn color="primary">
                {{ $t('data_migration.export') }}
              </v-btn>
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('data_migration.friend_scenario_title') }}
          </v-card-title>
          <v-card-text>
            {{ $t('data_migration.friend_scenario_body') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <a
              class="text-decoration-none"
              :href="`/api/v1/data_migrations/friend_scenarios_migration.csv`"
            >
              <v-btn color="primary">
                {{ $t('data_migration.export') }}
              </v-btn>
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
