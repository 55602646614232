<template>
  <div>
    <v-card outlined>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title class="mb-2">
        <div class="d-flex align-items-center">
          <search-dialog
            :default-search-wrap="defaultSearchWrap"
            @decide="decide"
            @clear="searchFriends"
          />
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            dense
            clearable
            single-line
            hide-details
          >
            <template #prepend-inner>
              <v-icon>mdi-magnify</v-icon>
            </template>
          </v-text-field>
          <v-card-actions>
            <v-btn color="primary" @click="searchWord">{{
              $t('btn.search')
            }}</v-btn>
            <v-btn color="submit" @click="searchClear">{{
              $t('btn.clear')
            }}</v-btn>
          </v-card-actions>
        </div>
      </v-card-title>
      <v-divider class="my-0" />
      <v-layout column>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          item-key="id"
          disable-sort
          mobile-breakpoint="0"
          hide-default-header
          :hide-default-footer="items.length < 10"
          :server-items-length="totalCount"
          :options.sync="options"
          :footer-props="{
            itemsPerPageOptions: [10, 30, 50],
            itemsPerPageText: '',
          }"
          @click:row="rowClick"
        >
          <template #[`item.name`]="{ item }">
            <div class="mt-4">
              <v-chip class="mr-2" pill>
                <v-avatar left>
                  <v-img
                    :src="$$noimageHelper(item.api_picture_url)"
                    :alt="item.name"
                  />
                </v-avatar>
                {{ item.name }}
              </v-chip>
              <v-chip v-if="showUnreadMessages(item)" dark>
                {{ item.unconfirmed_message_count }}
              </v-chip>
            </div>
            <div class="or-break ma-4 deil_line_wrap">{{ item.deli }}</div>
            <div v-if="item.last_receive_at" class="text-end text-caption mb-2">
              {{ $$moment(item.last_receive_at).format('MM/DD') }}
            </div>
            <div v-if="item.blocking_flg === 1" class="red--text text-end">
              ブロックしています
              <p v-if="item.blocking_at_formatted">
                {{ item.blocking_at_formatted }}
              </p>
            </div>
            <div v-if="item.blocked_flg === 1" class="red--text text-end">
              ブロックされています
              <p v-if="item.blocked_at_formatted">
                {{ item.blocked_at_formatted }}
              </p>
            </div>
          </template>
        </v-data-table>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
import SearchDialog from 'components/parts/unique/PtUnicastFriendIndex/SearchDialog'
export default {
  components: {
    SearchDialog,
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    search: '',
    defaultSearchWrap: {},
    totalCount: 0,
    searchWrap: {},
    options: {
      page: 1,
      per: 10,
    },
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.options.per = this.options.itemsPerPage
        this.searchFriends(this.searchWrap)
      },
      deep: true,
    },
  },
  created() {
    this.setup()
  },
  methods: {
    searchFriends(searchWrap = {}) {
      this.loading = true
      const searchWord = this.search
      const path = '/api/v1/friends/unicast_index'
      const prms = {
        search_wrap: searchWrap,
        search_word: searchWord,
        ...this.options,
      }
      this.axios
        .post(path, this.$$reqPrms(prms))
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.friends
          this.totalCount = res.data.total_count
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    searchWord() {
      this.options.page = 1
      this.searchFriends(this.searchWrap)
    },
    searchClear() {
      this.search = ''
      this.options.page = 1
      this.searchFriends((this.searchWrap = {}))
    },
    setup() {
      this.loading = true
      const path = '/api/v1/search_wraps/setup'
      this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.defaultSearchWrap = res.data.init_data.wrap
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    rowClick(item) {
      this.selectedItem(item, true)
      this.$emit('active-tab', 'tab-2')
    },
    selectedItem(item) {
      this.$set(item, 'selected', true)
      this.$emit('select-friend', item.id)
    },
    decide(searchWrap) {
      this.searchWrap = searchWrap
      this.searchFriends(searchWrap)
    },
    showUnreadMessages(item) {
      if (item.unconfirmed_message_count == 0) return false
      let selectedItemIds = this.items
        .filter((item) => item.selected)
        .map((item) => item.id)
      if (selectedItemIds.includes(item.id)) return false
      return true
    },
  },
}
</script>
<style scoped>
.deil_line_wrap {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.layout {
  height: 75vh;
}
.v-data-table.theme--light {
  overflow: auto;
}
</style>
