<template>
  <v-overlay :value="loading" absolute>
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
</template>
<script>
export default {
  props: {
    loading: { type: Boolean, default: false },
  },
}
</script>
