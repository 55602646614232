<template>
  <div>
    <v-card>
      <v-card-title class="pa-0">
        <v-list-item>
          <v-list-item-content>
            {{ $t('lbl.friend.reminder') }}
          </v-list-item-content>
          <v-list-item-action v-if="hasRole">
            <v-btn icon outlined @click="openSelectDialog">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <VDivider class="my-0" />
      <v-card-text>
        <v-chip
          v-for="(reminder, reminder_idx) in internalValue"
          :key="reminder_idx"
          class="ma-2 multiple_lines"
          large
        >
          {{ reminder.name }}
          <br />
          {{ $$fat(reminder.goal_at, 'YYYY/MM/DD HH:mm') }}
        </v-chip>
      </v-card-text>
    </v-card>
    <v-dialog v-if="hasRole" v-model="selectDialog" max-width="1200">
      <v-card>
        <validation-observer ref="observer">
          <VfLoadingOverlayAbsolute :loading="$wait.is('processing')" />
          <v-card-title>
            {{ $t(`lbl.action_group.action_types.reminder`) }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6" class="pb-0">
                <VfRadioButton
                  v-model="reminderActionTypeModel"
                  :label="$$tm('action_reminder.reminder_action_type')"
                  :selects="reminderActionType"
                  label-hidden
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" class="py-0">
                <PtDialogSelect
                  v-model="reminder"
                  select-type="reminder"
                  :single-select="true"
                  :vees="{ rules: 'required' }"
                  only-have-timing
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <VfDateTimeSelect
                  v-if="reminderActionTypeModel === 'start'"
                  v-model="goalAt"
                  :label="$$tm('action_reminder.goal_at')"
                  format="YYYY/MM/DD HH:mm"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <VSpacer />
            <v-btn @click="closeSelectDialog">{{ $t('btn.cancel') }}</v-btn>
            <v-btn
              color="primary"
              :loading="$wait.is('processing')"
              @click="save"
              >{{ $t('btn.update') }}</v-btn
            >
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtDialogSelect,
  },
  props: {
    value: { type: Array, required: true },
    friendId: { type: String, required: true },
  },
  data: () => ({
    selectDialog: false,
    reminder: {},
    goalAt: '',
    reminderActionTypeModel: 'start',
    reminderActionType: {
      リマインダーを開始: 'start',
      リマインダーを停止: 'cancel',
    },
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    hasRole() {
      return this.$store.getters.roles.includes('friend_reminder')
    },
  },
  methods: {
    openSelectDialog() {
      this.selectDialog = true
      var date = new Date()
      date.setHours(9)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)
      this.goalAt = date
    },
    closeSelectDialog() {
      this.selectDialog = false
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      if (!Object.keys(this.reminder.reminder).length) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      const path = `/api/v1/friends/${this.friendId}/update_reminders`
      const prms = {
        reminder: this.reminder.reminder,
        goal_at: this.goalAt,
        action_type: this.reminderActionTypeModel,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then((res) => {
          this.internalValue.splice(0)
          res.data.reminders.forEach((reminder) =>
            this.internalValue.push(reminder)
          )
          this.$toasted.success(this.$t('success.update'))
          this.goalAt = new Date()
          this.selectDialog = false
        })
        .catch(() => {
          this.$toasted.error(this.$t('danger.error_occurred'))
        })
        .finally(() => {
          this.$wait.end('processing')
        })
    },
  },
}
</script>
<style scoped>
.multiple_lines {
  font-size: 14px !important;
}
</style>
