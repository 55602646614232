<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-row>
      <v-col md="6" class="pb-0">
        <VfRadioButton
          v-model="internalValue.scenario_action_type"
          :label="$$tm('action_scenario.scenario_action_type')"
          :vees="vees.scenario_action_type"
          :selects="opts.scenario_action_type"
          label-hidden
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="py-0">
        <PtDialogSelect
          v-if="internalValue.scenario_action_type !== 'cancel'"
          v-model="internalValue"
          select-type="scenario"
          :single-select="true"
          :hide-folder="true"
          :vees="vees.name"
          only-have-timing
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="py-0">
        <PtDialogSelect
          v-if="
            internalValue.scenario_action_type !== 'cancel' &&
            internalValue.scenario_id
          "
          v-model="internalValue"
          select-type="scenario_timing"
          :single-select="true"
          :hide-folder="true"
          :vees="vees.start_position"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtDialogSelect,
  },
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    opts: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    loading: false,
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
<style scoped>
.time-pick {
  width: 200px;
}
</style>
