<template>
  <div>
    <v-card>
      <v-tabs v-model="tab" grow>
        <v-tab v-for="item in items" :key="item.value">
          {{ item.text }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="px-4 pb-4">
        <v-tab-item>
          <PtClickInfo :click="click" />
        </v-tab-item>
        <v-tab-item>
          <PtClickScenario :click-id="click.id" ref="scenario" />
        </v-tab-item>
        <v-tab-item>
          <PtClickBroadcast :click-id="click.id" ref="broadcast" />
        </v-tab-item>
        <v-tab-item>
          <PtClickUnicast :click-id="click.id" ref="unicast" />
        </v-tab-item>
        <v-tab-item>
          <PtClickRichMenu :click-id="click.id" ref="richmenu" />
        </v-tab-item>
        <v-tab-item>
          <PtClickRichMessage :click-id="click.id" ref="richmessage" />
        </v-tab-item>
        <v-tab-item>
          <PtClickQuestion :click-id="click.id" ref="question" />
        </v-tab-item>
        <v-tab-item>
          <PtClickCarousel :click-id="click.id" ref="carousel" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import PtClickInfo from 'components/parts/common/PtClick/PtClickInfo'
import PtClickBroadcast from 'components/parts/common/PtClick/PtClickBroadcast'
import PtClickScenario from 'components/parts/common/PtClick/PtClickScenario'
import PtClickUnicast from 'components/parts/common/PtClick/PtClickUnicast'
import PtClickRichMenu from 'components/parts/common/PtClick/PtClickRichMenu'
import PtClickRichMessage from 'components/parts/common/PtClick/PtClickRichMessage'
import PtClickQuestion from 'components/parts/common/PtClick/PtClickQuestion'
import PtClickCarousel from 'components/parts/common/PtClick/PtClickCarousel'
export default {
  components: {
    PtClickInfo,
    PtClickBroadcast,
    PtClickScenario,
    PtClickUnicast,
    PtClickRichMenu,
    PtClickRichMessage,
    PtClickQuestion,
    PtClickCarousel,
  },
  props: {
    click: { type: Object, required: true },
  },
  data: () => ({
    tab: null,
  }),
  computed: {
    items() {
      return [
        { text: this.$t('lbl.click.info'), value: 'info' },
        { text: this.$$tm('scenario.tname'), value: 'scenario' },
        { text: this.$$tm('broadcast.tname'), value: 'broadcast' },
        { text: this.$$tm('unicast.tname'), value: 'unicast' },
        { text: this.$$tm('rich_menu.tname'), value: 'richmenu' },
        { text: this.$$tm('rich_message.tname'), value: 'richmessage' },
        { text: this.$$tm('deli_question.tname'), value: 'question' },
        { text: this.$$tm('deli_carousel.tname'), value: 'carousel' },
      ]
    },
  },
  watch: {
    tab(val) {
      this.$emit('selectTab', this.items[val])
    },
  },
}
</script>
