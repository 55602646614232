<template>
  <div v-if="!hidden" class="mb-3">
    <v-row>
      <v-col v-if="!hiddenTitle" sm="4" cols="12" xs="12">
        <span class="text-bold">{{ title }}</span>
        <v-chip
          v-if="required"
          class="ma-2"
          label
          color="red"
          text-color="white"
          small
        >
          {{ $t('lbl.answer_form.require') }}
        </v-chip>
      </v-col>
      <v-col :sm="!hiddenTitle ? 8 : 12" cols="12" xs="12">
        <VfSelect
          v-model="internalValue"
          :label="title"
          :selects="prefectures"
          :vees="vees"
        />
        <div class="subtitle-3 theme--light v-messages">{{ hint }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PtFormRenderAddress',
  props: {
    value: { default: '' },
    opts: { default: null },
    label: { type: String, default: '' },
    title: { type: String, default: '' },
    hint: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    required: { default: 0 },
    hidden: { default: 0 },
    hiddenTitle: { default: 0 },
  },
  data: function () {
    return {
      loading: false,
      errorMsg: '',
      data: {},
      prefectures: {},
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value ? this.value[0] : null
      },
      set(value) {
        this.$emit('input', [value])
      },
    },
    vees: function () {
      let rules = {
        rules: '',
      }

      if (this.required) {
        rules.rules += (rules.rules ? '|' : '') + 'required'
        rules.required = true
      }

      return rules
    },
  },
  created() {
    this.prefectures = Object.assign(
      { [this.$t('lbl.answer_form.option_first_select')]: '' },
      this.opts.answer_form_input.additional_option_types.prefectures
    )
  },
  methods: {},
}
</script>

<style scoped lang="scss">
::v-deep {
  fieldset > legend {
    display: none;
  }

  .v-label {
    display: none;
  }
}
</style>
