export default {
  props: {
    label: { type: String, required: true },
    vees: { type: Object, default: () => ({}) },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    counter: { type: Boolean, default: false },
    noLabel: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    autocomplete: { type: String },
    placeholder: { type: String },
    hint: { type: String },
    styleWidth: { type: Number },
    vid: { type: String },
  },
  computed: {
    rules() {
      if (!this.vees) {
        return null
      }
      return (this.vees || {}).rules
    },
    max() {
      if (!this.counter) {
        return null
      }
      if (!this.vees) {
        return null
      }
      return (this.vees || {}).max
    },
    baseProps() {
      const ret = {}
      if (!this.noLabel) {
        ret['label'] = this.label
      }
      ret['no-label'] = this.noLabel
      ret['disabled'] = this.disabled
      ret['readonly'] = this.readonly
      ret['clearable'] = this.clearable
      ret['dense'] = this.dense
      ret['outlined'] = this.outlined
      ret['filled'] = this.filled
      ret['solo'] = this.solo
      ret['hide-details'] = this.hideDetails
      ret['clearable'] = this.clearable
      ret['counter'] = this.max
      ret['placeholoder'] = this.placeholoder
      ret['autocomplete'] = this.autocomplete
      ret['hint'] = this.hint
      if (this.hint) {
        ret['persistentHint'] = true
      }
      const styles = []
      if (this.styleWidth) {
        styles.push(`width: ${this.styleWidth}px`)
      }
      if (styles.length > 0) {
        ret['style'] = styles.join('; ')
      }
      return ret
    },
    dateTimeSelectProps() {
      const ret = this.$$clone(this.baseProps)
      ret['label'] = this.label
      return ret
    },
    dateTimePickerProps() {
      const ret = this.$$clone(this.baseProps)
      ret['label'] = this.label
      ret['readonly'] = true
      ret['autocomplete'] = 'off'
      return ret
    },
  },
}
