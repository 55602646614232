import { render, staticRenderFns } from "./PtFriendUpdateAction.vue?vue&type=template&id=c82c0ccc&scoped=true&"
import script from "./PtFriendUpdateAction.vue?vue&type=script&lang=js&"
export * from "./PtFriendUpdateAction.vue?vue&type=script&lang=js&"
import style0 from "./PtFriendUpdateAction.vue?vue&type=style&index=0&id=c82c0ccc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c82c0ccc",
  null
  
)

export default component.exports