<template>
  <div>
    <v-card class="mb-8">
      <validation-observer ref="observer">
        <VfLoadingOverlayAbsolute :loading="$wait.is('processing')" />
        <VfAlert v-model="errorMsg" type="error" />
        <v-card-text>
          <v-row>
            <v-col md="6">
              <PtDialogSelect
                v-model="activeTemplate"
                select-type="action_template"
                :single-select="true"
                :vees="{ rules: 'required' }"
              >
                <template #[`item.action_group`]="{ item }">
                  <PtActionLabelMessages
                    v-model="item.action_group.make_action_arr"
                    class="or-list"
                  />
                </template>
              </PtDialogSelect>
            </v-col>
          </v-row>
        </v-card-text>
      </validation-observer>
    </v-card>
    <pt-friend-update-common-bottom
      v-model="allFriendsSelected"
      :friends="friends"
      :total-count="totalCount"
      :alert-message="$t('lbl.confirm_friends_action_update')"
      @save="save"
    />
  </div>
</template>
<script>
import PtFriendUpdateCommonBottom from './PtFriendUpdateCommonBottom.vue'
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  components: {
    PtFriendUpdateCommonBottom,
    PtDialogSelect,
    PtActionLabelMessages,
  },
  props: {
    friends: { type: Array, required: true },
    checkedFriends: { type: Array, required: true },
    searchWrap: { type: Object, default: () => ({}) },
    totalCount: { type: Number, default: null },
  },
  data: () => ({
    errorMsg: '',
    activeTemplate: {},
    allFriendsSelected: 0,
  }),
  computed: {
    targetFriendIds() {
      return this.checkedFriends.map((friend) => friend.id)
    },
  },
  methods: {
    async save() {
      this.errorMsg = ''
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.$wait.end('processing') /* start child component */
        return this.$toasted.error(this.$t('danger.params'))
      }
      const path = `/api/v1/friends/target_friends_exec_actions`
      const prms = {
        friend_ids: this.targetFriendIds,
        actions: [this.activeTemplate.action_template],
        all_friends_selected: this.allFriendsSelected,
        search_wrap: this.searchWrap,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.$emit('update')
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.$wait.end('processing') /* start child component */
        })
    },
    selectFolder(folderId) {
      this.initTable(folderId)
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
  },
}
</script>
<style scoped>
.label-mb-0 ::v-deep label {
  margin-bottom: 0px;
}
</style>
