import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

import ja from 'vuetify/es5/locale/ja'

const opts = { lang: { locales: { ja }, current: 'ja' } }

export default new Vuetify(opts)
