<template>
  <div ref="payjpArea" />
</template>
<script>
export default {
  data: () => ({
    scriptPayjp: null,
    dataKey: '',
  }),
  mounted() {
    this.init()
  },
  beforeDestroy() {
    const payjpCheckoutIframeEl = document.getElementById(
      'payjp-checkout-iframe'
    )
    payjpCheckoutIframeEl.parentElement.remove()
    this.$refs.payjpArea.removeChild(this.scriptPayjp)
    window.PayjpCheckout = null
    window.onTokenCreated = null
    window.onTokenFailed = null
  },
  methods: {
    async init() {
      const path = '/api/v1/payment/setup'
      await this.$$sendForm('post', path, this.$$reqPrms())
        .then((res) => {
          this.dataKey = res.data.data_key
          this.createPayjpScript()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    createPayjpScript() {
      const attrs = {
        'data-key': this.dataKey,
        src: 'https://checkout.pay.jp/',
        class: 'payjp-button',
        'data-partial': 'true',
        'data-text': this.$t('btn.entry_card'),
        'data-submit-text': this.$t('btn.create'),
        'data-on-created': 'onTokenCreated',
        'data-on-failed': 'onTokenFailed',
        'data-name-placeholder': 'YAMADA TARO',
      }
      this.scriptPayjp = document.createElement('script')
      Object.keys(attrs).forEach((key) =>
        this.scriptPayjp.setAttribute(key, attrs[key])
      )
      this.$refs.payjpArea.appendChild(this.scriptPayjp)
      window.onTokenCreated = this.onTokenCreated
      window.onTokenFailed = this.onTokenFailed
    },
    onTokenCreated(res) {
      this.$emit('payjp:created', res.id)
    },
    onTokenFailed(res) {
      this.$emit('payjp:failed', res)
    },
  },
}
</script>
