import { render, staticRenderFns } from "./PtFormRenderAddress.vue?vue&type=template&id=2e8be3c7&scoped=true&"
import script from "./PtFormRenderAddress.vue?vue&type=script&lang=js&"
export * from "./PtFormRenderAddress.vue?vue&type=script&lang=js&"
import style0 from "./PtFormRenderAddress.vue?vue&type=style&index=0&id=2e8be3c7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e8be3c7",
  null
  
)

export default component.exports