<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn
            v-if="reminderTimingId !== 'new'"
            color="error"
            :disabled="frem_active_flg"
            @click="destroy"
            >{{ $t('btn.delete') }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.reminder_timing.timing') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="3">
              <VfSelect
                v-model="reminderTiming.reminder_timing_type"
                :label="$$tm('reminder_timing.reminder_timing_type')"
                :vees="vees.reminder_timing_type"
                :selects="opts.reminder_timing_type"
                :disabled="frem_active_flg"
              />
            </v-col>
            <template v-if="reminderTiming.reminder_timing_type !== 'start'">
              <v-col md="1">
                <VfTextField
                  v-model="reminderTiming.sch_day"
                  :label="$$tm('reminder_timing.sch_day')"
                  :vees="vees.sch_day"
                  :disabled="frem_active_flg"
                  number
                />
              </v-col>
              <v-col md="3">
                <VfDateTimeSelect
                  v-model="reminderTiming.sch_time"
                  :label="$$tm('reminder_timing.sch_time')"
                  :disabled="frem_active_flg"
                  format="HH:mm"
                />
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.reminder_timing.action') }}
        </v-card-title>
        <v-card-text>
          <PtActionSelectedList
            hide-timing-type-select
            v-model="reminderTiming.action_arr"
          />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col class="text-right">
          <v-btn
            color="primary"
            :loading="$wait.is('processing')"
            @click="save"
            >{{ $t('btn.create') }}</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtActionSelectedList from 'components/parts/common/PtActionGroup/PtActionSelectedList'
import { validateMultipleDeliText } from 'services/deliText'
export default {
  components: {
    PtActionSelectedList,
  },
  data: () => ({
    initDone: false,
    reminderId: '',
    reminderTimingId: '',
    reminderTiming: {},
    frem_active_flg: false,
    vees: {},
    folders: [],
    opts: {},
    deleteDialog: false,
    savePath: '',
  }),
  computed: {
    DAYS() {
      return [
        'sun_flg',
        'mon_flg',
        'tue_flg',
        'wed_flg',
        'thu_flg',
        'fri_flg',
        'sat_flg',
      ]
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.reminderId = this.$route.query.reminder_id
      this.reminderTimingId = this.$route.params.id
      const path =
        this.reminderTimingId === 'new'
          ? '/api/v1/reminder_timings/new'
          : `/api/v1/reminder_timings/${this.reminderTimingId}/edit`
      const prms = { reminder_id: this.reminderId }
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.reminderTiming = res.data.reminder_timing
          this.frem_active_flg = res.data.frem_active_flg
          this.vees = res.data.vees
          this.folders = res.data.folders
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      if (
        this.reminderTiming.action_arr.length > 0 &&
        this.reminderTiming.action_arr[0].type === 'broadcast' &&
        !validateMultipleDeliText(
          this.reminderTiming.action_arr[0].data.target_delis
        )
      ) {
        return this.$toasted.error(this.$t('danger.deli_text.format_invalid'))
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.reminderTimingId === 'new'
          ? '/api/v1/reminder_timings'
          : `/api/v1/reminder_timings/${this.reminderTimingId}`
      const method = this.reminderTimingId === 'new' ? 'post' : 'put'
      const prms = { reminder_timing: this.reminderTiming }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({
            name: 'ReminderForm',
            params: { id: this.$route.query.reminder_id },
          })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    destroy() {
      this.savePath = `/api/v1/reminder_timings/${this.reminderTimingId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({
        name: 'ReminderForm',
        params: { id: this.reminderId },
      })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
