<template>
  <div>
    <v-card class="mb-8">
      <validation-observer ref="observer">
        <VfLoadingOverlayAbsolute :loading="$wait.is('processing')" />
        <VfAlert v-model="errorMsg" type="error" />
        <v-card-title>
          <v-list-item>
            <v-list-item-content class="hashtag_caution">
              {{ $t('lbl.hashtag.friend_show_caution') }}
            </v-list-item-content>
          </v-list-item>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <PtDialogSelect
                v-model="dialogValue"
                select-type="hashtag"
                :vees="{ rules: 'required' }"
              >
                <template #[`item.action_group`]="{ item }">
                  <PtActionLabelMessages
                    v-model="item.action_group.make_action_arr"
                    class="or-list"
                  />
                </template>
              </PtDialogSelect>
            </v-col>
          </v-row>
        </v-card-text>
      </validation-observer>
    </v-card>
    <pt-friend-update-hashtag-bottom
      v-model="allFriendsSelected"
      :friends="friends"
      :total-count="totalCount"
      :alert-message="$t('lbl.confirm_friends_hashtag_update')"
      @attach="attach"
      @detach="detach"
    />
  </div>
</template>
<script>
import PtFriendUpdateHashtagBottom from './PtFriendUpdateHashtagBottom'
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  components: {
    PtFriendUpdateHashtagBottom,
    PtDialogSelect,
    PtActionLabelMessages,
  },
  props: {
    friends: { type: Array, required: true },
    checkedFriends: { type: Array, required: true },
    searchWrap: { type: Object, default: () => ({}) },
    totalCount: { type: Number, default: null },
  },
  data: () => ({
    errorMsg: '',
    headers: [],
    items: [],
    allFriendsSelected: 0,
    dialogValue: { hashtags: [] },
  }),
  computed: {
    targetFriendIds() {
      return this.checkedFriends.map((friend) => friend.id)
    },
    hashtagIds() {
      return this.dialogValue.hashtags.map((hashtag) => hashtag.id)
    },
  },
  methods: {
    async attach() {
      this.errorMsg = ''
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.$wait.end('processing') /* start child component */
        return this.$toasted.error(this.$t('danger.params'))
      }
      const path = `/api/v1/friends/target_friends_update_hashtags_attach`
      const prms = {
        friend_ids: this.targetFriendIds,
        hashtag_ids: this.hashtagIds,
        all_friends_selected: this.allFriendsSelected,
        search_wrap: this.searchWrap,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.$emit('update')
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.$wait.end('processing') /* start child component */
        })
    },
    async detach() {
      this.errorMsg = ''
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.$wait.end('processing') /* start child component */
        return this.$toasted.error(this.$t('danger.params'))
      }
      const path = `/api/v1/friends/target_friends_update_hashtags_detach`
      const prms = {
        friend_ids: this.targetFriendIds,
        hashtag_ids: this.hashtagIds,
        all_friends_selected: this.allFriendsSelected,
        search_wrap: this.searchWrap,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.$emit('update')
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.$wait.end('processing') /* start child component */
        })
    },
  },
}
</script>
<style scoped>
.hashtag_caution {
  font-size: 4px;
  color: #ff0000;
}
.label-mb-0 ::v-deep label {
  margin-bottom: 0px;
}
</style>
