import axios from 'axios'

const VueAxiosPlugin = {
  install(Vue) {
    axios.interceptors.request.use((config) => {
      config.headers['Content-Type'] = 'application/json'
      config.headers['X-Requested-With'] = 'XMLHttpRequest'
      return config
    }),
      (error) => {
        return Promise.reject(error)
      }
    axios.interceptors.response.use((response) => {
      return response
    }),
      (error) => {
        return Promise.reject(error)
      }

    Object.defineProperties(Vue.prototype, {
      axios: {
        get() {
          return axios
        },
      },
    })
  },
}

export default VueAxiosPlugin
