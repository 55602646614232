<template>
  <div>
    <PtTitle />
    <channel-notification-index
      :line-notices="lineNotices"
      @click:row="toEdit"
      @click:testNotification="sendTestNotification"
    />
  </div>
</template>
<script>
import ChannelNotificationIndex from 'pages/v1/notificators/Show/ChannelNotificationIndex'
export default {
  components: {
    ChannelNotificationIndex,
  },
  data: () => ({
    lineNotices: [],
  }),
  created() {
    this.getNotificators()
  },
  methods: {
    getNotificators() {
      this.loading = true
      const path = `/api/v1/notificator/${this.$route.params.id}`
      this.axios
        .get(path)
        .then((res) => {
          this.lineNotices = res.data.line_notices
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    sendTestNotification(itemId) {
      this.loading = true
      const path = '/api/v1/notificator/test_send'
      const params = { id: itemId }
      this.$$sendForm('post', path, params)
        .then(() => {
          this.$toasted.success(this.$t('lbl.send_message_done'))
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    toEdit(itemId) {
      this.$router.push({ name: 'SpNotificatorForm', params: { id: itemId } })
    },
  },
}
</script>
