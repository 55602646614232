<template>
  <v-row>
    <v-col v-if="!hiddenTitle" sm="4" cols="12" xs="12">
      <span class="text-bold">{{ title }}</span>
      <v-chip
        v-if="required"
        class="ma-2"
        label
        color="red"
        text-color="white"
        small
      >
        {{ $t('lbl.answer_form.require') }}
      </v-chip>
    </v-col>
    <v-col sm="8" cols="12" xs="12">
      <validation-provider
        v-slot="{ errors }"
        ref="provider"
        :name="label"
        :rules="localVees.rules"
      >
        <div v-for="option in inputOptions">
          <VfCheckbox
            v-model="checkedOptions"
            class="m-0"
            :checkbox-value="option.id"
            :label="
              isDisabled(option)
                ? option.capacity_warning ||
                  $t('lbl.answer_form.can_not_select') + option.value
                : option.value
            "
            :disabled="isDisabled(option)"
            :persistent-hint="true"
            :hide-details="'auto'"
            :color="color"
          />
          <VfTextField
            v-if="checkedOptions.includes(option.id) && option.is_other_flg"
            v-model="customOptions[option.id]"
            class="vf-hidden-label pt-0"
            :label="title"
            :placeholder="''"
            :hint="''"
          />
        </div>
        <div v-if="errors.length" class="v-text-field__details">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message">{{ errors[0] }}</div>
            </div>
          </div>
        </div>
      </validation-provider>
      <div class="subtitle-3 theme--light v-messages">{{ hint }}</div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PtFormRenderCheckbox',
  props: {
    value: { default: null },
    label: { type: String, default: '' },
    title: { type: String, default: '' },
    hiddenTitle: { default: 0 },
    hint: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    inputOptions: { type: Array, default: () => [] },
    optionCount: { type: Object, default: () => ({}) },
    selectFlg: { default: 0 },
    maxSelect: { type: Number, default: null },
    minSelect: { type: Number, default: null },
    color: { type: String, default: null },
    friend: { type: Object, default: () => ({}) },
    required: { default: 0 },
  },
  data: function () {
    return {
      loading: false,
      errorMsg: '',
      data: {},
      checkedOptions: [],
      customOptions: {},
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    localData: function () {
      return this.checkedOptions.map((optionId) => {
        if (this.customOptions[optionId]) {
          return [optionId, this.customOptions[optionId]]
        } else {
          return optionId
        }
      })
    },
    localVees: function () {
      let rules = {
        rules: '',
      }

      if (this.required) {
        rules.rules += (rules.rules ? '|' : '') + 'required'
        rules.required = true
      }

      if (this.selectFlg && this.maxSelect) {
        rules.rules += (rules.rules ? '|' : '') + `max_select:${this.maxSelect}`
        rules.select_max = this.maxSelect
      }

      if (this.selectFlg && this.minSelect) {
        rules.rules += (rules.rules ? '|' : '') + `min_select:${this.minSelect}`
        rules.select_min = this.minSelect
      }

      return rules
    },
  },
  watch: {
    checkedOptions: function (newVal) {
      this.$refs.provider.validate(newVal)
      this.$emit('input', this.localData)
    },
    customOptions: {
      handler() {
        this.$emit('input', this.localData)
      },
      deep: true,
    },
  },
  created() {
    if (
      !this.internalValue ||
      (Array.isArray(this.internalValue) && this.internalValue.length === 0)
    ) {
      this.inputOptions.forEach((option) => {
        if (option.default_type === 'selected' && !this.isDisabled(option)) {
          this.checkedOptions.push(option.id)
        } else if (
          option.default_type === 'only_with_tag' &&
          !this.isDisabled(option) &&
          this.friend &&
          this.friendHasDefaultTag(this.friend.hashtags, option.default_tag)
        ) {
          this.checkedOptions.push(option.id)
        } else if (
          option.default_type === 'only_without_tag' &&
          !this.isDisabled(option) &&
          this.friend &&
          !this.friendHasDefaultTag(this.friend.hashtags, option.default_tag)
        ) {
          this.checkedOptions.push(option.id)
        }

        this.$set(this.customOptions, option.id, '')
      })
    } else {
      this.inputOptions.forEach((option) => {
        let select
        if (Array.isArray(this.internalValue)) {
          select = this.internalValue.find((sl) => {
            if (typeof sl === 'string') {
              return sl === option.id
            } else if (Array.isArray(sl) && sl.length > 0) {
              return sl[0] === option.id
            }
          })
        }

        //vue Reactivity in Depth this.$set
        if (typeof select === 'string') {
          this.checkedOptions.push(select)
          this.$set(this.customOptions, option.id, '')
        } else if (Array.isArray(select) && select.length > 0) {
          this.checkedOptions.push(select[0])
          this.$set(this.customOptions, option.id, select[1])
        } else {
          this.$set(this.customOptions, option.id, '')
        }
      })
    }
  },
  methods: {
    updateLocaleData() {
      return this.checkedOptions.map((optionId) => {
        if (this.customOptions[optionId]) {
          return [optionId, this.customOptions[optionId]]
        } else {
          return optionId
        }
      })
    },
    checkCapacity(optionId) {
      if (
        this.optionCount?.[optionId] &&
        this.optionCount[optionId].capacity !== null
      ) {
        return (
          this.optionCount[optionId].select_count >=
          this.optionCount[optionId].capacity
        )
      } else {
        return false
      }
    },
    isDisabled(option) {
      return this.checkCapacity(option.id) && option.limit_type === 'use'
    },
    friendHasDefaultTag(friendTags, defaultTag) {
      if (Array.isArray(friendTags)) {
        return friendTags.some((tag) => {
          return tag.id === defaultTag?.id
        })
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.vf-hidden-label ::v-deep {
  .v-input {
    padding-top: 0;
  }

  fieldset > legend {
    display: none;
  }

  .v-label {
    display: none;
  }
}
</style>
