<template>
  <div>
    <PtTitle />
    {{ $t('subtitle.message') }}
    <v-card outlined class="transparent">
      <v-card-title class="p-0 pb-2">
        <v-card outlined>
          <v-expansion-panels v-model="accordion" accordion>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div color="red">{{ $t('lbl.search_condition') }}</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="py-6">
                <v-card outlined>
                  <v-card-text class="pb-0">
                    <v-row class="py-0 px-4">
                      <VfCheckbox
                        v-for="message_status in opts.message_status"
                        v-model="searchOpts.message_status"
                        class="my-0 mr-6"
                        :label="
                          $t(`enum.message.message_status.${message_status}`)
                        "
                        :checkbox-value="message_status"
                        multiple
                      />
                    </v-row>
                  </v-card-text>
                  <VDivider class="ma-0" />
                  <v-card-text class="pb-0">
                    <v-row class="py-0 px-4">
                      <VfCheckbox
                        v-for="message_type in opts.message_type"
                        v-model="searchOpts.message_type"
                        class="my-0 mr-6"
                        :label="$t(`enum.message.message_type.${message_type}`)"
                        :checkbox-value="message_type"
                        multiple
                      />
                    </v-row>
                  </v-card-text>
                  <VDivider class="ma-0" />
                  <v-card-text class="pb-0">
                    <v-row class="py-0">
                      <v-col>
                        <div class="mb-4">
                          {{ $t('lbl.search_with_friend') }}
                        </div>
                        <PtSearchSelectedList
                          v-if="searchWrap"
                          v-model="searchWrap"
                          class="mb-4"
                          @pt-search-selected-list-save="
                            ptSearchSelectedListSave
                          "
                          @pt-search-selected-list-clear="
                            ptSearchSelectedListSave
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-card-title>
      <VDivider class="my-0" />
      <v-card outlined>
        <VfLoadingOverlayAbsolute :loading="loadingTable" />
        <PtMessagesTable
          :headers="headers"
          :items="items"
          :total-count="totalCount"
          :options.sync="options"
          :checked-items.sync="checkedItems"
          @open-dialog-message-status="openDialogMessageStatus"
        />
      </v-card>
    </v-card>
    <v-card v-if="checkedItems.length > 0" class="mt-4">
      <v-card-title>{{ $t('btn.multi') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6">
            <VfSelect
              v-model="checked_message_status"
              :label="$$tm('friend.message_status')"
              :selects="opts.message_status"
            />
          </v-col>
          <v-col md="6">
            <v-btn color="primary" @click="multiMessageStatus">{{
              $t('btn.update')
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <PtDialogLinePreview
      :key="selectedItem.id"
      v-model="previewDialog"
      type="message"
      :model-id="selectedItem.id"
    />
    <v-dialog v-model="dialogMessageStatus" max-width="400">
      <v-card outlined>
        <v-card-text class="pt-6">
          <VfSelect
            v-model="selectedItemClone.message_status"
            :label="$$tm('message.message_status')"
            :selects="opts.message_status"
          />
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="closeDialogMessageStatus">{{
            $t('btn.cancel')
          }}</v-btn>
          <v-btn color="primary" @click="updateDialogMessageStatus">{{
            $t('btn.update')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtDialogLinePreview from 'components/parts/common/PtDialogLinePreview'
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
import PtMessagesTable from 'components/parts/unique/PtMessagesTable'
export default {
  components: {
    PtDialogLinePreview,
    PtSearchSelectedList,
    PtMessagesTable,
  },
  data: () => ({
    firstInit: false,
    loading: false,
    loadingTable: false,
    dialogMessageStatus: false,
    dialogMessageStatusLoading: false,
    headers: [],
    items: [],
    checkedItems: [],
    opts: {},
    previewDialog: false,
    selectedItem: { id: '' },
    selectedItemClone: {},
    searchOpts: {},
    searchWrap: null,
    accordion: null,
    checked_message_status: '',
    totalCount: 0,
    options: {
      page: 1,
      per: 50,
    },
  }),
  computed: {
    hasRole() {
      return this.$store.getters.roles.includes('friend_edit')
    },
  },
  watch: {
    searchOpts: {
      handler() {
        if (!this.firstInit) {
          this.firstInit = true
          return
        }
        this.options.page = 1
        this.search()
      },
      deep: true,
    },
    options: {
      handler() {
        this.options.per = this.options.itemsPerPage
        this.search()
      },
      deep: true,
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.checked_message_status = 'done'
      const path = '/api/v1/messages'
      await this.axios
        .get(path, { params: this.$$reqPrms(this.options) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.messages
          this.opts = res.data.opts
          this.searchWrap = res.data.search_wrap
          this.searchOpts = res.data.search_opts
          this.totalCount = res.data.total_count
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    showItem(item) {
      this.selectedItem = item
      this.previewDialog = true
    },
    openDialogMessageStatus(val) {
      if (!this.hasRole) {
        return
      }
      this.selectedItem = val
      this.selectedItemClone = this.$$clone(val)
      this.dialogMessageStatus = true
    },
    closeDialogMessageStatus() {
      this.dialogMessageStatus = false
    },
    async updateDialogMessageStatus() {
      this.errorMsg = ''
      this.dialogMessageStatusLoading = true
      const path = `/api/v1/messages/${this.selectedItem.id}/update_message_status`
      const prms = { message: this.selectedItemClone }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          Object.assign(this.selectedItem, this.selectedItemClone)
          this.$toasted.success(this.$t('success.update'))
          this.dialogMessageStatus = false
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.dialogMessageStatusLoading = false
        })
    },
    async search() {
      this.loadingTable = true
      const path = '/api/v1/messages/search'
      const prms = {
        search_wrap: this.searchWrap,
        search_opts: this.searchOpts,
        ...this.options,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then((res) => {
          this.items = res.data.messages
          this.totalCount = res.data.total_count
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    async ptSearchSelectedListSave() {
      this.options.page = 1
      this.search()
    },
    async multiMessageStatus() {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/messages/multi_message_status`
      const prms = {
        messages: this.checkedItems,
        message_status: this.checked_message_status,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.checkedItems.forEach((item) => {
            this.$set(item, 'message_status', this.checked_message_status)
          })
          this.$toasted.success(this.$t('success.update'))
          this.checkedItems = []
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
