<template>
  <div>
    <v-form @submit.prevent="save">
      <v-card outlined>
        <v-card-title>テンプレート</v-card-title>
        <v-card-text>
          <v-tabs v-model="selectedTab">
            <v-tab v-for="(val, key) in tabItems" :key="key">{{ val }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item v-for="(val, key, tabIndex) in tabItems" :key="key">
              <v-item-group mandatory>
                <v-row no-gutters>
                  <v-col
                    v-for="layout in areaTemplates[tabIndex]"
                    :key="layout.value"
                    cols="6"
                    md="1"
                  >
                    <v-item v-slot="{ toggle }">
                      <v-card
                        :color="
                          layout.value == internalValue.template_type
                            ? 'primary'
                            : ''
                        "
                        class="pa-1 ml-3"
                        @click="
                          toggle()
                          selectLayout(layout.value)
                        "
                      >
                        <VImg :src="layout.image" />
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <PtDialogRichMenuTemplate
          v-model="templateDialog"
          :detail="$t('confirm.template_done')"
          :template-type="templateType"
          target-emit="done-template"
          @done-template="applyTemplate"
        />
      </v-card>
    </v-form>
  </div>
</template>
<script>
import PtDialogRichMenuTemplate from 'components/parts/common/PtDialogRichMenuTemplate'
export default {
  components: {
    PtDialogRichMenuTemplate,
  },
  props: {
    value: { type: Object, required: true },
    areaTemplates: { type: Array, required: true },
    selectEmit: { type: String, default: 'select-emit' },
    opts: { type: Object, required: true },
  },
  data: () => ({
    dialog: false,
    deleteDialog: false,
    templateDialog: false,
    templateType: '',
    areaCount: 0,
    selectedTab: 0,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    tabItems() {
      if (this.areaTemplates.length == 5) {
        return this.opts.template_group
      } else if (this.areaTemplates.length == 1) {
        const result = {}
        const key = 'header_none'
        result[key] = this.opts.template_group[key]
        return result
      }
    },
  },
  created() {
    this.initTab()
  },
  methods: {
    selectLayout(val) {
      this.templateDialog = true
      this.templateType = val
    },
    applyTemplate(templateType) {
      const areaTemplate = this.areaTemplates[this.selectedTab].find(
        (template) => template.value === templateType
      )
      this.internalValue.template_type = templateType
      this.areaCount = areaTemplate.area
      this.internalValue.rich_menu_contents.length = 0
      for (let i = 0; i < this.areaCount; i++) {
        this.internalValue.rich_menu_contents.push({
          sort_no: i + 1,
          action_type: 'url_open',
          multi_text: '',
          action_arr: [],
        })
      }
      this.$emit(this.selectEmit, templateType)
    },
    initTab() {
      this.areaTemplates.forEach((areaTemplateGroup, index) => {
        const findAreaTemplate = areaTemplateGroup.find(
          (areaTemplate) =>
            areaTemplate.value === this.internalValue.template_type
        )
        if (findAreaTemplate) {
          this.selectedTab = index
        }
      })
    },
  },
}
</script>
