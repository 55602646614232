<template>
  <div>
    <v-card outlined class="transparent">
      <v-card-title class="pa-0 or-card-title" style="height: 54px">
        {{ $t('lbl.message_status_count') }}
      </v-card-title>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <VfAlert v-model="errorMsg" type="error" />
      <v-simple-table class="message_status_table">
        <template #default>
          <thead>
            <tr>
              <th>ステータス</th>
              <th>人数</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(key, ja) in messageStatus" :key="key">
              <td class="text-start">
                <v-chip dark label :color="colors[key]">{{ ja }}</v-chip>
              </td>
              <td class="text-start">
                <v-chip
                  v-if="counts[key] != null"
                  dark
                  color="default"
                  text-color="white"
                  >{{ counts[key] }}</v-chip
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    errorMsg: '',
    colors: {
      unread: 'primary',
      magazine_comment: 'primary',
      low_important: 'primary',
      done: 'default',
      claim: 'red',
      closing: 'red',
      trouble: 'red',
      question: 'red',
    },
    counts: {},
    messageStatus: {},
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.errorMsg = ''
      const path = '/api/v1/friends/status'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.counts = res.data.counts
          this.messageStatus = res.data.message_status
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
      this.loading = false
    },
  },
}
</script>
<style scoped>
.message_status_table tr td {
  text-align: center;
}

.v-chip__content {
  display: block;
  height: auto;
}
</style>
