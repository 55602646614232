<template>
  <div>
    <v-row>
      <v-col md="6" class="pb-0">
        <VfSelect
          v-model="internalValue.scenario_read_type"
          :label="$$tm('search_scenario.scenario_read_type')"
          :vees="vees.scenario_read_type"
          :selects="opts.scenario_read_type"
        />
      </v-col>
    </v-row>
    <template v-if="internalValue.scenario_read_type === 'doing'">
      <v-row>
        <v-col md="6">
          <PtDialogSelect
            v-model="internalValue"
            select-type="scenario"
            :single-select="true"
            :hide-folder="true"
            :vees="vees.name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" class="py-0">
          <VfSelect
            v-model="internalValue.search_scenario_type"
            :label="$$tm('search_scenario.search_scenario_type')"
            :vees="vees.search_scenario_type"
            :selects="opts.search_scenario_type"
          />
        </v-col>
        <v-col
          v-if="internalValue.search_scenario_type === 'timing'"
          md="6"
          class="py-0"
        >
          <PtDialogSelect
            v-model="internalValue"
            select-type="scenario_timing"
            :single-select="true"
            :hide-folder="true"
            :vees="vees.timing"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtDialogSelect,
  },
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    opts: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    firstInit: false,
    loading: false,
    dialog: false,
    previewDialog: false,
    errorMsg: '',
    headers: [],
    items: [],
    checkedItems: [],
    timingDialog: false,
    timingHeaders: [],
    timingItems: [],
    checkedTimingItems: [],
    scenarioId: '',
    scenario: {},
    dialogCheckedItems: [],
    scenarioTimingId: '',
    scenarioTiming: {},
    dialogCheckedTimingItems: [],
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    selects() {
      const ret = {}
      this.checkedItems.forEach((item) => (ret[item.name] = item.id))
      return ret
    },
    dialogSelects() {
      const ret = {}
      this.dialogCheckedItems.forEach((item) => (ret[item.name] = item.id))
      return ret
    },
    selectTimings() {
      const ret = {}
      this.checkedTimingItems.forEach((item) => (ret[item.name] = item.id))
      return ret
    },
    dialogSelectTimings() {
      const ret = {}
      this.dialogCheckedTimingItems.forEach(
        (item) => (ret[item.name] = item.id)
      )
      return ret
    },
  },
  watch: {
    checkedItems(arr) {
      if (this.firstInit) {
        this.checkedTimingItems = []
      }
      this.firstInit = true
      if (arr.length === 0) {
        this.internalValue.scenario_id = ''
        this.$set(this.internalValue, 'scenario', { id: '', name: '' })
        return
      }
      this.internalValue.scenario_id = arr[0].id
      this.$set(this.internalValue, 'scenario', {
        id: arr[0].id,
        name: arr[0].name,
      })
    },
    dialogCheckedItems(arr) {
      if (arr.length === 0) {
        this.scenarioId = ''
        this.scenario = { id: '', name: '' }
        return
      }
      this.scenarioId = arr[0].id
      this.scenario = { id: arr[0].id, name: arr[0].name }
    },
    checkedTimingItems(arr) {
      if (arr.length === 0) {
        this.internalValue.scenario_timing_id = ''
        this.$set(this.internalValue, 'scenario_timing', { id: '', name: '' })
        return
      }
      this.internalValue.scenario_timing_id = arr[0].id
      this.$set(this.internalValue, 'scenario_timing', {
        id: arr[0].id,
        name: arr[0].name,
      })
    },
    dialogCheckedTimingItems(arr) {
      if (arr.length === 0) {
        this.scenarioTimingId = ''
        this.scenarioTiming = { id: '', name: '' }
        return
      }
      this.scenarioTimingId = arr[0].id
      this.scenarioTiming = { id: arr[0].id, name: arr[0].name }
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      if (!this.internalValue.scenario) {
        return
      }
      this.checkedItems = [
        {
          id: this.internalValue.scenario_id,
          name: this.internalValue.scenario.name,
        },
      ]
      if (!this.internalValue.scenario_timing) {
        return
      }
      this.checkedTimingItems = [
        {
          id: this.internalValue.scenario_timing_id,
          name: this.internalValue.scenario_timing.name,
        },
      ]
    },
    openDialog() {
      this.dialogCheckedItems = this.$$clone(this.checkedItems)
      this.dialog = true
      this.initTable()
    },
    openTimingDialog() {
      this.dialogCheckedTimingItems = this.$$clone(this.checkedTimingItems)
      this.timingDialog = true
      this.initTimingTable()
    },
    async initTable() {
      this.loading = true
      const path = '/api/v1/scenarios/action_group'
      const prms = {}
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.scenarios
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async initTimingTable() {
      this.errorMsg = ''
      this.loading = true
      const path = '/api/v1/scenario_timings/action_group'
      const prms = { scenario_id: this.internalValue.scenario_id }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.timingHeaders = res.data.thead
          this.timingItems = res.data.scenario_timings
          if (!this.timingItems.length) {
            this.errorMsg = 'シナリオタイミングが登録されていません。'
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
    rowTimingClick(item, opts) {
      opts.select(!opts.isSelected)
    },
    decide() {
      this.checkedItems = this.$$clone(this.dialogCheckedItems)
      this.dialog = false
    },
    decideTimingDialog() {
      this.checkedTimingItems = this.$$clone(this.dialogCheckedTimingItems)
      this.timingDialog = false
    },
    cancel() {
      this.dialog = false
    },
    cancelTimingDialog() {
      this.timingDialog = false
    },
  },
}
</script>
