<template>
  <v-row>
    <v-col v-if="shows.year" class="py-0">
      <VfSelect
        v-model="year"
        v-bind="dateTimeSelectProps"
        :selects="selects.year"
        :vees="vees"
        :no-label="!labelDisp.year"
        :disabled="disabled"
      >
        <template #append-outer>
          <div class="mt-1">{{ $t('lbl.year') }}</div>
        </template>
      </VfSelect>
    </v-col>
    <v-col v-if="shows.month" class="py-0">
      <VfSelect
        v-model="month"
        v-bind="dateTimeSelectProps"
        :selects="selects.month"
        :vees="vees"
        :no-label="!labelDisp.month"
        :disabled="disabled"
      >
        <template #append-outer>
          <div class="mt-1">{{ $t('lbl.month') }}</div>
        </template>
      </VfSelect>
    </v-col>
    <v-col v-if="shows.date" class="py-0">
      <VfSelect
        v-model="date"
        v-bind="dateTimeSelectProps"
        :selects="selects.date"
        :vees="vees"
        :no-label="!labelDisp.date"
        :disabled="disabled"
      >
        <template #append-outer>
          <div class="mt-1">{{ $t('lbl.date') }}</div>
        </template>
      </VfSelect>
    </v-col>
    <v-col v-if="shows.hour" class="py-0">
      <VfSelect
        v-model="hour"
        v-bind="dateTimeSelectProps"
        :selects="selects.hour"
        :vees="vees"
        :no-label="!labelDisp.hour"
        :disabled="disabled"
      >
        <template #append-outer>
          <div class="mt-1">{{ $t('lbl.hour') }}</div>
        </template>
      </VfSelect>
    </v-col>
    <v-col v-if="shows.minute" class="py-0">
      <VfSelect
        v-model="minute"
        v-bind="dateTimeSelectProps"
        :selects="selects.minute"
        :vees="vees"
        :no-label="!labelDisp.minute"
        :disabled="disabled"
      >
        <template #append-outer>
          <div class="mt-1">{{ $t('lbl.minute') }}</div>
        </template>
      </VfSelect>
    </v-col>
    <v-col v-if="shows.second" class="py-0">
      <VfSelect
        v-model="second"
        v-bind="dateTimeSelectProps"
        :selects="selects.second"
        :vees="vees"
        :no-label="!labelDisp.second"
        :disabled="disabled"
      >
        <template #append-outer>
          <div class="mt-1">{{ $t('lbl.second') }}</div>
        </template>
      </VfSelect>
    </v-col>
  </v-row>
</template>
<script>
import mixin from 'components/vuetifyParts/commonExport'
import moment from 'moment'
export default {
  mixins: [mixin],
  props: {
    value: { default: null },
    disabled: { type: Boolean, default: false },
    format: { type: String, default: '' },
    hourInterval: { type: Number, default: 1 },
    minuteInterval: { type: Number, default: 1 },
    secondInterval: { type: Number, default: 1 },
    onlyDate: { type: Boolean, default: false },
    onlyTime: { type: Boolean, default: false },
    rangeSt: { type: String, default: '1900/1/1 00:00:00' },
    rangeEd: { type: String, default: '2100/12/31 23:59:59' },
  },
  computed: {
    momentSt() {
      return this.$$moment(new Date(this.rangeSt))
    },
    momentEd() {
      return this.$$moment(new Date(this.rangeEd))
    },
    types() {
      return ['year', 'month', 'date', 'hour', 'minute', 'second']
    },
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', !value ? null : this.$$moment(value).toISOString())
      },
    },
    formatter() {
      if (this.format) {
        return this.format
      }
      if (this.onlyDate) {
        return 'YYYY/MM/DD'
      }
      if (this.onlyTime) {
        return 'HH:mm'
      }
      return 'YYYY/MM/DD HH:mm'
    },
    formattedValue: {
      get() {
        return this.$$fat(this.internalValue, this.formatter)
      },
      set(val) {
        if (!val) {
          this.internalValue = null
        }
      },
    },
    selects() {
      const ret = {}
      ret.year = [...Array(this.momentEd.get('years') + 1).keys()]
        .slice(this.momentSt.get('years'))
        .map((num) => this.$$0pad(num, 4))
      const stMonth =
        this.year === String(this.momentSt.get('years'))
          ? this.momentSt.get('months')
          : 0
      const edMonth =
        this.year === String(this.momentEd.get('years'))
          ? this.momentEd.get('months') + 1
          : 12
      ret.month = [...Array(edMonth).keys()]
        .slice(stMonth)
        .map((num) => this.$$0pad(num + 1, 2))
      const temp = new Date(this.year, this.month, 0)
      const momentTemp = this.$$moment(temp)
      ret.date = [...Array(momentTemp.get('date')).keys()].map((num) =>
        this.$$0pad(num + 1, 2)
      )
      ret.hour = [...Array(24).keys()]
        .filter((num) => num % this.hourInterval === 0)
        .map((num) => this.$$0pad(num, 2))
      ret.minute = [...Array(60).keys()]
        .filter((num) => num % this.minuteInterval === 0)
        .map((num) => this.$$0pad(num, 2))
      ret.second = [...Array(60).keys()]
        .filter((num) => num % this.secondInterval === 0)
        .map((num) => this.$$0pad(num, 2))
      return ret
    },
    moVal: {
      get() {
        return this.$$moment(this.internalValue)
      },
      set() {},
    },
    year: {
      get() {
        return !this.moVal ? '2000' : this.$$0pad(this.moVal.get('year'), 4)
      },
      set(val) {
        this.selectTime('year', val)
      },
    },
    month: {
      // month is 0 orignin
      get() {
        return !this.moVal ? '01' : this.$$0pad(this.moVal.get('month') + 1, 2)
      },
      set(val) {
        this.selectTime('month', parseInt(val) - 1)
      },
    },
    date: {
      get() {
        return !this.moVal ? '01' : this.$$0pad(this.moVal.get('date'), 2)
      },
      set(val) {
        this.selectTime('date', val)
      },
    },
    hour: {
      get() {
        return !this.moVal ? '00' : this.$$0pad(this.moVal.get('hour'), 2)
      },
      set(val) {
        this.selectTime('hour', val)
      },
    },
    minute: {
      get() {
        return !this.moVal ? '00' : this.$$0pad(this.moVal.get('minute'), 2)
      },
      set(val) {
        this.selectTime('minute', val)
      },
    },
    second: {
      get() {
        return !this.moVal ? '00' : this.$$0pad(this.moVal.get('second'), 2)
      },
      set(val) {
        this.selectTime('second', val)
      },
    },
    shows() {
      return {
        year: this.formatter.includes('YYYY'),
        month: this.formatter.includes('MM'),
        date: this.formatter.includes('DD'),
        hour: this.formatter.includes('HH'),
        minute: this.formatter.includes('mm'),
        second: this.formatter.includes('ss'),
      }
    },
    styles() {
      return {
        width4: 'width: 140px;',
        width2: 'width: 120px;',
      }
    },
    isFirst() {
      const ret = {}
      this.types.forEach((type) => (ret[type] = false))
      this.types.some((type) => {
        if (this.shows[type]) {
          ret[type] = true
          return true
        }
      })
      return ret
    },
    labelDisp() {
      const ret = {}
      this.types.forEach((type) => (ret[type] = false))
      if (this.noLabel) {
        return ret
      }
      return this.isFirst
    },
  },
  watch: {
    'selects.month'(val) {
      if (val.some((v) => v === this.month)) {
        return
      }
      this.selectTime('month', parseInt(val[0]) - 1)
    },
  },
  methods: {
    selectTime(type, val) {
      let temp = this.$$moment(this.internalValue)
      if (!temp) {
        temp = moment()
        temp.set({
          year: 2000,
          month: 0,
          date: 1,
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
      }
      temp.set(type, val)
      this.internalValue = temp.toISOString()
      this.$emit('change')
    },
  },
}
</script>
