import VueLazyload from 'vue-lazyload'

const VueLazyloadPlugin = {
  install(Vue) {
    Vue.use(VueLazyload, {
      preLoad: 1.3,
      error: '/noimage.png',
      loading: '/loading.png',
      attempt: 1,
    })
  },
}

export default VueLazyloadPlugin
