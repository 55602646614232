<template>
  <div>
    <v-row>
      <v-tabs
        v-model="tab"
        background-color="blue-grey darken-4"
        centered
        dark
        icons-and-text
      >
        <v-tab href="#tab-1">
          リスト
          <v-icon>mdi-account</v-icon>
        </v-tab>
        <v-tab href="#tab-2">
          メッセージ
          <v-icon>mdi-message</v-icon>
        </v-tab>
        <v-tab href="#tab-3">
          情報
          <v-icon>mdi-clipboard-account</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" :value="'tab-1'">
          <PtUnicastFriendIndex
            @select-friend="selectFriend"
            @active-tab="activeTab"
          />
        </v-tab-item>
        <v-tab-item :key="2" :value="'tab-2'">
          <PtUnicastMessageIndex
            :key="friendId"
            :friend-id="friendId"
            :first-time-flg="first_time_flg"
          />
        </v-tab-item>
        <v-tab-item :key="3" :value="'tab-3'">
          <PtUnicastFriendShow
            :key="friendId"
            :friend-id="friendId"
            :first-time-flg="first_time_flg"
            is-mobile
          />
        </v-tab-item>
      </v-tabs-items>
    </v-row>
  </div>
</template>
<script>
import PtUnicastFriendIndex from 'components/parts/unique/PtUnicastFriendIndex'
import PtUnicastMessageIndex from 'components/parts/unique/PtUnicastMessageIndex'
import PtUnicastFriendShow from 'components/parts/unique/PtUnicastFriendShow'
export default {
  components: {
    PtUnicastFriendIndex,
    PtUnicastMessageIndex,
    PtUnicastFriendShow,
  },
  data: () => ({
    tab: null,
    friendId: '',
    first_time_flg: true,
  }),
  methods: {
    selectFriend(val) {
      this.first_time_flg = false
      this.friendId = val
    },
    activeTab(val) {
      this.tab = val
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
::v-deep .v-window {
  width: 100%;
}

::v-deep .v-card.v-sheet.v-sheet--outlined.theme--light {
  padding-left: 5%;
}

::v-deep .message_input_container {
  position: fixed;
  bottom: 0;
}

::v-deep #chat-area {
  position: fixed;
  bottom: 60px;
  top: 80px;
  right: 0;
  width: 100%;
  height: auto !important;
}

::v-deep .mb-4.v-card.v-sheet.v-sheet--outlined.theme--light {
  min-height: 1000px;
}

::v-deep .profile_image {
  width: 200px;
  margin: auto;
}

::v-deep .profile_name {
  width: fit-content;
  margin: auto;
}
</style>
