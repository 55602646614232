<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-row>
      <v-col md="6" class="pb-0">
        <VfTextField
          v-model="internalValue.title"
          :label="$t('lbl.answer_form.item_name')"
          :vees="vees.answer_form_input.title"
        />
      </v-col>
      <v-col md="6" class="pb-0">
        <VfCheckbox
          v-model="internalValue.hide_title_flg"
          :label="$t('lbl.answer_form.hide_item_name')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="pb-4">
        <VfTextField
          v-model="internalValue.description"
          :label="$t('lbl.answer_form.description')"
          :vees="vees.answer_form_input.short_description"
          :hint="$t('lbl.answer_form.text_field_desc_hint')"
        />
      </v-col>
    </v-row>
    <v-row v-if="internalValue.action_flg">
      <v-col md="6" class="py-0" @click="openDialog">
        <VfSelect
          v-model="internalValue.action_arr[0].data.reminder_id"
          :label="$$tm('reminder.name')"
          :selects="selects"
          :vees="{ rules: 'required', required: true }"
          readonly
        />
      </v-col>
    </v-row>
    <v-row v-if="internalValue.action_flg">
      <v-col>
        <v-row>
          <v-col>
            <VfTextField
              v-model="date"
              :label="$t('lbl.answer_form.goal_date_and_time')"
              :placeholder="
                $t('lbl.answer_form.placeholder_goal_date_and_time')
              "
              filled
              readonly
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mt-5">
        <VfDateTimeSelect
          v-model="timePick"
          :label="$$tm('action_reminder.goal_at')"
          :vees="vees.disp_st_at"
          format="HH:mm"
          :only-time="true"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="8">
        <VfCheckbox
          v-model="internalValue.action_flg"
          :label="$t('lbl.answer_form.set_a_reminder')"
          :hint="$t('lbl.answer_form.reminder_note')"
          :hide-details="'auto'"
          :persistent-hint="true"
        />
      </v-col>
      <v-col md="4">
        <v-row>
          <v-col md="6" class="pb-0">
            <VfCheckbox
              v-model="internalValue.require_flg"
              :label="$t('lbl.answer_form.require')"
            />
          </v-col>
          <v-col md="6" class="pb-0">
            <VfCheckbox
              v-model="internalValue.hidden_flg"
              :label="$t('lbl.answer_form.hide')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!--dialog show choices reminder-->
    <v-dialog v-if="internalValue.action_flg" v-model="dialog" max-width="800">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <VfSelect
                    v-model="internalValue.action_arr[0].data.reminder_id"
                    :label="$$tm('reminder.name')"
                    :selects="selects"
                    readonly
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="4">
              <PtFolder
                ref="folder"
                folder-type="reminder"
                select-only
                @select-folder="selectFolder"
              />
            </v-col>
            <v-col md="8">
              <v-data-table
                v-model="checkedItems"
                :headers="headers"
                :items="items"
                item-key="id"
                show-select
                :single-select="true"
                :disable-sort="true"
                :items-per-page="50"
                :footer-props="{
                  itemsPerPageOptions: [50, 100, 150],
                }"
                @click:row="rowClick"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PtFolder from 'components/parts/common/PtFolder'
export default {
  name: 'PtFormSetupDate',
  components: {
    PtFolder,
  },
  props: {
    value: { type: Object, required: true },
    targets: { type: Array, default: () => [] },
    vees: { type: Object, default: () => ({}) },
    opts: { type: Object, default: () => ({}) },
  },
  data: function () {
    return {
      date: null,
      loading: false,
      dialog: false,
      headers: [],
      items: [],
      checkedItems: [],
      timePick: '',
      errorMsg: '',
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    selects() {
      const ret = {}
      this.checkedItems.forEach((item) => (ret[item.name] = item.id))
      return ret
    },
  },
  watch: {
    checkedItems(arr) {
      if (arr.length === 0) {
        this.internalValue.reminder_id = ''
        this.$set(this.internalValue.action_arr[0].data, 'reminder', {})
        return
      }
      this.internalValue.action_arr[0].data.reminder_id = arr[0].id
      this.$set(this.internalValue.action_arr[0].data, 'reminder', {
        id: arr[0].id,
        name: arr[0].name,
      })
    },
    timePick(val) {
      if (
        this.internalValue.action_arr &&
        this.internalValue.action_arr.hasOwnProperty(0)
      ) {
        let arrString = val.split(' ')
        if (arrString.length > 1) {
          return
        }
        const dt = this.$$fdt(new Date())
        this.internalValue.action_arr[0].data.goal_at = `${dt} ${val}`
      }
    },
  },
  mounted() {
    if (this.internalValue.additional_option_type === null) {
      this.internalValue.additional_option_type = 'do_nothing'
    }
    this.init()
  },
  methods: {
    init() {
      const timeDefault = '10:00'
      const dateNow = this.$$fdt(new Date())
      let dateTime = `${dateNow} ${timeDefault}`
      dateTime = this.$$moment(dateTime).toISOString()

      if (
        this.internalValue.action_arr &&
        this.internalValue.action_arr.length &&
        this.internalValue.action_arr[0].data.goal_at
      ) {
        this.timePick = this.internalValue.action_arr[0].data.goal_at

        let arr = this.internalValue.action_arr[0].data.goal_at.split(' ')
        if (arr.length > 1) {
          this.timePick = arr[1]
        }
      } else {
        this.timePick = dateTime
      }

      if (
        !this.internalValue.action_arr ||
        this.internalValue.action_arr.length === 0
      ) {
        this.internalValue.action_arr = [
          {
            data: {
              cancel_goal_type: 'no_goal',
              goal_at: dateTime,
              reminder: {},
              reminder_action_type: 'start',
              reminder_id: null,
              sort_no: 0,
            },
            type: 'reminder',
          },
        ]
      } else {
        if (
          this.internalValue.action_arr &&
          this.internalValue.action_arr?.[0]?.data?.reminder_id
        ) {
          this.checkedItems = [
            {
              id: this.internalValue.action_arr[0].data.reminder_id,
              name: this.internalValue.action_arr[0].data.reminder.name,
            },
          ]
        }
      }
    },
    openDialog() {
      this.dialog = true
      this.initTable(null)
    },
    async initTable(folderId) {
      this.loading = true
      const path = '/api/v1/reminders/action_group'
      const prms = { folder_id: folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.reminders
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectFolder(folderId) {
      this.initTable(folderId)
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
  },
}
</script>
