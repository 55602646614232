<template>
  <v-card class="my-4">
    <VfLoadingOverlayAbsolute :loading="loading" />
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      :disable-sort="true"
    >
      <template #[`item.created_at`]="{ item }">
        {{ $$fat(item.created_at) }}
      </template>
      <template #[`item.search_wrap_id`]="{ item }">
        <template v-if="!item.search_wrap_id">
          {{ $$tm('broadcast.all_cast_flg') }}
        </template>
        <template v-else="!item.search_wrap_id">
          <PtSearchSelectedList
            v-model="item.search_wrap.as_json_form"
            class="or-list"
            disp-type="list"
          />
        </template>
      </template>
      <template #[`item.deli_id`]="{ item }">
        <PtLinePreviewRow class="my-2" :deli="item.deli" />
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import PtLinePreviewRow from 'components/parts/common/PtLinePreviewRow'
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
export default {
  components: {
    PtLinePreviewRow,
    PtSearchSelectedList,
  },
  props: {
    clickId: { type: String, required: true },
  },
  data: () => ({
    headers: [],
    items: [],
    loading: false,
    csv_data: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/click_broadcasts'
      const prms = { click_id: this.clickId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.click_broadcasts
          let headers = this.headers.map((h) => h.text)
          let items = this.items.map((item) => [
            this.$$fat(item.created_at),
            !item.search_wrap_id
              ? this.$$tm('broadcast.all_cast_flg')
              : this.delivery_destination_for(item),
            this.$$csv_deli(item.deli),
            item.click_count,
            item.visit_count,
          ])
          this.csv_data = [headers].concat(items)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    delivery_destination_for(item) {
      let search_arr = item.search_wrap.as_json_form.search_arr
      return this.searchWrapMsgs_for(search_arr)
        .map((searchGroup) => {
          if (searchGroup.length === 0) {
            return 'none'
          } else {
            return searchGroup.map((msg) => msg.operate).join(', ')
          }
        })
        .join(', ')
    },
    searchWrapMsgs_for(search_arr) {
      return search_arr.map((group) => {
        return group.map((search, index) => {
          const yml = `lbl.search_wrap.search_types_short_msg.${search.type}`
          const ret = {}
          ret.no = index + 1
          switch (search.type) {
            case 'name': {
              ret.type = this.$t(yml)
              const names = []
              if (search.data.api_display_name_flg) {
                names.push(this.$$tm('search_name.api_display_name_flg'))
              }
              if (search.data.system_disp_name_flg) {
                names.push(this.$$tm('search_name.system_disp_name_flg'))
              }
              ret.operate = this.$t('lbl.search_wrap.name_include', {
                targets: names.join(', '),
                name: search.data.name,
              })
              break
            }
            case 'message_status': {
              ret.type = this.$t(yml)
              const sts_arr = search.data.target_message_statuses.map((sts) =>
                this.$t(
                  `enum.search_message_status_choice.message_status.${sts}`
                )
              )
              ret.operate = this.$t('lbl.search_wrap.message_status_in', {
                message_statuses: sts_arr.join(', '),
              })
              break
            }
            case 'hashtag': {
              ret.type = this.$t(yml)
              const type = this.$$te(
                'search_hashtag.search_hashtag_type',
                search.data
              )
              const tags = search.data.hashtags.map((hashtag) => hashtag.name)
              ret.operate = `[${type}][${tags.join(', ')}]`
              break
            }
            case 'scenario':
              ret.type = this.$$te(
                'search_scenario.scenario_read_type',
                search.data
              )
              if (search.data.scenario_read_type === 'stop') {
                break
              }
              ret.operate = this.$t('lbl.search_wrap.scenario_is', {
                scenario: search.data.scenario.name,
                type: this.$$te(
                  'search_scenario.search_scenario_type',
                  search.data
                ),
              })
              break
            case 'reminder':
              ret.type = this.$t(yml)
              ret.operate = this.$t('lbl.search_wrap.reminder_is', {
                reminder: search.data.reminder.name,
                type: this.$$te(
                  'search_reminder.search_reminder_type',
                  search.data
                ),
              })
              break
            case 'last_response':
              ret.type = this.$t(yml)
              ret.operate = this.$t('lbl.search_wrap.last_response_is', {
                from: this.$$fdt(search.data.range_st_dt),
                to: this.$$fdt(search.data.range_ed_dt),
              })
              break
            case 'friend_created_at':
              ret.type = this.$t(yml)
              ret.operate = this.$t('lbl.search_wrap.friend_created_at_is', {
                from: this.$$fdt(search.data.range_st_dt),
                to: this.$$fdt(search.data.range_ed_dt),
              })
              break
            case 'inflow_route': {
              ret.type = this.$t(yml)
              const inflow_route_type = this.$$te(
                'search_inflow_route.search_inflow_route_type',
                search.data
              )
              const inflow_route_tags = search.data.inflow_routes.map(
                (inflow_route) => inflow_route.name
              )
              ret.operate = `[${inflow_route_type}][${inflow_route_tags.join(
                ', '
              )}]`
              break
            }
            case 'rich_menu': {
              ret.type = this.$t(yml)
              const rich_menu_type = this.$$te(
                'search_rich_menu.search_rich_menu_type',
                search.data
              )
              switch (search.data.search_rich_menu_type) {
                case ('now_one', 'now_not'):
                  ret.operate = this.$t('lbl.search_wrap.rich_menu_is', {
                    rich_menu: search.data.rich_menu.name,
                    type: rich_menu_type,
                  })
                default:
                  ret.operate = this.$t('lbl.search_wrap.rich_menu_type', {
                    type: rich_menu_type,
                  })
                  break
              }
              break
            }
            case 'answer_form': {
              ret.type = this.$t(yml)
              const answer_form_type = this.$$te(
                'search_answer_form.search_answer_form_type',
                search.data
              )
              const answer_form = search.data.answer_form.name
              ret.operate = `[${answer_form}] ${answer_form_type}`
              break
            }
          }
          return ret
        })
      })
    },
  },
}
</script>
<style scoped>
.or-list {
  max-width: 300px;
  font-size: 0.5em;
}
</style>
