<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle :title="click.name" />
      </v-col>
      <v-col cols="4" align="right">
        <v-btn
          v-if="this.selectedTab.value != 'info'"
          class="mt-5"
          @click="downloadCSV"
        >
          {{ $t('lbl.click.download_csv') }}
        </v-btn>
        <v-btn
          class="mt-5"
          color="primary"
          :to="{ name: 'ClickForm', params: { id: click.id } }"
        >
          {{ $t('btn.edit') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col cols="12" md="6">
        <v-card :min-height="100">
          <v-card-title>
            {{ click.name }}
          </v-card-title>
          <v-card-text>
            <a :href="click.site_url" target="_blank">{{ click.site_url }}</a>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card :min-height="100">
          <v-simple-table>
            <thead>
              <tr>
                <th>{{ $t('lbl.click.click_count_total') }}</th>
                <th>{{ $t('lbl.click.visit_count') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ click.click_count_total }}</td>
                <td>{{ click.visit_count }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <PtClick
      v-model="click"
      :click="click"
      @selectTab="selectTab"
      ref="ptClick"
    />
  </div>
</template>
<script>
import PtClick from 'components/parts/common/PtClick/PtClick'
export default {
  components: {
    PtClick,
  },
  data: () => ({
    click: {},
    selectedTab: 0,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      const clickId = this.$route.params.id
      const path = `/api/v1/clicks/${clickId}`
      await this.axios
        .get(path)
        .then((res) => {
          this.click = res.data.click
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    async downloadCSV() {
      let csv = this.get_csv_data()
      if (csv.length > 0) {
        let bom = new Uint8Array([0xef, 0xbb, 0xbf])
        let contents = csv.join('\n')
        let blob = new Blob([bom, contents], { type: 'text/csv' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = this.getToday() + `_click_${this.selectedTab.value}.csv`
        link.click()
      }
    },
    selectTab(val) {
      this.selectedTab = val
    },
    getToday() {
      const dt = new Date()
      const year = dt.getFullYear()
      const month = ('00' + (dt.getMonth() + 1)).slice(-2)
      const day = ('00' + dt.getDate()).slice(-2)
      const hour = ('00' + dt.getDate()).slice(-2)
      const minutes = ('00' + dt.getMinutes()).slice(-2)
      const second = ('00' + dt.getSeconds()).slice(-2)
      const result = year + month + day + hour + minutes + second
      return result
    },
    get_csv_data() {
      return this.$refs.ptClick.$refs[this.selectedTab.value]
        ? this.$refs.ptClick.$refs[this.selectedTab.value].csv_data
        : []
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
