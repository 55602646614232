<template>
  <validation-provider
    v-slot="{ errors }"
    :name="label"
    :rules="rules"
    :vid="Math.random().toString()"
  >
    <VTextarea
      v-model="internalValue"
      v-bind="baseProps"
      :rows="rows"
      :error-messages="errors"
      :placeholder="placeholder"
      :auto-grow="autoGrow"
      outlined
    />
  </validation-provider>
</template>
<script>
import mixin from 'components/vuetifyParts/commonExport'
export default {
  mixins: [mixin],
  props: {
    value: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    rows: { type: Number, default: 3 },
    autoGrow: { type: Boolean, default: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
