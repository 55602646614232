<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <template v-for="(search, search_i) in internalValue">
      <v-card :key="search_i">
        <v-card-title class="pa-0 text-subtitle-1 blue-grey lighten-5">
          <v-list-item>
            <v-list-item-content>
              {{ $t(`lbl.search_group.search_types.${search.type}`) }}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                small
                icon
                :disabled="internalValue.length <= 1 && lockFlg"
                @click="removeSearch(search_i)"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>
        <v-card-text>
          <PtSearchName
            v-if="search.type === 'name'"
            v-model="search.data"
            :vees="allVees[search.type]"
            :opts="opts"
          />
          <PtSearchMessageStatus
            v-if="search.type === 'message_status'"
            v-model="search.data"
            :vees="allVees[search.type]"
            :opts="opts"
          />
          <PtSearchHashtag
            v-if="search.type === 'hashtag'"
            v-model="search.data"
            :vees="allVees[search.type]"
            :opts="opts"
          />
          <PtSearchScenario
            v-if="search.type === 'scenario'"
            v-model="search.data"
            :vees="allVees[search.type]"
            :opts="opts"
          />
          <PtSearchReminder
            v-if="search.type === 'reminder'"
            v-model="search.data"
            :vees="allVees[search.type]"
            :opts="opts"
          />
          <PtSearchLastResponse
            v-if="search.type === 'last_response'"
            v-model="search.data"
            :vees="allVees[search.type]"
            :opts="opts"
          />
          <PtSearchFriendCreatedAt
            v-if="search.type === 'friend_created_at'"
            v-model="search.data"
            :vees="allVees[search.type]"
            :opts="opts"
          />
          <PtSearchInflowRoute
            v-if="search.type === 'inflow_route'"
            v-model="search.data"
            :vees="allVees[search.type]"
            :opts="opts"
          />
          <PtSearchRichMenu
            v-if="search.type === 'rich_menu'"
            v-model="search.data"
            :vees="allVees[search.type]"
            :opts="opts"
          />
          <PtSearchAnswerForm
            v-if="search.type === 'answer_form'"
            v-model="search.data"
            :vees="allVees[search.type]"
            :opts="opts"
          />
        </v-card-text>
      </v-card>
    </template>
    <v-row>
      <v-col>
        <v-btn
          v-for="(searchType, disp) in opts.search_types"
          :key="disp"
          class="ma-2"
          @click="addSearch(searchType)"
          >{{ disp }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtSearchName from 'components/parts/common/PtSearchWrap/PtSearchName'
import PtSearchMessageStatus from 'components/parts/common/PtSearchWrap/PtSearchMessageStatus'
import PtSearchHashtag from 'components/parts/common/PtSearchWrap/PtSearchHashtag'
import PtSearchScenario from 'components/parts/common/PtSearchWrap/PtSearchScenario'
import PtSearchReminder from 'components/parts/common/PtSearchWrap/PtSearchReminder'
import PtSearchLastResponse from 'components/parts/common/PtSearchWrap/PtSearchLastResponse'
import PtSearchFriendCreatedAt from 'components/parts/common/PtSearchWrap/PtSearchFriendCreatedAt'
import PtSearchInflowRoute from 'components/parts/common/PtSearchWrap/PtSearchInflowRoute'
import PtSearchRichMenu from 'components/parts/common/PtSearchWrap/PtSearchRichMenu'
import PtSearchAnswerForm from 'components/parts/common/PtSearchWrap/PtSearchAnswerForm'
export default {
  components: {
    PtSearchName,
    PtSearchMessageStatus,
    PtSearchHashtag,
    PtSearchScenario,
    PtSearchReminder,
    PtSearchLastResponse,
    PtSearchFriendCreatedAt,
    PtSearchInflowRoute,
    PtSearchRichMenu,
    PtSearchAnswerForm,
  },
  props: {
    value: { default: null },
    initData: { type: Object, default: () => ({}) },
    opts: { type: Object, default: () => ({}) },
    allVees: { type: Object, default: () => ({}) },
    lockFlg: { type: Boolean, default: false },
  },
  data: () => ({
    firstInit: false,
    loading: false,
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    addSearch(searchType) {
      this.internalValue.push({
        type: searchType,
        data: this.$$clone(this.initData[searchType]),
      })
    },
    removeSearch(index) {
      this.internalValue.splice(index, 1)
    },
  },
}
</script>
