<template>
  <v-row>
    <v-col md="4" sm="6" cols="12">
      <VfSelect
        v-model="internalValue.timing_type"
        :label="$t('lbl.timing_type')"
        :vees="vees.timing_type"
        :selects="timing_types"
        label-hidden
      />
    </v-col>
    <v-col
      class="d-flex align-items-center justify-content-around"
      md="8"
      sm="6"
      cols="12"
      v-if="internalValue.timing_type === 'delay'"
    >
      <div><v-icon>mdi-arrow-right-bold</v-icon>{{ $t('lbl.reaction') }}</div>
      <div class="w-50 mx-3">
        <VfSelect
          v-model="internalValue.minute"
          :selects="minute"
          :vees="vees.second"
          :label="' '"
        >
        </VfSelect>
      </div>
      <div>{{ $t('lbl.after_minute') }}</div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    timing_types: { type: Object, required: true },
    value: {
      type: Object,
      required: true,
      default: () => ({ timing_type: 'immediately' }),
    },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    minute() {
      return [...Array(60).keys()].map((num) => num + 1)
    },
  },
}
</script>
