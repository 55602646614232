<template>
  <v-card class="mx-auto py-4 mt-8" :max-width="500">
    <validation-observer ref="observer">
      <v-form @submit.prevent="save">
        <v-card-title>
          <v-row justify="center">
            <div>{{ $t(`meta.title.${$route.name}`) }}</div>
          </v-row>
        </v-card-title>
        <v-divider class="mx-4" />
        <v-card-text align="center">
          <div>発行したLine連携コードを入力してください</div>
          <vf-text-field
            v-model="code"
            label="line連携コード"
            :vees="{ rules: 'required' }"
          />
          <vue-recaptcha
            ref="recaptcha"
            sitekey="6LeIc-UUAAAAAF_hZmOAtE6maZozgVQCH3nEdvBh"
            @verify="onVerify"
          />
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col align="center">
              <v-btn
                :width="200"
                type="submit"
                color="primary"
                :disabled="loading"
              >
                送信
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha'
export default {
  components: {
    'vue-recaptcha': VueRecaptcha,
  },
  data: () => ({
    code: '',
    loading: false,
  }),
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.loading) {
        return
      }
      this.loading = true
      this.axios
        .post('/api/v1/sp/verify', { code: this.code })
        .then((res) => {
          this.$store.dispatch('login', res)
          this.$store.dispatch('selectChannel', res.data.channel)
          this.$store.dispatch('selectChannelRoles', res.data.roles)
          this.$router.push({ name: 'SpIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onVerify(response) {
      if (response) {
        this.form.robot = true
      }
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
