<template>
  <div class="import-csv-log">
    <PtTitle />
    <h3 class="text-24">{{ $t('subtitle.import_log') }}</h3>

    <v-row>
      <v-col md="12">
        <v-card>
          <v-card-text>
            <div class="d-flex align-center justify-space-between">
              <div>
                <p class="font-weight-bold">{{ csvDetail.csv_name }}</p>
                <p class="mb-0">
                  {{ $t('lbl.import.log.import_at') }}：{{ importAt }}
                </p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12">
        <v-card>
          <v-card-title class="text-14">
            {{ $t('lbl.import.log.attention') }}
          </v-card-title>

          <v-card-text>
            <div
              class="import-csv-log__fail-info"
              v-html="(csvDetail.log_import || '').trim()"
            />

            <p v-if="isConfirm" class="mt-5 text-14">
              {{ $t('lbl.import.log.action_warning') }}
            </p>

            <div class="mt-10">
              <v-btn v-if="isConfirm" color="success" @click="importData">{{
                $t('lbl.import.log.btn_confirm_action')
              }}</v-btn>
              <v-btn v-else color="success" disabled>{{
                $t('lbl.import.log.btn_complete_action')
              }}</v-btn>

              <p class="mt-10">
                <a href="#" @click="$router.push({ name: 'FriendIndex' })">{{
                  $t('lbl.import.btn_back')
                }}</a>
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      csvDetail: {},
      loading: false,
    }
  },
  computed: {
    isConfirm() {
      return this.csvDetail.status === 'confirm'
    },

    importAt() {
      if (!this.csvDetail.created_at) return ''

      return this.$$moment(this.csvDetail.created_at).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    },
  },
  mounted() {
    this.initLog()
  },
  methods: {
    async initLog() {
      this.loading = true
      const path = `/api/v1/importer_csv_friend_lists/${this.$route.params.id}`

      await this.axios
        .get(path)
        .then((res) => {
          this.csvDetail = res.data.importer_csv_friend_list || {}
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async importData() {
      const csvId = this.$route.params.id || ''
      if (!csvId) {
        this.$$ajaxError('ID missing!')
      }

      const path = `/api/v1/importer_csv_friend_lists/${csvId}/confirm_import`

      this.loading = true

      await this.axios
        .post(path, this.$$reqPrms({ id: csvId }))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))

          this.$router.push({ name: 'FriendIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>

<style lang="scss" scoped>
.import-csv-log {
  &__fail-info {
    background-color: #f5f5f5;
    padding: 12px;
    white-space: pre-line;
  }

  .v-card {
    &__title {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
</style>
