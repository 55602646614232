<template>
  <v-dialog v-model="dialog" :max-width="500">
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" icon outlined v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col align="center" class="pa-0">
            {{ $t('lbl.credit_card.add') }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-row>
        <v-col align="center" class="mb-4">
          <pt-payjp-form v-if="dialog" @payjp:created="save" />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import PtPayjpForm from 'components/parts/unique/PtPayjpForm'
export default {
  components: {
    PtPayjpForm,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    save(token) {
      this.dialog = false
      this.$emit('create:token', token)
    },
    cancel() {
      this.dialog = false
    },
  },
}
</script>
