<template>
  <div>
    <div class="mt-4">{{ $t('lbl.click.statistics') }}</div>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th>{{ $t('lbl.click.click_count') }}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t('lbl.click.send_count_total') }}</td>
                  <td>
                    {{ click.send_count_total }}{{ $t('lbl.click.unit_count') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('lbl.click.click_count_total') }}</td>
                  <td>
                    {{ click.click_count_total
                    }}{{ $t('lbl.click.unit_count') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('lbl.click.click_count/send_count') }}</td>
                  <td>{{ clickCountPerSendCount }}%</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th>{{ $t('lbl.click.number_of_peple') }}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ $t('lbl.click.send_count') }}</td>
                  <td>
                    {{ click.send_friends_count
                    }}{{ $t('lbl.click.friend_count') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('lbl.click.visit_count') }}</td>
                  <td>
                    {{ click.visit_count }}{{ $t('lbl.click.friend_count') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('lbl.click.visit_count/send_count') }}</td>
                  <td>{{ visitCountPerSendCount }}%</td>
                </tr>
                <tr>
                  <td>{{ $t('lbl.click.visit_count/friend_count') }}</td>
                  <td>{{ visitCountPerFriendCount }}%</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    click: { type: Object, required: true },
  },
  computed: {
    clickCountPerSendCount() {
      return this.calcRate(
        this.click.click_count_total,
        this.click.send_count_total
      )
    },
    visitCountPerSendCount() {
      return this.calcRate(
        this.click.visit_count,
        this.click.send_friends_count
      )
    },
    visitCountPerFriendCount() {
      return this.calcRate(this.click.visit_count, this.click.friends_count)
    },
  },
  methods: {
    calcRate(numerator, denominator) {
      if (!denominator || denominator === 0) {
        return 0
      }
      const rate = (numerator / denominator) * 100
      const roundedValue = Math.round(rate * 10) / 10
      return roundedValue
    },
  },
}
</script>
