<template>
  <v-dialog v-model="dialog" :max-width="1200">
    <template #activator="{ on, attrs }">
      <v-chip class="mr-2" label v-bind="attrs" v-on="on">
        {{ $t('lbl.unicast.search') }}
      </v-chip>
    </template>
    <v-card v-if="dialog">
      <v-card-title class="green--text text--darken-1">
        {{ $t('lbl.search_wrap.setting') }}
      </v-card-title>
      <v-container>
        <v-tabs v-model="tab" grow>
          <v-tab v-for="tab in tabs" :key="tab.value">
            {{ tab.text }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="px-4 pb-4">
          <v-tab-item>
            <HashtagSearch v-model="hashtags" />
          </v-tab-item>
          <v-tab-item>
            <TamplateSearch ref="tamplateSearchRef" v-model="searchWrap" />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
      <VDivider />
      <v-card-actions>
        <VSpacer />
        <v-btn color="submit" @click="clear">{{ $t('btn.clear') }}</v-btn>
        <v-btn color="primary" @click="decide">{{ $t('btn.decide') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TamplateSearch from 'components/parts/unique/PtUnicastFriendIndex/TemplateSearch'
import HashtagSearch from 'components/parts/unique/PtUnicastFriendIndex/HashtagSearch'
export default {
  components: {
    TamplateSearch,
    HashtagSearch,
  },
  props: {
    defaultSearchWrap: { type: Object, required: true },
  },
  data: () => ({
    dialog: false,
    tab: 0,
    hashtags: [],
    searchWrap: {},
    cloneSearchWrap: {},
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    tabs() {
      return [
        { text: this.$t('lbl.friend.hashtag'), value: 'hashtag' },
        {
          text: this.$t('lbl.friend.search_template'),
          value: 'search_template',
        },
      ]
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.init()
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.tab = 0
      this.hashtags = []
      this.searchWrap = {}
      this.cloneSearchWrap = this.$$clone(this.defaultSearchWrap)
    },
    async decide() {
      if (this.tab === 0) {
        this.dialog = false
        const targetHashtagIds = this.hashtags.map((row) => row.id)
        this.cloneSearchWrap.search_arr[0] = [
          {
            type: 'hashtag',
            data: {
              target_hashtag_ids: targetHashtagIds,
              sort_no: 1,
              search_hashtag_type: 'or',
            },
          },
        ]
        this.$emit('decide', this.cloneSearchWrap)
      } else {
        const isValid =
          await this.$refs.tamplateSearchRef.$refs.searchTemplateObserver.validate()
        if (isValid) {
          this.dialog = false
          this.$emit('decide', this.searchWrap)
        } else {
          this.$toasted.error(this.$t('danger.params'))
        }
      }
    },
    clear() {
      this.dialog = false
      this.$emit('clear')
    },
  },
}
</script>
