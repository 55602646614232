<template>
  <div>
    <div class="mb-4">
      <PtTitle />
      <div class="or-break">{{ $t('subtitle.line_notice') }}</div>
    </div>
    <notify-form
      v-model="lineNotice"
      class="mb-4"
      :channels="channels"
      :vees="vees"
      :notice-types="opts.notice_type"
      @save="save"
    />
    <setting-table
      class="mb-4"
      :line-notices="lineNotices"
      @click:row="toEdit"
      @click:testNotification="sendTestNotification"
    />
    <account-table
      class="mb-4"
      :channels="channelsWithCount"
      @click:row="toShow"
    />
    <v-card>
      <v-card-title>
        {{ $t('account.sp_link_title') }}
      </v-card-title>
      <v-card-text>
        <v-btn color="error" @click="destroySpLinkCode">
          {{ $t('line_sp_link.unsync') }}
        </v-btn>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col class="text-right">
        <v-btn @click="$router.push({ name: 'SpIndex' })">{{
          $t('btn.back')
        }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import NotifyForm from 'pages/v1/notificators/Common/NotifyForm'
import SettingTable from 'pages/v1/notificators/Index/SettingTable'
import AccountTable from 'pages/v1/notificators/Index/AccountTable'
export default {
  components: {
    NotifyForm,
    SettingTable,
    AccountTable,
  },
  data: () => ({
    channels: [],
    channelsWithCount: [],
    lineNotices: [],
    lineNotice: {
      channel_id: '',
      notice_type: 'all',
      normal_flg: 1,
      auto_answer_flg: 1,
      action_flg: 1,
    },
    vees: {},
    opts: { notice_type: [] },
  }),
  created() {
    this.init()
    this.getNotificators()
  },
  methods: {
    init() {
      this.lineNotice.channel_id = this.$store.getters.channel.id
    },
    getNotificators() {
      this.loading = true
      const path = '/api/v1/notificator'
      this.axios
        .get(path, { params: {} })
        .then((res) => {
          this.channels = res.data.channels
          this.lineNotices = res.data.line_notices
          this.channelsWithCount = res.data.channels_with_count
          this.vees = res.data.vees
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    save() {
      this.loading = true
      const path = '/api/v1/notificator'
      const params = { line_notice: this.lineNotice }
      this.$$sendForm('post', path, params)
        .then((res) => {
          window.location.href = res.data.redirect_url
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    sendTestNotification(item) {
      this.loading = true
      const path = '/api/v1/notificator/test_send'
      const params = { id: item.id }
      this.$$sendForm('post', path, params)
        .then(() => {
          this.$toasted.success(this.$t('lbl.send_message_done'))
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    destroySpLinkCode() {
      const url = '/api/v1/sp/link'
      this.axios
        .delete(url)
        .then(() => {
          this.logout()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    logout() {
      const url = '/auth/sign_out'
      this.axios
        .delete(url)
        .then(() => {
          this.$store.dispatch('logout')
          this.$toasted.success(this.$t('line_sp_link.destroy_link'))
          this.$router.push({ name: 'SpVerify' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    toEdit(itemId) {
      this.$router.push({ name: 'SpNotificatorForm', params: { id: itemId } })
    },
    toShow(itemId) {
      this.$router.push({ name: 'SpNotificatorShow', params: { id: itemId } })
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep {
  .v-data-table .v-data-table-header-mobile {
    display: none !important;
  }
}
</style>
