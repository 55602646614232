<template>
  <div>
    <PtTitle />
    {{ $t('subtitle.show_answer_url') }}
    <validation-observer v-if="initDone" ref="observer">
      <v-card class="mb-6">
        <v-card-text>
          <v-card>
            <v-card-title>
              {{ `フォーム「${answer_form.name}」用リンク` }}
            </v-card-title>
          </v-card>
          <v-row class="mt-4">
            <v-col md="2" class="text-right mt-4"> 注意点 </v-col>
            <v-col md="10">
              <v-alert text outlined class="blue-grey lighten-5">
                このアカウントからメッセージ配信した<span
                  class="red--text font-weight-bold"
                  >短縮URLをクリックしたことがある友だち</span
                >しか回答を特定することはできません。<br />
                もしくは、<span class="font-weight-bold"
                  >GETパラメータを引き継ぐ仕組み</span
                >を利用することもできます。 方法がわからない場合は<span
                  class="font-weight-bold"
                  >TalkLabel公式LINE</span
                >にご連絡ください。
              </v-alert>
            </v-col>
          </v-row>
          <v-divider />
          <v-row class="mt-4">
            <v-col md="3" class="text-right mt-5">
              外部用回答フォームURL
            </v-col>
            <v-col md="9" class="">
              <v-text-field
                :value="answerUrl"
                readonly
                single-line
                :hide-details="true"
                class="pb-5"
              >
                <template #append-outer>
                  <v-icon
                    v-clipboard:copy="answerUrl"
                    v-clipboard:success="onClipCopy"
                    v-clipboard:error="onClipError"
                    class="p-0 clipboard-icon"
                    >mdi-content-copy</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </validation-observer>
  </div>
</template>
<script>
export default {
  name: 'AnswerFormShowUrl',
  data: function () {
    return {
      answerFormId: '',
      answerUrl:
        window.location.protocol +
        '//' +
        window.location.host +
        this.$router.resolve({
          name: 'AnswerFormAnswer',
          params: { uuid: this.$route.params.alt_uuid },
        }).href,
      initDone: true,
      answer_form: {
        name: '',
      },
    }
  },
  created() {
    this.answerFormId = this.$route.params.id
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      const path = `/api/v1/answer_forms/${this.answerFormId}/edit`

      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.answer_form = res.data.answer_form
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
          this.isSetDefaultSettingDone = true
        })
    },
    onClipCopy() {
      this.$toasted.success('コピーしました')
    },
    onClipError() {
      this.$toasted.success('error')
    },
  },
}
</script>
