<template>
  <div>
    <PtTitle />
    {{ $t('subtitle.answer_form') }}
    <v-row>
      <v-col md="3">
        <PtFolder
          ref="folder"
          folder-type="answer_form"
          @select-folder="selectFolder"
          @pt-init-folders="ptInitFolders"
        />
      </v-col>
      <v-col outlined md="9">
        <v-card>
          <VfLoadingOverlayAbsolute :loading="loading" />
          <v-card-title>
            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="search"
                  label="Search"
                  append-icon="mdi-magnify"
                  clearable
                  single-line
                  hide-details
                />
              </v-col>
              <v-col md="6" class="text-right">
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :disabled="checkedItems.length === 0"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('btn.multi') }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="selectedActionConfirm('delete')">
                      <v-list-item-title>{{
                        $t('lbl.selected_delete')
                      }}</v-list-item-title>
                    </v-list-item>
                    <VDivider />
                    <v-list-item
                      v-for="(folder, folder_idx) in folders"
                      :key="folder_idx"
                      @click="moveFolder(folder)"
                    >
                      <v-list-item-title>{{
                        $t('lbl.move_to', { to: folder.name })
                      }}</v-list-item-title>
                    </v-list-item>
                    <VDivider />
                    <v-list-item @click="favorite(checkedIds, 1)">
                      <v-icon>mdi-star</v-icon
                      ><v-list-item-title>{{
                        $t('lbl.answer_form.puton')
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="favorite(checkedIds, 0)">
                      <v-icon>mdi-star</v-icon
                      ><v-list-item-title>{{
                        $t('lbl.answer_form.remove')
                      }}</v-list-item-title>
                    </v-list-item>
                    <VDivider />
                    <v-list-item @click="bulkMute(checkedIds, 1)">
                      <v-list-item-title>{{
                        $t('lbl.answer_form.mute_bulk')
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="bulkMute(checkedIds, 0)">
                      <v-list-item-title>{{
                        $t('lbl.answer_form.unmute_bulk')
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn color="primary" @click="newItem">{{
                  $t('btn.new_item')
                }}</v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            v-model="checkedItems"
            :headers="headers"
            :items="items"
            :search="search"
            item-key="id"
            show-select
            :disable-sort="true"
            :items-per-page="50"
            :footer-props="{
              itemsPerPageOptions: [50, 100, 150],
            }"
          >
            <template #[`item.name`]="{ item }">
              <v-row class="m-0">
                <v-col md="9" class="p-0 d-flex align-center">
                  <a href="javascript:;" @click="editItem(item)">
                    {{ item.name }}
                  </a>
                  <v-chip
                    v-if="!item.publish_flg"
                    class="ma-2 chip-publish"
                    label
                    color="red"
                    text-color="white"
                    small
                  >
                    停止中
                  </v-chip>
                </v-col>
                <v-col md="3" class="p-0">
                  <v-btn
                    v-if="item.favourite_flg"
                    class="ma-2"
                    text
                    icon
                    small
                    color="orange"
                    @click="favorite([item.id], 0)"
                  >
                    <v-icon>mdi-star</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    class="ma-2"
                    text
                    icon
                    small
                    color="gray"
                    @click="favorite([item.id], 1)"
                  >
                    <v-icon>mdi-star-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="item.mute_notification_flg"
                    class="ma-0"
                    text
                    icon
                    small
                    color="gray"
                    @click="muteNotification(item.id, 0)"
                  >
                    <v-icon>mdi-volume-mute</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="m-0">
                <v-col md="9" class="p-0 d-flex align-center">
                  <v-text-field
                    :value="`[FORM_${item.alt_uuid}]`"
                    readonly
                    :hide-details="true"
                    :persistent-hint="false"
                    class="mt-0 pt-0 font-size-x-small"
                  />
                  <v-btn
                    v-clipboard:copy="`[FORM_${item.alt_uuid}]`"
                    v-clipboard:success="onClipCopy"
                    v-clipboard:error="onClipError"
                    small
                    class="ma-1"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    class="text-decoration-none"
                    @click="
                      $router.push({
                        name: 'AnswerFormShowUrl',
                        params: { id: item.id, alt_uuid: item.alt_uuid },
                      })
                    "
                  >
                    {{ $t('lbl.answer_form.external') }}
                  </v-btn>
                </v-col>
                <v-col md="3" class="p-0">
                  <a
                    v-if="item.spreadsheet_replication_flg && item.sheet_id"
                    target="_blank"
                    :href="`https://docs.google.com/spreadsheets/d/${item.sheet_id}`"
                    class="text-decoration-none"
                  >
                    <v-btn class="ma-2" text icon small color="green">
                      <v-icon>mdi-google-spreadsheet</v-icon>
                    </v-btn>
                  </a>
                </v-col>
              </v-row>
            </template>
            <template #[`item.answer_form_status`]="{ item }">
              {{
                `${item.response_count}${$t('lbl.answer_form.reply')}` +
                `/${item.total_friends_answer}${$t('lbl.answer_form.people')}`
              }}
              <template v-if="item.total_answer">
                <v-btn
                  small
                  class="mb-2"
                  @click="
                    $router.push({
                      name: 'AnswerFormShow',
                      params: { id: item.id },
                      query: { tab: 3 },
                    })
                  "
                >
                  {{ $t('lbl.answer_form.display') }}
                </v-btn>
                <a
                  class="text-decoration-none"
                  :href="`/api/v1/answer_form_responses/export_csv.csv?answer_form_id=${item.id}`"
                >
                  <v-btn class="mb-2" small>
                    {{ $t('lbl.answer_form.response_list_dl') }}
                  </v-btn>
                </a>
              </template>
            </template>
            <template #[`item.buttons`]="{ item }">
              <v-btn
                small
                class="mb-2"
                @click="
                  $router.push({
                    name: 'AnswerFormChangeStatus',
                    params: { id: item.id },
                  })
                "
              >
                {{ $t('lbl.answer_form.stop_switching') }}
              </v-btn>
              <a
                class="text-decoration-none"
                target="_blank"
                :href="
                  $router.resolve({
                    name: 'AnswerFormAnswer',
                    params: { uuid: item.alt_uuid },
                  }).href + '?mode=example'
                "
              >
                <v-btn small class="mb-2">
                  {{ $t('lbl.answer_form.preview') }}
                </v-btn>
              </a>
              <v-btn
                small
                class="mb-2"
                @click="
                  $router.push({
                    name: 'AnswerFormShow',
                    params: { id: item.id },
                  })
                "
              >
                {{ $t('lbl.answer_form.detail') }}
              </v-btn>
            </template>
            <template #[`item.action`]="{ item }">
              <v-menu offset-y>
                <template #activator="{ on, attrs }">
                  <v-btn
                    small
                    class="mb-2"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $t('btn.operation') }}
                    <v-icon right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-if="item.favourite_flg"
                    @click="favorite([item.id], 0)"
                  >
                    <v-icon>mdi-star</v-icon
                    ><v-list-item-title>{{
                      $t('lbl.answer_form.remove')
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="!item.favourite_flg"
                    @click="favorite([item.id], 1)"
                  >
                    <v-icon>mdi-star</v-icon
                    ><v-list-item-title>{{
                      $t('lbl.answer_form.puton')
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="editItem(item)">
                    <v-list-item-title>{{
                      $t('lbl.answer_form.edit_form')
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="copyForm(item)">
                    <v-list-item-title>{{
                      $t('lbl.answer_form.copy_and_edit')
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="!item.mute_notification_flg"
                    @click="muteNotification(item.id, 1)"
                  >
                    <v-list-item-title>{{
                      $t('lbl.answer_form.mute_notification')
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="item.mute_notification_flg"
                    @click="muteNotification(item.id, 0)"
                  >
                    <v-list-item-title>{{
                      $t('lbl.answer_form.un_mute_notification')
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteItem(item)">
                    <v-list-item-title>{{
                      $t('lbl.answer_form.delete_form')
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template #[`item.folder`]="{ item }">
              <span class="text-caption">{{ item.folder_name }}</span
              ><br />
              <span class="text-caption">{{
                formatDate(item.created_at) + $t('lbl.answer_form.registration')
              }}</span>
            </template>
            <template #[`item.used_count`]="{ item }">
              {{ $t('lbl.people', { count: item.used_count }) }}
              <template v-if="item.limit_flg">
                /
                {{
                  $t('lbl.hashtag.limit_used_count', { count: item.limit_num })
                }}
              </template>
            </template>
            <template #[`item.action_group`]="{ item }">
              <PtActionLabelMessages
                v-model="item.action_group.make_action_arr"
                class="or-list"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
    <PtDialogLinePreview
      :key="selectedItem.id"
      v-model="previewDialog"
      type="answer_form"
      :model-id="selectedItem.id"
    />
    <v-dialog v-model="commonDialog" max-width="500">
      <v-card>
        <VfLoadingOverlayAbsolute :loading="commonDialogLoading" />
        <v-card-text class="pt-6">
          {{ $t(`lbl.confirm_selected_${selectedAction}`) }}
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="commonDialog = false">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="primary" @click="selectedSave(selectedAction)">{{
            $t('btn.action')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtFolder from 'components/parts/common/PtFolder'
import PtDialogLinePreview from 'components/parts/common/PtDialogLinePreview'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
import moment from 'moment'
export default {
  components: {
    PtFolder,
    PtDialogLinePreview,
    PtActionLabelMessages,
  },
  data: () => ({
    loading: false,
    checkedItems: [],
    headers: [],
    items: [],
    search: '',
    savePath: '',
    folderId: '',
    selectedItem: { id: '' },
    previewDialog: false,
    deleteDialog: false,
    commonDialog: false,
    commonDialogLoading: false,
    selectedAction: '',
    folders: [],
    moveFolderId: '',
  }),
  computed: {
    checkedIds() {
      return this.checkedItems.map((item) => {
        return item.id
      })
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/answer_forms'
      const prms = { folder_id: this.folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.answer_forms
          this.formatHeader()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatHeader() {
      this.headers.splice(
        2,
        0,
        ...[
          { text: '', value: 'buttons' },
          { text: '', value: 'action' },
        ]
      )
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    newItem() {
      const prms = {}
      if (this.folderId) {
        prms.fid = this.folderId
      }
      this.$router.push({
        name: 'AnswerFormForm',
        params: { id: 'new' },
        query: prms,
      })
    },
    editItem(item) {
      this.$router.push({ name: 'AnswerFormForm', params: { id: item.id } })
    },
    copyForm(item) {
      this.$router.push({
        name: 'AnswerFormForm',
        params: { id: 'new' },
        query: { clone_id: item.id },
      })
    },
    deleteItem(item) {
      this.savePath = `/api/v1/answer_forms/${item.id}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.init()
      this.$refs.folder.initFromParent()
    },
    showItem(item) {
      this.selectedItem = item
      this.previewDialog = true
    },
    selectFolder(folderId) {
      this.folderId = folderId
      this.init()
    },
    ptInitFolders(folders) {
      this.folders = folders
    },
    selectedActionConfirm(action) {
      this.selectedAction = action
      this.commonDialog = true
    },
    moveFolder(folder) {
      this.moveFolderId = folder.id
      this.selectedSave('move_folder')
    },
    async selectedSave(action) {
      this.commonDialogLoading = true
      let prms = {
        selected_ids: this.checkedIds,
        answer_form: {},
      }
      let path = null
      let method = ''
      switch (action) {
        case 'delete':
          path = `/api/v1/answer_forms/selected_delete`
          method = 'delete'
          break
        case 'move_folder':
          method = 'post'
          path = `/api/v1/answer_forms/selected_move_folder`
          prms.answer_form.folder_id = this.moveFolderId
          break
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.checkedItems = []
          this.$refs.folder.init()
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.commonDialogLoading = false
          this.commonDialog = false
        })
    },
    async favorite(ids, favouriteFlg) {
      let prms = {
        selected_ids: ids,
        answer_form: { favourite_flg: favouriteFlg },
      }
      await this.$$sendForm(
        'post',
        `/api/v1/answer_forms/bulk_update`,
        this.$$reqPrms(prms)
      )
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.commonDialogLoading = false
          this.commonDialog = false
        })
    },
    async bulkMute(ids, muteFlg) {
      let prms = {
        selected_ids: ids,
        answer_form: { mute_notification_flg: muteFlg },
      }
      await this.$$sendForm(
        'post',
        `/api/v1/answer_forms/bulk_update`,
        this.$$reqPrms(prms)
      )
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.commonDialogLoading = false
          this.commonDialog = false
        })
    },
    async muteNotification(id, muteNotificationFlg) {
      let prms = {
        answer_form: { mute_notification_flg: muteNotificationFlg },
      }
      const path = `/api/v1/answer_forms/${id}`
      await this.$$sendForm('put', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.commonDialogLoading = false
          this.commonDialog = false
        })
    },
    async responseListDownload(formId) {
      this.loading = true
      const path = `/api/v1/answer_form_responses/export_csv`
      const prms = { answer_form_id: formId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          let csv = res.data.csv
          let contents = csv.join('\n')
          let blob = new Blob([contents], { type: 'text/csv' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = this.getToday() + '_answers.csv'
          link.click()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClipCopy(e) {
      this.$toasted.success(e.text + 'をコピーしました')
    },
    onClipError() {
      this.$toasted.success('error')
    },
    getToday() {
      const dt = new Date()
      const year = dt.getFullYear()
      const month = ('00' + (dt.getMonth() + 1)).slice(-2)
      const day = ('00' + dt.getDate()).slice(-2)
      const hour = ('00' + dt.getDate()).slice(-2)
      const minutes = ('00' + dt.getMinutes()).slice(-2)
      const second = ('00' + dt.getSeconds()).slice(-2)
      const result = year + month + day + hour + minutes + second
      return result
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped lang="scss">
.or-list {
  max-width: 200px;
  font-size: 0.5em;
}
::v-deep {
  .v-data-table > .v-data-table__wrapper > table > tbody > tr:first-child {
    padding: 16px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr {
    td:first-child {
      padding: 16px;
    }
    > td {
      padding: 16px 8px 16px 8px;
    }
  }

  .v-data-table > .v-data-table__wrapper > table > thead > tr {
    th:first-child {
      padding: 16px;
    }
    th {
      padding: 16px 8px 16px 8px;
    }
  }
}

::v-deep {
  .chip-publish {
    min-width: 60px;
  }
  .font-size-x-small {
    font-size: x-small;
  }
}

::v-deep tr {
  th:nth-child(2) {
    width: 34%;
  }
  th:nth-child(3) {
    width: 15%;
  }
}
</style>
