<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-expansion-panels v-model="panel" accordion>
      <draggable
        :list="internalValue"
        :disabled="false"
        class="w-100"
        ghost-class="ghost"
        :move="checkMove"
        handle=".drag-handle"
        :animation="200"
        :delay="0"
        @start="dragging = true"
        @end="updatePanel($event)"
      >
        <v-expansion-panel
          v-for="(formSetup, formSetup_i) in internalValue"
          :key="formSetup_i"
        >
          <validation-observer v-slot="{ invalid }">
            <v-expansion-panel-header
              expand-icon=""
              class="pa-0 text-subtitle-1 lighten-5"
              :class="invalid ? 'red' : 'blue-grey'"
            >
              <v-list-item>
                <div class="mr-1">
                  <p class="mt-3 mb-3">
                    <span>{{ formSetup.title }}</span>
                    <span class="text-subtitle-2 grey--text text--lighten-1">{{
                      $t(
                        `enum.answer_form_input.input_types.${formSetup['_type']}`
                      )
                    }}</span>
                    <v-chip
                      v-if="formSetup.require_flg"
                      label
                      small
                      color="red"
                      text-color="white"
                    >
                      {{ $t('lbl.answer_form.require') }}
                    </v-chip>
                  </p>
                </div>
                <v-list-item-content />
                <v-card-actions>
                  <v-btn small icon>
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                  <v-btn small icon class="drag-handle" @click.stop="">
                    <v-icon>mdi-cursor-move</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    @click.stop=""
                    @click="duplicateSetup(formSetup_i)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                    small
                    icon
                    @click.stop=""
                    @click="
                      removeDialog = true
                      removeSetupIndex = formSetup_i
                    "
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-list-item>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <component
                :is="inputTypeMap[formSetup._type]"
                v-model="internalValue[formSetup_i]"
                :init-data="initData"
                :targets="targets"
                :vees="vees"
                :opts="opts"
                :section-opts="sectionOpts"
                :input-type="formSetup._type"
                :setup-index="formSetup_i"
                :checked-interact-index="checkedInteractIndex"
                :section-length="sectionLength"
                :section-list="sectionList"
                :section-index="sectionIndex"
                @update="handleUpdateForm($event, sectionIndex, formSetup_i)"
              />
            </v-expansion-panel-content>
          </validation-observer>
        </v-expansion-panel>
      </draggable>
    </v-expansion-panels>
    <v-row class="d-flex justify-content-center mt-5">
      <div class="text-subtitle-2 grey--text text--lighten-1">
        {{ $t('lbl.answer_form.select_element') }}
      </div>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-for="(inputType, disp) in opts.answer_form_input.input_type"
          :key="disp"
          class="ma-2"
          @click="addSetup(inputType)"
        >
          {{ disp }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="removeDialog" max-width="300">
      <v-card>
        <v-card-title>{{ '' }}</v-card-title>
        <v-card-text>
          <div class="or-break">
            {{ $t('lbl.answer_form.do_you_really_want_to_delete_the_item') }}
          </div>
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="removeDialog = false">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="error" @click="removeSetup(removeSetupIndex)">{{
            $t('btn.yes')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PtFormSetupSubHeading from 'components/parts/common/PtFormSetupSection/PtFormSetupSubHeading'
import PtFormSetupTextField from 'components/parts/common/PtFormSetupSection/PtFormSetupTextField'
import PtFormSetupTextArea from 'components/parts/common/PtFormSetupSection/PtFormSetupTextArea'
import PtFormSetupAttachment from 'components/parts/common/PtFormSetupSection/PtFormSetupAttachment'
import PtFormSetupAddress from 'components/parts/common/PtFormSetupSection/PtFormSetupAddress'
import PtFormSetupSelect from 'components/parts/common/PtFormSetupSection/PtFormSetupSelect'
import PtFormSetupDate from 'components/parts/common/PtFormSetupSection/PtFormSetupDate'
import draggable from 'vuedraggable'
export default {
  name: 'PtFormSetupSection',

  components: {
    PtFormSetupSubHeading,
    PtFormSetupTextField,
    PtFormSetupTextArea,
    PtFormSetupSelect,
    PtFormSetupAttachment,
    PtFormSetupAddress,
    PtFormSetupDate,
    draggable,
  },

  props: {
    value: { default: null },
    targets: { type: Array, default: () => [] },
    titles: { type: Array, default: () => [] },
    sectionOpts: { type: Object, default: () => ({}) },
    initData: { type: Object, default: () => ({}) },
    opts: { type: Object, default: () => ({}) },
    vees: { type: Object, default: () => ({}) },
    sectionIndex: { type: Number, default: null },
    sectionLength: { type: Number, default: null },
    sectionList: { type: Array, default: () => [] },
  },

  data: function () {
    return {
      // initData: {},
      dragging: false,
      removeDialog: false,
      removeSetupIndex: null,
      dragCurrentIndex: null,
      dragFutureIndex: null,
      panel: [],
      loading: false,
      errorMsg: '',
      inputTypeMap: {
        subheading: 'PtFormSetupSubHeading',
        middleheading: 'PtFormSetupSubHeading',
        text_field: 'PtFormSetupTextField',
        text_area: 'PtFormSetupTextArea',
        radio: 'PtFormSetupSelect',
        checkbox: 'PtFormSetupSelect',
        dropdown: 'PtFormSetupSelect',
        attachment: 'PtFormSetupAttachment',
        address: 'PtFormSetupAddress',
        date: 'PtFormSetupDate',
      },
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    checkedInteractIndex() {
      //findIndex Otherwise, it returns -1, indicating that no element passed the test.
      return this.internalValue.findIndex((setup) => {
        return setup.on_page_interact_flg === 1
      })
    },
  },
  methods: {
    updateInteractIndex(setupIndex, onPageInteractFlg) {
      this.checkedInteractIndex = onPageInteractFlg ? setupIndex : -1
    },
    addSetup(inputType) {
      this.panel = this.internalValue.length
      let initData = this.$$clone(this.initData)
      if (
        inputType == 'date' ||
        inputType == 'address' ||
        inputType == 'attachment'
      ) {
        delete initData.answer_form_input_options_attributes
      }
      if (inputType == 'attachment') {
        initData.additional_option_type = 'image'
      } else if (
        inputType === 'checkbox' ||
        inputType === 'radio' ||
        inputType === 'dropdown'
      ) {
        initData.answer_form_input_options_attributes[0].limit_type = 'not_use'
      }
      initData._type = inputType
      initData.title =
        this.$t(`lbl.answer_form.untitled_${inputType}`) +
        (this.titles.length + 1)
      initData.heading_image_type = 0
      initData.duplicate_count = 0
      initData.assign_value_to = []
      initData.internal_id = `id_${new Date().getTime()}`

      this.internalValue.push(initData)
    },
    updatePanel() {
      if (this.panel === this.dragCurrentIndex) {
        this.panel = this.dragFutureIndex
      } else if (
        this.panel > this.dragCurrentIndex &&
        this.panel <= this.dragFutureIndex
      ) {
        this.panel = this.panel - 1
      } else if (
        this.panel < this.dragCurrentIndex &&
        this.panel >= this.dragFutureIndex
      ) {
        this.panel = this.panel + 1
      }

      this.panel = undefined
    },
    removeSetup(index) {
      if (this.panel === index) {
        //reset expansion
        this.panel = undefined
      }
      this.internalValue[index].duplicate_count -= 1
      this.internalValue.splice(index, 1)
      this.removeDialog = false
    },
    duplicateSetup(index) {
      this.panel = this.internalValue.length
      let inputSetup = this.$$clone(this.internalValue[index])
      inputSetup.title = this.incrementTitle(
        inputSetup.title + this.$t('lbl.answer_form.copy_of'),
        this.titles
      )
      this.internalValue[index].duplicate_count += 1
      delete inputSetup.id
      if (inputSetup.answer_form_input_options_attributes.length) {
        for (const input_option of inputSetup.answer_form_input_options_attributes) {
          delete input_option.id
        }
      }
      this.internalValue.push(inputSetup)
    },
    incrementTitle(title, titles) {
      let counter = 1
      let newTitle = title
      while (titles.includes(newTitle)) {
        counter++
        newTitle = title + counter
      }

      return newTitle
    },
    checkMove: function (e) {
      this.dragCurrentIndex = e.draggedContext.index
      this.dragFutureIndex = e.draggedContext.futureIndex
    },
    handleUpdateForm(data, sectionIndex, formQuestionIndex) {
      data.sectionIndex = sectionIndex
      data.formQuestionIndex = formQuestionIndex
      data.internal_id = this.internalValue[formQuestionIndex].internal_id

      this.$emit('update', data)
    },
  },
}
</script>

<style scoped>
.v-expansion-panel >>> .v-expansion-panel-content__wrap {
  padding-left: 16px;
  padding-right: 16px;
}
</style>
