<template>
  <div>
    <v-data-table
      v-model="checkedFriends"
      class="friend_index_table"
      :headers="headers"
      :items="items"
      :options.sync="tableOptions"
      item-key="id"
      sort-by="created_at"
      sort-desc
      show-select
      disable-sort
      :server-items-length="totalCount"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [50, 100, 150],
      }"
    >
      <template #[`item.message_status`]="{ item }">
        <v-chip
          dark
          label
          :color="colors[item.message_status]"
          @click="openDialogMessageStatus(item)"
        >
          {{ $$te('friend.message_status', item) }}
        </v-chip>
      </template>
      <template #[`item.is_blocked`]="{ item }">
        <div v-if="item.blocked_flg === 1">ブロックされています</div>
        <div v-if="item.blocking_flg === 1">ブロックしています</div>
      </template>
      <template #[`item.name`]="{ item }">
        <router-link :to="{ name: 'FriendShow', params: { id: item.id } }">
          <v-avatar size="48" class="mr-2 mt-2 mb-2">
            <v-img
              small
              :src="$$noimageHelper(item.api_picture_url)"
              :alt="item.name"
            />
          </v-avatar>
          {{ item.name }}
        </router-link>
      </template>
      <template #[`item.active_scenario`]="{ item }">
        <div v-if="item.active_scenario">
          {{ item.active_scenario.name }}
        </div>
      </template>
      <template #[`item.last_receive_deli`]="{ item }">
        <template v-if="item.last_receive_deli">
          {{ $$fdt(item.last_receive_at) }}
          <br />
          {{ $$ftm(item.last_receive_at, 'LTS') }}
          <PtLinePreviewRow
            :deli="item.last_receive_deli"
            message-type="receive"
            hide-detail
          />
        </template>
      </template>
      <template #[`item.hashtags`]="{ item }">
        <v-chip
          v-for="(hashtag, hashtag_idx) in item.hashtags"
          :key="hashtag_idx"
          >{{ hashtag.name }}</v-chip
        >
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ $$fdt(item.created_at) }}
        <br />
        {{ $$ftm(item.created_at, 'LTS') }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import PtLinePreviewRow from 'components/parts/common/PtLinePreviewRow'
export default {
  components: {
    PtLinePreviewRow,
  },
  props: {
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    checkedItems: { type: Array, default: () => [] },
    totalCount: { type: Number, default: null },
  },
  data: () => ({
    loading: false,
    search: '',
    colors: {
      unread: 'primary',
      magazine_comment: 'primary',
      low_important: 'primary',
      done: 'default',
      claim: 'red',
      closing: 'red',
      trouble: 'red',
      question: 'red',
    },
  }),
  computed: {
    tableOptions: {
      get() {
        return this.options
      },
      set(val) {
        this.$emit('update:options', val)
      },
    },
    checkedFriends: {
      get() {
        return this.checkedItems
      },
      set(val) {
        this.$emit('update:checked-items', val)
      },
    },
  },
  methods: {
    openDialogMessageStatus(item) {
      this.$emit('open-dialog-message-status', item)
    },
  },
}
</script>
