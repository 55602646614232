<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle />
      </v-col>
      <v-col cols="4" align="right">
        <v-btn v-if="!isNewRecord" class="mt-4" color="error" @click="destroy">
          {{ $t('btn.delete') }}
        </v-btn>
      </v-col>
    </v-row>
    <validation-observer ref="observer">
      <v-form @submit.prevent="save">
        <v-card outlined>
          <v-card-title class="pa-0">
            <v-list-item>
              <v-list-item-content>
                {{ $t('admin.plan') }}
              </v-list-item-content>
            </v-list-item>
          </v-card-title>
          <v-card-text>
            <VfTextField
              v-model="plan.api_plan_id"
              :label="$t('admin.api_plan_id')"
              disabled
            />
            <VfTextField
              v-model="plan.name"
              :label="$t('admin.plan_name')"
              :vees="vees.name"
            />
            <VfTextField
              v-model="plan.amount"
              :label="$$tm('plan.amount')"
              :vees="vees.amount"
              :disabled="!isNewRecord"
              number
            />
            <VfTextField
              v-model="plan.billing_day"
              :label="$$tm('plan.billing_day')"
              :vees="vees.billing_day"
              :disabled="!isNewRecord"
              :hint="$t('admin.plan_billing_day')"
              number
            />
          </v-card-text>
        </v-card>
        <v-row>
          <v-col class="text-right">
            <v-btn @click="$router.push({ name: 'AdminPlanIndex' })">{{
              $t('btn.back')
            }}</v-btn>
            <v-btn color="primary" type="submit">登録</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="deletePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
export default {
  data: () => ({
    plan: {},
    vees: {},
    deleteDialog: false,
  }),
  computed: {
    planId() {
      return this.$route.params.id
    },
    isNewRecord() {
      return this.planId === 'new'
    },
    deletePath() {
      return `/api/v1/plans/${this.planId}`
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = this.isNewRecord
        ? '/api/v1/plans/new'
        : `/api/v1/plans/${this.planId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.plan = res.data.plan
          this.vees = res.data.vees
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.$store.dispatch('loadingOverlay', true)
      const path = this.isNewRecord
        ? '/api/v1/plans'
        : `/api/v1/plans/${this.planId}`
      const method = this.isNewRecord ? 'post' : 'put'
      const prms = this.plan
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'AdminPlanIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$store.dispatch('loadingOverlay', false)
        })
    },
    destroy() {
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'AdminPlanIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
