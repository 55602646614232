<template>
  <div>
    <v-row>
      <v-col cols="12">
        <PtTitle />
      </v-col>
    </v-row>
    <v-row v-if="user.id">
      <v-col cols="12" md="6">
        <user-base-info
          class="mb-4"
          :user="user"
          :vees="vees.user"
          @save="userSave"
        />
        <user-address
          class="mb-4"
          :user-address="user.user_address"
          :vees="vees.user_address"
          :opts="opts"
          @save="userAddressSave"
        />
        <password-edit class="mb-4" @save="passwordSave" />
        <staff-code-entry class="mb-4" @save="register" />
      </v-col>
      <v-col cols="12" md="6">
        <channel-select-index
          class="mb-4"
          :channels="user.channels"
          @select="selectChannel"
        />
        <mobile-attach-code
          class="mb-4"
          :user="user"
          @save="getSpLinkCode"
          @destroy="destroySpLinkCode"
        />
        <line-notify-setting class="mb-4" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import UserBaseInfo from './Show/UserBaseInfo'
import UserAddress from './Show/UserAddress'
import ChannelSelectIndex from './Show/ChannelSelectIndex'
import MobileAttachCode from './Show/MobileAttachCode'
import LineNotifySetting from './Show/LineNotifySetting'
import PasswordEdit from './Show/PasswordEdit'
import StaffCodeEntry from './Show/StaffCodeEntry'
export default {
  components: {
    UserBaseInfo,
    UserAddress,
    ChannelSelectIndex,
    MobileAttachCode,
    LineNotifySetting,
    PasswordEdit,
    StaffCodeEntry,
  },
  data: () => ({
    user: {},
    vees: {},
    opts: {},
  }),
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      this.$$loadingOverlayContent(true)
      const path = '/api/v1/account'
      this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.user = res.data.user
          this.vees = res.data.vees
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    userSave(updateUser) {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/account/update_from_base`
      const prms = { user: updateUser }
      this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.user = updateUser
          this.$toasted.success(this.$t('success.update'))
          this.$router.go({ name: 'AccountShow' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    userAddressSave(saveUserAddress) {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/user_address`
      const prms = { user_address: saveUserAddress }
      this.$$sendForm('put', path, this.$$reqPrms(prms))
        .then(() => {
          this.user.user_address = saveUserAddress
          this.$toasted.success(this.$t('success.update'))
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    selectChannel(channelId) {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/channel/select`
      const prms = { channel_id: channelId }
      this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then((res) => {
          this.$store.dispatch('selectChannel', res.data.channel)
          window.location.href = this.$router.resolve({ name: 'BaseHome' }).href
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    getSpLinkCode() {
      const url = '/api/v1/sp/link'
      this.axios
        .post(url)
        .then((res) => {
          this.$toasted.success(this.$t('line_sp_link.create_code'))
          this.$set(this.user, 'sp_user', res.data.sp_user)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    destroySpLinkCode() {
      const isVerified = this.user.sp_user.verified
      const url = '/api/v1/sp/link'
      this.axios
        .delete(url)
        .then(() => {
          const messageType = isVerified ? 'destroy_link' : 'destroy_code'
          this.$toasted.success(this.$t(`line_sp_link.${messageType}`))
          this.user.sp_user = null
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    passwordSave(passwordForm) {
      this.$$loadingOverlayContent(true)
      const url = '/api/v1/account/password_reset'
      this.axios
        .post(url, passwordForm)
        .then(() => {
          this.$toasted.success('パスワードを変更しました')
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    register(code) {
      const url = `/api/v1/channel_tokens/${code}`
      this.axios
        .patch(url)
        .then(() => {
          this.$toasted.success('success')
          window.location.href = this.$router.resolve({ name: 'BaseHome' }).href
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
