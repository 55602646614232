<template>
  <v-dialog v-model="dialog" max-width="400">
    <template #activator="{ on, attrs }">
      <v-btn color="error" v-bind="attrs" v-on="on">
        {{ $t('btn.detach') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">{{
        $t('confirm_title.delete')
      }}</v-card-title>
      <v-card-text>{{ detail }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="dialog = false">
          {{ $t('btn.cancel') }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="save">
          {{ $t('btn.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    detail: { type: String, required: true },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    save() {
      this.$emit('save')
      this.dialog = false
    },
  },
}
</script>
