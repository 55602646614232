<template>
  <v-card class="my-4">
    <VfLoadingOverlayAbsolute :loading="loading" />
    <div class="pt-3 mr-3 align-items-end d-flex flex-column">
      <div>{{ $t('lbl.visit_count', { count: visit_count }) }}</div>
      <div>{{ $t('lbl.sent_count', { count: sent_count }) }}</div>
    </div>
    <v-container fluid>
      <v-data-table
        v-if="!loading && questions.length > 0"
        :headers="headers"
        :items="questions"
        item-key="id"
        disable-sort
        :loading="loading"
      >
        <template v-slot:body="{ items }">
          <template v-for="item in items">
            <template>
              <PtClickQuestionDetail
                :key="item.id"
                :click-id="clickId"
                :question="item"
                :friends="click_question_choice_friends"
              />
            </template>
          </template>
        </template>
      </v-data-table>
      <v-data-table v-else :headers="headers" :hide-default-footer="true" />
    </v-container>
  </v-card>
</template>
<script>
import PtClickQuestionDetail from './PtClickQuestionDetail'

export default {
  components: {
    PtClickQuestionDetail,
  },
  props: {
    clickId: { type: String, required: true },
  },
  data: () => ({
    headers: [],
    items: [],
    visit_count: 0,
    sent_count: 0,
    questions: [],
    click_question_choice_friends: [],
    loading: false,
    csv_data: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      await this.axios
        .get(`/api/v1/click_questions/${this.clickId}`)
        .then((res) => {
          const {
            questions,
            sent_count,
            visit_count,
            thead,
            click_question_choice_friends,
          } = res.data
          this.headers = thead
          this.questions = questions
          this.sent_count = sent_count
          this.visit_count = visit_count
          this.click_question_choice_friends = click_question_choice_friends
          this.build_csv_data()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    build_csv_data() {
      let headers = this.headers.map((h) => h.text)
      let items = []
      let click_question_choice_friends = []
      this.questions.forEach((question) => {
        question.deli_question_choices.forEach((choice) => {
          click_question_choice_friends =
            this.click_question_choice_friends.filter(
              (f) => f.deli_question_choice_id === choice.id
            )
          if (click_question_choice_friends.length > 0) {
            click_question_choice_friends.forEach((object) => {
              items.push([
                question.title,
                choice.choice_name,
                object.friend_name
                  ? object.friend_name
                  : this.$t('lbl.unknown'),
                object.click_count,
                this.$$fat(object.created_at),
              ])
            })
          } else {
            items.push([question.title, choice.choice_name])
          }
        })
      })
      this.csv_data = [headers].concat(items)
    },
  },
}
</script>
