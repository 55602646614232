<template>
  <v-dialog v-model="internalValue" :max-width="maxWidth">
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <VfAlert v-model="errorMsg" type="error" />
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <div class="text-center or-break">{{ detail }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="close">{{ $t('btn.cancel') }}</v-btn>
        <v-btn color="primary" @click="save">{{ $t('btn.action') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    title: { type: String, default: '' },
    detail: { type: String, required: true },
    maxWidth: { type: Number, default: 500 },
    targetEmit: { type: String, default: 'form-success' },
  },
  data: () => ({
    loading: false,
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    internalValue(val) {
      if (val) {
        this.init()
      }
    },
  },
  methods: {
    init() {
      this.errorMsg = ''
    },
    close() {
      this.internalValue = false
    },
    save() {
      this.$emit(this.targetEmit)
      this.loading = false
      this.close()
    },
  },
}
</script>
