<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
    </v-row>
    <validation-observer ref="observer">
      <v-card outlined class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.send_target') }}
        </v-card-title>
        <v-card-text>
          <VfSwitch
            v-model="broadcast.all_cast_flg"
            :label="$$tm('broadcast.all_cast_flg')"
            :vees="vees.all_cast_flg"
          />
          <v-card v-if="!broadcast.all_cast_flg">
            <v-card-text>
              <PtSearchSelectedListOnlyScenario
                v-model="broadcast.target_search_wrap"
              />
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
      <v-card outlined class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.send_message') }}
        </v-card-title>
        <v-card-text>
          <PtOnlyDeliText v-if="initDone" v-model="delis" />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col class="text-right">
          <v-btn @click="$router.push({ name: 'SpIndex' })">{{
            $t('btn.back')
          }}</v-btn>
          <v-btn color="primary" @click="sendConfirm">{{
            $t('btn.send')
          }}</v-btn>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" :max-width="300">
        <v-card outlined>
          <v-card-title>{{ $t('confirm_title.send') }}</v-card-title>
          <v-card-text class="or-break">{{ $t('confirm.send') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="dialog = false">{{ $t('btn.cancel') }}</v-btn>
            <v-btn @click="send">{{ $t('btn.send') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </validation-observer>
  </div>
</template>
<script>
import PtOnlyDeliText from 'components/parts/common/PtDeli/PtOnlyDeliText'
import PtSearchSelectedListOnlyScenario from 'components/parts/common/PtSearchWrap/PtSearchSelectedListOnlyScenario'
export default {
  components: {
    PtOnlyDeliText,
    PtSearchSelectedListOnlyScenario,
  },
  data: () => ({
    dialog: false,
    broadcast: {},
    vees: {},
    delis: [],
    initDone: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.initDone = false
      const path = '/api/v1/broadcasts/new'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.broadcast = res.data.broadcast
          this.vees = res.data.vees
          this.delis = res.data.delis
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.initDone = true
        })
    },
    async send() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.dialog = false
      this.$store.dispatch('loadingOverlay', true)
      const path = '/api/v1/broadcasts'
      const method = 'post'
      const prms = { broadcast: this.broadcast, delis: this.delis }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'SpIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$store.dispatch('loadingOverlay', false)
        })
    },
    sendConfirm() {
      this.dialog = true
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
