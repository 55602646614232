<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn v-if="hashtagId !== 'new'" color="error" @click="destroy">
            {{ $t('btn.delete') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-form @submit.prevent="save">
        <v-card class="mb-6">
          <v-card-title class="or-card-title mb-6">
            {{ $t('lbl.hashtag.info') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6">
                <VfSelect
                  v-model="hashtag.folder_id"
                  :label="$$tm('folder.name')"
                  :vees="vees.folder_id"
                  :selects="folders"
                />
              </v-col>
              <v-col md="6">
                <VfTextField
                  v-model="hashtag.name"
                  :label="$$tm('hashtag.name')"
                  :vees="vees.name"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mb-6">
          <v-card-title class="or-card-title mb-6">
            {{ $t('lbl.hashtag.action') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('lbl.hashtag.action_detail') }}
            <div class="hashtag_caution">
              {{ $t('lbl.hashtag.action_caution') }}
            </div>
          </v-card-subtitle>
          <v-card-text>
            <PtActionSelectedList v-model="hashtag.action_arr" />
          </v-card-text>
        </v-card>
        <v-card class="mb-6">
          <v-card-title class="or-card-title mb-6">
            {{ $t('lbl.hashtag.limit') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('lbl.hashtag.limit_detail') }}
            <div class="hashtag_caution">
              {{ $t('lbl.hashtag.limit_caution') }}
            </div>
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col md="3">
                <VfSwitch
                  v-model="hashtag.limit_flg"
                  :label="$$tm('hashtag.limit_flg')"
                  :vees="vees.limit_flg"
                />
              </v-col>
              <v-col v-if="hashtag.limit_flg" md="3">
                <VfTextField
                  v-model="hashtag.limit_num"
                  :label="$$tm('hashtag.limit_num')"
                  :vees="vees.limit_num"
                  number
                />
              </v-col>
            </v-row>
            <v-card v-if="hashtag.limit_flg">
              <v-card-title>
                {{ $t('lbl.hashtag.limited_action') }}
              </v-card-title>
              <v-card>
                <v-card-text>
                  <PtActionSelectedList v-model="hashtag.limited_action_arr" />
                </v-card-text>
              </v-card>
            </v-card>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col class="text-right">
            <v-btn
              type="submit"
              :loading="$wait.is('processing')"
              color="primary"
              >{{ $t('btn.create') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtActionSelectedList from 'components/parts/common/PtActionGroup/PtActionSelectedList'
export default {
  components: {
    PtActionSelectedList,
  },
  data: () => ({
    initDone: false,
    hashtagId: '',
    hashtag: {},
    vees: {},
    folders: [],
    opts: {},
    savePath: '',
    deleteDialog: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.hashtagId = this.$route.params.id
      const path =
        this.hashtagId === 'new'
          ? '/api/v1/hashtags/new'
          : `/api/v1/hashtags/${this.hashtagId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.hashtag = res.data.hashtag
          this.vees = res.data.vees
          this.folders = res.data.folders
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.hashtagId === 'new'
          ? '/api/v1/hashtags'
          : `/api/v1/hashtags/${this.hashtagId}`
      const method = this.hashtagId === 'new' ? 'post' : 'put'
      const prms = {
        hashtag: this.hashtag,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'HashtagIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    destroy() {
      this.savePath = `/api/v1/hashtags/${this.hashtagId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'HashtagIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.hashtag_caution {
  font-size: 4px;
  color: #ff0000;
}
</style>
