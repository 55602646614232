<template>
  <div>
    <v-dialog v-model="internalValue" :max-width="800">
      <v-card>
        <VfLoadingOverlayAbsolute :loading="loading" />
        <VfAlert v-model="errorMsg" type="error" />
        <v-card-title>
          {{ $t(`btn.select_${uploadFileType}`) }}
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="selectTab" grow>
            <v-tab key="newUploadFile">{{ $t('btn.new_upload_file') }}</v-tab>
            <v-tab key="selectUploadFile">{{
              $t('btn.select_upload_file')
            }}</v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectTab">
            <v-tab-item key="newUploadFileTab">
              <PtFilePondForUploadFile
                :name="uploadFileType"
                init-path="/api/v1/upload_files"
                :init-prms="{ upload_file_type: uploadFileType }"
                :allow-multiple="false"
                :max-file-size="deliTypeOpts['max-file-size']"
                :accepted-file-types="deliTypeOpts['accepted-file-types']"
                :allow-image-preview="deliTypeOpts['allow-image-preview']"
                @upload-success="responseSelectedFile"
              />
            </v-tab-item>
            <v-tab-item key="selectUploadFileTab">
              <v-item-group>
                <v-row no-gutters justify="center">
                  <v-col
                    v-for="uploadFile in uploadFiles"
                    :key="uploadFile.id"
                    :md="deliTypeOpts['md']"
                    class="text-center"
                  >
                    <v-item v-slot="{ active, toggle }">
                      <v-card
                        :color="active ? 'primary' : ''"
                        class="pa-1"
                        @click="
                          toggle()
                          selectFile(uploadFile)
                        "
                      >
                        <VImg
                          v-if="uploadFileType === 'image'"
                          :src="uploadFile.preview_url"
                        />
                        <VImg
                          v-if="uploadFileType === 'rich_menu_image'"
                          :src="uploadFile.preview_url"
                        />
                        <PtVideo
                          v-else-if="uploadFileType === 'video'"
                          :width="200"
                          :height="200"
                          :src="uploadFile.video_url"
                        />
                        <PtVideo
                          v-else-if="uploadFileType === 'audio'"
                          :width="200"
                          :height="100"
                          :src="uploadFile.audio_url"
                        />
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn @click="responseSelectedFile(selectedFile)">{{
                      $t('btn.select')
                    }}</v-btn>
                  </v-col>
                </v-row>
              </v-item-group>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtFilePondForUploadFile from 'components/parts/common/PtFilePondForUploadFile'
import PtVideo from 'components/parts/common/PtVideo'
export default {
  components: {
    PtFilePondForUploadFile,
    PtVideo,
  },
  props: {
    value: { type: Boolean, default: false },
    uploadFileType: { type: String, required: true },
  },
  data: () => ({
    loading: false,
    errorMsg: '',
    selectTab: 0,
    uploadFiles: [],
    selectedFile: {},
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    deliTypeOpts() {
      return this.$$uploadFileTypeOpts(this.uploadFileType)
    },
    initPrms() {
      const prms = { image: 0, video: 0, audio: 0 }
      prms[this.uploadFileType] = 1
      return prms
    },
  },
  watch: {
    internalValue(value) {
      if (value) {
        this.init()
      }
    },
  },
  methods: {
    async init() {
      this.errorMsg = ''
      this.loading = true
      const path = '/api/v1/upload_files'
      await this.axios
        .get(path, { params: this.$$reqPrms(this.initPrms) })
        .then((res) => {
          this.uploadFiles = res.data.upload_files
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectFile(uploadFile) {
      this.selectedFile = uploadFile
    },
    responseSelectedFile(file) {
      this.$emit('select-file-done', file)
    },
  },
}
</script>
