<template>
  <v-card class="mx-auto" max-width="500">
    <v-card-title>
      {{ $t('account.user_logout') }}
    </v-card-title>
    <v-divider class="mx-4" />
    <v-card-actions>
      <v-spacer />
      <v-btn color="error" @click="logout">ログアウト</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  methods: {
    logout() {
      if (!this.$store.getters.user) {
        this.$toasted.error('already logout')
        return false
      }
      const url = '/auth/sign_out'
      this.axios.delete(url).then(
        () => {
          this.$store.dispatch('logout')
          this.$toasted.success('logout')
          this.$router.push({ name: 'Login' })
        },
        (error) => {
          this.error = error
        }
      )
    },
  },
}
</script>
