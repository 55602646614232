import VfTextField from 'components/vuetifyParts/VfTextField'
import VfTextFieldOnlyNumber from 'components/vuetifyParts/VfTextFieldOnlyNumber'
import VfTextArea from 'components/vuetifyParts/VfTextArea'
import VfDatePicker from 'components/vuetifyParts/VfDatePicker'
import VfDateTimeSelect from 'components/vuetifyParts/VfDateTimeSelect'
import VfSelect from 'components/vuetifyParts/VfSelect'
import VfSelectChip from 'components/vuetifyParts/VfSelectChip'
import VfRadioButton from 'components/vuetifyParts/VfRadioButton'
import VfSwitch from 'components/vuetifyParts/VfSwitch'
import VfFile from 'components/vuetifyParts/VfFile'
import VfDialogConfirm from 'components/vuetifyParts/VfDialogConfirm'
import VfDialogConfirmWithoutPath from 'components/vuetifyParts/VfDialogConfirmWithoutPath'
import VfGlobalMessage from 'components/vuetifyParts/VfGlobalMessage'
import VfAlert from 'components/vuetifyParts/VfAlert'
import VfLoadingOverlay from 'components/vuetifyParts/VfLoadingOverlay'
import VfLoadingOverlayAbsolute from 'components/vuetifyParts/VfLoadingOverlayAbsolute'
import VfBreadcrumbs from 'components/vuetifyParts/VfBreadcrumbs'
import VfCheckbox from 'components/vuetifyParts/VfCheckbox'

const OrgnVuetifyParts = {
  install(Vue) {
    Vue.component('VfTextField', VfTextField)
    Vue.component('VfTextFieldOnlyNumber', VfTextFieldOnlyNumber)
    Vue.component('VfTextArea', VfTextArea)
    Vue.component('VfDatePicker', VfDatePicker)
    Vue.component('VfDateTimeSelect', VfDateTimeSelect)
    Vue.component('VfSelect', VfSelect)
    Vue.component('VfSelectChip', VfSelectChip)
    Vue.component('VfRadioButton', VfRadioButton)
    Vue.component('VfSwitch', VfSwitch)
    Vue.component('VfFile', VfFile)
    Vue.component('VfDialogConfirm', VfDialogConfirm)
    Vue.component('VfDialogConfirmWithoutPath', VfDialogConfirmWithoutPath)
    Vue.component('VfGlobalMessage', VfGlobalMessage)
    Vue.component('VfAlert', VfAlert)
    Vue.component('VfLoadingOverlay', VfLoadingOverlay)
    Vue.component('VfLoadingOverlayAbsolute', VfLoadingOverlayAbsolute)
    Vue.component('VfBreadcrumbs', VfBreadcrumbs)
    Vue.component('VfCheckbox', VfCheckbox)
  },
}

export default OrgnVuetifyParts
