<template>
  <div>
    <v-card outlined class="w-fc transparent">
      <v-card-title class="pa-0 or-card-title">
        <v-list-item class="pl-0">
          <v-list-item-content>
            {{ $t('lbl.current_friend_info') }}
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              small
              color="success"
              @click="$router.push({ name: 'FriendIndex' })"
            >
              友だちリストを開く
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <VfAlert v-model="errorMsg" type="error" />
      <v-card-text class="pl-0 pt-0">
        <v-simple-table>
          <thead>
            <tr>
              <th class="border-right">
                <div class="d-flex justify-center">有効友だち数</div>
              </th>
              <th>
                <div class="d-flex justify-center">ブロック/友だち解除数</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border-right">
                <div class="count_container">
                  <h5 class="current_valid_count">
                    {{ friendsCounts.validCount }}
                  </h5>
                  <span class="all_current_valid_count"
                    >(友だち総数: {{ friendsCounts.totalCount }} 人)</span
                  >
                </div>
              </td>
              <td>
                <div class="count_container">
                  <h5 class="current_valid_count">
                    {{ friendsCounts.invalidCount }}
                    <span class="percent">({{ friendsCounts.percent }}%)</span>
                  </h5>
                  <span class="all_current_valid_count">
                    (相手から: {{ friendsCounts.blockedCount }} 人 自分から:
                    {{ friendsCounts.blockingCount }} 人 )
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    errorMsg: '',
    friendsCounts: {
      validCount: 0,
      invalidCount: 0,
      blockedCount: 0,
      blockingCount: 0,
      totalCount: 0,
      percent: 0,
    },
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.errorMsg = ''
      const path = '/api/v1/friend_reports/current'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.friendsCounts.validCount = res.data.valid_count
          this.friendsCounts.invalidCount = res.data.invalid_count
          this.friendsCounts.blockedCount = res.data.blocked_count
          this.friendsCounts.blockingCount = res.data.blocking_count
          this.friendsCounts.totalCount =
            res.data.valid_count + res.data.invalid_count
          if (res.data.valid_count + res.data.invalid_count === 0) {
            this.friendsCounts.percent = 0
          } else {
            var percent =
              (res.data.invalid_count /
                (res.data.valid_count + res.data.invalid_count)) *
              100
            this.friendsCounts.percent = Math.round(percent * 10) / 10 // 小数点第一位まで表示
          }
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
      this.loading = false
    },
  },
}
</script>
<style scoped>
.count_container {
  text-align: center;
  padding: 20px 0;
}
</style>
