<template>
  <div>
    <template v-if="isSettingAutoFillAvailable">
      <template v-for="(autoItem, autoItemIndex) in tempAutoList">
        <v-row
          v-if="autoItem.isAutoField"
          :key="autoItem.internal_id"
          class="mt-8 auto-fill-row"
        >
          <template>
            <v-col md="3 px-0">
              <p class="mr-10 font-weight-bold">{{ autoItem.title }}</p>
            </v-col>
            <v-col md="9">
              <VfCheckbox
                v-model="allowSettingList"
                class="vf-unset-default mb-10"
                :checkbox-value="autoItem.internal_id"
                :label="$t('lbl.answer_form.setting.auto_input.checkbox_label')"
              />
              <template v-if="isReadyForSetting(autoItem.internal_id)">
                <v-row class="align-center">
                  <v-col md="2">
                    <span class="font-weight-bold">{{
                      $t('lbl.answer_form.setting.auto_input.all_address')
                    }}</span>
                  </v-col>
                  <v-col md="2">
                    <v-icon>fas fa-angle-right</v-icon>
                  </v-col>
                  <v-col md="6">
                    <v-select
                      v-model="autoAllAdress[autoItemIndex]"
                      class="vf-reset-select"
                      :items="autoFields(autoItem.internal_id)"
                      label=""
                      item-text="title"
                      item-value="internal_id"
                      return-object
                      single-line
                      @change="
                        updateSettingsInfo(
                          $event,
                          autoItem.internal_id,
                          'all_address'
                        )
                      "
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col md="2">
                    <span class="font-weight-bold">{{
                      $t('lbl.answer_form.setting.auto_input.prefecture')
                    }}</span>
                  </v-col>
                  <v-col md="2">
                    <v-icon>fas fa-angle-right</v-icon>
                  </v-col>
                  <v-col md="6">
                    <v-select
                      v-model="autoPrefecture[autoItemIndex]"
                      class="vf-reset-select"
                      :items="autoFields(autoItem.internal_id)"
                      label=""
                      item-text="title"
                      item-value="internal_id"
                      return-object
                      single-line
                      @change="
                        updateSettingsInfo(
                          $event,
                          autoItem.internal_id,
                          'prefecture'
                        )
                      "
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col md="2">
                    <span class="font-weight-bold">{{
                      $t('lbl.answer_form.setting.auto_input.district')
                    }}</span>
                  </v-col>
                  <v-col md="2">
                    <v-icon>fas fa-angle-right</v-icon>
                  </v-col>
                  <v-col md="6">
                    <v-select
                      v-model="autoDistrict[autoItemIndex]"
                      class="vf-reset-select"
                      :items="autoFields(autoItem.internal_id)"
                      label=""
                      item-text="title"
                      item-value="internal_id"
                      return-object
                      single-line
                      @change="
                        updateSettingsInfo(
                          $event,
                          autoItem.internal_id,
                          'district'
                        )
                      "
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col md="2">
                    <span class="font-weight-bold">{{
                      $t('lbl.answer_form.setting.auto_input.town')
                    }}</span>
                  </v-col>
                  <v-col md="2">
                    <v-icon>fas fa-angle-right</v-icon>
                  </v-col>
                  <v-col md="6">
                    <v-select
                      v-model="autoTown[autoItemIndex]"
                      class="vf-reset-select"
                      :items="autoFields(autoItem.internal_id)"
                      label=""
                      item-text="title"
                      item-value="internal_id"
                      return-object
                      single-line
                      @change="
                        updateSettingsInfo($event, autoItem.internal_id, 'town')
                      "
                    />
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col md="2">
                    <span class="font-weight-bold">{{
                      $t(
                        'lbl.answer_form.setting.auto_input.subsequent_address'
                      )
                    }}</span>
                  </v-col>
                  <v-col md="2">
                    <v-icon>fas fa-angle-right</v-icon>
                  </v-col>
                  <v-col md="6">
                    <v-select
                      v-model="autoSubsequentAdress[autoItemIndex]"
                      class="vf-reset-select"
                      :items="autoFields(autoItem.internal_id)"
                      label=""
                      item-text="title"
                      item-value="internal_id"
                      return-object
                      single-line
                      @change="
                        updateSettingsInfo(
                          $event,
                          autoItem.internal_id,
                          'subsequent_address'
                        )
                      "
                    />
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </template>
        </v-row>
      </template>
    </template>
    <p v-else class="mt-10 font-weight-bold">
      {{ $t('lbl.answer_form.setting.auto_input.no_configure') }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    autoList: { type: Array, default: () => [] },
    value: { type: Object, default: () => ({}) },
    mode: { type: String, default: 'new' },
  },
  data() {
    return {
      allowSettingList: [],
      tempAutoList: [],
      autoAllAdress: [],
      autoPrefecture: [],
      autoDistrict: [],
      autoTown: [],
      autoSubsequentAdress: [],
    }
  },
  computed: {
    isSettingAutoFillAvailable() {
      return this.autoList.some((item) => item.isAutoField)
    },
    deepAutoList() {
      return JSON.stringify(this.autoList || [])
    },
  },
  watch: {
    deepAutoList: {
      deep: true,
      handler() {
        this.tempAutoList = this.tempAutoList.map((item) => {
          let itemChange = this.autoList.find(
            (autoItem) => autoItem.internal_id === item.internal_id
          )
          if (itemChange && itemChange.internal_id) {
            item.title = itemChange.title
            item.isAutoField = itemChange.isAutoField
            if (!item.isAutoField) {
              delete item.all_address
              delete item.prefecture
              delete item.district
              delete item.town
              delete item.subsequent_address
            }
          }
          return item
        })
      },
    },
    autoList() {
      // If auto list remove an item
      if (this.autoList.length < this.tempAutoList.length) {
        this.tempAutoList.forEach((item, index) => {
          let itemDeleted = this.autoList.every(
            (autoField) => autoField.internal_id !== item.internal_id
          )
          if (itemDeleted) {
            this.tempAutoList.splice(index, 1)
          }
        })
      } else if (this.autoList.length > this.tempAutoList.length) {
        // If auto list add an item
        this.autoList.forEach((item) => {
          let itemAdded = this.tempAutoList.every(
            (tempItem) => tempItem.internal_id !== item.internal_id
          )
          if (itemAdded) {
            this.tempAutoList.push(item)
          }
        })
      }
      this.setDefaultValueAutoFill()
      this.$emit('change', this.tempAutoList)
    },
    allowSettingList(value) {
      this.tempAutoList = this.tempAutoList.map((item) => {
        if (!value.includes(item.internal_id)) {
          delete item.all_address
          delete item.prefecture
          delete item.district
          delete item.town
          delete item.subsequent_address
        }
        return item
      })
      this.$emit('change', this.tempAutoList)
    },
  },
  mounted() {
    if (this.mode !== 'new') {
      if (this.value.auto_input_setting) {
        this.tempAutoList = JSON.parse(this.value.auto_input_setting)
        if (typeof this.tempAutoList === 'string') {
          this.tempAutoList = JSON.parse(this.tempAutoList)
        }
        this.tempAutoList = this.tempAutoList || []
        this.allowSettingList = this.tempAutoList.reduce((a, i) => {
          let isHasSetAutoFill =
            i.all_address ||
            i.prefecture ||
            i.district ||
            i.town ||
            i.subsequent_address
          if (i.isAutoField && isHasSetAutoFill) {
            a.push(i.internal_id)
          }
          return a
        }, [])
        this.setDefaultValueAutoFill()
      }
    }
  },
  updated() {
    this.$emit('change', this.tempAutoList)
  },
  methods: {
    setDefaultValueAutoFill() {
      this.tempAutoList = this.tempAutoList || []
      this.tempAutoList.forEach((item, index) => {
        this.autoAllAdress[index] = item.all_address
        this.autoPrefecture[index] = item.prefecture
        this.autoDistrict[index] = item.district
        this.autoTown[index] = item.town
        this.autoSubsequentAdress[index] = item.subsequent_address
      })
    },
    autoFields(internal_id) {
      let autoFillList = this.tempAutoList.filter(
        (item) => item.internal_id !== internal_id
      )
      autoFillList.unshift({ internal_id: '', title: 'なし' })
      return autoFillList
    },
    isReadyForSetting(internal_id) {
      return this.allowSettingList.some((item) => item === internal_id)
    },
    updateSettingsInfo(value, questionId, field) {
      let questionIndex = this.tempAutoList.findIndex(
        (item) => item.internal_id === questionId
      )
      this.tempAutoList[questionIndex][field] = value
      this.$emit('change', this.tempAutoList)
    },
  },
}
</script>
<style lang="scss" scoped>
.vf-unset-default ::v-deep {
  margin: unset;
  padding: 0;

  .v-input__control {
    max-width: fit-content;
  }

  .v-input__slot {
    margin: unset;
    .v-label {
      margin: unset;
    }
  }

  .v-messages {
    min-height: 0;
  }
}

.vf-reset-select ::v-deep {
  margin: 0;
  padding: 0;

  .v-input__slot {
    margin: 0;
  }

  .v-text-field__details {
    display: none;
  }
}

.auto-fill-row {
  border-bottom: 1px solid #eee;
  margin: auto 16px;
}
</style>
