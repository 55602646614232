<template>
  <div>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <VfSelectChip
                  v-model="checkedItemsIds"
                  :label="$$tm('hashtag.name')"
                  :selects="selects"
                  readonly
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="4">
            <PtFolder
              ref="folder"
              folder-type="hashtag"
              select-only
              @select-folder="selectFolder"
            />
          </v-col>
          <v-col md="8">
            <v-data-table
              v-model="hashtagsClone"
              :headers="headers"
              :items="items"
              item-key="id"
              show-select
              :single-select="false"
              :disable-sort="true"
              :footer-props="{
                itemsPerPageOptions: [10, 30, 50, 100],
              }"
              @click:row="rowClick"
            >
              <template #[`item.action_group`]="{ item }">
                <PtActionLabelMessages
                  v-model="item.action_group.make_action_arr"
                  class="or-list"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PtFolder from 'components/parts/common/PtFolder'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  components: {
    PtFolder,
    PtActionLabelMessages,
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    hashtagsClone: [],
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    selects() {
      const ret = {}
      this.hashtagsClone.forEach((item) => (ret[item.name] = item.id))
      this.internalValue = this.hashtagsClone
      return ret
    },
    checkedItemsIds: {
      get() {
        return this.hashtagsClone.map((row) => row.id)
      },
      set(arr) {
        const ret = []
        arr.forEach((id) =>
          ret.push(this.hashtagsClone.find((row) => row.id === id))
        )
        this.hashtagsClone = ret
        this.internalValue = this.hashtagsClone
      },
    },
  },
  created() {
    this.initTable()
  },
  methods: {
    async initTable(folderId) {
      this.loading = true
      const path = '/api/v1/hashtags/action_group'
      const prms = { folder_id: folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.hashtags
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectFolder(folderId) {
      this.initTable(folderId)
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
  },
}
</script>
