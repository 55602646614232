<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-btn @click="dialog = true">
          {{ $t('btn.select_video') }}
        </v-btn>
        <v-card-text v-if="internalValue.video_url">
          <v-card outlined tile :max-width="200">
            <PtVideo
              :key="internalValue.video_url"
              :src="internalValue.video_url"
              :width="200"
              :height="200"
            />
          </v-card>
        </v-card-text>
      </v-card-text>
    </v-card>
    <PtDeliDialogForSelectFile
      v-model="dialog"
      upload-file-type="video"
      @select-file-done="selectFileDone"
    />
  </div>
</template>
<script>
import PtVideo from 'components/parts/common/PtVideo'
import PtDeliDialogForSelectFile from 'components/parts/common/PtDeli/PtDeliDialogForSelectFile'
export default {
  components: {
    PtVideo,
    PtDeliDialogForSelectFile,
  },
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    selectFileDone(uploadFile) {
      this.dialog = false
      this.internalValue.upload_file_id = uploadFile.id
      this.internalValue.video_url = uploadFile.video_url
    },
  },
}
</script>
