<template>
  <v-card>
    <v-card-title>
      {{ $t('notificator.setting') }}
    </v-card-title>
    <v-divider class="my-0" />
    <v-data-table
      :headers="headers"
      :items="lineNotices"
      :items-per-page="-1"
      disable-sort
      hide-default-footer
    >
      <template #[`item.channel.name`]="{ item }">
        <a @click="$emit('click:row', item.id)">
          {{ item.channel.name }}
        </a>
      </template>
      <template #[`item.notice_type`]="{ item }">
        {{ $$te('line_notice.notice_type', item) }}
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn color="primary" small @click="testNotification(item)">
          {{ $t('notificator.test') }}
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    lineNotices: { type: Array, required: true },
  },
  data: () => ({
    headers: [
      { text: 'チャネル名', value: 'channel.name' },
      { text: '設定', value: 'notice_type' },
      { text: '操作', value: 'actions' },
    ],
  }),
  methods: {
    testNotification(item) {
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing') /* end parent component */
      this.$emit('click:testNotification', item)
    },
  },
}
</script>
