<template>
  <div>
    <PtTitle />
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-title>
            {{ '「' + name + '」への回答' }}
          </v-card-title>
          <hr />
          <v-card-text>
            <p v-if="answerDetail && answerDetail.hasOwnProperty('created_at')">
              <span
                >回答日:
                <b>{{
                  moment(answerDetail.created_at).format('YYYY/MM/DD HH:mm:ss')
                }}</b></span
              >
            </p>
            <table
              v-if="
                answerDetail && answerDetail.hasOwnProperty('form_response')
              "
              class="table table-bordered table-striped"
            >
              <tbody>
                <template v-for="answer in answerDetail.form_response">
                  <tr
                    v-if="
                      answer.hasOwnProperty('beautify_value') &&
                      Array.isArray(answer.beautify_value) &&
                      answer.beautify_value.length > 0 &&
                      answer.beautify_value[0]
                    "
                  >
                    <td width="40%">{{ answer.input_title }}</td>
                    <td width="60%">
                      <p v-for="val in answer.beautify_value">{{ val }}</p>
                    </td>
                  </tr>
                  <tr v-else>
                    <td width="40%">{{ answer.input_title }}</td>
                    <td width="60%">
                      <template
                        v-if="answer.value && !Array.isArray(answer.value)"
                      >
                        <template v-if="typeof answer.value == 'object'">
                          <div
                            v-if="
                              answer.value.hasOwnProperty('upload_file_type') &&
                              answer.value.upload_file_type == 'image'
                            "
                          >
                            <img :src="answer.value.file.preview" alt="" />
                            <p>
                              <a :href="answer.value.file.original">{{
                                getNameImage(answer.value.file.original)
                              }}</a>
                            </p>
                          </div>
                          <div
                            v-if="
                              answer.value.hasOwnProperty('upload_file_type') &&
                              answer.value.upload_file_type == 'document'
                            "
                          >
                            <v-icon>mdi-file-document</v-icon>
                            <p>
                              <a :href="answer.value.file.original">{{
                                getNameImage(answer.value.file.original)
                              }}</a>
                            </p>
                          </div>
                        </template>
                        <template v-else>
                          {{ answer.value }}
                        </template>
                      </template>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card>
          <v-card-title> 回答を登録する友だち </v-card-title>
          <hr />
          <v-card-text>
            <v-row v-if="friends.length > 0 && detailData == null">
              <div class="d-block">
                <p class="font-weight-bold ml-3 mb-0">
                  友だちを選択してください
                </p>
                <p class="ml-3">検索名: {{ search }}</p>
              </div>
              <table class="table table-bordered ml-3">
                <tbody>
                  <tr v-for="item in friends">
                    <td>
                      <a href="javascript:void(0)" @click="detail(item)">
                        <img
                          class="orgn-icon"
                          :src="$$noimageHelper(item.api_picture_url)"
                        />
                        <span>{{ item.name }}</span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-row>
            <v-row
              v-if="!friends.length && search && isSearch && detailData == null"
            >
              <p class="ml-3">検索名: {{ search }}</p>
              <table class="table table-bordered ml-3">
                <tbody>
                  <tr>
                    <td>該当する友だちがいません</td>
                  </tr>
                </tbody>
              </table>
            </v-row>
            <v-row v-if="detailData" class="ml-1">
              <v-col md="12">
                <div>
                  <img
                    class="orgn-icon"
                    :src="$$noimageHelper(detailData.api_picture_url)"
                  />
                  <span class="pl-2"
                    ><b>{{ detailData.name }}</b></span
                  >
                  <span>{{ detailData.id }}</span>
                  <v-btn small @click="detailFriend(detailData.id)">詳細</v-btn>
                </div>
              </v-col>
              <v-chip class="ma-2 pl-2" color="green" text-color="white" small>
                <v-icon x-small>mdi-message</v-icon>LINE
              </v-chip>
              <v-col md="12">
                <div>
                  <p class="font-weight-bold">タグ</p>
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td v-if="!detailData.hashtags.length">登録なし</td>
                        <td v-else>
                          <template v-for="item in detailData.hashtags">
                            <v-chip class="ma-2">
                              {{ item.name }}
                            </v-chip>
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-col>
              <v-col md="12">
                <table class="table table-bordered ml-3">
                  <tbody>
                    <tr v-for="item in friends">
                      <td>
                        <a href="javascript:void(0)" @click="detail(item)" />
                        <img
                          class="orgn-icon"
                          :src="$$noimageHelper(item.api_picture_url)"
                        />
                        <span>{{ item.name }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
            <v-row>
              <div class="d-block">
                <p v-if="detailData" class="ml-3 mb-0">検索をやり直す</p>
                <p class="font-weight-bold mt-3 ml-3">
                  検索する友だち名を入力してください
                </p>
              </div>
              <v-col md="10">
                <v-text-field v-model="search" />
              </v-col>
              <v-col md="2">
                <v-btn color="success" @click="onSearch">検索</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="detailData != null">
      <hr />
      <v-row>
        <v-col md="6">
          <v-card height="100px" color="#fcf8e3">
            <v-card-text>
              <p>確認事項</p>
              <ul>
                <li>
                  フォームにメッセージ送信を設定していた場合、照合時に配信されます。
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="6">
          <v-card height="100px">
            <v-card-text>
              <v-dialog v-model="dialog" persistent max-width="400">
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mt-3"
                    color="success"
                    width="100%"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ detailData.name.trim() + 'の回答として登録する' }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title />
                  <v-card-text>
                    操作は取り消せません。本当にこの友だちに登録しますか？
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn @click="dialog = false">いいえ</v-btn>
                    <v-btn color="primary" @click="addFriend">はい</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  data: () => ({
    loading: false,
    moment: moment,
    name: null,
    answerDetail: null,
    friends: [],
    search: null,
    isSearch: false,
    detailData: null,
    dialog: false,
  }),
  created() {
    this.name = this.$route.query.name
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path =
        '/api/v1/answer_form_responses/' + this.$route.params.id + '/edit'
      await this.axios
        .get(path)
        .then((res) => {
          this.answerDetail = res.data.answer_form_response
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async onSearch() {
      if (this.search) {
        this.detailData = null
        this.isSearch = true
        this.loading = true
        const path = '/api/v1/friends/search_assign_friends'
        await this.axios
          .get(path, {
            params: { 'q[free_text]': this.search },
          })
          .then((res) => {
            this.friends = res.data.friends
          })
          .catch((error) => {
            this.$$ajaxError(error)
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    getNameImage(link) {
      let arr = link.split('/')
      return arr[arr.length - 1]
    },
    detail(data) {
      this.detailData = data
      this.friends = []
    },
    async addFriend() {
      this.loading = true
      this.dialog = false
      const path = '/api/v1/answer_form_responses/' + this.$route.params.id
      await this.axios
        .put(path, { answer_form_response: { friend_id: this.detailData.id } })
        .then(() => {
          let routeData = this.$router.resolve({
            name: 'AnswerFormShow',
            params: { id: this.$route.query.form_id },
            query: { tab: 3 },
          })
          window.open(routeData.href)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    detailFriend(friendId) {
      let routeData = this.$router.resolve({
        name: 'FriendShow',
        params: { id: friendId },
      })
      window.open(routeData.href, '_blank')
    },
  },
}
</script>
<style scoped lang="scss">
a:hover {
  text-decoration: none;
}
::v-deep .v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
::v-deep .v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.color-white {
  color: #fff !important;
}
::v-deep {
  fieldset > legend {
    display: none;
  }
  .v-label {
    display: none;
  }
}
.orgn-icon {
  width: 60px;
  height: 60px;
}
</style>
