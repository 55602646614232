<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-row>
      <v-col md="6" class="pb-0">
        <VfTextField
          v-model="internalValue.title"
          :label="$t('lbl.answer_form.item_name')"
          :vees="vees.answer_form_input.title"
          @input="updateTitle"
        />
      </v-col>
      <v-col md="6" class="pb-0">
        <VfCheckbox
          v-model="internalValue.hide_title_flg"
          :label="$t('lbl.answer_form.hide_item_name')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="pb-4">
        <VfTextField
          v-model="internalValue.description"
          :label="$t('lbl.answer_form.description')"
          :vees="vees.answer_form_input.short_description"
          :hint="$t('lbl.answer_form.text_field_desc_hint')"
        />
      </v-col>
      <v-col md="6" class="pb-4">
        <VfTextField
          v-model="internalValue.placeholder"
          :label="$t('lbl.answer_form.placeholder')"
          :vees="vees.answer_form_input.placeholder"
          :placeholder="$t('lbl.answer_form.placeholder_hint')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="pb-0">
        <VfSelect
          v-model="internalValue.additional_option_type"
          :label="$t('lbl.answer_form.initial_display')"
          :selects="opts.answer_form_input.additional_option_types.text_field"
        />
      </v-col>
      <v-col md="6" class="pb-0">
        <VfTextField
          v-if="internalValue.additional_option_type === 'prepopulate'"
          v-model="internalValue.prepopulate_value"
          :label="''"
          :vees="vees.answer_form_input.prepopulate_value"
          :hint="
            internalValue.validation_type === 'time'
              ? $t('lbl.answer_form.initial_time_desc')
              : ''
          "
        />
        <div
          v-show="
            internalValue.validation_type === 'time' &&
            internalValue.additional_option_type === 'do_nothing'
          "
          class="v-messages__message v-messages theme--light pt-8"
        >
          {{ $t('lbl.answer_form.initial_time_desc') }}
        </div>
      </v-col>
    </v-row>
    <div class="pt-6 pb-4">
      <v-card>
        <v-card-subtitle class="or-card-title mb-0">
          {{ $t('lbl.answer_form.where_assign_to') }}
        </v-card-subtitle>
        <v-card-text>
          <v-card
            v-for="(target, target_i) in internalValue.assign_value_to"
            :key="target_i"
            class="mb-4"
          >
            <v-card-text class="py-0">
              <v-row>
                <v-col md="2" class="d-flex align-items-center center">
                  {{ $t(`lbl.answer_form.assign_to.${target}`) }}
                </v-col>
                <v-col md="8" class="d-flex align-items-center center">
                  {{ $t(`lbl.answer_form.assign_to.${target}_hint`) }}
                </v-col>
                <v-col md="2" class="text-right">
                  <v-btn
                    icon
                    class=""
                    :disabled="false"
                    @click="removeTarget(target_i)"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row>
            <v-col class="d-flex justify-content-center">
              <v-btn
                v-for="(assign, assign_i) in opts.answer_form_input.assign_to"
                :key="assign_i"
                class="ma-2"
                :disabled="targets.includes(assign)"
                @click="addTarget(assign)"
                >{{ assign_i }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-row>
      <v-col md="4">
        <VfSelect
          v-model="internalValue.validation_type"
          :label="''"
          :selects="opts.answer_form_input.validation_types"
          @change="updateValidationRule"
        />
      </v-col>
      <v-col md="2" class="pb-0 offset-4">
        <VfCheckbox
          v-model="internalValue.require_flg"
          :label="$t('lbl.answer_form.require')"
        />
      </v-col>
      <v-col md="2" class="pb-0">
        <VfCheckbox
          v-model="internalValue.hidden_flg"
          :label="$t('lbl.answer_form.hide')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PtFormSetupTextField',
  props: {
    value: { type: Object, required: true },
    targets: { type: Array, default: () => [] },
    vees: { type: Object, default: () => ({}) },
    opts: { type: Object, default: () => ({}) },
  },
  data: function () {
    return {
      loading: false,
      errorMsg: '',
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    if (this.internalValue.additional_option_type === null) {
      this.internalValue.additional_option_type = 'do_nothing'
    }
  },
  methods: {
    addTarget(target) {
      this.internalValue.assign_value_to.push(target)
    },
    removeTarget(index) {
      this.internalValue.assign_value_to.splice(index, 1)
    },
    updateTitle(title) {
      this.handleFormInputUpdate('title', { title })
    },
    updateValidationRule(value) {
      this.handleFormInputUpdate('validation', { validation: value })
    },
    handleFormInputUpdate(attribute, data) {
      this.$emit('update', {
        type: 'text-field',
        attribute,
        data,
      })
    },
  },
}
</script>

<style scoped>
.time-pick {
  width: 100px;
}
</style>
