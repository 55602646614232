<template>
  <div>
    <PtTitle />
    {{ $t('subtitle.click') }}
    <v-row>
      <v-col md="3">
        <PtFolder
          ref="folder"
          folder-type="click"
          @select-folder="selectFolder"
          @pt-init-folders="ptInitFolders"
        />
      </v-col>
      <v-col md="9">
        <v-card outlined>
          <VfLoadingOverlayAbsolute :loading="loading" />
          <v-card-title>
            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="search"
                  label="Search"
                  append-icon="mdi-magnify"
                  clearable
                  single-line
                  hide-details
                />
              </v-col>
              <v-col md="6" class="text-right">
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :disabled="checkedItems.length === 0"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('btn.multi') }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="selectedActionConfirm('delete')">
                      <v-list-item-title>{{
                        $t('lbl.selected_delete')
                      }}</v-list-item-title>
                    </v-list-item>
                    <VDivider />
                    <v-list-item
                      v-for="(folder, folder_idx) in folders"
                      :key="folder_idx"
                      @click="moveFolder(folder)"
                    >
                      <v-list-item-title>{{
                        $t('lbl.move_to', { to: folder.name })
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn color="primary" @click="newItem">{{
                  $t('btn.new_item')
                }}</v-btn>
                <div
                  class="mt-1"
                  style="font-size: 12px"
                  v-html="$t('lbl.click.delete_warning')"
                ></div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            v-model="checkedItems"
            :headers="headers"
            :items="items"
            :search="search"
            item-key="id"
            :disable-sort="true"
            show-select
            :items-per-page="50"
            :footer-props="{
              itemsPerPageOptions: [50, 100, 150],
            }"
          >
            <template #[`item.name`]="{ item }">
              <a href="#" @click="showItem(item)">
                {{ item.name }}
              </a>
            </template>
            <template #[`item.created_at`]="{ item }">
              {{ $$fdt(item.created_at) }}
            </template>
            <template #[`item.action_group`]="{ item }">
              <PtActionLabelMessages
                v-model="item.action_group.make_action_arr"
                class="or-list"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
    <PtDialogLinePreview
      :key="selectedItem.id"
      v-model="previewDialog"
      type="click"
      :model-id="selectedItem.id"
    />
    <v-dialog v-model="commonDialog" max-width="500">
      <v-card>
        <VfLoadingOverlayAbsolute :loading="commonDialogLoading" />
        <v-card-text class="pt-6">
          {{ $t('confirm.delete_click') }}
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="commonDialog = false">{{ $t('btn.back') }}</v-btn>
          <v-btn color="primary" @click="selectedSave(selectedAction)">{{
            $t('btn.delete')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtFolder from 'components/parts/common/PtFolder'
import PtDialogLinePreview from 'components/parts/common/PtDialogLinePreview'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  components: {
    PtFolder,
    PtDialogLinePreview,
    PtActionLabelMessages,
  },
  data: () => ({
    loading: false,
    checkedItems: [],
    headers: [],
    items: [],
    search: '',
    savePath: '',
    folderId: '',
    selectedItem: { id: '' },
    previewDialog: false,
    deleteDialog: false,
    commonDialog: false,
    commonDialogLoading: false,
    selectedAction: '',
    folders: [],
    moveFolderId: '',
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/clicks'
      const prms = { folder_id: this.folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          if (Array.isArray(this.headers)) {
            this.headers.push({
              text: 'Site Url',
              value: 'site_url',
              align: ' d-none',
            })
          }
          this.items = res.data.clicks
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    newItem() {
      const prms = {}
      if (this.folderId) {
        prms.fid = this.folderId
      }
      this.$router.push({
        name: 'ClickForm',
        params: { id: 'new' },
        query: prms,
      })
    },
    deleteItem(item) {
      this.savePath = `/api/v1/clicks/${item.id}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.init()
      this.$refs.folder.initFromParent()
    },
    showItem(item) {
      this.$router.push({ name: 'ClickShow', params: { id: item.id } })
    },
    selectFolder(folderId) {
      this.folderId = folderId
      this.init()
    },
    ptInitFolders(folders) {
      this.folders = folders
    },
    selectedActionConfirm(action) {
      this.selectedAction = action
      this.commonDialog = true
    },
    moveFolder(folder) {
      this.moveFolderId = folder.id
      this.selectedSave('move_folder')
    },
    async selectedSave(action) {
      this.commonDialogLoading = true
      let prms = { selected_items: this.checkedItems }
      let path = null
      switch (action) {
        case 'delete':
          path = `/api/v1/clicks/selected_delete`
          break
        case 'move_folder':
          path = `/api/v1/clicks/selected_move_folder`
          prms['folder_id'] = this.moveFolderId
          break
      }
      const method = 'post'
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.checkedItems = []
          this.$refs.folder.init()
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.commonDialogLoading = false
          this.commonDialog = false
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
