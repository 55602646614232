<template>
  <div>
    <v-card class="mb-8">
      <validation-observer ref="observer">
        <VfLoadingOverlayAbsolute :loading="$wait.is('processing')" />
        <VfAlert v-model="errorMsg" type="error" />
        <v-card>
          <v-card-title>
            {{ $t(`lbl.action_group.action_types.reminder`) }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6" class="pb-0">
                <VfRadioButton
                  v-model="reminderActionTypeModel"
                  :label="$$tm('action_reminder.reminder_action_type')"
                  :selects="reminderActionType"
                  label-hidden
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <PtDialogSelect
                  v-model="reminder"
                  select-type="reminder"
                  :single-select="true"
                  :vees="{ rules: 'required' }"
                  only-have-timing
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <VfDateTimeSelect
                  v-if="reminderActionTypeModel === 'start'"
                  v-model="goalAt"
                  :label="$$tm('action_reminder.goal_at')"
                  format="YYYY/MM/DD HH:mm"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </validation-observer>
    </v-card>
    <pt-friend-update-common-bottom
      v-model="allFriendsSelected"
      :friends="friends"
      :total-count="totalCount"
      :alert-message="$t('lbl.confirm_friends_reminder_update')"
      @save="save"
    />
  </div>
</template>
<script>
import PtFriendUpdateCommonBottom from './PtFriendUpdateCommonBottom.vue'
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtFriendUpdateCommonBottom,
    PtDialogSelect,
  },
  props: {
    friends: { type: Array, required: true },
    checkedFriends: { type: Array, required: true },
    searchWrap: { type: Object, default: () => ({}) },
    totalCount: { type: Number, default: null },
  },
  data: () => ({
    dialog: false,
    errorMsg: '',
    headers: [],
    items: [],
    reminder: {},
    goalAt: '',
    allFriendsSelected: 0,
    reminderActionTypeModel: 'start',
    reminderActionType: {
      リマインダーを開始: 'start',
      リマインダーを停止: 'cancel',
    },
  }),
  computed: {
    targetFriendIds() {
      return this.checkedFriends.map((friend) => friend.id)
    },
  },
  created() {
    this.goalAt = new Date()
  },
  methods: {
    async save() {
      this.errorMsg = ''
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.$wait.end('processing') /* start child component */
        return this.$toasted.error(this.$t('danger.params'))
      }
      const path = `/api/v1/friends/target_friends_update_reminders`
      const prms = {
        friend_ids: this.targetFriendIds,
        reminder: this.reminder.reminder,
        goal_at: this.goalAt,
        action_type: this.reminderActionTypeModel,
        all_friends_selected: this.allFriendsSelected,
        search_wrap: this.searchWrap,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.$emit('update')
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.$wait.end('processing') /* start child component */
        })
    },
  },
}
</script>
<style scoped>
.label-mb-0 ::v-deep label {
  margin-bottom: 0px;
}
</style>
