<template>
  <div>
    <div v-for="deli in delis" class="mb-4">
      <PtLinePreviewRow :deli="deli" />
    </div>
  </div>
</template>
<script>
import PtLinePreviewRow from 'components/parts/common/PtLinePreviewRow'
export default {
  components: {
    PtLinePreviewRow,
  },
  props: {
    delis: { default: null },
  },
}
</script>
