<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn
            v-if="scheduleId !== 'new'"
            color="error"
            :disabled="fsch_active_flg"
            @click="destroy"
            >{{ $t('btn.delete') }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.schedule.info') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <VfSelect
                v-model="schedule.folder_id"
                :label="$$tm('folder.name')"
                :vees="vees.folder_id"
                :selects="folders"
              />
            </v-col>
            <v-col md="6">
              <VfTextField
                v-model="schedule.name"
                :label="$$tm('schedule.name')"
                :vees="vees.name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <VfDateTimeSelect
                v-model="schedule.st_at"
                :label="$$tm('schedule.st_at')"
                format="YYYY/MM/DD"
                :vees="vees.st_at"
                :disabled="fsch_active_flg"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.schedule.schedule') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="3">
              <VfDateTimeSelect
                v-model="schedule.action_tm"
                :label="$$tm('schedule.action_tm')"
                :vees="vees.action_tm"
                :disabled="fsch_active_flg"
                only-time
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="2">
              <VfTextField
                v-model="schedule.interval_num"
                :label="$$tm('schedule.interval_num')"
                :vees="vees.interval_num"
                :disabled="fsch_active_flg"
              />
            </v-col>
            <v-col md="4">
              <VfSelect
                v-model="schedule.interval_type"
                :label="$$tm('schedule.interval_type')"
                :vees="vees.interval_type"
                :selects="opts.interval_type"
                :disabled="fsch_active_flg"
              />
            </v-col>
          </v-row>
          <v-row v-if="schedule.interval_type === 'week'">
            <v-col>
              <table>
                <tr>
                  <td v-for="day in DAYS">
                    <div class="mr-6">
                      <VfCheckbox
                        v-model="schedule[day]"
                        :label="$$tm(`schedule.${day}`)"
                        :disabled="fsch_active_flg"
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <v-row v-if="schedule.interval_type === 'month'">
            <v-col md="6">
              <VfSelect
                v-model="schedule.month_type"
                :label="$$tm('schedule.month_type')"
                :vees="vees.month_type"
                :selects="opts.month_type"
                :disabled="fsch_active_flg"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <VfSelect
                v-model="schedule.ed_type"
                :label="$$tm('schedule.ed_type')"
                :vees="vees.ed_type"
                :selects="opts.ed_type"
                :disabled="fsch_active_flg"
              />
            </v-col>
            <v-col v-if="schedule.ed_type === 'date'" md="6">
              <VfDateTimeSelect
                v-model="schedule.ed_at"
                :label="$$tm('schedule.ed_at')"
                :vees="vees.ed_at"
                :disabled="fsch_active_flg"
                format="YYYY/MM/DD"
              />
            </v-col>
            <v-col v-if="schedule.ed_type === 'times'" md="2">
              <VfTextField
                v-model="schedule.ed_action_count"
                :label="$$tm('schedule.ed_action_count')"
                :vees="vees.ed_action_count"
                :disabled="fsch_active_flg"
                number
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.schedule.action') }}
        </v-card-title>
        <v-card-text>
          <PtActionSelectedList
            hide-timing-type-select
            v-model="schedule.action_arr"
          />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col class="text-right">
          <v-btn
            color="primary"
            :loading="$wait.is('processing')"
            @click="save"
            >{{ $t('btn.create') }}</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtActionSelectedList from 'components/parts/common/PtActionGroup/PtActionSelectedList'
export default {
  components: {
    PtActionSelectedList,
  },
  data: () => ({
    initDone: false,
    scheduleId: '',
    schedule: {},
    fsch_active_flg: false,
    vees: {},
    folders: [],
    opts: {},
    timePick: '',
    savePath: '',
    deleteDialog: false,
  }),
  computed: {
    DAYS() {
      return [
        'sun_flg',
        'mon_flg',
        'tue_flg',
        'wed_flg',
        'thu_flg',
        'fri_flg',
        'sat_flg',
      ]
    },
  },
  watch: {
    timePick(val) {
      this.schedule.action_tm = `2000-01-01 ${val}`
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.scheduleId = this.$route.params.id
      const path =
        this.scheduleId === 'new'
          ? '/api/v1/schedules/new'
          : `/api/v1/schedules/${this.scheduleId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.schedule = res.data.schedule
          this.fsch_active_flg = res.data.fsch_active_flg
          this.vees = res.data.vees
          this.folders = res.data.folders
          this.opts = res.data.opts
          this.timePick = this.$$ftm(this.schedule.action_tm)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.scheduleId === 'new'
          ? '/api/v1/schedules'
          : `/api/v1/schedules/${this.scheduleId}`
      const method = this.scheduleId === 'new' ? 'post' : 'put'
      const prms = {
        schedule: this.schedule,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'ScheduleIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    destroy() {
      this.savePath = `/api/v1/schedules/${this.scheduleId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'ScheduleIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
