<template>
  <v-card class="my-4">
    <VfLoadingOverlayAbsolute :loading="loading" />
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      :disable-sort="true"
    >
      <template #[`item.created_at`]="{ item }">
        {{ $$fat(item.created_at) }}
      </template>
      <template #[`item.friend_id`]="{ item }">
        {{ item.friend.name }}
      </template>
      <template #[`item.deli_id`]="{ item }">
        <PtLinePreviewRow class="my-2" :deli="item.deli" />
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import PtLinePreviewRow from 'components/parts/common/PtLinePreviewRow'
export default {
  components: {
    PtLinePreviewRow,
  },
  props: {
    clickId: { type: String, required: true },
  },
  data: () => ({
    headers: [],
    items: [],
    loading: false,
    csv_data: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/click_unicasts'
      const prms = { click_id: this.clickId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.click_unicasts

          let headers = this.headers.map((h) => h.text)
          let items = this.items.map((item) => [
            this.$$fat(item.created_at),
            item.friend.name,
            this.$$csv_deli(item.deli),
            item.click_count,
          ])
          this.csv_data = [headers].concat(items)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
