<template>
  <div>
    <v-row>
      <v-col cols="12">
        <VfRadioButton
          v-model="internalValue.target_menu_type"
          :label="$$tm('search_rich_menu.search_rich_menu_type')"
          :selects="opts.target_menu_type"
        />
      </v-col>
    </v-row>
    <v-row v-if="internalValue.target_menu_type === 'one'">
      <v-col md="6" class="py-0">
        <PtDialogSelect
          v-model="internalValue"
          select-type="rich_menu"
          :single-select="true"
          :vees="vees.rich_menu_id"
        >
          <template #[`item.upload_file_id`]="{ item }">
            <VImg width="120" :src="item.upload_file.preview_image.url" />
          </template>
        </PtDialogSelect>
      </v-col>
      <v-col cols="12" md="6" class="py-0">
        <VfSelect
          v-model="internalValue.search_rich_menu_type"
          :label="$$tm('search_rich_menu.search_rich_menu_type')"
          :selects="setMenus"
          :vees="vees.search_rich_menu_type"
        />
      </v-col>
    </v-row>
    <v-row v-if="internalValue.target_menu_type === 'all'">
      <v-col md="12">
        <VfSelect
          v-model="internalValue.search_rich_menu_type"
          :label="$$tm('search_rich_menu.search_rich_menu_type')"
          :selects="notMenus"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtDialogSelect,
  },
  props: {
    value: { type: Object, required: true },
    vees: { type: Object, default: () => ({}) },
    opts: { type: Object, default: () => ({}) },
  },
  data: () => ({
    loading: false,
    setMenus: [],
    notMenus: [],
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    'internalValue.target_menu_type'(val) {
      if (val !== 'one') {
        this.internalValue.rich_menu_id = ''
        this.$set(this.internalValue, 'rich_menu', { id: '', name: '' })
        this.internalValue.search_rich_menu_type = 'someone'
        this.checkedItems = []
      } else {
        this.internalValue.search_rich_menu_type = 'now_one'
      }
    },
  },
  created() {
    const menuSelects1 = Object.entries(this.opts.search_rich_menu_type)
      .slice(0, 2)
      .map(([key, value]) => ({ key, value }))
    const menuSelects2 = Object.entries(this.opts.search_rich_menu_type)
      .slice(2, 4)
      .map(([key, value]) => ({ key, value }))
    this.setMenus = menuSelects1.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    )
    this.notMenus = menuSelects2.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.value }),
      {}
    )
  },
}
</script>
