<template>
  <div>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="$wait.is('processing')" />
      <v-card-title class="pa-0">
        <v-list-item>
          <v-list-item-content>
            {{ $t('lbl.friend.scenario') }}
          </v-list-item-content>
          <v-list-item-content class="scenario_caution">
            {{ $t('lbl.scenario.friend_show_caution') }}
          </v-list-item-content>
          <v-list-item-action v-if="hasRole">
            <v-wait for="processing">
              <PtDialogSelect
                v-model="scenario"
                select-type="scenario"
                :single-select="true"
                :hide-folder="true"
                @decide="save"
                only-have-timing
              >
                <template #opener>
                  <v-btn icon outlined>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </PtDialogSelect>
            </v-wait>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <VDivider class="my-0" />
      <v-card-text>
        <v-chip v-if="internalValue.name" class="ma-2">
          {{ internalValue.name }}
        </v-chip>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtDialogSelect,
  },
  props: {
    value: { type: Object, required: true },
    friendId: { type: String, required: true },
  },
  data: () => ({
    scenario: {
      scenario: { id: '', name: '' },
    },
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    hasRole() {
      return this.$store.getters.roles.includes('friend_scenario')
    },
  },
  created() {
    this.setDataScenario()
  },
  methods: {
    async save() {
      this.$wait.start('processing')
      const path = `/api/v1/friends/${this.friendId}/update_scenarios`
      const prms = { scenario: this.scenario.scenario }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then((res) => {
          if (res.data.scenario) {
            Object.assign(this.internalValue, res.data.scenario)
          } else {
            Object.assign(this.internalValue, { id: '', name: '' })
          }
          this.$toasted.success(this.$t('success.update'))
        })
        .catch(() => {
          this.$toasted.error(this.$t('danger.error_occurred'))
          this.setDataScenario()
        })
        .finally(() => {
          this.$wait.end('processing')
        })
    },
    setDataScenario() {
      this.scenario.scenario = this.$$clone(this.internalValue)
      this.scenario.scenario_id = this.internalValue.id
    },
  },
}
</script>
<style scoped>
.scenario_caution {
  font-size: 4px;
  color: #ff0000;
}
</style>
