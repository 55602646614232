<template>
  <v-dialog v-model="dialog" :max-width="500">
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" small v-on="on">
        {{ $t('btn.registor_url') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>アカウント登録用URL</v-card-title>
      <v-card-text class="pt-6">
        <v-text-field
          :label="$t('lbl.registor_url')"
          :value="registorUrl"
          readonly
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false">
          {{ $t('btn.close') }}
        </v-btn>
        <v-btn color="primary" @click="clipboardCopy">
          {{ $t('btn.clipboard_copy') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    registorUrl() {
      const baseUrl = `${window.location.protocol}//${window.location.host}`
      return `${baseUrl}/registration?plan_id=${this.item.id}`
    },
  },
  methods: {
    clipboardCopy() {
      navigator.clipboard
        .writeText(this.registorUrl)
        .then(() => {
          this.$toasted.success(this.$t('success.clipboard_copy'))
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
