<template>
  <div v-if="!hidden">
    <v-row>
      <v-col v-if="!hiddenTitle" sm="4" xs="12" cols="12">
        <span class="text-bold">{{ title }}</span>
        <v-chip
          v-if="required"
          class="ma-2"
          label
          color="red"
          text-color="white"
          small
        >
          {{ $t('lbl.answer_form.require') }}
        </v-chip>
      </v-col>
      <v-col :sm="!hiddenTitle ? 8 : 12" xs="12" cols="12">
        <validation-provider
          ref="provider"
          v-slot="{ errors }"
          :name="title"
          :rules="vees.rules"
        >
          <input :key="forceUpdate" type="file" @change="onFileChange" />
          <div v-if="errors.length" class="v-text-field__details mt-2">
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">{{ errors[0] }}</div>
              </div>
            </div>
          </div>
        </validation-provider>
        <div class="subtitle-3 theme--light v-messages">
          {{ getDescriptionUploadFile }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PtFormRenderAttachment',
  props: {
    type: { type: String, default: '' },
    uuid: { type: String, default: '' },
    value: { default: null },
    label: { type: String, default: '' },
    title: { type: String, default: '' },
    hint: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    required: { default: 0 },
    hidden: { default: 0 },
    hiddenTitle: { default: 0 },
  },
  data: function () {
    return {
      loading: false,
      forceUpdate: 1,
      upload_file_type: 'image',
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    getDescriptionUploadFile() {
      if (this.type === 'image')
        return this.$t('lbl.answer_form.upload_image_note')
      if (this.type === 'pdf') return this.$t('lbl.answer_form.upload_pdf_note')
      if (this.type === 'word')
        return this.$t('lbl.answer_form.upload_word_note')
      if (this.type === 'excel')
        return this.$t('lbl.answer_form.upload_excel_note')
    },
    getFileType() {
      if (this.type === 'image') {
        return 'image'
      }

      return 'document'
    },
    vees: function () {
      let rules = {
        rules: '',
      }

      if (this.required) {
        rules.rules += (rules.rules ? '|' : '') + 'required'
        rules.required = true
      }

      if (this.type === 'image') {
        rules.rules += (rules.rules ? '|' : '') + 'ext:jpeg,jpg,png,heic'
        rules.required = true
      }

      if (this.type === 'pdf') {
        rules.rules += (rules.rules ? '|' : '') + 'ext:pdf'
        rules.required = true
      }

      if (this.type === 'word') {
        rules.rules += (rules.rules ? '|' : '') + 'ext:doc,docx'
        rules.required = true
      }

      if (this.type === 'excel') {
        rules.rules += (rules.rules ? '|' : '') + 'ext:xls,xlsx'
        rules.required = true
      }

      return rules
    },
  },
  methods: {
    async onFileChange(e) {
      const { valid } = await this.$refs.provider.validate(e)
      let file = e.target.files[0]
      if (!file) return

      const formData = new FormData()
      formData.append('alt_uuid', this.uuid)
      formData.append('upload_file_type', this.getFileType)
      formData.append(this.getFileType, file, file.name)

      this.$emit('upload-file', true)
      this.axios
        .post('/api/v1/upload_files/upload_answer_attachment', formData)
        .then((res) => {
          this.$toasted.success(this.$t('lbl.answer_form.upload_file_success'))
          let result = {}
          if (this.getFileType === 'image') {
            result = {
              upload_file_type: this.getFileType,
              file: {
                preview: res.data.preview_image_url,
                original: res.data.image_url,
              },
            }
          } else {
            result = {
              upload_file_type: this.getFileType,
              file: {
                original: res.data.document_url,
              },
            }
          }

          this.$emit('input', result)
          this.$emit('upload-file', false)
        })
        .catch((error) => {
          this.$$ajaxError(error)
          this.$emit('upload-file', false)
          this.$toasted.error(this.$t('lbl.answer_form.upload_file_fail'))
          this.forceUpdate += 1
        })
        .finally(() => {})
    },

    validate() {},
  },
}
</script>
