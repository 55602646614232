<template>
  <v-row class="align-center">
    <v-col sm="12">
      <h5>
        <span class="text-bold">{{ title }}</span>
      </h5>
      <br />
      <VImg v-if="src" :src="src" />
      <p>{{ description }}</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PtFormRenderTextField',
  props: {
    title: { type: String, default: '' },
    src: { type: String, default: '' },
    showImg: { type: String, default: '' },
    description: { type: String, default: '' },
  },
}
</script>
