var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getDate(_vm.deli.created_at) != _vm.getDate(_vm.deli.prev_created_at))?_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"date"},[_vm._v(_vm._s(_vm.getDate(_vm.deli.created_at)))])]):_vm._e(),_vm._v(" "),_c('v-row',{class:_vm.direction,attrs:{"justify":_vm.justify}},[_c('div',[_c('p',{staticClass:"sent_time"},[_vm._v(_vm._s(_vm.getTime(_vm.deli.created_at)))]),_vm._v(" "),(_vm.messageType === 'receive')?_c('div',[(_vm.showableStatus)?_c('div',[_c('v-chip',{attrs:{"dark":"","label":"","color":_vm.colors[_vm.messageStatus]},on:{"click":function($event){return _vm.openDialogMessageStatus(_vm.messageId, _vm.messageStatus)}}},[_vm._v("\n            "+_vm._s(_vm.$t(("enum.friend.message_status." + _vm.messageStatus)))+"\n          ")])],1):_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":"","label":""}},[_vm._v("既読")])],1):_vm._e()]),_vm._v(" "),(_vm.deli.deli_type === 'text' && _vm.deli.deli_text.text)?_c('div',{staticClass:"speech-bubble",staticStyle:{"max-width":"300px","margin-bottom":"24px"}},[(_vm.messageType === 'send')?[_c('div',{class:[
            'sb-bubble',
            'sb-line2',
            'sb-right',
            'black--text',
            { 'is-broadcast': _vm.isBroadcast } ]},[_c('div',{staticClass:"or-break"},[_vm._v(_vm._s(_vm.deli.deli_text.text))])])]:(_vm.messageType === 'receive')?[_c('div',{class:[
            'sb-bubble',
            'sb-line2',
            'sb-left',
            'black--text',
            { 'is-broadcast': _vm.isBroadcast } ]},[_c('div',{staticClass:"or-break"},[_vm._v(_vm._s(_vm.deli.deli_text.text))])])]:[_c('v-card',{attrs:{"color":"grey lighten-3 mx-8"}},[_c('v-card-text',{staticClass:"text-center py-0"},[_c('div',{staticClass:"or-break"},[_vm._v(_vm._s(_vm.deli.deli_text.text))])])],1)]],2):_vm._e(),_vm._v(" "),(_vm.deli.deli_type === 'sticker' && _vm.deli.deli_sticker.sticker_id)?_c('div',[_c('img',{staticClass:"sticker-img",attrs:{"src":_vm.stickerImg(_vm.deli.deli_sticker.sticker_id)}})]):_vm._e(),_vm._v(" "),(
        _vm.deli.deli_type === 'image' &&
        _vm.deli.deli_image &&
        _vm.deli.deli_image.preview_url
      )?_c('div',[_c('PtImage',{attrs:{"deli-image":_vm.deli.deli_image}})],1):_vm._e(),_vm._v(" "),(
        _vm.deli.deli_type === 'video' &&
        _vm.deli.deli_video &&
        _vm.deli.deli_video.video_url
      )?_c('div',[_c('PtVideo',{key:_vm.deli.deli_video.video_url,attrs:{"src":_vm.deli.deli_video.video_url,"width":200,"height":200}})],1):_vm._e(),_vm._v(" "),(
        _vm.deli.deli_type === 'audio' &&
        _vm.deli.deli_audio &&
        _vm.deli.deli_audio.audio_url
      )?_c('div',[_c('PtVideo',{key:_vm.deli.deli_audio.audio_url,attrs:{"src":_vm.deli.deli_audio.audio_url,"width":200,"height":100}})],1):_vm._e(),_vm._v(" "),(_vm.deli.deli_type === 'question' && _vm.deli.deli_question.title)?_c('div',[_c('v-card',{attrs:{"width":_vm.maxWidth}},[_c('v-card-text',[_vm._v("\n          "+_vm._s(_vm.deli.deli_question.title)+"\n        ")]),_vm._v(" "),_c('v-row',{staticClass:"text-center",attrs:{"no-gutters":""}},_vm._l((_vm.deli.deli_question
              .deli_question_choices),function(choice,choice_idx){return _c('v-col',{key:choice_idx},[_c('v-card',{staticClass:"px-2 py-4 h-100"},[_vm._v("\n              "+_vm._s(choice.choice_name)+"\n            ")])],1)}),1)],1)],1):_vm._e(),_vm._v(" "),(
        _vm.deli.deli_type === 'carousel' &&
        _vm.deli.deli_carousel.deli_carousel_panels
      )?_c('div',{staticStyle:{"overflow":"scroll","padding":"30px"}},[_c('v-row',{staticClass:"pl-4 pr-4",staticStyle:{"flex-shrink":"0","width":"max-content"},attrs:{"justify":"start"}},_vm._l((_vm.deli.deli_carousel
            .deli_carousel_panels),function(panel,panel_idx){return _c('div',{key:panel_idx,staticClass:"ml-3"},[_c('v-card',{attrs:{"width":150}},[(panel.upload_file_id)?_c('VImg',{attrs:{"src":panel.image_url}}):_vm._e(),_vm._v(" "),_c('v-card-text',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(panel.title))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(panel.text))])]),_vm._v(" "),_c('v-row',{staticClass:"text-center",attrs:{"no-gutters":""}},_vm._l((panel.deli_carousel_panel_choices),function(choice,choice_idx){return _c('v-col',{key:choice_idx,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-2 py-4 h-100"},[_vm._v("\n                  "+_vm._s(choice.choice_name)+"\n                ")])],1)}),1)],1)],1)}),0)],1):_vm._e(),_vm._v(" "),(_vm.deli.deli_type === 'location' && _vm.deli.deli_location.title)?_c('div',[_c('v-card',{attrs:{"width":200}},[_c('GmapMap',{ref:"map",staticStyle:{"width":"200px","height":"200px"},attrs:{"center":{
            lat: Number(_vm.deli.deli_location.latitude),
            lng: Number(_vm.deli.deli_location.longitude),
          },"zoom":14,"options":{
            mapTypeControl: false,
            streetViewControl: false,
          },"map-type-id":"terrain"}},[_c('GmapMarker',{attrs:{"position":{
              lat: Number(_vm.deli.deli_location.latitude),
              lng: Number(_vm.deli.deli_location.longitude),
            },"draggable":false}})],1),_vm._v(" "),_c('v-card-text',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.deli.deli_location.title))]),_vm._v(" "),_c('div',{staticClass:"or-break"},[_vm._v(_vm._s(_vm.deli.deli_location.address))])])],1)],1):_vm._e(),_vm._v(" "),(_vm.deli.deli_type === 'introduction' && _vm.deli.deli_introduction.title)?_c('div',[_c('v-card',{attrs:{"width":_vm.maxWidth}},[_c('v-card-text',[_c('div',{staticClass:"font-weight-bold"},[_vm._v("\n            "+_vm._s(_vm.deli.deli_introduction.title)+"\n          ")])]),_vm._v(" "),_c('v-row',{staticClass:"text-center",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"px-2 py-4 h-100"},[_vm._v("\n              "+_vm._s(_vm.$t('btn.add_friend_message'))+"\n            ")])],1)],1)],1)],1):_vm._e(),_vm._v(" "),(
        _vm.deli.deli_type === 'file' && _vm.deli.deli_file && _vm.deli.deli_file.file_url
      )?_c('div',[_c('v-card',{attrs:{"width":300}},[_c('v-card-subtitle',{staticClass:"py-0"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"md":"2"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-file-pdf-box")])],1)],1),_vm._v(" "),_c('v-col',{attrs:{"md":"10"}},[_c('div',[_c('a',{attrs:{"href":_vm.deli.deli_file.file_url,"target":"_blank"}},[_vm._v(_vm._s(_vm.deli.deli_file.file_name))])])])],1)],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }