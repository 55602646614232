<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <VfTextField
          v-model="internalValue.title"
          :label="$$tm('deli_introduction.title')"
          :vees="vees.title"
          counter
        />
        <VfTextField
          v-model="internalValue.line_account_id"
          :label="$$tm('deli_introduction.line_account_id')"
          :vees="vees.line_account_id"
          counter
        />
        <div class="mt-4">
          <VfTextArea
            v-model="internalValue.message_for_pc"
            :label="$$tm('deli_introduction.message_for_pc')"
            :vees="vees.message_for_pc"
            counter
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
