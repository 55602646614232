<template>
  <div>
    <v-card>
      <v-card-title class="or-card-title mb-6">
        {{ $t('lbl.scenario.timing') }}
      </v-card-title>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        <v-row>
          <v-col md="8">
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              clearable
              single-line
              hide-details
            />
          </v-col>
          <v-col md="4" class="text-right">
            <v-btn color="primary" @click="openTestSendDialog('all')">
              {{ $t('btn.test_send_all') }}
            </v-btn>
            <v-btn color="primary" :disabled="fsceActiveFlg" @click="newItem">{{
              $t('btn.new_item')
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        item-key="id"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageOptions: [30, 50, 100],
        }"
      >
        <template #[`item.sch_timing`]="{ item }">
          <a href="#" @click="editItem(item)">
            {{ timingDisplay(item) }}
          </a>
        </template>
        <template #[`item.action_group`]="{ item }">
          <PtActionLabelMessages
            v-model="item.action_group.make_action_arr"
            class="or-list"
          />
        </template>
        <template #[`item.created_at`]="{ item }">
          {{ $$fdt(item.created_at) }}
        </template>
        <template #[`item.preview`]="{ item }">
          <v-row align="center">
            <v-col cols="3">
              <v-btn depressed x-small @click="openTestSendDialog(item)">
                {{ $t('btn.test_send') }}
              </v-btn>
            </v-col>
            <v-col
              v-for="(broadcast, broadcast_idx) in item.action_group.broadcasts"
              :key="broadcast_idx"
              cols="3"
            >
              <v-btn class="my-1" depressed x-small @click="showItem(broadcast)"
                >プレビュー</v-btn
              >
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="testSendDialog" max-width="800px">
      <v-card>
        <VfLoadingOverlayAbsolute :loading="$wait.is('processing')" />
        <v-card-title>
          {{ $t('btn.test_send') }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <template v-if="testFriends.length == 0">
            <v-alert color="red lighten-2" dark>
              {{ $t('lbl.scenario_timing.no_tester') }}
            </v-alert>
          </template>
          <template v-else>
            <v-container fluid>
              <v-row>
                <v-col
                  v-for="(friend, friend_idx) in testFriends"
                  :key="friend_idx"
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <VfCheckbox
                    v-model="friend.checked"
                    :label="friend.friend.api_display_name"
                    :name="friend.friend.api_display_name"
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :disabled="noTestFriendsAreChecked()"
                :loading="$wait.is('processing')"
                @click="sendTest"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-card-actions>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
    <PtDialogLinePreview
      v-if="selectedItem"
      :key="selectedItem.id"
      v-model="previewDialog"
      type="broadcast"
      :model-id="selectedItem.id"
    />
  </div>
</template>
<script>
import PtDialogLinePreview from 'components/parts/common/PtDialogLinePreview'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  components: {
    PtDialogLinePreview,
    PtActionLabelMessages,
  },
  props: {
    scenarioId: { type: String, required: true },
    fsceActiveFlg: { type: Boolean, required: true },
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    search: '',
    savePath: '',
    deleteDialog: false,
    previewDialog: false,
    testFriends: [],
    selectedScenarioTiming: null,
    testSendDialog: false,
    selectedItem: { id: '' },
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/scenario_timings'
      const prms = { scenario_id: this.scenarioId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.scenario_timings
          this.testFriends = res.data.test_friends
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    newItem() {
      this.$router.push({
        name: 'ScenarioTimingForm',
        params: { id: 'new' },
        query: { scenario_id: this.scenarioId },
      })
    },
    editItem(item) {
      this.$router.push({
        name: 'ScenarioTimingForm',
        params: { id: item.id },
        query: { scenario_id: this.scenarioId },
      })
    },
    deleteItem(item) {
      this.savePath = `/api/v1/scenario_timings/${item.id}?scenario_id=${this.scenarioId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.init()
    },
    openTestSendDialog(item) {
      this.testSendDialog = true
      this.selectedScenarioTiming = item
    },
    async sendTest() {
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      const path =
        this.selectedScenarioTiming == 'all'
          ? `/api/v1/scenarios/${this.scenarioId}/test_send`
          : `/api/v1/scenario_timings/${this.selectedScenarioTiming.id}/test_send`
      const prms = {
        test_friends: this.testFriends,
        scenario_id: this.scenarioId,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.send'))
          this.testSendDialog = false
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
        })
    },
    showItem(item) {
      this.selectedItem = item
      this.previewDialog = true
    },
    noTestFriendsAreChecked() {
      return this.testFriends.every(function (friend) {
        return !friend.checked
      })
    },
    timingDisplay(scenarioTiming) {
      let disp = ''
      switch (scenarioTiming.scenario_timing_type) {
        case 'start':
          disp = this.$$te(
            'scenario_timing.scenario_timing_type',
            scenarioTiming
          )
          break
        case 'end':
          disp = this.$$te(
            'scenario_timing.scenario_timing_type',
            scenarioTiming
          )
          disp += ' '
          disp += scenarioTiming.sch_timing
          break
        default:
          disp = scenarioTiming.sch_timing
      }
      return disp
    },
  },
}
</script>
<style scoped>
.or-list {
  max-width: 300px;
  font-size: 0.5em;
}
</style>
