<template>
  <div>
    <v-row>
      <v-col md="6" class="py-0">
        <PtDialogSelect
          v-model="internalValue"
          select-type="inflow_route"
          :vees="vees.name"
        >
          <template #[`item.action_group`]="{ item }">
            <PtActionLabelMessages
              v-model="item.action_group.make_action_arr"
              class="or-list"
            />
          </template>
        </PtDialogSelect>
      </v-col>
      <v-col md="6">
        <VfSelect
          v-model="internalValue.search_inflow_route_type"
          :label="$$tm('search_inflow_route.search_inflow_route_type')"
          :selects="opts.search_inflow_route_type"
          :vees="vees.search_inflow_route_type"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  components: {
    PtDialogSelect,
    PtActionLabelMessages,
  },
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    opts: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    loading: false,
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
