<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn v-if="richMenuId !== 'new'" color="error" @click="destroy">
            {{ $t('btn.delete') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-form @submit.prevent="save">
        <v-card class="mb-6">
          <v-card-title class="or-card-title mb-6">
            {{ $t('lbl.rich_menu.info') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6">
                <v-btn @click="openDialog">
                  {{ $t('btn.select_image') }}
                </v-btn>
                <template v-if="previewImage.preview_image_url">
                  <v-card class="mt-2">
                    <VImg :src="PREVIEW_HEADER" />
                  </v-card>
                  <v-card outlined tile>
                    <div class="base-image">
                      <VImg :src="previewImage.preview_image_url" />
                      <div class="area-image">
                        <VImg :src="selectFrameUrl" />
                      </div>
                    </div>
                  </v-card>
                  <v-card>
                    <div class="base-footer">
                      <VImg :src="PREVIEW_FOOTER" />
                      <div class="footer-text">
                        {{ richMenu.chat_bar_text }}
                      </div>
                    </div>
                  </v-card>
                </template>
              </v-col>
              <v-col md="6">
                <VfSelect
                  v-model="richMenu.folder_id"
                  :label="$$tm('folder.name')"
                  :vees="vees.folder_id"
                  :selects="folders"
                />
                <VfTextField
                  v-model="richMenu.name"
                  :label="$$tm('rich_menu.name')"
                  :vees="vees.name"
                />
                <VfTextField
                  v-model="richMenu.chat_bar_text"
                  :label="$$tm('rich_menu.chat_bar_text')"
                  :vees="vees.chat_bar_text"
                />
                <VfSwitch
                  v-model="richMenu.menu_show_flg"
                  :label="$$tm('rich_menu.menu_show_flg')"
                  :vees="vees.menu_show_flg"
                />
                <VfSwitch
                  v-model="richMenu.active_flg"
                  :label="$$tm('auto_answer.active_flg')"
                  :vees="vees.active_flg"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <PtRichMenuTemplate
          v-if="previewImage.preview_image_url"
          v-model="richMenu"
          :vees="vees"
          :opts="opts"
          :area-templates="templatePattern"
          select-emit="select-template"
          @select-template="selectTemplate"
        />
        <PtRichMenuContentIndex
          v-if="previewImage.preview_image_url"
          v-model="richMenu"
          :template-type="opts.template_type"
          :action-types="opts.action_type"
          :other-open-types="opts.other_open_type"
          :vees="vees"
        />
        <v-row>
          <v-col class="text-right">
            <v-btn
              v-if="inputFinished"
              type="submit"
              color="primary"
              :loading="$wait.is('processing')"
              >{{ $t('btn.create') }}
            </v-btn>
          </v-col>
        </v-row>
        <PtDeliDialogForSelectFile
          v-model="dialog"
          upload-file-type="rich_menu_image"
          @select-file-done="selectFileDone"
        />
      </v-form>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtDeliDialogForSelectFile from 'components/parts/common/PtDeli/PtDeliDialogForSelectFile'
import PtRichMenuTemplate from 'components/parts/unique/PtRichMenuTemplate'
import PtRichMenuContentIndex from 'components/parts/unique/PtRichMenuContentIndex'
export default {
  components: {
    PtDeliDialogForSelectFile,
    PtRichMenuTemplate,
    PtRichMenuContentIndex,
  },
  data: () => ({
    initDone: false,
    richMenuId: '',
    richMenu: {},
    vees: {},
    folders: [],
    opts: {},
    savePath: '',
    deleteDialog: false,
    dialog: '',
    previewImage: {},
    templatePattern: [],
    updateContents: {},
    selectFrameUrl: '',
  }),
  computed: {
    inputFinished() {
      return (
        this.previewImage.preview_image_url &&
        this.richMenu.rich_menu_contents.length !== 0
      )
    },
    PREVIEW_HEADER: () => '/rich_menu_template/preview_top.png',
    PREVIEW_FOOTER: () => '/rich_menu_template/preview_footer.png',
    IMAGE_BRANCH_POINT: () => 843,
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.dialog = false
      this.richMenuId = this.$route.params.id
      const path =
        this.richMenuId === 'new'
          ? '/api/v1/rich_menus/new'
          : `/api/v1/rich_menus/${this.richMenuId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.richMenu = res.data.rich_menu
          this.vees = res.data.vees
          this.folders = res.data.folders
          this.opts = res.data.opts
          this.previewImage = this.opts.upload_file
          this.templatePattern = this.opts.template_type
          this.updateContents = Object.assign(
            [],
            this.richMenu.rich_menu_contents
          )
          if (this.richMenuId !== 'new') {
            this.selectTemplate()
          }
          this.initTemplatePattern()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      if (this.richMenuId !== 'new') {
        this.updateContents.forEach((oldCont) => {
          let matchedNewCont = this.richMenu.rich_menu_contents.find(
            (newCont) => newCont.sort_no == oldCont.sort_no
          )
          matchedNewCont != null
            ? Object.assign(oldCont, matchedNewCont)
            : delete oldCont.id
        })
        Object.assign(this.richMenu.rich_menu_contents, this.updateContents)
      }
      this.$$loadingOverlayContent(true)
      const path =
        this.richMenuId === 'new'
          ? '/api/v1/rich_menus'
          : `/api/v1/rich_menus/${this.richMenuId}`
      const method = this.richMenuId === 'new' ? 'post' : 'put'
      const prms = { rich_menu: this.richMenu }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then((res) => {
          this.$toasted.success(this.$t('success.create'))
          this.$router
            .push({
              name: 'RichMenuIndex',
              params: { id: res.data.rich_menu.id },
            })
            .catch((err) => {})
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    destroy() {
      this.savePath = `/api/v1/rich_menus/${this.richMenuId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'RichMenuIndex' })
    },
    openDialog() {
      this.dialog = true
    },
    selectFileDone(uploadFile) {
      this.previewImage = uploadFile
      this.richMenu.upload_file_id = uploadFile.id
      this.selectFrameUrl = ''
      this.richMenu.rich_menu_contents = []
      this.initTemplatePattern()
      this.dialog = false

      const imageSize = { width: 2500, height: 1686, half_height: 843 }
      const is_correct_width = this.previewImage.width === imageSize.width
      const is_correct_height =
        this.previewImage.height === imageSize.height ||
        this.previewImage.height === imageSize.half_height
      if (!(is_correct_width && is_correct_height)) {
        const error = this.$t('lbl.rich_menu_image_size_error', imageSize)
        this.$$ajaxError(error)
      }
    },
    selectTemplate() {
      const zero_padding_num = (
        '000' + this.richMenu.template_type.substr(-2, 2)
      ).slice(-3)
      this.selectFrameUrl = `/rich_menu_area/rich_menu_frame_${zero_padding_num}.png`
    },
    initTemplatePattern() {
      if (this.previewImage.height > this.IMAGE_BRANCH_POINT) {
        /* templatePattern mapping
           0 ~  9 :   Big-1-1 ~ Big-1-9 (header none)
           9 ~ 13 :   Small-1-1 ~ Small-1-4
          13 ~ 22 :   Big-2-1 ~ Big-2-9 (with header)
          22 ~ 31 :   Big-3-1 ~ Big-3-9 (with 2split header)
          31 ~ 40 :   Big-4-1 ~ Big-4-9 (with 3split header)
          40 ~ 47 :   230624_new (header none)
          47 ~ 54 :   230624_new (with 2split header)
          54 ~ 61 :   230624_new (with 3split header)
          61 ~ 77 :   230624_new (with 4split header)
        */
        this.templatePattern = [
          this.opts.template_type
            .slice(0, 9)
            .concat(this.opts.template_type.slice(40, 47)),
          this.opts.template_type.slice(13, 22),
          this.opts.template_type
            .slice(22, 31)
            .concat(this.opts.template_type.slice(47, 54)),
          this.opts.template_type
            .slice(31, 40)
            .concat(this.opts.template_type.slice(54, 61)),
          this.opts.template_type.slice(61, 77),
        ]
      } else {
        this.templatePattern = [this.opts.template_type.slice(9, 13)]
      }
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.base-image {
  position: relative;
}
.area-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
.base-footer {
  position: relative;
}
.footer-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
}
</style>
