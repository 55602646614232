<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle />
      </v-col>
      <v-col cols="4" align="right" class="pt-8">
        <v-btn
          color="primary"
          @click="$router.push({ name: 'AdminUsersRegistration' })"
        >
          {{ $t('btn.admin_created') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-row class="m-2">
        <v-col cols="6">
          <v-text-field
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            clearable
            single-line
            hide-details
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="params.name_null"
            :items="opts.name_null"
            item-text="label"
            item-value="value"
            @change="init"
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="params.payment_status"
            :items="opts.payment_statuses"
            item-text="label"
            item-value="value"
            @change="init"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        item-key="id"
        :disable-sort="true"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150],
        }"
        @click:row="rowClick"
      >
        <template #[`item.admin_created_flg`]="{ item }">
          {{ $t(`lbl.account.admin_created_flg.${item.admin_created_flg}`) }}
          <v-btn
            v-if="item.access_locked"
            color="error"
            @click.stop="unlock(item.id)"
            class="btn-unlock"
          >
            {{ $t('btn.account.unlock') }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="unlockDialog" max-width="500">
      <v-card>
        <v-card-title>{{
          $t('lbl.account.unlock_confirm_title')
        }}</v-card-title>
        <v-divider class="mt-1 mb-0" />
        <v-card-text class="pt-6">
          <div class="or-break">
            {{ $t('lbl.account.unlock_confirm_desc') }}
          </div>
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn color="primary" @click="decide">{{ $t('btn.yes') }}</v-btn>
          <v-btn @click="unlockDialog = false">{{ $t('btn.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    headers: [],
    opts: {
      name_null: [],
      payment_statuses: [],
    },
    params: {
      name_null: false,
      payment_status: 'all',
    },
    items: [],
    search: '',
    unlockDialog: false,
    userId: '',
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.opts.name_null = [
        { label: this.$t('lbl.disp'), value: true },
        { label: this.$t('lbl.hide'), value: false },
      ]
      this.opts.payment_statuses = [
        { label: this.$t('admin.payment_statuses.all'), value: 'all' },
        { label: this.$t('admin.payment_statuses.paused'), value: 'paused' },
      ]
      this.loading = true
      const path = '/api/v1/admin/users'
      await this.axios
        .get(path, { params: this.$$reqPrms(this.params) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.users
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    rowClick(item) {
      this.$router.push({ name: 'AdminUsersShow', params: { id: item.id } })
    },
    unlock(userId) {
      this.userId = userId
      this.unlockDialog = true
    },
    async decide() {
      this.$store.dispatch('loadingOverlay', true)
      const path = `/api/v1/admin/users/${this.userId}/unlock`
      const method = 'put'
      await this.$$sendForm(method, path, this.$$reqPrms())
        .then(() => {
          this.unlockDialog = false
          this.$toasted.success(this.$t('lbl.account.success.unlock'))
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$store.dispatch('loadingOverlay', false)
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>

<style scoped lang="scss">
.btn-unlock {
  float: right;
  right: 20%;
}
</style>
