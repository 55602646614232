<div>
  <chart
    :chartdata="chartdata"
    :options="options"
    :height="200"/>
</div>
<script>
import { Bar } from 'vue-chartjs'
export default {
  name: 'Chart',
  extends: Bar,
  props: {
    items: { default: '' },
  },
  data: () => ({
    // chartdata: {},
    chartdata: {
      labels: [],
      datasets: [
        {
          label: '実数',
          data: [],
          borderColor: '#0000FF',
          fill: false,
          type: 'line',
          lineTension: 0.3,
          yAxisID: 'y-axis-2',
        },
        {
          label: '増減',
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
          yAxisID: 'y-axis-1',
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: '日付',
            },
          },
        ],
        yAxes: [
          {
            id: 'y-axis-1',
            type: 'linear',
            position: 'right',
            scaleLabel: {
              display: true,
              labelString: '前日比',
            },
            ticks: {
              beginAtZero: true,
              stepSize: 10,
            },
          },
          {
            id: 'y-axis-2',
            type: 'linear',
            position: 'left',
            scaleLabel: {
              display: true,
              labelString: '有効友だち数',
            },
            ticks: {
              beginAtZero: true,
              stepSize: 10,
            },
          },
        ],
      },
    },
  }),
  watch: {
    items: function () {
      this.update()
    },
  },
  methods: {
    update() {
      this.chartdata.labels = []
      this.chartdata.datasets[0].data = []
      this.chartdata.datasets[1].data = []
      this.chartdata.datasets[1].backgroundColor = []
      this.chartdata.datasets[1].borderColor = []
      for (var item of this.items) {
        this.fdt(item.report_dt)
        this.chartdata.labels.unshift(this.fdt(item.report_dt))
        this.chartdata.datasets[0].data.unshift(item.active_total_count)
        this.chartdata.datasets[1].data.unshift(item.plus_minus)
        this.chartdata.datasets[1].backgroundColor.unshift(
          'rgba(70, 160, 70, 1)'
        )
        this.chartdata.datasets[1].borderColor.unshift('rgba(70, 160, 70, 1)')
      }
      this.renderChart(this.chartdata, this.options)
    },
    fdt(d) {
      var date = Date.parse(d)
      var a = new Date(date)
      var formatted = `${a.getFullYear()}/${(a.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${a.getDate().toString().padStart(2, '0')}`.replace(
        /\n|\r/g,
        ''
      )
      return formatted
    },
  },
}
</script>
