<template>
  <v-row>
    <v-col v-if="!hiddenTitle" sm="4" cols="12" xs="12">
      <span class="text-bold">{{ title }}</span>
      <v-chip
        v-if="required"
        class="ma-2"
        label
        color="red"
        text-color="white"
        small
      >
        {{ $t('lbl.answer_form.require') }}
      </v-chip>
    </v-col>
    <v-col sm="8" cols="12" xs="12">
      <validation-provider
        v-slot="{ errors }"
        :name="title"
        :rules="localVees.rules"
      >
        <v-select
          v-model="localValue"
          class="mt-0 pt-0"
          v-bind="baseProps"
          :error-messages="errors"
          :items="items"
          :placeholder="placeholder"
          menu-props="auto"
          required
          @change="$emit('change', $event)"
        >
          <template #append-outer>
            <slot name="append-outer" />
          </template>
        </v-select>
      </validation-provider>
      <template v-for="(option, option_i) in inputOptions">
        <VfTextField
          v-if="
            localValue === option.id &&
            option.id !== null &&
            option.is_other_flg
          "
          :key="option_i"
          v-model="customOptions[option.id]"
          class="vf-hidden-label pt-0"
          :label="title"
          :placeholder="''"
          :hint="''"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import mixin from 'components/vuetifyParts/commonExport'
export default {
  name: 'PtFormRenderSelect',
  mixins: [mixin],
  props: {
    value: { default: null },
    label: { type: String, default: '' },
    title: { type: String, default: '' },
    hiddenTitle: { default: 0 },
    hint: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    inputOptions: { type: Array, default: () => [] },
    optionCount: { type: Object, default: () => ({}) },
    friend: { type: Object, default: () => ({}) },
    row: { type: Boolean, default: true },
    column: { type: Boolean, default: false },
    required: { default: 0 },
  },
  data: function () {
    return {
      errorMsg: '',
      data: {},
      checkedOptions: [],
      localValue: '',
      customOptions: {},
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    localData: function () {
      if (this.localValue === null) {
        return [[]]
      } else {
        const input = this.inputOptions.find((option) => {
          return option.id === this.localValue
        })

        if (input && input.is_other_flg) {
          return [[this.localValue, this.customOptions[this.localValue]]]
        } else {
          return [this.localValue]
        }
      }
    },
    items: function () {
      let list = [
        { text: this.$t('lbl.answer_form.please_select'), value: null },
      ]
      this.inputOptions.forEach((option) => {
        list.push({
          text: this.isDisabled(option)
            ? option.capacity_warning ||
              this.$t('lbl.answer_form.can_not_select') + option.value
            : option.value,
          value: option.id,
          disabled: this.isDisabled(option),
        })
      })

      return list
    },
    localVees: function () {
      let rules = {
        rules: '',
      }

      if (this.maxLength) {
        rules.rules += (rules.rules ? '|' : '') + `max:${this.maxLength}`
        rules.max = this.maxLength
      }

      if (this.required) {
        rules.rules += (rules.rules ? '|' : '') + 'required'
        rules.required = true
      }

      return rules
    },
  },
  watch: {
    localValue: function () {
      this.$emit('input', this.localData)
    },
    customOptions: {
      handler() {
        this.$emit('input', this.localData)
      },
      deep: true,
    },
  },
  created() {
    this.initLocalValue()
  },
  methods: {
    initLocalValue() {
      if (
        !this.value ||
        (Array.isArray(this.value) && this.value.length === 0)
      ) {
        this.inputOptions.forEach((option) => {
          if (this.localValue) return
          if (option.default_type === 'selected' && !this.isDisabled(option)) {
            this.localValue = option.id
          } else if (
            option.default_type === 'only_with_tag' &&
            !this.isDisabled(option) &&
            this.friend &&
            this.friendHasDefaultTag(this.friend.hashtags, option.default_tag)
          ) {
            this.localValue = option.id
          } else if (
            option.default_type === 'only_without_tag' &&
            !this.isDisabled(option) &&
            this.friend &&
            !this.friendHasDefaultTag(this.friend.hashtags, option.default_tag)
          ) {
            this.localValue = option.id
          }

          this.$set(this.customOptions, option.id, '')
        })
      } else {
        let clone = this.$$clone(this.value)
        this.inputOptions.forEach((option) => {
          //vue Reactivity in Depth
          this.$set(this.customOptions, option.id, '')
        })
        if (typeof this.value[0] === 'object') {
          this.localValue = clone[0][0]
          this.$set(this.customOptions, clone[0][0], clone[0][1])
        } else if (typeof this.value[0] === 'string') {
          this.localValue = clone[0]
        } else {
          this.localValue = ''
        }
      }
    },
    checkCapacity(optionId) {
      if (
        this.optionCount?.[optionId] &&
        this.optionCount[optionId].capacity !== null
      ) {
        return (
          this.optionCount[optionId].select_count >=
          this.optionCount[optionId].capacity
        )
      } else {
        return false
      }
    },
    isDisabled(option) {
      return this.checkCapacity(option.id) && option.limit_type === 'use'
    },
    friendHasDefaultTag(friendTags, defaultTag) {
      if (Array.isArray(friendTags)) {
        return friendTags.some((tag) => {
          return tag.id === defaultTag?.id
        })
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.vf-hidden-label ::v-deep {
  .v-input {
    padding-top: 0;
  }

  fieldset > legend {
    display: none;
  }

  .v-label {
    display: none;
  }
}
</style>
