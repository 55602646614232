<template>
  <div>
    <v-row>
      <v-col md="6" class="py-0">
        <PtDialogSelect
          v-model="internalValue"
          select-type="reminder"
          :single-select="true"
          :vees="vees.name"
        />
      </v-col>
      <v-col md="6" class="py-0">
        <VfSelect
          v-model="internalValue.search_reminder_type"
          :label="$$tm('search_reminder.search_reminder_type')"
          :vees="vees.search_reminder_type"
          :selects="opts.search_reminder_type"
        />
      </v-col>
      <v-col md="6" class="py-0">
        <VfSelect
          v-model="internalValue.goal_type"
          :label="$$tm('search_reminder.goal_type')"
          :vees="vees.goal_type"
          :selects="opts.goal_type"
        />
      </v-col>
    </v-row>
    <v-row v-if="internalValue.goal_type === 'range'">
      <v-col class="py-0" md="4">
        <VfDateTimeSelect
          v-model="internalValue.goal_range_st_dt"
          :label="$$tm('search_reminder.goal_range_st_dt')"
          :vees="vees.goal_range_st_dt"
          format="YYYY/MM/DD"
        />
      </v-col>
      <v-col class="py-0" md="4">
        <div class="mt-6 text-center">〜</div>
      </v-col>
      <v-col class="py-0" md="4">
        <VfDateTimeSelect
          v-model="internalValue.goal_range_ed_dt"
          :label="$$tm('search_reminder.goal_range_ed_dt')"
          :vees="vees.goal_range_ed_dt"
          format="YYYY/MM/DD"
        />
      </v-col>
    </v-row>
    <v-row v-if="internalValue.goal_type === 'specify'">
      <v-col>
        <VfDateTimeSelect
          v-model="internalValue.goal_at"
          :label="$$tm('search_reminder.goal_at')"
          :vees="vees.goal_at"
          format="YYYY/MM/DD HH:mm"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtDialogSelect,
  },
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    opts: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    loading: false,
    errorMsg: '',
    datePick: '',
    timePick: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    datePick(val) {
      const dt = !val ? '' : this.$$fdt(val)
      this.internalValue.goal_at = `${dt} ${this.timePick}`
    },
    timePick(val) {
      const dt = !this.datePick ? '' : this.$$fdt(this.datePick)
      this.internalValue.goal_at = `${dt} ${val}`
    },
  },
  created() {
    const now = new Date()
    let date = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    this.internalValue.goal_at = this.internalValue.goal_at || date
    this.internalValue.goal_range_st_dt =
      this.internalValue.goal_range_st_dt || date
    this.internalValue.goal_range_ed_dt =
      this.internalValue.goal_range_ed_dt || date
    this.init()
  },
}
</script>
<style scoped>
.time-pick {
  width: 200px;
}
</style>
