<template>
  <div>
    <template v-if="dispType === 'selectable'">
      <div class="mb-4">
        <v-btn block @click="openDialog">{{
          $t('lbl.search_wrap.edit')
        }}</v-btn>
        <v-btn block @click="clearConfirm">{{
          $t('lbl.search_wrap.clear')
        }}</v-btn>
      </div>
      <div v-for="(searchGroup, index) in searchWrapMsgs">
        <div v-if="index > 0" class="ma-6">{{ $t('lbl.search_wrap.or') }}</div>
        <v-card v-if="searchGroup.length">
          <v-data-table
            :headers="searchHeaders"
            :items="searchGroup"
            class="search_table"
            disable-sort
            hide-default-footer
          />
        </v-card>
      </div>
      <v-dialog v-model="dialog">
        <v-card>
          <v-card-title class="green--text text--darken-1">
            {{ $t('lbl.search_wrap.setting') }}
          </v-card-title>
          <v-container>
            <validation-observer ref="childObserver">
              <PtSearchWrapOnlyScenario v-model="clone" />
            </validation-observer>
          </v-container>
          <VDivider />
          <v-card-actions>
            <VSpacer />
            <v-btn color="error" @click="dialog = false">{{
              $t('btn.cancel')
            }}</v-btn>
            <v-btn color="primary" @click="decide">{{
              $t('btn.decide')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="clearDialog" max-width="300">
        <v-card>
          <v-card-title>{{
            $t('lbl.search_wrap.clear_confirm_title')
          }}</v-card-title>
          <v-card-text>
            <div class="or-break">
              {{ $t('lbl.search_wrap.clear_confirm_desc') }}
            </div>
          </v-card-text>
          <v-card-actions>
            <VSpacer />
            <v-btn @click="clearDialog = false">{{ $t('btn.cancel') }}</v-btn>
            <v-btn color="error" @click="clearSearch">{{
              $t('lbl.search_wrap.clear')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="templateDialog" max-width="1200">
        <v-card>
          <VfLoadingOverlayAbsolute :loading="loading" />
          <v-card-title>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <VfSelect
                      v-model="selectedId"
                      :label="$$tm('search_template.name')"
                      :selects="selects"
                      readonly
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="4">
                <PtFolder
                  ref="folder"
                  folder-type="search_template"
                  select-only
                  @select-folder="selectFolder"
                />
              </v-col>
              <v-col md="8">
                <v-data-table
                  v-model="activeTemplateClone"
                  :headers="headers"
                  :items="items"
                  item-key="id"
                  show-select
                  :single-select="true"
                  :disable-sort="true"
                  :footer-props="{
                    itemsPerPageOptions: [10, 30, 50, 100],
                  }"
                  @click:row="rowClick"
                >
                  <template #[`item.search_wrap`]="{ item }">
                    <PtSearchSelectedList
                      v-model="item.search_wrap.as_json_form"
                      class="or-list"
                      disp-type="list"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <VSpacer />
            <v-btn color="error" @click="templateDialog = false">{{
              $t('btn.cancel')
            }}</v-btn>
            <v-btn color="primary" @click="decideTemplate">{{
              $t('btn.decide')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-else>
      <div v-for="(searchGroup, index) in searchWrapMsgs">
        <div v-if="index > 0">{{ $t('lbl.search_wrap.or') }}</div>
        <div v-for="msg in searchGroup" :key="msg.no">{{ msg.operate }}</div>
        <div v-if="searchGroup.length === 0">none</div>
      </div>
    </template>
  </div>
</template>
<script>
import PtSearchWrapOnlyScenario from 'components/parts/common/PtSearchWrap/PtSearchWrapOnlyScenario'
import PtFolder from 'components/parts/common/PtFolder'
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
export default {
  name: 'PtSearchSelectedList',
  components: {
    PtSearchWrapOnlyScenario,
    PtFolder,
    PtSearchSelectedList,
  },
  props: {
    value: { default: null },
    dispType: { type: String, default: 'selectable' },
  },
  data: () => ({
    firstInit: false,
    dialog: false,
    clearDialog: false,
    templateDialog: false,
    loading: false,
    opts: {},
    initData: {},
    allVees: {},
    clone: [],
    headers: [],
    items: [],
    searchHeaders: [
      { text: 'No.', value: 'no' },
      { text: 'タイプ', value: 'type' },
      { text: '検索条件', value: 'operate' },
    ],
    activeTemplateClone: [],
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    searchWrapMsgs() {
      if (!this.internalValue) {
        return [[]]
      }
      return this.internalValue.search_arr.map((group) => {
        return group.map((search, index) => {
          const yml = `lbl.search_wrap.search_types_short_msg.${search.type}`
          const ret = {}
          ret.no = index + 1
          switch (search.type) {
            case 'scenario':
              ret.type = this.$$te(
                'search_scenario.scenario_read_type',
                search.data
              )
              if (search.data.scenario_read_type === 'stop') {
                break
              }
              ret.operate = this.$t('lbl.search_wrap.scenario_is', {
                scenario: search.data.scenario.name,
                type: this.$$te(
                  'search_scenario.search_scenario_type',
                  search.data
                ),
              })
              break
          }
          return ret
        })
      })
    },
    selectedId() {
      if (this.activeTemplateClone.length === 0) {
        return ''
      }
      return this.activeTemplateClone[0].id
    },
    selects() {
      if (this.activeTemplateClone.length === 0) {
        return {}
      }
      return {
        [this.activeTemplateClone[0].name]: this.activeTemplateClone[0].id,
      }
    },
  },
  methods: {
    openDialog() {
      this.dialog = true
      this.clone = this.$$clone(this.internalValue)
    },
    openTemplateDialog() {
      this.templateDialog = true
      this.initTable(null)
    },
    async initTable() {
      this.loading = true
      const path = '/api/v1/search_templates'
      const prms = { folder_id: this.folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.search_templates
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async decide() {
      const isValid = await this.$refs.childObserver.validate()
      if (isValid) {
        this.internalValue = this.clone
        this.$emit('pt-search-selected-list-save')
        this.dialog = false
      } else {
        this.$toasted.error(this.$t('danger.params'))
      }
    },
    async decideTemplate() {
      this.internalValue = this.activeTemplateClone[0].search_wrap.as_json_form
      this.$emit('pt-search-selected-list-save')
      this.templateDialog = false
    },
    clearConfirm() {
      this.clearDialog = true
    },
    clearSearch() {
      this.internalValue.search_arr = [[]]
      this.$emit('pt-search-selected-list-clear')
      this.clearDialog = false
    },
    selectFolder(folderId) {
      this.initTable(folderId)
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
  },
}
</script>
