<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle />
      </v-col>
      <v-col cols="4" align="right" class="pt-8">
        <v-btn class="mr-2" :to="{ name: 'StaffIndex' }">
          {{ $t('btn.staff_index') }}
        </v-btn>
        <v-btn color="primary" @click="toNewForm">
          {{ $t('btn.new') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :disable-sort="true"
          :items-per-page="-1"
          hide-default-footer
        >
          <template #[`item.name`]="{ item }">
            <a href="#" @click="toEditForm(item)">{{ item.name }}</a>
          </template>
          <template #[`item.channel_role_choices`]="{ item }">
            <v-chip
              v-for="channel_role_choice in item.channel_role_choices"
              class="ma-1"
              small
            >
              {{
                $$te(
                  'channel_role_choice.role_choice_type',
                  channel_role_choice
                )
              }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    headers: [],
    items: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/channel_roles'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.channel_roles
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
      this.loading = false
    },
    toNewForm() {
      this.$router.push({ name: 'ChannelRoleForm', params: { id: 'new' } })
    },
    toEditForm(item) {
      this.$router.push({ name: 'ChannelRoleForm', params: { id: item.id } })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
