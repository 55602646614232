<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-row>
      <v-col md="6" class="pb-0">
        <VfTextField
          v-model="internalValue.title"
          :label="$t('lbl.answer_form.item_name')"
          :vees="vees.answer_form_input.title"
        />
      </v-col>
      <v-col md="6" class="pb-0">
        <VfCheckbox
          v-model="internalValue.hide_title_flg"
          :label="$t('lbl.answer_form.hide_item_name')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="pb-4">
        <VfTextField
          v-model="internalValue.description"
          :label="$t('lbl.answer_form.description')"
          :vees="vees.answer_form_input.short_description"
          :hint="$t('lbl.answer_form.text_field_desc_hint')"
        />
      </v-col>
      <v-col md="6" class="pb-4">
        <VfTextField
          v-model="internalValue.placeholder"
          :label="$t('lbl.answer_form.placeholder')"
          :vees="vees.answer_form_input.placeholder"
          :placeholder="$t('lbl.answer_form.placeholder_hint')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="pb-0">
        <VfSelect
          v-model="internalValue.additional_option_type"
          :label="$t('lbl.answer_form.initial_display')"
          :selects="opts.answer_form_input.additional_option_types.text_field"
        />
      </v-col>
      <v-col md="6" class="pb-0">
        <VfTextField
          v-if="internalValue.additional_option_type === 'prepopulate'"
          v-model="internalValue.prepopulate_value"
          :label="''"
          :vees="vees.answer_form_input.prepopulate_value"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2" class="pb-0 offset-8">
        <VfCheckbox
          v-model="internalValue.require_flg"
          :label="$t('lbl.answer_form.require')"
        />
      </v-col>
      <v-col md="2" class="pb-0">
        <VfCheckbox
          v-model="internalValue.hidden_flg"
          :label="$t('lbl.answer_form.hide')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PtFormSetupTextArea',
  props: {
    value: { type: Object, required: true },
    vees: { type: Object, default: () => ({}) },
    opts: { type: Object, default: () => ({}) },
  },
  data: function () {
    return {
      loading: false,
      errorMsg: '',
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    if (this.internalValue.additional_option_type === null) {
      this.internalValue.additional_option_type = 'do_nothing'
    }
  },
}
</script>

<style scoped>
.time-pick {
  width: 100px;
}
</style>
