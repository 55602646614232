<template>
  <div v-if="initDone">
    <PtTitle />
    <v-card>
      <v-card-text>
        <div>
          <v-row>
            <v-col md="4">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>回答数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {{
                        data.answer_form_response_count +
                        '種(全' +
                        data.answer_form_count +
                        '回)'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col md="8">
              <v-row>
                <v-col md="2">
                  <img
                    class="orgn-icon"
                    :src="$$noimageHelper(data.api_picture_url)"
                  />
                </v-col>
                <v-col md="10" class="mt-3">
                  <p class="font-weight-bold">{{ data.system_disp_name }}</p>
                  <p class="mb-0">LINE登録名</p>
                  <p class="font-weight-bold">{{ data.api_display_name }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <hr />
        <div>
          <p>回答一覧</p>
        </div>
        <!-- show list answer response -->
        <div v-for="item in dataShow" v-if="dataShow && dataShow.length">
          <hr />
          <v-row v-if="item.hasOwnProperty('answer_form')">
            <v-col md="3">
              <div>
                <p class="mb-0">
                  <b>{{ item.answer_form.name }}</b
                  >への回答
                </p>
                <p class="mb-0">
                  {{
                    '(回答日' +
                    moment(item.created_at).format('YYYY/MM/DD HH:mm:ss') +
                    ')'
                  }}
                </p>
                <p>{{ '推定回答時間 ：' + item.convert_answer_time }}</p>
              </div>
            </v-col>
            <v-col md="9">
              <p><b>フォームの回答</b></p>
              <table class="table table-bordered table-striped">
                <tbody>
                  <template v-for="answer in item.form_response">
                    <tr
                      v-if="
                        answer.hasOwnProperty('beautify_value') &&
                        Array.isArray(answer.beautify_value) &&
                        answer.beautify_value.length > 0 &&
                        answer.beautify_value[0]
                      "
                    >
                      <td width="40%">{{ answer.input_title }}</td>
                      <td width="60%">
                        <p v-for="val in answer.beautify_value">
                          {{ val }}
                        </p>
                      </td>
                    </tr>
                    <tr v-else>
                      <td width="40%">{{ answer.input_title }}</td>
                      <td width="60%">
                        <template
                          v-if="answer.value && !Array.isArray(answer.value)"
                        >
                          <template v-if="typeof answer.value == 'object'">
                            <div
                              v-if="
                                answer.value.hasOwnProperty(
                                  'upload_file_type'
                                ) && answer.value.upload_file_type == 'image'
                              "
                            >
                              <img :src="answer.value.file.preview" alt="" />
                              <p>
                                <a :href="answer.value.file.original">{{
                                  getNameImage(answer.value.file.original)
                                }}</a>
                              </p>
                            </div>
                            <div
                              v-if="
                                answer.value.hasOwnProperty(
                                  'upload_file_type'
                                ) && answer.value.upload_file_type == 'document'
                              "
                            >
                              <v-icon>mdi-file-document</v-icon>
                              <p>
                                <a :href="answer.value.file.original">{{
                                  getNameImage(answer.value.file.original)
                                }}</a>
                              </p>
                            </div>
                          </template>
                          <template v-else>
                            {{ answer.value }}
                          </template>
                        </template>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </div>
        <div v-if="pagination.totalPage > 1">
          <v-row>
            <v-col md="4" class="offset-4 justify-content-center">
              <v-pagination
                v-model="pagination.page"
                :length="pagination.totalPage"
                :total-visible="5"
                @input="paginate($event)"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  data: () => ({
    loading: false,
    data: null,
    listAnswersResponse: null,
    moment: moment,
    dataShow: [],
    pagination: {
      page: 1,
      perPage: 20,
      totalPage: 5,
    },
    initDone: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path =
        '/api/v1/friends/' +
        this.$route.params.friend_id +
        '/answer_form_responses'
      await this.axios
        .get(path)
        .then((res) => {
          this.data = res.data
          this.listAnswersResponse = res.data.answer_form_responses
          this.getDataShow(this.pagination.page)
          this.pagination.totalPage =
            this.listAnswersResponse.length > 0
              ? Math.ceil(
                  this.listAnswersResponse.length / this.pagination.perPage
                )
              : 0
          this.initDone = true
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getNameImage(link) {
      let arr = link.split('/')
      return arr[arr.length - 1]
    },
    paginate(value) {
      this.getDataShow(value)
    },
    getDataShow(value) {
      this.dataShow = this.listAnswersResponse.slice(
        value * this.pagination.perPage - this.pagination.perPage,
        value * this.pagination.perPage
      )
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep .v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
a:hover {
  text-decoration: none;
}
::v-deep .v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.color-white {
  color: #fff !important;
}
::v-deep {
  fieldset > legend {
    display: none;
  }

  .v-label {
    display: none;
  }
}
.orgn-icon {
  width: 120px;
  height: 120px;
}
</style>
