<template>
  <div>
    <v-alert
      border="left"
      colored-border
      color="deep-purple accent-4"
      elevation="2"
      style="font-size: 12px"
    >
      {{ $t('lbl.friend.all_friends_selected_desc', { count: totalCount }) }}
    </v-alert>
    <v-row>
      <v-col cols="12" md="6">
        <VfCheckbox
          v-model="internalValue"
          class="label-mb-0 my-0"
          :label="$t('lbl.friend.all_friends_selected')"
          hide-details
        />
      </v-col>
      <v-col cols="12" md="6" align="right">
        <btn-dialog-confirm :detail="alertMessage" @save="$emit('save')" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import BtnDialogConfirm from './BtnDialogConfirm'
export default {
  components: {
    BtnDialogConfirm,
  },
  props: {
    value: { type: Number, required: true },
    friends: { type: Array, required: true },
    alertMessage: { type: String, required: true },
    totalCount: { type: Number, default: null },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
<style scoped>
.label-mb-0 ::v-deep label {
  margin-bottom: 0px;
}
</style>
