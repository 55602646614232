<template>
  <validation-observer v-if="initDone" ref="observer">
    <div
      v-if="systemErrors.length > 0 && exampleMode"
      class="admin-tips"
      v-html="adminTip"
    />
    <div class="container">
      <div class="row answer-form">
        <div
          class="col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-6 offset-lg-3 p-0 form-box"
        >
          <v-row v-if="showError" :style="bg_header" class="form-header">
            <v-col
              :cols="formHeaderCols.cols"
              :md="formHeaderCols.md"
              :sm="formHeaderCols.sm"
              :xs="formHeaderCols.xs"
            >
              <div class="submit_response_message">
                <p
                  v-if="systemErrors.length > 0"
                  v-html="autoLink(systemErrors[0].message, false)"
                />
              </div>
              <p>{{ $t('lbl.answer_form.please_close_page') }}</p>
            </v-col>
            <v-col
              v-if="
                !answerForm.answer_form_setting_attributes.hide_header_icon_flg
              "
              cols="3"
              sm="2"
              xs="3"
              md="2"
              class="icon-header"
            >
              <v-icon size="79" color="#ddd">mdi-block-helper</v-icon>
            </v-col>
          </v-row>
          <v-row v-if="!showError" :style="bg_header" class="form-header">
            <v-col
              :cols="formHeaderCols.cols"
              :md="formHeaderCols.md"
              :sm="formHeaderCols.sm"
              :xs="formHeaderCols.xs"
            >
              <h3>
                {{ answerForm.form_title
                }}<small v-if="exampleMode" class="text-danger">{{
                  $t('lbl.answer_form.mode_example')
                }}</small>
              </h3>
              <p class="ml-2 style-des" v-text="answerForm.form_description" />
            </v-col>
            <v-col
              v-if="
                !answerForm.answer_form_setting_attributes.hide_header_icon_flg
              "
              cols="3"
              md="2"
              sm="2"
              xs="3"
              class="icon-header"
            >
              <v-icon v-if="!showThankPage" size="79" color="#ddd"
                >mdi-lead-pencil</v-icon
              >
              <v-icon v-if="showThankPage" size="79" color="#ddd"
                >mdi-check</v-icon
              >
            </v-col>
          </v-row>
          <v-row v-if="!showError" :style="bg_content" class="form-content">
            <v-col>
              <div
                v-for="(
                  section, section_i
                ) in answerForm.answer_form_inputs_attributes"
              >
                <template v-if="page === section_i + 1 && !showThankPage">
                  <div v-for="(input, input_id) in section">
                    <PtFormRenderTextField
                      v-if="
                        input._type === 'text_field' &&
                        input.validation_type !== 'date_format' &&
                        input.validation_type !== 'time' &&
                        !input.hidden_flg
                      "
                      :value="form_response[input.id].value"
                      :internal-id="input.internal_id"
                      :auto-input="
                        answerForm.answer_form_setting_attributes
                          .auto_input_setting
                      "
                      :data-address="dataAddress"
                      :placeholder="input.placeholder"
                      :title="input.title"
                      :hidden-title="input.hide_title_flg"
                      :hint="input.description"
                      :label="input.title"
                      :required="input.require_flg"
                      :validation-type="input.validation_type"
                      :max-length="200"
                      @input="form_response[input.id].value = $event"
                      @fill-address="dataAddress = $event"
                    />
                    <PtFormRenderTime
                      v-if="
                        input._type === 'text_field' &&
                        input.validation_type === 'time' &&
                        !input.hidden_flg
                      "
                      :value="form_response[input.id].value"
                      :internal-id="input.internal_id"
                      :auto-input="
                        answerForm.answer_form_setting_attributes
                          .auto_input_setting
                      "
                      :data-address="dataAddress"
                      :placeholder="input.placeholder"
                      :title="input.title"
                      :hidden-title="input.hide_title_flg"
                      :hint="input.description"
                      :label="input.title"
                      :required="input.require_flg"
                      :validation-type="input.validation_type"
                      :max-length="200"
                      @input="form_response[input.id].value = $event"
                      @fill-address="dataAddress = $event"
                    />
                    <PtFormRenderTextArea
                      v-if="input._type === 'text_area' && !input.hidden_flg"
                      :value="form_response[input.id].value"
                      :placeholder="input.placeholder"
                      :title="input.title"
                      :hidden-title="input.hide_title_flg"
                      :hint="input.description"
                      :label="input.title"
                      :required="input.require_flg"
                      @input="form_response[input.id].value = $event"
                    />
                    <PtFormRenderCheckbox
                      v-if="input._type === 'checkbox' && !input.hidden_flg"
                      :value="form_response[input.id].value"
                      :placeholder="input.placeholder"
                      :title="input.title"
                      :hidden-title="input.hide_title_flg"
                      :hint="input.description"
                      :label="input.title"
                      :required="input.require_flg"
                      :input-options="
                        input.answer_form_input_options_attributes
                      "
                      :option-count="answerForm.selected_opt_count[input.id]"
                      :select-flg="input.limit_select_option_flg"
                      :min-select="input.limit_select_option_min"
                      :max-select="input.limit_select_option_max"
                      :friend="friend"
                      :color="
                        answerForm.answer_form_setting_attributes.button_color
                      "
                      @input="form_response[input.id].value = $event"
                    />
                    <PtFormRenderRadio
                      v-if="input._type === 'radio' && !input.hidden_flg"
                      :value="form_response[input.id].value"
                      :placeholder="input.placeholder"
                      :title="input.title"
                      :hidden-title="input.hide_title_flg"
                      :hint="input.description"
                      :label="input.title"
                      :required="input.require_flg"
                      :input-options="
                        input.answer_form_input_options_attributes
                      "
                      :option-count="answerForm.selected_opt_count[input.id]"
                      :friend="friend"
                      :column="true"
                      :row="false"
                      :color="
                        answerForm.answer_form_setting_attributes.button_color
                      "
                      no-label
                      label-hidden
                      @input="form_response[input.id].value = $event"
                    />
                    <PtFormRenderSelect
                      v-if="input._type === 'dropdown' && !input.hidden_flg"
                      :value="form_response[input.id].value"
                      :placeholder="input.placeholder"
                      :title="input.title"
                      :hidden-title="input.hide_title_flg"
                      :hint="input.description"
                      :label="input.title"
                      :required="input.require_flg"
                      :input-options="
                        input.answer_form_input_options_attributes
                      "
                      :option-count="answerForm.selected_opt_count[input.id]"
                      :friend="friend"
                      :column="true"
                      :row="false"
                      no-label
                      label-hidden
                      @input="form_response[input.id].value = $event"
                    />
                    <PtFormRenderSubHeading
                      v-if="input._type === 'subheading' && !input.hidden_flg"
                      :src="input.heading_image"
                      :title="input.title"
                      :show-img="input.additional_option_type"
                      :description="input.description"
                    />
                    <PtFormRenderMiddleHeading
                      v-if="
                        input._type === 'middleheading' && !input.hidden_flg
                      "
                      :src="input.heading_image"
                      :title="input.title"
                      :show-img="input.additional_option_type"
                      :description="input.description"
                    />
                    <PtFormRenderAttachment
                      v-if="input._type === 'attachment' && !input.hidden_flg"
                      v-model="form_response[input.id].value"
                      :uuid="answerFormId"
                      :type="input.additional_option_type"
                      :placeholder="input.placeholder"
                      :title="input.title"
                      :hint="input.description"
                      :label="input.title"
                      :required="input.require_flg"
                      :hidden="input.hidden_flg"
                      :hidden-title="input.hide_title_flg"
                      :input-options="
                        input.answer_form_input_options_attributes
                      "
                      @upload-file="disabledBtnSubmit = $event"
                    />
                    <PtFormRenderDate
                      v-if="
                        (input._type === 'date' ||
                          (input._type === 'text_field' &&
                            input.validation_type === 'date_format')) &&
                        !input.hidden_flg
                      "
                      v-model="form_response[input.id].value"
                      :placeholder="input.placeholder"
                      :title="input.title"
                      :hint="input.description"
                      :label="input.title"
                      :required="input.require_flg"
                      :hidden="input.hidden_flg"
                      :hidden-title="input.hide_title_flg"
                      :input-options="
                        input.answer_form_input_options_attributes
                      "
                      :data="input"
                    />
                    <PtFormRenderAddress
                      v-if="input._type === 'address' && !input.hidden_flg"
                      v-model="form_response[input.id].value"
                      :opts="opts"
                      :placeholder="input.placeholder"
                      :title="input.title"
                      :hint="input.description"
                      :label="input.title"
                      :required="input.require_flg"
                      :hidden="input.hidden_flg"
                      :hidden-title="input.hide_title_flg"
                      :input-options="
                        input.answer_form_input_options_attributes
                      "
                    />
                  </div>
                  <v-row class="mt-5 d-flex">
                    <v-col md="2" sm="3" xs="4" cols="12" class="">
                      <v-btn
                        v-if="pageStack.length"
                        :disabled="disabledBtnSubmit"
                        large
                        block
                        @click="handleBack(section_i)"
                        >{{ $t('btn.back') }}</v-btn
                      >
                    </v-col>
                    <v-col md="8" sm="6" xs="12" cols="12" class="">
                      <v-btn
                        color="primary"
                        :disabled="disabledBtnSubmit"
                        :style="bg_button"
                        large
                        block
                        @click="handleNext(section_i)"
                        >{{
                          section_i + 1 === totalPage
                            ? $t('btn.send')
                            : $t('btn.move_on')
                        }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </template>
              </div>
              <div v-show="showThankPage" class="form-finish">
                <b>{{ $t('lbl.answer_form.send_complete') }}</b
                ><span v-if="exampleMode">{{
                  $t('lbl.answer_form.not_actually_sent')
                }}</span>
                <br />
                <div class="submit_response_message">
                  <p
                    v-html="
                      autoLink(
                        answerForm.answer_form_setting_attributes
                          .submit_response_message,
                        true
                      )
                    "
                  />
                  <br />
                </div>
                <div>{{ $t('lbl.answer_form.please_close_page') }}</div>
              </div>
            </v-col>
          </v-row>
          <v-dialog v-model="dialog" persistent max-width="290">
            <v-card>
              <v-card-title />
              <v-card-text>
                {{ $t('lbl.answer_form.confirm_send_answer_form') }}
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="dialog = false">
                  {{ $t('btn.cancel') }}
                </v-btn>
                <v-btn
                  :disabled="disabledBtnSubmit"
                  color="primary"
                  @click="save"
                >
                  {{ $t('btn.send') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </validation-observer>
</template>
<script>
import PtFormRenderTextField from 'components/parts/common/PtFormRender/PtFormRenderTextField'
import PtFormRenderTime from 'components/parts/common/PtFormRender/PtFormRenderTime'
import PtFormRenderAttachment from 'components/parts/common/PtFormRender/PtFormRenderAttachment'
import PtFormRenderAddress from 'components/parts/common/PtFormRender/PtFormRenderAddress'
import PtFormRenderDate from 'components/parts/common/PtFormRender/PtFormRenderDate'
import PtFormRenderTextArea from 'components/parts/common/PtFormRender/PtFormRenderTextArea'
import PtFormRenderCheckbox from 'components/parts/common/PtFormRender/PtFormRenderCheckbox'
import PtFormRenderRadio from 'components/parts/common/PtFormRender/PtFormRenderRadio'
import PtFormRenderSelect from 'components/parts/common/PtFormRender/PtFormRenderSelect'
import PtFormRenderSubHeading from 'components/parts/common/PtFormRender/PtFormRenderSubHeading'
import PtFormRenderMiddleHeading from 'components/parts/common/PtFormRender/PtFormRenderMiddleHeading'

const SUBMIT_PAGE = 0

export default {
  name: 'Answer',
  components: {
    PtFormRenderTextField,
    PtFormRenderTime,
    PtFormRenderAttachment,
    PtFormRenderAddress,
    PtFormRenderDate,
    PtFormRenderTextArea,
    PtFormRenderCheckbox,
    PtFormRenderRadio,
    PtFormRenderSelect,
    PtFormRenderSubHeading,
    PtFormRenderMiddleHeading,
  },
  props: {
    value: { default: null },
  },
  data: function () {
    return {
      loading: false,
      initDone: false,
      dialog: false,
      errorMsg: '',
      answerFormId: null,
      answerForm: {},
      opts: [],
      submitData: {},
      textFieldTitle: '',
      form_response: {},
      restoreData: {},
      dataAddress: {},
      page: 1,
      nextPage: 1,
      pageStack: [],
      showError: false,
      systemErrors: [],
      adminTip: '',
      startTime: '',
      endTime: '',
      friend: null,
      showThankPage: false,
      disabledBtnSubmit: false,
    }
  },
  computed: {
    totalPage() {
      if (Array.isArray(this.answerForm.answer_form_inputs_attributes)) {
        return this.answerForm.answer_form_inputs_attributes.length
      } else {
        return 0
      }
    },
    exampleMode() {
      return this.$route.query.mode
    },
    bg_header() {
      if (!this.answerForm.answer_form_setting_attributes.header_image_url)
        return null
      return {
        background:
          'url(' +
          this.answerForm.answer_form_setting_attributes.header_image_url +
          ')',
        'background-size': 'cover',
      }
    },
    bg_content() {
      if (!this.answerForm.answer_form_setting_attributes.form_section_color)
        return null
      return {
        backgroundColor:
          this.answerForm.answer_form_setting_attributes.form_section_color,
      }
    },
    bg_button() {
      if (!this.answerForm.answer_form_setting_attributes.button_color)
        return null
      return {
        backgroundColor:
          this.answerForm.answer_form_setting_attributes.button_color +
          ' !important',
      }
    },
    formHeaderCols() {
      const isHideHeaderIcon =
        this.answerForm.answer_form_setting_attributes.hide_header_icon_flg
      return {
        cols: isHideHeaderIcon ? 12 : 9,
        md: isHideHeaderIcon ? 12 : 10,
        sm: isHideHeaderIcon ? 12 : 10,
        xs: isHideHeaderIcon ? 12 : 9,
      }
    },
  },
  created() {
    this.init()
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        this.startTime = Date.now()
      }
    }
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.answerFormId = this.$route.params.uuid
      const apiUserId = this.$route.params.friend_id || ''
      const modeQuery = this.exampleMode ? '?mode=example' : ''
      const path = '/api/v1/form/' + this.answerFormId + '/' + apiUserId
      const prms = { fid: this.$route.query.fid }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.answerForm = res.data.answer_form
          this.friend = res.data.friend
          this.opts = res.data.opts
          if (res.data.hasOwnProperty('errors') && res.data.errors.length > 0) {
            this.showError = !this.exampleMode
            this.systemErrors = this.formatSystemErrors(res.data.errors)
          }
          this.adminTip = '※フォーム受付停止中※'
          if (this.exampleMode && this.systemErrors.length > 0) {
            this.systemErrors.forEach((error) => {
              if (error.error === 'deadline_text') {
                this.adminTip += '<br>締切超過'
              }
              if (error.error === 'response_limit_text') {
                this.adminTip += '<br>回答数満員'
              }
            })
          }
          if (
            this.answerForm.answer_form_setting_attributes
              .answer_restoration_flg &&
            res.data.answer_form_response
          ) {
            this.restoreData = Array.isArray(
              res.data.answer_form_response.form_response
            )
              ? this.formatRestoreData(
                  res.data.answer_form_response.form_response
                )
              : {}
          }
          this.generateResponseData()
          this.setBackgroundColor()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      this.endTime = Date.now()
      this.$$loadingOverlayContent(true)
      this.disabledBtnSubmit = true
      const apiUserId = this.$route.params.friend_id || ''
      const path = '/api/v1/form/' + this.answerFormId + '/' + apiUserId
      const method = 'post'
      const prms = {
        locale: 'ja',
        answer_form_response: {
          form_response: this.formatFormResponse(),
          answer_time: Math.floor((this.endTime - this.startTime) / 1000),
        },
        alt_id: this.answerFormId,
        fid: this.$route.query.fid,
        from: this.$route.query.from,
        lastest_updated_at: this.answerForm.updated_at,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.showThankPage = true
          this.$toasted.success(this.$t('success.send'))
          let thankPageUrl =
            this.answerForm.answer_form_setting_attributes.thank_page_url
          if (thankPageUrl) {
            window.location.href = thankPageUrl
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.dialog = false
          this.disabledBtnSubmit = false
        })
    },
    setBackgroundColor() {
      if (this.answerForm.answer_form_setting_attributes.background_image_url) {
        document.getElementsByClassName(
          'v-main__wrap'
        )[0].style.backgroundImage =
          'url(' +
          this.answerForm.answer_form_setting_attributes.background_image_url +
          ')'
        document.getElementsByClassName(
          'v-main__wrap'
        )[0].style.backgroundSize = 'cover'
      } else {
        document.getElementsByClassName(
          'v-main__wrap'
        )[0].style.backgroundColor =
          this.answerForm.answer_form_setting_attributes.background_color
      }
    },
    handleBack() {
      this.page = this.pageStack.pop()
    },
    async handleNext(sectionIndex) {
      let pageInteract = -1
      this.nextPage = sectionIndex + 1
      this.answerForm.answer_form_inputs_attributes[sectionIndex].forEach(
        (input) => {
          if (input._type === 'radio' || input._type === 'dropdown') {
            if (input.on_page_interact_flg) {
              let selected = ''
              if (
                Array.isArray(this.form_response[input.id].value) &&
                typeof this.form_response[input.id].value.hasOwnProperty(0)
              ) {
                if (Array.isArray(this.form_response[input.id].value[0])) {
                  selected = this.form_response[input.id].value[0][0]
                } else {
                  selected = this.form_response[input.id].value[0]
                }
              }
              input.answer_form_input_options_attributes.forEach((option) => {
                if (option.id === selected) {
                  pageInteract =
                    option.on_page_interact_action ||
                    option.on_page_interact_action === SUBMIT_PAGE
                      ? option.on_page_interact_action
                      : this.nextPage + 1
                }
              })
            }
          }
        }
      )
      if (pageInteract !== -1) {
        this.nextPage = pageInteract
      } else if (
        sectionIndex + 1 ===
        this.answerForm.answer_form_inputs_attributes.length
      ) {
        this.nextPage = 0
      } else {
        this.nextPage += 1
      }
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.nextPage !== 0) {
        this.pageStack.push(this.page)
        this.page = this.nextPage
      } else {
        if (this.$route.query.mode) {
          this.showThankPage = true
        } else {
          this.dialog = true
        }
      }
    },
    generateResponseData() {
      this.answerForm.answer_form_inputs_attributes.forEach((section) => {
        section.forEach((input) => {
          let defaultValue
          if (input._type === 'text_field' || input._type === 'text_area') {
            if (
              input.additional_option_type === 'prepopulate' &&
              input.prepopulate_value
            ) {
              defaultValue = input.prepopulate_value
            } else if (input.validation_type === 'time') {
              defaultValue = '12:00'
            } else {
              defaultValue = null
            }
          } else {
            defaultValue = null
          }
          this.$set(this.form_response, input.id, {
            id: input.id,
            value: this.restoreData?.[input.id]
              ? this.restoreData[input.id].value
              : defaultValue,
          })
        })
      })
    },
    formatRestoreData(restoreData) {
      return restoreData.reduce((data, input) => {
        data[input.id] = input
        return data
      }, {})
    },
    formatFormResponse() {
      let formResponse = []
      for (const [key, value] of Object.entries(this.form_response)) {
        formResponse.push(value)
      }
      return formResponse
    },
    formatSystemErrors(errors) {
      return errors.reduce((accumulator, currentValue) => {
        for (const [key, value] of Object.entries(currentValue)) {
          accumulator.push({
            error: key,
            message: value,
          })
        }
        return accumulator
      }, [])
    },
    autoLink(text, is_submit) {
      if (text || is_submit) {
        return this.$sanitize(
          text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1'>$1</a>")
        )
      } else {
        return this.$t('lbl.answer_form.not_accepting')
      }
    },
  },
}
</script>
<style scoped>
::v-deep .text-bold {
  font-weight: bold;
  color: #555;
}
.icon-header {
  font-size: 50px;
  color: #ddd;
}
.form-header {
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px 25px;
}
.style-des {
  word-break: break-word;
  white-space: break-spaces;
}
.form-content {
  background-color: #eee;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 25px;
}
h3 {
  font-weight: 500;
  color: #555;
}
.form-box {
  -webkit-box-shadow: 8px 5px 8px 5px rgb(0 0 0 / 10%);
  box-shadow: 8px 5px 8px 5px rgb(0 0 0 / 10%);
}
.admin-tips {
  position: absolute;
  z-index: 100;
  left: 5px;
  top: 5px;
  color: red;
  text-align: center;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1em;
}
.submit_response_message {
  white-space: pre-line;
  word-wrap: break-word;
}
</style>
