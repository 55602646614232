<template>
  <div>
    <validation-observer ref="observer">
      <v-card class="mx-auto px-12 py-6" max-width="800">
        <v-card-title>
          <v-row justify="center">
            <div>{{ $t(`meta.title.${$route.name}`) }}</div>
          </v-row>
        </v-card-title>
        <v-card class="my-6">
          <v-card-title>
            {{ $t('lbl.login_channel_create') }}
          </v-card-title>
          <VDivider class="my-0" />
          <v-card-text>
            <div>{{ $t('lbl.line_login_channel_desc') }}</div>
            <v-row justify="center">
              <v-col>
                <div class="url_conf_explanation">
                  ※下記URLをコピーしてCallbackURLを設定してください。
                </div>
                <v-text-field
                  v-model="callbackUrl"
                  label="CallbackURL"
                  readonly
                >
                  <template #append-outer>
                    <v-icon
                      v-clipboard:copy="callbackUrl"
                      v-clipboard:success="onClipCopy"
                      v-clipboard:error="onClipError"
                      class="p-0 clipboard-icon"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                </v-text-field>
                <!-- <v-col>
                  <v-btn class="" @click='$toasted.show("リンク設置予定")'>{{$t('btn.line_login_channel_create')}}</v-btn>
                  <v-btn class="ml-4" style="text-transform: none" @click='$toasted.show("リンク設置予定")'>{{$t('btn.set_callback_url_create')}}</v-btn>
                </v-col> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="my-6">
          <v-card-title>
            {{ $t('lbl.login_channel_public') }}
          </v-card-title>
          <VDivider class="my-0" />
          <v-card-text>
            <div class="mb-4">{{ $t('lbl.login_channel_public_desc') }}</div>
            <VfCheckbox
              v-model="public_done"
              :label="$t('lbl.done_webhook_and_response')"
            />
          </v-card-text>
        </v-card>
        <v-card class="my-6">
          <v-card-title>
            {{ $t('lbl.set_callback_url') }}
          </v-card-title>
          <VDivider class="my-0" />
          <v-card-text>
            <div class="mb-4">{{ $t('lbl.set_callback_url_desc') }}</div>
            <VfCheckbox
              v-model="set_callback_done"
              :label="$t('lbl.done_callback_url')"
            />
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>
            {{ $t('lbl.login_channel_registration') }}
          </v-card-title>
          <VDivider class="my-0" />
          <v-card-text>
            <v-form @submit.prevent="save">
              <v-card-text v-if="channel">
                <v-row>
                  <v-col md="12">
                    <VfTextField
                      v-model="channel.line_login_channel_id"
                      :label="$$tm('channel.line_login_channel_id')"
                      :vees="vees.line_login_channel_id"
                      :disabled="!public_done || !set_callback_done"
                    />
                  </v-col>
                  <v-col md="12">
                    <VfTextField
                      v-model="channel.line_login_channel_secret_id"
                      :label="$$tm('channel.line_login_channel_secret_id')"
                      :vees="vees.line_login_channel_secret_id"
                      :disabled="!public_done || !set_callback_done"
                      password
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn type="submit" color="primary">{{
                  $t('btn.create')
                }}</v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-card>
    </validation-observer>
  </div>
</template>
<script>
export default {
  data: () => ({
    channel: {},
    vees: {},
    opts: {},
    public_done: false,
    set_callback_done: false,
    callbackUrl: `${window.location.origin}/inflow_routes/line_login_callback`,
  }),
  created: function () {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/channel/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.channel = res.data.channel
          this.vees = res.data.vees
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/channel`
      const prms = { channel: this.channel }
      await this.$$sendForm('put', path, this.$$reqPrms(prms))
        .then(() => {
          this.$router.push({ name: 'RegistrationLiffApp' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    copy() {
      console.log('copy')
    },
    onClipCopy(e) {
      this.$toasted.success('copied')
      console.log('You just copied: ' + e.text)
    },
    onClipError() {
      this.$toasted.success('error')
      console.log('Failed to copy texts')
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.clipboard-icon {
  cursor: pointer;
}

.url_conf_explanation {
  font-size: 4px;
  color: #ff0000;
}
</style>
