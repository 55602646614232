<template>
  <div @click="openDialog">
    <slot name="opener">
      <component
        :is="singleSelect ? 'VfSelect' : 'VfSelectChip'"
        v-model="selectedItemIds"
        :label="$$tm(`${selectType}.name`)"
        :selects="selects"
        :vees="vees"
        readonly
      />
    </slot>
    <v-dialog
      v-model="dialog"
      max-width="800"
      @click:outside="initSelectedItems"
      @keydown="keydownAction"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <component
                    :is="singleSelect ? 'VfSelect' : 'VfSelectChip'"
                    v-model="selectedItemIds"
                    :label="$$tm(`${selectType}.name`)"
                    :selects="selects"
                    readonly
                    icon
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="!hideFolder" md="4">
              <PtFolder
                ref="folder"
                :folder-type="selectType"
                select-only
                @select-folder="initTable"
              />
            </v-col>
            <v-col :md="hideFolder ? 12 : 8">
              <v-data-table
                v-model="selectedItems"
                :headers="headers"
                :items="items"
                item-key="id"
                show-select
                :single-select="singleSelect"
                disable-sort
                :items-per-page="50"
                :footer-props="{
                  itemsPerPageOptions: [50, 100, 150, -1],
                }"
                @click:row="rowClick"
              >
                <template
                  v-for="(slotContent, slotName) of $scopedSlots"
                  #[slotName]="data"
                >
                  <slot :name="slotName" v-bind="data" />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="cancel">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="primary" @click="decide">{{ $t('btn.update') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PtFolder from 'components/parts/common/PtFolder'
export default {
  components: {
    PtFolder,
  },
  props: {
    value: { type: Object, required: true },
    selectType: { type: String, required: true },
    singleSelect: { type: Boolean, default: false },
    hideFolder: { type: Boolean, default: false },
    onlyHaveTiming: { type: Boolean, default: false },
    vees: { type: Object, default: () => ({}) },
  },
  data: () => ({
    dialog: false,
    loading: false,
    headers: [],
    items: [],
    selectedItems: [],
    folderId: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    selects() {
      const ret = {}
      this.selectedItems.forEach((item) => (ret[item.name] = item.id))
      return ret
    },
    selectedItemIds: {
      get() {
        return this.singleSelect
          ? this.selectedItems[0]?.id
          : this.selectedItems.map((row) => row.id)
      },
      set(arr) {
        this.selectedItems = arr.map((id) =>
          this.selectedItems.find((row) => row.id === id)
        )
        if (!this.dialog) {
          this.decide()
        }
      },
    },
  },
  watch: {
    'internalValue.scenario_id'() {
      if (this.selectType !== 'scenario_timing') {
        return
      }
      this.$delete(this.internalValue, 'scenario_timing_id')
      this.$delete(this.internalValue, 'scenario_timing')
      this.selectedItems = []
    },
  },
  created() {
    this.initSelectedItems()
  },
  methods: {
    openDialog() {
      this.dialog = true
      this.initTable(this.folderId)
    },
    cancel() {
      this.initSelectedItems()
      this.dialog = false
    },
    decide() {
      if (this.singleSelect) {
        this.$set(
          this.internalValue,
          this.selectType + '_id',
          this.selectedItems[0]?.id || ''
        )
        this.$set(
          this.internalValue,
          this.selectType,
          this.selectedItems[0] || {}
        )
      } else {
        this.$set(
          this.internalValue,
          `target_${this.selectType}_ids`,
          this.selectedItems.map((row) => row.id)
        )
        this.$set(this.internalValue, this.selectType + 's', this.selectedItems)
      }
      this.dialog = false
      this.$emit('decide')
    },
    initTable(folderId) {
      this.folderId = folderId
      this.loading = true
      const path = `/api/v1/${this.selectType}s/action_group`
      const prms = {
        folder_id: folderId,
        only_have_timing: this.onlyHaveTiming,
      }
      if (this.selectType === 'scenario_timing') {
        prms['scenario_id'] = this.internalValue.scenario_id
      }
      this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data[`${this.selectType}s`]
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
    initSelectedItems() {
      if (this.singleSelect) {
        this.selectedItems = []
        if (
          this.internalValue[this.selectType] &&
          this.internalValue[this.selectType].name
        ) {
          this.selectedItems = [
            {
              id: this.internalValue[this.selectType + '_id'],
              name: this.internalValue[this.selectType].name,
            },
          ]
        }
      } else {
        this.selectedItems = this.$$clone(
          this.internalValue[this.selectType + 's']
        )
      }
    },
    keydownAction(event) {
      if (event.key === 'Escape') {
        this.initSelectedItems()
      }
    },
  },
}
</script>
