<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-row>
      <v-col md="6" class="pb-0">
        <VfTextField
          v-model="internalValue.title"
          :label="$t('lbl.answer_form.item_name')"
          :vees="vees.answer_form_input.title"
        />
      </v-col>
      <v-col md="6" class="pb-0">
        <VfCheckbox
          v-model="internalValue.hide_title_flg"
          :label="$t('lbl.answer_form.hide_item_name')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="pb-4">
        <VfTextField
          v-model="internalValue.description"
          :label="$t('lbl.answer_form.description')"
          :vees="vees.answer_form_input.short_description"
          :hint="$t('lbl.answer_form.text_field_desc_hint')"
        />
      </v-col>
    </v-row>
    <div class="pt-6 pb-4">
      <v-card>
        <v-card-subtitle class="or-card-title mb-0">
          {{ $t('lbl.answer_form.option') }}
        </v-card-subtitle>
        <v-card-text :key="forceRerenderOption">
          <draggable
            :list="internalValue.answer_form_input_options_attributes"
            :disabled="false"
            class="w-100"
            ghost-class="ghost"
            :move="checkMove"
            handle=".drag-handle"
            :animation="200"
            :delay="0"
            @start="dragging = true"
            @end="dragging = false"
          >
            <v-card
              v-for="(
                option, option_i
              ) in internalValue.answer_form_input_options_attributes"
              :key="option_i"
              class="mb-2"
            >
              <v-card-title class="pa-0 text-subtitle-1 blue-grey lighten-5">
                <v-list-item>
                  <div class="pr-1">{{ titleOption(option_i) }}</div>
                  <v-list-item-content class="px-3 py-0">
                    <VfTextField
                      v-model="
                        internalValue.answer_form_input_options_attributes[
                          option_i
                        ].value
                      "
                      class="hidden-label"
                      :label="titleOption(option_i)"
                      :vees="vees.answer_form_input_option.value"
                    />
                  </v-list-item-content>
                  <v-card-actions>
                    <v-btn small icon class="drag-handle" @click.stop>
                      <v-icon>mdi-cursor-move</v-icon>
                    </v-btn>
                    <v-btn small icon @click="copyOption(option_i)">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      icon
                      :disabled="inputOptionsLength === 1"
                      @click="removeOption(option_i)"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-list-item>
              </v-card-title>
              <v-card-text>
                <v-row class="mt-4">
                  <v-col md="2" class="font-weight-bold">
                    {{ $t('lbl.answer_form.configuration') }}
                  </v-col>
                  <v-col md="10">
                    <v-row no-gutters>
                      <v-col
                        md="2"
                        class="pa-2 border border-right-0 border-bottom-0 d-flex align-items-center font-weight-bold"
                      >
                        {{ $t('lbl.answer_form.initial_display') }}
                      </v-col>
                      <v-col class="pa-2 border border-right-0 border-bottom-0">
                        <VfSelect
                          :value="
                            internalValue.answer_form_input_options_attributes[
                              option_i
                            ].default_type
                          "
                          :label="''"
                          :selects="defaultType"
                          @input="handleDefaultSelect($event, option_i)"
                        />
                      </v-col>
                      <v-col class="pa-2 border border-left-0 border-bottom-0">
                        <div
                          v-if="
                            internalValue.answer_form_input_options_attributes[
                              option_i
                            ].default_type === 'only_with_tag' ||
                            internalValue.answer_form_input_options_attributes[
                              option_i
                            ].default_type === 'only_without_tag'
                          "
                          @click="openDialogDefault(option_i)"
                        >
                          <VfSelect
                            v-model="
                              internalValue
                                .answer_form_input_options_attributes[option_i]
                                .default_tag_id
                            "
                            :label="''"
                            :vv-name="$$tm('hashtag.name')"
                            :placeholder="
                              $t('lbl.answer_form.enter_the_tag_name')
                            "
                            :selects="selectDefaultTags[option_i]"
                            :vees="{ rules: 'required' }"
                            readonly
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        md="2"
                        class="pa-2 border border-right-0 d-flex align-items-center font-weight-bold"
                      >
                        {{ $t('lbl.answer_form.capacity') }}
                      </v-col>
                      <v-col class="pa-2 border border-right-0">
                        <VfSelect
                          v-model="
                            internalValue.answer_form_input_options_attributes[
                              option_i
                            ].limit_type
                          "
                          :label="''"
                          :selects="opts.answer_form_input.limit_type"
                        />
                      </v-col>
                      <v-col class="pa-2 border border-left-0">
                        <div
                          v-if="
                            internalValue.answer_form_input_options_attributes[
                              option_i
                            ].limit_type === 'use'
                          "
                        >
                          <VfTextFieldOnlyNumber
                            v-model.number="
                              internalValue
                                .answer_form_input_options_attributes[option_i]
                                .capacity
                            "
                            :label="$t('lbl.answer_form.capacity_number')"
                            :vees="{
                              rules: '|max:9|min_value:1|required',
                            }"
                            :minValue="0"
                          />
                          <VfTextField
                            v-model="
                              internalValue
                                .answer_form_input_options_attributes[option_i]
                                .capacity_warning
                            "
                            :label="$t('lbl.answer_form.capacity_warning')"
                            :placeholder="
                              $t('lbl.answer_form.can_not_select') +
                              (internalValue
                                .answer_form_input_options_attributes[option_i]
                                .value || '')
                            "
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="2" class="font-weight-bold pt-8 py-0">
                    <div>
                      {{ $t('lbl.answer_form.action') }}
                    </div>
                  </v-col>
                  <v-col md="10" class="py-0">
                    <div class="pt-5" @click="optionIndex = option_i">
                      <PtActionSelectedList
                        v-model="
                          internalValue.answer_form_input_options_attributes[
                            option_i
                          ].action_arr_temp
                        "
                        @pt-action-selected-list-save="
                          incrementForceRerenderOption($event, option_i)
                        "
                        @pt-action-selected-list-clear="
                          incrementForceRerenderOption($event, option_i)
                        "
                        @pt-search-selected-list-save="
                          incrementForceRerenderOption($event, option_i)
                        "
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="internalValue.on_page_interact_flg">
                  <v-col md="2" class="font-weight-bold pt-6 py-0">
                    {{ $t('lbl.answer_form.on_page_interact_action') }}
                  </v-col>
                  <v-col md="10" class="py-0">
                    <VfSelect
                      class="hidden-label"
                      v-model="
                        internalValue.answer_form_input_options_attributes[
                          option_i
                        ].on_page_interact_action
                      "
                      :label="$t('lbl.answer_form.on_page_interact_action')"
                      :selects="sectionOpts"
                      :vees="{
                        rules: internalValue.on_page_interact_flg
                          ? 'required'
                          : '',
                      }"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </draggable>
          <v-row>
            <v-col class="d-flex justify-content-center">
              <v-btn class="ma-2" @click="addOption('choice')">{{
                $t(`btn.add_choice`)
              }}</v-btn>
              <v-btn class="ma-2" @click="addOption('other')">{{
                $t(`btn.add_other`)
              }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <v-row v-if="inputType === 'checkbox'">
      <v-col md="2" class="pb-0 pr-0">
        <VfCheckbox
          v-model="internalValue.limit_select_option_flg"
          :label="$t('lbl.answer_form.selection_limit')"
        />
      </v-col>
      <v-col md="5" class="pb-0">
        <v-row v-if="internalValue.limit_select_option_flg">
          <v-col md="5" class="px-0 pb-0">
            <validation-provider name="min_value">
              <VfTextField
                v-model="internalValue.limit_select_option_min"
                class="hidden-label"
                label="min_value"
                :vees="compareMax"
                :min="0"
                number
              />
            </validation-provider>
          </v-col>
          <v-col
            md="1"
            class="d-flex justify-content-center align-items-center px-0 pb-0"
            >～</v-col
          >
          <v-col md="5" class="px-0 pb-0">
            <validation-provider name="max_value">
              <VfTextFieldOnlyNumber
                v-model="internalValue.limit_select_option_max"
                class="hidden-label"
                label="max_value"
                :vees="compareMin"
                :minValue="0"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="2" class="pb-0 offset-1">
        <VfCheckbox
          v-model="internalValue.require_flg"
          :label="$t('lbl.answer_form.require')"
        />
      </v-col>
      <v-col md="2" class="pb-0">
        <VfCheckbox
          v-model="internalValue.hidden_flg"
          :label="$t('lbl.answer_form.hide')"
        />
      </v-col> </v-row
    ><!-- end type checkbox -->
    <v-row
      v-if="inputType === 'radio' || inputType === 'dropdown'"
      class="pb-4"
    >
      <v-col md="3" class="pb-0 pr-0">
        <VfCheckbox
          v-if="
            checkedInteractIndex === setupIndex || checkedInteractIndex === -1
          "
          v-model="internalValue.on_page_interact_flg"
          :label="$t('lbl.answer_form.move_to_section')"
          :persistent-hint="true"
          :hint="$t('lbl.answer_form.please_create_destination')"
          :hide-details="'auto'"
          @change="updateOnPageInteractAction"
        />
      </v-col>
      <v-col md="4" class="pb-0" />
      <v-col md="2" class="pb-0 offset-1">
        <VfCheckbox
          v-model="internalValue.require_flg"
          :label="$t('lbl.answer_form.require')"
        />
      </v-col>
      <v-col md="2" class="pb-0">
        <VfCheckbox
          v-model="internalValue.hidden_flg"
          :label="$t('lbl.answer_form.hide')"
        />
      </v-col> </v-row
    ><!-- end type radio -->
    <v-dialog v-model="dialogDefaultTag" max-width="800">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <VfSelect
                    v-model="checkedDefaultTagId"
                    :label="$$tm('scenario.name')"
                    :selects="selectDialogDefault"
                    readonly
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="4">
              <PtFolder
                ref="folder"
                folder-type="hashtag"
                select-only
                @select-folder="selectFolder"
              />
            </v-col>
            <v-col md="8">
              <v-data-table
                v-model="checkedDefaultTags"
                :headers="headers"
                :items="items"
                item-key="id"
                show-select
                :single-select="true"
                :disable-sort="true"
                :items-per-page="50"
                :footer-props="{
                  itemsPerPageOptions: [50, 100, 150],
                }"
                @click:row="rowClick"
              >
                <template #[`item.action_group`]="{ item }">
                  <PtActionLabelMessages
                    v-model="item.action_group.make_action_arr"
                    class="or-list"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PtFolder from 'components/parts/common/PtFolder'
import PtActionSelectedList from 'components/parts/common/PtActionGroup/PtActionSelectedList'
import draggable from 'vuedraggable'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  name: 'PtFormSetupSelect',
  components: {
    draggable,
    PtFolder,
    PtActionSelectedList,
    PtActionLabelMessages,
  },
  props: {
    inputType: { type: String, default: '' },
    value: { type: Object, required: true },
    initData: { type: Object, required: true },
    targets: { type: Array, default: () => [] },
    vees: { type: Object, default: () => ({}) },
    opts: { type: Object, default: () => ({}) },
    setupIndex: { type: Number, default: null },
    checkedInteractIndex: { type: Number, default: null },
    sectionIndex: { type: Number, default: null },
    sectionLength: { type: Number, default: null },
    sectionList: { type: Array, default: () => [] },
  },
  data: function () {
    return {
      allVees: {},
      headers: [],
      items: [],
      selectedId: '',
      checkedDefaultTags: [],
      optionIndex: null,
      defaultType: {
        [this.$t('enum.answer_form_input_option.default_type.no_select')]:
          'no_select',
        [this.$t('enum.answer_form_input_option.default_type.selected')]:
          'selected',
        [this.$t('enum.answer_form_input_option.default_type.only_with_tag')]:
          'only_with_tag',
        [this.$t(
          'enum.answer_form_input_option.default_type.only_without_tag'
        )]: 'only_without_tag',
      },
      loading: false,
      dialogDefaultTag: false,
      checkedDefaultTagId: '',
      errorMsg: '',
      forceRerenderOption: 0,
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    selectDialogDefault() {
      const ret = {}
      this.checkedDefaultTags.forEach((item) => (ret[item.name] = item.id))
      return ret
    },
    selectDefaultTags() {
      const ret = []
      this.internalValue.answer_form_input_options_attributes.forEach(
        (option) => {
          if (option.default_tag) {
            ret.push({ [option.default_tag.name]: option.default_tag.id })
          } else {
            ret.push({})
          }
        }
      )

      return ret
    },
    inputOptionsLength() {
      return this.internalValue.answer_form_input_options_attributes.length
    },
    sectionOpts() {
      let sectionsList = {}
      this.sectionList.forEach((idx) => {
        if (idx - 1 !== this.sectionIndex) {
          sectionsList[this.$t('lbl.answer_form.section') + idx] = idx
        }
      })
      sectionsList[this.$t('lbl.answer_form.submit')] = 0
      this.internalValue.answer_form_input_options_attributes.forEach(
        (option, index) => {
          if (
            !Object.values(sectionsList).includes(
              option.on_page_interact_action
            )
          ) {
            option.on_page_interact_action = null
          }
        }
      )
      return sectionsList
    },
    compareMax() {
      const rules = this.allVees.answer_form_input.limit_select_option_min
      rules.rules += '|compare_max:@max_value'
      return rules
    },
    compareMin() {
      const rules = this.allVees.answer_form_input.limit_select_option_max
      rules.rules += '|compare_min:@min_value'
      return rules
    },
  },
  watch: {
    checkedDefaultTags(arr) {
      if (arr.length === 0) {
        this.internalValue.answer_form_input_options_attributes[
          this.optionIndex
        ].default_tag_id = ''
        this.$set(
          this.internalValue.answer_form_input_options_attributes[
            this.optionIndex
          ],
          'default_tag',
          {}
        )
        return
      }
      this.internalValue.answer_form_input_options_attributes[
        this.optionIndex
      ].default_tag_id = arr[0].id
      this.checkedDefaultTagId = arr[0].id
      this.$set(
        this.internalValue.answer_form_input_options_attributes[
          this.optionIndex
        ],
        'default_tag',
        { id: arr[0].id, name: arr[0].name }
      )
    },
  },
  created() {
    this.allVees = this.$$clone(this.vees)
    this.allVees.answer_form_input.description = { max: 90, rules: 'max:90' }
    this.internalValue.answer_form_input_options_attributes.forEach(
      (option, index) => {
        this.internalValue.answer_form_input_options_attributes[
          index
        ].action_arr_temp =
          this.internalValue.answer_form_input_options_attributes[
            index
          ].action_arr
      }
    )
  },
  methods: {
    incrementForceRerenderOption(actionArr, optionIndex) {
      this.internalValue.answer_form_input_options_attributes[
        optionIndex
      ].action_arr = actionArr
      this.forceRerenderOption += 1
    },
    openDialogDefault(optionIndex) {
      this.dialogDefaultTag = true
      this.optionIndex = optionIndex
      this.checkedDefaultTags = this.internalValue
        .answer_form_input_options_attributes[optionIndex].default_tag
        ? [
            this.internalValue.answer_form_input_options_attributes[optionIndex]
              .default_tag,
          ]
        : []
      this.checkedDefaultTagId =
        this.internalValue.answer_form_input_options_attributes[
          optionIndex
        ].default_tag_id
      this.initTable(null)
    },
    handleDefaultSelect(event, option_i) {
      this.$set(
        this.internalValue.answer_form_input_options_attributes[option_i],
        'default_type',
        event
      )

      let count = 0
      this.internalValue.answer_form_input_options_attributes.forEach(
        (option) => {
          if (option.default_type === 'selected') {
            count += 1
          }
        }
      )
      if (
        (this.inputType === 'radio' || this.inputType === 'dropdown') &&
        count > 1 &&
        event === 'selected'
      ) {
        this.$toasted.error(this.$t('lbl.answer_form.only_one_selected'))
        this.$set(
          this.internalValue.answer_form_input_options_attributes[option_i],
          'default_type',
          'no_select'
        )
        this.forceRerenderOption += 1
      } else {
        this.$set(
          this.internalValue.answer_form_input_options_attributes[option_i],
          'default_type',
          event
        )
      }
    },
    async initTable(folderId) {
      this.loading = true
      const path = '/api/v1/hashtags/action_group'
      const prms = { folder_id: folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.hashtags
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectFolder(folderId) {
      this.initTable(folderId)
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
    removeOption(index) {
      this.optionIndex = null
      this.internalValue.answer_form_input_options_attributes.splice(index, 1)
    },
    addOption(optionType) {
      let initOption = this.$$clone(
        this.initData.answer_form_input_options_attributes[0]
      )
      initOption.limit_type = 'not_use'
      initOption.is_other_flg = optionType === 'other' ? 1 : 0
      this.internalValue.answer_form_input_options_attributes.push(initOption)
    },
    copyOption(index) {
      let option = this.$$clone(
        this.internalValue.answer_form_input_options_attributes[index]
      )
      delete option.id
      this.internalValue.answer_form_input_options_attributes.splice(
        index,
        0,
        option
      )
    },
    titleOption(index) {
      let title = this.$t('lbl.answer_form.option') + (index + 1)
      if (
        this.internalValue.answer_form_input_options_attributes[index]
          .is_other_flg === 1
      ) {
        title += ` (${this.$t('lbl.answer_form.other')})`
      }

      return title
    },
    checkMove: function () {},
    updateOnPageInteractAction(index) {
      if (!this.internalValue.on_page_interact_flg) {
        this.internalValue.answer_form_input_options_attributes.forEach(
          (option) => {
            option.on_page_interact_action = null
          }
        )
      }
    },
  },
}
</script>

<style scoped lang="scss">
.time-pick {
  width: 100px;
}
.hidden-label ::v-deep {
  .v-text-field__slot .v-label,
  .v-select__slot .v-label {
    display: none;
  }
}
</style>
