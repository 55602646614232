<template>
  <v-menu
    ref="dateMenu"
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on }">
      <validation-provider v-slot="{ errors }" :name="label" :rules="rules">
        <v-text-field
          v-model="internalValue"
          :label="label"
          :placeholder="placeholder"
          :error-messages="errors"
          :disabled="disabled"
          :readonly="true"
          autocomplete="off"
          clearable
          v-on="on"
          @click:clear="datePick = null"
        />
      </validation-provider>
    </template>
    <v-date-picker
      v-model="datePick"
      no-title
      locale="jp-ja"
      :min="min"
      :max="max"
      :day-format="(date) => new Date(date).getDate()"
      @input="dateMenu = false"
    />
  </v-menu>
</template>
<script>
export default {
  props: {
    value: { type: String, default: '' },
    label: { type: String, required: true },
    placeholder: { type: String, default: '' },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    disabled: { type: Boolean, default: false },
    min: { type: String, default: '' },
    max: { type: String, default: '' },
  },
  data: () => ({
    datePick: '',
    dateMenu: false,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    rules() {
      if (!this.vees) {
        return null
      }
      return (this.vees || {}).rules
    },
  },
  watch: {
    internalValue() {
      this.internalValue = this.$$fdt(this.internalValue)
    },
    datePick() {
      this.internalValue = this.$$fdt(this.datePick)
    },
    dateMenu(val) {
      if (val) {
        this.datePick = this.internalValue?.replace(/\//g, '-')
      }
    },
  },
  mounted: function () {
    this.internalValue = this.$$fdt(this.internalValue)
  },
}
</script>
