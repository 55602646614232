import Vue from 'vue'
import VueI18n from 'vue-i18n'
import deepMerge from 'deepmerge'

let msg = {}
msg = deepMerge(msg, require('json-loader!yaml-loader!locales/models/ja.yml'))
msg = deepMerge(msg, require('json-loader!yaml-loader!locales/enums/ja.yml'))
msg = deepMerge(msg, require('json-loader!yaml-loader!locales/views/ja.yml'))
msg = deepMerge(
  msg,
  require('json-loader!yaml-loader!locales/view_defaults/ja.yml')
)
msg = deepMerge(
  msg,
  require('json-loader!yaml-loader!locales/meta_tags/ja.yml')
)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'ja',
  fallbackLocale: 'ja',
  messages: msg,
  silentTranslationWarn: true,
})

export default i18n
