<template>
  <div>
    <PtTitle />
    <div class="mb-2">
      <div>{{ $t('subtitle.staff_create') }}</div>
      <router-link :to="{ name: 'ChannelRoleForm', params: { id: 'new' } }">
        {{ $t('btn.channel_role_link') }}
      </router-link>
    </div>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        {{ $t('lbl.staff.wait') }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="channelTokens"
          :disable-sort="true"
          :items-per-page="-1"
          hide-default-footer
        >
          <template #[`item.channel_role`]="{ item }">
            <template v-if="item.channel_role">
              {{ item.channel_role.name }}
            </template>
          </template>
          <template #[`item.actions`]="{ item }">
            <btn-dialog-destroy
              @click="openDialogDestroy(item)"
              @save="destroy"
            />
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="mr-2" @click="toStaffIndex">
          {{ $t('btn.back') }}
        </v-btn>
        <btn-dialog-create-token
          v-model="selectedChannelRoleId"
          :select-channel-roles="selectChannelRoles"
          @click="openDialogCreateToken"
          @save="save"
        />
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import BtnDialogCreateToken from './Attach/BtnDialogCreateToken'
import BtnDialogDestroy from './Attach/BtnDialogDestroy'
export default {
  components: {
    BtnDialogCreateToken,
    BtnDialogDestroy,
  },
  data: () => ({
    loading: false,
    dialog: false,
    headers: [],
    channelTokens: [],
    channelTokenId: '',
    selectChannelRoles: [],
    selectedChannelRoleId: '',
  }),
  created() {
    this.getChannelTokens()
    this.getSelectChannelRoles()
  },
  methods: {
    async getChannelTokens() {
      this.loading = true
      const path = '/api/v1/channel_tokens'
      this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.headers = res.data.thead
          this.channelTokens = res.data.channel_tokens
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getSelectChannelRoles() {
      const path = '/api/v1/channel_roles/select'
      this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.selectChannelRoles = res.data.select_channel_roles
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    save() {
      this.loading = true
      const path = '/api/v1/channel_tokens'
      const params = { channel_role_id: this.selectedChannelRoleId }
      this.$$sendForm('post', path, this.$$reqPrms(params))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.getChannelTokens()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    destroy() {
      this.loading = true
      const path = `/api/v1/channel_tokens/${this.channelTokenId}`
      this.$$sendForm('delete', path)
        .then(() => {
          this.$toasted.success(this.$t('success.delete'))
          this.getChannelTokens()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    toStaffIndex() {
      this.$router.push({ name: 'StaffIndex' })
    },
    openDialogDestroy(item) {
      this.channelTokenId = item.id
    },
    openDialogCreateToken() {
      this.selectedChannelRoleId = ''
      if (this.selectChannelRoles.length > 0) {
        this.selectedChannelRoleId = this.selectChannelRoles[0].value
      }
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
