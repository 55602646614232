<template>
  <div>
    <PtTitle />
    <div>{{ $t('subtitle.staff') }}</div>
    <v-row class="mb-4">
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('staff.info') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-btn :to="{ name: 'StaffForm' }">{{
                  $t('btn.staff_new')
                }}</v-btn>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn :to="{ name: 'StaffAttach' }">{{
                  $t('btn.staff_already')
                }}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="hasRole">
        <v-card>
          <v-card-title>
            {{ $t('lbl.staff.channel_role_setting') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6">
                <v-btn :to="{ name: 'ChannelRoleIndex' }">{{
                  $t('btn.channel_role_index')
                }}</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        {{ $t('staff.list') }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :disable-sort="true"
          :items-per-page="-1"
          hide-default-footer
        >
          <template #[`item.user.current_sign_in_at`]="{ item }">
            <template v-if="item.user.current_sign_in_at">
              {{ $$fat(item.user.current_sign_in_at) }}
            </template>
            <template v-else>
              {{ item.user.unchanged_password }}
            </template>
          </template>
          <template #[`item.role`]="{ item }">
            <template v-if="item.channel_role">
              {{ item.channel_role.name }}
            </template>
            <template v-else>
              {{ $$te('user_channel.role', item) }}
            </template>
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ $$fat(item.created_at) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <template v-if="item.role !== 'owner'">
              <btn-dialog-select-role
                v-if="hasRole"
                v-model="selectedChannelRoleId"
                :select-channel-roles="selectChannelRoles"
                @click="openDialogSelectRole(item)"
                @save="saveRole"
              />
              <btn-dialog-remove-from-channel
                @click="openDialogRemove(item)"
                @save="saveRemove"
              />
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import BtnDialogSelectRole from './Index/BtnDialogSelectRole'
import BtnDialogRemoveFromChannel from './Index/BtnDialogRemoveFromChannel'
export default {
  components: {
    BtnDialogSelectRole,
    BtnDialogRemoveFromChannel,
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    selectedUserChannelId: '',
    selectedChannelRoleId: '',
    selectChannelRoles: [],
  }),
  computed: {
    hasRole() {
      return this.$store.getters.roles.includes('role')
    },
  },
  created() {
    this.getStaffs()
    if (this.hasRole) {
      this.getSelectChannelRoles()
    }
  },
  methods: {
    getStaffs() {
      this.loading = true
      const path = '/api/v1/staffs'
      this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.user_channels
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getSelectChannelRoles() {
      const path = '/api/v1/channel_roles/select'
      this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.selectChannelRoles = res.data.select_channel_roles
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    openDialogSelectRole(item) {
      this.selectedUserChannelId = item.id
      this.selectedChannelRoleId = ''
      if (item.channel_role) {
        this.selectedChannelRoleId = item.channel_role.id
      }
    },
    openDialogRemove(item) {
      this.selectedUserChannelId = item.id
    },
    saveRole() {
      const path = `/api/v1/staffs/${this.selectedUserChannelId}`
      const params = { channel_role_id: this.selectedChannelRoleId }
      this.$$sendForm('put', path, this.$$reqPrms(params))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.getStaffs()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    saveRemove() {
      const path = `/api/v1/staffs/${this.selectedUserChannelId}`
      this.$$sendForm('delete', path)
        .then((res) => {
          this.$toasted.success(this.$t('success.update'))
          if (this.$store.getters.user.id === res.data.user_id) {
            window.location.href = this.$router.resolve({
              name: 'AccountShow',
            }).href
          } else {
            this.getStaffs()
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
