<template>
  <div>
    <PtTitle />
    <notify-form
      v-model="lineNotice"
      class="mb-4"
      :channels="channels"
      :vees="vees"
      :notice-types="opts.notice_type"
      edit
      @save="save"
    />
    <v-row>
      <v-col align="right">
        <v-btn text color="error" @click="openDialogDestroy">
          {{ $t('btn.delete') }}
        </v-btn>
      </v-col>
    </v-row>
    <VfDialogConfirm
      v-model="dialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="PATH.destroy"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="destroySuccess"
    />
  </div>
</template>
<script>
import NotifyForm from 'pages/v1/notificators/Common/NotifyForm'
export default {
  components: {
    NotifyForm,
  },
  data: () => ({
    lineNotice: {},
    channels: [],
    dialog: false,
    vees: {},
    opts: { notice_type: [] },
  }),
  computed: {
    PATH() {
      return {
        edit: `/api/v1/notificator/${this.$route.params.id}/edit`,
        update: `/api/v1/notificator/${this.$route.params.id}`,
        destroy: `/api/v1/notificator/${this.$route.params.id}`,
      }
    },
  },
  created() {
    this.getNotificator()
  },
  methods: {
    getNotificator() {
      this.$$loadingOverlayContent(true)
      this.axios
        .get(this.PATH.edit)
        .then((res) => {
          this.channels = res.data.channels
          this.lineNotice = res.data.line_notice
          this.opts = res.data.opts
          this.vees = res.data.vees
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    save() {
      this.$$loadingOverlayContent(true)
      const prms = { line_notice: this.lineNotice }
      this.$$sendForm('put', this.PATH.update, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.$router.push({ name: 'SpNotificatorIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    openDialogDestroy() {
      this.dialog = true
    },
    destroySuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'SpNotificatorIndex' })
    },
  },
}
</script>
