<template>
  <v-dialog v-model="dialog" :max-width="500">
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" small v-on="on" @click="$emit('click')">
        {{ $t('btn.change_role') }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t('lbl.staff.dialog.change_role') }}</v-card-title>
      <v-divider class="mt-2" />
      <v-card-text class="pt-2">
        <VfSelect
          v-model="internalValue"
          :label="$$tm('user_channel.channel_role_id')"
          :selects="selectChannelRoles"
          :vees="{ rules: 'required' }"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="dialog = false">
          {{ $t('btn.close') }}
        </v-btn>
        <v-btn color="primary" @click="save">
          {{ $t('btn.create') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: { type: String, required: true },
    selectChannelRoles: { type: Array, required: true },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    save() {
      this.dialog = false
      this.$emit('save')
    },
  },
}
</script>
