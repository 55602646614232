<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn
            v-if="actionTemplateId !== 'new'"
            color="error"
            @click="destroy"
          >
            {{ $t('btn.delete') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.action_template.info') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <VfSelect
                v-model="actionTemplate.folder_id"
                :label="$$tm('folder.name')"
                :vees="vees.folder_id"
                :selects="folders"
              />
            </v-col>
            <v-col md="6">
              <VfTextField
                v-model="actionTemplate.name"
                :label="$$tm('action_template.name')"
                :vees="vees.name"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.action_template.action') }}
        </v-card-title>
        <v-card-text>
          <PtActionGroup v-model="actionTemplate.action_arr" />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col class="text-right">
          <v-btn
            color="primary"
            :loading="$wait.is('processing')"
            @click="save"
            >{{ $t('btn.create') }}</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtActionGroup from 'components/parts/common/PtActionGroup/PtActionGroup'
import {
  validateMultipleDeliText,
  validateMultipleUrlDeliText,
} from 'services/deliText'
export default {
  components: {
    PtActionGroup,
  },
  data: () => ({
    initDone: false,
    actionTemplateId: '',
    actionTemplate: {},
    vees: {},
    folders: {},
    opts: {},
    savePath: '',
    deleteDialog: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.actionTemplateId = this.$route.params.id
      const path =
        this.actionTemplateId === 'new'
          ? '/api/v1/action_templates/new'
          : `/api/v1/action_templates/${this.actionTemplateId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.actionTemplate = res.data.action_template
          this.vees = res.data.vees
          this.folders = res.data.folders
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        if (
          this.actionTemplate.action_arr.length > 0 &&
          !validateMultipleUrlDeliText(this.actionTemplate.action_arr)
        ) {
          return this.$toasted.error(this.$t('validation.length_of_url'))
        } else {
          return this.$toasted.error(this.$t('danger.params'))
        }
      }
      if (this.$wait.is('processing')) {
        return
      }
      if (
        this.actionTemplate.action_arr.length > 0 &&
        this.actionTemplate.action_arr[0].type === 'broadcast' &&
        !validateMultipleDeliText(
          this.actionTemplate.action_arr[0].data.target_delis
        )
      ) {
        return this.$toasted.error(this.$t('danger.deli_text.format_invalid'))
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.actionTemplateId === 'new'
          ? '/api/v1/action_templates'
          : `/api/v1/action_templates/${this.actionTemplateId}`
      const method = this.actionTemplateId === 'new' ? 'post' : 'put'
      const prms = {
        action_template: this.actionTemplate,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'ActionTemplateIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    destroy() {
      this.savePath = `/api/v1/action_templates/${this.actionTemplateId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'ActionTemplateIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
