<template>
  <div>NotFound 404</div>
</template>
<script>
export default {
  mounted: function () {
    this.$toasted.error('Not Found Page')
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
