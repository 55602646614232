<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-list-item>
        <v-list-item-content>
          {{ $t('account.channel') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            outlined
            @click="$router.push({ name: 'RegistrationAccount' })"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card-title>
    <v-list-item
      v-for="channel in channels"
      :key="channel.id"
      :disabled="channel.stop_flg == 1"
      @click="$emit('select', channel.id)"
    >
      <v-list-item-content>
        <v-row justify="space-between">
          <v-col cols="8">
            <v-list-item-title>{{ channel.name }}</v-list-item-title>
          </v-col>
          <v-col v-if="channel.stop_flg == 1" cols="2">
            <v-icon color="red lighten-3">far fa-times-circle</v-icon>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  props: {
    channels: { type: Array, required: true },
  },
}
</script>
