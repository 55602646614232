<template>
  <v-card>
    <v-card-title>
      {{ $t('account.sp_link_title') }}
    </v-card-title>
    <v-card-text>
      {{ $t('account.sp_link_detail') }}
      <hr />
      <template v-if="!user.sp_user">
        <h5>{{ $t('line_sp_link.not_sync') }}</h5>
        <p>{{ $t('line_sp_link.not_sync_label') }}</p>
      </template>
      <template v-else-if="user.sp_user.verified">
        <h5>{{ $t('line_sp_link.sync') }}</h5>
        <p>{{ $t('line_sp_link.name_sync', { name: user.name }) }}</p>
      </template>
      <template v-else>
        <h5>{{ $t('line_sp_link.wait_sync') }}</h5>
        <ul class="pl-0">
          <ol class="pl-0">
            {{
              $t('line_sp_link.wait_sync_detail_01')
            }}
          </ol>
          <ol class="pl-0">
            {{
              $t('line_sp_link.wait_sync_detail_02')
            }}
          </ol>
          <ol class="pl-0">
            {{
              $t('line_sp_link.wait_sync_detail_03')
            }}
          </ol>
        </ul>
        <v-row>
          <v-col align="center">
            <div>{{ $t('line_sp_link.link_code') }}</div>
            <h4>
              <strong>{{ user.sp_user.code }}</strong>
            </h4>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn v-if="user.sp_user" color="danger" @click="$emit('destroy')">
        {{ $t(destroyBtnLabel) }}
      </v-btn>
      <v-btn v-else color="primary" @click="$emit('save')">
        {{ $t('line_sp_link.issue_link_code') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    user: { type: Object, required: true },
  },
  computed: {
    destroyBtnLabel() {
      return `line_sp_link.${
        this.user.sp_user.verified ? 'unsync' : 'destroy_link_code'
      }`
    },
  },
}
</script>
