<template>
  <div>
    <v-text-field v-model="webhook" readonly class="webhook-url-text">
      <template #append-outer>
        <v-btn
          v-clipboard:copy="webhook"
          v-clipboard:success="onClipCopy"
          v-clipboard:error="onClipError"
          class="ml-4"
        >
          <v-icon>mdi-content-copy</v-icon>
          copy
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>
<script>
export default {
  props: {
    channel_id: { type: String, required: true },
  },
  data: () => ({
    webhook: '',
  }),
  computed: {
    webhookUrl() {
      return `${window.location.protocol}//${window.location.host}/api/v1/line_webhook/${this.channel_id}`
    },
  },
  watch: {
    webhookUrl(val) {
      this.webhook = val
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.webhook = this.webhookUrl
    },
    copy() {
      console.log('copy')
    },
    onClipCopy(e) {
      this.$toasted.success('copied')
      console.log('You just copied: ' + e.text)
    },
    onClipError() {
      this.$toasted.success('error')
      console.log('Failed to copy texts')
    },
  },
}
</script>
<style scoped>
.webhook-url-text {
  font-size: 12px;
}
</style>
