<template>
  <div>
    <PtTitle />
    {{ $t('subtitle.broadcast') }}
    <v-card outlined>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        <v-row>
          <v-col md="8" />
          <v-col md="4" class="text-right">
            <v-btn color="primary" @click="newItem">{{
              $t('btn.new_item')
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :disable-sort="true"
        :server-items-length="totalCount"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150],
        }"
        @update:options="updateOptions"
      >
        <template #[`item.send_target`]="{ item }">
          <div v-if="item.all_cast_flg">
            <div v-if="item.send_flg == 0">
              <a href="#" @click="editItem(item)">
                {{ $$tm('broadcast.all_cast_flg') }}
              </a>
            </div>
            <div v-else>
              {{ $$tm('broadcast.all_cast_flg') }}
            </div>
          </div>
          <div v-else class="or-list">
            <div v-if="item.send_flg == 0">
              <a href="#" @click="editItem(item)">
                <PtSearchSelectedList
                  v-model="item.search_wrap.as_json_form"
                  class="send-target-list"
                  disp-type="list"
                />
              </a>
            </div>
            <div v-else>
              <PtSearchSelectedList
                v-model="item.search_wrap.as_json_form"
                class="send-target-list"
                disp-type="list"
              />
            </div>
          </div>
        </template>
        <template #[`item.delis`]="{ item }">
          <div class="my-2 pointer" @click="showItem(item)">
            <PtLinePreviewRow :deli="item.delis[0]" message-type="send" />
            <template v-if="item.delis.length > 1">
              <div class="text-right mt-2">
                <a>
                  {{
                    $t('lbl.with_other_count', { count: item.delis.length - 1 })
                  }}
                </a>
              </div>
            </template>
          </div>
        </template>
        <template #[`item.reserve_at`]="{ item }">
          {{ $$fdt(item.reserve_at) }}
          {{ $$ftm(item.reserve_at, 'LTS') }}
        </template>
        <template #[`item.preview`]="{ item }">
          <v-btn depressed x-small @click="showItem(item)">プレビュー</v-btn>
        </template>
      </v-data-table>
    </v-card>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
    <PtDialogLinePreview
      :key="selectedItem.id"
      v-model="previewDialog"
      type="broadcast"
      :model-id="selectedItem.id"
    />
  </div>
</template>
<script>
import PtLinePreviewRow from 'components/parts/common/PtLinePreviewRow'
import PtDialogLinePreview from 'components/parts/common/PtDialogLinePreview'
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
export default {
  components: {
    PtLinePreviewRow,
    PtDialogLinePreview,
    PtSearchSelectedList,
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    savePath: '',
    deleteDialog: false,
    previewDialog: false,
    selectedItem: { id: '' },
    totalCount: 0,
    options: {
      page: 1,
      per: 50,
    },
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/broadcasts'
      await this.axios
        .get(path, { params: this.$$reqPrms({ ...this.options }) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.broadcasts
          this.totalCount = res.data.total_count
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateOptions({ page, itemsPerPage }) {
      const isChangedPage = this.options.page !== page
      const isChangedPer = this.options.per !== itemsPerPage
      if (!isChangedPage && !isChangedPer) {
        return
      }
      this.options.page = page
      this.options.per = itemsPerPage
      this.init()
    },
    newItem() {
      this.$router.push({ name: 'BroadcastForm', params: { id: 'new' } })
    },
    editItem(item) {
      this.$router.push({ name: 'BroadcastForm', params: { id: item.id } })
    },
    deleteItem(item) {
      this.savePath = `/api/v1/broadcasts/${item.id}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.init()
    },
    showItem(item) {
      this.selectedItem = item
      this.previewDialog = true
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.or-list {
  max-width: 300px;
  font-size: 0.5em;
}
.send-target-list {
  font-size: 14px;
}
</style>
