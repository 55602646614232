<template>
  <v-card class="my-4">
    <VfLoadingOverlayAbsolute :loading="loading" />
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="id"
      :disable-sort="true"
    >
      <template #[`item.scenario_id`]="{ item }">
        {{ item.scenario.name }}
      </template>
      <template #[`item.scenario_timing_id`]="{ item }">
        {{ timingDisplay(item.scenario_timing) }}
      </template>
      <template #[`item.deli_id`]="{ item }">
        <PtLinePreviewRow class="my-2" :deli="item.deli" />
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import PtLinePreviewRow from 'components/parts/common/PtLinePreviewRow'
export default {
  components: {
    PtLinePreviewRow,
  },
  props: {
    clickId: { type: String, required: true },
  },
  data: () => ({
    headers: [],
    items: [],
    loading: false,
    csv_data: [],
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      const path = '/api/v1/click_scenarios'
      const prms = { click_id: this.clickId }
      this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.click_scenarios

          let headers = this.headers.map((h) => h.text)
          let items = this.items.map((item) => [
            item.scenario.name,
            this.timingDisplay(item.scenario_timing),
            this.$$csv_deli(item.deli),
            item.click_count,
            item.visit_count,
            item.send_count,
          ])
          this.csv_data = [headers].concat(items)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    timingDisplay(scenarioTiming) {
      let disp = ''
      switch (scenarioTiming.scenario_timing_type) {
        case 'start':
          disp = this.$$te(
            'scenario_timing.scenario_timing_type',
            scenarioTiming
          )
          break
        case 'end':
          disp = this.$$te(
            'scenario_timing.scenario_timing_type',
            scenarioTiming
          )
          disp += ' '
          disp += scenarioTiming.sch_timing
          break
        default:
          disp = scenarioTiming.sch_timing
      }
      return disp
    },
  },
}
</script>
