<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-list-item>
        <v-list-item-content>
          {{ $$tm('user_address.tname') }}
        </v-list-item-content>
        <v-list-item-action>
          <btn-dialog-user-address-form
            :user-address="userAddress"
            :vees="vees"
            :opts="opts"
            @save="save"
          />
        </v-list-item-action>
      </v-list-item>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <tbody>
          <tr>
            <td>{{ $$tm('user_address.zip') }}</td>
            <td>{{ userAddress.zip }}</td>
          </tr>
          <tr>
            <td>{{ $$tm('user_address.pref') }}</td>
            <td>{{ $$te('user_address.pref', userAddress) }}</td>
          </tr>
          <tr>
            <td>{{ $$tm('user_address.address1') }}</td>
            <td>{{ userAddress.address1 }}</td>
          </tr>
          <tr>
            <td>{{ $$tm('user_address.address2') }}</td>
            <td>{{ userAddress.address2 }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import BtnDialogUserAddressForm from './BtnDialogUserAddressForm'
export default {
  components: {
    BtnDialogUserAddressForm,
  },
  props: {
    userAddress: { type: Object, required: true },
    vees: { type: Object, required: true },
    opts: { type: Object, required: true },
  },
  methods: {
    async save(saveUserAddress) {
      this.$emit('save', saveUserAddress)
    },
  },
}
</script>
