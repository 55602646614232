<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-row>
      <v-col md="6" class="pb-0">
        <VfSelect
          v-model="internalValue.change_message_status"
          :label="$$tm('action_label.change_message_status')"
          :vees="vees.change_message_status"
          :selects="opts.change_message_status"
          label-hidden
        />
      </v-col>
      <v-col md="6" class="pb-0">
        <VfSelect
          v-model="internalValue.change_friend_status"
          :label="$$tm('action_label.change_friend_status')"
          :vees="vees.change_friend_status"
          :selects="opts.change_friend_status"
          label-hidden
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    opts: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    loading: false,
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.init()
  },
}
</script>
