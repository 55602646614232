<template>
  <div class="container">
    <div class="ma-auto logo-container blue-grey darken-4 mb-7">
      <img class="logo-header" src="~images/talk_label_logo.png" />
    </div>
    <template v-if="hasActiveChannels">
      <v-select
        v-model="selectedChannel"
        class="mb-7"
        :items="selectChannels"
        outlined
        dense
        hide-details
        @change="changeChannel"
      />
      <template v-if="hasRole.mobile">
        <v-btn
          v-if="hasRole.broadcast"
          block
          class="mb-7"
          color="primary"
          :to="{ name: 'SpBroadcastForm' }"
        >
          一斉送信
        </v-btn>
        <v-btn
          v-if="hasRole.broadcast"
          block
          class="mb-7"
          color="primary"
          :to="{ name: 'SpFixedPhraseForm' }"
        >
          テンプレート送信
        </v-btn>
        <v-btn
          v-if="hasRole.friend_unicast"
          block
          class="mb-7"
          color="green accent-3 white--text"
          :to="{ name: 'SpUnicastIndex' }"
        >
          個別トーク
        </v-btn>
      </template>
      <template v-else>
        <v-alert type="error">
          <div class="or-break">{{ $t('lbl.no_role_mobile') }}</div>
        </v-alert>
      </template>
      <v-btn block class="mt-7" :to="{ name: 'SpNotificatorIndex' }">
        スマートフォン連携設定
      </v-btn>
    </template>
    <template v-else>
      <v-alert type="error">
        <div class="or-break">
          {{ $t('line_sp_link.nothing_active_channels') }}
        </div>
      </v-alert>
    </template>
  </div>
</template>
<script>
export default {
  data: () => ({
    selectChannels: [],
    selectedChannel: '',
    hasActiveChannels: true,
  }),
  computed: {
    hasRole() {
      const channel = this.$store.getters.channel
      return {
        mobile: channel && channel.plan_role_types.includes('mobile'),
        broadcast: this.$store.getters.roles.includes('broadcast'),
        friend_unicast: this.$store.getters.roles.includes('friend_unicast'),
      }
    },
  },
  created() {
    this.init()
    this.getChannels()
  },
  methods: {
    init() {
      this.selectedChannel = this.$store.getters.channel.id
    },
    getChannels() {
      this.$$loadingOverlay(true)
      this.axios
        .get('/api/v1/account/channels')
        .then((res) => {
          this.selectChannels = res.data.channels.map((channel) => {
            return { text: channel.name, value: channel.id }
          })
          if (this.selectChannels.length === 0) {
            this.hasActiveChannels = false
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlay(false)
        })
    },
    changeChannel(channelId) {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/channel/select`
      const prms = { channel_id: channelId }
      this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          window.location.href = this.$router.resolve({ name: 'SpIndex' }).href
        })
        .catch((error) => {
          this.$$ajaxError(error)
          this.$$loadingOverlayContent(false)
        })
    },
  },
}
</script>
<style scoped>
.logo-container {
  width: fit-content;
  padding: 11px;
}
.logo-header {
  cursor: hand;
  cursor: pointer;
  width: 200px;
}
</style>
