<template>
  <v-card outlined>
    <v-card-title class="pa-0">
      <v-list-item>
        <v-list-item-content>
          {{ $t('lbl.credit_card.index') }}
        </v-list-item-content>
        <v-list-item-action>
          <btn-dialog-credit-card-create @create:token="createToken" />
        </v-list-item-action>
      </v-list-item>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="creditCards"
      :items-per-page="-1"
      disable-sort
      hide-default-footer
      @click:row="openDialog"
    >
      <template #[`item.default_card`]="{ item }">
        <v-icon v-if="item.default_card" color="primary">
          mdi-check-circle-outline
        </v-icon>
      </template>
      <template #[`item.actions`]="{ item }">
        <btn-dialog-destroy
          small
          @click="selectCreditCard(item)"
          @save="destroyCreditCard"
        />
      </template>
    </v-data-table>
    <dialog-update-default-card v-model="dialog" @save="updateDefaultCard" />
  </v-card>
</template>
<script>
import BtnDialogDestroy from 'components/commonParts/BtnDialogDestroy'
import BtnDialogCreditCardCreate from './BtnDialogCreditCardCreate'
import DialogUpdateDefaultCard from './DialogUpdateDefaultCard'
export default {
  components: {
    BtnDialogDestroy,
    BtnDialogCreditCardCreate,
    DialogUpdateDefaultCard,
  },
  props: {
    creditCards: { type: Array, required: true },
  },
  data: () => ({
    dialog: false,
    selectedCreditCardId: '',
  }),
  computed: {
    headers() {
      return [
        { text: '', value: 'default_card' },
        { text: this.$t('lbl.credit_card.brand'), value: 'brand' },
        { text: this.$t('lbl.credit_card.card_no'), value: 'card_no' },
        { text: this.$t('lbl.credit_card.expired_dt'), value: 'expired_dt' },
        { text: this.$t('lbl.actions'), value: 'actions' },
      ]
    },
  },
  methods: {
    createToken(token) {
      this.$emit('create:token', token)
    },
    selectCreditCard(item) {
      this.selectedCreditCardId = item.card_id
    },
    openDialog(item) {
      this.selectCreditCard(item)
      this.dialog = true
    },
    updateDefaultCard() {
      this.$emit('update:default-card', this.selectedCreditCardId)
    },
    destroyCreditCard() {
      this.$emit('destroy:credit-card', this.selectedCreditCardId)
    },
  },
}
</script>
