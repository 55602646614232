<template>
  <div>
    <v-card outlined>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        {{ $t('lbl.folder') }}
      </v-card-title>
      <v-list v-if="folders.length > 0" class="py-0">
        <v-list-item-group v-model="selectedFolderId" mandatory>
          <draggable
            v-model="folders"
            draggable=".sortable"
            handle=".drag-handle"
            group="ITEMS"
            :animation="200"
            :force-fallback="true"
            :delay="50"
            @end="sortFolder"
          >
            <v-list-item
              v-for="(folder, folder_i) in folders"
              :key="folder.id"
              :value="folder.id"
              :class="{ sortable: folder_i > 0 }"
              :data-folder-id="folder.id"
            >
              <v-list-item-icon
                v-if="!selectOnly"
                class="mr-2"
                :class="{ 'drag-handle': folder_i > 0 }"
              >
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ folder.name }}
                  ({{ folder.item_count }})
                </v-list-item-title>
              </v-list-item-content>
              <template
                v-if="
                  !selectOnly && folder.id === selectedFolderId && folder_i > 0
                "
              >
                <v-list-item-icon @click="openDialogEdit(folder)">
                  <v-icon>mdi-pencil</v-icon>
                </v-list-item-icon>
                <v-list-item-icon @click="openDialogDelete(folder)">
                  <v-icon>mdi-delete</v-icon>
                </v-list-item-icon>
              </template>
            </v-list-item>
            <v-list-item
              v-if="!selectOnly"
              key="add"
              value="add"
              @click="openDialogAdd"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('btn.add') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </draggable>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-dialog v-model="formDialog" max-width="300">
      <v-card>
        <VfLoadingOverlayAbsolute :loading="dialogLoading" />
        <VfAlert v-model="errorMsg" type="error" />
        <validation-observer ref="observer">
          <v-form @submit.prevent="save">
            <v-card-text>
              <VfTextField
                v-model="targetFolder.name"
                :label="$$tm('folder.name')"
                :vees="vees.name"
              />
            </v-card-text>
            <v-card-actions>
              <VSpacer />
              <v-btn @click="formDialog = false">{{ $t('btn.cancel') }}</v-btn>
              <v-btn color="primary" type="submit">{{
                $t('btn.create')
              }}</v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="300">
      <v-card>
        <VfLoadingOverlayAbsolute :loading="dialogLoading" />
        <VfAlert v-model="errorMsg" type="error" />
        <v-card-title>
          {{ $t('confirm_title.delete') }}
        </v-card-title>
        <v-card-text>
          {{ $t('confirm.delete') }}
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="deleteDialog = false">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="error" @click="deleteFolder">{{
            $t('btn.delete')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable,
  },
  props: {
    folderType: { type: String, required: true },
    selectOnly: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
    dialogLoading: false,
    formDialog: false,
    deleteDialog: false,
    errorMsg: '',
    vees: {},
    selectedFolderId: '',
    reserveMoveId: '',
    targetFolder: {},
    folders: [],
    isFirst: true,
  }),
  watch: {
    selectedFolderId(val) {
      if (val !== 'add')
        if (this.isFirst) {
          this.isFirst = false
          return
        }
      this.$emit('select-folder', this.selectedFolderId)
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.errorMsg = ''
      const path = '/api/v1/folders'
      const prms = { folder_type: this.folderType }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.folders = res.data.folders
          this.vees = res.data.vees
          if (this.reserveMoveId) {
            this.selectedFolderId = this.reserveMoveId
            this.reserveMoveId = ''
          }
          this.$emit('pt-init-folders', this.folders)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async save() {
      this.errorMsg = ''
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.dialogLoading = true
      let path = '/api/v1/folders'
      let method = 'post'
      if (this.targetFolder.id) {
        path += `/${this.targetFolder.id}`
        method = 'put'
      }
      const prms = {
        name: this.targetFolder.name,
        folder_type: this.folderType,
      }
      await this.$$sendForm(method, path, this.$$reqPrms({ folder: prms }))
        .then((res) => {
          this.reserveMoveId = res.data.folder.id
          this.$toasted.success(this.$t('success.create'))
          this.init()
          this.formDialog = false
          this.$emit('pt-init-folders', this.folders)
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    async deleteFolder() {
      this.dialogLoading = true
      this.errorMsg = ''
      const path = `/api/v1/folders/${this.targetFolder.id}`
      const method = 'delete'
      await this.$$sendForm(method, path, this.$$reqPrms())
        .then(() => {
          this.reserveMoveId = this.folders[0].id
          this.$toasted.success(this.$t('success.delete'))
          this.init()
          this.deleteDialog = false
          this.$emit('pt-init-folders', this.folders)
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.dialogLoading = false
        })
    },
    async sortFolder(evt) {
      if (evt.oldIndex === evt.newIndex) {
        return
      }
      this.loading = true
      const path = `/api/v1/folders/${evt.item.dataset.folderId}/sort`
      const prms = { sort_no: evt.newIndex + 1 }
      const method = 'post'
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.sort'))
          this.init()
          this.$emit('pt-init-folders', this.folders)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    openDialogAdd() {
      this.targetFolder = {}
      this.openDialogForm()
    },
    openDialogEdit(folder) {
      this.targetFolder = this.$$clone(folder)
      this.openDialogForm()
    },
    openDialogForm() {
      if (this.$refs.observer) {
        this.$refs.observer.reset()
      }
      this.errorMsg = ''
      this.formDialog = true
    },
    openDialogDelete(folder) {
      this.targetFolder = folder
      this.errorMsg = ''
      this.deleteDialog = true
    },
    initFromParent() {
      this.isFirst = true
      this.init()
    },
  },
}
</script>
