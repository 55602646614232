<template>
  <div>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <validation-observer ref="searchTemplateObserver">
                  <VfSelect
                    v-model="selectedId"
                    :label="$$tm('search_template.name')"
                    :selects="selects"
                    :vees="{ rules: 'required' }"
                    readonly
                  />
                </validation-observer>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="4">
            <PtFolder
              ref="folder"
              folder-type="search_template"
              select-only
              @select-folder="selectFolder"
            />
          </v-col>
          <v-col md="8">
            <v-data-table
              v-model="activeTemplateClone"
              :headers="headers"
              :items="items"
              item-key="id"
              show-select
              :single-select="true"
              :disable-sort="true"
              :footer-props="{
                itemsPerPageOptions: [10, 30, 50, 100],
              }"
              @click:row="rowClickSearchTemplate"
            >
              <template #[`item.search_wrap`]="{ item }">
                <PtSearchSelectedList
                  v-model="item.search_wrap.as_json_form"
                  class="or-list"
                  disp-type="list"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PtFolder from 'components/parts/common/PtFolder'
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
export default {
  components: {
    PtFolder,
    PtSearchSelectedList,
  },
  props: {
    value: { default: null },
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    activeTemplateClone: [],
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    selectedId() {
      if (this.activeTemplateClone.length === 0) {
        return ''
      }
      this.internalValue = this.activeTemplateClone[0].search_wrap.as_json_form
      return this.activeTemplateClone[0].id
    },
    selects() {
      if (this.activeTemplateClone.length === 0) {
        return {}
      }
      this.internalValue = this.activeTemplateClone[0].search_wrap.as_json_form
      return {
        [this.activeTemplateClone[0].name]: this.activeTemplateClone[0].id,
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.initTable(null)
    },
    async initTable(folderId) {
      this.loading = true
      const path = '/api/v1/search_templates'
      const prms = { folder_id: folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.search_templates
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectFolder(folderId) {
      this.initTable(folderId)
    },
    rowClickSearchTemplate(item, opts) {
      opts.select(!opts.isSelected)
    },
  },
}
</script>
