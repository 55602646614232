var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('PtTitle')],1),_vm._v(" "),_c('v-col',{staticClass:"pt-8",attrs:{"cols":"4","align":"right"}},[_c('v-btn',{attrs:{"color":"primary","to":{ name: 'AdminPlanForm', params: { id: 'new' } }}},[_vm._v("\n        "+_vm._s(_vm.$t('btn.new_item'))+"\n      ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"or-break mb-4"},[_vm._v(_vm._s(_vm.$t('subtitle.admin_plan')))]),_vm._v(" "),_c('v-card',[_c('VfLoadingOverlayAbsolute',{attrs:{"loading":_vm.loading}}),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","disable-sort":true,"items-per-page":50,"footer-props":{
        itemsPerPageOptions: [50, 100, 150],
      }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'AdminPlanForm', params: { id: item.id } }}},[_vm._v("\n          "+_vm._s(item.name)+"\n        ")])]}},{key:"item.item_status",fn:function(ref){
      var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.$$te('plan.item_status', item))+"\n      ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.toRoleSetting(item)}}},[_vm._v("\n          "+_vm._s(_vm.$t('btn.plan_role_setting'))+"\n        ")]),_vm._v(" "),_c('btn-dialog-registor-url',{attrs:{"item":item}}),_vm._v(" "),(item.item_status !== 'active')?_c('btn-dialog-active',{on:{"save":function($event){return _vm.saveItemStatus(item, 'active')}}}):_vm._e(),_vm._v(" "),(item.item_status === 'active')?_c('btn-dialog-hidden',{on:{"save":function($event){return _vm.saveItemStatus(item, 'hidden')}}}):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }