<template>
  <div>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="$wait.is('processing')" />
      <v-card-title class="pa-0">
        <v-list-item>
          <v-list-item-content>
            {{ $t('lbl.friend.schedule') }}
          </v-list-item-content>
          <v-list-item-action v-if="hasRole">
            <v-wait for="processing">
              <PtDialogSelect
                v-model="schedule"
                select-type="schedule"
                @decide="save"
              >
                <template #opener>
                  <v-btn icon outlined>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <template #[`item.action_group`]="{ item }">
                  <PtActionLabelMessages
                    v-model="item.action_group.make_action_arr"
                    class="or-list"
                  />
                </template>
              </PtDialogSelect>
            </v-wait>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <VDivider class="my-0" />
      <v-card-text>
        <v-chip
          v-for="(schedule, schedule_idx) in internalValue"
          :key="schedule_idx"
          class="ma-2"
        >
          {{ schedule.name }}
        </v-chip>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  components: {
    PtDialogSelect,
    PtActionLabelMessages,
  },
  props: {
    value: { type: Array, required: true },
    friendId: { type: String, required: true },
  },
  data: () => ({
    errorMsg: '',
    schedule: {},
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    hasRole() {
      return this.$store.getters.roles.includes('friend_schedule')
    },
  },
  created() {
    this.schedule.schedules = this.$$clone(this.internalValue)
  },
  methods: {
    async save() {
      this.$wait.start('processing')
      const path = `/api/v1/friends/${this.friendId}/update_schedules`
      const prms = { schedules: this.schedule.schedules }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then((res) => {
          this.internalValue.splice(0)
          res.data.schedules.forEach((schedule) =>
            this.internalValue.push(schedule)
          )
          this.$toasted.success(this.$t('success.update'))
          this.dialog = false
        })
        .catch(() => {
          this.$toasted.error(this.$t('danger.error_occurred'))
        })
        .finally(() => {
          this.$wait.end('processing')
        })
    },
  },
}
</script>
