var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PtTitle'),_vm._v("\n  "+_vm._s(_vm.$t('subtitle.auto_answer'))+"\n  "),_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('PtFolder',{ref:"folder",attrs:{"folder-type":"auto_answer"},on:{"select-folder":_vm.selectFolder,"pt-init-folders":_vm.ptInitFolders}})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"9"}},[_c('v-card',{attrs:{"outlined":""}},[_c('VfLoadingOverlayAbsolute',{attrs:{"loading":_vm.loading}}),_vm._v(" "),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-col',{staticClass:"text-right",attrs:{"md":"6"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.checkedItems.length === 0}},'v-btn',attrs,false),on),[_vm._v("\n                    "+_vm._s(_vm.$t('btn.multi'))+"\n                    "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_vm._v(" "),_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.selectedActionConfirm('on')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('lbl.selected_on')))])],1),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.selectedActionConfirm('off')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('lbl.selected_off')))])],1),_vm._v(" "),_c('VDivider'),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){return _vm.selectedActionConfirm('delete')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('lbl.selected_delete')))])],1),_vm._v(" "),_c('VDivider'),_vm._v(" "),_vm._l((_vm.folders),function(folder,folder_idx){return _c('v-list-item',{key:folder_idx,on:{"click":function($event){return _vm.moveFolder(folder)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('lbl.move_to', { to: folder.name })))])],1)})],2)],1),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.newItem}},[_vm._v(_vm._s(_vm.$t('btn.new_item')))])],1)],1)],1),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","show-select":"","disable-sort":true,"items-per-page":50,"footer-props":{
            itemsPerPageOptions: [50, 100, 150],
          }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("\n              "+_vm._s(item.name)+"\n            ")])]}},{key:"item.active_flg",fn:function(ref){
          var item = ref.item;
return [(item.active_flg === 0)?_c('div',[_vm._v("無効")]):_c('div',[_vm._v("有効")])]}},{key:"item.auto_answer_reacts",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"or-list"},[(item.no_keyword_flg)?_c('div',[_vm._v("\n                "+_vm._s(_vm.$$tm('auto_answer.no_keyword_flg'))+"\n              ")]):_c('div',[_vm._v("\n                "+_vm._s(_vm.$$te('auto_answer.keyword_match_type', item))+"\n                "),_vm._l((item.auto_answer_reacts),function(react,i){return _c('div',{key:i},[_vm._v("\n                  ["+_vm._s(react.keyword)+"]\n                ")])})],2)])]}},{key:"item.action_group",fn:function(ref){
          var item = ref.item;
return [_c('PtActionLabelMessages',{staticClass:"or-list",model:{value:(item.action_group.make_action_arr),callback:function ($$v) {_vm.$set(item.action_group, "make_action_arr", $$v)},expression:"item.action_group.make_action_arr"}})]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.$$fdt(item.created_at))+"\n          ")]}}],null,true),model:{value:(_vm.checkedItems),callback:function ($$v) {_vm.checkedItems=$$v},expression:"checkedItems"}})],1)],1)],1),_vm._v(" "),_c('VfDialogConfirm',{attrs:{"title":_vm.$t('confirm_title.delete'),"detail":_vm.$t('confirm.delete'),"save-path":_vm.savePath,"form-type":"delete","target-emit":"delete-success"},on:{"delete-success":_vm.deleteSuccess},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}}),_vm._v(" "),_c('PtDialogLinePreview',{key:_vm.selectedItem.id,attrs:{"type":"auto_answer","model-id":_vm.selectedItem.id},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.commonDialog),callback:function ($$v) {_vm.commonDialog=$$v},expression:"commonDialog"}},[_c('v-card',[_c('VfLoadingOverlayAbsolute',{attrs:{"loading":_vm.commonDialogLoading}}),_vm._v(" "),_c('v-card-text',{staticClass:"pt-6"},[_vm._v("\n        "+_vm._s(_vm.$t(("lbl.confirm_selected_" + _vm.selectedAction)))+"\n      ")]),_vm._v(" "),_c('v-card-actions',[_c('VSpacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){_vm.commonDialog = false}}},[_vm._v(_vm._s(_vm.$t('btn.cancel')))]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectedSave(_vm.selectedAction)}}},[_vm._v(_vm._s(_vm.$t('btn.action')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }