import VueToasted from 'vue-toasted'

const Toasted = {
  install(Vue) {
    Vue.use(VueToasted, {
      position: 'top-right',
      duration: 2000,
    })
  },
}

export default Toasted
