<template>
  <div>
    <v-card class="mx-auto px-12 py-6" max-width="800">
      <v-card-title class="mb-4">
        <v-row justify="center">
          <div>{{ $t(`meta.title.${$route.name}`) }}</div>
        </v-row>
      </v-card-title>
      <v-card class="mb-4">
        <v-card-title>
          {{ $t('lbl.set_webhook') }}
        </v-card-title>
        <VDivider class="my-0" />
        <v-card-text>
          <div class="or-break">{{ $t('lbl.set_webhook_desc') }}</div>
          <PtWebhookCopy v-if="channel.id" :channel_id="channel.id" />
        </v-card-text>
      </v-card>
      <v-card class="mb-4">
        <v-card-title>
          {{ $t('lbl.set_response') }}
        </v-card-title>
        <VDivider class="my-0" />
        <v-card-text>
          <div class="or-break">{{ $t('lbl.set_response_desc') }}</div>
          <v-card class="mt-4">
            <VImg
              src="/cstms/line_response_setting.png"
              alt="response setting"
            />
          </v-card>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <VfCheckbox
            v-model="next_ready"
            :label="$t('lbl.done_webhook_and_response')"
          />
          <v-btn
            color="primary"
            :disabled="!next_ready"
            @click="$router.push({ name: 'RegistrationLoginChannel' })"
          >
            {{ $t('btn.next') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import PtWebhookCopy from 'components/parts/common/PtWebhookCopy'
export default {
  components: {
    PtWebhookCopy,
  },
  data: () => ({
    channel: {},
    next_ready: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/channel/registration_webhook`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.channel = res.data.channel
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
