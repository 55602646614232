<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <div class="mb-4">
          <v-btn icon class="float-left mt-2" @click="dialog = true">
            <v-icon>far fa-grin</v-icon>
          </v-btn>
          <v-btn
            v-for="(key, index) in Object.keys(variables)"
            :key="index"
            class="ma-2"
            @click="insertVariable(key)"
          >
            {{ variables[key] }}
          </v-btn>
          <v-btn class="ma-2" @click="openDateSetting">
            {{ $t('btn.deli_text.date') }}
          </v-btn>

          <div>
            <v-row
              no-gutters
              v-show="
                showTimeSetting &&
                (showDateSetting[position] !== undefined
                  ? showDateSetting[position]
                  : dateSettingDeliText[position])
              "
            >
              <v-col cols="4" md="1" class="mb-3 text-center text-show"
                >配信日から</v-col
              >
              <v-col cols="10" md="1" class="date-box">
                <v-text-field
                  v-model="days"
                  outlined
                  class="qualityField"
                  type="text"
                  @change="formatDays()"
                  @keypress="onlyNumber"
                  @copy.prevent
                  @paste.prevent
                >
                  <template #prepend>
                    <v-btn
                      class="minusBtn"
                      min-width="0"
                      width="32"
                      height="auto"
                      @click="onDecrement"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </template>

                  <template #append-outer>
                    <v-btn
                      class="plusBtn"
                      min-width="0"
                      width="32"
                      height="auto"
                      @click="onIncreament"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="5" md="1" class="text-show text-center"
                >日後の日付</v-col
              >
              <v-col cols="4" md="1">
                <v-btn
                  class="ma-2"
                  :disabled="isNaN(days)"
                  @click="insertDateValue()"
                >
                  挿入
                </v-btn>
              </v-col>
            </v-row>
          </div>

          <div
            cols="12"
            v-show="
              errMessageText[position] !== undefined
                ? errMessageText[position]
                : errMessageDeliText[position]
            "
            class="red--text mb-4"
          >
            <span>
              {{
                errMessageText[position] !== undefined
                  ? errMessageText[position]
                  : errMessageDeliText[position]
              }}
            </span>
          </div>
        </div>
        <VfTextArea
          v-model="internalValue.text"
          :label="$$tm('deli_text.text')"
          :vees="vees.text"
          counter
          ref="textArea"
        />
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" max-width="340">
      <v-card>
        <PtDeliEmoji @insertEmoji="insertEmoji" />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtDeliEmoji from 'components/parts/common/PtDeli/PtDeliEmoji'
export default {
  components: {
    PtDeliEmoji,
  },
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    flagDialogUnicast: { type: Boolean, default: false },
    position: { type: Number, default: 0 },
    errMessageText: { type: Array, default: () => [] },
    showDateSetting: { type: Array, default: () => [] },
  },
  data: () => ({
    dialog: false,
    emoji: '',
    variables: { name: '名前' },
    days: 0,
    showTimeSetting: false,
    errMessageDeliText: [],
    dateSettingDeliText: [],
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    days(value) {
      this.days = this.formatDays(value)
    },
  },
  methods: {
    onDecrement() {
      isNaN(this.days) ? (this.days = -1) : this.days--
    },
    onIncreament() {
      isNaN(this.days) ? (this.days = 1) : this.days++
    },
    insertEmoji(emoji) {
      this.emoji = emoji
      if (this.internalValue.text == null) {
        this.internalValue.text = this.emoji.native
      } else {
        this.internalValue.text = this.internalValue.text + this.emoji.native
      }
    },
    showNoteMessage() {
      let messageText = `自動挿入された「$$$name$$$」や「$$$today$$$」を編集した場合は正常に配信できない可能性があります。`

      if (this.flagDialogUnicast) {
        this.$emit('add-err-text', this.position, messageText)
      } else {
        this.errMessageDeliText[this.position] = messageText
      }
    },
    insertVariable(variable) {
      this.showTimeSetting = false
      this.dateSettingDeliText[this.position] = false
      this.showNoteMessage()
      variable = `$$$${variable}$$$`
      this.processInsertVariable(variable)
    },
    insertDateValue() {
      let variable =
        this.days > 0
          ? `$$$today+${Math.abs(this.days).toString()}$$$`
          : `$$$today-${Math.abs(this.days).toString()}$$$`
      if ([0, '-', ''].includes(this.days)) {
        variable = '$$$today$$$'
      }

      this.processInsertVariable(variable)
    },
    processInsertVariable(variable) {
      const cursorPos =
        this.$refs.textArea.$el.querySelector('textarea').selectionEnd
      this.internalValue.text =
        this.internalValue.text == null
          ? variable
          : this.internalValue.text.substring(0, cursorPos) +
            variable +
            this.internalValue.text.substring(cursorPos)
    },
    openDateSetting() {
      this.showTimeSetting = true
      this.days = 0
      this.showNoteMessage()

      if (this.flagDialogUnicast) {
        this.$emit('add-open-data-setting', this.position)
      } else {
        this.dateSettingDeliText[this.position] = true
      }
      this.processInsertVariable('')
    },
    formatDays(days) {
      if (['', '-'].includes(days)) return days
      return Math.round(days)
    },
    onlyNumber(e) {
      const keyCode = e.keyCode || e.which
      if (![8, 0].includes(keyCode) && (keyCode < 48 || keyCode > 57)) {
        e.preventDefault()
      }
    },
  },
}
</script>
<style scoped lang="scss">
.text-show {
  line-height: 4;
}
.date-box {
  display: inline-table;
  ::v-deep .qualityField {
    width: 160px;

    .v-text-field__details {
      display: none;
    }

    .v-text-field__slot input {
      text-align: center;
    }
  }
}
</style>
