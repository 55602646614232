<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn
            v-if="reminderId !== 'new'"
            color="error"
            :disabled="frem_active_flg"
            @click="destroy"
            >{{ $t('btn.delete') }}</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-form @submit.prevent="save">
        <v-card class="mb-6">
          <v-card-title class="or-card-title mb-6">
            {{ $t('lbl.reminder.info') }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6">
                <VfSelect
                  v-model="reminder.folder_id"
                  :label="$$tm('folder.name')"
                  :vees="vees.folder_id"
                  :selects="folders"
                />
              </v-col>
              <v-col md="6">
                <VfTextField
                  v-model="reminder.name"
                  :label="$$tm('reminder.name')"
                  :vees="vees.name"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <VSpacer />
            <v-btn @click="$router.push({ name: 'ReminderIndex' })">{{
              $t('btn.back')
            }}</v-btn>
            <v-btn type="submit" color="primary">{{ $t('btn.create') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
    <PtReminderTimingIndex
      v-if="reminderId !== 'new' && initDone"
      :reminder-id="reminderId"
      :frem-active-flg="frem_active_flg"
    />
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtReminderTimingIndex from 'components/parts/unique/PtReminderTimingIndex'
export default {
  components: {
    PtReminderTimingIndex,
  },
  data: () => ({
    initDone: false,
    reminderId: '',
    reminder: {},
    frem_active_flg: false,
    vees: {},
    folders: [],
    opts: {},
    savePath: '',
    deleteDialog: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.reminderId = this.$route.params.id
      const path =
        this.reminderId === 'new'
          ? '/api/v1/reminders/new'
          : `/api/v1/reminders/${this.reminderId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.reminder = res.data.reminder
          this.frem_active_flg = res.data.frem_active_flg
          this.vees = res.data.vees
          this.folders = res.data.folders
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.reminderId === 'new'
          ? '/api/v1/reminders'
          : `/api/v1/reminders/${this.reminderId}`
      const method = this.reminderId === 'new' ? 'post' : 'put'
      const prms = { reminder: this.reminder }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then((res) => {
          this.$toasted.success(this.$t('success.create'))
          this.$router
            .push({
              name: 'ReminderForm',
              params: { id: res.data.reminder.id },
            })
            .catch((err) => {})
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    destroy() {
      this.savePath = `/api/v1/reminders/${this.reminderId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'ReminderIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
