<template>
  <div>
    <v-row
      v-if="getDate(deli.created_at) != getDate(deli.prev_created_at)"
      justify="center"
    >
      <p class="date">{{ getDate(deli.created_at) }}</p>
    </v-row>
    <v-row :justify="justify" :class="direction">
      <div>
        <p class="sent_time">{{ getTime(deli.created_at) }}</p>
        <div v-if="messageType === 'receive'">
          <div v-if="showableStatus">
            <v-chip
              dark
              label
              :color="colors[messageStatus]"
              @click="openDialogMessageStatus(messageId, messageStatus)"
            >
              {{ $t(`enum.friend.message_status.${messageStatus}`) }}
            </v-chip>
          </div>
          <v-chip class="ma-2" x-small label v-else>既読</v-chip>
        </div>
      </div>

      <div
        v-if="deli.deli_type === 'text' && deli.deli_text.text"
        class="speech-bubble"
        style="max-width: 300px; margin-bottom: 24px"
      >
        <template v-if="messageType === 'send'">
          <div
            :class="[
              'sb-bubble',
              'sb-line2',
              'sb-right',
              'black--text',
              { 'is-broadcast': isBroadcast },
            ]"
          >
            <div class="or-break">{{ deli.deli_text.text }}</div>
          </div>
        </template>
        <template v-else-if="messageType === 'receive'">
          <div
            :class="[
              'sb-bubble',
              'sb-line2',
              'sb-left',
              'black--text',
              { 'is-broadcast': isBroadcast },
            ]"
          >
            <div class="or-break">{{ deli.deli_text.text }}</div>
          </div>
        </template>
        <template v-else>
          <v-card color="grey lighten-3 mx-8">
            <v-card-text class="text-center py-0">
              <div class="or-break">{{ deli.deli_text.text }}</div>
            </v-card-text>
          </v-card>
        </template>
      </div>
      <div v-if="deli.deli_type === 'sticker' && deli.deli_sticker.sticker_id">
        <img
          class="sticker-img"
          :src="stickerImg(deli.deli_sticker.sticker_id)"
        />
      </div>
      <div
        v-if="
          deli.deli_type === 'image' &&
          deli.deli_image &&
          deli.deli_image.preview_url
        "
      >
        <PtImage :deli-image="deli.deli_image" />
      </div>
      <div
        v-if="
          deli.deli_type === 'video' &&
          deli.deli_video &&
          deli.deli_video.video_url
        "
      >
        <PtVideo
          :key="deli.deli_video.video_url"
          :src="deli.deli_video.video_url"
          :width="200"
          :height="200"
        />
      </div>
      <div
        v-if="
          deli.deli_type === 'audio' &&
          deli.deli_audio &&
          deli.deli_audio.audio_url
        "
      >
        <PtVideo
          :key="deli.deli_audio.audio_url"
          :src="deli.deli_audio.audio_url"
          :width="200"
          :height="100"
        />
      </div>
      <div v-if="deli.deli_type === 'question' && deli.deli_question.title">
        <v-card :width="maxWidth">
          <v-card-text>
            {{ deli.deli_question.title }}
          </v-card-text>
          <v-row no-gutters class="text-center">
            <v-col
              v-for="(choice, choice_idx) in deli.deli_question
                .deli_question_choices"
              :key="choice_idx"
            >
              <v-card class="px-2 py-4 h-100">
                {{ choice.choice_name }}
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div
        v-if="
          deli.deli_type === 'carousel' &&
          deli.deli_carousel.deli_carousel_panels
        "
        style="overflow: scroll; padding: 30px"
      >
        <v-row
          justify="start"
          style="flex-shrink: 0; width: max-content"
          class="pl-4 pr-4"
        >
          <div
            v-for="(panel, panel_idx) in deli.deli_carousel
              .deli_carousel_panels"
            :key="panel_idx"
            class="ml-3"
          >
            <v-card :width="150">
              <VImg v-if="panel.upload_file_id" :src="panel.image_url" />
              <v-card-text>
                <div class="font-weight-bold">{{ panel.title }}</div>
                <div>{{ panel.text }}</div>
              </v-card-text>
              <v-row no-gutters class="text-center">
                <v-col
                  v-for="(
                    choice, choice_idx
                  ) in panel.deli_carousel_panel_choices"
                  :key="choice_idx"
                  cols="12"
                >
                  <v-card class="px-2 py-4 h-100">
                    {{ choice.choice_name }}
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-row>
      </div>
      <div v-if="deli.deli_type === 'location' && deli.deli_location.title">
        <v-card :width="200">
          <GmapMap
            ref="map"
            :center="{
              lat: Number(deli.deli_location.latitude),
              lng: Number(deli.deli_location.longitude),
            }"
            :zoom="14"
            :options="{
              mapTypeControl: false,
              streetViewControl: false,
            }"
            map-type-id="terrain"
            style="width: 200px; height: 200px"
          >
            <GmapMarker
              :position="{
                lat: Number(deli.deli_location.latitude),
                lng: Number(deli.deli_location.longitude),
              }"
              :draggable="false"
            />
          </GmapMap>
          <v-card-text>
            <div class="font-weight-bold">{{ deli.deli_location.title }}</div>
            <div class="or-break">{{ deli.deli_location.address }}</div>
          </v-card-text>
        </v-card>
      </div>
      <div
        v-if="deli.deli_type === 'introduction' && deli.deli_introduction.title"
      >
        <v-card :width="maxWidth">
          <v-card-text>
            <div class="font-weight-bold">
              {{ deli.deli_introduction.title }}
            </div>
          </v-card-text>
          <v-row no-gutters class="text-center">
            <v-col>
              <v-card class="px-2 py-4 h-100">
                {{ $t('btn.add_friend_message') }}
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div
        v-if="
          deli.deli_type === 'file' && deli.deli_file && deli.deli_file.file_url
        "
      >
        <v-card :width="300">
          <v-card-subtitle class="py-0">
            <v-row class="align-center">
              <v-col md="2">
                <v-row class="justify-center">
                  <v-icon color="red">mdi-file-pdf-box</v-icon>
                </v-row>
              </v-col>
              <v-col md="10">
                <div>
                  <a :href="deli.deli_file.file_url" target="_blank">{{
                    deli.deli_file.file_name
                  }}</a>
                </div>
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
      </div>
    </v-row>
  </div>
</template>
<script>
import PtVideo from 'components/parts/common/PtVideo'
import PtImage from 'components/parts/common/PtImage'
export default {
  components: {
    PtVideo,
    PtImage,
  },
  props: {
    deli: { type: Object, default: () => ({}) },
    message: { type: Object, default: () => ({}) },
    messageType: { type: String, default: 'send' },
    messageId: { type: String },
    messageStatus: { type: String },
    showableStatus: { type: Boolean, default: false },
    actionType: { type: String, default: 'unicast' },
  },
  data: () => ({
    maxWidth: 300,
    colors: {
      unread: 'primary',
      magazine_comment: 'primary',
      low_important: 'primary',
      done: 'default',
      claim: 'red',
      closing: 'red',
      trouble: 'red',
      question: 'red',
    },
  }),
  computed: {
    isBroadcast() {
      //FIXME: confirm value with API
      return this.actionType === 'broadcast'
    },
    stickerImg: function () {
      return function (stickerId) {
        return `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerId}/iPhone/sticker_key@2x.png`
      }
    },
    justify() {
      switch (this.messageType) {
        case 'receive':
          return 'end'
        case 'system':
          return 'center'
        case 'send':
          return 'end'
      }
    },
    direction() {
      switch (this.messageType) {
        case 'receive':
          return 'flex-row-reverse'
        case 'system':
          return ''
        case 'send':
          return ''
      }
    },
    getTime: function () {
      return function (created_at) {
        if (/\d{2}:\d{2}/.exec(created_at)) {
          return /\d{2}:\d{2}/.exec(created_at)[0]
        } else {
          return ''
        }
      }
    },
    getDate: function () {
      return function (created_at) {
        if (/\d{4}-\d{2}-\d{2}/.exec(created_at)) {
          return this.$$fdt(created_at, 'llll').slice(0, -6)
        } else {
          return ''
        }
      }
    },
  },
  methods: {
    openDialogMessageStatus(messageId, messageStatus) {
      this.$emit('open-dialog-message-status', messageId, messageStatus)
    },
  },
}
</script>
<style scoped>
.sent_time {
  font-size: 10px;
  margin: 0 5px;
  text-align: center;
}
.date {
  background-color: rgb(240, 240, 240);
  font-size: 12px;
  padding: 3px 7px;
  border-radius: 10px;
}
.sticker-img {
  width: 200px;
  height: 200px;
}
</style>
