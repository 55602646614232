<template>
  <div id="home">
    <div class="text-h5 ma-6 green--text text--darken-1 font-weight-black">
      {{ titleDisp }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: '' },
  },
  computed: {
    titleDisp() {
      return this.title || this.$t(`meta.title.${this.$route.name}`)
    },
  },
}
</script>
