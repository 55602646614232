import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  localize,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import ja from 'vee-validate/dist/locale/ja.json'
import i18n from 'plugins/vue-i18n'

const VeeValidate = {
  install(Vue) {
    setInteractionMode('eager')
    localize('ja', ja)

    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)

    // add rules
    for (let rule in rules) {
      extend(rule, {
        ...rules[rule],
        message: ja.messages[rule],
      })
    }

    // add custom rules
    const zen_hira = {
      message: `{_field_}${i18n.tc('validation.zen_hira')}`,
      validate(value) {
        return value.match(/^[ぁ-んー－]+$/)
      },
    }
    extend('zen_hira', zen_hira)

    const kana = {
      message: `{_field_}${i18n.tc('validation.kana')}`,
      validate(value) {
        return value.match(/^[ぁ-んァ-ン]+$/)
      },
    }
    extend('kana', kana)

    const line_account_id = {
      message: `{_field_}${i18n.tc('validation.line_account_id')}`,
      validate(value) {
        return value.match(/^@[a-z0-9\-._]*$/)
      },
    }
    extend('line_account_id', line_account_id)

    const tel = {
      message: `{_field_}${i18n.tc('validation.tel')}`,
      validate(value) {
        return value.match(/^[0-9-]{7,15}$/)
      },
    }
    extend('tel', tel)

    const zip_code = {
      message: `{_field_}${i18n.tc('validation.zip_code')}`,
      validate(value) {
        return value.match(/^[0-9]{3}(?:-?[0-9]{4})?$/)
      },
    }
    extend('zip_code', zip_code)

    const url = {
      validate(value) {
        if (value) {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          )
        }
      },
      message: `{_field_}${i18n.tc('validation.url')}`,
    }
    extend('url', url)

    extend('number_of_url', {
      validate(value, { max }) {
        if (value) {
          return (value.match(/https?:\/\/[^\s]*/g) || []).length <= Number(max)
        }
      },
      params: ['max'],
      message: `${i18n.tc('validation.number_of_url')}`,
    })

    extend('length_of_url', {
      validate(value, { max }) {
        if (value) {
          return !(value.match(/https?:\/\/[^\s]*/g) || []).find(
            (o) => o.length > Number(max)
          )
        }
      },
      params: ['max'],
      message: `${i18n.tc('validation.length_of_url')}`,
    })

    extend('max_select', {
      validate: (value, { max }) => {
        return Array.isArray(value) ? value.length <= Number(max) : true
      },
      params: ['max'],
      message: `{_field_}{max}${i18n.tc('validation.max_select')}`,
    })

    extend('min_select', {
      validate: (value, { min }) => {
        return Array.isArray(value) ? value.length >= Number(min) : true
      },
      params: ['min'],
      message: `{_field_}{min}${i18n.tc('validation.min_select')}`,
    })

    extend('compare_max', {
      validate: (value, { max }) => {
        return value > max ? false : true
      },
      params: ['max'],
      message: `{_max_}${i18n.tc('validation.compare_max')}`,
    })

    extend('compare_min', {
      validate: (value, { min }) => {
        return value < min ? false : true
      },
      params: ['min'],
      message: `{_min_}${i18n.tc('validation.compare_min')}`,
    })
  },
}

export default VeeValidate

export function validateStartEndTime(range) {
  return Date.parse(range.range_st_dt) <= Date.parse(range.range_ed_dt)
}

export function validateSearchComponent(search) {
  switch (search.type) {
    case 'name':
      if (
        !(search.data.api_display_name_flg || search.data.system_disp_name_flg)
      )
        return i18n.tc('validation.search_name_empty')
      break
    case 'message_status':
      if (search.data.target_message_statuses.length < 1)
        return i18n.tc('validation.message_status_empty')
      break
    case 'last_response':
    case 'friend_created_at':
      if (!validateStartEndTime(search.data))
        return i18n.tc('validation.over_start_time')
      break
    default:
      return undefined
  }
}
