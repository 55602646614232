<template>
  <file-pond
    ref="pond"
    :name="name"
    :allow-revert="false"
    :max-files="maxFiles"
    :max-file-size="maxFileSize"
    :class-name="className"
    :files="defaultFiles"
    :server="server"
    :instant-upload="instantUpload"
    :allow-multiple="allowMultiple"
    :allow-reorder="allowReorder"
    :disabled="disabled"
    :accepted-file-types="acceptedFileTypes"
    :max-parallel-uploads="maxParallelUploads"
    :image-resize-target-width="imageResizeTargetWidth"
    :image-resize-target-height="imageResizeTargetHeight"
    :allow-image-preview="allowImagePreview"
    :allow-image-edit="allowImageEdit"
    :image-edit-instant-edit="imageEditInstantEdit"
    label-idle="ここにファイルをドロップするか、ファイルを選択してください。"
    @processfile="processfile"
  />
</template>
<script>
export default {
  props: {
    name: { type: String, required: true },
    cols: { type: Number, default: 1 },
    initPath: { type: String, required: true },
    initPrms: { type: Object, default: () => ({}) },
    instantUpload: { type: Boolean, default: true },
    allowMultiple: { type: Boolean, default: true },
    allowReorder: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    maxFiles: { type: Number, default: 4 },
    maxFileSize: { type: String, default: '10MB' },
    maxParallelUploads: { type: Number, default: 4 },
    acceptedFileTypes: { type: String, default: '' },
    imageResizeTargetWidth: { default: null },
    imageResizeTargetHeight: { default: null },
    allowImagePreview: { type: Boolean, default: true },
    allowImageEdit: { type: Boolean, default: false },
    imageEditInstantEdit: { type: Boolean, default: false },
  },
  data: () => ({
    files: [],
    dispFiles: [],
    server: {},
    lastUploadModel: '',
  }),
  computed: {
    className() {
      return `filepond-col${this.cols}`
    },
  },
  watch: {
    initPrms() {
      this.init()
    },
  },
  created: async function () {
    this.init()
  },
  methods: {
    async init() {
      this.files = []
      this.dispFiles = []
      this.server = {}
      this.initServer()
      this.defaultFiles = []
    },
    initServer() {
      this.server = {
        url: this.initPath,
        process: (fieldName, file, metadata, load, error, progress) => {
          const formData = new FormData()
          formData.append(fieldName, file, file.name)
          for (let [k, v] of Object.entries(this.$$reqPrms(this.initPrms))) {
            formData.append(k, v)
          }
          const path = this.initPath
          this.axios
            .post(path, formData, {
              onUploadProgress: (e) => {
                progress(e.lengthComputable, e.loaded, e.total)
              },
            })
            .then((res) => {
              this.lastUploadModel = res.data
              load()
            })
            .catch((error) => {
              this.$$ajaxError(error)
            })
        },
        load: (source, load) => {
          fetch(source)
            .then((res) => res.blob())
            .then(load)
        },
        remove: (source, load) => {
          const file = this.files.find((f) => f.source === source)
          if (!file) {
            load()
            return
          }
          const path = `${this.initPath}/${file.options.key}`
          this.axios
            .delete(path, { params: this.$$reqPrms(this.initPrms) })
            .then(() => {
              this.files = this.files.filter((f) => f !== file)
              load()
              this.$emit('remove-success')
            })
            .catch((error) => {
              this.$$ajaxError(error)
            })
            .finally(() => {})
        },
        fetch: null,
        restore: null,
        revert: null,
      }
    },
    reflesh() {
      const checkArray = [2, 5]
      const refleshFlg = !this.$refs.pond
        .getFiles()
        .some((file) => !checkArray.includes(file.status))
      if (refleshFlg) {
        this.init()
      }
    },
    processfile() {
      this.$emit('upload-success', this.lastUploadModel)
      this.reflesh()
    },
  },
}
</script>
