<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-card class="py-4">
          <div class="text-center red--text">{{ $t('lbl.caution_panel') }}</div>
        </v-card>
        <v-card>
          <v-chip
            v-for="(panel, panel_i) in internalValue.deli_carousel_panels"
            :key="panel_i"
            class="ma-2"
            color="orange"
            dark
            @click="selectPanel(panel)"
          >
            {{ $t('lbl.panel') }} {{ panel_i + 1 }}
            <v-icon v-if="panel_i > 0" right @click="removePanel(panel_i)"
              >mdi-close-circle</v-icon
            >
          </v-chip>
          <v-chip
            v-if="canAddPanel"
            class="ma-2"
            color="primary"
            dark
            @click="addPanel"
          >
            {{ $t('btn.add') }}
            <v-icon right>mdi-plus-circle</v-icon>
          </v-chip>
          <div class="pl-2 pb-2 red--text">
            {{ $t('lbl.caution_panel_count') }}
          </div>
        </v-card>
        <div
          v-for="(panel, panel_i) in internalValue.deli_carousel_panels"
          :key="panel_i"
        >
          <div v-show="selectedPanel == panel">
            <v-card class="mb-8">
              <v-card-title>
                {{ $t('lbl.panel') }} {{ panel_i + 1 }}
              </v-card-title>
              <v-card-text>
                <VfTextField
                  v-model="panel.title"
                  :label="$$tm('deli_carousel_panel.title')"
                  :vees="veesPanel.title"
                  counter
                />
                <VfTextField
                  v-model="panel.text"
                  :label="$$tm('deli_carousel_panel.text')"
                  :vees="veesPanel.text"
                  counter
                />
                <v-row>
                  <v-col md="6">
                    <VImg :src="panel.image_url" :height="200" :width="200" />
                  </v-col>
                  <v-col md="6">
                    <div class="mb-4">
                      <v-btn @click="openDialog(panel)">
                        {{ $t('btn.select_image') }}
                      </v-btn>
                    </div>
                    <div v-if="panel.upload_file_id">
                      <v-btn @click="removeImage(panel)">
                        {{ $t('btn.remove_image') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card>
              <v-chip
                v-for="(choice, choice_i) in panel.deli_carousel_panel_choices"
                :key="choice_i"
                class="ma-2"
                color="orange"
                dark
                @click="selectChoice(choice)"
              >
                {{ $t('lbl.choice') }} {{ choice_i + 1 }}
                <v-icon
                  v-if="choice_i > 0"
                  right
                  @click="removeChoice(choice_i)"
                  >mdi-close-circle</v-icon
                >
              </v-chip>
              <v-chip
                v-if="canAddChoice"
                class="ma-2"
                color="primary"
                dark
                @click="addChoice"
              >
                {{ $t('btn.add') }}
                <v-icon right>mdi-plus-circle</v-icon>
              </v-chip>
              <div class="pl-2 pb-2 red--text">
                {{ $t('lbl.caution_panel_choice_count') }}
              </div>
            </v-card>
            <div
              v-for="(choice, choice_i) in panel.deli_carousel_panel_choices"
              :key="choice_i"
            >
              <div v-show="selectedChoice == choice">
                <v-card>
                  <v-card-title>
                    {{ $t('lbl.choice') }} {{ choice_i + 1 }}
                  </v-card-title>
                  <v-card-text>
                    <VfTextField
                      v-model="choice.choice_name"
                      :label="$$tm('deli_carousel_panel_choice.choice_name')"
                      :vees="veesChoice.choice_name"
                      counter
                    />
                    <VfSelect
                      v-model="choice.choice_action_type"
                      :label="
                        $$tm('deli_carousel_panel_choice.choice_action_type')
                      "
                      :vees="veesChoice.choice_action_type"
                      :selects="choiceActionTypes"
                    />
                    <VfTextField
                      v-if="
                        choice.choice_action_type !== 'action' &&
                        choice.choice_action_type !== 'none'
                      "
                      :key="choice.choice_action_type"
                      v-model="choice.multi_text"
                      :label="$t(`lbl.${choice.choice_action_type}_text`)"
                      :vees="multiTextValidates[choice.choice_action_type]"
                    />
                    <v-card-text v-if="choice.choice_action_type === 'action'">
                      <PtActionSelectedList
                        v-model="choice.action_arr"
                        :msg-hidden-flg="true"
                      />
                    </v-card-text>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <VfTextArea
            v-model="internalValue.message_for_pc"
            :label="$$tm('deli_question.message_for_pc')"
            :vees="vees.message_for_pc"
            counter
          />
        </div>
      </v-card-text>
    </v-card>
    <PtDeliDialogForSelectFile
      v-model="dialog"
      upload-file-type="image"
      @select-file-done="selectFileDone"
    />
  </div>
</template>
<script>
import PtDeliDialogForSelectFile from 'components/parts/common/PtDeli/PtDeliDialogForSelectFile'
export default {
  components: {
    PtDeliDialogForSelectFile,
    PtActionSelectedList: () =>
      import('components/parts/common/PtActionGroup/PtActionSelectedList.vue'),
  },
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    veesPanel: {
      type: Object,
      default: function () {
        return {}
      },
    },
    veesChoice: {
      type: Object,
      default: function () {
        return {}
      },
    },
    choiceActionTypes: {
      type: Object,
      default: function () {
        return {}
      },
    },
    urlOpenTypes: {
      type: Object,
      default: function () {
        return {}
      },
    },
    multiTextValidates: {
      type: Object,
      default: function () {
        return {}
      },
    },
    initCarouselPanel: {
      type: Object,
      default: function () {
        return {}
      },
    },
    initCarouselPanelChoice: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    dialog: false,
    selectedPanel: {},
    selectedChoice: {},
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    canAddPanel() {
      if (this.selectedPanel.deli_carousel_panel_choices.length >= 4) {
        return false
      } else {
        return this.internalValue.deli_carousel_panels.length < 5
      }
    },
    canAddChoice() {
      if (this.internalValue.deli_carousel_panels.length > 1) {
        return this.selectedPanel.deli_carousel_panel_choices.length < 3
      } else {
        return this.selectedPanel.deli_carousel_panel_choices.length < 4
      }
    },
    panelChoiceCount() {
      return this.selectedPanel.deli_carousel_panel_choices.length
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.selectFirstPanel()
    },
    removeImage(panel) {
      panel.upload_file_id = null
      panel.image_url = this.$t('image.noimage')
    },
    openDialog(panel) {
      this.selectedPanel = panel
      this.dialog = true
    },
    selectFileDone(uploadFile) {
      this.selectedPanel.upload_file_id = uploadFile.id
      this.selectedPanel.image_url = uploadFile.image_url
      this.dialog = false
    },
    selectPanel(panel) {
      this.selectedPanel = panel
      this.selectFirstChoice()
    },
    selectFirstPanel() {
      this.selectPanel(this.internalValue.deli_carousel_panels[0])
    },
    addPanel() {
      const panel = this.$$clone(this.initCarouselPanel)
      for (let i = 0; i < this.panelChoiceCount; i++) {
        this.targetPanelAddChoice(panel)
      }
      this.internalValue.deli_carousel_panels.push(panel)
      this.selectPanel(panel)
    },
    removePanel(panel_i) {
      const currentIndex = this.internalValue.deli_carousel_panels.findIndex(
        (p) => p == this.selectedPanel
      )
      if (currentIndex === panel_i) {
        this.selectFirstPanel()
      }
      this.internalValue.deli_carousel_panels.splice(panel_i, 1)
    },
    selectChoice(choice) {
      choice.action_arr = choice.action_arr ? choice.action_arr : []
      this.selectedChoice = choice
    },
    selectFirstChoice() {
      this.selectChoice(this.selectedPanel.deli_carousel_panel_choices[0])
    },
    addChoice() {
      this.internalValue.deli_carousel_panels.forEach((panel) => {
        const choice = this.targetPanelAddChoice(panel)
        if (this.selectedPanel == panel) {
          this.selectChoice(choice)
        }
      })
    },
    targetPanelAddChoice(panel) {
      const choice = this.$$clone(this.initCarouselPanelChoice)
      choice.action_arr = []
      panel.deli_carousel_panel_choices.push(choice)
      return choice
    },
    removeChoice(choice_i) {
      const currentIndex =
        this.selectedPanel.deli_carousel_panel_choices.findIndex(
          (p) => p == this.selectedChoice
        )
      if (currentIndex === choice_i) {
        this.selectFirstChoice()
      }
      this.internalValue.deli_carousel_panels.forEach((panel) => {
        panel.deli_carousel_panel_choices.splice(choice_i, 1)
      })
    },
  },
}
</script>
