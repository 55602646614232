<template>
  <div>
    <PtTitle />
    <v-row>
      <v-col cols="12">
        <PtAdminInfo />
      </v-col>
      <v-col cols="12" md="6">
        <PtFriendReportCurrent />
        <PtSendCount />
      </v-col>
      <v-col cols="12" md="6">
        <PtMessageStatus />
      </v-col>
      <v-col cols="12" md="6">
        <PtFriendTransition />
      </v-col>
      <v-col cols="12" md="6">
        <PtLatestMessage />
      </v-col>
    </v-row>
    <PtFavoriteAnswerForm v-if="hasRole.answer_form" />
  </div>
</template>
<script>
import PtFriendReportCurrent from 'components/parts/unique/PtFriendReportCurrent'
import PtSendCount from 'components/parts/unique/PtSendCount'
import PtMessageStatus from 'components/parts/unique/PtMessageStatus'
import PtFriendTransition from 'components/parts/unique/PtFriendTransition'
import PtAdminInfo from 'components/parts/unique/PtAdminInfo'
import PtFavoriteAnswerForm from 'components/parts/unique/PtFavoriteAnswerForm'
import PtLatestMessage from 'components/parts/unique/PtLatestMessage'
export default {
  components: {
    PtFriendReportCurrent,
    PtSendCount,
    PtMessageStatus,
    PtFriendTransition,
    PtAdminInfo,
    PtFavoriteAnswerForm,
    PtLatestMessage,
  },
  computed: {
    hasRole() {
      return {
        answer_form: this.$store.getters.roles.includes('answer_form'),
      }
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
