<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle />
      </v-col>
      <v-col cols="4" align="right" class="pt-8">
        <btn-dialog-destroy v-if="!isNewRecord" @save="destroy" />
      </v-col>
    </v-row>
    <validation-observer ref="observer">
      <v-form @submit.prevent="save">
        <v-card>
          <VfLoadingOverlayAbsolute :loading="loading" />
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <vf-text-field
                  v-model="channelRole.name"
                  :label="$$tm('channel_role.name')"
                  :vees="vees.name"
                />
              </v-col>
            </v-row>
            <choice-tables
              v-model="selectedItems"
              :available-role-types="availableRoleTypes"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="toIndex">{{ $t('btn.back') }}</v-btn>
            <v-btn
              type="submit"
              :loading="$wait.is('processing')"
              color="primary"
              >{{ $t('btn.create') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>
<script>
import ChoiceTables from './Form/ChoiceTables'
import BtnDialogDestroy from 'components/commonParts/BtnDialogDestroy'
export default {
  components: {
    ChoiceTables,
    BtnDialogDestroy,
  },
  data: () => ({
    loading: false,
    channelRole: {},
    availableRoleTypes: [],
    vees: {},
    selectedItems: [],
  }),
  computed: {
    channelRoleId() {
      return this.$route.params.id
    },
    isNewRecord() {
      return this.channelRoleId === 'new'
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = this.isNewRecord
        ? '/api/v1/channel_roles/new'
        : `/api/v1/channel_roles/${this.channelRoleId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.channelRole = res.data.channel_role
          this.vees = res.data.vees
          this.availableRoleTypes = res.data.opts.available_role_types
          this.selectedItems = res.data.role_choice_types.map((type) => ({
            value: type,
          }))
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.loading = true
      const path = this.isNewRecord
        ? '/api/v1/channel_roles'
        : `/api/v1/channel_roles/${this.channelRoleId}`
      const method = this.isNewRecord ? 'post' : 'put'
      const params = {
        channel_role: this.channelRole,
        role_choice_types: this.selectedItems.map((item) => item.value),
      }
      await this.$$sendForm(method, path, this.$$reqPrms(params))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'ChannelRoleIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.loading = false
        })
    },
    async destroy() {
      this.loading = true
      const path = `/api/v1/channel_roles/${this.channelRoleId}`
      await this.$$sendForm('delete', path)
        .then(() => {
          this.$toasted.success(this.$t('success.delete'))
          this.$router.push({ name: 'ChannelRoleIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    toIndex() {
      this.$router.push({ name: 'ChannelRoleIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
