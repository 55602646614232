<template>
  <v-dialog v-model="dialog" :max-width="800">
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" icon outlined v-on="on">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <validation-observer ref="observer">
      <v-form @submit.prevent="save">
        <v-card>
          <v-card-title>
            {{ $t('account.user_edit') }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <vf-text-field
              v-model="cloneUser.email"
              :label="$$tm('user.email')"
              :vees="vees.email"
            />
            <vf-text-field
              v-model="cloneUser.name"
              :label="$$tm('user.name')"
              :vees="vees.name"
            />
            <vf-text-field
              v-model="cloneUser.phone"
              :label="$$tm('user.phone')"
              :vees="vees.phone"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn @click="dialog = false">
                {{ $t('btn.cancel') }}
              </v-btn>
              <v-btn type="submit" color="primary">
                {{ $t('btn.update') }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>
<script>
export default {
  props: {
    user: { type: Object, required: true },
    vees: { type: Object, required: true },
  },
  data: () => ({
    dialog: false,
    cloneUser: {},
  }),
  watch: {
    dialog(val) {
      if (val) {
        this.cloneUser = this.$$clone(this.user)
      }
    },
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.$emit('save', this.cloneUser)
      this.dialog = false
    },
  },
}
</script>
