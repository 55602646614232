<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn
            v-if="searchTemplateId !== 'new'"
            color="error"
            @click="destroy"
          >
            {{ $t('btn.delete') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.search_template.info') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <VfSelect
                v-model="searchTemplate.folder_id"
                :label="$$tm('folder.name')"
                :vees="vees.folder_id"
                :selects="folders"
              />
            </v-col>
            <v-col md="6">
              <VfTextField
                v-model="searchTemplate.name"
                :label="$$tm('search_template.name')"
                :vees="vees.name"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.search_template.search') }}
        </v-card-title>
        <v-card-text>
          <PtSearchWrap v-model="searchWrap" />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col class="text-right">
          <v-btn
            color="primary"
            :loading="$wait.is('processing')"
            @click="save"
            >{{ $t('btn.create') }}</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtSearchWrap from 'components/parts/common/PtSearchWrap/PtSearchWrap'
import { validateSearchComponent } from 'plugins/vee-validate'
export default {
  components: {
    PtSearchWrap,
  },
  data: () => ({
    initDone: false,
    searchTemplateId: '',
    searchTemplate: {},
    vees: {},
    folders: {},
    opts: {},
    searchWrap: [],
    savePath: '',
    deleteDialog: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.searchTemplateId = this.$route.params.id
      const path =
        this.searchTemplateId === 'new'
          ? '/api/v1/search_templates/new'
          : `/api/v1/search_templates/${this.searchTemplateId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.searchTemplate = res.data.search_template
          this.vees = res.data.vees
          this.folders = res.data.folders
          this.searchWrap = res.data.search_wrap
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      let error_msg = undefined
      let found = undefined
      if (isValid) {
        this.searchWrap.search_arr.find((searchs) => {
          found = searchs.find((search) => {
            error_msg = validateSearchComponent(search)
            return error_msg !== undefined
          })
          return found !== undefined
        })
      } else {
        error_msg = this.$t('danger.params')
      }
      if (error_msg !== undefined) {
        return this.$toasted.error(error_msg)
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.searchTemplateId === 'new'
          ? '/api/v1/search_templates'
          : `/api/v1/search_templates/${this.searchTemplateId}`
      const method = this.searchTemplateId === 'new' ? 'post' : 'put'
      const prms = {
        search_template: this.searchTemplate,
        search_wrap: this.searchWrap,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'SearchTemplateIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    destroy() {
      this.savePath = `/api/v1/search_templates/${this.searchTemplateId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'SearchTemplateIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
