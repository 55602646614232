<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle />
      </v-col>
      <v-col cols="4" align="right" class="pt-8">
        <v-btn @click="$router.push({ name: 'AdminPlanIndex' })">{{
          $t('btn.index')
        }}</v-btn>
      </v-col>
    </v-row>
    <div class="mb-4">{{ $t('lbl.change_plan_impact') }}</div>
    <v-card class="pb-1">
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="items"
        item-key="plan_role_type"
        show-select
        disable-sort
        hide-default-footer
        :items-per-page="-1"
        @click:row="rowClick"
      />
    </v-card>
    <v-row>
      <v-col align="right">
        <v-btn @click="$router.push({ name: 'AdminPlanIndex' })">
          {{ $t('btn.back') }}
        </v-btn>
        <dialog-confirm @save="save" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DialogConfirm from './Role/DialogConfirm'
export default {
  components: {
    DialogConfirm,
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    selectedItems: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/plan_roles/${this.$route.params.id}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.headers = res.data.headers
          this.items = res.data.plan_role_types
          this.selectedItems = res.data.selected_plan_roles
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    async save() {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/plan_roles/${this.$route.params.id}`
      const prms = {
        plan_role_types: this.selectedItems.map((item) => item.plan_role_type),
      }
      await this.$$sendForm('put', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'AdminPlanIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.label-mb-0 ::v-deep label {
  margin-bottom: 0px;
}
</style>
