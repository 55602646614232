<template>
  <v-alert
    v-if="$store.getters.globalMessageType"
    :text="$store.getters.isAlertText"
    :type="$store.getters.globalMessageType"
  >
    <div
      v-if="$store.getters.isHtml"
      class="or-break"
      v-html="$store.getters.globalMessage"
    />
    <div v-else class="or-break">{{ $store.getters.globalMessage }}</div>
    <template #append>
      <v-icon @click="close">mdi-close-circle</v-icon>
    </template>
  </v-alert>
</template>
<script>
export default {
  methods: {
    close() {
      this.$$glMsg(null, null)
    },
  },
}
</script>
