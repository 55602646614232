<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.action_template.info') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <VfSelect
                v-model="answer_form.folder_id"
                :label="$$tm('folder.name')"
                :vees="vees.folder_id"
                :selects="folders"
              />
            </v-col>
            <v-col md="6">
              <VfTextField
                v-model="answer_form.name"
                :label="$$tm('answer_form.name')"
                :vees="vees.answer_form.name"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-text>
          <v-row>
            <v-col md="6">
              <VfTextField
                v-model="answer_form.form_title"
                :label="$$tm('answer_form.form_title')"
                :vees="vees.answer_form.form_title"
              />
            </v-col>
            <v-col md="12" class="pb-0">
              <VfTextArea
                v-model="answer_form.form_description"
                :label="$$tm('answer_form.form_description')"
                :vees="vees.answer_form.form_description"
              />
            </v-col>
            <v-col md="6" class="pb-0 pt-0">
              <VfCheckbox
                v-model="answer_form.spreadsheet_replication_flg"
                :label="$$tm('answer_form.spreadsheet_replication_flg')"
                :vees="vees.answer_form.spreadsheet_replication_flg"
              />
            </v-col>
          </v-row>
          <v-divider />
          <template v-for="(section, section_i) in sections">
            <v-card-title v-show="sections.length > 1" :key="section_i">
              <v-row>
                <v-col md="8">
                  {{ $t('lbl.answer_form.section') + (section_i + 1) }}
                </v-col>
                <v-col md="4" class="text-right">
                  <v-btn
                    small
                    @click="
                      removeDialog = true
                      removeSectionIndex = section_i
                    "
                    ><v-icon small>mdi-close</v-icon
                    >{{ $t('lbl.answer_form.delete_section') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <PtFormSetupSection
              :key="`PtFormSetupSection_${section_i}`"
              v-model="sections[section_i]"
              :init-data="initData"
              :titles="titles"
              :targets="allAssignTarget"
              :section-length="sections.length"
              :section-list="sectionList"
              :section-index="section_i"
              :vees="vees"
              :opts="opts"
              @update="handleFormUpdate"
            />
            <v-divider :key="`divider_${section_i}`" />
          </template>
          <v-row>
            <v-col>
              <v-btn class="btn-block" @click="addSection"
                ><v-icon>mdi-plus</v-icon>{{ $t(`btn.add_section`) }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- Form settings -->
      <v-card class="mb-6">
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-btn
                :color="tabActive(settingOption)"
                @click="changeSettingTab(settingOption)"
                >{{ $t('lbl.answer_form.btn_option') }}</v-btn
              >
              <v-btn
                :color="tabActive(settingDesign)"
                @click="changeSettingTab(settingDesign)"
                >{{ $t('lbl.answer_form.btn_design_setting') }}</v-btn
              >
              <v-btn
                :color="tabActive(settingAutoInput)"
                @click="changeSettingTab(settingAutoInput)"
                >{{ $t('lbl.answer_form.btn_auto_input') }}</v-btn
              >
            </v-col>
          </v-row>
          <!-- Setting options -->
          <template v-if="currentSettingTab === settingOption">
            <v-divider />
            <v-row class="align-center mx-6">
              <v-col md="3">
                <span class="text-16">{{
                  $t('lbl.answer_form.expired_at_flag')
                }}</span>
              </v-col>
              <v-col md="2">
                <VfCheckbox
                  v-model="answer_form_setting.expired_at_flag"
                  class="vf-unset-default"
                  :label="$t('lbl.answer_form.expired_at_flag_value')"
                  @change="changeExpiredAt"
                />
              </v-col>
              <v-col v-if="isDisplayExpiredAt" md="7">
                <VfDateTimeSelect
                  v-model="answer_form_setting.expired_at"
                  label=""
                  :vees="vees.disp_st_at"
                  format="YYYY/MM/DD HH:mm"
                />
              </v-col>
            </v-row>
            <v-row v-if="isDisplayExpiredAt" class="mx-6">
              <v-col md="3" class="align-self-start">
                <span class="text-16">{{
                  $t('lbl.answer_form.expired_at_flag_desc')
                }}</span>
              </v-col>
              <v-col md="9">
                <VfTextArea
                  v-model="answer_form_setting.deadline_text"
                  class="vf-hidden-label"
                  :label="$t('lbl.answer_form.expired_at_flag_desc')"
                  :vees="vees.answer_form_setting.deadline_text"
                />
              </v-col>
            </v-row>
            <v-row class="align-center mx-6">
              <v-col md="3">
                <span class="text-16">{{
                  $t('lbl.answer_form.response_limit')
                }}</span>
              </v-col>
              <v-col md="2">
                <VfCheckbox
                  v-model="answer_form_setting.response_limit"
                  class="vf-unset-default"
                  :label="$t('lbl.answer_form.response_limit_value')"
                  :true-value="0"
                  :false-value="1"
                />
              </v-col>
              <v-col v-if="answer_form_setting.response_limit" md="7">
                <VfTextField
                  v-model="answer_form_setting.response_limit"
                  label=""
                  :number="true"
                  :min="0"
                  :vees="vees.answer_form_setting.response_limit"
                  @input="responseLimitChange"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="answer_form_setting.response_limit"
              class="align-center mx-6"
            >
              <v-col md="3" class="align-self-start">
                <span class="text-16">{{
                  $t('lbl.answer_form.response_limit_desc')
                }}</span>
              </v-col>
              <v-col md="9">
                <VfTextArea
                  v-model="answer_form_setting.response_limit_text"
                  class="vf-hidden-label"
                  :label="$t('lbl.answer_form.response_limit_desc')"
                  :vees="vees.answer_form_setting.response_limit_text"
                />
              </v-col>
            </v-row>
            <v-row class="align-center mx-6">
              <v-col md="3">
                <span class="text-16">{{
                  $t('lbl.answer_form.response_limit_per_person')
                }}</span>
              </v-col>
              <v-col md="9">
                <VfSelect
                  v-model="answer_form_setting.response_limit_per_person"
                  label=""
                  :selects="response_limit_options"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="answer_form_setting.response_limit_per_person"
              class="align-center mx-6"
            >
              <v-col md="3" class="align-self-start">
                <span class="text-16">{{
                  $t('lbl.answer_form.response_limit_per_person_desc')
                }}</span>
              </v-col>
              <v-col md="9">
                <VfTextArea
                  v-model="answer_form_setting.response_limit_per_person_text"
                  class="vf-hidden-label"
                  :label="$t('lbl.answer_form.response_limit_per_person_desc')"
                  :vees="
                    vees.answer_form_setting.response_limit_per_person_text
                  "
                />
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="align-center mx-6">
              <v-col md="3">
                <span class="text-16">{{
                  $t('lbl.answer_form.thank_page_url')
                }}</span>
              </v-col>
              <v-col md="9">
                <VfTextField
                  v-model="answer_form_setting.thank_page_url"
                  class="vf-hidden-label"
                  :label="$t('lbl.answer_form.thank_page_url')"
                  :vees="vees.answer_form_setting.thank_page_url"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="
                !answer_form_setting.thank_page_url ||
                answer_form_setting.thank_page_url.length == 0
              "
              class="mx-6"
            >
              <v-col md="3">
                <span class="text-16">{{
                  $t('lbl.answer_form.submit_response_message')
                }}</span>
              </v-col>
              <v-col md="9">
                <VfTextArea
                  v-model="answer_form_setting.submit_response_message"
                  class="vf-hidden-label"
                  :label="$t('lbl.answer_form.submit_response_message')"
                  :vees="vees.answer_form_setting.submit_response_message"
                />
                <span class="text-12">{{
                  $t('lbl.answer_form.submit_response_message_helper')
                }}</span>
              </v-col>
            </v-row>
            <v-row class="mx-6">
              <v-col md="3">
                <span class="text-16">{{
                  $t('lbl.answer_form.action_after_answer')
                }}</span>
              </v-col>
              <v-col md="9">
                <PtActionSelectedList v-model="actionsAnswerSetting" />
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="mx-6">
              <v-col md="3">
                <span class="text-16">{{
                  $t('lbl.answer_form.answer_restoration_flg')
                }}</span>
              </v-col>
              <v-col md="9">
                <VfCheckbox
                  v-model="answer_form_setting.answer_restoration_flg"
                  class="vf-unset-default"
                  :label="$t('lbl.answer_form.answer_restoration_flg_value')"
                />
                <p class="text-caption grey--text text--lighten-1">
                  {{ $t('lbl.answer_form.answer_restoration_flg_helper') }}
                </p>
              </v-col>
            </v-row>
          </template>

          <!-- Setting colors and design -->
          <template v-if="isSetDefaultSettingDone">
            <color-design-setting
              v-show="currentSettingTab === settingDesign"
              :mode="mode"
              :value="answer_form_setting"
              :rules="vees.answer_form_setting"
              @change="updateDesignSettings"
            />
          </template>

          <!-- Setting auto input -->
          <template v-if="isSetDefaultSettingDone">
            <auto-input-setting
              v-show="currentSettingTab === settingAutoInput"
              :mode="mode"
              :auto-list="autoInputList"
              :value="autoInputSettings"
              :rules="vees.answer_form_setting"
              @change="updateAutoInputSettings"
            />
          </template>
        </v-card-text>
      </v-card>
      <!-- End form settings -->

      <v-card>
        <v-card-text>
          <v-row class="mt-1">
            <v-col md="6">
              <call-tag
                v-if="answerFormId !== 'new'"
                :form-key="answer_form.alt_uuid"
              />
            </v-col>
            <v-col md="6" class="text-right align-self-end">
              <v-btn
                class="px-10"
                large
                color="success"
                :loading="$wait.is('processing')"
                @click="save(false)"
                >{{ $t('btn.save_data') }}</v-btn
              >
              <v-btn
                class="px-10"
                large
                color="primary"
                :loading="$wait.is('processing')"
                @click="save(true)"
                >{{ $t('btn.save_preview') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </validation-observer>
    <v-dialog v-model="removeDialog" max-width="300">
      <v-card>
        <v-card-title>{{ '' }}</v-card-title>
        <v-card-text>
          <div class="or-break">
            {{ $t('lbl.answer_form.confirm_delete_section') }}
          </div>
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="removeDialog = false">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="error" @click="removeSection(removeSectionIndex)">{{
            $t('btn.yes')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PtFormSetupSection from 'components/parts/common/PtFormSetupSection/PtFormSetupSection'
import PtActionSelectedList from 'components/parts/common/PtActionGroup/PtActionSelectedList'
import ColorDesignSetting from './ColorDesignSetting'
import AutoInputSetting from './AutoInputSetting'
import CallTag from './CallTag'
export default {
  components: {
    PtFormSetupSection,
    ColorDesignSetting,
    AutoInputSetting,
    CallTag,
    PtActionSelectedList,
  },
  data: () => ({
    initDone: false,
    vees: {},
    folders: {},
    opts: {},
    savePath: '',
    removeDialog: false,
    removeSectionIndex: -1,
    deleteDialog: false,
    answerFormId: '',
    answer_form: {},
    answer_form_setting: {},
    sections: [],
    initData: {},
    response_limit_options: {},
    isDisplayExpiredAt: 0,
    currentSettingTab: 0,
    settingOption: 1,
    settingDesign: 2,
    settingAutoInput: 3,
    actionsAnswerSetting: [],
    designSettings: {},
    autoInputSettings: {},
    isSetDefaultSettingDone: false,
    autoInputList: [],
    sectionList: [],
  }),
  computed: {
    allAssignTarget: function () {
      let targets = []
      this.sections.forEach((section) => {
        section.forEach((setup) => {
          targets.push(...setup.assign_value_to)
        })
      })

      return targets
    },
    titles: function () {
      let allTitles = []
      this.sections.forEach((section) => {
        section.forEach((setup) => {
          allTitles.push(setup.title)
        })
      })

      return allTitles
    },
    mode() {
      return this.$route.query.clone_id || this.$route.params.id
    },
  },
  watch: {
    sections(value) {
      this.autoInputList = value.reduce((settings, section) => {
        if (section.length) {
          section.forEach((formItem, index) => {
            if (formItem._type === 'text_field') {
              settings.push({
                internal_id: section[index].internal_id,
                title: section[index].title,
                isAutoField: section[index].validation_type === 'zip_code',
              })
            }
          })
        }

        return settings
      }, [])
      this.triggerSectionsList()
    },
  },
  created() {
    this.setResponseLimitOptions()
    this.init()
  },
  methods: {
    addSection() {
      this.sections.push([])
    },
    removeSection(sectionIndex) {
      this.sections.splice(sectionIndex, 1)
      this.removeDialog = false
      this.removeSectionIndex = -1
    },
    triggerSectionsList() {
      this.sectionList = []
      this.sections.forEach((section, idx) => {
        if (section.length > 0) {
          this.sectionList.push(idx + 1)
        }
      })
      if (this.answer_form.answer_form_inputs_attributes) {
        this.answer_form.answer_form_inputs_attributes.forEach(
          (answerFormInputOption) => {
            if (
              answerFormInputOption[0] &&
              Array.isArray(
                answerFormInputOption[0].answer_form_input_options_attributes
              )
            ) {
              answerFormInputOption[0].answer_form_input_options_attributes.forEach(
                (option) => {
                  if (
                    !this.sectionList.includes(
                      option.on_page_interact_action
                    ) &&
                    option.on_page_interact_action != 0
                  ) {
                    option.on_page_interact_action = null
                  }
                }
              )
            }
          }
        )
      }
    },

    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.answerFormId = this.$route.params.id
      let cloneId = this.$route.query.clone_id
      let path

      if (this.answerFormId === 'new' && !cloneId) {
        path = '/api/v1/answer_forms/init_answer_form'
      } else if (this.answerFormId === 'new' && cloneId) {
        path = `/api/v1/answer_forms/new?id=${cloneId}`
      } else {
        path = `/api/v1/answer_forms/${this.answerFormId}/edit`
      }

      await this.axios
        .get('/api/v1/answer_forms/init_answer_form', {
          params: this.$$reqPrms(),
        })
        .then((res) => {
          this.initData = res.data.answer_form_init.answer_form_inputs[0]
          this.initData.answer_form_input_options_attributes =
            this.initData.answer_form_input_options
          this.initData.answer_form_input_options_attributes[0].action_arr = []
          this.initData.answer_form_input_options_attributes[0].action_arr_temp =
            []
          this.initData.answer_form_input_options_attributes[0].target_arr = [
            {
              type: 'hashtag',
              data: {
                hashtag_action_type: 'add_tag',
                hashtags: [],
                sort_no: null,
                target_hashtag_ids: [],
              },
            },
          ]
          delete this.initData.answer_form_input_options
          this.vees = res.data.vees
          this.vees.answer_form_input.short_description = {
            max: 90,
            rules: 'max:90',
          }
          this.folders = res.data.folders
          this.opts = this.$$clone(res.data.opts)
          delete this.opts.answer_form_input.assign_to['個別メモ'] // 個別メモを一時的に削除
          this.opts.answer_form_input.validation_types = Object.assign(
            {},
            { [this.$t('lbl.answer_form.no_validation_rules')]: null },
            res.data.opts.answer_form_input.validation_types
          )
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })

      this.isSetDefaultSettingDone = false
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          if (this.answerFormId === 'new' && !cloneId) {
            this.sections = [[]]
            this.answer_form = res.data.answer_form_init

            // Set answer form default setting
            this.setDefaultAnswerFormSetting(
              res.data.answer_form_init.answer_form_setting
            )
          } else {
            this.sections = res.data.answer_form.answer_form_inputs_attributes
            this.answer_form = res.data.answer_form

            this.setEditAnswerFormSetting(
              res.data.answer_form.answer_form_setting_attributes
            )
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
          this.isSetDefaultSettingDone = true
        })
    },

    async save(preview) {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.answer_form.answer_form_inputs_attributes = this.sections

      if (!this.existInput()) {
        return this.$toasted.error(
          this.$t('lbl.answer_form.must_one_form_item')
        )
      }
      this.$$loadingOverlayContent(true)
      const path =
        this.answerFormId === 'new'
          ? '/api/v1/answer_forms'
          : `/api/v1/answer_forms/${this.answerFormId}`
      const method = this.answerFormId === 'new' ? 'post' : 'put'

      this.answer_form.answer_form_setting_attributes =
        this.getAnswerFormSettingData()

      const prms = {
        answer_form: this.answer_form,
      }

      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then((res) => {
          this.$toasted.success(this.$t('success.create'))
          if (preview) {
            const uuid = res.data.answer_form.alt_uuid
            let previewUrl =
              this.$router.resolve({
                name: 'AnswerFormAnswer',
                params: { uuid: uuid },
              }).href + '?mode=example'
            window.open(previewUrl, '_blank')
            if (this.answerFormId === 'new') {
              this.answerFormId = res.data.answer_form.id
              this.$router.push({
                name: 'AnswerFormForm',
                params: { id: this.answerFormId },
              })
            }
            this.init()
          } else {
            this.$router.push({ name: 'AnswerFormIndex' })
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })

      let googleUserCode
      if (
        this.answer_form.spreadsheet_replication_flg &&
        this.answer_form.sheet_id == null
      ) {
        googleUserCode = await this.$gAuth
          .getAuthCode()
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.$$loadingOverlayContent(false)
          })

        if (googleUserCode) {
          this.$$loadingOverlayContent(true)
          await this.$$sendForm(
            'post',
            `/api/v1/answer_forms/${this.answerFormId}/retrieve_google_access`,
            this.$$reqPrms({ code: googleUserCode })
          )
            .then((res) => {
              this.$toasted.success(this.$t('success.create'))

              //FIXME: API redundant 's' in 'answer_forms' (correct is 'answer_form')
              if (res.data.answer_forms.sheet_id) {
                this.alertGoogleSpreedSheetUrl(
                  `${this.$t('lbl.answer_form.google_spreedsheet_url_prefix')}${
                    res.data.answer_forms.sheet_id
                  }`
                )
                this.answer_form.sheet_id = res.data.answer_forms.sheet_id
              }
            })
            .catch((error) => {
              this.$$ajaxError(error)
            })
            .finally(() => {
              this.$$loadingOverlayContent(false)
            })
        }
      }
    },

    destroy() {
      this.savePath = `/api/v1/action_templates/${this.answerFormId}`
      this.deleteDialog = true
    },

    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'ActionTemplateIndex' })
    },

    existInput() {
      const types = [
        'checkbox',
        'attachment',
        'dropdown',
        'radio',
        'date',
        'text_area',
        'text_field',
        'address',
      ]
      return this.sections.some((section) => {
        return section.some((setup) => {
          return types.includes(setup._type)
        })
      })
    },

    /**
     * Handle form emit update event
     *
     * @param {Object} data
     *
     * @example {
     *  data: { title: 'new title' } (Object)
     *  formQuestionIndex: 0 (Int)
     *  sectionIndex: 0 (Int)
     *  type: "text-field" (String)
     *  attribute: "title" (String)
     *  internal_id: "id_123456789" (String)
     * }
     */
    handleFormUpdate(formData) {
      let changeIndex = this.autoInputList.findIndex(
        (item) => item.internal_id === formData.internal_id
      )

      if (changeIndex !== -1 && formData.type === 'text-field') {
        switch (formData.attribute) {
          case 'title':
            this.autoInputList[changeIndex].title = formData.data.title
            break
          case 'validation':
            this.autoInputList[changeIndex].isAutoField =
              formData.data.validation === 'zip_code'
            break
        }
      }
    },

    /**
     * Get all answer form settings
     *
     * @return {Object} answer_form_setting
     */
    getAnswerFormSettingData() {
      let optionsSetting = {
        expired_at: this.answer_form_setting.expired_at_flag
          ? this.answer_form_setting.expired_at
          : null,
        deadline_text: this.answer_form_setting.deadline_text || '',
        response_limit: this.answer_form_setting.response_limit,
        response_limit_text: this.answer_form_setting.response_limit_text || '',
        response_limit_per_person:
          this.answer_form_setting.response_limit_per_person,
        response_limit_per_person_text:
          this.answer_form_setting.response_limit_per_person_text || '',
        thank_page_url: this.answer_form_setting.thank_page_url || '',
        submit_response_message:
          this.answer_form_setting.submit_response_message || '',
        answer_restoration_flg: this.answer_form_setting.answer_restoration_flg,

        action_arr: this.actionsAnswerSetting || [],
      }
      return Object.assign(
        {},
        this.answer_form_setting,
        this.designSettings,
        this.autoInputSettings,
        optionsSetting
      )
    },

    /**
     * Set answer form default settings
     *
     * @param {Object} defaultSettings
     */
    setDefaultAnswerFormSetting(defaultSettings) {
      this.answer_form_setting = Object.assign({}, defaultSettings)
      this.answer_form_setting.expired_at = new Date(
        new Date().setHours(0, 0, 0, 0)
      )
      this.setFormDesignSettings(this.answer_form_setting)
    },

    /**
     * Update response limit when changed
     *
     * @param {Int|String} value
     */
    responseLimitChange(value) {
      if (value === '' || value < 0) {
        this.answer_form_setting.response_limit = 0
      }
    },

    /**
     * Set reponse limit default options
     */
    setResponseLimitOptions() {
      this.response_limit_options = {}
      this.response_limit_options[
        this.$t('lbl.answer_form.response_limit_options.no_limit')
      ] = 0
      this.response_limit_options[
        this.$t('lbl.answer_form.response_limit_options.limit')
      ] = 1
    },

    /**
     * Set default value answer form settings to edit
     *
     * @param {Object} settings
     */
    setEditAnswerFormSetting(settings) {
      this.answer_form_setting = Object.assign({}, settings)
      this.actionsAnswerSetting = this.answer_form_setting.action_arr || []
      if (settings.expired_at) {
        this.answer_form_setting.expired_at_flag = this.isDisplayExpiredAt = 1
      } else {
        this.answer_form_setting.expired_at = new Date(
          new Date().setHours(0, 0, 0, 0)
        )
      }

      this.setFormDesignSettings(this.answer_form_setting)
      this.setFormAutoFillSettings(this.answer_form_setting.auto_input_setting)
    },

    setFormAutoFillSettings(settings) {
      this.autoInputSettings = { auto_input_setting: JSON.stringify(settings) }
    },

    /**
     * Update display status of expired field
     *
     * @param {Boolean} value
     */
    changeExpiredAt(value) {
      this.isDisplayExpiredAt = value
    },

    /**
     * Get tab active by key
     *
     * @param {Int} val
     *
     * @return {String} class name
     */
    tabActive(val) {
      return val === this.currentSettingTab ? 'primary' : ''
    },

    /**
     * Change answer form setting tab
     *
     * @param {Int} val
     */
    changeSettingTab(val) {
      this.currentSettingTab = val === this.currentSettingTab ? 0 : val
    },

    /**
     * Update answer form design settings
     *
     * @param {Object} settings
     */
    updateDesignSettings(settings) {
      this.designSettings = Object.assign({}, settings)
    },

    /**
     * Set default value of design settings
     *
     * @param {Object} settings
     */
    setFormDesignSettings(settings) {
      let {
        custom_design_flg,
        hide_header_icon_flg,
        header_image_url,
        background_image_url,
        button_color,
        background_color,
        form_section_color,
      } = settings

      this.designSettings = {
        custom_design_flg,
        hide_header_icon_flg,
        header_image_url,
        background_image_url,
        button_color,
        background_color,
        form_section_color,
      }
    },

    /**
     * Update answer form auto input settings
     *
     * @param {Object} settings
     */
    updateAutoInputSettings(settings) {
      this.autoInputSettings = { auto_input_setting: JSON.stringify(settings) }
    },

    alertGoogleSpreedSheetUrl(url = '#') {
      const prefixUrl = this.$t('lbl.answer_form.google_spreedsheet_url_msg')
      const urlText = this.$t('lbl.answer_form.google_spreedsheet_url_text')

      this.$$glMsg(
        'success',
        `${prefixUrl}<a href="${url}" target="_blank">${urlText}</a>`,
        true,
        true
      )
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>

<style scoped lang="scss">
.time-pick {
  width: 100px;
}
.vf-unset-default ::v-deep {
  margin: unset;
  padding: 0;
  .v-input__slot {
    margin: unset;
    .v-label {
      margin: unset;
    }
  }
  .v-messages {
    min-height: 0;
  }
}
.vf-hidden-label ::v-deep {
  fieldset > legend {
    display: none;
  }
  .v-label {
    display: none;
  }
}
</style>
