<template>
  <PtRedirect :path="path" :params="params" />
</template>
<script>
import PtRedirect from 'components/parts/common/PtRedirect'
export default {
  components: {
    PtRedirect,
  },
  data: () => ({
    path: '',
    params: {},
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      const uuid = this.$route.params.id
      const type = this.$route.query.type
      const friend_id = this.$route.query.friend_id
      this.params = { uuid: uuid, type: type, friend_id: friend_id }
      this.path = `/api/v1/click_redirect/`
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
