<template>
  <div>
    <v-row>
      <v-col cols="12">
        <PtTitle />
      </v-col>
    </v-row>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-row class="m-2">
        <v-col cols="8">
          <v-text-field
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            clearable
            single-line
            hide-details
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :disable-sort="true"
        @click:row="rowClick"
      >
        <template #[`item.channel.name`]="{ item }">
          {{ item.name }}
        </template>
        <template #[`item.name`]="{ item }">
          <div v-for="user in item.users" :key="user.id">
            <div>{{ user.name }}</div>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    headers: [],
    search: '',
    items: [],
  }),
  watch: {
    search(val) {
      val = String(val).trim()
      if (!val) {
        this.headers = []
        this.items = []
        return
      }
      this.fetchEntriesDebounced()
    },
  },
  methods: {
    async fetch() {
      this.loading = true
      const path = `/api/v1/admin/channels?search=${this.search}`
      await this.axios
        .get(path)
        .then(({ data: { channels, thead } }) => {
          this.items = channels
          this.headers = channels.length > 0 ? thead : []
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchEntriesDebounced() {
      clearTimeout(this._timerId)

      this._timerId = setTimeout(() => {
        this.fetch()
      }, 500)
    },
    rowClick(item) {
      this.$router.push({
        name: 'AdminUsersShow',
        params: { id: item.users[0].id },
      })
    },
  },
}
</script>
