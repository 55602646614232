<template>
  <div>
    <v-card outlined>
      <v-tabs background-color="green lighten-4" vertical>
        <v-tab v-for="(stickerIds, packageId) in stickers" :key="packageId">
          <VImg
            height="50"
            width="50"
            :aspect-ratio="1 / 1"
            :src="stickerImg(stickerIds[0])"
          />
        </v-tab>
        <v-tab-item
          v-for="(stickerIds, packageId) in stickers"
          :key="packageId"
        >
          <v-card flat>
            <v-row
              v-for="(rowSet, rowSet_idx) in stickerSeparate(stickerIds)"
              :key="rowSet_idx"
              no-gutters
            >
              <v-col
                v-for="stickerId in rowSet"
                :key="stickerId"
                @click="selectSticker(packageId, stickerId)"
              >
                <VImg
                  height="50"
                  width="50"
                  :aspect-ratio="1 / 1"
                  :src="stickerImg(stickerId)"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <v-card-text>
        <v-row>
          <v-col>
            {{ $t('lbl.selected_sticker') }}
          </v-col>
          <v-col>
            <VImg
              v-if="internalValue.sticker_id"
              height="180"
              width="180"
              :src="stickerImg(internalValue.sticker_id)"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    stickers: { type: Object, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    stickerImg: function () {
      return function (stickerId) {
        return `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerId}/iPhone/sticker_key@2x.png`
      }
    },
    stickerSeparate: function () {
      return function (stickerIds) {
        const sliceByNumber = (array, number) => {
          const length = Math.ceil(array.length / number)
          return new Array(length)
            .fill()
            .map((_, i) => array.slice(i * number, (i + 1) * number))
        }
        return sliceByNumber(stickerIds, 10)
      }
    },
  },
  methods: {
    selectSticker(packageId, stickerId) {
      this.internalValue.package_id = packageId
      this.internalValue.sticker_id = stickerId
    },
  },
}
</script>
