var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',_vm._l((_vm.answerForm),function(form,form_i){return _c('v-col',{key:form_i,attrs:{"md":"6"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left font-weight-bold",attrs:{"colspan":"2"}},[_vm._v("\n              回答"+_vm._s(form.name)+"\n            ")])])]),_vm._v(" "),_c('tbody',[_c('tr',[_c('td',[_vm._v("\n              "+_vm._s(_vm.moment().subtract(1, 'day').format('MM月DD日') + "～: ")),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(((form.last_day_total_answer) + "回答 (" + (form.last_day_total_friend) + "人)")))])]),_vm._v(" "),_c('td',[_vm._v("\n              "+_vm._s("累計：")),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(((form.total_answer) + "回答 (" + (form.total_friends_answer) + "人)")))])])]),_vm._v(" "),(form.most_three_recent_answers.length > 0)?[_vm._l((form.most_three_recent_answers),function(answer,answer_id){return _c('tr',{key:answer_id},[_c('td',{attrs:{"colspan":"2"}},[_c('a',{staticClass:"text-decoration-none black--text",attrs:{"href":_vm.$router.resolve({
                      name: 'AnswerFormShow',
                      params: { id: form.id },
                    }).href + '?tab=3'}},[_vm._v("\n                  "+_vm._s(_vm.moment(answer.created_at).format('MM月DD日 HH:mm') + ' ')),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(answer.hasOwnProperty('friend')
                      ? answer.friend.name
                      : '匿名'))])])])])}),_vm._v(" "),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('a',{staticClass:"text-decoration-none black--text",attrs:{"href":_vm.$router.resolve({
                      name: 'AnswerFormShow',
                      params: { id: form.id },
                    }).href + '?tab=3'}},[_vm._v("続きを表示")])])])]:[_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("回答がありません。")])])]],2)]},proxy:true}],null,true)})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }