<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <template v-if="firstInit">
      <template v-for="(group, group_i) in internalValue.search_arr">
        <v-card v-if="group_i > 0">
          <v-card-title>{{ $t('lbl.search_wrap.search_or') }}</v-card-title>
        </v-card>
        <v-card class="mb-6">
          <v-card-title class="pa-0">
            <v-list-item>
              <v-list-item-content>
                {{ $t('lbl.search_wrap.search_title') }}
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  small
                  icon
                  :disabled="internalValue.search_arr.length <= 1"
                  @click="removeSearchGroup(group_i)"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
          <v-card-text>
            <PtSearchGroup
              v-model="internalValue.search_arr[group_i]"
              :init-data="initData"
              :all-vees="allVees"
              :opts="opts"
            />
          </v-card-text>
        </v-card>
      </template>
      <v-row class="mb-6">
        <v-col class="text-right">
          <v-btn @click="addSearchGroup">{{
            $t('lbl.search_wrap.search_or_btn')
          }}</v-btn>
        </v-col>
      </v-row>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <VfSelect
                v-model="internalValue.blocked_user_type"
                :label="$$tm('search_wrap.blocked_user_type')"
                :vees="allVees.wrap.blocked_user_type"
                :selects="opts.blocked_user_type"
              />
            </v-col>
            <v-col cols="12">
              <VfSelect
                v-model="internalValue.blocking_user_type"
                :label="$$tm('search_wrap.blocking_user_type')"
                :vees="allVees.wrap.blocking_user_type"
                :selects="opts.blocking_user_type"
              />
            </v-col>
            <v-col cols="12">
              <VfSelect
                v-model="internalValue.sort_type"
                :label="$$tm('search_wrap.sort_type')"
                :vees="allVees.wrap.sort_type"
                :selects="opts.sort_type"
              />
            </v-col>
            <v-col cols="12">
              <VfSelect
                v-model="internalValue.disp_count_type"
                :label="$$tm('search_wrap.disp_count_type')"
                :vees="allVees.wrap.disp_count_type"
                :selects="opts.disp_count_type"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>
<script>
import PtSearchGroup from 'components/parts/common/PtSearchWrap/PtSearchGroup'
export default {
  components: {
    PtSearchGroup,
  },
  props: {
    value: { default: null },
  },
  data: () => ({
    firstInit: false,
    loading: false,
    errorMsg: '',
    opts: {},
    initData: {},
    allVees: {},
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.errorMsg = ''
      this.loading = true
      const path = '/api/v1/search_wraps/setup'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.initData = res.data.init_data
          this.allVees = res.data.all_vees
          this.opts = res.data.opts
          this.opts['search_types'] = {
            シナリオ: 'scenario',
            タグ: 'hashtag',
          }
          if (!this.internalValue.search_arr) {
            this.initSearchArr()
          }
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.loading = false
          this.firstInit = true
        })
    },
    initSearchArr() {
      this.internalValue = this.$$clone(this.initData.wrap)
    },
    addSearchGroup() {
      this.internalValue.search_arr.push([])
    },
    removeSearchGroup(index) {
      this.internalValue.search_arr.splice(index, 1)
    },
  },
}
</script>
