<template>
  <div>
    <PtTitle />
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :disable-sort="true"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150],
        }"
      >
        <template #[`item.month`]="{ item }">
          {{ $$fdt(item.month, 'YYYY/MM') }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    headers: [],
    items: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.errorMsg = ''
      this.loading = true
      const path = '/api/v1/messages/month_index'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.message_counts
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
