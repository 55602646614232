<template>
  <tbody class="carousel-divider">
    <template v-for="(pannel, pannelIdx) in carousel.deli_carousel_panels">
      <template
        v-for="(choice, choiceIdx) in pannel.deli_carousel_panel_choices"
      >
        <tr
          v-for="(item, idx) in choice.click_carousel_panel_choice_friends"
          :key="item.id"
        >
          <td
            width="40%"
            v-if="idx === 0 && choiceIdx === 0 && pannelIdx === 0"
            :rowspan="rowSpan"
            class="text-start py-3"
            style="border-bottom: none"
          >
            <div class="carousels d-flex py-1">
              <div
                v-for="(panel, panel_idx) in carousel.deli_carousel_panels"
                :key="panel_idx"
              >
                <v-card :width="150" class="mr-2">
                  <VImg v-if="panel.upload_file_id" :src="panel.image_url" />
                  <v-card-text>
                    <div class="font-weight-bold">{{ panel.title }}</div>
                    <div>{{ panel.text }}</div>
                  </v-card-text>
                  <v-row no-gutters class="text-center">
                    <v-col
                      v-for="(
                        choice, choice_idx
                      ) in panel.deli_carousel_panel_choices"
                      :key="choice_idx"
                      cols="12"
                    >
                      <v-card class="px-2 py-4 h-100 pointer">
                        {{ choice.choice_name }}
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </td>
          <td class="text-start" width="120">{{ choice.choice_name }}</td>
          <td
            :class="
              item.no_data
                ? 'text-start v-data-table__empty-wrapper'
                : 'text-start'
            "
            width="220"
          >
            {{ item.friend_name }}
          </td>
          <td class="text-start" width="220">{{ item.click_count }}</td>
          <td class="text-start" width="210" style="min-width: 210px">
            {{ $$fat(item.created_at) }}
          </td>
        </tr>
      </template>
    </template>
  </tbody>
</template>
<script>
export default {
  props: {
    carousel: { type: Object, required: true },
    friends: { type: Array, requred: true },
  },
  data: () => ({
    items: [],
    rowSpan: 0,
  }),
  created() {
    this.items = this.carousel.deli_carousel_panels.map((pannel) => {
      pannel.deli_carousel_panel_choices =
        pannel.deli_carousel_panel_choices.map((choice) => {
          choice.click_carousel_panel_choice_friends = this.friends.filter(
            (f) => f.deli_carousel_panel_choice_id === choice.id
          )
          if (choice.click_carousel_panel_choice_friends.length === 0) {
            choice.click_carousel_panel_choice_friends = [
              { friend_name: 'データはありません。', no_data: true },
            ]
          }
          return choice
        })
      return pannel
    })
    this.carousel.deli_carousel_panels.forEach(
      ({ deli_carousel_panel_choices }) => {
        deli_carousel_panel_choices.forEach((choice) => {
          this.rowSpan += choice.click_carousel_panel_choice_friends.length
        })
      }
    )
  },
}
</script>

<style lang="scss" scoped>
.carousel-divider {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
  .carousels {
    width: 100%;
    max-width: 350px;
    overflow: auto;
  }
}
.v-data-table__empty-wrapper {
  font-size: 12px !important;
}
</style>
