<template>
  <v-card class="my-4">
    <VfLoadingOverlayAbsolute :loading="loading" />
    <div class="pt-3 mr-3 align-items-end d-flex flex-column">
      <div>{{ $t('lbl.visit_count', { count: visit_count }) }}</div>
      <div>{{ $t('lbl.sent_count', { count: sent_count }) }}</div>
    </div>
    <v-container fluid>
      <v-data-table
        v-if="!loading && deli_carousels.length > 0"
        :headers="headers"
        :items="deli_carousels"
        item-key="id"
        disable-sort
        :loading="loading"
      >
        <template v-slot:body="{ items }">
          <template v-for="item in items">
            <template>
              <PtClickCarouselDetail
                :key="item.id"
                :click-id="clickId"
                :carousel="item"
                :friends="clicked_friends"
              />
            </template>
          </template>
        </template>
      </v-data-table>
      <v-data-table v-else :headers="headers" :hide-default-footer="true" />
    </v-container>
  </v-card>
</template>
<script>
import PtClickCarouselDetail from './PtClickCarouselDetail'

export default {
  components: {
    PtClickCarouselDetail,
  },
  props: {
    clickId: { type: String, required: true },
  },
  data: () => ({
    headers: [],
    items: [],
    deli_carousels: [],
    clicked_friends: [],
    visit_count: 0,
    sent_count: 0,
    loading: false,
    detail_loading: false,
    csv_data: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      await this.axios
        .get(`/api/v1/click_carousels/${this.clickId}`)
        .then((res) => {
          const {
            thead,
            deli_carousels,
            visit_count,
            sent_count,
            clicked_friends,
          } = res.data
          this.headers = thead
          this.deli_carousels = deli_carousels
          this.visit_count = visit_count
          this.sent_count = sent_count
          this.clicked_friends = clicked_friends
          this.build_csv_data()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    build_csv_data() {
      let headers = this.headers.map((h) => h.text)
      let items = []
      let click_carousel_panel_choice_friends = []
      this.deli_carousels.forEach((carousel) => {
        carousel.deli_carousel_panels.forEach((panel) => {
          panel.deli_carousel_panel_choices.forEach((choice) => {
            click_carousel_panel_choice_friends = this.clicked_friends.filter(
              (f) => f.deli_carousel_panel_choice_id === choice.id
            )
            if (click_carousel_panel_choice_friends.length > 0) {
              click_carousel_panel_choice_friends.forEach((object) => {
                items.push([
                  panel.title,
                  choice.choice_name,
                  object.friend_name
                    ? object.friend_name
                    : this.$t('lbl.unknown'),
                  object.click_count,
                  this.$$fat(object.created_at),
                ])
              })
            } else {
              items.push([panel.title, choice.choice_name])
            }
          })
        })
      })
      this.csv_data = [headers].concat(items)
    },
  },
}
</script>
