<template>
  <div>
    <PtTitle
      :title="
        $t('lbl.answer_form.change_status_title', {
          form_name: answer_form.name,
        })
      "
    />
    <validation-observer v-if="initDone" ref="observer">
      <v-row>
        <v-col md="6" class="py-0">
          <VfCheckbox
            v-model="answer_form.publish_flg"
            :label="$$tm('answer_form.publish_flg')"
          />
        </v-col>
      </v-row>
      <template v-if="!answer_form.publish_flg">
        <v-row class="mt-0">
          <v-col md="6 py-0">
            <VfTextArea
              v-model="answer_form.unpublish_message"
              :label="$t('lbl.answer_form.text_display_instead')"
              :vees="{ rules: 'max:1200', max: 1200 }"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col md="6 py-0">
            <v-alert text class="light-blue lighten-5">
              {{ $t('lbl.answer_form.preview_is_possible') }}
            </v-alert>
          </v-col>
        </v-row>
      </template>
      <v-row class="mt-1">
        <v-col>
          <v-btn class="px-10" large color="primary" @click="save">{{
            $t('btn.save')
          }}</v-btn>
        </v-col>
      </v-row>
    </validation-observer>
  </div>
</template>
<script>
export default {
  name: 'AnswerFormChangeStatus',
  data: function () {
    return {
      answerFormId: '',
      initDone: true,
      answer_form: {
        name: '',
      },
    }
  },
  created() {
    this.answerFormId = this.$route.params.id
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      const path = `/api/v1/answer_forms/${this.answerFormId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.answer_form = res.data.answer_form
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
          this.isSetDefaultSettingDone = true
        })
    },
    async save() {
      if (this.answer_form.publish_flg) {
        this.answer_form.unpublish_message = ''
      } else {
        this.answer_form.unpublish_message =
          this.answer_form.unpublish_message ||
          this.$t('lbl.answer_form.currently_not_accepting')
      }
      const path =
        this.answerFormId === 'new'
          ? '/api/v1/answer_forms'
          : `/api/v1/answer_forms/${this.answerFormId}`
      const prms = {
        answer_form: {
          publish_flg: this.answer_form.publish_flg,
          unpublish_message: this.answer_form.unpublish_message,
        },
      }
      await this.$$sendForm('put', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(
            this.$t('lbl.answer_form.changed_public_settings')
          )
          this.$router.push({ name: 'AnswerFormIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
  },
}
</script>
