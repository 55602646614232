<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-row>
      <v-col md="6" class="pb-0">
        <VfTextField
          v-model="internalValue.title"
          :label="$t('lbl.answer_form.item_name')"
          :vees="vees.answer_form_input.title"
        />
      </v-col>
      <v-col md="6" class="pb-0">
        <VfCheckbox
          v-model="internalValue.hide_title_flg"
          :label="$t('lbl.answer_form.hide_item_name')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="pb-4">
        <VfTextField
          v-model="internalValue.description"
          :label="$t('lbl.answer_form.description')"
          :vees="vees.answer_form_input.short_description"
          :hint="$t('lbl.answer_form.text_field_desc_hint')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-4">
        <VfSelect
          v-model="internalValue.additional_option_type"
          :label="$t('lbl.answer_form.attachment_select')"
          :selects="opts.answer_form_input.additional_option_types.attachment"
        />
      </v-col>
      <v-col>
        <div
          class="pt-5"
          v-text="$t('lbl.answer_form.text_select_attachment_option')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2" class="pb-0 offset-8">
        <VfCheckbox
          v-model="internalValue.require_flg"
          :label="$t('lbl.answer_form.require')"
        />
      </v-col>
      <v-col md="2" class="pb-0">
        <VfCheckbox
          v-model="internalValue.hidden_flg"
          :label="$t('lbl.answer_form.hide')"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PtFormSetupAttachment',
  props: {
    value: { type: Object, required: true },
    targets: { type: Array, default: () => [] },
    vees: { type: Object, default: () => ({}) },
    opts: { type: Object, default: () => ({}) },
  },
  data: function () {
    return {
      loading: false,
      errorMsg: '',
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    if (this.internalValue.additional_option_type === null) {
      this.internalValue.additional_option_type = 'do_nothing'
    }
  },
  methods: {
    addTarget(target) {
      this.internalValue.assign_value_to.push(target)
    },
    removeTarget(index) {
      this.internalValue.assign_value_to.splice(index, 1)
    },
  },
}
</script>

<style scoped>
.time-pick {
  width: 100px;
}
</style>
