<template>
  <div>
    <v-card class="mx-auto py-4" max-width="800">
      <v-form @submit.prevent="submitForm">
        <v-card-title>
          <v-row justify="center">
            <div>{{ $t(`meta.title.${$route.name}`) }}</div>
          </v-row>
        </v-card-title>
        <v-divider class="mx-4" />
        <v-card-text class="text-h5 font-weight-bold">
          <v-row justify="center">
            <v-col cols="12" md="8">
              <vf-text-field v-model="item.email" label="メールアドレス" />
            </v-col>
            <v-col cols="12" md="8">
              <vf-text-field
                v-model="item.password"
                label="パスワード"
                password
              />
            </v-col>
            <v-col cols="12" md="8">
              <vf-text-field
                v-model="item.password_confirmation"
                label="パスワード（確認）"
                password
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="submit" color="primary">{{ $t('btn.create') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    item: {
      email: '',
      password: '',
      password_confirmation: '',
    },
  }),
  methods: {
    submitForm() {
      const url = '/api/v1/admin/auth'
      this.axios
        .post(url, this.item)
        .then(() => {
          this.$toasted.success('create')
          this.logout()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    logout() {
      const url = '/auth/sign_out'
      this.axios
        .delete(url)
        .then(() => {
          this.$store.dispatch('logout')
          this.$router.push({ name: 'RegistrationEmail' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
