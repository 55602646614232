<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-list-item>
        <v-list-item-content>
          {{ $t('account.password_edit') }}
        </v-list-item-content>
        <v-list-item-action>
          <btn-dialog-password-form @save="save" />
        </v-list-item-action>
      </v-list-item>
    </v-card-title>
    <v-card-text>
      <v-text-field label="********" disabled />
    </v-card-text>
  </v-card>
</template>
<script>
import BtnDialogPasswordForm from './BtnDialogPasswordForm'
export default {
  components: {
    BtnDialogPasswordForm,
  },
  methods: {
    save(user) {
      this.$emit('save', user)
    },
  },
}
</script>
