<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2" align="right" class="mt-5">
        <v-btn
          v-if="broadcastId !== 'new'"
          color="error"
          @click="destroy"
          :disabled="broadcast.disabled"
        >
          {{ $t('btn.delete') }}
        </v-btn>
      </v-col>
    </v-row>
    <validation-observer ref="observer">
      <v-card outlined class="mb-6">
        <v-card-title class="or-card-title">
          {{ $t('lbl.send_target') }}
        </v-card-title>
        <v-card-text>
          <VfSwitch
            v-model="broadcast.all_cast_flg"
            :label="$$tm('broadcast.all_cast_flg')"
            :vees="vees.all_cast_flg"
            @vf-switch-chenge="getFriendCount"
          />
          <v-card v-if="!broadcast.all_cast_flg">
            <v-card-text>
              <PtSearchSelectedList
                v-model="broadcast.target_search_wrap"
                hide-bottom
                @pt-search-selected-list-save="getFriendCount"
                @pt-search-selected-list-clear="getFriendCount"
              />
            </v-card-text>
          </v-card>
          <div class="mt-5 red--text">
            {{ $t('lbl.broadcast.friend_count', { count: friendCount }) }}
          </div>
          <div v-if="!broadcast.all_cast_flg">
            {{ $t('lbl.broadcast.warning') }}
          </div>
        </v-card-text>
      </v-card>
      <v-card outlined class="mb-6">
        <v-card-title class="or-card-title">
          {{ $t('lbl.send_timing') }}
        </v-card-title>
        <v-card-text>
          <VfSwitch
            v-model="broadcast.immediate_flg"
            :label="$$tm('broadcast.immediate_flg')"
            :vees="vees.immediate_flg"
            :disabled="broadcast.disabled"
          />
          <v-card
            v-if="broadcast.immediate_flg === 0 && 'id' in broadcast"
            outlined
          >
            <v-card-title>
              {{ $t('lbl.set_send_at') }}
            </v-card-title>
            <v-card-text>
              <VfDateTimeSelect
                v-model="broadcast.reserve_at"
                :disabled="broadcast.disabled"
                label=""
              />
              <span class="error--text">{{
                $t('lbl.reserve_at_warning')
              }}</span>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
      <v-card outlined class="mb-6">
        <v-card-title class="or-card-title">
          {{ $t('lbl.send_message') }}
        </v-card-title>
        <v-card-text>
          <PtDeli v-if="initDone" v-model="delis" />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col align="right">
          <v-btn @click="$router.push({ name: 'BroadcastIndex' })">{{
            $t('btn.back')
          }}</v-btn>
          <v-btn
            @click="testSendDialog = true"
            :disabled="broadcast.disabled"
            >{{ $t('btn.test_send') }}</v-btn
          >
          <v-btn
            color="primary"
            @click="sendConfirm"
            :disabled="broadcast.disabled"
            >{{ $t('btn.send') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" :max-width="300">
        <v-card outlined>
          <v-card-title>{{ $t('confirm_title.send') }}</v-card-title>
          <v-card-text class="or-break">{{ $t('confirm.send') }}</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="dialog = false">{{ $t('btn.cancel') }}</v-btn>
            <v-btn :loading="$wait.is('processing')" @click="send">
              {{ $t('btn.send') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="testSendDialog" max-width="800px">
        <v-card>
          <VfLoadingOverlayAbsolute :loading="$wait.is('processing')" />
          <v-card-title>
            {{ $t('btn.test_send') }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <template v-if="testFriends.length == 0">
              <v-alert color="red lighten-2" dark>
                {{ $t('lbl.broadcast.no_tester') }}
              </v-alert>
            </template>
            <template v-else>
              <v-container fluid>
                <v-row>
                  <v-col
                    v-for="(friend, friend_idx) in testFriends"
                    :key="friend_idx"
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <VfCheckbox
                      v-model="friend.checked"
                      :label="friend.friend.api_display_name"
                      :name="friend.friend.api_display_name"
                      hide-details
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  :disabled="noTestFriendsAreChecked"
                  :loading="$wait.is('processing')"
                  @click="sendTest"
                >
                  {{ $t('btn.send') }}
                </v-btn>
              </v-card-actions>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtDeli from 'components/parts/common/PtDeli/PtDeli'
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
import {
  validateMultipleDeliText,
  validateMultipleUrlDeliText,
} from 'services/deliText'
export default {
  components: {
    PtDeli,
    PtSearchSelectedList,
  },
  data: () => ({
    dialog: false,
    broadcastId: '',
    broadcast: {},
    vees: {},
    delis: [],
    initDone: false,
    deleteDialog: false,
    savePath: '',
    testFriends: [],
    testSendDialog: false,
    friendCount: 0,
  }),
  computed: {
    noTestFriendsAreChecked() {
      return this.testFriends.every((friend) => !friend.checked)
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.initDone = false
      this.broadcastId = this.$route.params.id
      const path =
        this.broadcastId === 'new'
          ? '/api/v1/broadcasts/new'
          : `/api/v1/broadcasts/${this.broadcastId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.broadcast = res.data.broadcast
          this.vees = res.data.vees
          this.testFriends = res.data.test_friends
          this.delis = res.data.delis
          this.getFriendCount()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
          this.initDone = true
        })
    },
    async send() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        if (this.delis.length > 0 && !validateMultipleUrlDeliText(this.delis)) {
          return this.$toasted.error(this.$t('validation.length_of_url'))
        } else {
          return this.$toasted.error(this.$t('danger.params'))
        }
      }
      if (this.$wait.is('processing')) {
        return
      }
      if (!validateMultipleDeliText(this.delis)) {
        return this.$toasted.error(this.$t('danger.deli_text.format_invalid'))
      }
      this.$wait.start('processing')
      this.dialog = false
      this.$store.dispatch('loadingOverlay', true)
      this.broadcastId = this.$route.params.id
      const path =
        this.broadcastId === 'new'
          ? '/api/v1/broadcasts'
          : `/api/v1/broadcasts/${this.broadcastId}`
      const method = this.broadcastId === 'new' ? 'post' : 'put'
      const prms = { broadcast: this.broadcast, delis: this.delis }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'BroadcastIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$store.dispatch('loadingOverlay', false)
        })
    },
    async sendTest() {
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.testSendDialog = false
        if (this.delis.length > 0 && !validateMultipleUrlDeliText(this.delis)) {
          return this.$toasted.error(this.$t('validation.length_of_url'))
        } else {
          return this.$toasted.error(this.$t('danger.params'))
        }
      }
      this.dialog = false
      this.broadcastId = this.$route.params.id
      const path = '/api/v1/broadcasts/test_send'
      const method = 'post'
      const prms = {
        broadcast: this.broadcast,
        test_friends: this.testFriends,
        delis: this.delis,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.send'))
          this.testSendDialog = false
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
        })
    },
    async getFriendCount() {
      const path = '/api/v1/friends/search_wrap_send_target_count'
      let prms = {}
      if (!this.broadcast.all_cast_flg) {
        prms.search_wrap = this.broadcast.target_search_wrap
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then((res) => {
          this.friendCount = res.data.friend_count
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    sendConfirm() {
      this.dialog = true
    },
    destroy() {
      this.savePath = `/api/v1/broadcasts/${this.broadcastId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'BroadcastIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
