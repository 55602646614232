<template>
  <div>
    <v-card>
      <v-card-text v-if="internalValue.name">
        <PtFriendShowActionHashtag
          v-model="internalValue.hashtags"
          class="mb-4"
          :friend-id="internalValue.id"
        />
        <PtFriendShowActionScenario
          v-model="internalValue.active_scenario"
          class="mb-4"
          :friend-id="internalValue.id"
        />
        <PtFriendShowActionReminder
          v-model="internalValue.active_reminders"
          class="mb-4"
          :friend-id="internalValue.id"
        />
        <PtFriendShowActionSchedule
          v-model="internalValue.schedules"
          class="mb-4"
          :friend-id="internalValue.id"
        />
        <PtFriendShowActionRichMenu
          v-model="internalValue.rich_menu"
          :friend-id="internalValue.id"
        />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PtFriendShowActionHashtag from 'components/parts/unique/PtFriendShowActionHashtag'
import PtFriendShowActionScenario from 'components/parts/unique/PtFriendShowActionScenario'
import PtFriendShowActionReminder from 'components/parts/unique/PtFriendShowActionReminder'
import PtFriendShowActionSchedule from 'components/parts/unique/PtFriendShowActionSchedule'
import PtFriendShowActionRichMenu from 'components/parts/unique/PtFriendShowActionRichMenu'
export default {
  components: {
    PtFriendShowActionHashtag,
    PtFriendShowActionScenario,
    PtFriendShowActionReminder,
    PtFriendShowActionSchedule,
    PtFriendShowActionRichMenu,
  },
  props: {
    value: { type: Object, required: true },
    opts: { type: Object, default: () => ({}) },
    vees: { type: Object, default: () => ({}) },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
