<template>
  <tbody class="question-divider">
    <template v-for="(choice, choideIdx) in question.deli_question_choices">
      <tr
        v-for="(item, friendIdx) in choice.click_question_choice_friends"
        :key="item.id"
      >
        <td
          v-if="choideIdx === 0 && friendIdx === 0"
          :rowspan="rowSpan"
          class="text-start py-3"
          style="border-bottom: none"
        >
          <v-card :width="250" style="height: fit-content">
            <v-card-text>
              {{ question.title }}
            </v-card-text>
            <v-row no-gutters class="text-center">
              <v-col
                v-for="(choice, choice_idx) in question.deli_question_choices"
                :key="choice_idx"
              >
                <v-card class="px-2 py-4 h-100" style="word-break: break-all">
                  {{ choice.choice_name }}
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </td>
        <td class="text-start" width="120">{{ choice.choice_name }}</td>
        <td
          :class="
            item.no_data
              ? 'text-start v-data-table__empty-wrapper'
              : 'text-start'
          "
          width="220"
        >
          {{ item.friend_name }}
        </td>
        <td class="text-start" width="220">{{ item.click_count }}</td>
        <td class="text-start" width="210" style="min-width: 210px">
          {{ $$fat(item.created_at) }}
        </td>
      </tr>
    </template>
  </tbody>
</template>
<script>
export default {
  props: {
    clickId: { type: String, required: true },
    question: { type: Object, required: true },
    friends: { type: Array, required: true },
  },
  data: () => ({
    choices: [],
    rowSpan: null,
  }),
  created() {
    this.choices = this.question.deli_question_choices.map((choice) => {
      choice.click_question_choice_friends = this.friends.filter(
        (f) => f.deli_question_choice_id === choice.id
      )
      if (choice.click_question_choice_friends.length === 0) {
        choice.click_question_choice_friends = [
          { friend_name: 'データはありません。', no_data: true },
        ]
      }
      return choice
    })
    this.rowSpan = this.question.deli_question_choices
      .map(({ click_question_choice_friends }) => click_question_choice_friends)
      .flatten().length
  },
}
</script>

<style lang="scss" scoped>
.question-divider {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
.v-data-table__empty-wrapper {
  font-size: 12px !important;
}
</style>
