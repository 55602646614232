<template>
  <div class="enable-scroll unicast_friend_sticky">
    <v-card v-if="!firstTimeFlg" outlined class="pa-4">
      <VfLoadingOverlayAbsolute :loading="loading" />
      <router-link
        v-if="friend.id"
        :to="{ name: 'FriendShow', params: { id: friend.id } }"
        :event="isMobile ? '' : 'click'"
        :class="isMobile ? 'disabled-pointer' : ''"
      >
        <v-card class="profile_image">
          <VImg :src="$$noimageHelper(friend.api_picture_url)" />
        </v-card>
        <v-card-title class="green--text text--darken-3 pl-0 profile_name">
          {{ friend.name }}
        </v-card-title>
      </router-link>
      <v-card-text class="pl-0">
        <div>{{ $$fat(friend.created_at) }}</div>
        <VDivider />
        <div v-if="friend.id">
          <VfSelect
            v-model="friend.message_status"
            :label="$$tm('friend.message_status')"
            :vees="vees.message_status"
            :selects="opts.message_status"
            :disabled="!hasRole"
          />
        </div>
      </v-card-text>
      <v-card-text class="pl-0">
        <VfTextField
          v-model="friend.email"
          :label="$$tm('friend.email')"
          :vees="vees.email"
          disabled
        />
        <VfTextField
          v-model="friend.phone"
          :label="$$tm('friend.phone')"
          :vees="vees.phone"
          disabled
        />
        <div @click="showDialog">
          <label>{{ $$tm('friend.memo') }}</label>
          <div>
            <template>
              <span>
                {{ friend.memo | truncate(13) }}
              </span>
            </template>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="previewDialog" max-width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="12" class="py-0">
              <VfTextArea
                v-model="memoClone"
                :label="$$tm('friend.memo')"
                :vees="{ rules: 'max:2000', max: 2000 }"
                class="mt-4"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="closeDialog">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="primary" @click="saveChange('memo')">{{
            $t('btn.save_data')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    friendId: { type: String, default: '' },
    isMobile: { type: Boolean, default: false },
    firstTimeFlg: { type: Boolean, default: true },
  },
  data: () => ({
    friendInit: false,
    loading: false,
    friend: { memo: '' },
    vees: {},
    opts: {},
    previewDialog: false,
    memoClone: '',
  }),
  computed: {
    hasRole() {
      return this.$store.getters.roles.includes('friend_edit')
    },
  },
  watch: {
    'friend.message_status'(newValue) {
      if (this.friendInit) this.saveChange('message_status')
      this.friendInit = true
    },
  },
  created() {
    this.initFriend()
  },
  filters: {
    truncate: function (data, num) {
      if (!data) return ''
      if (data.length <= num) return data
      const reqdString = data.slice(0, num).concat('...')
      return reqdString
    },
  },
  methods: {
    async initFriend() {
      if (this.firstTimeFlg === true) {
        return
      }
      this.friendInit = false
      this.loading = true
      const fid = this.friendId ? this.friendId : 'init'
      const path = `/api/v1/friends/${fid}/unicast_show`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.friend = res.data.friend
          this.vees = res.data.vees
          this.opts = res.data.opts
          this.memoClone = this.friend.memo
        })
        .catch((error) => {
          // skip error message because this page has many components
          // this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async saveChange(updateAttr) {
      this.dialog = false
      this.$store.dispatch('loadingOverlay', true)
      const path = `/api/v1/friends/${this.friend.id}/unicast_update`
      this.friend[updateAttr] =
        updateAttr == 'memo' ? this.memoClone : this.friend[updateAttr]
      const prms = { unicast_friend: this.friend }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.previewDialog = false
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$store.dispatch('loadingOverlay', false)
        })
    },
    showDialog() {
      this.memoClone = this.friend.memo
      this.previewDialog = true
    },
    closeDialog() {
      this.previewDialog = false
    },
  },
}
</script>
<style scoped>
.v-card {
  background-color: #fff !important;
}
a {
  text-decoration: none;
}
.disabled-pointer {
  pointer-events: none;
}
.full-fixed .enable-scroll {
  position: fixed;
  top: 72px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
}
.unicast_friend_sticky {
  position: sticky;
  top: 80px;
}
.memo-content {
  padding-top: 20px;
}
</style>
