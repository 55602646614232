<template>
  <div class="import-check">
    <PtTitle />
    <h3 class="text-24">{{ $t('lbl.import.check.sub_header') }}</h3>
    <p v-if="isFileValid" class="mt-6">{{ $t('subtitle.import_check') }}</p>

    <v-row>
      <v-col md="12">
        <v-card>
          <v-card-text>
            <template v-if="isFileValid && !loading">
              <v-simple-table class="tbl-data-confirm">
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left item-success">
                        {{ $t('lbl.import.check.tbl.id') }}
                      </th>
                      <th class="text-left item-warning">
                        {{ $t('lbl.import.check.tbl.display_name') }}
                      </th>
                      <th class="text-left item-skip">
                        {{ $t('lbl.import.check.tbl.line_name') }}
                      </th>
                      <th class="text-left item-skip">
                        {{ $t('lbl.import.check.tbl.phone') }}
                      </th>
                      <th class="text-left item-skip">
                        {{ $t('lbl.import.check.tbl.email') }}
                      </th>
                      <th class="text-left item-warning">
                        {{ $t('lbl.import.check.tbl.badge_mark') }}
                      </th>
                      <th class="text-left item-skip">
                        {{ $t('lbl.import.check.tbl.status_notify') }}
                      </th>
                      <th class="text-left item-skip">
                        {{ $t('lbl.import.check.tbl.follow_register_date') }}
                      </th>
                      <th class="text-left item-warning">
                        {{ $t('lbl.import.check.tbl.display_status') }}
                      </th>
                      <th class="text-left item-skip">
                        {{ $t('lbl.import.check.tbl.user_block') }}
                      </th>
                      <th class="text-left item-skip">
                        {{ $t('lbl.import.check.tbl.last_message') }}
                      </th>
                      <th class="text-left item-skip">
                        {{ $t('lbl.import.check.tbl.last_message_date') }}
                      </th>
                      <th class="text-left item-skip">
                        {{ $t('lbl.import.check.tbl.scenario_subscribe') }}
                      </th>
                      <th class="text-left item-skip">
                        {{ $t('lbl.import.check.tbl.scenario_date') }}
                      </th>
                      <th class="text-left item-skip">
                        {{ $t('lbl.import.check.tbl.tag') }}
                      </th>
                      <template v-if="tagsExtend.length">
                        <th
                          v-for="tagExt in tagsExtend"
                          :key="tagExt"
                          class="text-left item-warning"
                        >
                          {{ tagExt }}
                        </th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, item_idx) in csvDetail.csv_data">
                      <tr
                        :key="item_idx"
                        :class="{ 'item-error': !item.status }"
                      >
                        <td class="item-success">{{ item.id }}</td>
                        <td class="item-warning">
                          {{ item.system_disp_name }}
                        </td>
                        <td class="item-skip">{{ item['LINE登録名'] }}</td>
                        <td class="item-skip">{{ item['電話番号'] }}</td>
                        <td class="item-skip">{{ item['メールアドレス'] }}</td>
                        <td class="item-warning">
                          {{ getMessageStatus(item.message_status) }}
                        </td>
                        <td class="item-skip">
                          {{ item['ステータスメッセージ'] }}
                        </td>
                        <td class="item-skip">
                          {{ item['登録（フォロー）日時'] }}
                        </td>
                        <td class="item-warning">{{ item.hidden_flg }}</td>
                        <td class="item-skip">
                          {{ item['ユーザーブロック'] }}
                        </td>
                        <td class="item-skip">{{ item['最終メッセージ'] }}</td>
                        <td class="item-skip">
                          {{ getLastMessageDate(item['最終メッセージ日時']) }}
                        </td>
                        <td class="item-skip">{{ item['購読中シナリオ'] }}</td>
                        <td class="item-skip">{{ item['シナリオ日数'] }}</td>
                        <td class="item-skip">{{ item['タグ一覧'] }}</td>
                        <template v-if="tagsExtend.length">
                          <td
                            v-for="tagExt in tagsExtend"
                            :key="tagExt"
                            class="item-warning"
                          >
                            {{ item[tagExt] }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-simple-table>

              <div class="tbl-note mt-3">
                <div class="tbl-note__item">
                  <span class="tbl-note__block tbl-note__block--success" />
                  <span>{{ $t('lbl.import.check.note.id') }}</span>
                </div>
                <div class="tbl-note__item">
                  <span class="tbl-note__block tbl-note__block--warning" />
                  <span>{{
                    $t('lbl.import.check.note.confirmed_column')
                  }}</span>
                </div>
                <div class="tbl-note__item">
                  <span class="tbl-note__block tbl-note__block--error" />
                  <span>{{ $t('lbl.import.check.note.failed_column') }}</span>
                </div>
                <div class="tbl-note__item">
                  <span class="tbl-note__block tbl-note__block--skip" />
                  <span>{{ $t('lbl.import.check.note.skip_column') }}</span>
                </div>
              </div>

              <v-row class="mt-10 justify-space-between align-center">
                <v-col md="3">
                  <p class="mb-0 font-weight-bold">
                    {{ $t('lbl.import.check.input_tag_cell') }}
                  </p>
                  <VfSelect
                    v-model="deleteTag"
                    class="reset-spacing"
                    label=""
                    :selects="deleteTagOptions"
                  />
                </v-col>
                <v-col md="2">
                  <v-btn large color="primary" @click="acceptImport">
                    {{ $t('lbl.import.check.btn_confirm_alow_data') }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>

            <template v-if="!isFileValid && !loading">
              <div>
                <p>アップロードしたCSVファイルは適切ではありません。</p>
                <p>ID列が存在していません</p>
              </div>
            </template>

            <div class="mt-10">
              <p>
                <a href="#" @click="$router.push({ name: 'ImportIndex' })">{{
                  $t('lbl.import.check.btn_back_to_import')
                }}</a>
              </p>
              <p>
                <a href="#" @click="$router.push({ name: 'FriendIndex' })">{{
                  $t('lbl.import.check.btn_back_to_list')
                }}</a>
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const MESSAGE_STATUS = {
  done: '確認済み',
  unread: '未確認',
  magazine_comment: '未返信（マガジンコメント）',
  low_important: '未返信（重要度低）',
  question: '要対応（質問）',
  trouble: '要対応（トラブル）',
  closing: '要対応（クロージング）',
  claim: '要対応（クレーム）',
}

export default {
  data() {
    return {
      deleteTag: false,
      deleteTagOptions: {
        タグを変更しない: false,
        タグを消去する: true,
      },
      isFileValid: false,
      csvDetail: {},
      loading: false,
      tagsExtend: [],
    }
  },
  mounted() {
    this.initCheck()
  },
  methods: {
    async initCheck() {
      this.loading = true
      const path = `/api/v1/importer_csv_friend_lists/${this.$route.params.id}`

      await this.axios
        .get(path)
        .then((res) => {
          this.csvDetail = res.data.importer_csv_friend_list || {}

          if (this.csvDetail.csv_data && this.csvDetail.csv_data.length) {
            let csvDataKeys = Object.keys(this.csvDetail.csv_data[0])
            this.tagsExtend = csvDataKeys.filter((key) => key.includes('(tag)'))
          }

          this.isFileValid = this.csvDetail.status !== 'failure'
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getLastMessageDate(date) {
      if (!date) return ''

      return this.$$moment(date).format('YYYY-MM-DD HH:mm:ss')
    },

    async acceptImport() {
      const csvId = this.$route.params.id || ''
      if (!csvId) {
        this.$$ajaxError('ID missing!')
      }

      const path = `/api/v1/importer_csv_friend_lists/${csvId}/import`

      this.loading = true

      await this.axios
        .post(path, this.$$reqPrms({ id: csvId, delete_tag: this.deleteTag }))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))

          this.$router.push({ name: 'FriendIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },

    getMessageStatus(status) {
      return MESSAGE_STATUS[status]
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>

<style lang="scss" scoped>
.import-check {
  .tbl-data-confirm {
    thead {
      th {
        min-width: 120px;
        width: max-content !important;
        word-break: keep-all;
        white-space: pre;
        border-right: 1px solid #ccc;
      }
    }

    tbody {
      td {
        white-space: nowrap;
        border-right: 1px solid #ccc;
      }
    }
  }

  $success: #dff0d8;
  $warning: #fcf8e3;
  $skip: #dddddd;
  $error: #f2dede;

  .item-success {
    background-color: $success;
  }

  .item-warning {
    background-color: $warning;
  }

  .item-skip {
    background-color: $skip;
  }

  .item-error {
    background-color: $error;

    td {
      background-color: $error !important;
    }
  }

  .tbl-note {
    display: flex;

    &__item {
      margin-right: 24px;
    }

    &__block {
      padding: 8px;
      margin-right: 8px;
      border: 1px solid #ddd;

      &--success {
        background-color: $success;
      }

      &--warning {
        background-color: $warning;
      }

      &--skip {
        background-color: $skip;
      }

      &--error {
        background-color: $error;
      }
    }
  }

  .reset-spacing {
    .v-text-field {
      margin: 0;
      padding: 0;
    }
  }
}
</style>
