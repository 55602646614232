<template>
  <v-card outlined>
    <v-card-text>
      <validation-observer ref="observer">
        <div v-if="'id' in defDeli">
          <v-row>
            <v-col md="8" cols="12" class="text-center">
              {{ $t('lbl.same_time_send_max') }}
            </v-col>
            <v-col md="4" cols="12" class="text-right">
              <v-btn block class="m-1" @click="templateCopy">{{
                $t('btn.template_copy')
              }}</v-btn>
              <v-btn block class="m-1" @click="preview = !preview">{{
                $t('btn.preview')
              }}</v-btn>
            </v-col>
          </v-row>
          <v-row v-for="(deli, deliIndex) in internalValue">
            <v-col :md="colMain">
              <v-card :key="deliIndex" outlined class="mb-4">
                <v-tabs
                  v-model="selectTab[deliIndex]"
                  center-active
                  centered
                  show-arrows
                  @change="tabChange(deliIndex, selectTab[deliIndex])"
                >
                  <v-tab v-for="(key, val) in opts.deli_types" :key="key">{{
                    val
                  }}</v-tab>
                </v-tabs>
                <v-tabs-items
                  v-model="selectTab[deliIndex]"
                  @change="tabChange(deliIndex, selectTab[deliIndex])"
                >
                  <v-tab-item v-for="tab in opts.deli_types" :key="tab">
                    <PtDeliText
                      v-if="deli.deli_type === 'text'"
                      :key="deliIndex"
                      v-model="deli.deli_text"
                      :vees="allVees.deli_text"
                      :flag-dialog-unicast="flagDialogUnicast"
                      :position="deliIndex"
                      :err-message-text="errMessageText"
                      :show-date-setting="showDateSetting"
                      v-on="$listeners"
                    />
                    <PtDeliStickerMobile
                      v-if="deli.deli_type === 'sticker'"
                      :key="deliIndex"
                      v-model="deli.deli_sticker"
                      :vees="allVees.deli_sticker"
                      :stickers="opts.stickers"
                    />
                    <PtDeliImage
                      v-if="deli.deli_type === 'image'"
                      :key="deliIndex"
                      v-model="deli.deli_image"
                      :vees="allVees.deli_image"
                      :vee-links="allVees.deli_image_link"
                      :link-types="opts.link_types"
                      :image-link-actions="opts.image_link_actions"
                      :link-layouts="opts.link_layouts"
                    />
                    <PtDeliVideo
                      v-if="deli.deli_type === 'video'"
                      :key="deliIndex"
                      v-model="deli.deli_video"
                      :vees="allVees.deli_video"
                    />
                    <PtDeliAudio
                      v-if="deli.deli_type === 'audio'"
                      :key="deliIndex"
                      v-model="deli.deli_audio"
                      :vees="allVees.deli_audio"
                    />
                    <PtDeliQuestion
                      v-if="deli.deli_type === 'question'"
                      :key="deliIndex"
                      v-model="deli.deli_question"
                      :vees="allVees.deli_question"
                      :vees-choice="allVees.deli_question_choice"
                      :choice-action-types="opts.choice_action_types"
                      :url-open-types="opts.url_open_types"
                      :multi-text-validates="opts.multi_text_validates"
                    />
                    <PtDeliCarousel
                      v-if="deli.deli_type === 'carousel'"
                      :key="deliIndex"
                      v-model="deli.deli_carousel"
                      :vees="allVees.deli_carousel"
                      :vees-panel="allVees.deli_carousel_panel"
                      :vees-choice="allVees.deli_carousel_panel_choice"
                      :choice-action-types="opts.choice_action_types"
                      :url-open-types="opts.url_open_types"
                      :multi-text-validates="opts.multi_text_validates"
                      :init-carousel-panel="opts.init_carousel_panel"
                      :init-carousel-panel-choice="
                        opts.init_carousel_panel_choice
                      "
                    />
                    <PtDeliLocation
                      v-if="deli.deli_type === 'location'"
                      :key="deliIndex"
                      v-model="deli.deli_location"
                      :vees="allVees.deli_location"
                    />
                    <PtDeliIntroduction
                      v-if="deli.deli_type === 'introduction'"
                      :key="deliIndex"
                      v-model="deli.deli_introduction"
                      :vees="allVees.deli_introduction"
                    />
                  </v-tab-item>
                </v-tabs-items>
                <v-card-actions>
                  {{ $t('lbl.send_time', { count: deliIndex + 1 }) }}
                  <v-spacer />
                  <v-btn
                    :disabled="internalValue.length <= 1 || deliIndex === 0"
                    small
                    icon
                    @click="sortMessage(deliIndex, -1)"
                  >
                    <v-icon>mdi-arrow-collapse-up</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="
                      internalValue.length <= 1 ||
                      deliIndex === internalValue.length - 1
                    "
                    small
                    icon
                    @click="sortMessage(deliIndex, 0)"
                  >
                    <v-icon>mdi-arrow-collapse-down</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="internalValue.length >= 5"
                    small
                    icon
                    color="primary"
                    @click="appendMessage(deliIndex)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="internalValue.length <= 1"
                    small
                    icon
                    color="error"
                    @click="deleteConfirm(deliIndex)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col v-if="preview" :md="colSub">
              <v-card outlined>
                <v-card-title>preview</v-card-title>
                <v-card-text>
                  <PtLinePreviewRow :deli="internalValue[deliIndex]" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-dialog v-model="dialog" :max-width="300">
            <v-card outlined>
              <v-card-title>{{ $t('confirm_title.delete') }}</v-card-title>
              <v-card-text>{{ $t('confirm.delete') }}</v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="dialog = false">{{ $t('btn.cancel') }}</v-btn>
                <v-btn @click="deleteMessage">{{ $t('btn.delete') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="selectDialog" :max-width="800">
            <PtDeliSelectFixedPhrase
              ref="ptselector"
              v-model="selectFixedPhrase"
              @decide-item="decideItem"
              @cancel-item="cancelItem"
            />
          </v-dialog>
        </div>
      </validation-observer>
    </v-card-text>
    <v-card-actions>
      <VSpacer />
      <v-btn @click="cancel">{{ $t('btn.cancel') }}</v-btn>
      <v-btn color="primary" @click="save">送信</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import PtDeliText from 'components/parts/common/PtDeli/PtDeliText'
import PtDeliStickerMobile from 'components/parts/common/PtDeli/PtDeliStickerMobile'
import PtDeliImage from 'components/parts/common/PtDeli/PtDeliImage'
import PtDeliVideo from 'components/parts/common/PtDeli/PtDeliVideo'
import PtDeliAudio from 'components/parts/common/PtDeli/PtDeliAudio'
import PtDeliQuestion from 'components/parts/common/PtDeli/PtDeliQuestion'
import PtDeliCarousel from 'components/parts/common/PtDeli/PtDeliCarousel'
import PtDeliLocation from 'components/parts/common/PtDeli/PtDeliLocation'
import PtDeliIntroduction from 'components/parts/common/PtDeli/PtDeliIntroduction'
import PtDeliSelectFixedPhrase from 'components/parts/common/PtDeli/PtDeliSelectFixedPhrase'
import PtLinePreviewRow from 'components/parts/common/PtLinePreviewRow'
export default {
  components: {
    PtDeliText,
    PtDeliStickerMobile,
    PtDeliImage,
    PtDeliVideo,
    PtDeliAudio,
    PtDeliQuestion,
    PtDeliCarousel,
    PtDeliLocation,
    PtDeliIntroduction,
    PtDeliSelectFixedPhrase,
    PtLinePreviewRow,
  },
  props: {
    value: { default: null },
    flagDialogUnicast: { type: Boolean },
    errMessageText: { type: Array, default: [] },
    showDateSetting: { type: Array, default: [] },
  },
  data: () => ({
    dialog: false,
    selectTab: [],
    defDeli: {},
    allVees: {},
    opts: {},
    preview: false,
    deleteTargetIndex: -1,
    selectDialog: false,
    selectFixedPhrase: {},
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    colMain() {
      return this.preview ? 8 : 12
    },
    colSub() {
      return this.preview ? 4 : 0
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const path = '/api/v1/delis/setup'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.defDeli = res.data.deli
          this.allVees = res.data.all_vees
          this.opts = res.data.opts
          if (this.internalValue.length === 0) {
            this.appendMessage(0)
          } else {
            this.updateInternalValueWithDefaultDeli()
          }
          this.tabSelectInit()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    tabChange(index, selectTab) {
      const deliTypes = Object.values(this.opts.deli_types)
      this.$set(this.internalValue[index], 'deli_type', deliTypes[selectTab])
    },
    tabSelectInit(delis) {
      delis = delis || this.internalValue
      delis.forEach((deli, idx) => {
        const tabIdx = Object.values(this.opts.deli_types).findIndex(
          (type) => type === deli.deli_type
        )
        this.$set(this.selectTab, idx, tabIdx)
      })
    },
    appendMessage(index) {
      this.internalValue.splice(index + 1, 0, this.$$clone(this.defDeli))
    },
    deleteMessage() {
      this.internalValue.splice(this.deleteTargetIndex, 1)
      this.dialog = false
      this.$emit('remove-error-text', this.deleteTargetIndex)
    },
    sortMessage(index, move) {
      const targetIdx = index + move
      const row1 = this.internalValue[targetIdx + 1]
      const row2 = this.internalValue[targetIdx]
      this.internalValue.splice(targetIdx, 2, row1, row2)
      this.tabSelectInit()
    },
    deleteConfirm(index) {
      this.deleteTargetIndex = index
      this.dialog = true
    },
    templateCopy() {
      this.selectDialog = true
    },
    async decideItem(item) {
      this.loading = true
      const path = `/api/v1/fixed_phrases/${item.id}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          var tmp = res.data.delis
          tmp.forEach((deli, index) => {
            tmp.splice(index, 1, { ...this.$$clone(this.defDeli), ...deli })
          })
          this.tabSelectInit(tmp)
          this.internalValue = tmp
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
          this.selectDialog = false
        })
    },
    cancelItem() {
      this.selectDialog = false
    },
    updateInternalValueWithDefaultDeli() {
      this.internalValue.forEach((deli, index) => {
        this.internalValue.splice(index, 1, {
          ...this.$$clone(this.defDeli),
          ...deli,
        })
      })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.$emit('save')
    },
    async cancel() {
      this.internalValue.forEach((deli) =>
        deli.deli_type === 'text' ? (deli.deli_text.text = null) : deli
      )
      this.$emit('cancel')
    },
  },
}
</script>
