<template>
  <div>
    <PtTitle />
    {{ $t('subtitle.friend') }}
    <v-row>
      <v-col cols="6">
        <PtSearchSelectedList
          v-if="searchWrap"
          v-model="searchWrap"
          class="mb-4"
          @pt-search-selected-list-save="ptSearchSelectedListSave"
          @pt-search-selected-list-clear="ptSearchSelectedListSave"
        />
      </v-col>
      <v-col cols="6" align="right">
        <v-btn @click="dialogCSV = true">csvエクスポート</v-btn>
        <v-btn @click="$router.push({ name: 'ImportIndex' })"
          >CSVインポート</v-btn
        >
      </v-col>
    </v-row>
    <v-card outlined>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <PtFriendTable
        :headers="headers"
        :items="items"
        :total-count="totalCount"
        :options.sync="options"
        :checked-items.sync="checkedItems"
        @checked-friends="checkedFriends"
        @open-dialog-message-status="openDialogMessageStatus"
      />
    </v-card>
    <v-card class="mt-4">
      <PtFriendUpdate
        :friends="items"
        :checked-items="checkedItems"
        :search-wrap="searchWrap"
        :total-count="totalCount"
        @friend_update="friendUpdate"
      />
    </v-card>
    <v-dialog v-if="hasRole" v-model="dialogMessageStatus" max-width="400">
      <v-card outlined>
        <v-card-text class="pt-6">
          <VfSelect
            v-model="selectedFriendClone.message_status"
            :label="$$tm('friend.message_status')"
            :selects="opts.message_status"
          />
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="closeDialogMessageStatus">{{
            $t('btn.cancel')
          }}</v-btn>
          <v-btn color="primary" @click="updateDialogMessageStatus">{{
            $t('btn.update')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCSV" :max-width="800">
      <v-card>
        <v-card-title> CSVダウンロード </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col
                v-for="(option, key) in csvOptions"
                :key="key"
                cols="12"
                sm="4"
                md="4"
              >
                <VfCheckbox
                  v-model="csvOptions[key].value"
                  :label="option.text"
                  :name="option.name"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <PtDialogSelect
                  v-model="dialogValue"
                  select-type="hashtag"
                  :vees="{ rules: 'required' }"
                >
                  <template #[`item.action_group`]="{ item }">
                    <PtActionLabelMessages
                      v-model="item.action_group.make_action_arr"
                      class="or-list"
                    />
                  </template>
                </PtDialogSelect>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-btn v-if="selectAllFlg" color="primary" @click="switchSelectAll">
              全選択
            </v-btn>
            <v-btn v-else color="grey lighten-2" @click="switchSelectAll">
              全解除
            </v-btn>
            <v-spacer />
            <v-btn color="primary" @click="downloadCSV"> CSVを出力 </v-btn>
            <v-btn color="primary" @click="linkToGooleSpreedSheet">
              スプレッドシートを出力
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
import PtFriendTable from 'components/parts/unique/PtFriendTable'
import PtFriendUpdate from 'components/parts/unique/PtFriendUpdate/PtFriendUpdate'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  components: {
    PtDialogSelect,
    PtSearchSelectedList,
    PtFriendTable,
    PtFriendUpdate,
    PtActionLabelMessages,
  },
  data: () => ({
    firstInit: false,
    dialogValue: { hashtags: [] },
    loading: false,
    dialogMessageStatus: false,
    dialogMessageStatusLoading: false,
    dialogCSV: false,
    dialogCSVLoading: false,
    opts: {},
    headers: [],
    items: [],
    checkedItems: [],
    selectedFriend: {},
    selectedFriendClone: {},
    selectAllFlg: false,
    searchWrap: null,
    totalCount: 0,
    options: {
      page: 1,
      per: 50,
    },
    csvOptions: {
      id: { text: 'ID', value: 1 },
      system_disp_name: { text: '表示名', value: 1 },
      api_display_name: { text: 'LINE登録名', value: 1 },
      phone: { text: '電話番号', value: 1 },
      email: { text: 'メールアドレス', value: 1 },
      message_status_str: { text: '対応マーク', value: 1 },
      api_status_message: { text: 'ステータスメッセージ', value: 1 },
      created_at: { text: '登録（フォロー）日時', value: 1 },
      hidden_flg: { text: '表示状態', value: 1 },
      blocked_flg: { text: 'ブロックされた', value: 1 },
      last_receive_deli_message: { text: '最終メッセージ', value: 1 },
      last_receive_at: { text: '最終メッセージ日時', value: 1 },
      active_scenario_name: { text: '購読中シナリオ', value: 1 },
      active_scenario_duration_days: { text: 'シナリオ日数', value: 1 },
      friend_hashtag_names: { text: 'タグ一覧', value: 1 },
    },
  }),
  computed: {
    checkedTagNames() {
      return this.dialogValue.hashtags.map((hashtag) => hashtag.name)
    },
    hasRole() {
      return this.$store.getters.roles.includes('friend_edit')
    },
  },
  watch: {
    options: {
      handler() {
        if (!this.firstInit) {
          this.firstInit = true
          return
        }
        this.options.per = this.options.itemsPerPage
        this.getFriends()
      },
      deep: true,
    },
  },
  created() {
    this.initTable(null)
  },
  methods: {
    async initTable() {
      this.loading = true
      const path = '/api/v1/friends'
      const blockedAt = this.$route.params.blocked_at
      const prms = {
        search_wrap: this.searchWrap,
        blocked_at: blockedAt,
        ...this.options,
      }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.friends
          this.totalCount = res.data.total_count
          this.searchWrap = res.data.search_wrap
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getFriends() {
      this.loading = true
      const path = '/api/v1/friends/search'
      const blockedAt = this.$route.params.blocked_at
      const prms = {
        search_wrap: this.searchWrap,
        blocked_at: blockedAt,
        ...this.options,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.friends
          this.totalCount = res.data.total_count
          this.searchWrap = res.data.search_wrap
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    checkedFriends(val) {
      this.checkedItems = val
    },
    friendUpdate() {
      this.options.per = this.options.itemsPerPage
      this.getFriends()
      this.checkedItems = []
    },
    openDialogMessageStatus(val) {
      if (!this.hasRole) {
        return
      }
      this.errorMsg = ''
      this.selectedFriend = val
      this.selectedFriendClone = this.$$clone(val)
      this.dialogMessageStatus = true
    },
    closeDialogMessageStatus() {
      this.dialogMessageStatus = false
    },
    async updateDialogMessageStatus() {
      this.errorMsg = ''
      this.dialogMessageStatusLoading = true
      const path = `/api/v1/friends/${this.selectedFriend.id}/update_message_status`
      const prms = { friend: this.selectedFriendClone }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          Object.assign(this.selectedFriend, this.selectedFriendClone)
          this.$toasted.success(this.$t('success.update'))
          this.dialogMessageStatus = false
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.dialogMessageStatusLoading = false
        })
    },
    async ptSearchSelectedListSave() {
      if (this.options.page == 1) {
        this.options.per = this.options.itemsPerPage
        this.getFriends()
      } else {
        this.options.page = 1
        this.options.per = this.options.itemsPerPage
      }
    },
    async downloadCSV() {
      this.loading = true
      const path = '/api/v1/friends/csv'
      const prms = {
        friend: this.csvOptions,
        items: this.checkedItems,
        hashtags: this.checkedTagNames,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then((res) => {
          let csv = res.data.csv
          let bom = new Uint8Array([0xef, 0xbb, 0xbf])
          let contents = csv.join('\n')
          let blob = new Blob([bom, contents], { type: 'text/csv' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = this.getToday() + '_friends.csv'
          link.click()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
          this.dialogCSV = false
        })
    },
    async linkToGooleSpreedSheet() {
      let googleUserCode
      let getGoogleSpreedsheetIdPath = `/api/v1/friends/retrieve_google_access`
      let prms = { friend: this.csvOptions, items: this.checkedItems }
      googleUserCode = await this.$gAuth
        .getAuthCode()
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
      if (googleUserCode) {
        this.dialogCSV = false
        this.$$loadingOverlayContent(true)
        prms.code = googleUserCode
        await this.$$sendForm(
          'post',
          getGoogleSpreedsheetIdPath,
          this.$$reqPrms(prms)
        )
          .then((res) => {
            if (res.data.spreadsheet_uri) {
              this.alertGoogleSpreedSheetUrl(`${res.data.spreadsheet_uri}`)
            }
          })
          .catch((error) => {
            this.$$ajaxError(error)
          })
          .finally(() => {
            this.$$loadingOverlayContent(false)
          })
      }
    },
    getToday() {
      const dt = new Date()
      const year = dt.getFullYear()
      const month = ('00' + (dt.getMonth() + 1)).slice(-2)
      const day = ('00' + dt.getDate()).slice(-2)
      const hour = ('00' + dt.getDate()).slice(-2)
      const minutes = ('00' + dt.getMinutes()).slice(-2)
      const second = ('00' + dt.getSeconds()).slice(-2)
      const result = year + month + day + hour + minutes + second
      return result
    },
    alertGoogleSpreedSheetUrl(url = '#') {
      const prefixUrl = this.$t('lbl.answer_form.google_spreedsheet_url_msg')
      const urlText = this.$t('lbl.answer_form.google_spreedsheet_url_text')
      this.$$glMsg(
        'success',
        `${prefixUrl}<a href="${url}" target="_blank">${urlText}</a>`,
        true,
        true
      )
    },
    switchSelectAll() {
      for (let key in this.csvOptions) {
        this.selectAllFlg
          ? this.$set(this.csvOptions[key], 'value', 1)
          : this.$set(this.csvOptions[key], 'value', 0)
      }
      this.selectAllFlg = !this.selectAllFlg
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
