<template>
  <div>
    <PtTitle />
    <v-row>
      <v-col v-for="(type, type_idx) in startTypes" :key="type_idx" md="6">
        <v-card outlined>
          <div class="or-card-title mb-6">
            <v-card-title>
              {{ $t(`lbl.friend_start_setting.title_${type}`) }}
            </v-card-title>
            <v-card-subtitle>
              {{ $t(`lbl.friend_start_setting.title_${type}_desc`) }}
            </v-card-subtitle>
          </div>
          <VfLoadingOverlayAbsolute :loading="loading" />
          <v-card-text>
            <PtActionSelectedList
              v-if="friendStartSettings[type]"
              v-model="friendStartSettings[type].action_arr"
              @pt-action-selected-list-save="PtActionSelectedListSave(type)"
              @pt-action-selected-list-clear="PtActionSelectedListSave(type)"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtActionSelectedList from 'components/parts/common/PtActionGroup/PtActionSelectedList'
export default {
  components: {
    PtActionSelectedList,
  },
  data: () => ({
    loading: false,
    friendStartSettings: {},
  }),
  computed: {
    startTypes() {
      return ['new', 'middle']
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/friend_start_setting/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.friendStartSettings = res.data.friend_start_settings
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    async PtActionSelectedListSave(startType) {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/friend_start_setting`
      const prms = {
        start_type: startType,
        action_arr: this.friendStartSettings[startType].action_arr,
      }
      await this.$$sendForm('put', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
