<template>
  <div>
    <v-card
      @click="dialog = true"
      class="img-box"
      width="200"
      height="200"
      color="black"
      elevation="0"
      tile
    >
      <img class="img-center" :src="deliImage.preview_url" />
    </v-card>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-text>
          <VImg :src="deliImage.image_url" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <!-- <v-btn color="primary" @click="downloadImage">{{ $t('btn.download') }}</v-btn> -->
          <v-btn @click="dialog = false">{{ $t('btn.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import { saveAs } from 'file-saver'
export default {
  props: {
    deliImage: { type: Object, required: true },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    // downloadImage () {
    //   fetch(this.deliImage.image_url)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     saveAs(blob, 'image_name.jpg');
    //   });
    // }
  },
}
</script>
<style scoped>
.img-box {
  position: relative;
}
.img-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
