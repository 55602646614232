<template>
  <div>
    <v-card class="mx-auto px-12 py-6" max-width="800">
      <v-card-title>
        <v-row justify="center">
          <div>{{ $t(`meta.title.${$route.name}`) }}</div>
        </v-row>
      </v-card-title>
      <VDivider class="mx-4" />
      <v-card-text>
        <v-row justify="center" class="text-center my-8">
          <div class="or-break">{{ $t('lbl.registration_done_desc') }}</div>
        </v-row>
        <v-row justify="center">
          <v-btn @click="done">{{ $t('btn.go_to_home') }}</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  methods: {
    done() {
      this.$$loadingOverlayContent(true)
      window.location.href = this.$router.resolve({ name: 'BaseHome' }).href
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
