<template>
  <div>
    <v-row>
      <v-col v-if="tableItems.friend.length > 0" cols="12" md="6">
        <v-card>
          <v-card-title>{{ $t('lbl.channel_role.friend') }}</v-card-title>
          <v-card-text>
            <v-data-table
              v-model="internalValue"
              :headers="headers"
              :items="tableItems.friend"
              item-key="value"
              show-select
              disable-sort
              :items-per-page="-1"
              hide-default-footer
              @click:row="rowClick"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="tableItems.sender.length > 0" cols="12" md="6">
        <v-card>
          <v-card-title>{{ $t('lbl.channel_role.sender') }}</v-card-title>
          <v-card-text>
            <v-data-table
              v-model="internalValue"
              :headers="headers"
              :items="tableItems.sender"
              item-key="value"
              show-select
              disable-sort
              :items-per-page="-1"
              hide-default-footer
              @click:row="rowClick"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="tableItems.statistics.length > 0" cols="12" md="6">
        <v-card>
          <v-card-title>{{ $t('lbl.channel_role.statistics') }}</v-card-title>
          <v-card-text>
            <v-data-table
              v-model="internalValue"
              :headers="headers"
              :items="tableItems.statistics"
              item-key="value"
              show-select
              disable-sort
              :items-per-page="-1"
              hide-default-footer
              @click:row="rowClick"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="tableItems.other.length > 0" cols="12" md="6">
        <v-card>
          <v-card-title>{{ $t('lbl.channel_role.other') }}</v-card-title>
          <v-card-text>
            <v-data-table
              v-model="internalValue"
              :headers="headers"
              :items="tableItems.other"
              item-key="value"
              show-select
              disable-sort
              :items-per-page="-1"
              hide-default-footer
              @click:row="rowClick"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Array, required: true },
    availableRoleTypes: { type: Array, required: true },
  },
  data: () => ({
    headers: [],
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    roles() {
      const baseChannelRoles = {
        friend: [
          'friend_edit',
          'friend_unicast',
          'friend_scenario',
          'friend_hashtag',
          'friend_rich_menu',
          'friend_reminder',
          'friend_schedule',
          'friend_action',
        ],
        sender: [
          'broadcast',
          'scenario',
          'reminder',
          'schedule',
          'auto_answer',
        ],
        statistics: ['answer_form', 'click', 'cross_analysis', 'inflow_route'],
        other: [
          'hashtag',
          'rich_menu',
          'friend_start_setting',
          'staff',
          'role',
          'channel',
        ],
      }
      const filterWithPlanRole = function (baseRoleTypes, availableRoleTypes) {
        return baseRoleTypes.filter((role) => availableRoleTypes.includes(role))
      }
      return {
        friend: filterWithPlanRole(
          baseChannelRoles.friend,
          this.availableRoleTypes
        ),
        sender: filterWithPlanRole(
          baseChannelRoles.sender,
          this.availableRoleTypes
        ),
        statistics: filterWithPlanRole(
          baseChannelRoles.statistics,
          this.availableRoleTypes
        ),
        other: filterWithPlanRole(
          baseChannelRoles.other,
          this.availableRoleTypes
        ),
      }
    },
    tableItems() {
      const createItems = function (_this, arr) {
        return arr.map((type) => ({
          text: _this.$t(`enum.channel_role_choice.role_choice_type.${type}`),
          value: type,
        }))
      }
      return {
        friend: createItems(this, this.roles.friend),
        sender: createItems(this, this.roles.sender),
        statistics: createItems(this, this.roles.statistics),
        other: createItems(this, this.roles.other),
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.headers = [
        {
          text: this.$$tm('channel_role_choice.role_choice_type'),
          value: 'text',
        },
      ]
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
  },
}
</script>
