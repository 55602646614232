<template>
  <div>
    <v-card class="mt-2">
      <v-card-title>
        {{ $t('lbl.rich_menu.area') }}
      </v-card-title>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-text>
        <v-row no-gutters>
          <v-col>
            <v-card
              v-for="(
                rich_menu_content, contIndex
              ) in internalValue.rich_menu_contents"
              :key="contIndex"
              class="mt-2"
            >
              <v-card-text :key="contIndex">
                <v-card-title
                  >ボタン{{ rich_menu_content.sort_no }}</v-card-title
                >
                <VfSelect
                  v-model="rich_menu_content.action_type"
                  :selects="actionTypes"
                  :label="$$tm('rich_menu_content.click_text')"
                  :vees="vees.action_type"
                />
                <VfTextField
                  v-if="
                    rich_menu_content.action_type === 'url_open' ||
                    rich_menu_content.action_type === 'tell_phone'
                  "
                  :key="rich_menu_content.action_type"
                  v-model="rich_menu_content.multi_text"
                  :label="$t(`lbl.${rich_menu_content.action_type}_text`)"
                  :vees="vees.multi_text"
                  counter
                />
                <v-card-text v-if="rich_menu_content.action_type === 'action'">
                  <PtActionSelectedList
                    v-model="rich_menu_content.action_arr"
                  />
                </v-card-text>
                <PtDialogSelect
                  v-if="rich_menu_content.action_type === 'questionnaire'"
                  v-model="internalValue.rich_menu_contents[contIndex]"
                  select-type="answer_form"
                  single-select
                  :vees="vees.answer_form_id"
                />
                <VfSelect
                  v-if="rich_menu_content.action_type === 'other'"
                  v-model="rich_menu_content.other_open_type"
                  :selects="otherOpenTypes"
                  :label="$$tm('rich_menu_content.other_text')"
                  :vees="vees.other_open_type"
                />
                <v-btn
                  :disabled="internalValue.length <= 1 || contIndex === 0"
                  small
                  icon
                  @click="sortContent(contIndex, -1)"
                >
                  <v-icon>mdi-arrow-collapse-up</v-icon>
                </v-btn>
                <v-btn
                  :disabled="
                    internalValue.length <= 1 ||
                    contIndex === internalValue.rich_menu_contents.length - 1
                  "
                  small
                  icon
                  @click="sortContent(contIndex, 0)"
                >
                  <v-icon>mdi-arrow-collapse-down</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PtActionSelectedList from 'components/parts/common/PtActionGroup/PtActionSelectedList'
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtActionSelectedList,
    PtDialogSelect,
  },
  props: {
    value: { type: Object, required: true },
    actionTypes: { type: Object, required: true },
    otherOpenTypes: { type: Object, required: true },
    vees: { type: Object, default: () => ({}) },
  },
  data: () => ({
    loading: false,
    deleteDialog: false,
    changePrms1: {},
    changePrms2: {},
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    sortContent(index, move) {
      const targetIdx = index + move
      const row1 = this.internalValue.rich_menu_contents[targetIdx + 1]
      const row2 = this.internalValue.rich_menu_contents[targetIdx]
      const contentRow1 = {
        action_type: row1['action_type'],
        multi_text: row1['multi_text'],
        other_open_type: row1['other_open_type'],
        action_arr: row1['action_arr'],
      }
      const contentRow2 = {
        action_type: row2['action_type'],
        multi_text: row2['multi_text'],
        other_open_type: row2['other_open_type'],
        action_arr: row2['action_arr'],
      }
      this.changePrms1 = Object.assign({}, contentRow2)
      this.changePrms2 = Object.assign({}, contentRow1)
      Object.assign(row1, this.changePrms1)
      Object.assign(row2, this.changePrms2)
    },
  },
}
</script>
