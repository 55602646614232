<template>
  <div class="call-tag">
    <p>{{ $t('lbl.answer_form.call_tag') }}</p>
    <v-row>
      <v-col class="d-flex">
        <div class="call-tag__value">
          {{ formKey }}
        </div>
        <v-btn class="call-tag__button" @click="openForm">{{
          $t('lbl.answer_form.call_tag_btn')
        }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ['formKey'],
  methods: {
    openForm() {
      let routeData = this.$router.resolve({
        name: 'AnswerFormAnswer',
        params: { uuid: this.formKey },
      })
      window.open(routeData.href + '?mode=example', '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.call-tag {
  &__value {
    border-radius: 4px 0 0 4px;
    border: 1px solid;
    padding: 6px 10px;
    height: 36px;
    width: 300px;
  }

  &__button {
    box-shadow: unset;
    border-radius: 0 4px 4px 0;
    border: 1px solid;
    border-left-color: transparent;
  }
}
</style>
