<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
        {{ $t('subtitle.inflow_route_form') }}
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn v-if="inflowRouteId !== 'new'" color="error" @click="destroy">
            {{ $t('btn.delete') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.inflow_route.info') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="5">
              <VfSelect
                v-model="inflowRoute.folder_id"
                :label="$$tm('folder.name')"
                :selects="folders"
              />
            </v-col>
            <v-col md="5">
              <VfTextField
                v-model="inflowRoute.name"
                :label="$$tm('inflow_route.name')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <VfTextField
                v-model="inflowRoute.qr_text"
                :label="$$tm('inflow_route.qr_text')"
                :vees="vees.qr_text"
                counter
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.inflow_route.action') }}
        </v-card-title>
        <v-card-text>
          <PtActionSelectedList v-model="inflowRoute.action_arr" />
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-text>
          <v-row>
            <v-col>
              <VfSwitch
                v-model="inflowRoute.action_skip_flg"
                :label="$$tm('inflow_route.action_skip_flg')"
              />
            </v-col>
            <v-col>
              <VfSwitch
                v-model="inflowRoute.action_exec_type"
                :label="$$tm('inflow_route.action_exec_type')"
              />
            </v-col>
            <v-col cols="12">
              <v-alert
                class="or-break"
                border="left"
                colored-border
                color="deep-purple accent-4"
                elevation="2"
                style="font-size: 12px"
                >{{ $t('inflow_route.inflow_route_friend_desc') }}</v-alert
              >
            </v-col>
            <v-col cols="12">
              <v-alert
                class="or-break"
                border="left"
                colored-border
                color="deep-purple accent-4"
                elevation="2"
                style="font-size: 12px"
                >{{ $t('inflow_route.inflow_route_action_desc') }}</v-alert
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row>
        <v-col class="text-right">
          <v-btn @click="$router.push({ name: 'InflowRouteIndex' })">{{
            $t('btn.back')
          }}</v-btn>
          <v-btn
            color="primary"
            :loading="$wait.is('processing')"
            @click="save"
            >{{ $t('btn.create') }}</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete_inflow_route')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtActionSelectedList from 'components/parts/common/PtActionGroup/PtActionSelectedList'
export default {
  components: {
    PtActionSelectedList,
  },
  data: () => ({
    initDone: false,
    inflowRouteId: '',
    inflowRoute: {},
    inflowRouteReact: {},
    folders: {},
    opts: {},
    vees: {},
    savePath: '',
    deleteDialog: false,
  }),
  computed: {
    DAYS() {
      return [
        'sun_flg',
        'mon_flg',
        'tue_flg',
        'wed_flg',
        'thu_flg',
        'fri_flg',
        'sat_flg',
      ]
    },
    selectedDaysCount() {
      return this.DAYS.reduce((acc, day) => acc + this.inflowRoute[day], 0)
    },
    allSelected: {
      get() {
        return this.selectedDaysCount === this.DAYS.length ? 1 : 0
      },
      set(value) {
        this.DAYS.forEach((day) => (this.inflowRoute[day] = value))
      },
    },
    indeterminate() {
      return this.selectedDaysCount !== 0 && this.allSelected === 0
    },
    stTm: {
      get() {
        return this.$$ftm(this.inflowRoute.st_tm)
      },
      set(value) {
        this.inflowRoute.st_tm = value ? `2000-01-01 ${value}` : ''
      },
    },
    edTm: {
      get() {
        return this.$$ftm(this.inflowRoute.ed_tm)
      },
      set(value) {
        this.inflowRoute.ed_tm = value ? `2000-01-01 ${value}` : ''
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.inflowRouteId = this.$route.params.id
      const path =
        this.inflowRouteId === 'new'
          ? '/api/v1/inflow_routes/new'
          : `/api/v1/inflow_routes/${this.inflowRouteId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.inflowRoute = res.data.inflow_route
          this.folders = res.data.folders
          this.vees = res.data.vees
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.inflowRouteId === 'new'
          ? '/api/v1/inflow_routes'
          : `/api/v1/inflow_routes/${this.inflowRouteId}`
      const method = this.inflowRouteId === 'new' ? 'post' : 'put'
      const prms = {
        inflow_route: this.inflowRoute,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'InflowRouteIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    addReact() {
      this.inflowRoute.inflow_route_reacts.push(
        this.$$clone(this.inflowRouteReact)
      )
    },
    removeReact(index) {
      this.inflowRoute.inflow_route_reacts.splice(index, 1)
    },
    destroy() {
      this.savePath = `/api/v1/inflow_routes/${this.inflowRouteId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'InflowRouteIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.time-pick {
  width: 100px;
}
#day_of_the_week ::v-deep .v-label {
  margin-top: 7px;
}
</style>
