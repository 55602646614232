import 'babel-polyfill'
import 'orgn-style.scss'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import store from 'store'
import router from 'router/index'
import axios from 'plugins/axios'
import wait from 'plugins/vue-wait'
import i18n from 'plugins/vue-i18n'
import vuetify from 'plugins/vuetify'
import VeeValidate from 'plugins/vee-validate'
import VueLazyload from 'plugins/vue-lazyload'
import VueGoogleMaps from 'plugins/vue2-google-maps'
import GoogleAuth from 'plugins/google_oAuth.js'
import VueClipboard from 'vue-clipboard2'
import FilePond from 'plugins/vue-filepond'
import toasted from 'plugins/toasted'
import OrgnSession from 'plugins/orgn-session'
import OrgnUtil from 'plugins/orgn-util'
import OrgnParts from 'plugins/orgn-parts'
import OrgnVuetifyParts from 'plugins/orgn-vuetify-parts'
import App from 'App.vue'
import sanitizeHTML from 'sanitize-html'

Vue.prototype.$sanitize = sanitizeHTML
Vue.use(OrgnSession)
Vue.use(VueMeta)
Vue.use(axios)
Vue.use(VeeValidate)
Vue.use(VueLazyload)
Vue.use(VueGoogleMaps)
Vue.use(VueClipboard)
Vue.use(toasted)
Vue.use(OrgnParts)
Vue.use(OrgnVuetifyParts)
Vue.mixin(OrgnUtil)
Vue.component(FilePond)

const gauthOption = {
  clientId:
    '810519601030-jtk4ktr1nb4r7oa3v881fe757e0ri2jq.apps.googleusercontent.com',
  prompt: 'consent',
}
Vue.use(GoogleAuth, gauthOption)

new Vue({
  store,
  router,
  wait,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
