<template>
  <div>
    <v-card>
      <v-card-title class="or-card-title mb-6">
        {{ $t('lbl.reminder.timing') }}
      </v-card-title>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        <v-row>
          <v-col md="8">
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              clearable
              single-line
              hide-details
            />
          </v-col>
          <v-col md="4" class="text-right">
            <v-btn color="primary" :disabled="fremActiveFlg" @click="newItem">{{
              $t('btn.new_item')
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        item-key="id"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageOptions: [30, 50, 100],
        }"
      >
        <template #[`item.sch_day`]="{ item }">
          <template v-if="item.reminder_timing_type === 'start'">
            <a href="#" @click="editItem(item)">
              {{ $$te('reminder_timing.reminder_timing_type', item) }}
            </a>
          </template>
          <template v-else>
            <a href="#" @click="editItem(item)"> {{ item.sch_day }}日前 </a>
          </template>
        </template>
        <template #[`item.sch_time`]="{ item }">
          <template v-if="item.reminder_timing_type !== 'start'">
            {{ $$ftm(item.sch_time) }}
          </template>
        </template>
        <template #[`item.action_group`]="{ item }">
          <div>
            <PtActionLabelMessages
              v-model="item.action_group.make_action_arr"
              class="or-list"
            />
          </div>
        </template>
        <template v-for="item in items" #[`item.preview`]="{ item }">
          <div v-for="broadcast in item.action_group.broadcasts">
            <v-btn class="my-1" depressed x-small @click="showItem(broadcast)"
              >プレビュー</v-btn
            >
          </div>
        </template>
      </v-data-table>
    </v-card>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
    <PtDialogLinePreview
      :key="selectedItem.id"
      v-model="previewDialog"
      type="broadcast"
      :model-id="selectedItem.id"
    />
  </div>
</template>
<script>
import PtDialogLinePreview from 'components/parts/common/PtDialogLinePreview'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  components: {
    PtDialogLinePreview,
    PtActionLabelMessages,
  },
  props: {
    reminderId: { type: String, required: true },
    fremActiveFlg: { type: Boolean, required: true },
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    search: '',
    savePath: '',
    deleteDialog: false,
    previewDialog: false,
    selectedItem: { id: '' },
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/reminder_timings'
      const prms = { reminder_id: this.reminderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.reminder_timings
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    newItem() {
      this.$router.push({
        name: 'ReminderTimingForm',
        params: { id: 'new' },
        query: { reminder_id: this.reminderId },
      })
    },
    editItem(item) {
      this.$router.push({
        name: 'ReminderTimingForm',
        params: { id: item.id },
        query: { reminder_id: this.reminderId },
      })
    },
    deleteItem(item) {
      this.savePath = `/api/v1/reminder_timings/${item.id}?reminder_id=${this.reminderId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.init()
    },
    showItem(item) {
      this.selectedItem = item
      this.previewDialog = true
    },
  },
}
</script>
<style scoped>
.or-list {
  max-width: 300px;
  font-size: 0.5em;
}
</style>
