<template>
  <div>
    <PtTitle />
    {{ $t('subtitle.scenario') }}
    <v-card outlined>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        <v-row>
          <v-col md="8">
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              clearable
              single-line
              hide-details
            />
          </v-col>
          <v-col md="4" class="text-right">
            <v-btn color="primary" @click="newItem">{{
              $t('btn.new_item')
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        item-key="id"
        :disable-sort="true"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150],
        }"
      >
        <template #[`item.name`]="{ item }">
          <a href="#" @click="editItem(item)">
            {{ item.name }}
          </a>
        </template>
        <template #[`item.scenario_status`]="{ item }">
          {{ $$te('scenario.scenario_status', item) }}
        </template>
        <template #[`item.scenario_type`]="{ item }">
          {{ $$te('scenario.scenario_type', item) }}
        </template>
        <template #[`item.created_at`]="{ item }">
          {{ $$fdt(item.created_at) }}
        </template>
        <template #[`item.action`]="{ item }">
          <v-btn @click="setCopyId(item.id)">{{ $t('btn.copy') }} </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
    <PtDialogLinePreview
      :key="selectedItem.id"
      v-model="previewDialog"
      type="scenario"
      :model-id="selectedItem.id"
    />
    <BtnDialogCopyScenario
      v-model="copyDialog"
      :scenario-copy-id="selectedCopyId"
      @clear-copy-id="clearCopyId"
    />
  </div>
</template>
<script>
import PtDialogLinePreview from 'components/parts/common/PtDialogLinePreview'
import BtnDialogCopyScenario from './Index/BtnDialogCopyScenario'

export default {
  components: {
    BtnDialogCopyScenario,
    PtDialogLinePreview,
  },
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    search: '',
    savePath: '',
    deleteDialog: false,
    previewDialog: false,
    copyDialog: false,
    selectedItem: { id: '' },
    selectedCopyId: '',
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/scenarios'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.headers = res.data.thead
          this.headers[1]['text'] = '配信方法'
          this.items = res.data.scenarios
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    newItem() {
      this.$router.push({ name: 'ScenarioForm', params: { id: 'new' } })
    },
    editItem(item) {
      this.$router.push({ name: 'ScenarioForm', params: { id: item.id } })
    },
    deleteItem(item) {
      this.savePath = `/api/v1/scenarios/${item.id}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.init()
    },
    showItem(item) {
      this.selectedItem = item
      this.previewDialog = true
    },
    setCopyId(itemId) {
      this.copyDialog = true
      this.selectedCopyId = itemId
    },
    clearCopyId() {
      this.copyDialog = false
      this.selectedCopyId = ''
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
