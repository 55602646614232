<template>
  <div>
    <v-row>
      <v-col md="4">
        <PtUnicastFriendIndex @select-friend="selectFriend" />
      </v-col>
      <v-col md="5">
        <PtUnicastMessageIndex
          :key="friendId"
          :friend-id="friendId"
          :first-time-flg="first_time_flg"
        />
      </v-col>
      <v-col md="3">
        <PtUnicastFriendShow
          :key="friendId"
          :friend-id="friendId"
          :first-time-flg="first_time_flg"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtUnicastFriendIndex from 'components/parts/unique/PtUnicastFriendIndex'
import PtUnicastMessageIndex from 'components/parts/unique/PtUnicastMessageIndex'
import PtUnicastFriendShow from 'components/parts/unique/PtUnicastFriendShow'
export default {
  components: {
    PtUnicastFriendIndex,
    PtUnicastMessageIndex,
    PtUnicastFriendShow,
  },
  data: () => ({
    friendId: '',
    query_flg: false,
    first_time_flg: true,
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.first_time_flg = false
        this.friendId = this.$route.query.id
      }
    },
    selectFriend(val) {
      this.first_time_flg = false
      if (this.$route.query.id && this.query_flg == false) {
        this.friendId = this.$route.query.id
        this.query_flg = true
      } else {
        this.friendId = val
      }
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
