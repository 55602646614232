<template>
  <div>
    <v-row>
      <v-col>
        <PtTitle :title="crossAnalysis.name" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="5">
        <v-btn @click="downloadCSV">
          {{ $t('lbl.cross_analysis.download_csv') }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="1">
        <p class="pt-2" v-text="$t('lbl.cross_analysis.sort')" />
      </v-col>
      <v-col cols="12" md="2" class="pt-0">
        <VfSelect
          v-model="selectCol"
          label=""
          :selects="optionSelectCols"
          hide-details
        />
      </v-col>
      <v-col cols="12" md="1" class="pt-0">
        <VfSelect
          v-model="typeSort"
          label=""
          :selects="optionSorts"
          hide-details
        />
      </v-col>
      <v-col cols="12" md="3" align="right">
        <v-btn color="warning" @click="recalculation">
          <v-icon left>mdi-autorenew</v-icon>
          {{ $t('lbl.cross_analysis.recalculation') }}
        </v-btn>
        <v-btn color="primary" @click="editItem">
          {{ $t('btn.edit') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-text>
        <v-simple-table class="cross-analysis-table">
          <thead>
            <tr>
              <template v-for="item in tableHeaders">
                <td v-if="item.csv_col_no === 0">{{ item.disp }}</td>
                <td
                  v-else-if="item.disp === 'is_search_wrap'"
                  :colspan="tableColspan"
                >
                  <PtSearchSelectedList
                    v-model="item.search_wrap.as_json_form"
                    class="or-list"
                    disp-type="list"
                  />
                </td>
                <td v-else :colspan="tableColspan">{{ item.disp }}</td>
              </template>
            </tr>
            <tr v-if="dispBlockedCount">
              <template v-for="item in tableHeaders">
                <template v-if="item.csv_col_no === 0">
                  <td />
                </template>
                <template v-else>
                  <td>到達数</td>
                  <td>相手からブロック</td>
                </template>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in tableBodies">
              <template v-for="item in row">
                <template v-if="item.csv_col_no === 0">
                  <td>{{ item.disp }}</td>
                </template>
                <template v-else>
                  <td>
                    <span class="underline-link" @click="searchFriend(item)">{{
                      item.disp
                    }}</span>
                  </td>
                  <td v-if="dispBlockedCount">
                    <span
                      class="underline-link"
                      @click="searchBlockedFriend(item)"
                      >{{ item.blocked_count }}</span
                    >
                  </td>
                </template>
              </template>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
export default {
  components: {
    PtSearchSelectedList,
  },
  data: () => ({
    crossAnalysis: {},
    rows: [],
    headers: [],
    selectCol: null,
    typeSort: null,
    recalc: 0,
  }),
  computed: {
    optionSorts() {
      return {
        [this.$t('lbl.cross_analysis.desc_order')]: 'desc',
        [this.$t('lbl.cross_analysis.asc_order')]: 'asc',
      }
    },
    tableHeaders() {
      return this.rows[0]
    },
    tableBodies() {
      return this.rows.filter((row) => row[0].csv_row_no !== 0)
    },
    dispBlockedCount() {
      return this.crossAnalysis.disp_blocked_user_flg === 1
    },
    tableColspan() {
      return this.dispBlockedCount ? 2 : 1
    },
    optionSelectCols() {
      let obj = {}
      this.headers = []
      if (!this.rows.length) {
        return obj
      }
      this.rows[0].forEach((row) => {
        if (row.disp !== 'is_search_wrap') {
          this.headers.push(row.disp)
          obj[row.disp] = row.csv_col_no
        } else {
          let text = this.getColText(row.search_wrap.as_json_form.search_arr)
          this.headers.push(text)
          obj[text] = row.csv_col_no
        }
      })
      return obj
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      const path = '/api/v1/cross_analyses/setup'
      const prms = {
        cross_analysis_id: this.$route.params.id,
        recalc: this.recalc,
        sort_by_col: this.selectCol,
        sort: this.typeSort,
      }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.crossAnalysis = res.data.cross_analysis
          this.rows = res.data.rows
          this.recalc = 0
          this.initDefaultSort(res.data.cross_analysis)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    initDefaultSort(crossAnalysis) {
      if (crossAnalysis) {
        this.selectCol = crossAnalysis.col_sort_default
        this.typeSort = crossAnalysis.sort_default
      }
    },
    editItem() {
      this.$router.push({
        name: 'CrossAnalysisForm',
        params: { id: this.$route.params.id },
      })
    },
    recalculation() {
      this.recalc = 1
      this.init()
    },
    async downloadCSV() {
      this.$$loadingOverlayContent(true)
      let headersClone = this.$$clone(this.headers)
      headersClone.forEach((header, index) => {
        headersClone[index] = header.replaceAll(',', '、')
      })
      let headers = headersClone.join(',')
      const path = '/api/v1/cross_analyses/download_csv'
      const prms = {
        cross_analysis_id: this.$route.params.id,
        headers: headers,
      }
      await this.axios
        .post(path, this.$$reqPrms(prms))
        .then((res) => {
          let csv = res.data.csv
          let bom = new Uint8Array([0xef, 0xbb, 0xbf])
          let contents = csv.join('\n')
          let blob = new Blob([bom, contents], { type: 'text/csv' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download =
            this.crossAnalysis.name + '_' + this.getToday() + '.csv'
          link.click()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    searchFriend(item) {
      this.$router.push({
        name: 'FriendIndex',
        query: { search_wrap_id: item.search_wrap_id },
      })
    },
    searchBlockedFriend(item) {
      this.$router.push({
        name: 'FriendIndex',
        query: {
          search_wrap_id: item.search_wrap_id,
          blocked_user_type: 'only_blocked',
        },
      })
    },
    getColText(search_arr) {
      let text = ''
      let data = this.searchWrapMsgs(search_arr)
      if (data.length === 0) {
        return text
      }
      data.forEach((searchGroup, index) => {
        if (index > 0) {
          text += this.$t('lbl.search_wrap.or')
        }
        if (searchGroup.length) {
          searchGroup.forEach((msg) => {
            text += msg.operate
          })
        }
      })
      return text
    },
    getToday() {
      const dt = new Date()
      const year = dt.getFullYear()
      const month = ('00' + (dt.getMonth() + 1)).slice(-2)
      const day = ('00' + dt.getDate()).slice(-2)
      const hour = ('00' + dt.getDate()).slice(-2)
      const minutes = ('00' + dt.getMinutes()).slice(-2)
      const second = ('00' + dt.getSeconds()).slice(-2)
      return year + month + day + hour + minutes + second
    },
    searchWrapMsgs(search_arr) {
      if (!search_arr) {
        return [[]]
      }
      return search_arr.map((group) => {
        return group.map((search, index) => {
          const yml = `lbl.search_wrap.search_types_short_msg.${search.type}`
          const ret = {}
          ret.no = index + 1
          switch (search.type) {
            case 'name': {
              ret.type = this.$t(yml)
              const names = []
              if (search.data.api_display_name_flg) {
                names.push(this.$$tm('search_name.api_display_name_flg'))
              }
              if (search.data.system_disp_name_flg) {
                names.push(this.$$tm('search_name.system_disp_name_flg'))
              }
              ret.operate = this.$t('lbl.search_wrap.name_include', {
                targets: names.join(', '),
                name: search.data.name,
              })
              break
            }
            case 'message_status': {
              ret.type = this.$t(yml)
              const sts_arr = search.data.target_message_statuses.map((sts) =>
                this.$t(
                  `enum.search_message_status_choice.message_status.${sts}`
                )
              )
              ret.operate = this.$t('lbl.search_wrap.message_status_in', {
                message_statuses: sts_arr.join(', '),
              })
              break
            }
            case 'hashtag': {
              ret.type = this.$t(yml)
              const type = this.$$te(
                'search_hashtag.search_hashtag_type',
                search.data
              )
              const tags = search.data.hashtags.map((hashtag) => hashtag.name)
              ret.operate = `[${type}][${tags.join(', ')}]`
              break
            }
            case 'scenario':
              ret.type = this.$$te(
                'search_scenario.scenario_read_type',
                search.data
              )
              if (search.data.scenario_read_type === 'stop') {
                break
              }
              ret.operate = this.$t('lbl.search_wrap.scenario_is', {
                scenario: search.data.scenario.name,
                type: this.$$te(
                  'search_scenario.search_scenario_type',
                  search.data
                ),
              })
              break
            case 'reminder':
              ret.type = this.$t(yml)
              ret.operate = this.$t('lbl.search_wrap.reminder_is', {
                reminder: search.data.reminder.name,
                type: this.$$te(
                  'search_reminder.search_reminder_type',
                  search.data
                ),
              })
              break
            case 'last_response':
              ret.type = this.$t(yml)
              ret.operate = this.$t('lbl.search_wrap.last_response_is', {
                from: this.$$fdt(search.data.range_st_dt),
                to: this.$$fdt(search.data.range_ed_dt),
              })
              break
            case 'friend_created_at':
              ret.type = this.$t(yml)
              ret.operate = this.$t('lbl.search_wrap.friend_created_at_is', {
                from: this.$$fdt(search.data.range_st_dt),
                to: this.$$fdt(search.data.range_ed_dt),
              })
              break
            case 'inflow_route': {
              ret.type = this.$t(yml)
              const inflow_route_type = this.$$te(
                'search_inflow_route.search_inflow_route_type',
                search.data
              )
              const inflow_route_tags = search.data.inflow_routes.map(
                (inflow_route) => inflow_route.name
              )
              ret.operate = `[${inflow_route_type}][${inflow_route_tags.join(
                ', '
              )}]`
              break
            }
          }
          return ret
        })
      })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.cross-analysis-table td {
  border: 1px solid #dddddd !important;
}
.underline-link {
  cursor: pointer;
  color: #1976d2;
  text-decoration: underline;
}
</style>
