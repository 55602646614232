import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    channel: null,
    roles: null,
    loadingOverlay: false,
    loadingOverlayContent: false,
    globalMessageType: null,
    globalMessage: null,
    isHtml: null,
    isAlertText: false,
    unreadMessageCount: 0,
  },
  getters: {
    user: (state) => state.user,
    channel: (state) => state.channel,
    roles: (state) => state.roles,
    loadingOverlay: (state) => state.loadingOverlay,
    loadingOverlayContent: (state) => state.loadingOverlayContent,
    globalMessageType: (state) => state.globalMessageType,
    globalMessage: (state) => state.globalMessage,
    isHtml: (state) => state.isHtml,
    isAlertText: (state) => state.isAlertText,
    unreadMessageCount: (state) => state.unreadMessageCount,
  },
  mutations: {
    user(state, value) {
      state.user = value
    },
    channel(state, value) {
      state.channel = value
    },
    roles(state, value) {
      state.roles = value
    },
    loadingOverlay(state, value) {
      state.loadingOverlay = value
    },
    loadingOverlayContent(state, value) {
      state.loadingOverlayContent = value
    },
    globalMessageType(state, value) {
      state.globalMessageType = value
    },
    globalMessage(state, value) {
      state.globalMessage = value
    },
    isHtml(state, value) {
      state.isHtml = value
    },
    isAlertText(state, value) {
      state.isAlertText = value
    },
    updateUnreadCount(state, value) {
      state.unreadMessageCount = value
    },
  },
  actions: {
    login({ commit }, payload) {
      commit('user', payload.data.data)
    },
    logout({ commit }) {
      commit('user', null)
      commit('channel', null)
      commit('roles', null)
    },
    selectChannel({ commit }, payload) {
      commit('channel', payload)
    },
    selectChannelRoles({ commit }, payload) {
      commit('roles', payload)
    },
    loadingOverlay({ commit }, payload) {
      commit('loadingOverlay', payload)
    },
    loadingOverlayContent({ commit }, payload) {
      commit('loadingOverlayContent', payload)
    },
    glMsg({ commit }, payload) {
      commit('globalMessageType', payload.globalMessageType)
      commit('globalMessage', payload.globalMessage)
      commit('isHtml', payload.isHtml)
      commit('isAlertText', payload.isAlertText)
    },
    glMsgAdd({ commit, getters }, payload) {
      commit('globalMessage', getters.globalMessage + '\n' + payload)
    },
    updateUnreadCount({ commit }, payload) {
      commit('updateUnreadCount', payload)
    },
  },
})
