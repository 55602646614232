<template>
  <div>
    <template>
      <div v-for="msg in actionLabelMsgs" :key="msg.no">
        {{ msg.label }}
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    value: { default: null },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    actionLabelMsgs() {
      return this.internalValue.map((action, index) => {
        const yml = `lbl.action_group.action_types_short_label_msg.${action.type}`
        const ret = {}
        ret.no = index + 1
        switch (action.type) {
          case 'broadcast': {
            let operate = ''
            action.data.target_delis.forEach((deli) => {
              switch (deli.deli_type) {
                case 'text':
                  operate += `[${this.$$tranc(deli.deli_text.text)}]`
                  break
                default:
                  operate += `[${this.$$te('deli.deli_type', deli)}]`
                  break
              }
            })
            ret.label = this.$t(`${yml}`, { text: operate })
            break
          }
          case 'fixed_phrase':
            ret.label = this.$t(`${yml}`, {
              text: `[${action.data.fixed_phrase.name}]`,
            })
            break
          case 'hashtag':
            ret.label = this.$t(`${yml}.${action.data.hashtag_action_type}`, {
              text: `${action.data.hashtags
                .map((row) => `[${row['name']}]`)
                .join('')}`,
            })
            break
          case 'scenario': {
            let operate = ''
            if (action.data.scenario_action_type == 'start') {
              operate = `[${action.data.scenario.name}]`
            }
            ret.label = this.$t(`${yml}.${action.data.scenario_action_type}`, {
              text: operate,
            })
            break
          }
          case 'reminder':
            ret.label = this.$t(`${yml}.${action.data.reminder_action_type}`, {
              text: `[${action.data.reminder.name}]`,
            })
            break
          case 'label': {
            const cols = ['change_message_status', 'change_friend_status']
            let operate = ''
            cols.forEach((col) => {
              if (action.data[col] != 'none') {
                const target = `action_label.${col}`
                operate += `[${this.$$tm(target)}: ${this.$$te(
                  target,
                  action.data
                )}]`
              }
            })
            ret.label = this.$t(`${yml}`, { text: operate })
            break
          }
          case 'notice':
            ret.label = this.$t(`${yml}`, {
              text: `[${this.$$tranc(action.data.text)}]`,
            })
            break
          case 'rich_menu': {
            let operate = ''
            if (action.data.rich_menu_action_type == 'start') {
              operate = `[${action.data.rich_menu.name}]`
            }
            ret.label = this.$t(`${yml}.${action.data.rich_menu_action_type}`, {
              text: operate,
            })
            break
          }
        }
        return ret
      })
    },
  },
}
</script>
