<template>
  <validation-provider
    v-slot="{ errors }"
    :name="label"
    :rules="rules"
    :vid="setVid"
  >
    <VTextField
      v-model="internalValue"
      v-bind="baseProps"
      :error-messages="errors"
      type="number"
      :min="minValue"
      @change="onlyNumber"
      @input="$emit('input', $event)"
      @keypress="onlyNumber"
    />
  </validation-provider>
</template>
<script>
import mixin from 'components/vuetifyParts/commonExport'
export default {
  mixins: [mixin],
  props: {
    value: { default: null },
    minValue: { type: Number, default: 0 },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    setVid() {
      return Math.random().toString()
    },
  },
  methods: {
    onlyNumber(e) {
      const keyCode = e.keyCode || e.which
      if (
        (![8, 0, 45].includes(keyCode) && (keyCode < 48 || keyCode > 57)) ||
        e.key == '-'
      ) {
        e.preventDefault()
      }
    },
  },
}
</script>
