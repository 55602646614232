import { render, staticRenderFns } from "./PtFileInfo.vue?vue&type=template&id=49d4ec79&scoped=true&"
import script from "./PtFileInfo.vue?vue&type=script&lang=js&"
export * from "./PtFileInfo.vue?vue&type=script&lang=js&"
import style0 from "./PtFileInfo.vue?vue&type=style&index=0&id=49d4ec79&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d4ec79",
  null
  
)

export default component.exports