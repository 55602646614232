<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle />
      </v-col>
      <v-col cols="4" align="right" class="pt-8">
        <v-btn color="primary" @click="newItem">{{ $t('btn.new_item') }}</v-btn>
      </v-col>
    </v-row>
    <div class="mb-4">{{ $t('subtitle.admin_info') }}</div>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :disable-sort="true"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150],
        }"
      >
        <template #[`item.title`]="{ item }">
          <a href="#" @click="editItem(item)">
            {{ item.title }}
          </a>
        </template>
        <template #[`item.disp_st_at`]="{ item }">
          {{ $$fat(item.disp_st_at) }}
        </template>
        <template #[`item.desc`]="{ item }">
          {{ $$tranc(item.desc, 40) }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn depressed x-small @click="showItem(item)"> プレビュー </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="previewDialog" max-width="800">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="4">
              {{ $$fat(selectedItem.disp_st_at) }}
            </v-col>
            <v-col cols="8">
              {{ selectedItem.title }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <v-card-subtitle>
          {{ selectedItem.subtitle }}
        </v-card-subtitle>
        <v-card-text>
          <div class="or-break">{{ selectedItem.desc }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    selectedItem: {},
    previewDialog: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.errorMsg = ''
      this.loading = true
      const path = '/api/v1/admin_infos'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.items = res.data.admin_infos
          this.items = this.items.reverse()
          this.headers = res.data.thead
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.loading = false
        })
    },
    newItem() {
      this.$router.push({ name: 'AdminInfosForm', params: { id: 'new' } })
    },
    showItem(item) {
      this.selectedItem = item
      this.previewDialog = true
    },
    editItem(item) {
      this.$router.push({ name: 'AdminInfosForm', params: { id: item.id } })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
