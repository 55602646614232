<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn v-if="autoAnswerId !== 'new'" color="error" @click="destroy">
            {{ $t('btn.delete') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.auto_answer.info') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="5">
              <VfSelect
                v-model="autoAnswer.folder_id"
                :label="$$tm('folder.name')"
                :vees="vees.folder_id"
                :selects="folders"
              />
            </v-col>
            <v-col md="5">
              <VfTextField
                v-model="autoAnswer.name"
                :label="$$tm('auto_answer.name')"
                :vees="vees.name"
              />
            </v-col>
            <v-col md="2">
              <VfSwitch
                v-model="autoAnswer.active_flg"
                :label="$$tm('auto_answer.active_flg')"
                :vees="vees.active_flg"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.auto_answer.response_keyword') }}
        </v-card-title>
        <v-card-text>
          <VfSwitch
            v-model="autoAnswer.no_keyword_flg"
            :label="$$tm('auto_answer.no_keyword_flg')"
            :vees="vees.no_keyword_flg"
          />
          <v-card v-if="!autoAnswer.no_keyword_flg">
            <v-card-text>
              <VfRadioButton
                v-model="autoAnswer.keyword_match_type"
                :label="$$tm('auto_answer.keyword_match_type')"
                :vees="vees.keyword_match_type"
                :selects="opts.keyword_match_type"
                label-hidden
              />
              <v-alert
                class="or-break"
                border="left"
                colored-border
                color="deep-purple accent-4"
                elevation="2"
                style="font-size: 12px"
                >{{
                  $t('lbl.auto_answer.auto_answer_match_type_desc')
                }}</v-alert
              >
              <v-card
                v-for="(react, react_i) in autoAnswer.auto_answer_reacts"
                :key="react_i"
                class="mb-4"
              >
                <v-card-text class="py-0">
                  <v-row>
                    <v-col md="8">
                      <VfTextField
                        v-model="react.keyword"
                        :label="$$tm('auto_answer_react.keyword')"
                        :vees="veesReact.keyword"
                      />
                    </v-col>
                    <v-col md="3">
                      <VfSelect
                        v-model="react.react_match_type"
                        :label="$$tm('auto_answer_react.react_match_type')"
                        :vees="veesReact.react_match_type"
                        :selects="opts.react_match_type"
                        label-hidden
                      />
                    </v-col>
                    <v-col md="1">
                      <v-btn
                        icon
                        class="mt-4"
                        :disabled="autoAnswer.auto_answer_reacts.length <= 1"
                        @click="removeReact(react_i)"
                      >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row>
                <v-col cols="12">
                  <v-btn color="primary" @click="addReact">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    {{ $t('btn.add_react_keyword') }}
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-alert
                    class="or-break"
                    border="left"
                    colored-border
                    color="deep-purple accent-4"
                    elevation="2"
                    style="font-size: 12px"
                    >{{ $t('lbl.auto_answer.auto_answer_desc') }}</v-alert
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.auto_answer.set_datetime') }}
        </v-card-title>
        <v-card-text>
          <VfSwitch
            v-model="autoAnswer.no_datetime_flg"
            :label="$$tm('auto_answer.no_datetime_flg')"
            :vees="vees.no_datetime_flg"
          />
          <v-card v-if="!autoAnswer.no_datetime_flg">
            <v-card-title>
              {{ $t('lbl.auto_answer.day_of_the_week') }}
            </v-card-title>
            <v-card-text id="day_of_the_week">
              <VfCheckbox
                v-model="allSelected"
                :label="$t('lbl.auto_answer.all_day')"
                :indeterminate="indeterminate"
              />
              <table>
                <tr>
                  <td v-for="day in DAYS" :key="day">
                    <div class="mr-6">
                      <VfCheckbox
                        v-model="autoAnswer[day]"
                        :label="$$tm(`auto_answer.${day}`)"
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </v-card-text>
            <VDivider />
            <v-card-title>
              {{ $t('lbl.auto_answer.time') }}
            </v-card-title>
            <v-card-text>
              <VfSwitch
                v-model="autoAnswer.time_select_flg"
                :label="$$tm('auto_answer.time_select_flg')"
                :vees="vees.time_select_flg"
              />
              <table v-if="autoAnswer.time_select_flg">
                <tr>
                  <td>
                    <div>
                      <VfDateTimeSelect
                        v-model="autoAnswer.st_tm"
                        label="開始時"
                        format="HH:mm"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="mx-2">〜</div>
                  </td>
                  <td>
                    <div>
                      <VfDateTimeSelect
                        v-model="autoAnswer.ed_tm"
                        label="終了時"
                        format="HH:mm"
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <div class="or-card-title mb-6">
          <v-card-title>
            {{ $t('lbl.auto_answer.search') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('lbl.auto_answer.search_desc') }}
          </v-card-subtitle>
        </div>
        <v-card-text>
          <PtSearchSelectedList v-model="autoAnswer.target_search_wrap" />
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.auto_answer.action') }}
        </v-card-title>
        <v-card-text>
          <PtActionSelectedList v-model="autoAnswer.action_arr" />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col class="text-right">
          <v-btn @click="$router.push({ name: 'AutoAnswerIndex' })">{{
            $t('btn.back')
          }}</v-btn>
          <v-btn
            color="primary"
            :loading="$wait.is('processing')"
            @click="save"
            >{{ $t('btn.create') }}</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
import PtActionSelectedList from 'components/parts/common/PtActionGroup/PtActionSelectedList'
export default {
  components: {
    PtSearchSelectedList,
    PtActionSelectedList,
  },
  data: () => ({
    initDone: false,
    autoAnswerId: '',
    autoAnswer: {},
    vees: {},
    autoAnswerReact: {},
    veesReact: {},
    folders: {},
    opts: {},
    savePath: '',
    deleteDialog: false,
  }),
  computed: {
    DAYS() {
      return [
        'sun_flg',
        'mon_flg',
        'tue_flg',
        'wed_flg',
        'thu_flg',
        'fri_flg',
        'sat_flg',
      ]
    },
    selectedDaysCount() {
      return this.DAYS.reduce((acc, day) => acc + this.autoAnswer[day], 0)
    },
    allSelected: {
      get() {
        return this.selectedDaysCount === this.DAYS.length ? 1 : 0
      },
      set(value) {
        this.DAYS.forEach((day) => (this.autoAnswer[day] = value))
      },
    },
    indeterminate() {
      return this.selectedDaysCount !== 0 && this.allSelected === 0
    },
    stTm: {
      get() {
        return this.$$ftm(this.autoAnswer.st_tm)
      },
      set(value) {
        this.autoAnswer.st_tm = value ? `2000-01-01 ${value}` : ''
      },
    },
    edTm: {
      get() {
        return this.$$ftm(this.autoAnswer.ed_tm)
      },
      set(value) {
        this.autoAnswer.ed_tm = value ? `2000-01-01 ${value}` : ''
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.autoAnswerId = this.$route.params.id
      const path =
        this.autoAnswerId === 'new'
          ? '/api/v1/auto_answers/new'
          : `/api/v1/auto_answers/${this.autoAnswerId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.autoAnswer = res.data.auto_answer
          this.vees = res.data.vees
          this.autoAnswerReact = res.data.auto_answer_react
          this.veesReact = res.data.vees_react
          this.folders = res.data.folders
          this.opts = res.data.opts
          if (this.autoAnswer.auto_answer_reacts.length === 0) {
            this.addReact()
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.autoAnswerId === 'new'
          ? '/api/v1/auto_answers'
          : `/api/v1/auto_answers/${this.autoAnswerId}`
      const method = this.autoAnswerId === 'new' ? 'post' : 'put'
      const prms = {
        auto_answer: this.autoAnswer,
        auto_answer_reacts: this.autoAnswer.auto_answer_reacts,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'AutoAnswerIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    addReact() {
      this.autoAnswer.auto_answer_reacts.push(
        this.$$clone(this.autoAnswerReact)
      )
    },
    removeReact(index) {
      this.autoAnswer.auto_answer_reacts.splice(index, 1)
    },
    destroy() {
      this.savePath = `/api/v1/auto_answers/${this.autoAnswerId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'AutoAnswerIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.time-pick {
  width: 100px;
}
#day_of_the_week ::v-deep .v-label {
  margin-top: 7px;
}
</style>
