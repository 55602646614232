import Vue from 'vue'
import VueRouter from 'vue-router'
import store from 'store'
import i18n from 'plugins/vue-i18n'
import toasted from 'plugins/toasted'
import axios from 'axios'

import AccountShow from 'pages/v1/accounts/Show'
import AccountPasswordReset from 'pages/v1/accounts/PasswordReset'
import AccountPasswordResetRequested from 'pages/v1/accounts/PasswordResetRequested'
import AccountPasswordResetConfirmation from 'pages/v1/accounts/PasswordResetConfirmation'
import ChannelForm from 'pages/v1/channels/Form'
import BaseHome from 'pages/v1/bases/Home'
import FriendReportIndex from 'pages/v1/friend_reports/Index'
import FriendIndex from 'pages/v1/friends/Index'
import FriendShow from 'pages/v1/friends/Show'
import TestFriendIndex from 'pages/v1/test_friends/Index'
import InfoIndex from 'pages/v1/infos/Index'
import MessageIndex from 'pages/v1/messages/Index'
import MessageCount from 'pages/v1/messages/Count'
import Notificator from 'pages/v1/notificators/Index'
import NotificatorForm from 'pages/v1/notificators/Form'
import NotificatorShow from 'pages/v1/notificators/Show'
import UnicastIndex from 'pages/v1/unicasts/Index'
import BroadcastIndex from 'pages/v1/broadcasts/Index'
import BroadcastForm from 'pages/v1/broadcasts/Form'
import FixedPhraseIndex from 'pages/v1/fixed_phrases/Index'
import FixedPhraseForm from 'pages/v1/fixed_phrases/Form'
import AnswerFormIndex from 'pages/v1/answer_forms/Index'
import AnswerFormForm from 'pages/v1/answer_forms/Form'
import AnswerFormShow from 'pages/v1/answer_forms/Show'
import AnswerFormMerge from 'pages/v1/answer_forms/Merge'
import ListAnswerResponseMember from 'pages/v1/answer_forms/ListAnswerResponseMember'
import AnswerFormChangeStatus from 'pages/v1/answer_forms/ChangeStatus'
import AnswerFormShowUrl from 'pages/v1/answer_forms/ShowUrl'
import AnswerFormAnswer from 'pages/v1/answer_forms/Answer'
import ScenarioIndex from 'pages/v1/scenarios/Index'
import ScenarioForm from 'pages/v1/scenarios/Form'
import ScenarioTimingForm from 'pages/v1/scenario_timings/Form'
import AutoAnswerIndex from 'pages/v1/auto_answers/Index'
import AutoAnswerForm from 'pages/v1/auto_answers/Form'
import ReminderIndex from 'pages/v1/reminders/Index'
import ReminderForm from 'pages/v1/reminders/Form'
import ReminderTimingForm from 'pages/v1/reminder_timings/Form'
import ScheduleIndex from 'pages/v1/schedules/Index'
import ScheduleForm from 'pages/v1/schedules/Form'
import ActionTemplateIndex from 'pages/v1/action_templates/Index'
import ActionTemplateForm from 'pages/v1/action_templates/Form'
import HashtagIndex from 'pages/v1/hashtags/Index'
import HashtagForm from 'pages/v1/hashtags/Form'
import InflowRouteIndex from 'pages/v1/inflow_routes/Index'
import InflowRouteForm from 'pages/v1/inflow_routes/Form'
import InflowRouteFriend from 'pages/v1/inflow_routes/Friend'
import InflowRouteRedirect from 'pages/v1/inflow_routes/Redirect'
import SearchTemplateIndex from 'pages/v1/search_templates/Index'
import SearchTemplateForm from 'pages/v1/search_templates/Form'
import ClickIndex from 'pages/v1/clicks/Index'
import ClickForm from 'pages/v1/clicks/Form'
import ClickShow from 'pages/v1/clicks/Show'
import ClickRedirect from 'pages/v1/clicks/Redirect'
import FriendStartSettingIndex from 'pages/v1/friend_start_settings/Index'
import UploadFileIndex from 'pages/v1/upload_files/Index'
import StaffIndex from 'pages/v1/staffs/Index'
import DataMigrationIndex from 'pages/v1/data_migration/Index'
import StaffForm from 'pages/v1/staffs/Form'
import StaffAttach from 'pages/v1/staffs/Attach'
import ChannelRoleIndex from 'pages/v1/channel_roles/Index'
import ChannelRoleForm from 'pages/v1/channel_roles/Form'
import CrossAnalysisIndex from 'pages/v1/cross_analyses/Index'
import CrossAnalysisForm from 'pages/v1/cross_analyses/Form'
import CrossAnalysisShow from 'pages/v1/cross_analyses/Show'
import RichMenuIndex from 'pages/v1/rich_menus/Index'
import RichMenuForm from 'pages/v1/rich_menus/Form'
import ImportIndex from 'pages/v1/imports/Index'
import ImportCheck from 'pages/v1/imports/Check'
import ImportLog from 'pages/v1/imports/Log'

import Registration from 'pages/v1/accounts/Registration'
import RegistrationEmail from 'pages/v1/registrations/Email'
import RegistrationAccount from 'pages/v1/registrations/Account'
import RegistrationChannel from 'pages/v1/registrations/Channel'
import RegistrationChannelApi from 'pages/v1/registrations/ChannelApi'
import RegistrationWebhook from 'pages/v1/registrations/Webhook'
import RegistrationLoginChannel from 'pages/v1/registrations/LoginChannel'
import RegistrationLiffApp from 'pages/v1/registrations/LiffApp'
import RegistrationDone from 'pages/v1/registrations/Done'
import RegistrationPasswordReset from 'pages/v1/registrations/Password'
import Login from 'pages/v1/accounts/Login'
import Logout from 'pages/v1/accounts/Logout'
import NotFound from 'pages/errors/NotFound'
import NotFoundPage from 'pages/errors/NotFoundPage'
import CancelForm from 'pages/v1/cancel_form/CancelForm'
import SentForm from 'pages/v1/cancel_form/SentForm'
import Reconfirmation from 'pages/v1/accounts/Reconfirmation'
import ReconfirmationRequested from 'pages/v1/accounts/ReconfirmationRequested'

import SpIndex from 'pages/v1/sp/Index'
import SpVerify from 'pages/v1/sp/Verify'
import SpUnicastIndex from 'pages/v1/sp/unicasts/Index'
import SpNotificatorIndex from 'pages/v1/sp/notificators/Index'
import SpNotificatorForm from 'pages/v1/sp/notificators/Form'
import SpNotificatorShow from 'pages/v1/sp/notificators/Show'
import SpFixedPhraseForm from 'pages/v1/sp/fixed_phrases/Form'
import SpBroadcastForm from 'pages/v1/sp/broadcasts/Form'

import AdminUsersIndex from 'pages/admin/users/Index'
import AdminChannelsIndex from 'pages/admin/channels/Index'
import AdminUsersShow from 'pages/admin/users/Show'
import AdminUsersRegistration from 'pages/admin/users/Registration'
import AdminFriendsIndex from 'pages/admin/friends/Index'
import AdminFriendsShow from 'pages/admin/friends/Show'
import AdminInfosIndex from 'pages/admin/infos/Index'
import AdminInfosShow from 'pages/admin/infos/Show'
import AdminInfosForm from 'pages/admin/infos/Form'
import AdminPlanIndex from 'pages/admin/plans/Index'
import AdminPlanForm from 'pages/admin/plans/Form'
import AdminPlanRole from 'pages/admin/plans/Role'
import AdminCancelFormIndex from 'pages/admin/cancel_forms/Index'
import AdminCancelFormShow from 'pages/admin/cancel_forms/Show'

Vue.use(VueRouter)
Vue.use(toasted)

const export_router = new VueRouter({
  mode: 'history',
  routes: [
    {
      name: 'BaseHome',
      path: '/',
      component: BaseHome,
      props: { metaOpts: {} },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'AccountShow',
      path: '/account/show',
      component: AccountShow,
      props: { metaOpts: { yml: 'AccountShow' } },
      meta: { onlySigned: true },
    },
    {
      name: 'AccountPasswordReset',
      path: '/password_reset',
      component: AccountPasswordReset,
      props: { metaOpts: { yml: 'AccountPasswordReset' } },
      meta: { onlyGuest: true },
    },
    {
      name: 'AccountPasswordResetRequested',
      path: '/password_reset_requested',
      component: AccountPasswordResetRequested,
      props: { metaOpts: { yml: 'AccountPasswordResetRequested' } },
    },
    {
      name: 'AccountPasswordResetConfirmation',
      path: '/password_reset_confirmation',
      component: AccountPasswordResetConfirmation,
      props: { metaOpts: { yml: 'AccountPasswordResetConfirmation' } },
    },
    {
      name: 'ChannelForm',
      path: '/channel/edit',
      component: ChannelForm,
      props: { metaOpts: { yml: 'ChannelForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'channel' },
    },
    {
      name: 'FriendReportIndex',
      path: '/friends/report',
      component: FriendReportIndex,
      props: { metaOpts: { yml: 'FriendReportIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'FriendIndex',
      path: '/friends',
      component: FriendIndex,
      props: { metaOpts: { yml: 'FriendIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'TestFriendIndex',
      path: '/test_friends',
      component: TestFriendIndex,
      props: { metaOpts: { yml: 'TestFriendIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'FriendShow',
      path: '/friends/:id',
      component: FriendShow,
      props: { metaOpts: { yml: 'FriendShow' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'InfoIndex',
      path: '/infos',
      component: InfoIndex,
      props: { metaOpts: { yml: 'InfoIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'MessageIndex',
      path: '/messages',
      component: MessageIndex,
      props: { metaOpts: { yml: 'MessageIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'MessageCount',
      path: '/counts',
      component: MessageCount,
      props: { metaOpts: { yml: 'MessageCount' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'NotificatorCallback',
      path: '/notificator/callback',
      component: Notificator,
      props: { metaOpts: { yml: 'Notificator' } },
      meta: { onlySigned: true },
    },
    {
      name: 'Notificator',
      path: '/notificator',
      component: Notificator,
      props: { metaOpts: { yml: 'Notificator' } },
      meta: { onlySigned: true },
    },
    {
      name: 'NotificatorForm',
      path: '/notificator/form/:id/',
      component: NotificatorForm,
      props: { metaOpts: { yml: 'NotificatorForm' } },
      meta: { onlySigned: true },
    },
    {
      name: 'NotificatorShow',
      path: '/notificator/:id/',
      component: NotificatorShow,
      props: { metaOpts: { yml: 'NotificatorShow' } },
      meta: { onlySigned: true },
    },
    {
      name: 'UnicastIndex',
      path: '/unicasts',
      component: UnicastIndex,
      props: { metaOpts: { yml: 'UnicastIndex' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'friend_unicast',
      },
    },
    {
      name: 'BroadcastIndex',
      path: '/broadcasts',
      component: BroadcastIndex,
      props: { metaOpts: { yml: 'BroadcastIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'broadcast' },
    },
    {
      name: 'BroadcastForm',
      path: '/broadcasts/form/:id',
      component: BroadcastForm,
      props: { metaOpts: { yml: 'BroadcastForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'broadcast' },
    },
    {
      name: 'FixedPhraseIndex',
      path: '/fixed_phrases',
      component: FixedPhraseIndex,
      props: { metaOpts: { yml: 'FixedPhraseIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'FixedPhraseForm',
      path: '/fixed_phrases/form/:id',
      component: FixedPhraseForm,
      props: { metaOpts: { yml: 'FixedPhraseForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'AnswerFormIndex',
      path: '/answer_forms',
      component: AnswerFormIndex,
      props: { metaOpts: { yml: 'AnswerFormIndex' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'answer_form',
      },
    },
    {
      name: 'AnswerFormForm',
      path: '/answer_forms/form/:id',
      component: AnswerFormForm,
      props: { metaOpts: { yml: 'AnswerFormForm' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'answer_form',
      },
    },
    {
      name: 'AnswerFormChangeStatus',
      path: '/answer_forms/:id/change-status',
      component: AnswerFormChangeStatus,
      props: { metaOpts: { yml: 'AnswerFormChangeStatus' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'answer_form',
      },
    },
    {
      name: 'AnswerFormShowUrl',
      path: '/answer_forms/:id/show-url/:alt_uuid',
      component: AnswerFormShowUrl,
      props: { metaOpts: { yml: 'AnswerFormShowUrl' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'answer_form',
      },
    },
    {
      name: 'AnswerFormShow',
      path: '/answer_forms/show/:id',
      component: AnswerFormShow,
      props: { metaOpts: { yml: 'AnswerFormShow' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'answer_form',
      },
    },
    {
      name: 'AnswerFormMerge',
      path: '/answer_forms/merge/:id',
      component: AnswerFormMerge,
      props: { metaOpts: { yml: 'AnswerFormMerge' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'answer_form',
      },
    },
    {
      name: 'ListAnswerResponseMember',
      path: '/answer_forms/list-answer-response-member/:friend_id',
      component: ListAnswerResponseMember,
      props: { metaOpts: { yml: 'ListAnswerResponseMember' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'answer_form',
      },
    },
    {
      name: 'AnswerFormAnswer',
      path: '/answer_forms/answer/:uuid/:friend_id?',
      component: AnswerFormAnswer,
      props: { metaOpts: { yml: 'AnswerFormAnswer' } },
    },
    {
      name: 'ScenarioIndex',
      path: '/scenarios',
      component: ScenarioIndex,
      props: { metaOpts: { yml: 'ScenarioIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'scenario' },
    },
    {
      name: 'ScenarioForm',
      path: '/scenarios/form/:id',
      component: ScenarioForm,
      props: { metaOpts: { yml: 'ScenarioForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'scenario' },
    },
    {
      name: 'ScenarioTimingForm',
      path: '/scenarios/timings/form/:id',
      component: ScenarioTimingForm,
      props: { metaOpts: { yml: 'ScenarioTimingForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'scenario' },
    },
    {
      name: 'AutoAnswerIndex',
      path: '/auto_answers',
      component: AutoAnswerIndex,
      props: { metaOpts: { yml: 'AutoAnswerIndex' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'auto_answer',
      },
    },
    {
      name: 'AutoAnswerForm',
      path: '/auto_answers/form/:id',
      component: AutoAnswerForm,
      props: { metaOpts: { yml: 'AutoAnswerForm' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'auto_answer',
      },
    },
    {
      name: 'ReminderIndex',
      path: '/reminders',
      component: ReminderIndex,
      props: { metaOpts: { yml: 'ReminderIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'reminder' },
    },
    {
      name: 'ReminderForm',
      path: '/reminders/form/:id',
      component: ReminderForm,
      props: { metaOpts: { yml: 'ReminderForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'reminder' },
    },
    {
      name: 'ReminderTimingForm',
      path: '/reminders/timings/form/:id',
      component: ReminderTimingForm,
      props: { metaOpts: { yml: 'ReminderTimingForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'reminder' },
    },
    {
      name: 'ScheduleIndex',
      path: '/schedules',
      component: ScheduleIndex,
      props: { metaOpts: { yml: 'ScheduleIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'schedule' },
    },
    {
      name: 'ScheduleForm',
      path: '/schedules/form/:id',
      component: ScheduleForm,
      props: { metaOpts: { yml: 'ScheduleForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'schedule' },
    },
    {
      name: 'ActionTemplateIndex',
      path: '/action_templates',
      component: ActionTemplateIndex,
      props: { metaOpts: { yml: 'ActionTemplateIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'ActionTemplateForm',
      path: '/action_templates/form/:id',
      component: ActionTemplateForm,
      props: { metaOpts: { yml: 'ActionTemplateForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'HashtagIndex',
      path: '/hashtags',
      component: HashtagIndex,
      props: { metaOpts: { yml: 'HashtagIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'hashtag' },
    },
    {
      name: 'HashtagForm',
      path: '/hashtags/form/:id',
      component: HashtagForm,
      props: { metaOpts: { yml: 'HashtagForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'hashtag' },
    },
    {
      name: 'InflowRouteIndex',
      path: '/inflow_routes',
      component: InflowRouteIndex,
      props: { metaOpts: { yml: 'InflowRouteIndex' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'inflow_route',
      },
    },
    {
      name: 'InflowRouteForm',
      path: '/inflow_routes/form/:id',
      component: InflowRouteForm,
      props: { metaOpts: { yml: 'InflowRouteForm' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'inflow_route',
      },
    },
    {
      name: 'InflowRouteFriend',
      path: '/inflow_routes/friends/:id',
      component: InflowRouteFriend,
      props: { metaOpts: { yml: 'InflowRouteFriend' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'inflow_route',
      },
    },
    {
      name: 'InflowRouteRedirect',
      path: '/inflow_routes/line_login',
      component: InflowRouteRedirect,
      props: { metaOpts: { yml: 'InflowRouteRedirect' } },
      meta: { noindex: true },
    },
    {
      name: 'SearchTemplateIndex',
      path: '/search_templates',
      component: SearchTemplateIndex,
      props: { metaOpts: { yml: 'SearchTemplateIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'SearchTemplateForm',
      path: '/search_templates/form/:id',
      component: SearchTemplateForm,
      props: { metaOpts: { yml: 'SearchTemplateForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'ClickIndex',
      path: '/clicks',
      component: ClickIndex,
      props: { metaOpts: { yml: 'ClickIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'click' },
    },
    {
      name: 'ClickForm',
      path: '/clicks/form/:id',
      component: ClickForm,
      props: { metaOpts: { yml: 'ClickForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'click' },
    },
    {
      name: 'ClickShow',
      path: '/clicks/show/:id',
      component: ClickShow,
      props: { metaOpts: { yml: 'ClickShow' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'click' },
    },
    {
      name: 'ClickRedirect',
      path: '/redirect/:id',
      component: ClickRedirect,
      props: { metaOpts: { yml: 'ClickRedirect' } },
      meta: { noindex: true },
    },
    {
      name: 'FriendStartSettingIndex',
      path: '/friend_start_setting',
      component: FriendStartSettingIndex,
      props: { metaOpts: { yml: 'FriendStartSettingIndex' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'friend_start_setting',
      },
    },
    {
      name: 'UploadFileIndex',
      path: '/upload_files',
      component: UploadFileIndex,
      props: { metaOpts: { yml: 'UploadFileIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'StaffIndex',
      path: '/staffs',
      component: StaffIndex,
      props: { metaOpts: { yml: 'StaffIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'staff' },
    },
    {
      name: 'DataMigrationIndex',
      path: '/data_migration',
      component: DataMigrationIndex,
      props: { metaOpts: { yml: 'DataMigrationIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'staff' },
    },
    {
      name: 'StaffForm',
      path: '/staffs/form',
      component: StaffForm,
      props: { metaOpts: { yml: 'StaffForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'staff' },
    },
    {
      name: 'StaffAttach',
      path: '/staffs/attach',
      component: StaffAttach,
      props: { metaOpts: { yml: 'StaffAttach' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'staff' },
    },
    {
      name: 'ChannelRoleIndex',
      path: '/channel_roles',
      component: ChannelRoleIndex,
      props: { metaOpts: { yml: 'ChannelRoleIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'role' },
    },
    {
      name: 'ChannelRoleForm',
      path: '/channel_roles/:id',
      component: ChannelRoleForm,
      props: { metaOpts: { yml: 'ChannelRoleForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'role' },
    },
    {
      name: 'CrossAnalysisIndex',
      path: '/cross_analyses',
      component: CrossAnalysisIndex,
      props: { metaOpts: { yml: 'CrossAnalysisIndex' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'cross_analysis',
      },
    },
    {
      name: 'CrossAnalysisForm',
      path: '/cross_analyses/form/:id',
      component: CrossAnalysisForm,
      props: { metaOpts: { yml: 'CrossAnalysisForm' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'cross_analysis',
      },
    },
    {
      name: 'CrossAnalysisShow',
      path: '/cross_analyses/show/:id',
      component: CrossAnalysisShow,
      props: { metaOpts: { yml: 'CrossAnalysisShow' } },
      meta: {
        onlySigned: true,
        onlySelectedChannel: true,
        role: 'cross_analysis',
      },
    },
    {
      name: 'RichMenuIndex',
      path: '/rich_menus',
      component: RichMenuIndex,
      props: { metaOpts: { yml: 'RichMenuIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'rich_menu' },
    },
    {
      name: 'RichMenuForm',
      path: '/rich_menus/form/:id',
      component: RichMenuForm,
      props: { metaOpts: { yml: 'RichMenuForm' } },
      meta: { onlySigned: true, onlySelectedChannel: true, role: 'rich_menu' },
    },
    {
      name: 'ImportIndex',
      path: '/imports',
      component: ImportIndex,
      props: { metaOpts: { yml: 'ImportIndex' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'ImportCheck',
      path: '/imports/check/:id',
      component: ImportCheck,
      props: { metaOpts: { yml: 'ImportCheck' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'ImportLog',
      path: '/imports/log/:id',
      component: ImportLog,
      props: { metaOpts: { yml: 'ImportLog' } },
      meta: { onlySigned: true, onlySelectedChannel: true },
    },
    {
      name: 'Login',
      path: '/login',
      component: Login,
      props: { metaOpts: { yml: 'Login' } },
      meta: { onlyGuest: true },
    },
    {
      name: 'Logout',
      path: '/logout',
      component: Logout,
      props: { metaOpts: { yml: 'Logout' } },
      meta: { onlySigned: true },
    },
    {
      name: 'Registration',
      path: '/registration',
      component: Registration,
      props: { metaOpts: { yml: 'Registration' } },
      meta: { onlyGuest: true },
    },
    {
      name: 'RegistrationEmail',
      path: '/registrations/email',
      component: RegistrationEmail,
      props: { metaOpts: { yml: 'RegistrationEmail' } },
      meta: { onlyGuest: true, noindex: true },
    },
    {
      name: 'RegistrationAccount',
      path: '/registrations/account',
      component: RegistrationAccount,
      props: { metaOpts: { yml: 'RegistrationAccount' } },
      meta: { onlySigned: true },
    },
    {
      name: 'RegistrationChannel',
      path: '/registrations/channel',
      component: RegistrationChannel,
      props: { metaOpts: { yml: 'RegistrationChannel' } },
      meta: { onlySigned: true },
    },
    {
      name: 'RegistrationChannelApi',
      path: '/registrations/channel_api',
      component: RegistrationChannelApi,
      props: { metaOpts: { yml: 'RegistrationChannelApi' } },
      meta: { onlySigned: true },
    },
    {
      name: 'RegistrationWebhook',
      path: '/registrations/webhook',
      component: RegistrationWebhook,
      props: { metaOpts: { yml: 'RegistrationWebhook' } },
      meta: { onlySigned: true },
    },
    {
      name: 'RegistrationLoginChannel',
      path: '/registrations/login_channel',
      component: RegistrationLoginChannel,
      props: { metaOpts: { yml: 'RegistrationLoginChannel' } },
      meta: { onlySigned: true },
    },
    {
      name: 'RegistrationLiffApp',
      path: '/registrations/liff_app',
      component: RegistrationLiffApp,
      props: { metaOpts: { yml: 'RegistrationLiffApp' } },
      meta: { onlySigned: true },
    },
    {
      name: 'RegistrationDone',
      path: '/registration_done',
      component: RegistrationDone,
      props: { metaOpts: { yml: 'RegistrationDone' } },
      meta: { onlySigned: true },
    },
    {
      name: 'RegistrationPasswordReset',
      path: '/registration_password_reset',
      component: RegistrationPasswordReset,
      props: { metaOpts: { yml: 'RegistrationPasswordReset' } },
      meta: { onlySigned: true },
    },
    {
      name: 'SpVerify',
      path: '/sp/verify',
      component: SpVerify,
      props: { metaOpts: { yml: 'SpVerify' } },
    },
    {
      name: 'SpIndex',
      path: '/sp',
      component: SpIndex,
      props: { metaOpts: { yml: 'SpIndex' } },
      meta: { onlySpSigned: true },
    },
    {
      name: 'SpUnicastIndex',
      path: '/sp/unicasts',
      component: SpUnicastIndex,
      props: { metaOpts: { yml: 'SpUnicastIndex' } },
      meta: { onlySpSigned: true, role: 'friend_unicast', mobileRole: true },
    },
    {
      name: 'SpFixedPhraseForm',
      path: '/sp/fixed_phrases/send',
      component: SpFixedPhraseForm,
      props: { metaOpts: { yml: 'SpFixedPhraseForm' } },
      meta: { onlySpSigned: true, role: 'broadcast', mobileRole: true },
    },
    {
      name: 'SpNotificatorIndex',
      path: '/sp/notificator',
      component: SpNotificatorIndex,
      props: { metaOpts: { yml: 'SpNotificatorIndex' } },
      meta: { onlySpSigned: true },
    },
    {
      name: 'SpNotificatorForm',
      path: '/sp/notificator/form/:id/',
      component: SpNotificatorForm,
      props: { metaOpts: { yml: 'SpNotificatorForm' } },
      meta: { onlySpSigned: true },
    },
    {
      name: 'SpNotificatorShow',
      path: '/sp/notificator/:id',
      component: SpNotificatorShow,
      props: { metaOpts: { yml: 'SpNotificatorShow' } },
      meta: { onlySpSigned: true },
    },
    {
      name: 'SpBroadcastForm',
      path: '/sp/broadcasts/form/new',
      component: SpBroadcastForm,
      props: { metaOpts: { yml: 'SpBroadcastForm' } },
      meta: { onlySpSigned: true, role: 'broadcast', mobileRole: true },
    },
    {
      name: 'AdminUsersIndex',
      path: '/admin/users',
      component: AdminUsersIndex,
      props: { metaOpts: { yml: 'AdminUsersIndex' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminUsersShow',
      path: '/admin/users/:id',
      component: AdminUsersShow,
      props: { metaOpts: { yml: 'AdminUsersShow' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminUsersRegistration',
      path: '/admin/user/registration',
      component: AdminUsersRegistration,
      props: { metaOpts: { yml: 'AdminUsersRegistration' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminFriendsIndex',
      path: '/admin/friends',
      component: AdminFriendsIndex,
      props: { metaOpts: { yml: 'AdminFriendsIndex' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminFriendsShow',
      path: '/admin/friends/:id',
      component: AdminFriendsShow,
      props: { metaOpts: { yml: 'AdminFriendsShow' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminChannelsIndex',
      path: '/admin/channels',
      component: AdminChannelsIndex,
      props: { metaOpts: { yml: 'AdminChannelsIndex' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminInfosIndex',
      path: '/admin/infos',
      component: AdminInfosIndex,
      props: { metaOpts: { yml: 'AdminInfosIndex' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminInfosShow',
      path: '/admin/infos/:id',
      component: AdminInfosShow,
      props: { metaOpts: { yml: 'AdminInfosShow' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminInfosForm',
      path: '/admin/infos/form/:id',
      component: AdminInfosForm,
      props: { metaOpts: { yml: 'AdminInfosForm' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminPlanIndex',
      path: '/admin/plans',
      component: AdminPlanIndex,
      props: { metaOpts: { yml: 'AdminPlanIndex' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminPlanForm',
      path: '/admin/plans/form/:id',
      component: AdminPlanForm,
      props: { metaOpts: { yml: 'AdminPlanForm' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminPlanRole',
      path: '/admin/plans/role/:id',
      component: AdminPlanRole,
      props: { metaOpts: { yml: 'AdminPlanRole' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminCancelFormIndex',
      path: '/admin/cancel_forms',
      component: AdminCancelFormIndex,
      props: { metaOpts: { yml: 'AdminCancelFormIndex' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'AdminCancelFormShow',
      path: '/admin/cancel_forms/:id',
      component: AdminCancelFormShow,
      props: { metaOpts: { yml: 'AdminCancelFormShow' } },
      meta: { onlySigned: true, onlyAdmin: true },
    },
    {
      name: 'CancelForm',
      path: '/cancel_forms',
      component: CancelForm,
      props: { metaOpts: { yml: 'CancelForm' } },
    },
    {
      name: 'SentForm',
      path: '/sent_form',
      component: SentForm,
      props: { metaOpts: { yml: 'SentForm' } },
    },
    {
      name: 'NotFoundPage',
      path: '/not_found',
      component: NotFoundPage,
      props: { metaOpts: { yml: 'NotFoundPage' } },
    },
    {
      name: 'NotFound',
      path: '*',
      component: NotFound,
      props: { metaOpts: { yml: 'NotFound', noindex: true } },
    },
    {
      name: 'Reconfirmation',
      path: '/reconfirmation',
      component: Reconfirmation,
      props: { metaOpts: { yml: 'Reconfirmation' } },
      meta: { onlyGuest: true },
    },
    {
      name: 'ReconfirmationRequested',
      path: '/reconfirmation_requested',
      component: ReconfirmationRequested,
      props: { metaOpts: { yml: 'ReconfirmationRequested' } },
      meta: { onlyGuest: true },
    },
  ],
})

export_router.beforeEach((to, from, next) => {
  store.dispatch('loadingOverlay', false)
  store.dispatch('glMsg', { globalMessageType: null, globalMessage: null })
  const user = store.getters.user
  const isGuest = !user
  const isNormalUser = user && user.admin_flg === 0
  const isAdminUser = user && user.admin_flg === 1
  const hasSpUserVerified = user && user.sp_user && user.sp_user.verified
  const hasSelectedChannel = store.getters.channel
  function hasNotRole(role, availableRoles = store.getters.roles) {
    if (!role) {
      return false
    }
    return !availableRoles.includes(role)
  }
  function hasNotMobileRole(channel = store.getters.channel) {
    if (!channel) {
      return true
    }
    return !channel.plan_role_types.includes('mobile')
  }
  function isOnlyNormalUserAvailable(meta) {
    return (meta.onlySigned || meta.onlySpSigned) && !meta.onlyAdmin
  }
  if (to.matched.some((page) => page.meta.onlySigned) && isGuest) {
    Vue.toasted.error(i18n.tc('danger.login'))
    next({ name: 'Login' })
  } else if (to.matched.some((page) => page.meta.onlySpSigned) && isGuest) {
    next({ name: 'SpVerify' })
  } else if (
    to.matched.some((page) => page.meta.onlySpSigned) &&
    !hasSpUserVerified
  ) {
    next({ name: 'SpVerify' })
  } else if (to.matched.some((page) => page.meta.onlyAdmin) && isNormalUser) {
    Vue.toasted.error(i18n.tc('danger.notfound_page'))
    next({ name: 'BaseHome' })
  } else if (to.matched.some((page) => page.meta.onlyGuest) && isNormalUser) {
    Vue.toasted.error(i18n.tc('danger.already_login'))
    next({ name: 'BaseHome' })
  } else if (to.matched.some((page) => page.meta.onlyGuest) && isAdminUser) {
    Vue.toasted.error(i18n.tc('danger.already_login'))
    next({ name: 'AdminUsersIndex' })
  } else if (
    to.matched.some(
      (page) => isOnlyNormalUserAvailable(page.meta) && isAdminUser
    )
  ) {
    Vue.toasted.error(i18n.tc('danger.only_normal_user'))
    next({ name: 'AdminUsersIndex' })
  } else if (
    to.matched.some((page) => page.meta.onlySelectedChannel) &&
    !hasSelectedChannel
  ) {
    Vue.toasted.error(i18n.tc('danger.not_select_channel'))
    next({ name: 'AccountShow' })
  } else if (to.matched.some((page) => hasNotRole(page.meta.role))) {
    Vue.toasted.error(i18n.tc('danger.notfound_page'))
    next({ name: 'NotFound' })
  } else if (
    to.matched.some((page) => page.meta.mobileRole && hasNotMobileRole())
  ) {
    Vue.toasted.error(i18n.tc('danger.notfound_page'))
    next({ name: 'NotFound' })
  } else {
    next()
  }
})
export_router.afterEach(() => {
  window.scrollTo({ top: 0 })
  const isNormalUser = store.getters.user && store.getters.user.admin_flg === 0
  const hasSelectedChannel = store.getters.channel
  if (isNormalUser && hasSelectedChannel) {
    getUnreadMessageCount()
  }
})

function getUnreadMessageCount() {
  const path = '/api/v1/friends/unconfirmed_message_count'
  axios
    .get(path)
    .then((res) => {
      store.dispatch(
        'updateUnreadCount',
        res.data.unconfirmed_message_count || 0
      )
    })
    .catch(() => {})
}

export default export_router
