<template>
  <v-row v-if="!hiddenTitle">
    <v-col sm="4" cols="12" xs="12">
      <span class="text-bold">{{ title }}</span>
      <v-chip
        v-if="required"
        class="ma-2"
        label
        color="red"
        text-color="white"
        small
      >
        {{ $t('lbl.answer_form.require') }}
      </v-chip>
    </v-col>
    <v-col sm="8" cols="12" xs="12">
      <VfTextField
        v-model="internalValue"
        class="vf-hidden-label mt-0 pt-0"
        :label="label"
        :placeholder="placeholder"
        :hint="hint"
        :vees="vees"
      />
    </v-col>
  </v-row>
</template>

<script>
import { Core as YubinBangoCore } from 'yubinbango-core'
export default {
  name: 'PtFormRenderTextField',
  props: {
    internalId: { default: null },
    dataAddress: { type: Object, default: () => ({}) },
    autoInput: { default: '' },
    value: { default: null },
    label: { type: String, default: '' },
    title: { type: String, default: '' },
    hiddenTitle: { default: 0 },
    hint: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    maxLength: { type: Number, default: null },
    validationType: { type: String, default: '' },
    required: { default: 0 },
  },
  data: function () {
    return {
      loading: false,
      errorMsg: '',
      data: {},
      address: '',
      addressMap: {
        all_address: 'all_address',
        prefecture: 'region',
        town: 'street',
        district: 'locality',
        subsequent_address: 'extended',
      },
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    autoInputSetting() {
      return JSON.parse(this.autoInput)
    },
    vees: function () {
      let rules = {
        rules: '',
      }

      if (this.maxLength) {
        rules.rules += (rules.rules ? '|' : '') + `max:${this.maxLength}`
        rules.max = this.maxLength
      }

      if (this.required) {
        rules.rules += (rules.rules ? '|' : '') + 'required'
        rules.required = true
      }

      if (this.validationType) {
        rules.rules += (rules.rules ? '|' : '') + this.validationType
      }

      return rules
    },
  },
  watch: {
    internalValue(newVal) {
      let newZip = newVal.replaceAll('-', '')
      if (this.isNumber(newZip) && newZip.length >= 7) {
        new YubinBangoCore(newZip, (addr) => {
          this.$emit('fill-address', { from: this.internalId, address: addr })
        })
      }
    },
    dataAddress() {
      let textAddress = ''
      let formatInputPostCode = {}
      let addressUnits = [
        'prefecture',
        'town',
        'district',
        'subsequent_address',
      ]

      // get input setting post cost form json setting
      let inputPostCode = this.autoInputSetting.find((input) => {
        return (
          input.isAutoField === true &&
          input.internal_id === this.dataAddress.from
        )
      })

      if (!inputPostCode || this.dataAddress.from === this.internalId)
        return // get input is set to auto input
      ;['all_address', ...addressUnits].forEach((el) => {
        if (
          inputPostCode.hasOwnProperty(el) &&
          inputPostCode[el].internal_id === this.internalId
        ) {
          formatInputPostCode[el] = inputPostCode[el]
        }
      })

      if (formatInputPostCode.hasOwnProperty('all_address')) {
        addressUnits.forEach((el) => {
          textAddress += this.dataAddress.address[this.addressMap[el]]
        })

        this.internalValue = textAddress
      } else if (
        formatInputPostCode && // 👈 null and undefined check
        Object.keys(formatInputPostCode).length !== 0 && // object empty check
        formatInputPostCode.constructor === Object
      ) {
        for (const [key, value] of Object.entries(formatInputPostCode)) {
          textAddress += this.dataAddress.address[this.addressMap[key]]
        }

        this.internalValue = textAddress
      }
    },
  },
  methods: {
    isNumber(n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n)
    },
  },
}
</script>

<style scoped lang="scss">
.time-pick {
  width: 100px;
}

.vf-hidden-label ::v-deep {
  fieldset > legend {
    display: none;
  }

  .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }

  .v-label {
    display: none;
  }
}
</style>
