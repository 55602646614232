<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <VfTextField
          v-model="internalValue.title"
          :label="$$tm('deli_question.title')"
          :vees="vees.title"
          counter
          outlined
        />
        <v-row no-gutters>
          <v-col
            v-for="(choice, choice_i) in internalValue.deli_question_choices"
            :key="choice_i"
          >
            <v-card>
              <v-card-title
                >{{ $t('lbl.choice') }} {{ choice_i + 1 }}</v-card-title
              >
              <v-card-text>
                <VfTextField
                  v-model="choice.choice_name"
                  :label="$$tm('deli_question_choice.choice_name')"
                  :vees="veesChoice.choice_name"
                  counter
                />
                <VfSelect
                  v-model="choice.choice_action_type"
                  :label="$$tm('deli_question_choice.choice_action_type')"
                  :vees="veesChoice.choice_action_type"
                  :selects="choiceActionTypes"
                />
                <VfTextField
                  v-if="
                    choice.choice_action_type !== 'action' &&
                    choice.choice_action_type !== 'none'
                  "
                  :key="choice.choice_action_type"
                  v-model="choice.multi_text"
                  :label="$t(`lbl.${choice.choice_action_type}_text`)"
                  :vees="multiTextValidates[choice.choice_action_type]"
                />
                <v-card-text v-if="choice.choice_action_type === 'action'">
                  <PtActionSelectedList
                    v-model="choice.action_arr"
                    :msg-hidden-flg="true"
                  />
                </v-card-text>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="mt-4">
          <VfTextArea
            v-model="internalValue.message_for_pc"
            :label="$$tm('deli_question.message_for_pc')"
            :vees="vees.message_for_pc"
            counter
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  components: {
    PtActionSelectedList: () =>
      import('components/parts/common/PtActionGroup/PtActionSelectedList.vue'),
  },
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    veesChoice: {
      type: Object,
      default: function () {
        return {}
      },
    },
    choiceActionTypes: {
      type: Object,
      default: function () {
        return {}
      },
    },
    urlOpenTypes: {
      type: Object,
      default: function () {
        return {}
      },
    },
    multiTextValidates: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.internalValue.deli_question_choices.forEach((choice) => {
        if (choice.action_arr === null) {
          choice.action_arr = []
        }
      })
    },
  },
}
</script>
