<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle />
      </v-col>
    </v-row>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-row>
        <v-col class="ml-4" md="8">
          <v-text-field
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            clearable
            single-line
            hide-details
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        item-key="id"
        :disable-sort="true"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150],
        }"
        @click:row="rowClick"
      />
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    headers: [],
    items: [],
    search: '',
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/cancel_forms'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.cancel_forms
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    rowClick(item) {
      this.$router.push({
        name: 'AdminCancelFormShow',
        params: { id: item.id },
      })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
