<template>
  <div class="import-note">
    <PtTitle />
    <h3 class="text-24">{{ $t('lbl.import.list.sub_header') }}</h3>
    <p class="mt-6">{{ $t('subtitle.import') }}</p>

    <v-alert text type="warning" :icon="false">
      <span class="d-flex align-center">
        <v-icon class="note-text-color text-14"
          >fas fa-exclamation-circle</v-icon
        >
        <span class="import-note__text note-text-color ml-1">{{
          $t('warning.import.note.title')
        }}</span>
      </span>
      <p
        class="import-note__text note-text-color text-14"
        v-html="$t('warning.import.note.content')"
      />
    </v-alert>

    <v-row>
      <v-col md="12">
        <v-card>
          <v-card-text>
            <div class="d-flex align-center justify-space-between">
              <div :class="{ 'is-error': isFileError }">
                <p class="font-weight-bold">
                  {{ $t('lbl.import.list.input_upload_csv') }}
                </p>
                <input
                  ref="inputCsv"
                  type="file"
                  @change="changeCsvImportFile"
                />
                <p v-if="isFileError" class="text-error">
                  {{ $t('lbl.import.list.csv_file_error') }}
                </p>
              </div>
              <div style="padding: 0 15%">
                <v-btn
                  class="px-16 font-weight-bold"
                  color="primary"
                  elevation="2"
                  large
                  @click="importCsvFile"
                >
                  {{ $t('lbl.import.list.btn_upload_csv') }}
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12">
        <v-card>
          <v-card-title>
            {{ $t('lbl.import.list.table_header') }}
          </v-card-title>

          <v-card-text>
            <v-overlay absolute :value="isLoadingPage">
              <v-progress-circular indeterminate size="64" />
            </v-overlay>
            <v-simple-table>
              <template #default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('lbl.import.list.table_th_date') }}
                    </th>
                    <th class="text-left">
                      {{ $t('lbl.import.list.table_th_name') }}
                    </th>
                    <th class="text-left">
                      {{ $t('lbl.import.list.table_th_status') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, item_idx) in csvImportList" :key="item_idx">
                    <td>{{ getCSVCreatedDate(item.created_at) }}</td>
                    <td class="font-weight-bold">{{ item.csv_name }}</td>
                    <td>
                      <span :class="getCsvItemColor(item)">
                        <v-icon
                          class="text-14"
                          :class="getCsvItemColor(item)"
                          >{{ getCsvItemIcon(item) }}</v-icon
                        >
                        {{ getCsvItemStatusText(item) }}
                      </span>
                      <template
                        v-if="!isFail(item.status) && !isComplete(item.status)"
                      >
                        <span>-></span>
                        <span
                          class="import-link"
                          @click="
                            $router.push({
                              name:
                                item.status === 'open'
                                  ? 'ImportCheck'
                                  : 'ImportLog',
                              params: { id: item.id },
                            })
                          "
                        >
                          {{ getCsvItemLink(item) }}
                        </span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div class="mt-10">
              <v-btn v-if="page > 1" color="success" @click="prevPage">
                {{ $t('lbl.import.list.btn_prev_item') }}
              </v-btn>
              <v-btn
                v-if="isDisplayNextPage"
                color="success"
                @click="nextPage"
                >{{ $t('lbl.import.list.btn_next_item') }}</v-btn
              >
              <p class="mt-10">
                <a href="#" @click="$router.push({ name: 'FriendIndex' })">{{
                  $t('lbl.import.btn_back')
                }}</a>
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      page: 1,
      csvImportFile: null,
      csvImportList: [],
      isFileError: false,
      isDisplayNextPage: false,
      isLoadingPage: false,
    }
  },
  mounted() {
    this.page = this.$route.query.page
      ? parseInt(this.$route.query.page)
      : this.page

    this.initTable()
  },
  methods: {
    async initTable() {
      this.isLoadingPage = true
      const path = '/api/v1/importer_csv_friend_lists'

      await this.axios
        .get(path, { params: this.$$reqPrms({ page: this.page }) })
        .then((res) => {
          this.csvImportList = res.data.importer_csv_friend_lists || []
          this.isDisplayNextPage = this.csvImportList.length >= 10

          this.$router
            .push({
              name: 'ImportIndex',
              query: { page: this.page },
            })
            .catch(() => {})
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.isLoadingPage = false
        })
    },

    async importCsvFile() {
      if (!this.csvImportFile) return

      this.loading = true
      const path = '/api/v1/importer_csv_friend_lists'
      const options = { headers: { 'Content-Type': 'multipart/form-data' } }

      var formData = new FormData()
      formData.append('csv', this.csvImportFile)

      await this.axios
        .post(path, formData, options)
        .then((res) => {
          this.isFileError = false
          this.$refs.inputCsv.value = ''
          this.csvImportFile = null

          this.$toasted.success(this.$t('success.create'))

          let responseCsv = res.data.importer_csv_friend_list
          if (responseCsv) {
            this.$router.push({
              name: 'ImportCheck',
              params: { id: responseCsv.id },
            })
          }
        })
        .catch((error) => {
          this.isFileError = true

          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },

    changeCsvImportFile(event) {
      this.csvImportFile = event.target.files[0] || null
    },

    prevPage() {
      this.page = this.page > 1 ? this.page - 1 : 1

      this.initTable()
    },

    nextPage() {
      this.page += 1

      this.initTable()
    },

    getCSVCreatedDate(date) {
      return date ? moment(date).format('YYYY-MM-DD HH:mm') : ''
    },

    getCsvItemIcon(csvItem) {
      if (this.isComplete(csvItem.status) || this.isComfirmed(csvItem.status)) {
        return 'fas fa-check'
      }

      if (this.isConfirm(csvItem.status)) {
        return 'fas fa-exclamation-triangle'
      }

      if (this.isOpen(csvItem.status)) {
        return 'fas fa-arrow-right'
      }

      return 'fas fa-times'
    },

    getCsvItemStatusText(csvItem) {
      if (this.isComplete(csvItem.status)) {
        return '反映完了'
      }

      if (this.isConfirm(csvItem.status)) {
        return '反映完了(確認事項あり)'
      }

      if (this.isOpen(csvItem.status)) {
        return '設定中'
      }

      if (this.isComfirmed(csvItem.status)) {
        return '反映完了(確認済み)'
      }

      return ' ファイル不適合'
    },

    getCsvItemLink(csvItem) {
      if (this.isComplete(csvItem.status)) {
        return '取り込みログを開く'
      }

      if (this.isConfirm(csvItem.status)) {
        return '取り込みログを開く'
      }

      if (this.isOpen(csvItem.status)) {
        return '設定を開く'
      }

      return ' ファイル不適合'
    },

    getCsvItemColor(csvItem) {
      if (this.isComplete(csvItem.status) || this.isComfirmed(csvItem.status)) {
        return 'color-success'
      }

      if (this.isConfirm(csvItem.status)) {
        return 'color-warning'
      }

      if (this.isOpen(csvItem.status)) {
        return 'color-waiting'
      }

      return 'color-failed'
    },

    isComfirmed(status) {
      return status === 'confirmed'
    },

    isComplete(status) {
      return status === 'completed'
    },

    isConfirm(status) {
      return status === 'confirm'
    },

    isOpen(status) {
      return status === 'open'
    },

    isFail(status) {
      return status === 'failure'
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>

<style lang="scss" scoped>
.import-note {
  .v-alert {
    &__content {
      display: flex;
    }
  }

  &__text {
    font-weight: 600;
  }

  .note-text-color {
    color: #8a6d3b;
  }

  .import-link {
    color: #337ab7;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .color-warning {
    color: #8a6d3b;
  }

  .color-success {
    color: #3c763d;
  }

  .color-waiting {
    color: #333;
  }

  .color-failed {
    color: #a94442;
  }
}

.is-error {
  background-color: #ffe4e4;
  padding: 0 12px;
}

.text-error {
  color: #a94442;
  font-weight: 600;
}
</style>
