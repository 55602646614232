<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn v-if="clickId !== 'new'" color="error" @click="destroy">
            {{ $t('btn.delete') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.click.info') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <VfSelect
                v-model="click.folder_id"
                :label="$$tm('folder.name')"
                :vees="vees.folder_id"
                :selects="folders"
              />
            </v-col>
            <v-col md="6">
              <VfTextField
                v-model="click.name"
                :label="$$tm('click.name')"
                :vees="vees.name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <VfTextField
                v-model="click.site_url"
                :label="$$tm('click.site_url')"
                :vees="vees.site_url"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-6">
        <v-card-title class="or-card-title mb-6">
          {{ $t('lbl.click.action') }}
        </v-card-title>
        <v-card-text>
          <PtActionSelectedList v-model="click.action_arr" />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col class="text-right">
          <v-btn
            color="primary"
            :loading="$wait.is('processing')"
            @click="save"
            >{{ $t('btn.create') }}</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete_click')"
      :ok-text="$t('btn.delete')"
      :cancel-text="$t('btn.back')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtActionSelectedList from 'components/parts/common/PtActionGroup/PtActionSelectedList'
export default {
  components: {
    PtActionSelectedList,
  },
  data: () => ({
    initDone: false,
    clickId: '',
    click: {},
    vees: {},
    folders: [],
    opts: {},
    savePath: '',
    deleteDialog: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.clickId = this.$route.params.id
      const path =
        this.clickId === 'new'
          ? '/api/v1/clicks/new'
          : `/api/v1/clicks/${this.clickId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.click = res.data.click
          this.vees = res.data.vees
          this.folders = res.data.folders
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.clickId === 'new'
          ? '/api/v1/clicks'
          : `/api/v1/clicks/${this.clickId}`
      const method = this.clickId === 'new' ? 'post' : 'put'
      const prms = {
        click: this.click,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'ClickIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    destroy() {
      this.savePath = `/api/v1/clicks/${this.clickId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'ClickIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
