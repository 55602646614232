<template>
  <div class="unicast_message_sticky">
    <v-card v-if="!firstTimeFlg" outlined class="mb-4">
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-text>
        <div id="chat-area" style="height: 500px">
          <v-row>
            <v-col align="center">
              <v-btn icon large color="green" @click="moreMessages">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-col
            v-for="message in messages"
            :id="`chat-${message.sort_no}`"
            :key="message.sort_no"
            cols="12"
          >
            <PtLinePreviewRow
              class="mx-4"
              :deli="message.deli"
              showable-status
              :message-id="message.id"
              :message-status="message.message_status"
              :message-type="message.message_type"
              :action-type="message.message_action_type"
              @open-dialog-message-status="openDialogMessageStatus"
            />
          </v-col>
        </div>
      </v-card-text>
    </v-card>
    <v-card v-if="friend.id" outlined class="message_input_container">
      <div class="d-flex align-items-center justify-content-around">
        <div>
          <v-btn icon class="move-up" @click="openDialog">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <div class="message-text-area">
          <validation-observer ref="observer">
            <VfTextArea
              v-model="oneMessage[0].deli_text.text"
              :label="$$tm('deli_text.tname')"
              auto-grow
              outlined
              :rows="1"
              row-height="15"
              :vees="vees.text"
              counter
            />
          </validation-observer>
        </div>
        <v-btn
          class="move-up"
          color="primary"
          :loading="$wait.is('processing')"
          icon
          @click="send('textArea')"
        >
          <v-icon> mdi-send-check </v-icon>
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-if="hasRole" v-model="dialogMessageStatus" max-width="400">
      <v-card outlined>
        <v-card-text class="pt-6">
          <VfSelect
            v-model="selectedMessageStatus"
            :label="$$tm('friend.message_status')"
            :selects="opts.message_status"
          />
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="closeDialogMessageStatus">{{
            $t('btn.cancel')
          }}</v-btn>
          <v-btn color="primary" @click="updateDialogMessageStatus">{{
            $t('btn.update')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="friend.id" v-model="dialog">
      <validation-observer ref="childObserver">
        <PtDeliMobile
          v-model="delis"
          class="pa-4"
          :flag-dialog-unicast="dialog"
          :err-message-text="errMessageText"
          :show-date-setting="showDateSetting"
          @cancel="closeDialog"
          @save="send('dialog')"
          @add-err-text="addErrText"
          @remove-error-text="removeErrorText"
          @add-open-data-setting="addOpenDataSetting"
        />
      </validation-observer>
    </v-dialog>
  </div>
</template>
<script>
import PtLinePreviewRow from 'components/parts/unique/PtLinePreviewRow'
import PtDeliMobile from 'components/parts/common/PtDeli/PtDeliMobile'
import {
  validateMultipleDeliText,
  validateSingleDeliText,
  validateUrlDeliText,
} from 'services/deliText'
export default {
  components: {
    PtLinePreviewRow,
    PtDeliMobile,
  },
  props: {
    friendId: { type: String, default: '' },
    firstTimeFlg: { type: Boolean, default: true },
  },
  data: () => ({
    loading: false,
    dialog: false,
    headers: [],
    friend: {},
    messages: [],
    opts: {},
    oneMessage: {},
    msg: '',
    delis: [],
    vees: {},
    selectedMessageStatus: '',
    selectedMessageId: '',
    dialogMessageStatus: false,
    dialogMessageStatusLoading: false,
    totalCount: 0,
    prevScrollHeight: 0,
    previewOptions: {
      page: 1,
      per: 30,
    },
    flagDialogUnicast: false,
    errMessageText: [],
    showDateSetting: [],
  }),
  computed: {
    hasRole() {
      return this.$store.getters.roles.includes('friend_edit')
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (this.firstTimeFlg === true) {
        return
      }
      this.loading = true
      const prms = { friend_id: this.friendId, ...this.previewOptions }
      const path = '/api/v1/unicasts'
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.friend = res.data.friend
          this.messages = res.data.messages
          this.delis = res.data.delis
          this.vees = res.data.vees
          this.totalCount = res.data.total_count
          this.oneMessage = this.$$clone(this.delis)
          this.opts = res.data.opts
          if (this.$refs.observer) {
            this.$refs.observer.reset()
          }

          for (var i = 0; i < this.messages.length; i++) {
            if (i == 0) {
              this.messages[i].deli.prev_created_at = ''
            } else {
              this.messages[i].deli.prev_created_at =
                this.messages[i - 1].deli.created_at
            }
          }

          this.$nextTick(function () {
            var container = this.$el.querySelector('#chat-area')
            container.scrollTop = container.scrollHeight
            this.prevScrollHeight = container.scrollHeight
          })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
      this.loading = false
    },
    async moreMessages() {
      this.loading = true
      this.previewOptions.page += 1
      const prms = { friend_id: this.friendId, ...this.previewOptions }
      const path = '/api/v1/unicasts'
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.friend = res.data.friend
          this.messages.unshift(...res.data.messages)
          this.delis = res.data.delis
          this.vees = res.data.vees
          this.oneMessage = this.$$clone(this.delis)
          if (this.$refs.observer) {
            this.$refs.observer.reset()
          }

          for (var i = 0; i < this.messages.length; i++) {
            if (i == 0) {
              this.messages[i].deli.prev_created_at = ''
            } else {
              this.messages[i].deli.prev_created_at =
                this.messages[i - 1].deli.created_at
            }
          }

          this.$nextTick(function () {
            var container = this.$el.querySelector('#chat-area')
            container.scrollTop +=
              container.scrollHeight - this.prevScrollHeight
            this.prevScrollHeight = container.scrollHeight
          })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
      this.loading = false
    },
    async send(sendType) {
      if (this.$refs.observer && sendType !== 'dialog') {
        if (sendType === 'textArea') {
          const text = this.oneMessage[0].deli_text.text
          if (
            text != undefined &&
            (text.match(/https?:\/\/[^\s]*/g) || []).length > 10
          ) {
            return this.$toasted.error(this.$t('validation.number_of_url'))
          } else if (!validateUrlDeliText(text)) {
            return this.$toasted.error(this.$t('validation.length_of_url'))
          }
        }

        const isValid = await this.$refs.observer.validate()
        if (!isValid) {
          return this.$toasted.error(this.$t('danger.params'))
        }
      }
      if (this.$wait.is('processing')) {
        return
      }
      if (sendType === 'textArea') {
        if (!validateSingleDeliText(this.oneMessage[0].deli_text.text)) {
          return this.$toasted.error(this.$t('danger.deli_text.format_invalid'))
        }
      } else {
        if (!validateMultipleDeliText(this.delis)) {
          return this.$toasted.error(this.$t('danger.deli_text.format_invalid'))
        }
      }
      this.$wait.start('processing')
      this.dialog = false
      this.$store.dispatch('loadingOverlay', true)
      this.broadcastId = this.$route.params.id
      const path = '/api/v1/unicasts'
      const target = sendType === 'textArea' ? this.oneMessage : this.delis
      const prms = { delis: target, friend_id: this.friend.id }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.send'))
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$store.dispatch('loadingOverlay', false)
        })
    },
    openDialog() {
      this.dialog = true
      this.errMessageText = []
      this.showDateSetting = []
      this.delis.forEach((deli) =>
        deli.deli_type === 'text' ? (deli.deli_text.text = null) : deli
      )
      if (this.$refs.childObserver) {
        this.$refs.childObserver.reset()
      }
    },
    closeDialog() {
      this.dialog = false
    },
    addErrText(deliIndex, messageText) {
      this.errMessageText[deliIndex] = messageText
    },
    removeErrorText(deliIndex) {
      this.errMessageText.splice(deliIndex, 1)
      if (this.showDateSetting[deliIndex]) {
        this.showDateSetting.splice(deliIndex, 1)
      }
    },
    addOpenDataSetting(deliIndex) {
      this.showDateSetting[deliIndex] = true
    },
    openDialogMessageStatus(messageId, messageStatus) {
      if (!this.hasRole) {
        return
      }
      this.errorMsg = ''
      this.selectedMessageId = messageId
      this.selectedMessageStatus = this.$$clone(messageStatus)
      this.dialogMessageStatus = true
    },
    closeDialogMessageStatus() {
      this.dialogMessageStatus = false
    },
    async updateDialogMessageStatus() {
      this.errorMsg = ''
      this.dialogMessageStatusLoading = true
      const path = `/api/v1/messages/${this.selectedMessageId}/update_message_status`
      const prms = { message: { message_status: this.selectedMessageStatus } }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          let updatedMessage = this.messages.find(
            (message) => message.id == this.selectedMessageId
          )
          updatedMessage.message_status = this.selectedMessageStatus
          this.$toasted.success(this.$t('success.update'))
          this.dialogMessageStatus = false
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.dialogMessageStatusLoading = false
        })
    },
  },
}
</script>
<style scoped>
.overflow-y-auto {
  overflow-y: auto;
}

.v-card {
  background-color: #fff !important;
}

.move-up {
  margin-top: -30px;
}

.message-text-area {
  width: 350px;
}
::v-deep .message-text-area textarea {
  max-height: calc(100vh - 500px) !important;
  overflow: auto;
}
.unicast_message_sticky {
  position: sticky;
  top: 80px;
}
::v-deep #chat-area > .row {
  width: 100%;
}
</style>
