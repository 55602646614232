<template>
  <v-dialog v-model="dialog" :max-width="500">
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" icon outlined v-on="on">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t('account.password_edit') }}
      </v-card-title>
      <v-divider />
      <validation-observer ref="observer">
        <v-form @submit.prevent="save">
          <v-card-text>
            <vf-text-field
              v-model="passwordForm.password"
              label="パスワード"
              :vees="{ rules: 'required' }"
              password
            />
            <vf-text-field
              v-model="passwordForm.password_confirmation"
              label="パスワード（確認）"
              :vees="{ rules: 'required' }"
              password
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="dialog = false">
              {{ $t('btn.cancel') }}
            </v-btn>
            <v-btn type="submit" color="primary">
              {{ $t('btn.update') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    passwordForm: {
      password: '',
      password_confirmation: '',
    },
  }),
  watch: {
    dialog(val) {
      if (!val) {
        return
      }
      this.passwordForm.password = ''
      this.passwordForm.password_confirmation = ''
      if (this.$refs.observer) {
        this.$refs.observer.reset()
      }
    },
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.$emit('save', this.passwordForm)
      this.dialog = false
    },
  },
}
</script>
