<template>
  <div>
    <PtTitle />
    {{ $t('subtitle.inflow_route') }}<br />
    {{ $t('lbl.inflow_route.warning_desc') }}
    <v-row>
      <v-col md="3">
        <PtFolder
          ref="folder"
          folder-type="inflow_route"
          @select-folder="selectFolder"
          @pt-init-folders="ptInitFolders"
        />
      </v-col>
      <v-col md="9">
        <v-card outlined>
          <VfLoadingOverlayAbsolute :loading="loading" />
          <v-card-title>
            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="search"
                  label="Search"
                  append-icon="mdi-magnify"
                  clearable
                  single-line
                  hide-details
                />
              </v-col>
              <v-col md="6" class="text-right">
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :disabled="checkedItems.length === 0"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('btn.multi') }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="selectedActionConfirm('delete')">
                      <v-list-item-title>{{
                        $t('lbl.selected_delete')
                      }}</v-list-item-title>
                    </v-list-item>
                    <VDivider />
                    <v-list-item
                      v-for="(folder, folder_idx) in folders"
                      :key="folder_idx"
                      @click="moveFolder(folder)"
                    >
                      <v-list-item-title>{{
                        $t('lbl.move_to', { to: folder.name })
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn color="primary" @click="newItem">{{
                  $t('btn.new_item')
                }}</v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            v-model="checkedItems"
            :headers="headers"
            :items="items"
            :search="search"
            item-key="id"
            :disable-sort="true"
            :items-per-page="50"
            show-select
            :footer-props="{
              itemsPerPageOptions: [50, 100, 150],
            }"
          >
            <template #[`item.name`]="{ item }">
              <div class="pt-1">
                <a @click="editItem(item)" href="#">
                  {{ item.name }}
                </a>
              </div>
              <v-text-field
                :ref="item.state"
                class="line_login_url_input pt-0"
                :value="line_login_url(item)"
                readonly
              >
                <template #append-outer>
                  <v-row>
                    <v-col class="p-0 clipboard-icon" @click="copy(item.state)">
                      <v-icon>mdi-content-copy</v-icon>
                    </v-col>
                    <a :href="qr_url(item)" target="_blank">
                      <v-col class="p-0 pl-1">
                        <v-icon>mdi-qrcode</v-icon>
                      </v-col>
                    </a>
                  </v-row>
                </template>
              </v-text-field>
            </template>
            <template #[`item.action_group`]="{ item }">
              <PtActionLabelMessages
                v-model="item.action_group.make_action_arr"
                class="or-list"
              />
            </template>
            <template #[`item.action_skip_flg`]="{ item }">
              {{ $$te('inflow_route.action_skip_flg', item) }}
            </template>
            <template #[`item.friend_count`]="{ item }">
              <a @click="showFriends(item)">
                {{ $t('lbl.people', { count: item.origin_friend_count }) }}/{{
                  $t('lbl.people', { count: item.friend_count })
                }}
              </a>
            </template>
            <template #[`item.friend_summary_count`]="{ item }">
              {{ $t('lbl.times', { count: item.friend_summary_count }) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
    <v-dialog v-model="commonDialog" max-width="500">
      <v-card>
        <VfLoadingOverlayAbsolute :loading="commonDialogLoading" />
        <v-card-text class="pt-6">
          {{ $t('confirm.delete_inflow_route') }}
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="commonDialog = false">{{ $t('btn.cancel') }}</v-btn>
          <v-btn color="primary" @click="selectedSave(selectedAction)">{{
            $t('btn.action')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtFolder from 'components/parts/common/PtFolder'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  components: {
    PtFolder,
    PtActionLabelMessages,
  },
  data: () => ({
    loading: false,
    checkedItems: [],
    headers: [],
    items: [],
    channel: {},
    search: '',
    savePath: '',
    folderId: '',
    previewDialog: false,
    deleteDialog: false,
    commonDialog: false,
    commonDialogLoading: false,
    selectedAction: '',
    folders: [],
    moveFolderId: '',
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/inflow_routes'
      const prms = { folder_id: this.folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.inflow_routes
          this.channel = res.data.channel
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    newItem() {
      const prms = {}
      if (this.folderId) {
        prms.fid = this.folderId
      }
      this.$router.push({
        name: 'InflowRouteForm',
        params: { id: 'new' },
        query: prms,
      })
    },
    editItem(item) {
      this.$router.push({ name: 'InflowRouteForm', params: { id: item.id } })
    },
    deleteItem(item) {
      this.savePath = `/api/v1/inflow_routes/${item.id}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.init()
      this.$refs.folder.initFromParent()
    },
    showItem(item) {
      this.selectedItem = item
      this.previewDialog = true
    },
    showFriends(item) {
      this.$router.push({ name: 'InflowRouteFriend', params: { id: item.id } })
    },
    selectFolder(folderId) {
      this.folderId = folderId
      this.init()
    },
    ptInitFolders(folders) {
      this.folders = folders
    },
    selectedActionConfirm(action) {
      this.selectedAction = action
      this.commonDialog = true
    },
    moveFolder(folder) {
      this.moveFolderId = folder.id
      this.selectedSave('move_folder')
    },
    async selectedSave(action) {
      this.commonDialogLoading = true
      let prms = { selected_items: this.checkedItems }
      let path = null
      switch (action) {
        case 'delete':
          path = `/api/v1/inflow_routes/selected_delete`
          break
        case 'move_folder':
          path = `/api/v1/inflow_routes/selected_move_folder`
          prms['folder_id'] = this.moveFolderId
          break
      }
      const method = 'post'
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.checkedItems = []
          this.$refs.folder.init()
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.commonDialogLoading = false
          this.commonDialog = false
        })
    },
    copy(state) {
      const input = this.$refs[state].$el.querySelector('input')
      input.select()
      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        this.$toasted.success('コピーしました')
      } catch (err) {
        this.$toasted.success('error')
      }
    },
    line_login_url(item) {
      const liffLineMeUrl = this.$$getLiffLineMeUrl({
        state: item.state,
        ...this.channel,
      })
      return liffLineMeUrl
    },
    qr_url(item) {
      const qr_text = item.qr_text ? `&text=${item.qr_text}` : ''
      const qr_url = `&url=${encodeURIComponent(this.line_login_url(item))}`
      return `https://qr.tools.lineml.jp/?size=300${qr_text}${qr_url}`
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style lang="scss" scoped>
.or-list {
  max-width: 300px;
  font-size: 0.5em;
}

.clipboard-icon {
  cursor: pointer;
}

.line_login_url_input {
  font-size: 12px;
}

.v-data-table a {
  color: #1976d2 !important;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
