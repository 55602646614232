<template>
  <v-alert v-if="type && internalValue" :type="type">
    <div class="or-break">{{ internalValue }}</div>
    <template #append>
      <v-icon @click="close">mdi-close-circle</v-icon>
    </template>
  </v-alert>
</template>
<script>
export default {
  props: {
    value: { type: String, default: '' },
    type: { type: String, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    close() {
      this.internalValue = ''
    },
  },
}
</script>
