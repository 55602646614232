<template>
  <div>
    <PtTitle />
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :disable-sort="true"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150],
        }"
        @click:row="openDialog"
      >
        <template #[`item.disp_st_at`]="{ item }">
          {{ $$fat(item.disp_st_at) }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="showItem(item)">
            mdi-information-outline
          </v-icon>
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title>
          {{ $$fat(selectInfo.disp_st_at) }} {{ selectInfo.title }}
        </v-card-title>
        <v-card-subtitle>
          <div>{{ selectInfo.subtitle }}</div>
        </v-card-subtitle>
        <v-card-text>
          <div>{{ selectInfo.desc }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    dialogMessageStatus: false,
    dialogMessageStatusLoading: false,
    opts: {},
    headers: [],
    items: [],
    item: {},
    checkedItems: [],
    selectedFriend: {},
    selectedFriendClone: {},
    searchWrap: null,
    info_dialog: false,
    savePath: '',
    deleteDialog: false,
    selectedItem: { id: '' },
    previewDialog: false,
    deleteDialog: false,
    commonDialog: false,
    commonDialogLoading: false,
    selectedAction: '',
    dialog: false,
    selectInfo: {},
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.errorMsg = ''
      this.loading = true
      const path = '/api/v1/admin_infos'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.items = res.data.admin_infos
          this.headers = res.data.thead
          this.headers.pop()
          this.headers.pop()
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.loading = false
        })
    },
    openDialog(info) {
      this.selectInfo = info
      this.dialog = true
    },
    newItem() {
      const prms = {}
      this.$router.push({
        name: 'AdminInfosForm',
        params: { id: 'new' },
        query: prms,
      })
    },
    showItem(item) {
      // dialogに変更予定
      this.$router.push({ name: 'AdminInfosShow', params: { id: item.id } })
    },
    editItem(item) {
      this.$router.push({ name: 'AdminInfosForm', params: { id: item.id } })
    },
    deleteItem(item) {
      this.savePath = `/api/v1/admin_infos/${item.id}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.init()
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
