<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn v-if="fixedPhraseId !== 'new'" color="error" @click="destroy">
            {{ $t('btn.delete') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <validation-observer v-if="initDone" ref="observer">
      <v-card outlined class="transparent mb-6">
        <v-card-title class="pl-0 or-card-title">
          {{ $t('lbl.fixed_phrase.info') }}
        </v-card-title>
        <v-card-text class="white">
          <v-row>
            <v-col md="6">
              <VfSelect
                v-model="fixedPhrase.folder_id"
                :label="$$tm('folder.name')"
                :vees="vees.folder_id"
                :selects="folders"
              />
            </v-col>
            <v-col md="6">
              <VfTextField
                v-model="fixedPhrase.name"
                :label="$$tm('fixed_phrase.name')"
                :vees="vees.name"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card outlined class="transparent mb-6">
        <v-card-title class="pl-0 or-card-title">
          {{ $t('lbl.send_message') }}
        </v-card-title>
        <v-card-text class="grey lighten-4">
          <PtDeli v-if="initDone" v-model="delis" />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col class="text-right">
          <v-btn
            color="primary"
            :loading="$wait.is('processing')"
            @click="save"
            >{{ $t('btn.create') }}</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
  </div>
</template>
<script>
import PtDeli from 'components/parts/common/PtDeli/PtDeli'
import {
  validateMultipleDeliText,
  validateMultipleUrlDeliText,
} from 'services/deliText'
export default {
  components: {
    PtDeli,
  },
  data: () => ({
    initDone: false,
    fixedPhraseId: '',
    fixedPhrase: {},
    vees: {},
    folders: {},
    delis: [],
    savePath: '',
    deleteDialog: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.initDone = false
      this.fixedPhraseId = this.$route.params.id
      const path =
        this.fixedPhraseId === 'new'
          ? '/api/v1/fixed_phrases/new'
          : `/api/v1/fixed_phrases/${this.fixedPhraseId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.fixedPhrase = res.data.fixed_phrase
          this.vees = res.data.vees
          this.delis = res.data.delis
          this.folders = res.data.folders
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.initDone = true
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        if (this.delis.length > 0 && !validateMultipleUrlDeliText(this.delis)) {
          return this.$toasted.error(this.$t('validation.length_of_url'))
        } else {
          return this.$toasted.error(this.$t('danger.params'))
        }
      }
      if (this.$wait.is('processing')) {
        return
      }
      if (!validateMultipleDeliText(this.delis)) {
        return this.$toasted.error(this.$t('danger.deli_text.format_invalid'))
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.fixedPhraseId === 'new'
          ? '/api/v1/fixed_phrases'
          : `/api/v1/fixed_phrases/${this.fixedPhraseId}`
      const method = this.fixedPhraseId === 'new' ? 'post' : 'put'
      const prms = { fixed_phrase: this.fixedPhrase, delis: this.delis }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'FixedPhraseIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          this.$$loadingOverlayContent(false)
        })
    },
    destroy() {
      this.savePath = `/api/v1/fixed_phrases/${this.fixedPhraseId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'FixedPhraseIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
