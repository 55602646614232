<template>
  <v-card class="mx-auto py-4" max-width="800">
    <v-form @submit.prevent="submitForm">
      <v-card-title>
        <v-row justify="center">
          <div>{{ $t(`meta.title.${$route.name}`) }}</div>
        </v-row>
      </v-card-title>
      <v-divider class="mx-4" />
      <v-card
        v-if="$route.query.is_user_confirmed === '1'"
        color="grey lighten-4"
        class="ma-8"
      >
        <v-card-text>
          <v-row justify="center">
            <div class="or-break">{{ $t('lbl.email_done') }}</div>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card-text class="text-h5 font-weight-bold">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <vf-text-field v-model="item.email" label="メールアドレス" />
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <vue-recaptcha
              ref="recaptcha"
              sitekey="6LeIc-UUAAAAAF_hZmOAtE6maZozgVQCH3nEdvBh"
              @verify="onVerify"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn type="submit" color="primary" :disabled="submiting">送信</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha'
export default {
  components: {
    'vue-recaptcha': VueRecaptcha,
  },
  data: () => ({
    item: {
      email: '',
    },
    submiting: false,
  }),
  methods: {
    submitForm() {
      this.submiting = true
      const url = '/api/v1/account/password_reset_request'
      this.axios
        .post(url, this.item)
        .then(() => {
          this.$toasted.success('メールを送信しました')
          this.$router.push({ name: 'AccountPasswordResetRequested' })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$$glMsg('error', this.$t('danger.login_miss'))
          } else {
            this.$$ajaxError(error)
          }
          this.$toasted.show('error')
        })
        .finally(() => {
          this.submiting = false
        })
    },
    onVerify: function (response) {
      if (response) this.form.robot = true
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
