<template>
  <div>
    <v-card class="mb-8">
      <validation-observer ref="observer">
        <VfLoadingOverlayAbsolute :loading="$wait.is('processing')" />
        <VfAlert v-model="errorMsg" type="error" />
        <v-card-text>
          <v-row>
            <v-col md="6" class="pb-0">
              <VfRadioButton
                v-model="scenario.scenario_action_type"
                :label="$$tm('action_scenario.scenario_action_type')"
                :selects="scenarioActionType"
                label-hidden
              />
            </v-col>
          </v-row>
          <v-row v-if="scenario.scenario_action_type !== 'cancel'">
            <v-col md="6">
              <PtDialogSelect
                v-model="scenario"
                select-type="scenario"
                :single-select="true"
                :hide-folder="true"
                :vees="{ rules: 'required' }"
                only-have-timing
              />
            </v-col>
          </v-row>
          <v-row
            v-if="
              scenario.scenario_action_type !== 'cancel' && scenario.scenario_id
            "
          >
            <v-col md="6">
              <PtDialogSelect
                v-model="scenario"
                select-type="scenario_timing"
                :single-select="true"
                :hide-folder="true"
                :vees="{ rules: 'required' }"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </validation-observer>
    </v-card>
    <pt-friend-update-common-bottom
      v-model="allFriendsSelected"
      :friends="friends"
      :total-count="totalCount"
      :alert-message="$t('lbl.confirm_friends_scenario_update')"
      @save="save"
    />
  </div>
</template>
<script>
import PtFriendUpdateCommonBottom from './PtFriendUpdateCommonBottom.vue'
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtFriendUpdateCommonBottom,
    PtDialogSelect,
  },
  props: {
    friends: { type: Array, required: true },
    checkedFriends: { type: Array, required: true },
    searchWrap: { type: Object, default: () => ({}) },
    totalCount: { type: Number, default: null },
  },
  data: () => ({
    errorMsg: '',
    scenarioActionType: { シナリオを開始: 'start', シナリオを停止: 'cancel' },
    scenario: { scenario_action_type: 'start', scenario_id: '' },
    allFriendsSelected: 0,
  }),
  computed: {
    targetFriendIds() {
      return this.checkedFriends.map((friend) => friend.id)
    },
  },
  methods: {
    async save() {
      this.errorMsg = ''
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.$wait.end('processing') /* start child component */
        return this.$toasted.error(this.$t('danger.params'))
      }
      const path = `/api/v1/friends/target_friends_update_scenarios`
      const prms = {
        friend_ids: this.targetFriendIds,
        scenario: this.scenario,
        all_friends_selected: this.allFriendsSelected,
        search_wrap: this.searchWrap,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.dialog = false
          this.$emit('update')
        })
        .catch(() => {
          this.$toasted.error(this.$t('danger.error_occurred'))
        })
        .finally(() => {
          this.$wait.end('processing') /* start child component */
        })
    },
  },
}
</script>
<style scoped>
.time-pick {
  width: 100px;
}
.label-mb-0 ::v-deep label {
  margin-bottom: 0px;
}
</style>
