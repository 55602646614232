<template>
  <v-row>
    <v-col v-for="(form, form_i) in answerForm" :key="form_i" md="6">
      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th class="text-left font-weight-bold" colspan="2">
                回答{{ form.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ moment().subtract(1, 'day').format('MM月DD日') + `～: `
                }}<span class="font-weight-bold">{{
                  `${form.last_day_total_answer}回答 (${form.last_day_total_friend}人)`
                }}</span>
              </td>
              <td>
                {{ `累計：`
                }}<span class="font-weight-bold">{{
                  `${form.total_answer}回答 (${form.total_friends_answer}人)`
                }}</span>
              </td>
            </tr>
            <template v-if="form.most_three_recent_answers.length > 0">
              <tr
                v-for="(answer, answer_id) in form.most_three_recent_answers"
                :key="answer_id"
              >
                <td colspan="2">
                  <a
                    class="text-decoration-none black--text"
                    :href="
                      $router.resolve({
                        name: 'AnswerFormShow',
                        params: { id: form.id },
                      }).href + '?tab=3'
                    "
                  >
                    {{ moment(answer.created_at).format('MM月DD日 HH:mm') + ' '
                    }}<span class="font-weight-bold">{{
                      answer.hasOwnProperty('friend')
                        ? answer.friend.name
                        : '匿名'
                    }}</span>
                  </a>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <a
                    class="text-decoration-none black--text"
                    :href="
                      $router.resolve({
                        name: 'AnswerFormShow',
                        params: { id: form.id },
                      }).href + '?tab=3'
                    "
                    >続きを表示</a
                  >
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="2">回答がありません。</td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
export default {
  name: 'PtFavoriteAnswerForm',
  data: function () {
    return {
      loading: false,
      errorMsg: '',
      answerForm: [],
      moment: moment,
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.errorMsg = ''
      const path = '/api/v1/answer_forms/daily_report'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.answerForm = res.data.answer_forms
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
      this.loading = false
    },
  },
}
</script>
