<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
        {{ $t('subtitle.inflow_route_friend') }}
      </v-col>
      <v-col cols="2">
        <div style="text-align: right; margin-top: 20px">
          <v-btn color="primary" @click="frirndListBtActoin">
            {{ $t('lbl.inflow_route.friend_list') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-card outlined>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :disable-sort="true"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150],
        }"
      >
        <template #[`item.api_display_name`]="{ item }">
          <template v-if="item.friend_id">
            <router-link
              :to="{ name: 'FriendShow', params: { id: item.friend_id } }"
            >
              {{ item.api_display_name }}
            </router-link>
          </template>
          <template v-else>
            {{ item.api_display_name }}
          </template>
        </template>
        <template #[`item.created_at`]="{ item }">
          {{ $$fat(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>
    <v-row>
      <v-col class="text-right">
        <v-btn @click="$router.push({ name: 'InflowRouteIndex' })">{{
          $t('btn.back')
        }}</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    headers: [],
    items: [],
    loading: false,
    searchWrapId: '',
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/inflow_routes/' + this.$route.params.id + '/friends'
      await this.axios
        .get(path)
        .then((res) => {
          this.headers = res.data.headers
          this.items = res.data.friends
          this.searchWrapId = res.data.search_wrap_id
          console.log(this.items)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    frirndListBtActoin() {
      this.$router.push({
        name: 'FriendIndex',
        query: { search_wrap_id: this.searchWrapId },
      })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.or-list {
  max-width: 300px;
  font-size: 0.5em;
}
</style>
