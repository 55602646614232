<template>
  <div>
    <PtTitle />
    <v-row>
      <v-col md="6" cols="12">
        <channel-show
          class="mb-4"
          :channel="channel"
          :vees="vees"
          @save="updateChannel"
        />
        <test-account />
      </v-col>
      <v-col md="6" cols="12">
        <credit-card-index
          v-if="isChannelOwner"
          class="mb-4"
          :credit-cards="creditCards"
          @create:token="createCreditCard"
          @destroy:credit-card="destroyCreditCard"
          @update:default-card="updateDefaultCard"
        />
        <PtPaymentHistoryIndex
          :headers="historyHeaders"
          :items="paymentHistories"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ChannelShow from './Form/ChannelShow'
import TestAccount from './Form/TestAccount'
import CreditCardIndex from './Form/CreditCardIndex'
import PtPaymentHistoryIndex from 'components/parts/unique/PtPaymentHistoryIndex'
export default {
  components: {
    ChannelShow,
    TestAccount,
    CreditCardIndex,
    PtPaymentHistoryIndex,
  },
  data: () => ({
    loading: {
      channel: false,
      creditCards: false,
      paymentHistories: false,
    },
    channel: {},
    vees: {},
    creditCards: [],
    historyHeaders: [],
    paymentHistories: [],
    isChannelOwner: false,
  }),
  watch: {
    loading: {
      handler() {
        const pageLoading = Object.values(this.loading).reduce(
          (sum, val) => sum || val
        )
        this.$$loadingOverlayContent(pageLoading)
      },
      deep: true,
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getChannel()
      this.getPaymentHistories()
      this.getCreditCards()
    },
    getChannel() {
      this.loading.channel = true
      const path = `/api/v1/channel/edit`
      this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.channel = res.data.channel
          this.vees = res.data.vees
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading.channel = false
        })
    },
    updateChannel(formChannel) {
      this.loading.channel = true
      const path = `/api/v1/channel`
      const prms = { channel: formChannel }
      this.$$sendForm('put', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.channel = formChannel
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading.channel = false
        })
    },
    getCreditCards() {
      this.loading.creditCards = true
      const path = '/api/v1/credit_cards'
      this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          if (!res.data.is_not_channel_owner) {
            this.isChannelOwner = true
            this.creditCards = res.data.credit_cards
          }
        })
        .catch((error) => {
          this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.loading.creditCards = false
        })
    },
    createCreditCard(token) {
      this.loading.creditCards = true
      const path = '/api/v1/credit_cards'
      const prms = { token: token }
      this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.getCreditCards()
        })
        .catch((error) => {
          this.$$ajaxError(error)
          this.loading.creditCards = false
        })
    },
    destroyCreditCard(creditCardId) {
      this.loading.creditCards = true
      const path = `/api/v1/credit_cards/${creditCardId}`
      this.$$sendForm('delete', path)
        .then(() => {
          this.$toasted.success(this.$t('success.delete'))
          this.getCreditCards()
        })
        .catch((error) => {
          this.$$ajaxError(error)
          this.loading.creditCards = false
        })
    },
    updateDefaultCard(creditCardId) {
      this.loading.creditCards = true
      const path = `/api/v1/credit_cards/${creditCardId}`
      this.$$sendForm('put', path)
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.getCreditCards()
        })
        .catch((error) => {
          this.$$ajaxError(error)
          this.loading.creditCards = false
        })
    },
    getPaymentHistories() {
      this.loading.paymentHistories = true
      const path = `/api/v1/payment_histories`
      this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.historyHeaders = res.data.thead
          this.paymentHistories = res.data.payment_histories
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading.paymentHistories = false
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
