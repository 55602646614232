<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-list-item>
        <v-list-item-content>
          {{ $t('account.staff_add') }}
        </v-list-item-content>
      </v-list-item>
    </v-card-title>
    <validation-observer ref="observer">
      <v-form @submit.prevent="save">
        <v-card-text>
          <vf-text-field
            v-model="code"
            label="コード"
            :vees="{ rules: 'required' }"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="clear">
            {{ $t('btn.clear') }}
          </v-btn>
          <v-btn type="submit" color="primary">
            {{ $t('account.regist') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card>
</template>
<script>
export default {
  data: () => ({
    code: '',
  }),
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.$emit('save', this.code)
    },
    clear() {
      this.code = ''
      this.$refs.observer.reset()
    },
  },
}
</script>
