<template>
  <div>
    <v-card outlined class="transparent">
      <v-card-title class="pl-0 pa-0 or-card-title">
        <v-list-item class="pa-0">
          <v-list-item-content> 最新受信メッセージ </v-list-item-content>
          <v-list-item-action>
            <v-btn
              small
              class="or-card-title"
              @click="$router.push({ name: 'MessageIndex' })"
            >
              さらに詳しく
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <VfAlert v-model="errorMsg" type="error" />
      <v-card outlined>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          disable-sort
          hide-default-footer
        >
          <template #[`item.friend.name`]="{ item }">
            <router-link
              :to="{ name: 'FriendShow', params: { id: item.friend.id } }"
            >
              <v-avatar size="36" class="mr-2">
                <v-img
                  small
                  :src="$$noimageHelper(item.friend.api_picture_url)"
                  :alt="item.friend.name"
                />
              </v-avatar>
              {{ item.friend.name }}
            </router-link>
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ $$fdt(item.created_at) }}
            <br />
            {{ $$ftm(item.created_at, 'LTS') }}
          </template>
          <template #[`item.message_status`]="{ item }">
            <div v-if="item.message_type === 'receive'">
              <v-chip
                dark
                label
                :color="colors[item.message_status]"
                @click="openDialogMessageStatus(item)"
              >
                {{ $$te('friend.message_status', item) }}
              </v-chip>
            </div>
          </template>
          <template #[`item.deli`]="{ item }">
            <PtLinePreviewRow
              class="my-2"
              :deli="item.deli"
              :message-type="item.message_type"
              hide-detail
            />
          </template>
        </v-data-table>
      </v-card>
    </v-card>
    <v-dialog v-model="dialogMessageStatus" max-width="400">
      <v-card outlined>
        <v-card-text class="pt-6">
          <VfSelect
            v-model="selectedItemClone.message_status"
            :label="$$tm('message.message_status')"
            :selects="opts.message_status"
          />
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="closeDialogMessageStatus">{{
            $t('btn.cancel')
          }}</v-btn>
          <v-btn color="primary" @click="updateDialogMessageStatus">{{
            $t('btn.update')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtLinePreviewRow from 'components/parts/common/PtLinePreviewRow'
export default {
  components: {
    PtLinePreviewRow,
  },
  data: () => ({
    loading: false,
    dialogMessageStatus: false,
    headers: [],
    items: [],
    opts: {},
    selectedItem: { id: '' },
    selectedItemClone: {},
    errorMsg: '',
    colors: {
      unread: 'primary',
      magazine_comment: 'primary',
      low_important: 'primary',
      done: 'default',
      claim: 'red',
      closing: 'red',
      trouble: 'red',
      question: 'red',
    },
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/messages/receive_messages'
      const prms = { limit: 30 }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.messages
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    openDialogMessageStatus(val) {
      this.selectedItem = val
      this.selectedItemClone = this.$$clone(val)
      this.dialogMessageStatus = true
    },
    closeDialogMessageStatus() {
      this.dialogMessageStatus = false
    },
    async updateDialogMessageStatus() {
      this.errorMsg = ''
      const path = `/api/v1/messages/${this.selectedItem.id}/update_message_status`
      const prms = { message: this.selectedItemClone }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          Object.assign(this.selectedItem, this.selectedItemClone)
          this.$toasted.success(this.$t('success.update'))
          this.dialogMessageStatus = false
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
    },
  },
}
</script>
