<template>
  <div v-if="hasDisplayRole">
    <v-card>
      <v-card-text v-if="internalValue.name">
        <PtFriendShowActionHashtag
          v-if="displayRole.friend_hashtag"
          v-model="internalValue.hashtags"
          class="mb-4"
          :friend-id="internalValue.id"
        />
        <PtFriendShowActionScenario
          v-if="displayRole.friend_scenario"
          v-model="internalValue.active_scenario"
          class="mb-4"
          :friend-id="internalValue.id"
        />
        <PtFriendShowActionReminder
          v-if="displayRole.friend_reminder"
          v-model="internalValue.active_reminders"
          class="mb-4"
          :friend-id="internalValue.id"
        />
        <PtFriendShowActionSchedule
          v-if="displayRole.friend_schedule"
          v-model="internalValue.schedules"
          class="mb-4"
          :friend-id="internalValue.id"
        />
        <PtFriendShowActionRichMenu
          v-if="displayRole.friend_rich_menu"
          v-model="internalValue.rich_menu"
          :friend-id="internalValue.id"
        />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PtFriendShowActionHashtag from 'components/parts/unique/PtFriendShowActionHashtag'
import PtFriendShowActionScenario from 'components/parts/unique/PtFriendShowActionScenario'
import PtFriendShowActionReminder from 'components/parts/unique/PtFriendShowActionReminder'
import PtFriendShowActionSchedule from 'components/parts/unique/PtFriendShowActionSchedule'
import PtFriendShowActionRichMenu from 'components/parts/unique/PtFriendShowActionRichMenu'
export default {
  components: {
    PtFriendShowActionHashtag,
    PtFriendShowActionScenario,
    PtFriendShowActionReminder,
    PtFriendShowActionSchedule,
    PtFriendShowActionRichMenu,
  },
  props: {
    value: { type: Object, required: true },
    opts: { type: Object, default: () => ({}) },
    vees: { type: Object, default: () => ({}) },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    displayRole() {
      return {
        friend_hashtag: this.$store.getters.roles.includes('friend_hashtag'),
        friend_scenario: this.$store.getters.roles.includes('friend_scenario'),
        friend_reminder: this.$store.getters.roles.includes('friend_reminder'),
        friend_schedule: this.$store.getters.roles.includes('friend_schedule'),
        friend_rich_menu:
          this.$store.getters.roles.includes('friend_rich_menu'),
      }
    },
    hasDisplayRole() {
      const keys = Object.keys(this.displayRole)
      const filteredRoles = keys.filter((key) => this.displayRole[key])
      return filteredRoles.length > 0
    },
  },
}
</script>
