<template>
  <v-dialog v-model="dialog" :max-width="800">
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" icon outlined v-on="on">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <validation-observer ref="observer">
      <v-form @submit.prevent="save">
        <v-card>
          <v-card-title>
            {{ $$tm('user_address.tname') }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <vf-text-field
              v-model="cloneUserAddress.zip"
              :label="$$tm('user_address.zip')"
              :vees="vees.zip"
            />
            <vf-select
              v-model="cloneUserAddress.pref"
              :label="$$tm('user_address.pref')"
              :vees="vees.pref"
              :selects="opts.pref"
              disabled
            />
            <vf-text-field
              v-model="cloneUserAddress.address1"
              :label="$$tm('user_address.address1')"
              :vees="vees.address1"
            />
            <vf-text-field
              v-model="cloneUserAddress.address2"
              :label="$$tm('user_address.address2')"
              :vees="vees.address2"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn @click="dialog = false">
                {{ $t('btn.cancel') }}
              </v-btn>
              <v-btn type="submit" color="primary">
                {{ $t('btn.update') }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>
<script>
export default {
  props: {
    userAddress: { type: Object, required: true },
    vees: { type: Object, required: true },
    opts: { type: Object, required: true },
  },
  data: () => ({
    dialog: false,
    cloneUserAddress: {},
  }),
  watch: {
    dialog(val) {
      if (val) {
        this.cloneUserAddress = this.$$clone(this.userAddress)
      }
    },
    'cloneUserAddress.zip'(val) {
      this.getAddress(val)
    },
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.$emit('save', this.cloneUserAddress)
      this.dialog = false
    },
    getAddress(val) {
      if (!val || val.length !== 7) {
        return
      }
      const path = '/api/v1/address/get_address_from_zip'
      const prms = { zipcode: val }
      this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          if (res.data.address) {
            this.cloneUserAddress.pref = this.opts.pref[res.data.address.pref]
            this.cloneUserAddress.address1 = res.data.address.address
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
  },
}
</script>
