<template>
  <div v-if="!hidden" class="mb-3">
    <v-row>
      <v-col v-if="!hiddenTitle" sm="4" xs="12" cols="12">
        <span class="text-bold">{{ title }}</span>
        <v-chip
          v-if="required"
          class="ma-2"
          label
          color="red"
          text-color="white"
          small
        >
          {{ $t('lbl.answer_form.require') }}
        </v-chip>
      </v-col>
      <v-col :sm="!hiddenTitle ? 8 : 12" xs="12" cols="12">
        <VfDatePicker
          v-model="localValue"
          class="vf-hidden-label"
          :placeholder="$t('lbl.answer_form.date_placeholder')"
          :min="
            data && data.action_arr.length
              ? new Date().toISOString().slice(0, 10)
              : ''
          "
          :label="title"
          :vees="vees"
          @input="$emit('input', localValue)"
        />
        <div class="subtitle-3 theme--light v-messages">{{ hint }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PtFormRenderDate',
  props: {
    value: { default: null },
    label: { type: String, default: null },
    title: { type: String, default: null },
    hint: { type: String, default: null },
    placeholder: { type: String, default: null },
    required: { default: 0 },
    hidden: { default: 0 },
    hiddenTitle: { default: 0 },
    data: { default: null },
  },
  data: function () {
    return {
      loading: false,
      errorMsg: '',
      localValue: '',
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    vees: function () {
      let rules = {
        rules: '',
      }

      if (this.required) {
        rules.rules += (rules.rules ? '|' : '') + 'required'
        rules.required = true
      }

      return rules
    },
  },
  created() {
    this.localValue = this.$$clone(this.value)
  },
  methods: {},
}
</script>

<style scoped lang="scss">
::v-deep {
  fieldset > legend {
    display: none;
  }

  .v-label {
    display: none;
  }

  .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
