<template>
  <div>
    <v-card class="mx-auto px-12 py-6" max-width="800">
      <v-card-title class="mb-4">
        <v-row justify="center">
          <div>{{ $t(`meta.title.${$route.name}`) }}</div>
        </v-row>
      </v-card-title>
      <v-card class="mb-4">
        <v-card-title>
          {{ $t('lbl.liff_app.registration_title') }}
        </v-card-title>
        <VDivider class="my-0" />
        <v-card-text>
          <div class="or-break">{{ $t('lbl.liff_app.registration_desc') }}</div>
          <v-card class="mt-4">
            <VImg src="/cstms/line_liff_app.png" alt="liff app" />
          </v-card>
        </v-card-text>
      </v-card>
      <v-card class="mb-4">
        <v-card-title>
          {{ $t('lbl.liff_app.registration_scope_title') }}
        </v-card-title>
        <VDivider class="my-0" />
        <v-card-text>
          <div class="or-break">
            {{ $t('lbl.liff_app.registration_scope_desc') }}
          </div>
          <v-card class="mt-4">
            <VImg
              src="/cstms/line_liff_app_scope_setting.png"
              alt="liff app scope setting"
            />
          </v-card>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>
          {{ $t('lbl.liff_app.registration_confirm_title') }}
        </v-card-title>
        <v-card-text>
          <div class="or-break">
            {{ $t('lbl.liff_app.registration_confirm_desc') }}
          </div>
          <VfCheckbox
            v-model="nextReady"
            :label="$t('lbl.liff_app.registration_confirm_checkbox')"
          />
          <div class="w-100 d-flex justify-content-end">
            <v-btn
              color="primary"
              :disabled="!nextReady"
              @click="$router.push({ name: 'RegistrationDone' })"
            >
              {{ $t('btn.create') }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    nextReady: false,
  }),
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
