<template>
  <v-card class="mx-auto py-4" max-width="800">
    <v-form @submit.prevent="submitForm">
      <v-card-title>
        <v-row justify="center">
          <div>{{ $t(`meta.title.${$route.name}`) }}</div>
        </v-row>
      </v-card-title>
      <v-divider class="mx-4" />
      <v-card v-if="message" color="grey lighten-4" class="ma-8">
        <v-card-text>
          <v-row justify="center">
            <div class="or-break">{{ message }}</div>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card-text class="text-h5 font-weight-bold">
        <v-row>
          <v-col cols="12">
            <vf-text-field v-model="item.email" label="メールアドレス" />
          </v-col>
          <v-col cols="12">
            <vf-text-field
              v-model="item.password"
              label="パスワード"
              password
            />
          </v-col>
          <v-col cols="12">
            <vue-recaptcha
              ref="recaptcha"
              sitekey="6LeIc-UUAAAAAF_hZmOAtE6maZozgVQCH3nEdvBh"
              @verify="onVerify"
            />
          </v-col>
        </v-row>
        <a
          class="small-link"
          @click="$router.push({ name: 'Reconfirmation' })"
          >{{ $t('lbl.reconfirmation') }}</a
        ><br />
        <a
          class="small-link"
          @click="$router.push({ name: 'AccountPasswordReset' })"
          >{{ $t('lbl.forget_password') }}</a
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn type="submit" color="primary" :disabled="submiting">
          ログイン
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha'
export default {
  components: {
    VueRecaptcha,
  },
  data: () => ({
    item: {
      email: '',
      password: '',
    },
    submiting: false,
  }),
  computed: {
    message() {
      if (this.$route.query.is_user_confirmed === '1')
        return this.$t('lbl.email_done')
      if (this.$route.query.user_unlocked === '1')
        return this.$t('lbl.unlock_done')
      if (this.$route.query.unlock_token_invalid === '1')
        return this.$t('lbl.unlock_token_invalid')
      return ''
    },
  },
  methods: {
    submitForm() {
      this.submiting = true
      const url = '/auth/sign_in'
      this.axios
        .post(url, this.item)
        .then((res) => {
          this.$store.dispatch('login', res)
          this.$store.dispatch('selectChannel', res.data.channel)
          this.$store.dispatch('selectChannelRoles', res.data.roles)
          this.$toasted.success('login')
          this.$router.push({ name: res.data.next_page })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$$glMsg('error', error.response.data.errors[0])
          } else {
            this.$$ajaxError(error)
          }
          this.$toasted.show('error')
        })
        .finally(() => {
          this.submiting = false
        })
    },
    onVerify(response) {
      if (response) this.form.robot = true
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>

<style>
.small-link {
  font-size: 12px;
}
</style>
