<template>
  <div>
    <v-data-table
      v-model="checkedMessages"
      :headers="headers"
      :items="items"
      item-key="id"
      show-select
      disable-sort
      :server-items-length="totalCount"
      :options.sync="tableOptions"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [50, 100, 150],
      }"
    >
      <template #[`item.friend.name`]="{ item }">
        <router-link
          :to="{ name: 'FriendShow', params: { id: item.friend.id } }"
        >
          <v-avatar size="36" class="mr-2">
            <v-img
              small
              :src="$$noimageHelper(item.friend.api_picture_url)"
              :alt="item.friend.name"
            />
          </v-avatar>
          {{ item.friend.name }}
        </router-link>
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ $$fdt(item.created_at) }}
        <br />
        {{ $$ftm(item.created_at, 'LTS') }}
      </template>
      <template #[`item.data-table-select`]="{ item, isSelected, select }">
        <v-simple-checkbox
          v-if="item.message_type === 'receive'"
          :value="isSelected"
          @input="select($event)"
        />
      </template>
      <template #[`item.message_status`]="{ item }">
        <div v-if="item.message_type === 'receive'">
          <v-chip
            dark
            label
            :color="colors[item.message_status]"
            @click="openDialogMessageStatus(item)"
          >
            {{ $$te('friend.message_status', item) }}
          </v-chip>
        </div>
      </template>
      <template #[`item.deli`]="{ item }">
        <PtLinePreviewRow
          class="my-2"
          :deli="item.deli"
          :message-type="item.message_type"
          hide-detail
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import PtLinePreviewRow from 'components/parts/common/PtLinePreviewRow'
export default {
  components: {
    PtLinePreviewRow,
  },
  props: {
    headers: { type: Array, required: true },
    items: { type: Array, default: () => [] },
    options: { type: Object, required: true },
    totalCount: { type: Number, default: null },
    checkedItems: { type: Array, default: () => [] },
  },
  data: () => ({
    loading: false,
    search: '',
    colors: {
      unread: 'primary',
      magazine_comment: 'primary',
      low_important: 'primary',
      done: 'default',
      claim: 'red',
      closing: 'red',
      trouble: 'red',
      question: 'red',
    },
  }),
  computed: {
    tableOptions: {
      get() {
        return this.options
      },
      set(val) {
        this.$emit('update:options', val)
      },
    },
    checkedMessages: {
      get() {
        return this.checkedItems
      },
      set(val) {
        this.$emit('update:checked-items', val)
      },
    },
  },
  methods: {
    openDialogMessageStatus(item) {
      this.$emit('open-dialog-message-status', item)
    },
  },
}
</script>
