<template>
  <v-card class="my-4">
    <VfLoadingOverlayAbsolute :loading="loading" />
    <div class="pt-3 mr-3 justify-end d-flex">
      {{ $t('lbl.visit_count', { count: visit_count }) }}
    </div>
    <v-data-table :headers="headers" :items="items" item-key="id" disable-sort>
      <template #[`item.deli_id`]="{ item }">
        <v-card width="100">
          <VImg :src="item.deli.deli_image.preview_url" />
        </v-card>
      </template>
      <template #[`item.friend_id`]="{ item }">
        <div v-if="item.friend">
          {{ item.friend.name }}
        </div>
        <div v-else>
          {{ $t('lbl.unknown') }}
        </div>
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ $$fat(item.created_at) }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    clickId: { type: String, required: true },
  },
  data: () => ({
    headers: [],
    items: [],
    visit_count: 0,
    loading: false,
    csv_data: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/click_rich_messages'
      const prms = { click_id: this.clickId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.click_rich_messages
          this.visit_count = res.data.visit_count

          let headers = this.headers.map((h) => h.text)
          let items = this.items.map((item) => [
            item.deli.deli_image.preview_url,
            item.friend ? item.friend.name : this.$t('lbl.unknown'),
            this.$$fat(item.created_at),
          ])
          this.csv_data = [headers].concat(items)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
