import * as VueGoogleMaps from 'vue2-google-maps'

const VueGoogleMapsPlugin = {
  install(Vue) {
    Vue.use(VueGoogleMaps, {
      load: {
        key: 'AIzaSyDjIaM4rYBL1Y2gtKsrdcKw6Jo8IOpF4gg',
        libraries: 'places',
        region: 'JP',
        language: 'ja',
      },
      installComponents: true,
    })
  },
}

export default VueGoogleMapsPlugin
