<template>
  <div>
    <v-row>
      <v-col cols="8">
        <PtTitle />
      </v-col>
      <v-col cols="4" align="right" class="pt-8">
        <v-btn
          color="primary"
          :to="{ name: 'AdminPlanForm', params: { id: 'new' } }"
        >
          {{ $t('btn.new_item') }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="or-break mb-4">{{ $t('subtitle.admin_plan') }}</div>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :disable-sort="true"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150],
        }"
      >
        <template #[`item.name`]="{ item }">
          <router-link :to="{ name: 'AdminPlanForm', params: { id: item.id } }">
            {{ item.name }}
          </router-link>
        </template>
        <template #[`item.item_status`]="{ item }">
          {{ $$te('plan.item_status', item) }}
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn small @click="toRoleSetting(item)">
            {{ $t('btn.plan_role_setting') }}
          </v-btn>
          <btn-dialog-registor-url :item="item" />
          <btn-dialog-active
            v-if="item.item_status !== 'active'"
            @save="saveItemStatus(item, 'active')"
          />
          <btn-dialog-hidden
            v-if="item.item_status === 'active'"
            @save="saveItemStatus(item, 'hidden')"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import BtnDialogRegistorUrl from './Index/BtnDialogRegistorUrl'
import BtnDialogActive from './Index/BtnDialogActive'
import BtnDialogHidden from './Index/BtnDialogHidden'
export default {
  components: {
    BtnDialogRegistorUrl,
    BtnDialogActive,
    BtnDialogHidden,
  },
  data: () => ({
    loading: false,
    items: [],
    headers: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/plans`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.items = res.data.plans
          for (const item of this.items) {
            item.url = `${window.location.protocol}//${window.location.host}/registration?plan_id=${item.id}`
          }
          this.headers = res.data.thead
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    saveItemStatus(item, itemStatus) {
      this.$$loadingOverlayContent(true)
      const path = `/api/v1/plans/${item.id}/${itemStatus}`
      this.$$sendForm('post', path, this.$$reqPrms())
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    toRoleSetting(item) {
      this.$router.push({ name: 'AdminPlanRole', params: { id: item.id } })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
