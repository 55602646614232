<template>
  <div>
    <v-list-item class="p-0">
      <v-card-title>
        <PtTitle />
      </v-card-title>
      <v-list-item-content />
      <v-card-actions>
        <v-btn type="submit" color="success" @click="submitForm(user.id)"
          >{{ $t('btn.login_to_user_site') }}
        </v-btn>
      </v-card-actions>
    </v-list-item>
    <v-row>
      <v-col md="6">
        <v-card class="mb-6">
          <v-card-title>
            {{ $t('admin.user_info') }}
          </v-card-title>
          <v-simple-table>
            <template #default>
              <tbody>
                <tr>
                  <td>{{ $t('admin.name') }}</td>
                  <td>{{ user.name }}</td>
                </tr>
                <tr>
                  <td>{{ $$tm('user.email') }}</td>
                  <td>{{ user.email }}</td>
                </tr>
                <tr>
                  <td>{{ $$tm('user.phone') }}</td>
                  <td>{{ user.phone }}</td>
                </tr>
                <tr>
                  <td>{{ $$tm('user_address.zip') }}</td>
                  <td>{{ address.zip }}</td>
                </tr>
                <tr>
                  <td>{{ $$tm('user_address.tname') }}</td>
                  <td>
                    <div v-if="address.pref">
                      {{ $$te('user_address.pref', address) }}
                      {{ address.address1 }}
                      {{ address.address2 }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{ $$tm('user.admin_created_flg') }}</td>
                  <td>
                    {{
                      $t(
                        `lbl.account.admin_created_flg.${user.admin_created_flg}`
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col md="6">
        <v-card class="mb-6">
          <v-card-title>
            {{ $t('admin.channel_list') }}
          </v-card-title>
          <v-card-text>
            <v-list-item v-for="channel in channels" :key="channel.id">
              <v-list-item-content>
                <v-list-item-title>
                  {{ channel.name }} {{ channel.line_account_id }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-row>
                  <v-col v-if="channel.stop_flg == 1" disabled>
                    <v-btn icon disabled>
                      <v-icon>far fa-times-circle</v-icon>
                    </v-btn>
                  </v-col>

                  <v-col>
                    <btn-dialog-channel-form
                      v-model="cloneChannel"
                      :vees="vees"
                      :is-user="false"
                      @click="initCloneChannel(channel)"
                      @save="updateChannel(cloneChannel)"
                    />
                    <v-btn icon @click="openChannelDialog(channel)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mb-6">
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-card-title>
        {{ $t('admin.payment_history') }}
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        :disable-sort="true"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150],
        }"
      >
        <template #[`item.plan_name`]="{ item }">
          {{ item.plan_name }}
        </template>
        <template #[`item.created_at`]="{ item }">
          {{ $$fat(item.created_at) }}
        </template>
        <template #[`item.payment_status`]="{ item }">
          {{ $$te('payment_history.payment_status', item) }}
          <v-btn
            v-if="unconfirmedPayment(item)"
            icon
            @click="openPaymentDialog(item)"
          >
            <v-icon>fas fa-exclamation-circle</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="paymentDialog" :max-width="500">
      <v-card>
        <v-card-title>
          {{ $t('admin.payment_status_change') }}
        </v-card-title>
        <v-card-text>
          <v-col>
            {{ $t('admin.payment_confirm') }}
            <br />
            <p class="red--text text-caption">
              {{ $t('admin.manual_operation_invalid_period') }}
            </p>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <VSpacer />
          <v-btn @click="closePaymentDialog">{{ $t('btn.cancel') }}</v-btn>
          <v-btn
            color="primary"
            :disabled="paymentHistory.manual_operation_invalid_period"
            @click="save"
            >{{ $t('btn.action') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="channelDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">{{
          $t('confirm_title.delete')
        }}</v-card-title>
        <v-card-text>
          {{ $t('lbl.confirm_channel_delete') }}
        </v-card-text>
        <v-col md="8">
          <v-text-field v-model="inputDeleteAccount" label="ID入力" />
        </v-col>
        <v-card-actions v-if="matchAccountId">
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeChannelDialog">
            {{ $t('btn.cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteChannel">
            {{ $t('btn.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BtnDialogChannelForm from 'pages/v1/channels/Form/BtnDialogChannelForm'
export default {
  components: {
    BtnDialogChannelForm,
  },
  data: () => ({
    headers: [],
    items: [],
    user: {},
    userId: '',
    address: {},
    channels: [],
    password: '',
    selectChannel: {},
    paymentHistory: {},
    paymentStatus: {},
    inputDeleteAccount: '',
    cloneChannel: {},
    loading: false,
    channelDialog: false,
    paymentDialog: false,
  }),
  computed: {
    matchAccountId() {
      return this.selectChannel.line_account_id == this.inputDeleteAccount
    },
  },
  created: function () {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.userId = this.$route.params.id
      const path = `/api/v1/admin/users/${this.userId}`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.user = res.data.user
          this.channels = res.data.channels
          this.headers = res.data.headers
          this.vees = res.data.vees
          if (res.data.address != null) {
            this.address = res.data.address
          }
          this.getHistory()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    initCloneChannel(channel) {
      this.cloneChannel = this.$$clone(channel)
    },
    submitForm(uid) {
      const url = '/api/sign_in_user/' + uid + '/'
      this.axios
        .post(url, this.item)
        .then((res) => {
          this.$store.dispatch('login', res)
          this.$store.dispatch('selectChannel', res.data.channel)
          this.$store.dispatch('selectChannelRoles', res.data.roles)
          this.$toasted.success('login')
          this.$router.push({ name: 'AccountShow' })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$$glMsg('error', this.$t('danger.login_miss'))
          } else {
            this.$$ajaxError(error)
          }
          this.$toasted.show('error')
        })
        .finally(() => {})
    },
    async getHistory() {
      this.loading = true
      const prms = { id: this.$route.params.id }
      const path = '/api/v1/payment/history'
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.headers
          this.items = res.data.histories
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async deleteItem(channel_id) {
      this.loading = true
      const path = `/api/v1/admin/users/${this.userId}/channel_destroy`
      const prms = { channel_id: channel_id }
      const method = 'post'
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.delete'))
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async save() {
      this.loading = true
      this.paymentStatus = 'transfer'
      const path = '/api/v1/payment'
      const method = 'put'
      const prms = {
        payment_status: this.paymentStatus,
        payment_history_id: this.paymentHistory.id,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.paymentDialog = false
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateChannel(formChannel) {
      this.loading = true
      const path = `/api/v1/admin/channel`
      const prms = { channel: formChannel, user_id: this.$route.params.id }
      this.$$sendForm('put', path, this.$$reqPrms(prms))
        .then((res) => {
          this.$toasted.success(this.$t('success.update'))
          this.channels = res.data.channels
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    openChannelDialog(channel) {
      this.channelDialog = true
      this.selectChannel = channel
    },
    closeChannelDialog() {
      this.channelDialog = false
    },
    openPaymentDialog(paymentHistory) {
      this.paymentDialog = true
      this.paymentHistory = paymentHistory
    },
    closePaymentDialog() {
      this.paymentDialog = false
    },
    deleteChannel() {
      this.channelDialog = false
      this.deleteItem(this.selectChannel.id)
    },
    unconfirmedPayment(item) {
      return item.conf_flg !== 1 && item.payment_status == 'paused'
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
