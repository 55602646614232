<template>
  <div>
    <v-card class="mb-8">
      <v-card-text>
        <VfLoadingOverlayAbsolute :loading="$wait.is('processing')" />
        <VfAlert v-model="errorMsg" type="error" />
        <v-row>
          <v-col md="4" class="pb-0">
            <VfSelect
              v-model="messageStatus"
              :label="$$tm('friend.message_status')"
              :selects="selects"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <pt-friend-update-common-bottom
      v-model="allFriendsSelected"
      :friends="friends"
      :total-count="totalCount"
      :alert-message="$t('lbl.confirm_friends_status_update')"
      @save="save"
    />
  </div>
</template>
<script>
import PtFriendUpdateCommonBottom from './PtFriendUpdateCommonBottom.vue'
export default {
  components: {
    PtFriendUpdateCommonBottom,
  },
  props: {
    friends: { type: Array, required: true },
    checkedFriends: { type: Array, required: true },
    searchWrap: { type: Object, default: () => ({}) },
    totalCount: { type: Number, default: null },
  },
  data: () => ({
    errorMsg: '',
    vees: {},
    selects: {},
    messageStatus: 'done',
    allFriendsSelected: 0,
  }),
  computed: {
    targetFriendIds() {
      return this.checkedFriends.map((friend) => friend.id)
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const path = `/api/v1/friends/status_base`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.vees = res.data.vees
          this.selects = res.data.opts.message_status
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    async save() {
      this.errorMsg = ''
      const path = `/api/v1/friends/target_friends_update_status`
      const prms = {
        friend_ids: this.targetFriendIds,
        status: this.messageStatus,
        all_friends_selected: this.allFriendsSelected,
        search_wrap: this.searchWrap,
      }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.update'))
          this.$emit('update')
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
        .finally(() => {
          this.$wait.end('processing') /* start child component */
        })
    },
  },
}
</script>
<style scoped>
.label-mb-0 ::v-deep label {
  margin-bottom: 0px;
}
</style>
