<template>
  <div>
    <template v-if="dispType === 'selectable'">
      <div class="mb-4">
        <v-btn @click="openDialog">{{ $t('lbl.search_wrap.edit') }}</v-btn>
        <v-btn @click="clearConfirm">{{ $t('lbl.search_wrap.clear') }}</v-btn>
        <v-btn @click="openTemplateDialog">{{
          $t('lbl.search_wrap.template')
        }}</v-btn>
      </div>
      <div v-for="(searchGroup, index) in searchWrapMsgs">
        <div v-if="index > 0" class="ma-6">{{ $t('lbl.search_wrap.or') }}</div>
        <v-card v-if="searchGroup.length">
          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left" width="30">No.</th>
                  <th class="text-left" width="200">{{ $t('lbl.type') }}</th>
                  <th class="text-left">{{ $t('lbl.search_condition') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="msg in searchGroup" :key="msg.no">
                  <td>{{ msg.no }}</td>
                  <td>{{ msg.type }}</td>
                  <td>{{ msg.operate }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </div>
      <v-dialog v-model="dialog">
        <v-card>
          <v-card-title class="green--text text--darken-1">
            {{ $t('lbl.search_wrap.setting') }}
          </v-card-title>
          <v-container>
            <validation-observer ref="childObserver">
              <PtSearchWrap v-model="clone" :hide-bottom="hideBottom" />
            </validation-observer>
          </v-container>
          <VDivider />
          <v-card-actions>
            <VSpacer />
            <v-btn color="error" @click="cancel">{{ $t('btn.cancel') }}</v-btn>
            <v-btn color="primary" @click="decide">{{
              $t('btn.decide')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="clearDialog" max-width="300">
        <v-card>
          <v-card-title>{{
            $t('lbl.search_wrap.clear_confirm_title')
          }}</v-card-title>
          <v-card-text>
            <div class="or-break">
              {{ $t('lbl.search_wrap.clear_confirm_desc') }}
            </div>
          </v-card-text>
          <v-card-actions>
            <VSpacer />
            <v-btn @click="clearDialog = false">{{ $t('btn.cancel') }}</v-btn>
            <v-btn color="error" @click="clearSearch">{{
              $t('lbl.search_wrap.clear')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="templateDialog" max-width="1200">
        <v-card>
          <VfLoadingOverlayAbsolute :loading="loading" />
          <v-card-title>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-text>
                    <validation-observer ref="searchTemplateObserver">
                      <VfSelect
                        v-model="selectedId"
                        :label="$$tm('search_template.name')"
                        :selects="selects"
                        :vees="{ rules: 'required' }"
                        readonly
                      />
                    </validation-observer>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="4">
                <PtFolder
                  ref="folder"
                  folder-type="search_template"
                  select-only
                  @select-folder="selectFolder"
                />
              </v-col>
              <v-col md="8">
                <v-data-table
                  v-model="activeTemplateClone"
                  :headers="headers"
                  :items="items"
                  item-key="id"
                  show-select
                  :single-select="true"
                  :disable-sort="true"
                  :footer-props="{
                    itemsPerPageOptions: [10, 30, 50, 100],
                  }"
                  @click:row="rowClick"
                >
                  <template #[`item.search_wrap`]="{ item }">
                    <PtSearchSelectedList
                      v-model="item.search_wrap.as_json_form"
                      class="or-list"
                      disp-type="list"
                    />
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <VSpacer />
            <v-btn color="error" @click="templateDialog = false">{{
              $t('btn.cancel')
            }}</v-btn>
            <v-btn color="primary" @click="decideTemplate">{{
              $t('btn.decide')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-else>
      <div v-for="(searchGroup, index) in searchWrapMsgs">
        <div v-if="index > 0">{{ $t('lbl.search_wrap.or') }}</div>
        <div v-for="msg in searchGroup" :key="msg.no">{{ msg.operate }}</div>
        <div v-if="searchGroup.length === 0">none</div>
      </div>
    </template>
  </div>
</template>
<script>
import PtSearchWrap from 'components/parts/common/PtSearchWrap/PtSearchWrap'
import PtFolder from 'components/parts/common/PtFolder'
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
import { validateSearchComponent } from 'plugins/vee-validate'
export default {
  name: 'PtSearchSelectedList',
  components: {
    PtSearchWrap,
    PtFolder,
    PtSearchSelectedList,
  },
  props: {
    value: { default: null },
    dispType: { type: String, default: 'selectable' },
    hideBottom: { type: Boolean, default: false },
  },
  data: () => ({
    firstInit: false,
    dialog: false,
    clearDialog: false,
    templateDialog: false,
    loading: false,
    opts: {},
    initData: {},
    allVees: {},
    clone: [],
    headers: [],
    items: [],
    activeTemplateClone: [],
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    searchWrapMsgs() {
      if (!this.internalValue) {
        return [[]]
      }
      return this.internalValue.search_arr.map((group) => {
        return group.map((search, index) => {
          const yml = `lbl.search_wrap.search_types_short_msg.${search.type}`
          const ret = {}
          ret.no = index + 1
          switch (search.type) {
            case 'name': {
              ret.type = this.$t(yml)
              const names = []
              if (search.data.api_display_name_flg) {
                names.push(this.$$tm('search_name.api_display_name_flg'))
              }
              if (search.data.system_disp_name_flg) {
                names.push(this.$$tm('search_name.system_disp_name_flg'))
              }
              ret.operate = this.$t('lbl.search_wrap.name_include', {
                targets: names.join(', '),
                name: search.data.name,
              })
              break
            }
            case 'message_status': {
              ret.type = this.$t(yml)
              const sts_arr = search.data.target_message_statuses.map((sts) =>
                this.$t(
                  `enum.search_message_status_choice.message_status.${sts}`
                )
              )
              ret.operate = this.$t('lbl.search_wrap.message_status_in', {
                message_statuses: sts_arr.join(', '),
              })
              break
            }
            case 'hashtag': {
              ret.type = this.$t(yml)
              const type = this.$$te(
                'search_hashtag.search_hashtag_type',
                search.data
              )
              const tags = search.data.hashtags.map((hashtag) => hashtag.name)
              ret.operate = `[${type}][${tags.join(', ')}]`
              break
            }
            case 'scenario':
              ret.type = this.$$te(
                'search_scenario.scenario_read_type',
                search.data
              )
              if (search.data.scenario_read_type === 'stop') {
                break
              }
              ret.operate = this.$t('lbl.search_wrap.scenario_is', {
                scenario: search.data.scenario.name,
                type: this.$$te(
                  'search_scenario.search_scenario_type',
                  search.data
                ),
              })
              break
            case 'reminder':
              ret.type = this.$t(yml)
              ret.operate = this.$t('lbl.search_wrap.reminder_is', {
                reminder: search.data.reminder.name,
                type: this.$$te(
                  'search_reminder.search_reminder_type',
                  search.data
                ),
              })
              break
            case 'last_response':
              ret.type = this.$t(yml)
              ret.operate = this.$t('lbl.search_wrap.last_response_is', {
                from: this.$$fdt(search.data.range_st_dt),
                to: this.$$fdt(search.data.range_ed_dt),
              })
              break
            case 'friend_created_at':
              ret.type = this.$t(yml)
              ret.operate = this.$t('lbl.search_wrap.friend_created_at_is', {
                from: this.$$fdt(search.data.range_st_dt),
                to: this.$$fdt(search.data.range_ed_dt),
              })
              break
            case 'inflow_route': {
              ret.type = this.$t(yml)
              const inflow_route_type = this.$$te(
                'search_inflow_route.search_inflow_route_type',
                search.data
              )
              const inflow_route_tags = search.data.inflow_routes.map(
                (inflow_route) => inflow_route.name
              )
              ret.operate = `[${inflow_route_type}][${inflow_route_tags.join(
                ', '
              )}]`
              break
            }
            case 'rich_menu': {
              ret.type = this.$t(yml)
              const rich_menu_type = this.$$te(
                'search_rich_menu.search_rich_menu_type',
                search.data
              )
              switch (search.data.search_rich_menu_type) {
                case ('now_one', 'now_not'):
                  ret.operate = this.$t('lbl.search_wrap.rich_menu_is', {
                    rich_menu: search.data.rich_menu.name,
                    type: rich_menu_type,
                  })
                default:
                  ret.operate = this.$t('lbl.search_wrap.rich_menu_type', {
                    type: rich_menu_type,
                  })
                  break
              }
              break
            }
            case 'answer_form': {
              ret.type = this.$t(yml)
              const answer_form_type = this.$$te(
                'search_answer_form.search_answer_form_type',
                search.data
              )
              const answer_form = search.data.answer_form.name
              ret.operate = `[${answer_form}] ${answer_form_type}`
              break
            }
          }
          return ret
        })
      })
    },
    selectedId() {
      if (this.activeTemplateClone.length === 0) {
        return ''
      }
      return this.activeTemplateClone[0].id
    },
    selects() {
      if (this.activeTemplateClone.length === 0) {
        return {}
      }
      return {
        [this.activeTemplateClone[0].name]: this.activeTemplateClone[0].id,
      }
    },
  },
  methods: {
    openDialog() {
      this.dialog = true
      this.clone = this.$$clone(this.internalValue)
    },
    cancel() {
      this.dialog = false
      this.clone = this.$$clone(this.internalValue)
    },
    openTemplateDialog() {
      this.templateDialog = true
      this.initTable(null)
    },
    async initTable(folderId) {
      this.loading = true
      const path = '/api/v1/search_templates'
      const prms = { folder_id: folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.search_templates
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async decide() {
      const isValid = await this.$refs.childObserver.validate()
      let error_msg = undefined
      let found = undefined
      if (isValid) {
        this.clone.search_arr.find((searchs) => {
          found = searchs.find((search) => {
            error_msg = validateSearchComponent(search)
            return error_msg !== undefined
          })
          return found !== undefined
        })
      } else {
        error_msg = this.$t('danger.params')
      }

      if (error_msg === undefined) {
        const { search_arr, ...clone } = this.clone
        this.internalValue = {
          ...clone,
          search_arr: this.clearDuplicateLastResponseSearch(search_arr),
        }
        this.$emit('pt-search-selected-list-save')
        this.dialog = false
      } else {
        this.$toasted.error(error_msg)
      }
    },
    clearDuplicateLastResponseSearch(searchArr) {
      return searchArr.map((groups) => {
        return this.removeDuplicateByType(groups)
      })
    },
    removeDuplicateByType(groups, targetType) {
      let searchTypes = ['friend_created_at', 'last_response']
      searchTypes.forEach((targetType) => {
        if (groups.filter((g) => g.type === targetType).length > 1) {
          const lastResponseIndexes = groups.reduce(
            (r, g, idx) => r.concat(g.type === targetType ? idx : []),
            []
          )
          lastResponseIndexes.pop()
          groups = groups.filter(
            (g, index) => !lastResponseIndexes.includes(index)
          )
        }
      })
      return groups
    },
    async decideTemplate() {
      const isValid = await this.$refs.searchTemplateObserver.validate()
      if (isValid) {
        this.internalValue =
          this.activeTemplateClone[0].search_wrap.as_json_form
        this.$emit('pt-search-selected-list-save')
        this.templateDialog = false
      } else {
        this.$toasted.error(this.$t('danger.params'))
      }
    },
    clearConfirm() {
      this.clearDialog = true
    },
    clearSearch() {
      this.internalValue.search_arr = [[]]
      this.$emit('pt-search-selected-list-clear')
      this.clearDialog = false
    },
    selectFolder(folderId) {
      this.initTable(folderId)
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
  },
}
</script>
