<template>
  <div>
    <PtTitle />
    {{ $t('subtitle.upload_file') }}
    <v-row>
      <v-col v-for="(val, key) in disps" :key="key">
        <v-btn @click="uploadDialog(key)">{{ $t(`btn.upload_${key}`) }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(val, key) in disps" :key="key">
        <VfSwitch
          :key="key"
          v-model="disps[key]"
          inset="true"
          :label="$t(`btn.disp_${key}`)"
        />
      </v-col>
    </v-row>
    <div>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-row no-gutters>
        <v-card
          v-for="uploadFile in uploadFiles"
          :key="uploadFile.id"
          :width="200"
          class="m-1"
        >
          <template v-if="uploadFile.image_url">
            <VImg :src="uploadFile.image_url" :height="200" />
          </template>
          <template v-if="uploadFile.rich_menu_image_url">
            <VImg :src="uploadFile.rich_menu_image_url" :height="200" />
          </template>
          <template v-else-if="uploadFile.video_url">
            <PtVideo
              :key="uploadFile.video_url"
              :src="uploadFile.video_url"
              :width="200"
              :height="200"
            />
          </template>
          <template v-else-if="uploadFile.audio_url">
            <PtVideo
              :key="uploadFile.audio_url"
              :src="uploadFile.audio_url"
              :width="200"
              :height="200"
            />
          </template>
          <template v-else-if="uploadFile.document_url">
            <div class="text-center item-document">
              <v-icon class="icon-document" color="gray"> mdi-file </v-icon>
              <div class="d-flex">
                <v-text-field
                  :value="`${uploadFile.document_url}`"
                  readonly
                  hide-details
                  class="mt-0 pt-0 pl-1 font-size-x-small"
                />
                <v-btn
                  v-clipboard:copy="`${uploadFile.document_url}`"
                  v-clipboard:success="onClipCopy"
                  v-clipboard:error="onClipError"
                  small
                  class="ma-1"
                >
                  <v-icon small> mdi-content-copy </v-icon>{{ $t('btn.copy') }}
                </v-btn>
              </div>
            </div>
          </template>
          <div class="d-flex justify-end">
            <PtFileInfo
              :name="uploadFile.file_name"
              :file-id="uploadFile.id"
              :file-url="fileUrl(uploadFile)"
            />
            <VCheckbox
              v-model="uploadFileIds"
              class="m-0"
              :value="uploadFile.id"
              :dense="true"
              hide-details
              :multiple="true"
            />
          </div>
        </v-card>
      </v-row>
      <v-row>
        <v-col align="right">
          <v-btn color="error" @click="openDeleteDialog">{{
            $t('btn.delete_checked_media')
          }}</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog :key="uploadFileType" v-model="dialog" :max-width="800">
      <v-card>
        <v-card-title>
          {{ $t(`btn.upload_${uploadFileType}`) }}
        </v-card-title>
        <v-card-text>
          <PtFilePondForUploadFile
            :name="uploadFileType"
            :cols="5"
            init-path="/api/v1/upload_files"
            :init-prms="{ upload_file_type: uploadFileType }"
            :max-files="null"
            :max-file-size="fileTypeOpts['max-file-size']"
            :accepted-file-types="fileTypeOpts['accepted-file-types']"
            :allow-image-preview="fileTypeOpts['allow-image-preview']"
            @upload-success="uploadSuccess"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" :max-width="500">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('confirm_title.delete') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="text-center or-break">
                  {{ $t('confirm.delete') }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="closeDeleteDialog">{{
            $t('btn.cancel')
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteAll">{{
            $t('btn.yes')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtVideo from 'components/parts/common/PtVideo'
import PtFileInfo from 'components/parts/common/PtFileInfo'
import PtFilePondForUploadFile from 'components/parts/common/PtFilePondForUploadFile'
export default {
  components: {
    PtVideo,
    PtFileInfo,
    PtFilePondForUploadFile,
  },
  data: () => ({
    loading: false,
    dialog: false,
    uploadFiles: [],
    disps: {},
    uploadFileType: '',
    uploadFileIds: [],
    deleteDialog: false,
  }),
  computed: {
    fileTypeOpts() {
      return this.$$uploadFileTypeOpts(this.uploadFileType)
    },
  },
  watch: {
    disps: {
      handler: function () {
        this.init()
      },
      deep: true,
    },
  },
  created() {
    this.disps = {
      image: 1,
      audio: 0,
      video: 0,
      rich_menu_image: 0,
      document: 0,
    }
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const path = '/api/v1/upload_files'
      const prms = this.disps
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.uploadFiles = res.data.upload_files
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    uploadDialog(key) {
      this.uploadFileType = key
      this.dialog = true
    },
    uploadSuccess() {
      this.$toasted.success(this.$t('success.create'))
      this.init()
    },
    openDeleteDialog() {
      this.deleteDialog = true
    },
    closeDeleteDialog() {
      this.deleteDialog = false
    },
    onClipCopy(e) {
      this.$toasted.success(e.text + 'をコピーしました')
    },
    onClipError() {
      this.$toasted.success('error')
    },
    fileUrl(uploadFile) {
      return (
        uploadFile.audio_url ||
        uploadFile.video_url ||
        uploadFile.document_url ||
        uploadFile.rich_menu_image_url ||
        uploadFile.image_url
      )
    },
    deleteAll() {
      this.deleteDialog = false
      this.loading = true
      const path = '/api/v1/upload_files/destroy_all'
      const prms = { ids: this.uploadFileIds }
      this.axios
        .post(path, prms)
        .then(() => {
          this.uploadFileIds = []
          this.init()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.item-document {
  height: 200px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
::v-deep .icon-document.v-icon.v-icon {
  font-size: 150px;
}
</style>
