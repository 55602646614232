<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-list-item>
        <v-list-item-content>
          {{ $t('account.user') }}
        </v-list-item-content>
        <v-list-item-action>
          <btn-dialog-user-edit :user="user" :vees="vees" @save="save" />
        </v-list-item-action>
      </v-list-item>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <tbody>
          <tr>
            <td>{{ $$tm('user.email') }}</td>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <td>{{ $$tm('user.name') }}</td>
            <td>{{ user.name }}</td>
          </tr>
          <tr>
            <td>{{ $$tm('user.phone') }}</td>
            <td>{{ user.phone }}</td>
          </tr>
          <tr>
            <td>{{ $t('lbl.created_at') }}</td>
            <td>{{ $$fat(user.confirmed_at) }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import BtnDialogUserEdit from './BtnDialogUserEdit'
export default {
  components: {
    BtnDialogUserEdit,
  },
  props: {
    user: { type: Object, required: true },
    vees: { type: Object, required: true },
  },
  methods: {
    async save(updateUser) {
      this.$emit('save', updateUser)
    },
  },
}
</script>
