<template>
  <div>
    <v-card class="mx-auto py-4" max-width="800">
      <v-card-title>
        {{ $t('account.password_edit') }}
      </v-card-title>
      <v-divider />
      <v-card-subtitle>
        お手数ですがセキュリティ維持のため、パスワード変更をお願い致します。
      </v-card-subtitle>
      <v-card-text>
        <vf-text-field v-model="item.password" label="パスワード" password />
        <vf-text-field
          v-model="item.password_confirmation"
          label="パスワード（確認）"
          password
        />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="passwordReset"> 変更 </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    item: {},
  }),
  methods: {
    passwordReset() {
      const url = '/api/v1/account/password_reset'
      // const prm = {password: this.password, password_confirmation: this.password_confirmation}
      this.axios
        .post(url, this.item)
        .then(() => {
          this.$toasted.success('success')
          this.password_dialog = false
          this.$router.push({ name: 'BaseHome' })
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$$glMsg('error', error.response.data)
          } else {
            this.$$ajaxError(error)
          }
        })
        .finally(() => {})
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
