import i18n from 'plugins/vue-i18n'
import store from 'store'
import moment from 'moment'
import { metaTags } from 'plugins/vue-meta'
import Autolinker from 'autolinker'
export default {
  props: {
    metaOpts: {},
  },
  methods: {
    $$reqPrms(val) {
      if (!val) {
        val = {}
      }
      const prms = { ...this.$route.params }
      delete prms.id
      return { ...this.$route.query, ...prms, ...val }
    },
    $$sendForm(type, path, prms = {}) {
      this.$$glMsg('', '')
      const hasFile = this.$$hasFile(prms)
      const formPrms = hasFile ? this.$$createPostData(prms) : prms
      const headers = hasFile ? { 'content-type': 'multipart/form-data' } : {}
      switch (type) {
        case 'post':
          return this.axios.post(path, formPrms, { headers })
        case 'put':
          return this.axios.put(path, formPrms, { headers })
        case 'delete':
          return this.axios.delete(path, { params: formPrms }, { headers })
        default:
          this.$toasted.error('formType error')
      }
    },
    $$createPostData(prms) {
      const fm = new FormData()
      const makeAppendData = function (fm, key, val) {
        const cls = Object.prototype.toString.call(val).slice(8, -1)
        if (cls !== 'Object') {
          return fm.append(key, val)
        }
        for (let [k, v] of Object.entries(val)) {
          makeAppendData(fm, `${key}[${k}]`, v)
        }
      }
      for (let [key, val] of Object.entries(prms)) {
        makeAppendData(fm, key, val)
      }
      return fm
    },
    $$hasFile(prms) {
      let hasFileFlg = false
      const checkHasFile = function (val) {
        const cls = Object.prototype.toString.call(val).slice(8, -1)
        if (cls === 'File') {
          hasFileFlg = true
          return
        }
        if (cls !== 'Object') {
          return
        }
        for (let [k, v] of Object.entries(val)) {
          checkHasFile(v)
        }
      }
      for (let [k, v] of Object.entries(prms)) {
        checkHasFile(v)
      }
      return hasFileFlg
    },
    $$clone(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    $$loadingOverlay(flg) {
      store.dispatch('loadingOverlay', flg)
    },
    $$loadingOverlayContent(flg) {
      store.dispatch('loadingOverlayContent', flg)
    },
    $$glMsg(type, msg, isHtml = false, isAlertText = false) {
      store.dispatch('glMsg', {
        globalMessageType: type,
        globalMessage: msg,
        isHtml,
        isAlertText,
      })
      if (msg) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    $$glMsgAdd(msg) {
      store.dispatch('glMsgAdd', msg)
      if (msg) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },
    $$updateUnreadCount(value) {
      store.dispatch('updateUnreadCount', value)
    },
    $$tm(model_column) {
      return i18n.tc(`activerecord.attributes.${model_column}`)
    },
    $$te(model_column, item) {
      const column = model_column.split('.')[1]
      return i18n.tc(`enum.${model_column}.${item[column]}`)
    },
    $$moment(val) {
      if (!val) {
        return null
      }
      moment.locale(i18n.locale)
      let temp = val
      if (typeof temp === 'string') {
        temp = temp.replace(/\//g, '-')
      }
      let ret = moment(temp)
      if (!ret.isValid()) {
        return null
      }
      return ret
    },
    $$fdt(val, fmt = 'L') {
      if (!val) {
        return null
      }
      moment.locale(i18n.locale)
      let temp = val
      if (typeof temp === 'string') {
        temp = temp.replace(/\//g, '-')
      }
      let ret = moment(temp)
      if (!ret.isValid()) {
        return null
      }
      return ret.format(fmt)
    },
    $$ftm(val, fmt = 'LT') {
      return this.$$fdt(val, fmt)
    },
    $$fat(val, fmt = 'LLL') {
      return this.$$fdt(val, fmt)
    },
    $$flg(val) {
      return val ? 'ON' : 'OFF'
    },
    $$fcm(val) {
      if (!val) {
        return null
      }
      let ret = Number(val)
      if (isNaN(ret)) {
        return null
      }
      return ret.toLocaleString(i18n.locale, { maximumFractionDigits: 20 })
    },
    $$tranc(text, len, clamp) {
      if (!text) {
        return text
      }
      len = len || 30
      clamp = clamp || '...'
      return text.length > len ? text.slice(0, len) + clamp : text
    },
    $$metaTags(val) {
      return metaTags(val)
    },
    $$0pad(num, len) {
      var ret = ''
      for (var i = 0; i < len; i++) {
        ret += '0'
      }
      return (ret + num).slice(-len)
    },
    $$ajaxError(error, dialog = false) {
      if (!dialog) {
        this.$toasted.show('error')
      }
      if (!error.response) {
        this.$$glMsg('error', error)
        console.log(error)
        return
      }
      let errorMsg = `error code: ${error.response.status}`
      switch (error.response.status) {
        case 401:
          errorMsg = i18n.tc('danger.login')
          store.dispatch('logout')
          break
        case 403:
          errorMsg = i18n.tc('danger.forbidden')
          this.$store.dispatch('logout')
          this.$router.push({ name: 'Login' })
          this.axios
            .delete('/auth/sign_out')
            .then(() => {})
            .catch(() => {})
          break
        case 404:
          errorMsg += '\n' + i18n.tc('danger.notfound.common')
          errorMsg += '\n' + i18n.tc('danger.syntax')
          break
        case 422:
          errorMsg = error.response.data
          break
        case 500:
          errorMsg += '\n' + error.response.statusText
          errorMsg += '\n' + i18n.tc('danger.syntax')
          errorMsg += '\n' + error.response.data // todo remove for production
          break
        default:
          errorMsg += '\n' + error.response.statusText
          errorMsg += '\n' + i18n.tc('danger.syntax')
          errorMsg += '\n' + error.response.data // todo remove for production
      }
      if (!dialog) {
        this.$$glMsg('error', errorMsg)
      }
      return errorMsg
    },
    $$noimageHelper(url) {
      if (url) {
        return url
      }
      return '/orgns/noimage.png'
    },
    $$uploadFileTypeOpts(uploadFileType) {
      const ret = {}
      switch (uploadFileType) {
        case 'image':
          ret['md'] = '2'
          ret['max-file-size'] = '10MB'
          ret['accepted-file-types'] = 'image/jpg, image/jpeg, image/png'
          ret['allow-image-preview'] = true
          break
        case 'video':
          ret['md'] = '4'
          ret['max-file-size'] = '100MB'
          ret['accepted-file-types'] =
            'video/mpeg, video/mp4, video/x-ms-wmv, video/quicktime'
          ret['allow-image-preview'] = false
          break
        case 'audio':
          ret['md'] = '6'
          ret['max-file-size'] = '100MB'
          ret['accepted-file-types'] =
            'audio/x-m4a, audio/mpeg, audio/x-wav, audio/aac, audio/ogg'
          ret['allow-image-preview'] = false
          break
        case 'rich_menu_image':
          ret['md'] = '2'
          ret['max-file-size'] = '10MB'
          ret['accepted-file-types'] = 'image/jpg, image/jpeg, image/png'
          ret['allow-image-preview'] = true
          break
        case 'document':
          ret['md'] = '2'
          ret['max-file-size'] = '10MB'
          ret['accepted-file-types'] = 'application/pdf'
          ret['allow-image-preview'] = false
          break
      }
      return ret
    },
    // { state: , basicId: , liffId: , clientId: }
    $$getLiffLineMeUrl(data) {
      const options = {
        state: data.state,
        basicId: data.line_account_id,
        liffId: data.inflow_liff_id,
        clientId: data.line_login_channel_id,
      }
      if (data.scenario) options.scenario = 'openapp'

      const query = new URLSearchParams(options)
      return `https://liff.line.me/${data.inflow_liff_id}?${query.toString()}`
    },
    $$csv_deli(deli) {
      switch (deli.deli_type) {
        case 'text':
          return deli.deli_text.text
            .replaceAll('\n', '\\n')
            .replaceAll('"', '\\"')
        case 'sticker':
          return `https://stickershop.line-scdn.net/stickershop/v1/sticker/${deli.deli_sticker.sticker_id}/iPhone/sticker_key@2x.png`
        case 'image':
          return deli.deli_image.preview_url
        case 'video':
          return deli.deli_video.video_url
        case 'audio':
          return deli.deli_audio.audio_url
        case 'question':
          return deli.deli_question.title
        case 'carousel':
          return deli.deli_carousel.deli_carousel_panels
            .map(function (p) {
              return p.title
            })
            .join(';')
        case 'location':
          return deli.deli_location.address
        case 'introduction':
          return deli.deli_introduction.title
        case 'file':
          return deli.deli_file.file_url
        default:
          return ''
      }
    },
  },
  filters: {},
}
