<template>
  <div>
    <v-card class="mx-auto px-12 py-6" max-width="800">
      <v-card-title>
        <v-row justify="center">
          <div>{{ $t(`meta.title.${$route.name}`) }}</div>
        </v-row>
      </v-card-title>
      <VDivider />
      <v-card-text>
        <v-row justify="center" class="text-center my-8">
          <div class="or-break">{{ $t('lbl.click_redirect_desc') }}</div>
        </v-row>
        <v-row justify="center">
          <v-btn @click="init">{{ $t('btn.click_redirect') }}</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    path: { type: String, default: '' },
    params: { type: Object, default: () => ({}) },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      await this.axios
        .post(this.path, this.params)
        .then((res) => {
          window.location.href = res.data.redirect_url
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
  },
}
</script>
