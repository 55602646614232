<template>
  <div>
    <v-row>
      <v-col cols="10">
        <PtTitle />
      </v-col>
      <v-col cols="2" align="right">
        <v-btn
          v-if="crossAnalysisId !== 'new'"
          class="mt-5"
          color="error"
          @click="destroy"
        >
          {{ $t('btn.delete') }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- 基本情報 -->
    <v-card class="mb-6">
      <v-card-title class="or-card-title mb-6">
        {{ $t('lbl.cross_analysis.info') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <VfSelect
              v-model="crossAnalysis.folder_id"
              :label="$$tm('folder.name')"
              :vees="vees.folder_id"
              :selects="folders"
            />
          </v-col>
          <v-col cols="12" md="6">
            <VfTextField
              v-model="crossAnalysis.name"
              :label="$$tm('cross_analysis.name')"
              :vees="vees.name"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- 評価対象 -->
    <v-card class="mb-6">
      <div class="or-card-title mb-6">
        <v-card-title>
          {{ $t('lbl.cross_analysis.search') }}
        </v-card-title>
      </div>
      <v-card-text>
        <PtSearchSelectedList v-model="crossAnalysis.target_search_wrap" />
      </v-card-text>
    </v-card>
    <!-- 評価軸（縦軸） -->
    <v-card class="mb-6">
      <div class="or-card-title mb-6">
        <v-card-title>
          {{ $t('lbl.cross_analysis.vertical') }}
        </v-card-title>
        <v-card-text>
          <VfRadioButton
            v-if="shows.crossAnalysisType"
            v-model="crossAnalysis.cross_analysis_type"
            :label="$$tm('cross_analysis.cross_analysis_type')"
            :vees="vees.cross_analysis_type"
            :selects="opts.cross_analysis_type"
            label-hidden
          />
          <v-row v-if="shows.hashtagType">
            <v-col cols="12" md="6" class="py-0" @click="openDialog">
              <VfSelectChip
                v-model="hashtagIds"
                :label="$$tm('hashtag.name')"
                :selects="selects"
                :vees="{ rules: 'required' }"
                readonly
              />
            </v-col>
          </v-row>
          <v-row v-if="shows.createAtType">
            <v-col>
              <table>
                <tr>
                  <td>
                    <div>
                      <VfDateTimeSelect
                        v-model="verticalCreatedAt.st_dt"
                        label="日付選択（起点）"
                        format="YYYY/MM/DD"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="mx-2">〜</div>
                  </td>
                  <td>
                    <div>
                      <VfDateTimeSelect
                        v-model="verticalCreatedAt.ed_dt"
                        label="日付選択（終点）"
                        format="YYYY/MM/DD"
                      />
                    </div>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
    <!-- 評価軸（横軸） -->
    <v-card class="mb-6">
      <div class="or-card-title mb-6">
        <v-card-title>
          {{ $t('lbl.cross_analysis.horizontal') }}
        </v-card-title>
      </div>
      <v-card-text>
        <v-card
          v-for="(
            cross_analysis_horizon, cross_analysis_horizon_i
          ) in crossAnalysisHorizons"
          :key="cross_analysis_horizon_i"
          class="mb-4"
        >
          <v-card-title class="pa-0">
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  v-model="cross_analysis_horizon.name"
                  label="横軸の名前"
                />
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  class="mt-4"
                  :disabled="crossAnalysisHorizons.length <= 1"
                  @click="removeHorizon(cross_analysis_horizon_i)"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-title>
          <v-card-text>
            <PtSearchSelectedList
              v-model="cross_analysis_horizon.target_search_wrap"
            />
          </v-card-text>
        </v-card>
        <v-btn color="primary" @click="addHorizon">
          <v-icon left>mdi-plus-circle-outline</v-icon>
          {{ $t('lbl.cross_analysis.add_horizontal') }}
        </v-btn>
      </v-card-text>
    </v-card>
    <!-- オプション -->
    <v-card class="mb-6">
      <div class="or-card-title mb-6">
        <v-card-title>
          {{ $t('lbl.cross_analysis.option') }}
        </v-card-title>
        <v-card-text>
          <table>
            <tr>
              <td v-for="(option, option_idx) in OPTIONS" :key="option_idx">
                <div class="mr-6">
                  <VfCheckbox
                    v-model="crossAnalysis[option]"
                    :label="$$tm(`cross_analysis.${option}`)"
                  />
                </div>
              </td>
            </tr>
          </table>
        </v-card-text>
      </div>
    </v-card>
    <v-row>
      <v-col cols="12" md="8">
        {{ $t('lbl.cross_analysis.wait_a_moment') }}
      </v-col>
      <v-col cols="12" md="4" align="right">
        <v-btn @click="$router.push({ name: 'CrossAnalysisShow' })">{{
          $t('btn.back')
        }}</v-btn>
        <v-btn
          color="primary"
          :loading="$wait.is('processing')"
          @click="save"
          >{{ $t('btn.create') }}</v-btn
        >
      </v-col>
    </v-row>
    <VfDialogConfirm
      v-model="deleteDialog"
      :title="$t('confirm_title.delete')"
      :detail="$t('confirm.delete')"
      :save-path="savePath"
      form-type="delete"
      target-emit="delete-success"
      @delete-success="deleteSuccess"
    />
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <v-card>
                <v-card-text>
                  <VfSelectChip
                    v-model="hashtagIds"
                    :label="$$tm('hashtag.name')"
                    :selects="selects"
                    readonly
                    icon
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="4">
              <PtFolder
                ref="folder"
                folder-type="hashtag"
                select-only
                @select-folder="selectFolder"
              />
            </v-col>
            <v-col md="8">
              <v-data-table
                v-model="verticalHashtags"
                :headers="headers"
                :items="items"
                item-key="id"
                show-select
                :single-select="false"
                :disable-sort="true"
                :items-per-page="50"
                :footer-props="{
                  itemsPerPageOptions: [50, 100, 150],
                }"
                @click:row="rowClick"
              >
                <template #[`item.action_group`]="{ item }">
                  <PtActionLabelMessages
                    v-model="item.action_group.make_action_arr"
                    class="or-list"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtSearchSelectedList from 'components/parts/common/PtSearchWrap/PtSearchSelectedList'
import PtFolder from 'components/parts/common/PtFolder'
import PtActionLabelMessages from 'components/parts/common/PtActionGroup/PtActionLabelMessages'
export default {
  components: {
    PtSearchSelectedList,
    PtFolder,
    PtActionLabelMessages,
  },
  data: () => ({
    dialog: false,
    crossAnalysisId: '',
    crossAnalysis: {},
    vees: {},
    folders: [],
    opts: {},
    savePath: '',
    deleteDialog: false,
    headers: [],
    items: [],
    crossAnalysisHorizon: {},
    crossAnalysisHorizons: [],
    verticalHashtags: [],
    verticalCreatedAt: { st_dt: '', ed_dt: '' },
  }),
  computed: {
    OPTIONS() {
      return [
        'no_use_all_flg',
        'disp_blocked_user_flg',
        'remove_zero_record_flg',
      ]
    },
    selects() {
      const ret = {}
      this.verticalHashtags.forEach((item) => (ret[item.name] = item.id))
      return ret
    },
    shows() {
      const keySize = function (obj) {
        return Object.keys(obj).length
      }
      return {
        crossAnalysisType:
          this.opts.cross_analysis_type &&
          keySize(this.opts.cross_analysis_type) > 1,
        hashtagType: this.crossAnalysis.cross_analysis_type === 'hashtag',
        createAtType: this.crossAnalysis.cross_analysis_type === 'created_at',
      }
    },
    hashtagIds: {
      get() {
        return this.verticalHashtags.map((row) => row.id)
      },
      set(arr) {
        const ret = []
        arr.forEach((id) =>
          ret.push(this.verticalHashtags.find((row) => row.id === id))
        )
        this.verticalHashtags = ret
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$$loadingOverlayContent(true)
      this.crossAnalysisId = this.$route.params.id
      const path =
        this.crossAnalysisId === 'new'
          ? '/api/v1/cross_analyses/new'
          : `/api/v1/cross_analyses/${this.crossAnalysisId}/edit`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.crossAnalysis = res.data.cross_analysis
          this.crossAnalysisHorizons = res.data.cross_analysis_horizons
          this.crossAnalysisHorizon = res.data.default_cross_analysis_horizon
          if (res.data.vertical_created_at) {
            this.verticalCreatedAt = res.data.vertical_created_at
          }
          if (res.data.vertical_hashtags) {
            this.verticalHashtags = res.data.vertical_hashtags
          }
          this.vees = res.data.vees
          this.folders = res.data.folders
          this.opts = res.data.opts
          const crossAnalysisTypeValues = Object.values(
            this.opts.cross_analysis_type
          )
          if (!crossAnalysisTypeValues.includes('hashtag')) {
            this.crossAnalysis.cross_analysis_type = 'created_at'
          }
          if (this.crossAnalysisHorizons.length === 0) {
            this.addHorizon()
          }
          if (!this.verticalCreatedAt.st_dt) {
            this.verticalCreatedAt.st_dt = new Date()
            this.verticalCreatedAt.ed_dt = new Date()
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    async save() {
      if (this.$wait.is('processing')) {
        return
      }
      this.$wait.start('processing')
      this.$$loadingOverlayContent(true)
      const path =
        this.crossAnalysisId === 'new'
          ? '/api/v1/cross_analyses'
          : `/api/v1/cross_analyses/${this.crossAnalysisId}`
      const method = this.crossAnalysisId === 'new' ? 'post' : 'put'
      const prms = {
        cross_analysis: this.crossAnalysis,
        cross_analysis_horizons: this.crossAnalysisHorizons,
        vertical_hashtags: this.verticalHashtags,
        vertical_created_at: this.verticalCreatedAt,
      }

      let successFlg = false
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then((res) => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({
            name: 'CrossAnalysisShow',
            params: { id: res.data.cross_analysis.id },
          })
          successFlg = true
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$wait.end('processing')
          if (!successFlg) {
            this.$$loadingOverlayContent(false)
          }
        })
    },
    destroy() {
      this.savePath = `/api/v1/cross_analyses/${this.crossAnalysisId}`
      this.deleteDialog = true
    },
    deleteSuccess() {
      this.$toasted.success(this.$t('success.delete'))
      this.$router.push({ name: 'CrossAnalysisIndex' })
    },
    openDialog() {
      this.dialog = true
      this.initTable(null)
    },
    async initTable(folderId) {
      this.loading = true
      const path = '/api/v1/hashtags/action_group'
      const prms = { folder_id: folderId }
      await this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.hashtags
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    selectFolder(folderId) {
      this.initTable(folderId)
    },
    rowClick(item, opts) {
      opts.select(!opts.isSelected)
    },
    addHorizon() {
      this.crossAnalysisHorizons.push(this.$$clone(this.crossAnalysisHorizon))
    },
    removeHorizon(index) {
      this.crossAnalysisHorizons.splice(index, 1)
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
