<template>
  <div>
    <v-row>
      <v-col>
        <PtTitle :title="friend.name" />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="pt-0">
        <PtFriendShowBase v-model="friend" :opts="opts" :vees="vees" readonly />
      </v-col>
      <v-col md="6" class="pt-0">
        <PtAdminFriendShowAction v-model="friend" readonly />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtFriendShowBase from 'components/parts/unique/PtFriendShowBase'
import PtAdminFriendShowAction from 'components/parts/unique/PtAdminFriendShowAction'
export default {
  components: {
    PtFriendShowBase,
    PtAdminFriendShowAction,
  },
  data: () => ({
    friend: {},
    vees: {},
    opts: {},
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$store.dispatch('selectChannelRoles', [])
      this.$$loadingOverlayContent(true)
      const friendId = this.$route.params.id
      const path = `/api/v1/admin/friends/${friendId}`
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.friend = res.data.friend
          this.vees = res.data.vees
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
