<template>
  <div>
    <v-row class="mt-3">
      <v-col md="9">
        <v-card>
          <v-card-title>
            <span style="font-size: 14px">
              <b style="color: red">{{
                $t('lbl.answer_form.list_of_respondents_not_1')
              }}</b>
            </span>
            <span class="pl-2" style="font-size: 14px">{{
              $t('lbl.answer_form.list_of_respondents_not_2')
            }}</span>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col md="3">
        <v-btn class="blue lighten-2 color-white" @click="totalAnsweredFriends">
          {{ $t('btn.view_friends_list') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <table class="table .table-hover">
          <thead>
            <tr>
              <th>対応マーク</th>
              <th>名前</th>
              <th />
              <th
                :class="
                  !formSetting.response_limit_per_person ? 'opacity-blur' : ''
                "
              >
                再回答
              </th>
              <th
                :class="
                  !formSetting.response_limit_per_person ? 'opacity-blur' : ''
                "
              >
                <VfCheckbox
                  v-model="selectAllAccept"
                  label="再回答可にする"
                  hide-details
                />
              </th>
              <th
                :class="
                  !formSetting.response_limit_per_person ? 'opacity-blur' : ''
                "
              >
                <VfCheckbox
                  v-model="selectAllDenied"
                  label="再回答不可にする"
                  hide-details
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(friend, friend_i) in listFriend" :key="friend_i">
              <td>
                <v-chip dark label :color="colors[friend.message_status]">
                  {{ $$te('friend.message_status', friend) }}
                </v-chip>
              </td>
              <td>
                <a
                  :href="
                    $router.resolve({
                      name: 'FriendShow',
                      params: { id: friend.id },
                    }).href
                  "
                >
                  {{ friend.name }}
                </a>
              </td>
              <td>
                <a
                  class=""
                  :href="
                    $router.resolve({
                      name: 'ListAnswerResponseMember',
                      params: { friend_id: friend.id },
                    }).href
                  "
                >
                  <v-btn color="primary">回答表示</v-btn>
                </a>
              </td>
              <template
                v-if="
                  Array.isArray(limitExceptionFriends) &&
                  !limitExceptionFriends.includes(friend.id)
                "
              >
                <td
                  :class="
                    !formSetting.response_limit_per_person ? 'opacity-blur' : ''
                  "
                >
                  再回答不可
                </td>
                <td
                  :class="
                    !formSetting.response_limit_per_person ? 'opacity-blur' : ''
                  "
                >
                  <VfCheckbox
                    v-model="selectedAccept"
                    label=""
                    :checkbox-value="friend.id"
                  />
                </td>
                <td />
              </template>
              <template v-else>
                <td
                  class="red--text"
                  :class="
                    !formSetting.response_limit_per_person ? 'opacity-blur' : ''
                  "
                >
                  再回答可
                </td>
                <td />
                <td
                  :class="
                    !formSetting.response_limit_per_person ? 'opacity-blur' : ''
                  "
                >
                  <VfCheckbox
                    v-model="selectedDenied"
                    label=""
                    :checkbox-value="friend.id"
                  />
                </td>
              </template>
            </tr>
            <tr
              v-if="
                answerData &&
                answerData.hasOwnProperty('total_anonymous_answer')
              "
            >
              <td />
              <td />
              <td class="text-right">
                {{ `匿名(${answerData.total_anonymous_answer}名)` }}
              </td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" offset-md="6" class="text-right">
        <v-btn
          color="primary"
          :class="!formSetting.response_limit_per_person ? 'opacity-blur' : ''"
          @click="exceptFriend"
        >
          チェックしたメンバーの再回答を変更する
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'ShowTabFriendAnswer',
  props: {
    answerData: { default: null },
    colors: { default: null },
  },
  data: function () {
    return {
      listFriend: [],
      answer_form: {},
      answerFormId: '',
      formSetting: {},
      loading: false,
      selectedAccept: [],
      limitExceptionFriends: [],
      selectedDenied: [],
    }
  },
  computed: {
    selectAllAccept: {
      get: function () {
        if (this.friendIdsLimited.length === 0) {
          return false
        }
        return this.friendIdsLimited
          ? this.selectedAccept.length === this.friendIdsLimited.length
          : false
      },
      set: function (value) {
        let selected = []
        if (value) {
          this.friendIdsLimited.forEach(function (id) {
            selected.push(id)
          })
        }
        this.selectedAccept = selected
      },
    },
    selectAllDenied: {
      get: function () {
        if (this.limitExceptionFriends.length === 0) {
          return false
        }
        return this.limitExceptionFriends
          ? this.selectedDenied.length === this.limitExceptionFriends.length
          : false
      },
      set: function (value) {
        let selected = []
        if (value) {
          this.limitExceptionFriends.forEach(function (id) {
            selected.push(id)
          })
        }
        this.selectedDenied = selected
      },
    },
    friendIdsLimited() {
      let ids = []
      this.listFriend.forEach((friend) => {
        if (!this.limitExceptionFriends.includes(friend.id)) {
          ids.push(friend.id)
        }
      })
      return ids
    },
  },
  created() {
    this.answerFormId = this.$route.params.id
    this.loadListFriend()
  },
  methods: {
    async loadListFriend() {
      this.loading = true
      const path = '/api/v1/answer_form_responses/answered_friends'
      await this.axios
        .get(path, {
          params: { answer_form_id: this.$route.params.id },
        })
        .then((res) => {
          this.listFriend = res.data.answer_form_response.friends
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
      await this.axios
        .get(`/api/v1/answer_forms/${this.$route.params.id}/edit`, {
          params: this.$$reqPrms(),
        })
        .then((res) => {
          this.answer_form = res.data.answer_form
          this.formSetting = res.data.answer_form.answer_form_setting_attributes
          this.limitExceptionFriends =
            res.data.answer_form.limit_exception_friends || []
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
          this.loading = false
        })
    },
    async exceptFriend() {
      if (
        this.selectedAccept.length === 0 &&
        this.selectedDenied.length === 0
      ) {
        this.$$glMsg('error', 'エラー\n' + '\n' + '最低一人を指定して下さい。')
        return
      }
      const path =
        this.answerFormId === 'new'
          ? '/api/v1/answer_forms'
          : `/api/v1/answer_forms/${this.answerFormId}`
      const prms = {
        answer_form: {
          limit_exception_friends: this.makeListUnLimited(),
        },
      }
      await this.$$sendForm('put', path, this.$$reqPrms(prms))
        .then(() => {
          this.$router
            .push({
              name: 'AnswerFormShow',
              params: { id: this.answerFormId },
              query: { tab: '2' },
            })
            .catch(() => {})
          this.$router.go()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    totalAnsweredFriends() {
      this.$router.push({
        name: 'FriendIndex',
        query: { search_wrap_id: this.answer_form.search_wrap_id },
      })
    },
    makeListUnLimited() {
      return [...this.limitExceptionFriends, ...this.selectedAccept].filter(
        (id) => {
          return !this.selectedDenied.includes(id)
        }
      )
    },
  },
}
</script>
<style scoped lang="scss">
.color-white {
  color: #fff !important;
}
.opacity-blur {
  opacity: 0.6;
}
</style>
