<template>
  <div>
    <PtTitle />
    <div class="mb-2">
      <div>{{ $t('subtitle.staff_create') }}</div>
      <router-link :to="{ name: 'ChannelRoleForm', params: { id: 'new' } }">
        {{ $t('btn.channel_role_link') }}
      </router-link>
    </div>
    <validation-observer ref="observer">
      <v-form @submit.prevent="save">
        <v-card max-width="800">
          <VfLoadingOverlayAbsolute :loading="loading" />
          <v-card-title>{{ $t('lbl.staff.entry') }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <vf-text-field
                  v-model="user.email"
                  :label="$$tm('user.email')"
                  :vees="{ rules: 'required' }"
                />
              </v-col>
              <v-col cols="12">
                <vf-text-field
                  v-model="user.name"
                  :label="$$tm('user.name')"
                  :vees="{ rules: 'required' }"
                />
              </v-col>
              <v-col>
                <VfSelect
                  v-model="channelRoleId"
                  :label="$$tm('user_channel.channel_role_id')"
                  :selects="selectChannelRoles"
                  :vees="{ rules: 'required' }"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="toIndex">{{ $t('btn.back') }}</v-btn>
            <v-btn type="submit" color="primary">{{ $t('btn.create') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    user: {},
    channelRoleId: '',
    selectChannelRoles: [],
  }),
  created() {
    this.getSelectChannelRoles()
  },
  methods: {
    getSelectChannelRoles() {
      const path = '/api/v1/channel_roles/select'
      this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.selectChannelRoles = res.data.select_channel_roles
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
    },
    async save() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      this.loading = true
      const url = '/api/v1/staffs'
      const params = {
        user: this.user,
        channel_role_id: this.channelRoleId,
      }
      this.axios
        .post(url, params)
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.push({ name: 'StaffIndex' })
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    toIndex() {
      this.$router.push({ name: 'StaffIndex' })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
