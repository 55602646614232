<template>
  <div>
    <v-card v-if="initDone && !isExpired" class="mx-auto py-4" max-width="800">
      <v-form @submit.prevent="submitForm">
        <v-card-title>
          <v-row justify="center">
            <div>{{ $t(`meta.title.${$route.name}`) }}</div>
          </v-row>
        </v-card-title>
        <v-divider class="mx-4" />
        <v-card
          v-if="$route.query.is_user_confirmed === '1'"
          color="grey lighten-4"
          class="ma-8"
        >
          <v-card-text>
            <v-row justify="center">
              <div class="or-break">{{ $t('lbl.email_done') }}</div>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card-text class="text-h5 font-weight-bold">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <vf-text-field
                v-model="item.password"
                label="パスワード"
                password
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <vf-text-field
                v-model="item.password_confirmation"
                label="パスワード確認"
                password
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn type="submit" color="primary" :disabled="submiting"
            >送信</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
    <div v-else-if="initDone && isExpired" class="container">
      <div class="row answer-form">
        <div
          class="col-sm-10 offset-sm-1 col-md-6 offset-md-3 col-lg-6 offset-lg-3 p-0 form-box"
        >
          <v-row :style="bg_header" class="form-header">
            <v-col cols="9">
              <p>{{ $t('lbl.expired') }}</p>
              <p>ページを閉じて下さい。</p>
            </v-col>
            <v-col cols="3" sm="2" xs="3" md="2" class="icon-header">
              <v-icon size="79" color="#ddd">mdi-block-helper</v-icon>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    item: {
      password: '',
      password_confirmation: '',
      token: '',
    },
    submiting: false,
    isExpired: false,
    initDone: false,
    loading: false,
  }),
  created: function () {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      const path = '/api/v1/account/is_expired'
      const prms = { token: this.$route.query.token }
      this.axios
        .get(path, { params: this.$$reqPrms(prms) })
        .then((res) => {
          this.isExpired = res.data.is_expired
          this.initDone = true
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    submitForm() {
      this.submiting = true
      const url = '/api/v1/account/password_reset'
      this.item.token = this.$route.query.token
      console.log(this.$route.query.token)
      this.axios
        .post(url, this.item)
        .then(() => {
          this.$toasted.success('パスワードを変更しました')
          this.$router.push({ name: 'Login' })
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$$glMsg('error', this.$t('danger.login_miss'))
          } else {
            this.$$ajaxError(error)
          }
          this.$toasted.show('error')
        })
        .finally(() => {
          this.submiting = false
        })
    },
    onVerify: function (response) {
      if (response) this.form.robot = true
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped>
.icon-header {
  font-size: 50px;
  color: #ddd;
}
.form-header {
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px 25px;
}
.form-box {
  -webkit-box-shadow: 8px 5px 8px 5px rgb(0 0 0 / 10%);
  box-shadow: 8px 5px 8px 5px rgb(0 0 0 / 10%);
}
</style>
