<template>
  <div>
    <v-card outlined class="transparent">
      <v-card-title class="pl-0 pa-0 or-card-title">
        <v-list-item class="pa-0">
          <v-list-item-content>
            {{ $t('lbl.friend_transition') }}
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              small
              class="or-card-title"
              @click="$router.push({ name: 'FriendReportIndex' })"
            >
              さらに詳しく
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <VfAlert v-model="errorMsg" type="error" />
      <v-data-table
        :headers="headers"
        :items="items"
        :disable-sort="true"
        hide-default-footer
      >
        <template #[`item.block_count`]="{ item }">
          <router-link
            :to="{
              name: 'FriendIndex',
              params: { blocked_at: item.report_dt },
            }"
          >
            {{ item.block_count }}
          </router-link>
        </template>
        <template #[`item.report_dt`]="{ item }">
          {{ $$fdt(item.report_dt) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    errorMsg: '',
    headers: [],
    items: [],
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.errorMsg = ''
      const path = '/api/v1/friend_reports/week'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.friend_reports
        })
        .catch((error) => {
          this.errorMsg = this.$$ajaxError(error, true)
        })
      this.loading = false
    },
  },
}
</script>
