<template>
  <div>
    <v-card>
      <VfLoadingOverlayAbsolute :loading="$wait.is('processing')" />
      <v-card-title class="pa-0">
        <v-list-item>
          <v-list-item-content>
            {{ $t('lbl.friend.rich_menu') }}
          </v-list-item-content>
          <v-list-item-action v-if="hasRole">
            <v-wait for="processing">
              <PtDialogSelect
                v-model="richMenu"
                select-type="rich_menu"
                :single-select="true"
                @decide="save"
              >
                <template #opener>
                  <v-btn icon outlined>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <template #[`item.upload_file_id`]="{ item }">
                  <VImg width="120" :src="item.upload_file.preview_image.url" />
                </template>
              </PtDialogSelect>
            </v-wait>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <VDivider class="my-0" />
      <v-card-text>
        <v-chip v-if="richMenu.rich_menu.id" class="ma-2">
          {{ richMenu.rich_menu.name }}
        </v-chip>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import PtDialogSelect from 'components/parts/common/PtDialogSelect'
export default {
  components: {
    PtDialogSelect,
  },
  props: {
    value: { type: Object, required: true },
    friendId: { type: String, required: true },
  },
  data: () => ({
    richMenu: {
      rich_menu: { id: '', name: '' },
    },
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    hasRole() {
      return this.$store.getters.roles.includes('friend_rich_menu')
    },
  },
  created() {
    this.richMenu.rich_menu = this.$$clone(this.internalValue)
    this.richMenu.rich_menu_id = this.internalValue.id
  },
  methods: {
    async save() {
      this.$wait.start('processing')
      const path = `/api/v1/friends/${this.friendId}/update_rich_menu`
      const prms = { rich_menu: this.richMenu.rich_menu }
      await this.$$sendForm('post', path, this.$$reqPrms(prms))
        .then((res) => {
          if (res.data.rich_menu) {
            Object.assign(this.internalValue, res.data.rich_menu)
          } else {
            Object.assign(this.internalValue, { id: '', name: '' })
          }
          this.$toasted.success(this.$t('success.update'))
          this.dialog = false
        })
        .catch(() => {
          this.$toasted.error(this.$t('danger.error_occurred'))
        })
        .finally(() => {
          this.$wait.end('processing')
        })
    },
  },
}
</script>
