<template>
  <div>
    <PtTitle />
    <v-card outlined>
      <VfLoadingOverlayAbsolute :loading="loading" />
      <v-data-table
        class="friend_index_table"
        :headers="headers"
        :items="items"
        item-key="id"
        hide-default-footer
        :disable-sort="true"
        :items-per-page="-1"
      >
        <template #[`item.name`]="{ item }">
          <router-link
            :to="{ name: 'FriendShow', params: { id: item.friend.id } }"
          >
            <v-avatar size="48" class="mr-2 mt-2 mb-2">
              <v-img
                small
                :src="$$noimageHelper(item.friend.api_picture_url)"
                :alt="item.friend.api_display_name"
              />
            </v-avatar>
            {{ item.friend.api_display_name }}
          </router-link>
        </template>
        <template #[`item.action`]="{ item }">
          <v-btn color="red white--text" @click="deleteTestAccount(item.id)"
            >登録削除</v-btn
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    opts: {},
    headers: [],
    items: [],
    selectedFriend: {},
    selectedFriendClone: {},
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.initTable(null)
    },
    async initTable() {
      this.loading = true
      const path = '/api/v1/test_friends'

      await this.axios
        .get(path)
        .then((res) => {
          this.headers = res.data.thead
          this.items = res.data.test_friends
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async deleteTestAccount(id) {
      this.loading = true
      const path = `/api/v1/test_friends/${id}`

      await this.$$sendForm('delete', path, {})
        .then(() => {
          this.$toasted.success('テストアカウントから削除しました')

          if (this.searchWrap) {
            this.ptSearchSelectedListSave()
          } else {
            this.initTable(null)
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
