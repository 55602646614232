var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PtTitle'),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.$t('subtitle.staff')))]),_vm._v(" "),_c('v-row',{staticClass:"mb-4"},[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("\n          "+_vm._s(_vm.$t('staff.info'))+"\n        ")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"to":{ name: 'StaffForm' }}},[_vm._v(_vm._s(_vm.$t('btn.staff_new')))])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"to":{ name: 'StaffAttach' }}},[_vm._v(_vm._s(_vm.$t('btn.staff_already')))])],1)],1)],1)],1)],1),_vm._v(" "),(_vm.hasRole)?_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("\n          "+_vm._s(_vm.$t('lbl.staff.channel_role_setting'))+"\n        ")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-btn',{attrs:{"to":{ name: 'ChannelRoleIndex' }}},[_vm._v(_vm._s(_vm.$t('btn.channel_role_index')))])],1)],1)],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('v-card',[_c('VfLoadingOverlayAbsolute',{attrs:{"loading":_vm.loading}}),_vm._v(" "),_c('v-card-title',[_vm._v("\n      "+_vm._s(_vm.$t('staff.list'))+"\n    ")]),_vm._v(" "),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"disable-sort":true,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.user.current_sign_in_at",fn:function(ref){
var item = ref.item;
return [(item.user.current_sign_in_at)?[_vm._v("\n            "+_vm._s(_vm.$$fat(item.user.current_sign_in_at))+"\n          ")]:[_vm._v("\n            "+_vm._s(item.user.unchanged_password)+"\n          ")]]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [(item.channel_role)?[_vm._v("\n            "+_vm._s(item.channel_role.name)+"\n          ")]:[_vm._v("\n            "+_vm._s(_vm.$$te('user_channel.role', item))+"\n          ")]]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.$$fat(item.created_at))+"\n        ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.role !== 'owner')?[(_vm.hasRole)?_c('btn-dialog-select-role',{attrs:{"select-channel-roles":_vm.selectChannelRoles},on:{"click":function($event){return _vm.openDialogSelectRole(item)},"save":_vm.saveRole},model:{value:(_vm.selectedChannelRoleId),callback:function ($$v) {_vm.selectedChannelRoleId=$$v},expression:"selectedChannelRoleId"}}):_vm._e(),_vm._v(" "),_c('btn-dialog-remove-from-channel',{on:{"click":function($event){return _vm.openDialogRemove(item)},"save":_vm.saveRemove}})]:_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }