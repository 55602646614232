import store from 'store'
import VueCookies from 'vue-cookies'

const OrgnSession = {
  install() {
    const cooks = (function () {
      const retArr = {}
      const tmpArr = document.cookie.split('; ')
      tmpArr.forEach((tmp) => {
        const row = tmp.split('=')
        if (row[0] && row[1]) {
          retArr[row[0]] = (function (str) {
            try {
              return JSON.parse(decodeURIComponent(str.replace(/\+/g, ' ')))
            } catch {
              return decodeURIComponent(str.replace(/\+/g, ' '))
            }
          })(row[1])
        }
      })
      return retArr
    })()
    const payload = {
      data: { data: cooks.user },
    }
    store.dispatch('login', payload)
    store.dispatch('selectChannel', cooks.channel)
    store.dispatch('selectChannelRoles', cooks.roles)
    VueCookies.remove('user')
    VueCookies.remove('channel')
    VueCookies.remove('roles')
  },
}

export default OrgnSession
