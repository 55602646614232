var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',{staticClass:"or-card-title mb-6"},[_vm._v("\n      "+_vm._s(_vm.$t('lbl.reminder.timing'))+"\n    ")]),_vm._v(" "),_c('VfLoadingOverlayAbsolute',{attrs:{"loading":_vm.loading}}),_vm._v(" "),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"md":"8"}},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-col',{staticClass:"text-right",attrs:{"md":"4"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.fremActiveFlg},on:{"click":_vm.newItem}},[_vm._v(_vm._s(_vm.$t('btn.new_item')))])],1)],1)],1),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","disable-sort":true,"footer-props":{
        itemsPerPageOptions: [30, 50, 100],
      }},scopedSlots:_vm._u([{key:"item.sch_day",fn:function(ref){
      var item = ref.item;
return [(item.reminder_timing_type === 'start')?[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("\n            "+_vm._s(_vm.$$te('reminder_timing.reminder_timing_type', item))+"\n          ")])]:[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(item.sch_day)+"日前 ")])]]}},{key:"item.sch_time",fn:function(ref){
      var item = ref.item;
return [(item.reminder_timing_type !== 'start')?[_vm._v("\n          "+_vm._s(_vm.$$ftm(item.sch_time))+"\n        ")]:_vm._e()]}},{key:"item.action_group",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('PtActionLabelMessages',{staticClass:"or-list",model:{value:(item.action_group.make_action_arr),callback:function ($$v) {_vm.$set(item.action_group, "make_action_arr", $$v)},expression:"item.action_group.make_action_arr"}})],1)]}},_vm._l((_vm.items),function(item){return {key:"item.preview",fn:function(ref){
      var item = ref.item;
return _vm._l((item.action_group.broadcasts),function(broadcast){return _c('div',[_c('v-btn',{staticClass:"my-1",attrs:{"depressed":"","x-small":""},on:{"click":function($event){return _vm.showItem(broadcast)}}},[_vm._v("プレビュー")])],1)})}}})],null,true)})],1),_vm._v(" "),_c('VfDialogConfirm',{attrs:{"title":_vm.$t('confirm_title.delete'),"detail":_vm.$t('confirm.delete'),"save-path":_vm.savePath,"form-type":"delete","target-emit":"delete-success"},on:{"delete-success":_vm.deleteSuccess},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}}),_vm._v(" "),_c('PtDialogLinePreview',{key:_vm.selectedItem.id,attrs:{"type":"broadcast","model-id":_vm.selectedItem.id},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }