<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-row>
      <v-col md="6" class="pb-0">
        <VfTextField
          v-model="internalValue.title"
          :label="$t('lbl.answer_form.heading')"
          :vees="vees.answer_form_input.title"
        />
      </v-col>
      <v-col md="6" class="pb-0">
        <VfCheckbox
          v-model="internalValue.hide_title_flg"
          :label="$t('lbl.answer_form.hide_item_name')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <VfSelect
          v-model="internalValue.additional_option_type"
          :label="$t('lbl.answer_form.image')"
          :selects="opts.answer_form_input.additional_option_types.subheading"
        />
      </v-col>
      <v-col md="4">
        <v-btn
          v-show="internalValue.additional_option_type === 'upload_file'"
          class="mt-3"
          @click="openDialog"
        >
          {{ $t('btn.select_image') }}
        </v-btn>
        <VfTextField
          v-show="internalValue.additional_option_type === 'external_url'"
          v-model="internalValue.heading_image"
          :label="''"
          @input="imageKey += 1"
        />
      </v-col>
      <v-col md="4">
        <v-card
          v-if="
            internalValue.heading_image &&
            internalValue.additional_option_type !== 'do_nothing'
          "
          class="mt-4"
          outlined
          tile
          :max-width="200"
        >
          <VImg :key="imageKey" :src="internalValue.heading_image" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <VfTextArea
          v-model="internalValue.description"
          :label="$t('lbl.answer_form.explanatory_text')"
          :vees="vees.answer_form_input.description"
          :hint="$t('lbl.answer_form.subheading_desc_hint')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2" class="offset-md-10 pb-0 pt-0">
        <VfCheckbox
          v-model="internalValue.hidden_flg"
          :label="$t('lbl.answer_form.hide')"
        />
      </v-col>
    </v-row>
    <PtDeliDialogForSelectFile
      v-model="dialog"
      upload-file-type="image"
      @select-file-done="selectFileDone"
    />
  </div>
</template>
<script>
import PtDeliDialogForSelectFile from 'components/parts/common/PtDeli/PtDeliDialogForSelectFile'
export default {
  name: 'PtFormSetupSubHeading',
  components: {
    PtDeliDialogForSelectFile,
  },
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    opts: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: function () {
    return {
      //for rerender image
      imageKey: 0,
      loading: false,
      errorMsg: '',
      dialog: false,
      imageTypes: {
        external_url: 2,
        do_nothing: 0,
        upload_file: 1,
      },
      convertImageTypes: {},
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      Object.entries(
        this.opts.answer_form_input.additional_option_types.subheading
      ).forEach(([key, val]) => {
        this.convertImageTypes[key] = this.imageTypes[val]
      })
    },
    openDialog() {
      this.dialog = true
    },
    selectFileDone(uploadFile) {
      this.internalValue.upload_file_id = uploadFile.id
      this.internalValue.heading_image = uploadFile.image_url
      this.dialog = false
    },
  },
}
</script>
<style scoped>
.time-pick {
  width: 100px;
}
</style>
