import i18n from 'plugins/vue-i18n'

export function metaTags(params) {
  const site = i18n.tc('meta.site.name')
  const p_yml = params['yml'] ? params['yml'] : 'Default'
  const p_ttl = params['title']
  const p_dsc = params['description']
  const p_kwd = params['keywords']
  const p_img = params['image']
  const title = p_ttl
    ? p_ttl + ' - ' + site
    : !params['yml']
    ? site
    : i18n.tc(`meta.title.${p_yml}`) + ' - ' + site
  const makeMeta = function (p_val, target, p_yml) {
    if (p_val) {
      return p_val
    }
    const str = `meta.${target}.${p_yml}`
    if (i18n.tc(str) !== str) {
      return str
    }
    return i18n.tc(`meta.${target}.Default`)
  }
  const description = makeMeta(p_dsc, 'description', p_yml)
  const keywords = makeMeta(p_kwd, 'keywords', p_yml)
  const twitter_account = i18n.tc(`meta.site.twitter_account`)
  const twitter_image = p_img ? p_img : i18n.tc(`meta.site.twitter_image`)
  const og_image = i18n.tc(`meta.site.og_image`)
  const tags = {
    title: title,
    link: [{ vmid: 'canonical', rel: 'canonical', href: location.href }],
    meta: [
      { vmid: 'description', name: 'description', content: description },
      { vmid: 'keywords', name: 'keywords', content: keywords },
      {
        vmid: 'twitter:site',
        property: 'twitter:site',
        content: twitter_account,
      },
      {
        vmid: 'twitter:creator',
        property: 'twitter:creator',
        content: twitter_account,
      },
      { vmid: 'twitter:title', property: 'twitter:title', content: title },
      {
        vmid: 'twitter:card',
        property: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        vmid: 'twitter:description',
        property: 'twitter:description',
        content: description,
      },
      {
        vmid: 'twitter:image',
        property: 'twitter:image',
        content: twitter_image,
      },
      { vmid: 'og:title', property: 'og:title', content: title },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: description,
      },
      { vmid: 'og:type', property: 'og:type', content: 'website' },
      { vmid: 'og:url', property: 'og:url', content: location.href },
      { vmid: 'og:image', property: 'og:image', content: og_image },
      { vmid: 'og:locale', property: 'og:locale', content: i18n.locale },
    ],
    script: [],
  }
  if (params['noindex']) {
    tags['meta'].push({
      vmid: 'robots',
      property: 'robots',
      content: 'noindex',
    })
    tags['meta'].push({
      vmid: 'googlebot',
      property: 'googlebot',
      content: 'noindex',
    })
  }
  return tags
}
