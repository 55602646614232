<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-row>
      <v-col class="pb-0">
        <div class="red--text">{{ $t('lbl.notify.caution') }}</div>
        <VfTextArea
          v-model="internalValue.text"
          :label="$$tm('action_notice.text')"
          :vees="vees.text"
          counter
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data: () => ({
    loading: false,
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
