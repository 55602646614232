<template>
  <v-card class="mb-6">
    <v-card-title>
      {{ $t('admin.payment_history') }}
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :disable-sort="true"
      :items-per-page="10"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
    >
      <template #[`item.plan_name`]="{ item }">
        {{ item.plan_name }}
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ $$fat(item.created_at) }}
      </template>
      <template #[`item.payment_status`]="{ item }">
        {{ $$te('payment_history.payment_status', item) }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    headers: { type: Array, required: true },
    items: { type: Array, required: true },
  },
}
</script>
