<template>
  <div>
    <VfLoadingOverlayAbsolute :loading="loading" />
    <VfAlert v-model="errorMsg" type="error" />
    <v-row>
      <v-col class="pb-0">
        <PtDeli v-model="internalValue.target_delis" />
      </v-col>
    </v-row>
    <v-row>
      <v-col xl="4" md="7" class="py-0">
        <PtTimingTypeSelect
          v-if="!hideTimingTypeSelect"
          v-model="internalValue.timing"
          :timing_types="opts.timing_type"
          :vees="vees.timing"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PtDeli from 'components/parts/common/PtDeli/PtDeli'
import PtTimingTypeSelect from 'components/parts/common/PtTimingTypeSelect'
export default {
  components: {
    PtDeli,
    PtTimingTypeSelect,
  },
  props: {
    value: { type: Object, required: true },
    vees: {
      type: Object,
      default: function () {
        return {}
      },
    },
    opts: {
      type: Object,
      default: function () {
        return {}
      },
    },
    hideTimingTypeSelect: { type: Boolean, default: false },
  },
  data: () => ({
    loading: false,
    errorMsg: '',
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
