<template>
  <div>
    <PtTitle />
    <v-row class="mt-3 mb-3">
      <v-col md="6">
        <v-card>
          <v-card-title>
            <span
              v-text="
                answerForm && answerForm.hasOwnProperty('name')
                  ? answerForm.name
                  : null
              "
            />
          </v-card-title>
        </v-card>
      </v-col>
      <v-col md="2">
        <v-btn @click="preview">{{ $t('btn.preview') }}</v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-tabs v-model="tab" grow>
        <v-tab v-for="item in items" :key="item.value" @click="reloadPage">{{
          item.text
        }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="px-4 pb-4">
        <v-tab-item>
          <v-row class="mt-3">
            <v-col md="6">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('lbl.answer_form.number_answer') }}
                    </td>
                    <td
                      v-if="
                        answerFormClone &&
                        answerFormClone.hasOwnProperty('created_at')
                      "
                    >
                      {{
                        moment(answerFormClone.created_at).format(
                          'YYYY/MM/DD HH:mm'
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col md="6">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('lbl.answer_form.total_anonymous_answer') }}
                    </td>
                    <td
                      v-if="
                        answerFormClone &&
                        answerFormClone.hasOwnProperty('total_anonymous_answer')
                      "
                    >
                      {{
                        answerFormClone.total_anonymous_answer +
                        $t('lbl.answer_form.user')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
          <hr />
          <h4>{{ $t('lbl.answer_form.answer_number_counter') }}</h4>
          <v-row class="mt-3 mb-3">
            <v-col md="3">
              <p class="font-weight-bold">
                {{ $t('lbl.answer_form.number_of_form_answers') }}
              </p>
            </v-col>
            <v-col md="6">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td class="font-weight-bold">
                      {{ $t('lbl.answer_form.number_of_answers') }}
                    </td>
                    <td
                      v-if="
                        answerFormClone &&
                        answerFormClone.hasOwnProperty('response_count')
                      "
                    >
                      {{ answerFormClone.response_count }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
          <hr />
          <!--Show option-->
          <div
            v-if="
              answerFormClone &&
              answerFormClone.hasOwnProperty('selected_opt_count')
            "
          >
            <v-row
              v-for="item in answerFormClone.selected_opt_count"
              class="mt-3 mb-3"
            >
              <v-col md="3">
                <p class="font-weight-bold">{{ item.input_title }}</p>
              </v-col>
              <v-col md="8">
                <table class="table table-bordered table-striped">
                  <tbody>
                    <tr v-for="value in item">
                      <template
                        v-if="
                          typeof value === 'object' &&
                          value !== null &&
                          value.option_value
                        "
                      >
                        <td width="35%" class="font-weight-bold">
                          {{ value.option_value }}
                        </td>
                        <td width="65%">
                          {{
                            value.select_count +
                            ' / ' +
                            $t('lbl.answer_form.max') +
                            ' ' +
                            (value.capacity ? value.capacity : '-')
                          }}
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>

        <!-- answer counter correction-->
        <v-tab-item>
          <validation-observer ref="observer">
            <v-row>
              <v-col md="12">
                <v-card class="mt-3">
                  <v-card-title>
                    <span
                      v-text="
                        $t('lbl.answer_form.answer_counter_correction_title')
                      "
                    />
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <hr />
            <v-row>
              <v-col md="3">
                <p class="font-weight-bold">
                  {{ $t('lbl.answer_form.number_of_form_answers') }}
                </p>
              </v-col>
              <v-col md="8">
                <table class="table table-bordered">
                  <tbody>
                    <tr>
                      <td width="20%" class="font-weight-bold">
                        <p class="mt-5">
                          {{ $t('lbl.answer_form.number_of_answers') }}
                        </p>
                      </td>
                      <td width="60%">
                        <VfTextField
                          v-model="answerForm.response_count"
                          number
                          :min="0"
                          :vees="{
                            rules: 'required|min_value:0',
                            required: true,
                          }"
                          :label="$t('lbl.answer_form.number_of_answers')"
                        />
                      </td>
                      <td width="20%">
                        <p class="mt-5">-</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
            <!--edit option-->
            <div
              v-if="
                answerFormClone &&
                answerFormClone.hasOwnProperty('selected_opt_count')
              "
            >
              <v-row
                v-for="item in answerForm.selected_opt_count"
                class="mt-3 mb-3"
              >
                <v-col md="3">
                  <p class="font-weight-bold">{{ item.input_title }}</p>
                </v-col>
                <v-col md="8">
                  <table class="table table-bordered table-striped">
                    <tbody>
                      <tr v-for="value in item">
                        <template
                          v-if="
                            typeof value === 'object' &&
                            value !== null &&
                            value.option_value
                          "
                        >
                          <td width="20%" class="font-weight-bold">
                            <p class="mt-5">{{ value.option_value }}</p>
                          </td>
                          <td width="60%">
                            <VfTextField
                              v-model="value.select_count"
                              number
                              :min="0"
                              :label="value.option_value"
                              :vees="{
                                rules: 'required|min_value:0',
                                required: true,
                              }"
                            />
                          </td>
                          <td width="20%">
                            <p class="mt-5">
                              {{
                                $t('lbl.answer_form.max') +
                                ' ' +
                                (value.capacity ? value.capacity : '-')
                              }}
                            </p>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col md="8" class="pl-2 offset-md-3">
                <v-btn color="warning" @click="saveAnswerCounter">{{
                  $t(`btn.to_fix`)
                }}</v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </v-tab-item>

        <!-- list of respondents-->
        <v-tab-item>
          <ShowTabFriendAnswer :answer-data="answerForm" :colors="colors" />
        </v-tab-item>

        <!-- list of answers -->
        <v-tab-item>
          <div>
            <v-row class="mt-3">
              <v-col md="4">
                <VfDatePicker
                  v-model="search.dateForm"
                  :max="new Date().toISOString().slice(0, 10)"
                  label=""
                  @input="$emit('input', search.dateForm)"
                />
              </v-col>
              <v-col md="1">
                <p class="mt-4 text-center">から</p>
              </v-col>
              <v-col md="4">
                <VfDatePicker
                  v-model="search.dateTo"
                  :max="new Date().toISOString().slice(0, 10)"
                  label=""
                  @input="$emit('input', search.dateTo)"
                />
              </v-col>
              <v-col md="2">
                <v-btn class="mt-3" @click="onSearch">
                  {{ $t('btn.show_answers_for_this_period') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="(dataShow && !dataShow.length) || !dataShow">
              <v-col md="12">
                <p>表示する回答がありません</p>
              </v-col>
            </v-row>
          </div>

          <!-- show list answer response -->
          <div v-for="item in dataShow">
            <hr />
            <v-row>
              <v-col md="3">
                <div v-if="item && item.hasOwnProperty('friend')">
                  <p>回答者</p>
                  <div>
                    <a
                      @click="
                        $router.push({
                          name: 'FriendShow',
                          params: { id: item.friend.id },
                        })
                      "
                    >
                      <img
                        class="orgn-icon"
                        :src="$$noimageHelper(item.friend.api_picture_url)"
                      />
                      <span class="font-weight-bold">{{
                        item.friend.name
                      }}</span>
                    </a>
                  </div>
                  <p class="mt-2">
                    {{
                      '(回答日 ' +
                      moment(item.created_at).format('YYYY/MM/DD HH:mm:ss') +
                      ')'
                    }}
                  </p>
                  <p>{{ '推定回答時間：' + item.convert_answer_time }}</p>
                </div>
                <div v-else>
                  <p><b>匿名回答</b></p>
                  <v-btn
                    color="warning"
                    @click="addFriend(item.id, answerForm.name)"
                    >友だちの回答として登録する</v-btn
                  >
                  <p class="mt-2">
                    {{
                      '(回答日' +
                      moment(item.created_at).format('YYYY/MM/DD HH:mm:ss') +
                      ')'
                    }}
                  </p>
                  <p>{{ '推定回答時間 ：' + item.convert_answer_time }}</p>
                </div>
                <div v-if="item.from_type != 'none'">
                  <div>
                    {{ $$te('answer_form_response.from_type', item) }}
                  </div>
                  <div v-if="item.referer_url" class="text-caption">
                    <a :href="item.referer_url" target="_blank">
                      {{ item.referer_url }}
                    </a>
                  </div>
                </div>
              </v-col>
              <v-col md="9">
                <p><b>フォームの回答</b></p>
                <table class="table table-bordered table-striped">
                  <tbody>
                    <template v-for="answer in item.form_response">
                      <tr
                        v-if="
                          answer.hasOwnProperty('beautify_value') &&
                          Array.isArray(answer.beautify_value) &&
                          answer.beautify_value.length > 0 &&
                          answer.beautify_value[0]
                        "
                      >
                        <td width="40%">{{ answer.input_title }}</td>
                        <td width="60%">
                          <p v-for="val in answer.beautify_value">
                            {{ val }}
                          </p>
                        </td>
                      </tr>
                      <tr v-else>
                        <td width="40%">{{ answer.input_title }}</td>
                        <td width="60%">
                          <template
                            v-if="answer.value && !Array.isArray(answer.value)"
                          >
                            <template v-if="typeof answer.value == 'object'">
                              <div
                                v-if="
                                  answer.value.hasOwnProperty(
                                    'upload_file_type'
                                  ) && answer.value.upload_file_type == 'image'
                                "
                              >
                                <img :src="answer.value.file.preview" alt="" />
                                <p>
                                  <a :href="answer.value.file.original">{{
                                    getNameImage(answer.value.file.original)
                                  }}</a>
                                </p>
                              </div>
                              <div
                                v-if="
                                  answer.value.hasOwnProperty(
                                    'upload_file_type'
                                  ) &&
                                  answer.value.upload_file_type == 'document'
                                "
                              >
                                <v-icon>mdi-file-document</v-icon>
                                <p>
                                  <a :href="answer.value.file.original">{{
                                    getNameImage(answer.value.file.original)
                                  }}</a>
                                </p>
                              </div>
                            </template>
                            <template v-else>
                              {{ answer.value }}
                            </template>
                          </template>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </div>
          <div v-if="pagination.totalPage > 1">
            <v-row>
              <v-col md="4" class="offset-4 justify-content-center">
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.totalPage"
                  :total-visible="5"
                  @input="paginate($event)"
                />
              </v-col>
            </v-row>
          </div>
        </v-tab-item>

        <!-- csv export -->
        <v-tab-item>
          <ShowTabExportCSV :answer-data="answerForm" :colors="colors" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import moment from 'moment'
import ShowTabFriendAnswer from './ShowTabFriendAnswer'
import ShowTabExportCSV from './ShowTabExportCSV'
export default {
  components: {
    ShowTabFriendAnswer,
    ShowTabExportCSV,
  },
  data: () => ({
    loading: false,
    tab: 0,
    answerFormId: null,
    answerForm: {},
    answerFormClone: null,
    moment: moment,
    listAnswersResponse: null,
    dataShow: null,
    listFriend: null,
    formInfo: null,
    search: {
      dateForm: null,
      dateTo: null,
    },
    pagination: {
      page: 1,
      perPage: 20,
      totalPage: 5,
    },
    checkbox1: null,
    checkbox2: null,
    colors: {
      unread: 'primary',
      magazine_comment: 'primary',
      low_important: 'primary',
      done: 'default',
      claim: 'red',
      closing: 'red',
      trouble: 'red',
      question: 'red',
    },
  }),
  computed: {
    items() {
      const arr = []
      arr.push({ text: this.$t('lbl.answer_form.overview'), value: 'overview' })
      arr.push({
        text: this.$t('lbl.answer_form.answer_counter_correction'),
        value: 'answer_counter',
      })
      arr.push({
        text: this.$t('lbl.answer_form.list_of_respondents'),
        value: 'list_respondents',
      })
      arr.push({
        text: this.$t('lbl.answer_form.list_of_answers'),
        value: 'list_answers',
      })
      arr.push({
        text: this.$t('lbl.answer_form.csv_export'),
        value: 'csv_export',
      })
      return arr
    },
  },
  created() {
    this.init()
    this.loadListAnswer()
  },
  methods: {
    async init() {
      this.tab = Number(this.$route.query.tab) || 0
      this.answerFormId = this.$route.params.id
      this.loading = true
      const path = '/api/v1/answer_forms/' + this.answerFormId
      await this.axios
        .get(path)
        .then((res) => {
          this.answerForm = res.data.answer_form
          this.answerFormClone = this.$$clone(this.answerForm)
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async saveAnswerCounter() {
      this.loading = true
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return this.$toasted.error(this.$t('danger.params'))
      }
      const path = '/api/v1/answer_forms/' + this.answerFormId
      const method = 'put'
      const prms = {
        answer_form: this.answerForm,
      }
      await this.$$sendForm(method, path, this.$$reqPrms(prms))
        .then(() => {
          this.$toasted.success(this.$t('success.create'))
          this.$router.go()
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.$$loadingOverlayContent(false)
        })
    },
    async loadListAnswer() {
      this.loading = true
      const path = '/api/v1/answer_form_responses'
      await this.axios
        .get(path, {
          params: { answer_form_id: this.$route.params.id },
        })
        .then((res) => {
          this.listAnswersResponse = res.data
          this.getDataShow(this.pagination.page)
          this.pagination.totalPage =
            this.listAnswersResponse.length > 0
              ? Math.ceil(
                  this.listAnswersResponse.length / this.pagination.perPage
                )
              : 0
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getNameImage(link) {
      let arr = link.split('/')
      return arr[arr.length - 1]
    },
    preview() {
      let routeData = this.$router.resolve({
        name: 'AnswerFormAnswer',
        params: { uuid: this.answerForm.alt_uuid },
      })
      window.open(routeData.href + '?mode=example', '_blank')
    },
    paginate(value) {
      this.getDataShow(value)
    },
    getDataShow(value) {
      this.dataShow = this.listAnswersResponse.slice(
        value * this.pagination.perPage - this.pagination.perPage,
        value * this.pagination.perPage
      )
    },
    addFriend(answerId, name) {
      this.$router.push({
        name: 'AnswerFormMerge',
        params: { id: answerId },
        query: { form_id: this.answerFormId, name: name },
      })
    },
    reloadPage() {
      this.init()
      this.loadListAnswer()
    },
    async onSearch() {
      this.loading = true
      const path = '/api/v1/answer_form_responses'
      await this.axios
        .get(path, {
          params: {
            answer_form_id: this.$route.params.id,
            'q[start_date]': this.search.dateForm,
            'q[end_date]': this.search.dateTo,
          },
        })
        .then((res) => {
          this.listAnswersResponse = res.data
          this.getDataShow(this.pagination.page)
          this.pagination.totalPage =
            this.listAnswersResponse.length > 0
              ? Math.ceil(
                  this.listAnswersResponse.length / this.pagination.perPage
                )
              : 0
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  metaInfo() {
    return this.$$metaTags(this.metaOpts)
  },
}
</script>
<style scoped lang="scss">
::v-deep .v-text-field {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
a:hover {
  text-decoration: none;
}
::v-deep .v-input--selection-controls {
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.color-white {
  color: #fff !important;
}
::v-deep {
  fieldset > legend {
    display: none;
  }
  .v-label {
    display: none;
  }
}
.orgn-icon {
  width: 80px;
  height: 80px;
}
</style>
