<template>
  <div v-if="'id' in defDeli">
    <v-row v-for="(deli, deliIndex) in internalValue">
      <v-col cols="12">
        <v-card :key="deliIndex" class="mb-4" outlined>
          <PtDeliText
            :key="deliIndex"
            v-model="deli.deli_text"
            :vees="allVees.deli_text"
          />
          <v-card-actions>
            {{ $t('lbl.send_time', { count: deliIndex + 1 }) }}
            <v-spacer />
            <v-btn
              :disabled="internalValue.length <= 1 || deliIndex === 0"
              small
              icon
              @click="sortMessage(deliIndex, -1)"
            >
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-btn>
            <v-btn
              :disabled="
                internalValue.length <= 1 ||
                deliIndex === internalValue.length - 1
              "
              small
              icon
              @click="sortMessage(deliIndex, 0)"
            >
              <v-icon>mdi-arrow-collapse-down</v-icon>
            </v-btn>
            <v-btn
              :disabled="internalValue.length >= 5"
              small
              icon
              color="primary"
              @click="appendMessage(deliIndex)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              :disabled="internalValue.length <= 1"
              small
              icon
              color="error"
              @click="deleteConfirm(deliIndex)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" :max-width="300">
      <v-card outlined>
        <v-card-title>{{ $t('confirm_title.delete') }}</v-card-title>
        <v-card-text>{{ $t('confirm.delete') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialog = false">{{ $t('btn.cancel') }}</v-btn>
          <v-btn @click="deleteMessage">{{ $t('btn.delete') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PtDeliText from 'components/parts/common/PtDeli/PtDeliText'

export default {
  components: {
    PtDeliText,
  },
  props: {
    value: { default: null },
  },
  data: () => ({
    dialog: false,
    defDeli: {},
    allVees: {},
    opts: {},
    deleteTargetIndex: -1,
  }),
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const path = '/api/v1/delis/setup'
      await this.axios
        .get(path, { params: this.$$reqPrms() })
        .then((res) => {
          this.defDeli = res.data.deli
          this.allVees = res.data.all_vees
          this.opts = res.data.opts
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
      if (this.internalValue.length === 0) {
        this.appendMessage(0)
      }
    },
    appendMessage(index) {
      this.internalValue.splice(index + 1, 0, this.$$clone(this.defDeli))
    },
    deleteMessage() {
      this.internalValue.splice(this.deleteTargetIndex, 1)
      this.dialog = false
    },
    sortMessage(index, move) {
      const targetIdx = index + move
      const row1 = this.internalValue[targetIdx + 1]
      const row2 = this.internalValue[targetIdx]
      this.internalValue.splice(targetIdx, 2, row1, row2)
    },
    deleteConfirm(index) {
      this.deleteTargetIndex = index
      this.dialog = true
    },
  },
}
</script>
