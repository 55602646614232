<template>
  <validation-provider
    v-slot="{ errors }"
    :name="label"
    :rules="rules"
    :vid="Math.random().toString()"
  >
    <v-select
      v-model="internalValue"
      v-bind="baseProps"
      :error-messages="errors"
      :items="items"
      :placeholder="placeholder"
      chips
      clearable
      multiple
    >
      <template #selection="{ attrs, item, select, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="remove(item.value)"
        >
          {{ item.text }}
        </v-chip>
      </template>
    </v-select>
  </validation-provider>
</template>
<script>
import mixin from 'components/vuetifyParts/commonExport'
export default {
  mixins: [mixin],
  props: {
    value: { default: null },
    placeholder: { default: '' },
    selects: { type: Object, required: true },
  },
  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    items() {
      let arr = []
      for (let [k, v] of Object.entries(this.selects)) {
        arr.push({ text: k, value: v })
      }
      return arr
    },
  },
  methods: {
    remove(val) {
      const index = Object.values(this.internalValue).indexOf(val)
      const temp = this.$$clone(this.internalValue)
      temp.splice(index, 1)
      this.internalValue = temp
    },
  },
}
</script>
