<template>
  <div>
    <v-row>
      <v-col md="12">
        <v-btn color="primary" @click="exportAll"
          >すべての回答をエクスポート</v-btn
        >
        <v-btn class="blue lighten-2 color-white" @click="showSearch"
          >回答日を選んでエクスポート</v-btn
        >
      </v-col>
      <!--search-->
      <v-col v-if="isSearch" md="12">
        <v-row class="mt-3">
          <v-col md="4">
            <VfDatePicker
              v-model="search.dateFrom"
              :max="new Date().toISOString().slice(0, 10)"
              label=""
              @input="$emit('input', search.dateFrom)"
            />
          </v-col>
          <v-col md="1">
            <p class="mt-4 text-center">から</p>
          </v-col>
          <v-col md="4">
            <VfDatePicker
              v-model="search.dateTo"
              :max="new Date().toISOString().slice(0, 10)"
              label=""
              @input="$emit('input', search.dateTo)"
            />
          </v-col>
          <v-col md="2">
            <v-btn class="mt-3" @click="onSearchWithDate">
              {{ $t('btn.export_answers_for_this_period') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="12">
        <hr />
        <p>友だちを選んでエクスポート</p>
        <table class="table .table-hover">
          <thead>
            <tr>
              <th>対応マーク</th>
              <th>名前</th>
              <th />
              <th>
                <VfCheckbox v-model="selectAll" label="" hide-details />
              </th>
            </tr>
          </thead>
          <tbody>
            <template
              v-if="
                (listFriend && listFriend.length) ||
                (answerData &&
                  answerData.hasOwnProperty('total_anonymous_answer') &&
                  answerData.total_anonymous_answer)
              "
            >
              <tr v-for="(friend, friend_i) in listFriend" :key="friend_i">
                <td>
                  <v-chip dark label :color="colors[friend.message_status]">
                    {{ $$te('friend.message_status', friend) }}
                  </v-chip>
                </td>
                <td>
                  <a
                    :href="
                      $router.resolve({
                        name: 'FriendShow',
                        params: { id: friend.id },
                      }).href
                    "
                  >
                    {{ friend.name }}
                  </a>
                </td>
                <td>
                  <a
                    :href="
                      $router.resolve({
                        name: 'ListAnswerResponseMember',
                        params: { friend_id: friend.id },
                      }).href
                    "
                  >
                    <v-btn color="primary">回答表示</v-btn>
                  </a>
                </td>
                <td>
                  <VfCheckbox
                    v-model="selected"
                    label=""
                    :checkbox-value="friend.id"
                    hide-details
                  />
                </td>
              </tr>
              <tr>
                <td />
                <td />
                <td />
                <td
                  v-if="
                    answerData &&
                    answerData.hasOwnProperty('total_anonymous_answer')
                  "
                >
                  <VfCheckbox
                    v-model="include_anonymous"
                    :label="
                      '匿名(' +
                      answerData.total_anonymous_answer +
                      '名)を含める'
                    "
                    hide-details
                  />
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td>回答した友だちはいません</td>
              </tr>
            </template>
          </tbody>
        </table>
      </v-col>
      <v-row class="p-3">
        <v-col md="6" offset-md="6" class="text-right">
          <v-btn @click="exportCSV"
            >チェックしたメンバーの回答をエクスポート</v-btn
          >
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'ShowTabExportCSV',
  props: {
    answerData: { default: null },
    colors: { default: null },
  },
  data: function () {
    return {
      listFriend: [],
      selected: [],
      isSearch: false,
      search: {
        dateFrom: null,
        dateTo: null,
      },
      include_anonymous: 0,
    }
  },
  computed: {
    selectAll: {
      get: function () {
        if (this.listFriend.length === 0) {
          return false
        }
        return this.listFriend
          ? this.selected.length === this.listFriend.length
          : false
      },
      set: function (value) {
        let selected = []
        if (value) {
          this.listFriend.forEach(function (friend) {
            selected.push(friend.id)
          })
          selected.push('include_anonymous')
        }
        this.selected = selected
      },
    },
  },
  created() {
    this.answerFormId = this.$route.params.id
    this.loadListFriend()
  },
  methods: {
    showSearch() {
      this.isSearch = !this.isSearch
    },
    exportAll() {
      let params = {
        answer_form_id: this.$route.params.id,
      }
      this.downloadCSV(params)
    },
    exportCSV() {
      if (!this.selected.length && this.include_anonymous == 0) {
        this.$$glMsg('error', 'エラー\n' + '\n' + '最低一人を指定して下さい。')
        return
      }
      let friendSelected = this.$$clone(this.selected)
      friendSelected = encodeURIComponent(JSON.stringify(friendSelected))
      let params = {
        answer_form_id: this.$route.params.id,
        'q[selected_friend_ids]': friendSelected,
        'q[include_anonymous]': this.include_anonymous,
      }
      this.downloadCSV(params)
    },
    onSearchWithDate() {
      if (!this.search.dateFrom && !this.search.dateTo) {
        this.$$glMsg('error', 'エラー\n' + '\n' + '期間を指定してください。')
        return
      }
      let params = {
        answer_form_id: this.$route.params.id,
        'q[start_date]': this.search.dateFrom,
        'q[end_date]': this.search.dateTo,
      }
      this.downloadCSV(params)
    },
    async downloadCSV(params) {
      let path = '/api/v1/answer_form_responses/export_csv?'
      if (params) {
        for (const key in params) {
          path += key + '=' + params[key] + '&'
        }
      }
      await this.axios
        .get(path)
        .then((res) => {
          let data = res.data
          if (data && data.hasOwnProperty('errors') && data.errors.length) {
            this.$$glMsg('error', 'エラー\n' + '\n' + data.errors[0])
          } else {
            this.callAPIExport(params)
          }
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    callAPIExport(params) {
      this.loading = true
      let path = '/api/v1/answer_form_responses/export_csv.csv?'
      if (params) {
        for (const key in params) {
          path += key + '=' + params[key] + '&'
        }
      }
      let link = document.createElement('a')
      link.href = path
      link.click()
    },
    async loadListFriend() {
      this.loading = true
      const path = '/api/v1/answer_form_responses/answered_friends'
      await this.axios
        .get(path, {
          params: { answer_form_id: this.$route.params.id },
        })
        .then((res) => {
          this.listFriend = res.data.answer_form_response.friends
        })
        .catch((error) => {
          this.$$ajaxError(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
.color-white {
  color: #fff !important;
}
</style>
